import React from 'react';
import PropTypes from 'prop-types';
import RoundButton from 'components/uikit/Button/RoundButton';
import { converter } from 'libs/showdown';



const ParagraphTemplate = props => {
    const {
        handleUpDividerDragEnter,
        handleUpDividerDragLeave,
        handleDownDividerDragEnter,
        handleDownDividerDragLeave,
        index,
        text,
        deleteTemplateChild,
        handleModalOpen,
    } = props;

    return (
        <div className="TemplateItemContainer">
            <div
                className="TemplateItemContainer__UpDivider"
                onDragEnter={handleUpDividerDragEnter}
                onDragLeave={handleUpDividerDragLeave}
                data-index={index}
            />
            <div className="TemplateItem">
                <div
                    className="TemplateItem__Text"
                    dangerouslySetInnerHTML={{ __html: converter.makeHtml(text) }}
                />
                <div className="TemplateItem__Buttons TemplateItem__Buttons--text">
                    <RoundButton type={'edit'} onClick={handleModalOpen(index)} />
                    <RoundButton type={'close'} onClick={deleteTemplateChild(index)} />
                </div>
            </div>
            <div
                className="TemplateItemContainer__DownDivider"
                onDragEnter={handleDownDividerDragEnter}
                onDragLeave={handleDownDividerDragLeave}
                data-index={index + 1}
            />
        </div>
    );
};

ParagraphTemplate.propTypes = {
    handleUpDividerDragEnter: PropTypes.func,
    handleUpDividerDragLeave: PropTypes.func,
    handleDownDividerDragEnter: PropTypes.func,
    handleDownDividerDragLeave: PropTypes.func,
    index: PropTypes.number,
    text: PropTypes.string,
    deleteTemplateChild: PropTypes.func,
    handleModalOpen: PropTypes.func,
};

export default ParagraphTemplate;
