import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Header from 'components/Lk/Basic/Layout/Header';
import MainCard from 'components/Lk/Basic/MainCard';
import ListCard from 'components/Lk/Basic/ListCard';
import DetailsCard from 'components/Lk/Basic/DetailsCard';
import { ACTIVE_MAIN_CARD } from 'components/Lk/Basic/constants';
import ErrorBoundary from 'components/common/ErrorBoundary';
import './Layout.scss';

const Layout = props => {
    const [activeCard, setActiveCard] = useState(ACTIVE_MAIN_CARD);
    const { user, refetchKey } = props;

    useEffect(() => {
        window.scrollTo(0, 1);
    });

    return (
        <div className='LKLayout'>
            <Header user={user} refetchKey={refetchKey} />
            <ErrorBoundary>
                <div className="LKLayout__CardsWrapper">  
                <div id="modal-root" />
                <div id="tooltip-root" /> 
                    <MainCard 
                        activeCard={activeCard} 
                        setActiveCard={setActiveCard}
                    />
                    <ListCard                 
                        activeCard={activeCard} 
                        setActiveCard={setActiveCard}
                    />
                    <DetailsCard                
                        activeCard={activeCard} 
                        setActiveCard={setActiveCard}
                    />
                </div>
            </ErrorBoundary>            
        </div>
    );
};

const mapStateToProps = state => {
    return { user: state.auth.user, refetchKey: state.user.refetchKey };
}; 

export default connect(mapStateToProps)(Layout);