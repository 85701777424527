import React from 'react';
import Icons from 'components/Lk/Uikit/Icons';
import { PERSON_COMMENT_VISIBILITY_ENUM, PERSON_COMMENT_TYPE_ENUM } from 'constants.js';
import { getUserShortName, localizeTimezone } from 'utils';

class Comment extends React.Component {
    getTypeComment = comment => {
        switch (comment.type) {
            case PERSON_COMMENT_TYPE_ENUM.Person:
                return <span>(из&nbsp;анкеты)</span>;
            case PERSON_COMMENT_TYPE_ENUM.Wishlist:
                return <span>(из&nbsp;подборки)</span>;
            default:
                return '';
        }
    };

    getCommentInfo = (comment, isCurrentUserComment) => {
        const author = isCurrentUserComment ? 'Моя заметка' : getUserShortName(comment.author);
        return `${author}, ${localizeTimezone(
            comment.modified || comment.created,
            'DD.MM.YYYY HH:mm',
        )} ${this.getTypeComment(comment)?.props?.children || ''}:`;
    };
    getVisibility = visibility => {
        switch (visibility) {
            case PERSON_COMMENT_VISIBILITY_ENUM.Everyone:
                return 'Все, у кого есть доступ к данной анкете резервиста';
            case PERSON_COMMENT_VISIBILITY_ENUM.Group:
                return 'Все, у кого такая же группа, как у автора';
            default:
                return 'Только я';
        }
    };

    getAttributeVisibilityInfo = (comment, isCurrentUserComment) => {
        return isCurrentUserComment ? (
            <div className="PersonCommentsBlock__AttributeVisibility">
                <div>Кто видит мою заметку:</div>
                <div className="PersonCommentsBlock__AttributeVisibility--attr">
                    {this.getVisibility(comment.visibility)}
                </div>
            </div>
        ) : (
            ''
        );
    };

    render() {
        const { data: comment, user } = this.props;
        const isCurrentUserComment = user?.id === comment.author.id;
        const attributeVisibility = this.getAttributeVisibilityInfo(comment, isCurrentUserComment);
        return (
            <div className="LKPersonComments__Comment" key={comment.id}>
                <div className="LKPersonComments__Comment--text">
                    {this.getCommentInfo(comment, isCurrentUserComment)}
                    <div className="LKPersonComments__Comment--overflow">{comment.text}</div>
                    {attributeVisibility}
                </div>
                {isCurrentUserComment && comment.type === PERSON_COMMENT_TYPE_ENUM.Person && (
                    <div>
                        <div
                            className="LKPersonComments__Icon"
                            onClick={this.props.onEdit(comment)}
                        >
                            <Icons name="edit" />
                        </div>
                        <div
                            className="LKPersonComments__Icon"
                            onClick={e => this.props.onRemove(e, comment.id)}
                        >
                            <Icons name="deleteIcon" />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

Comment.propTypes = {};

export default Comment;
