import React, { useState, useEffect, useRef, useCallback } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import { serviceResultCode, getError } from 'serviceErrors';
import {
    getPersonGrowthPlans,
    getPersonGrowthReportPdfDownloadLink,
    savePersonGrowthPlan,
    updatePersonGrowthPlan,
    sendPersons,
    getPersonAlreadyHavePersonGrowthPlan,
    clearPersonGrowthPlanSession,
    deletePersonGrowthPlan,
} from 'api';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import { assistanceType } from './constants';
import { capitalizeFirstLetter, localizeTimezone } from 'utils';
import ExpandedBlock from 'components/Lk/Uikit/ExpandedBlock';
import InfoField from 'components/Lk/Uikit/InfoField';
import ResourceCard from './ResourceCard';
import RoundedButton from 'components/Lk/Uikit/Buttons/RoundedButton';
import Service from 'components/Lk/Common/Service';
import CardItemBlock from 'components/Lk/Common/CardItemBlock';
import AcceptPage from 'components/Lk/Common/AcceptPage';
import EditShtortPlan from './EditShortPlan';
import { ROLE_LEADER, PERSONGROWTHPLAN_TYPE, PERSONGROWTHPLAN_STATUS } from 'constants.js';
import EditProgressPlan from 'components/Lk/Common/EditProgressPlan';
import Loader from 'components/common/Loader';
import IconButton from 'components/Lk/Uikit/Buttons/IconButton';

const Progress = (props) => {
    const { personId, showErrorAlert, showSuccessAlert, scrollParentRef, auth } = props;
    const [plans, setPlans] = useState([]);
    const [personAlreadyHavePersonGrowthPlan, setPersonAlreadyHavePersonGrowthPlan] = useState(
        false,
    );
    const [loading, setLoading] = useState(true);
    const [currentPlan, setCurrentPlan] = useState(null);

    const defaultAcceptPageParams = {
        title: '',
        acceptAction: () => {},
        forceClose: false,
    };

    const [acceptPage, setAcceptPage] = useState(defaultAcceptPageParams);
    const [isAcceptPage, setIsAcceptPage] = useState(false);

    const wrapperRef = useRef();

    const loadPlan = useCallback(
        async (mounted) => {
            setLoading(true);
            const plansData = await getPersonGrowthPlans(personId);
            const personAlreadyHavePersonGrowthPlan = await getPersonAlreadyHavePersonGrowthPlan(
                personId,
            );
            mounted && setPersonAlreadyHavePersonGrowthPlan(personAlreadyHavePersonGrowthPlan.data);
            mounted && setPlans(plansData.data);
            mounted && setLoading(false);
        },
        [personId],
    );

    useEffect(() => {
        let mounted = true;

        const loadPlan = async () => {
            setLoading(true);
            const plansData = await getPersonGrowthPlans(personId);
            const personAlreadyHavePersonGrowthPlan = await getPersonAlreadyHavePersonGrowthPlan(
                personId,
            );
            mounted && setPlans(plansData.data);
            mounted && setPersonAlreadyHavePersonGrowthPlan(personAlreadyHavePersonGrowthPlan.data);
            mounted && setLoading(false);
        };

        loadPlan();
        return () => {
            mounted = false;
        };
    }, [personId, loadPlan]);

    const getTitle = (plan) => {
        let title = 'Цель развития';
        if (plan.type === PERSONGROWTHPLAN_TYPE.Short) {
            title = plan.title;
        } else if (plan.competencies && plan.competencies.length > 0) {
            title = plan.competencies.map((x) => x.name).join(' / ');
        }

        if (plan.status === PERSONGROWTHPLAN_STATUS.Draft) {
            return (
                <div className="LKPlanDraftTitle">
                    <span className="LKPlanDraftTitle__Text">{title}</span>
                </div>
            );
        }

        return <span>{title}</span>;
    };

    const getDatesFooter = (plan) => {
        return (
            <div className="LKProfileProgress__DatesFooter">
                <div>Создана {localizeTimezone(plan.start, 'DD.MM.YYYY')}</div>
                <div>
                    {plan.status === PERSONGROWTHPLAN_STATUS.Draft
                        ? ''
                        : `Реализовать до ${moment.utc(plan.end).format('DD.MM.YYYY')}`}
                </div>
            </div>
        );
    };

    const getActions = (plan, isSession = true) => {
        return (
            auth.user.personId === plan.personId && (
                <div className="LKProfileProgress__DraftActions">
                    <IconButton
                        type={'cross'}
                        onClick={onAcceptRemove(
                            plan,
                            isSession,
                            plan.type === PERSONGROWTHPLAN_TYPE.Short,
                        )}
                    />
                    {!plan.isApproved &&
                    (plan.status === PERSONGROWTHPLAN_STATUS.Draft ||
                        moment(plan.end).isAfter()) ? (
                        plan.type === PERSONGROWTHPLAN_TYPE.Full ? (
                            <IconButton
                                type={'pencil'}
                                onClick={onAddPlan(PERSONGROWTHPLAN_TYPE.Full, plan.id, isSession)}
                            />
                        ) : (
                            <IconButton type={'pencil'} onClick={onEditShortPlan(plan)} />
                        )
                    ) : null}
                </div>
            )
        );
    };
    const onAcceptSend = () => {
        setIsAcceptPage(true);
        setAcceptPage({
            title: `Направить задачу по созданию ИПР?`,
            info:
                'Задача о создании индивидуального плана развития будет направлена резервисту и эксперту по управлению талантами, который закреплён за Вами и вашими командами',
            acceptAction: onPersonsSend,
        });
    };

    const onAcceptRemove = (plan, isSession = true, isShortPlan = false) => (e) => {
        e.stopPropagation();
        setIsAcceptPage(true);

        const modalHeader =
            plan.type === PERSONGROWTHPLAN_TYPE.Short
                ? 'Вы уверены, что хотите удалить цель развития без возможности восстановления?'
                : 'Вы уверены, что хотите удалить ИПР без возможности его восстановления?';

        setAcceptPage({
            title: modalHeader,
            info: null,
            acceptAction: removeDraftPlan(plan.id, isSession, isShortPlan),
            forceClose: true,
        });
    };

    const onPersonsSend = async () => {
        try {
            await sendPersons('PersonGrowth', null, [personId]);
            showSuccessAlert('Запрос успешно отправлен');
        } catch (error) {
            const reqError = getError(error, getSendError());
            showErrorAlert(reqError.message);
        }
    };

    const getSendError = () => (code, payload) => {
        switch (code) {
            case serviceResultCode.PersonAlreadyHavePersonGrowthPlan:
                return `У выбранного резервиста уже есть ИПР`;
            case serviceResultCode.CuratorNotFound:
                return payload ? payload : 'Специалист по развитию не найден';
            default:
                return 'Произошла непредвиденная ошибка';
        }
    };

    const renderPlan = (plan) => {
        return plan.type === PERSONGROWTHPLAN_TYPE.Short
            ? renderShortPlan(plan)
            : renderFullPlan(plan);
    };

    const renderShortPlan = (plan) => {
        return (
            <>
                <InfoField noIcon title="Цель развития" value={plan.title} />
                <InfoField
                    noIcon
                    title="Срок выполнения"
                    value={plan.end && capitalizeFirstLetter(moment(plan.end).format('MMMM YYYY'))}
                />
            </>
        );
    };

    const coutGoalInstruments = (plan) => {
        return plan.resources && plan.resources.length > 0 && plan.resources.length;
    };

    const renderGoalInstruments = (plan) => {
        if (!wrapperRef.current) {
            return;
        }
        const divWidth = wrapperRef.current.offsetWidth;
        const params = {
            slidesPerView: 2,
            spaceBetween: 15,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
        };

        return plan.resources && plan.resources.length > 0 && divWidth <= 500 ? (
            <Swiper {...params}>
                {plan.resources.map((x) => (
                    <div key={x.id}>
                        <ResourceCard mobile itemId={x.id} name={x.name} url={x.link} />
                    </div>
                ))}
            </Swiper>
        ) : (
            <div className="LKPersonGrowthResourcesList">
                {plan.resources && renderPlainListInstruments(plan.resources)}
            </div>
        );
    };

    const renderPlainListInstruments = (resources) => {
        return resources.map((x) => (
            <div className="LKPersonGrowthResourcesList__Item" key={x.id}>
                <ResourceCard itemId={x.id} name={x.name} url={x.link} />
            </div>
        ));
    };

    const renderMentor = (plan) => {
        if (plan.personGrowthAssistants && plan.personGrowthAssistants.length > 0) {
            const correctPlans = plan.personGrowthAssistants.filter(
                (mentor) => mentor.fullName || mentor.position || mentor.assistanceDetails,
            );
            return (
                correctPlans.length > 0 &&
                correctPlans.map((mentor) => (
                    <div key={mentor.id}>
                        <div className="LKProfileProgress__Line-">{mentor.fullName}</div>
                        <div className="LKProfileProgress__Line--sub">{mentor.position}</div>
                        <div className="LKProfileProgress__Line--sub">
                            {mentor.assistanceDetails}
                        </div>
                    </div>
                ))
            );
        }
        return false;
    };

    const renderEvents = (plan) => {
        return (
            plan.personGrowthEvents &&
            plan.personGrowthEvents.filter((x) => x.type).length > 0 &&
            plan.personGrowthEvents.map((assistance) => (
                <div className="LKProfileProgress__Line" key={assistance.id}>
                    {assistanceType[assistance.type]}{' '}
                    {assistance.assistanceDetails && `(${assistance.assistanceDetails})`}
                </div>
            ))
        );
    };

    const renderFullPlan = (plan) => {
        return (
            <>
                <InfoField noIcon title="Цель развития" value={plan.title} />
                <InfoField noIcon title="Желаемый конечный результат" value={plan.expectedResult} />

                <InfoField
                    fullWidth
                    noIcon
                    title="Инструменты развития"
                    value={coutGoalInstruments(plan)}
                >
                    {coutGoalInstruments(plan) && (
                        <div className="LKPersonGrowthResources">
                            <div className="LKPersonGrowthResources__Header">
                                Выбрано - {coutGoalInstruments(plan)}
                            </div>
                            {renderGoalInstruments(plan)}
                        </div>
                    )}
                </InfoField>

                <InfoField
                    noIcon
                    title="Кто поможет в развитии компетенции"
                    value={renderMentor(plan)}
                />
                <InfoField
                    noIcon
                    title="Что поможет в развитии компетенции"
                    value={renderEvents(plan)}
                />

                <InfoField
                    noIcon
                    title="Срок выполнения"
                    value={plan.end && capitalizeFirstLetter(moment(plan.end).format('MMMM YYYY'))}
                />

                <div className="LKProfileProgress__Footer">
                    {plan.status !== PERSONGROWTHPLAN_STATUS.Draft && (
                        <RoundedButton onClick={() => handleDownload(plan.id)}>
                            Сохранить в PDF
                        </RoundedButton>
                    )}
                </div>
            </>
        );
    };

    const handleDownload = async (planId) => {
        if (planId.length === 0) {
            return;
        }
        const downloadLink = getPersonGrowthReportPdfDownloadLink(planId);
        window.location = downloadLink;
    };

    const canLeaderAddGoal = () => {
        if (!props.auth.user) {
            return true;
        }
        return (
            props.auth.user.roles.find((role) => role.name === ROLE_LEADER) &&
            props.auth.user.personId === personId
        );
    };

    const removeDraftPlan = (id, isSession = true, isShortPlan = false) => async () => {
        isSession
            ? await clearPersonGrowthPlanSession(personId, id)
            : await deletePersonGrowthPlan(id);

        isSession
            ? showSuccessAlert('Черновик ИПР успешно удален')
            : isShortPlan
            ? showSuccessAlert('Цель развития успешно удалена')
            : showSuccessAlert('План развития успешно удален');

        loadPlan(true);
    };

    const onSaveDraft = (manual = true) => {
        manual && updatePlans();
    };

    const cancelDraft = () => {
        updatePlans();
    };

    const updatePlans = () => {
        loadPlan(true);
        setCurrentPlan(null);
    };

    const onEditShortPlan = (shortPlan) => (e) => {
        e.stopPropagation();
        const initial = {
            personId: personId,
            ...shortPlan,
        };

        setCurrentPlan(initial);
    };

    const onAddPlan = (type, id = null, isSession = true) => (e) => {
        e.stopPropagation();
        const initial = {
            personId: personId,
            id,
            type,
            title: null,
            minLengthWarning: true,
            start: new Date(),
            end: null,
            isSession: isSession,
        };

        setCurrentPlan(initial);
    };

    const onSaveShortPlan = async (plan) => {
        try {
            setLoading(true);
            currentPlan.isSession
                ? await savePersonGrowthPlan({ ...plan, status: PERSONGROWTHPLAN_STATUS.Process })
                : await updatePersonGrowthPlan(plan);
            setCurrentPlan(null);
            currentPlan.isSession
                ? showSuccessAlert('Цель успешно создана')
                : showSuccessAlert('Цель успешно обновлена');
            loadPlan(true);
        } catch (error) {
            showErrorAlert(error.message);
        }
    };

    if (currentPlan) {
        return currentPlan.type === PERSONGROWTHPLAN_TYPE.Full ? (
            <EditProgressPlan
                plan={currentPlan}
                onSave={onSaveShortPlan}
                onSaveDraft={onSaveDraft}
                onCancel={() => setCurrentPlan(null)}
                scrollParentRef={scrollParentRef}
                cancelDraft={cancelDraft}
            />
        ) : (
            <EditShtortPlan
                plan={currentPlan}
                onSave={onSaveShortPlan}
                onCancel={() => setCurrentPlan(null)}
            />
        );
    }

    const activePlans = plans && plans.filter((x) => x.status !== PERSONGROWTHPLAN_STATUS.Draft);
    const draftPlans = plans && plans.filter((x) => x.status === PERSONGROWTHPLAN_STATUS.Draft);
    return (
        <>
            <div ref={wrapperRef} id="LKProfileProgress" className="LKProfileProgress">
                {loading ? (
                    <Loader overlay />
                ) : (
                    <>
                        <div className="LKProfileProgress__Header">Цели развития</div>
                        {activePlans && activePlans.length !== 0 ? (
                            activePlans.map((x) => (
                                <ExpandedBlock
                                    key={x.id}
                                    header={getTitle(x)}
                                    actions={getActions(x, false)}
                                    direction="vertical"
                                    footer={getDatesFooter(x)}
                                >
                                    {renderPlan(x)}
                                </ExpandedBlock>
                            ))
                        ) : (
                            <div className="LKProfileProgress__NoData">
                                Цели развития пока отсутствуют
                            </div>
                        )}

                        {draftPlans && draftPlans.length !== 0 && (
                            <>
                                <div className="LKProfileProgress__Header LKProfileProgress__Header--draft">
                                    Черновики
                                </div>
                                {draftPlans.map((x) => (
                                    <ExpandedBlock
                                        key={x.id}
                                        header={getTitle(x)}
                                        actions={getActions(x, true)}
                                        direction="vertical"
                                        footer={getDatesFooter(x)}
                                    >
                                        {renderPlan(x)}
                                    </ExpandedBlock>
                                ))}
                            </>
                        )}

                        <div className="LKProfileProgress__ButtonMenu">
                            {!personAlreadyHavePersonGrowthPlan && !canLeaderAddGoal() && (
                                <div className="LKProfileProgress__MenuBlock">
                                    <CardItemBlock grey>
                                        <Service
                                            onClick={onAcceptSend}
                                            title="Создать ИПР"
                                            icon="createPlan"
                                            text="Поставить задачу по созданию ИПР"
                                        />
                                    </CardItemBlock>
                                </div>
                            )}

                            {canLeaderAddGoal() && (
                                <div className="LKProfileProgress__MenuBlock">
                                    <CardItemBlock grey>
                                        <Service
                                            onClick={onAddPlan(PERSONGROWTHPLAN_TYPE.Full)}
                                            title="Создать ИПР"
                                            icon="createPlan"
                                        />
                                    </CardItemBlock>
                                </div>
                            )}

                            {canLeaderAddGoal() && (
                                <div className="LKProfileProgress__MenuBlock">
                                    <CardItemBlock grey>
                                        <Service
                                            onClick={onAddPlan(PERSONGROWTHPLAN_TYPE.Short)}
                                            title="Создать личную цель"
                                            icon="createPersonalPlan"
                                            text=""
                                        />
                                    </CardItemBlock>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
            <AcceptPage
                show={isAcceptPage}
                title={acceptPage.title}
                info={acceptPage.info}
                acceptAction={acceptPage.acceptAction}
                setIsAcceptPage={setIsAcceptPage}
                forceClose={acceptPage.forceClose}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

const actions = { showErrorAlert, showSuccessAlert };

export default connect(mapStateToProps, actions)(Progress);
