import React from 'react';
import { Route, Switch } from 'react-router';
import { testsRoute, testEditRoute, testNewRoute } from 'routes';
import TestList from 'pages/manager/moderation/tests/TestList';
import Test from 'pages/manager/moderation/tests/Test';
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import { ALLOWED_MODERATE_TESTS } from 'rightsController';
import NotFound from 'pages/common/NotFound';

const TestsRoutes = () => {
    const renderNewtest = (props) => {
        return <Test {...props} type={'new'} />;
    };

    const renderEditTest = ({ match, location }) => {
        const { params } = match;
        return <Test type={'edit'} location={location} id={params.id} />;
    };

    return (
        <Switch>
            <ProtectedRoute
                exact
                path={testsRoute.url}
                component={TestList}
                allowedRoles={ALLOWED_MODERATE_TESTS}
            />
            <ProtectedRoute
                exact
                path={testEditRoute.url}
                component={renderEditTest}
                allowedRoles={ALLOWED_MODERATE_TESTS}
            />
            <ProtectedRoute
                exact
                path={testNewRoute.url}
                component={renderNewtest}
                allowedRoles={ALLOWED_MODERATE_TESTS}
            />
            <Route component={NotFound} />
        </Switch>
    );
};

export default TestsRoutes;
