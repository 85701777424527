import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { ACTIVE_LIST_CARD, EVALUATION_TYPE } from 'components/Lk/Basic/constants';
import { lkDetailsEvaluateTeam } from 'routes';
import EvaluationCardItemBlock from './EvaluationCardItemBlock';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';
import EvaluationType from './EvaluationType/EvaluationType';
import { setSelectes } from 'ducks/Evaluation';
import './EvaluateSelect.scss';

const EvaluateSelect = (props) => {

    const { push, activeCard, setActiveCard } = props;
    
    const nextCard = (url) => {
        push(url);
    };

    setActiveCard(ACTIVE_LIST_CARD);
    const isMobileActive = activeCard === ACTIVE_LIST_CARD; 

    const handleBlockClick = key => id => () => {
        const param = {id,key}
        props.setSelectes(param);
        nextCard(lkDetailsEvaluateTeam.url)
    }

    const renderEvaluationType = obg => {
        const result = obg.filter(item => item.forEvaluation);

        return result.map((item, key) => (
            <EvaluationCardItemBlock grey key={item.id}>
                <EvaluationType {...item} tooltip
                    onClick={handleBlockClick(key)}
                    icon="evaluateType"
                    />
            </EvaluationCardItemBlock>)
        )
    }
    
    return (
        <div className={classnames('ListCard', { 'ListCard--isMobileActive': isMobileActive})}>
            <MainCardMenu />
            <div className="EvaluateSelect">
                <div className="EvaluateSelect__Item">
                    <h3 className="LKLayout__Title">Выберите тип оценки</h3>
                        {renderEvaluationType(EVALUATION_TYPE)}
                </div>
            </div>
        </div>
    );
};

EvaluateSelect.propTypes = {
    activeCard: PropTypes.string.isRequired,
    setActiveCard: PropTypes.func.isRequired,
};

const mapStateToProps = state => (
    { 
        personId: state.auth.user.personId, 
        selectedEvaluation: state.evaluation.selected,
        basket: state.baskets.evaluate.basket.persons,
    }
);

const actions = { push, setSelectes };

export default connect(mapStateToProps, actions)(EvaluateSelect);