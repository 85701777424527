import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { showSuccessAlert, showErrorAlert, showWarningAlert } from 'ducks/Alert';
import { serviceResultCode, getError } from 'serviceErrors';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import {
    getPersonGrowthResourceById,
    createPersonGrowthResource,
    updatePersonGrowthResource,
    uploadResourcePhoto,
    getPersonGrowthResourcePhotoUri,
    getPersonGrowthResourceProviders,
    getCompetenceIndicatorsTree,
} from 'api';
import { trimObjectStrings, isNullOrWhitespace } from 'utils';
import { personGrowthResourceRoute, personGrowthResourcesRoute } from 'routes';
import memoize from 'fast-memoize';
import { resourceTypes as resourceTypesImported, SOURCE_TYPE } from 'constants.js';
import Button from 'components/uikit/Button';
import Label from 'components/uikit/Label';
import Select from 'components/uikit/Select';
import Input from 'components/uikit/Input';
import Field from 'components/uikit/Field';
import Page from 'components/common/Page';
import Image from 'components/common/Image';
import PhotoBlock from 'components/common/PhotoBlock';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ScheduleRecords from './ScheduleRecords';
import { educationTypes, resourceTypeEnum } from 'constants.js';
import './PersonGrowthResource.scss';
import IndicatorsTree from 'pages/manager/moderation/competencies/IndicatorsTree.js';
import ModalDialog from 'components/common/ModalDialog';
import Chip from 'components/uikit/Chip';

const initialState = {
    loadPersonGrowthIndicatorsComplete: false,
    loadProvidersComplete: false,
    loadResourceComplete: false,

    indicatorsTree: [],
    resourceTypes: [],
    providers: [],
    isSubmited: false,

    resource: {
        id: null,
        name: null,
        link: '',
        type: null,
        previewId: null,
        indicatorIds: [],
        description: null,
        providerId: null,
        educationType: null,
        sourceType: null,
    },

    refetchKey: null,
    isDisabled: true,
    saving: false,
    canLoadImage: true,

    indicatorsModalOpen: false,
    selectedIndicators: [],
};

class PersonGrowthResource extends Component {
    state = initialState;
    _mounted = true;

    async componentDidMount() {
        await this.performTreeFetch();
        this.performPersonGrowthResourceProvidersFetching();

        this.setState({
            resourceTypes: resourceTypesImported.filter((x) => !x.hidden),
        });

        if (this.props.match.params.regime.includes('new')) {
            this.setState({
                loadResourceComplete: true,
                resource: { ...this.state.resource, id: this.props.match.params.id },
                canLoadImage: false,
            });
        } else {
            this.setState({
                canLoadImage: true,
            });
            this.performPersonGrowthResourceFetching();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { loadResourceComplete } = this.state;
        const { regime } = this.props.match.params;
        const isEditMode = regime === 'edit' || regime === 'new';

        if (prevState.loadResourceComplete !== loadResourceComplete && loadResourceComplete) {
            this.setState({ isDisabled: this.isDisabled(isEditMode) });
        }
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    async performTreeFetch() {
        const { showPageLoader, hidePageLoader, showErrorAlert } = this.props;
        try {
            showPageLoader();
            const response = await getCompetenceIndicatorsTree();
            const updatedIndicators = response.data.map((x) => ({
                value: x.id,
                label: x.name,
                children: x.competencies.map(({ id: value, name: label, indicators }) => ({
                    value,
                    label,
                    children: indicators.map(({ id: value, name: label }) => ({
                        value,
                        label,
                    })),
                })),
            }));

            this.setState({ indicatorsTree: updatedIndicators });
        } catch (error) {
            showErrorAlert(error.message);
        } finally {
            this.setState({ loadPersonGrowthIndicatorsComplete: true });
            hidePageLoader();
        }
    }

    getIndicatorById = (id) => {
        const { indicatorsTree } = this.state;
        let res = null;
        for (var i = 0; i < indicatorsTree.length; i++) {
            indicatorsTree[i].children.map((x) => {
                const indicator = x?.children.find((ind) => ind.value === id);
                if (indicator) {
                    res = { ...indicator, parent: x.value, groupId: indicatorsTree[i].value };
                    return res;
                }
            });
        }
        return res;
    };

    clearIndicators = () => {
        this.setState((state) => ({
            resource: { ...state.resource, indicatorIds: initialState.resource.indicatorIds },
        }));
    };

    removeIndicator = (id) => {
        this.setState((state) => ({
            resource: { ...state.resource, indicatorIds: state.resource.indicatorIds.filter((x) => x != id) },
        }));
    };

    indicatorsBlock = (isEditMode) => {
        const { resource } = this.state;
        return (
            <div>
                <div className="CompetenceCard__Chips">
                    {resource?.indicatorIds?.map((x) => (
                        <Chip
                            text={this.getIndicatorById(x)?.label || 'Error'}
                            key={x}
                            canDelete={isEditMode}
                            onDelete={() => this.removeIndicator(x)}
                        />
                    ))}
                </div>
                {isEditMode && (
                    <div className="CompetenceCard__IndicatorsActions">
                        <Button editLink onClick={this.openModal}>
                            Редактировать
                        </Button>
                        <Button clearLink onClick={this.clearIndicators}>
                            Очистить все
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    noIndicatorsBlock = (isEditMode) => {
        return (
            isEditMode && (
                <div>
                    <div className="CompetenceCard__HelpText CompetenceCard__HelpText--invalid">
                        Нужно добавить минимум 1 индикатор
                    </div>
                    <div className="CompetenceCard__IndicatorsActions">
                        <Button onClick={this.openModal} size="sm">
                            Добавить
                        </Button>
                    </div>
                </div>
            )
        );
    };

    setSelectedIndicators = (selectedIndicators) => {
        this.setState({ selectedIndicators });
    };

    getExpanded = () => {
        const {
            resource: { indicatorIds },
        } = this.state;
        let expanded = [];

        indicatorIds.forEach((x) => {
            const indicator = this.getIndicatorById(x);
            if (indicator) {
                expanded.push(indicator.parent, indicator.groupId);
            }
        });
        return expanded;
    };

    openModal = () => {
        this.setState((state) => ({
            selectedIndicators: state.resource.indicatorIds,
            indicatorsModalOpen: true,
        }));
    };

    setResourceIndicators = () => {
        this.setState((state) => ({
            resource: { ...state.resource, indicatorIds: state.selectedIndicators },
            indicatorsModalOpen: false,
        }));
    };

    performPersonGrowthResourceFetching = () => {
        this.callEffect(
            async () => {
                const response = await getPersonGrowthResourceById(this.props.match.params.id);
                if (response && response.status === 200) {
                    response.data.type =
                        response.data.type === resourceTypeEnum.Microcourse
                            ? resourceTypeEnum.CourseScormTincan
                            : response.data.type;
                    this.setState({
                        resource: response.data,
                        loadResourceComplete: true,
                    });
                }
            },
            { withPageLoader: true },
        );
    };

    performPersonGrowthResourceProvidersFetching = () => {
        this.callEffect(
            async () => {
                const response = await getPersonGrowthResourceProviders();

                if (response && response.status === 200) {
                    this.setState({
                        providers: response.data.map((x) => ({ ...x, name: x.title })),
                        loadProvidersComplete: true,
                    });
                }
            },
            { withPageLoader: true },
        );
    };

    callEffect = async (callback, { withPageLoader } = {}) => {
        const { showPageLoader, hidePageLoader, showErrorAlert } = this.props;
        withPageLoader && showPageLoader();
        try {
            await callback();
        } catch (error) {
            if (error.response && error.response.status === 422) {
                const fileErrorCodes = error.response.data;
                if (fileErrorCodes && fileErrorCodes.formatUnsupported) {
                    showErrorAlert(
                        'Неподдерживаемый формат изображения. Поддерживаются форматы JPEG и PNG',
                    );
                } else if (fileErrorCodes && fileErrorCodes.tooLarge) {
                    let maxSize = (fileErrorCodes.tooLarge.maxSize / (1024 * 1024)).toFixed(2);
                    showErrorAlert(`Фото превышает лимит в ${maxSize} Мб`);
                }
            }
            const reqError = getError(error, this.getResourceError);
            showErrorAlert(reqError.message);
        } finally {
            withPageLoader && hidePageLoader();
            this._mounted && this.setState({ saving: false });
        }
    };

    getResourceError = (code, payload) => {
        switch (code) {
            case serviceResultCode.NotFound:
                return `${payload}`;
            case serviceResultCode.PersonGrowthResourceError:
                return `${payload}`;
            case serviceResultCode.PersonGrowthResourceNameRequired:
                return `Не указано название инструмента развития`;
            case serviceResultCode.PersonGrowthResourceTypeRequired:
                return `Не указан тип инструмента развития`;
            case serviceResultCode.PersonGrowthResourceLinkRequired:
                return `Не указана ссылка на инструмент развития`;
            case serviceResultCode.PersonGrowthResourceAlreadyExists:
                return `Инструмент развития с таким названием уже существует`;
            default:
                return `Произошла непредвиденная ошибка`;
        }
    };

    handleStateChange = (stateKey, value, block = '') => {
        this.setState((state) => {
            return block
                ? { ...state, [block]: { ...state[block], [stateKey]: value } }
                : { ...state, [stateKey]: value };
        });
    };

    fillSelect = (dictionary) => {
        if (dictionary) {
            return dictionary.map((x) => {
                return { value: x.id, label: x.name };
            });
        } else {
            return [];
        }
    };

    isValidUrl = (string) => {
        if (this.state.resource?.sourceType === SOURCE_TYPE.Alpina) {
            return true;
        }
        const urlRegExp = new RegExp(
            /^((https?|ftp):\/\/)?([a-z0-9])((\.[a-z0-9-])|([a-z0-9-]))*\.([a-z])(\/?)S*/,
        );
        if (string.match(urlRegExp)) {
            return true;
        }
        return false;
    };

    changeUrl = (string) => {
        const http = RegExp(/^(https|http|ftp)(:|\.)/);
        if (!string.match(http) || isNullOrWhitespace(this.state.resource.link)) {
            string = 'https://' + string;
            this.setState({
                resource: {
                    ...this.state.resource,
                    link: string,
                },
            });
        }
    };

    getValidateErrors = (isEducationType) => {
        const errors = [];

        if (!this.state.resource.name) {
            errors.push('Не указано название инструмента развития');
        }

        if (!this.state.resource.type) {
            errors.push('Не указан тип инструмента развития');
        }

        if (!this.state.resource.indicatorIds || !this.state.resource.indicatorIds.length > 0) {
            errors.push('Не указаны теги инструмента развития');
        }

        if (!this.state.resource.link) {
            errors.push('Не указана ссылка на инструмент развития');
        } else if (!this.isValidUrl(this.state.resource.link)) {
            errors.push('Указана неверная ссылка');
        }

        if (isEducationType && !this.state.resource.educationType) {
            errors.push('Не указан тип программы');
        }

        if (isEducationType && !this.state.resource.providerId) {
            errors.push('Не указано кто проводит обучение');
        }
        return errors;
    };

    onSubmit = async (e, isEducationType) => {
        e.preventDefault();
        this.setState({ isSubmited: true, saving: true });
        const errors = this.getValidateErrors(isEducationType);

        if (errors.length > 0) {
            this.props.showWarningAlert('Не все поля корректно заполнены.');
            this.setState({ saving: false });
            return;
        }

        if (this.props.match.params.regime === 'new') {
            this.callEffect(async () => {
                await createPersonGrowthResource(trimObjectStrings(this.state.resource));
                this.setState({ saving: false, canLoadImage: false });
                return this.props.push({
                    pathname: personGrowthResourceRoute.buildUrl({
                        id: this.state.resource.id,
                        regime: 'edit',
                    }),
                    search: '?page=1',
                });
            });
        }

        if (this.props.match.params.regime === 'edit') {
            this.callEffect(async () => {
                const response = await updatePersonGrowthResource(
                    trimObjectStrings(this.state.resource),
                );

                if (response && response.status === 200) {
                    this.props.showSuccessAlert('Инструмент развития успешно сохранен');
                    this.setState({ saving: false });
                    return this.returnToMain();
                }
            });
        }
    };

    handlePhotoOpened = (resourceId) => async (photoFile) => {
        if (!photoFile) {
            return;
        }

        if (resourceId) {
            await this.callEffect(
                async () => {
                    const response = await uploadResourcePhoto(resourceId, photoFile);

                    if (response && response.status === 200) {
                        this.setState({
                            ...this.state,
                            resource: {
                                ...this.state.resource,
                                previewId: response.data,
                            },
                            refetchKey: new Date().getTime(),
                            canLoadImage: true,
                        });
                        this.props.showSuccessAlert('Фото инструмента развития успешно сохранено');
                    }
                },
                { withPageLoader: false },
            );
        }
    };

    handlePersonGrowthResourcePreviewOpened = memoize((resourceId) =>
        this.handlePhotoOpened(resourceId || null),
    );

    renderPhotoBlock = (resourceId, regime) => {
        const { canLoadImage } = this.state;
        if (regime === 'edit') {
            const refetchKey = this.state.refetchKey;
            const photoUri = canLoadImage
                ? getPersonGrowthResourcePhotoUri(resourceId, refetchKey)
                : null;
            return (
                <div className="ResourceImage_Image_resized">
                    <PhotoBlock
                        id={`photo-block_${resourceId}`}
                        photoUri={photoUri}
                        photoLink={photoUri}
                        onPhotoOpened={this.handlePersonGrowthResourcePreviewOpened(resourceId)}
                        readOnly={this.state.isDisabled}
                    />
                </div>
            );
        } else {
            const photoUri = canLoadImage
                ? `api/personGrowthResource/${resourceId}/file/download`
                : null;
            return <Image className="ResourceImage__Image_viewSize" photoUri={photoUri} />;
        }
    };

    handleNameInputChange = (e) => {
        this.handleStateChange('name', e.target.value, 'resource');
    };

    isDisabled = (isEditMode = false) => {
        const {
            resource: { sourceType },
        } = this.state;
        return !isEditMode || (isEditMode && [SOURCE_TYPE.Alpina].indexOf(sourceType) >= 0);
    };

    renderNameInput = (resourceName, isEditMode) => {
        return (
            <Field
                filled={resourceName}
                required={isEditMode}
                invalid={this.state.isSubmited && !resourceName}
            >
                <Label>Название</Label>
                <Input
                    value={resourceName || ''}
                    maxLength="200"
                    onChange={this.handleNameInputChange}
                    disabled={this.state.isDisabled}
                    data-test="PersonGrowthResourceName--input"
                />
            </Field>
        );
    };

    handleLinkInputChange = (e) => {
        this.handleStateChange('link', e.target.value, 'resource');
    };

    renderLinkInput = (resourceLink, isEditMode) => {
        return (
            <Field
                filled={resourceLink}
                required={isEditMode}
                invalid={this.state.isSubmited && (!resourceLink || !this.isValidUrl(resourceLink))}
            >
                <Label>Ссылка</Label>
                <Input
                    value={resourceLink}
                    maxLength="200"
                    onChange={this.handleLinkInputChange}
                    disabled={this.state.isDisabled}
                    onKeyUp={(e) => resourceLink && this.changeUrl(e.target.value)}
                    onBlur={(e) => resourceLink && this.changeUrl(e.target.value)}
                    data-test="PersonGrowthResourceLink--input"
                />
            </Field>
        );
    };

    renderResourceType(resourceType, isEditMode, resourceTypes) {
        const updateResourceTypes = (item) => {
            if (item) {
                this.setState({
                    resource: {
                        ...this.state.resource,
                        type: resourceTypes.find((x) => x.name === item.label).type,
                        educationType: null,
                        providerId: null,
                    },
                });
            }
        };

        const getFriendlyName = (resourceType) => {
            if (resourceType) {
                const searchType =
                    resourceType === resourceTypeEnum.Microcourse
                        ? resourceTypeEnum.CourseScormTincan
                        : resourceType;
                return resourceTypes.find((i) => i.type === searchType).name;
            }
        };

        if (isEditMode) {
            return (
                <Field
                    filled={resourceType}
                    required={isEditMode}
                    invalid={this.state.isSubmited && !resourceType}
                >
                    <Label>Тип</Label>
                    <Select
                        placeholder={<div>Выберите тип</div>}
                        options={
                            resourceTypes &&
                            Array.isArray(resourceTypes) &&
                            this.fillSelect(resourceTypes)
                        }
                        value={
                            resourceType &&
                            this.fillSelect([resourceTypes.find((x) => x.type === resourceType)])
                        }
                        onChange={updateResourceTypes}
                        dataTest="PersonGrowthResourceType"
                        isDisabled={this.state.isDisabled}
                    />
                </Field>
            );
        } else {
            return (
                <Field
                    filled={resourceType}
                    required={isEditMode}
                    invalid={this.state.isSubmited && !resourceType}
                >
                    <Label>Тип</Label>
                    <Input
                        value={getFriendlyName(resourceType)}
                        disabled={this.state.isDisabled}
                        data-test={`PersonGrowthResourceType--${isEditMode ? 'input' : 'text'}`}
                    />
                </Field>
            );
        }
    }

    renderProviderSelector = (resourceProviderId, isEditMode, providers, regime) => {
        const handleProviderId = (item) => {
            if (item) {
                this.handleStateChange(
                    'providerId',
                    providers.find((x) => x.id === item.value).id,
                    'resource',
                );
            }
        };

        const renderProviderData = () => {
            const provider = providers.find((x) => x.id === resourceProviderId);
            return (
                <>
                    <Row>
                        <Col xs={12}>
                            <Field>
                                <Label>ФИО контактного лица</Label>
                                <Input
                                    value={(provider && provider.agentName) || ''}
                                    disabled={true}
                                    data-test="PersonGrowthResourceProviderFullName--text"
                                />
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Field>
                                <Label>Телефон</Label>
                                <Input
                                    value={(provider && provider.phone) || ''}
                                    disabled={true}
                                    data-test="PersonGrowthResourceProviderPhone--text"
                                />
                            </Field>
                        </Col>
                        <Col xs={6}>
                            <Field>
                                <Label>Email</Label>
                                <Input
                                    value={(provider && provider.email) || ''}
                                    disabled={true}
                                    data-test="PersonGrowthResourceProviderEmail--text"
                                />
                            </Field>
                        </Col>
                    </Row>
                </>
            );
        };

        if (isEditMode) {
            return (
                <>
                    <Row>
                        <Col xs={12}>
                            <Field
                                filled={resourceProviderId}
                                required
                                invalid={this.state.isSubmited && !resourceProviderId}
                            >
                                <Label>Кто проводит</Label>
                                <Select
                                    placeholder={<div>Выберите провайдера обучения</div>}
                                    options={providers && this.fillSelect(providers)}
                                    value={
                                        providers &&
                                        resourceProviderId &&
                                        this.fillSelect([
                                            providers.find((x) => x.id === resourceProviderId),
                                        ])
                                    }
                                    onChange={handleProviderId}
                                    dataTest="PersonGrowthResourceProvider"
                                    isDisabled={this.state.isDisabled}
                                />
                            </Field>
                        </Col>
                    </Row>
                    {regime === 'edit' && renderProviderData()}
                </>
            );
        } else {
            return (
                <>
                    <Row>
                        <Col xs={12}>
                            <Field
                                filled={resourceProviderId}
                                required={isEditMode}
                                invalid={this.state.isSubmited && !resourceProviderId}
                            >
                                <Label>Кто проводит</Label>
                                <Input
                                    value={
                                        providers &&
                                        resourceProviderId &&
                                        providers.find((x) => x.id === resourceProviderId).title
                                    }
                                    disabled={this.state.isDisabled}
                                    data-test={`PersonGrowthResourceProvider--${
                                        isEditMode ? 'input' : 'text'
                                    }`}
                                />
                            </Field>
                        </Col>
                    </Row>
                    {renderProviderData()}
                </>
            );
        }
    };

    renderEducationTypeSelector = (educationType, isEditMode, educationTypes) => {
        const handleEducationType = (item) => {
            if (item) {
                this.handleStateChange(
                    'educationType',
                    educationTypes.find((x) => x.id === item.value).type,
                    'resource',
                );
            }
        };

        if (isEditMode) {
            return (
                <Field
                    filled={educationType}
                    required
                    invalid={this.state.isSubmited && !educationType}
                >
                    <Label>Тип обучения</Label>
                    <Select
                        placeholder={<div>Выберите тип обучения</div>}
                        options={educationTypes && this.fillSelect(educationTypes)}
                        value={
                            (educationType &&
                                this.fillSelect([
                                    educationTypes.find((x) => x.type === educationType),
                                ])) ||
                            ''
                        }
                        onChange={handleEducationType}
                        isDisabled={this.state.isDisabled}
                        dataTest="PersonGrowthResourceEducationType"
                    />
                </Field>
            );
        } else {
            return (
                <Field
                    filled={educationType}
                    required={isEditMode}
                    invalid={this.state.isSubmited && !educationType}
                >
                    <Label>Тип обучения</Label>
                    <Input
                        value={
                            educationTypes &&
                            educationType &&
                            educationTypes.find((x) => x.type === educationType).name
                        }
                        disabled={this.state.isDisabled}
                        data-test={`PersonGrowthResourceEducationType--${
                            isEditMode ? 'input' : 'text'
                        }`}
                    />
                </Field>
            );
        }
    };

    returnToMain = () => {
        this.props.push({
            pathname: personGrowthResourcesRoute.url,
            search: this.props.location.search,
        });
    };

    renderButtons = (regime, isEducationType) => {
        const onClearFilters = (e) => {
            e.preventDefault();
            this.setState({ initialState }, () => this.returnToMain());
        };

        if (regime === 'new') {
            return (
                <Row className="ResourceButtonsLine">
                    <div className="ResourceButtonsLine__Btn">
                        <Button
                            loading={this.state.saving}
                            type="button"
                            onClick={(e) => this.onSubmit(e, isEducationType)}
                            data-test="PersonGrowthResourceNext--button"
                        >
                            Далее
                        </Button>
                        <Button
                            type="button"
                            onClick={onClearFilters}
                            closeLink
                            data-test="PersonGrowthResourceCancel--button"
                        >
                            Отменить
                        </Button>
                    </div>
                </Row>
            );
        } else if (regime === 'edit') {
            return (
                <Row className="ResourceButtonsLine">
                    <div className="ResourceButtonsLine__Btn">
                        <Button
                            loading={this.state.saving}
                            type="button"
                            onClick={(e) => this.onSubmit(e, isEducationType)}
                            data-test="PersonGrowthResourceSave--button"
                        >
                            Сохранить
                        </Button>
                    </div>
                </Row>
            );
        } else if (regime === 'view') {
            return (
                <Row className="ResourceButtonsLine">
                    <div className="ResourceButtonsLine__Btn">
                        <Button
                            type="button"
                            onClick={() => this.returnToMain()}
                            data-test="PersonGrowthResourceReturn--button"
                        >
                            Вернуться
                        </Button>
                    </div>
                </Row>
            );
        }
    };

    render() {
        if (
            !this.state.loadResourceComplete ||
            !this.state.loadPersonGrowthIndicatorsComplete ||
            !this.state.loadProvidersComplete
        ) {
            return null;
        }

        const { resource, resourceTypes, providers } = this.state;
        const { regime } = this.props.match.params;
        const isEditMode = regime === 'edit' || regime === 'new';
        const isEducationType =
            resource.type === 'DistanceEducation' ||
            resource.type === 'FullTimeEducation' ||
            resource.type === 'Conference';
        return (
            <Grid fluid>
                <Page>
                    <Col xs={12}>
                        <div className="Resource">
                            <div className="ResourceLine__Header">Инструмент развития</div>
                            <div className="ResourceLine__Divider">
                                <hr />
                            </div>
                            <Row className="ResourceContainer">
                                <Col
                                    xs={10}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={4}
                                    className="ResourceImage__Container"
                                >
                                    <div className="ResourceImage">
                                        {this.renderPhotoBlock(resource.id, regime)}
                                    </div>
                                </Col>
                                <Col xs={9} sm={12} md={12} lg={12} xl={8}>
                                    <Row className="ResourceLine">
                                        <div className="ResourceLine__Input">
                                            {this.renderNameInput(resource.name, isEditMode)}
                                        </div>
                                    </Row>
                                    <Row className="ResourceLine">
                                        <div className="ResourceLine__Input">
                                            {this.renderResourceType(
                                                resource.type,
                                                isEditMode,
                                                resourceTypes,
                                            )}
                                        </div>
                                    </Row>

                                    <Row className="ResourceLine">
                                        <div className="ResourceLine__Input">
                                            {this.renderLinkInput(resource.link, isEditMode)}
                                        </div>
                                    </Row>

                                    <div className="CompetenceCard__Line">
                                        <Field>
                                            <Label>Поведенческие индикаторы </Label>
                                            {resource?.indicatorIds?.length !== 0
                                                ? this.indicatorsBlock(isEditMode)
                                                : this.noIndicatorsBlock(isEditMode)}
                                        </Field>
                                    </div>

                                    {isEducationType &&
                                        this.renderEducationTypeSelector(
                                            resource.educationType,
                                            isEditMode,
                                            educationTypes,
                                        )}
                                    {isEducationType &&
                                        this.renderProviderSelector(
                                            resource.providerId,
                                            isEditMode,
                                            providers,
                                            regime,
                                        )}
                                </Col>
                            </Row>
                            {this.renderButtons(regime, isEducationType)}
                        </div>
                        {regime !== 'new' && isEducationType && (
                            <ScheduleRecords
                                resourceId={this.props.match.params.id}
                                regime={this.props.match.params.regime}
                            />
                        )}
                    </Col>
                </Page>

                <ModalDialog
                    size="lg"
                    modalHeader="Поведенческие индикаторы"
                    modalOpen={this.state.indicatorsModalOpen}
                    onClick={this.setResourceIndicators}
                    onCloseModal={() => this.setState({ indicatorsModalOpen: false })}
                    processing={this.state.isSaving}
                    btnOktext="Применить"
                    btnCanceltext="Отмена"
                >
                    <IndicatorsTree
                        indicatorsTree={this.state.indicatorsTree}
                        expanded={this.getExpanded()}
                        setChecked={this.setSelectedIndicators}
                        checked={this.state.selectedIndicators}
                    />
                </ModalDialog>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        search: state.router.location.search,
    };
};

const actions = {
    showPageLoader,
    hidePageLoader,
    showSuccessAlert,
    showErrorAlert,
    showWarningAlert,
    push,
};
export default connect(mapStateToProps, actions)(PersonGrowthResource);
