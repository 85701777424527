import React from 'react';

const ChartTooltip = props => {
    const { criteria, values } = props;

    const renderCriteria = val => {
        const { title } = criteria[val];
        const [leftTitle, rightTitle] = title.split(/[-––]/);
        return (
            <div className="LKSignalsValueLineChart" key={val}>
                <div className="LKSignalsValueLineChart__Text LKSignalsValueLineChart__Text--right">
                    {leftTitle}
                </div>
                <div className="LKSignalsSeparator">
                    <div className="LKSignalsSeparator__Item" />
                </div>
                <div className="LKSignalsValueLineChart__Text">{rightTitle}</div>
            </div>
        );
    };
    const renderSeparators = () => {
        return (
            <div className="LKChartTooltip">
                <div className="LKSignalsSeparatorLineVal">
                    <span className="LKSignalsSeparatorLineVal__Span LKSignalsSeparatorLineVal__Span--left">
                        1
                    </span>
                    <span className="LKSignalsSeparatorLineVal__Span">5</span>
                    <span className="LKSignalsSeparatorLineVal__Span LKSignalsSeparatorLineVal__Span--right">
                        10
                    </span>
                </div>

                <div className="LKSignalsSeparatorLine LKSignalsSeparatorLine--tooltip">
                    <div className="LKSignalsSeparatorLine__Item" />
                </div>
                <div className="LKSignalsValueLineChart">
                    <div className="LKSignalsSeparator">
                        <div className="LKSignalsSeparator__Item" />
                    </div>
                </div>

                {values.map(renderCriteria)}
            </div>
        );
    };

    return renderSeparators();
};

export default ChartTooltip;
