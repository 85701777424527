import React from 'react';
import ToggleOpen from 'components/hoc/ToggleOpen';
import LinkButton from 'components/uikit/LinkButton';

import SearchMenuJSON from './SearchMenuJSON';
import RenderDataCard from './renderDataCard';

import './Search.scss';
import { getEvaluationLabel } from './QueryOperation';

const EvaluationTag = props => {
    return (
        <div className="EvaluationTag">
            <div className="EvaluationTag__Title" onClick={() => props.onSelect(props.id)}>
                {getEvaluationLabel(props)}
            </div>
            <div className="EvaluationTag__Action">
                <LinkButton
                    close
                    onClick={e => {
                        e.preventDefault();
                        props.onRemove(props.id);
                    }}
                    type="button"
                ></LinkButton>
            </div>
        </div>
    );
};

const EvaluationBlock = props => {
    return (
        <RenderDataCard
            obj={SearchMenuJSON.EvaluationBlock}
            {...props}
            tagName={EvaluationTag}
            onRemove={props.onRemove}
            onSelect={props.onSelect}
        />
    );
};

export default ToggleOpen(EvaluationBlock);
