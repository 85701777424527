import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import { capitalizeFirstLetter, isSameOrAfter } from 'utils';
import { quotasNewRoute, quotasEditRoute, quotasViewRoute } from 'routes';
import { getQuotasInfo } from 'api';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import Button from 'components/uikit/Button';
import RoundButton from 'components/uikit/Button/RoundButton';
import './Quotas.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Quotas extends Component {
    state = {
        quotas: [],
        loaded: false,
        canCreateNewQuotas: false,
    };

    renderHeader = () => {
        return (
            <div className="Quotas-Container__Header Quotas-Container__Header--aside">
                <div className="Col-50">
                    <div className="Header">Модерация квот</div>
                </div>
                {this.state.canCreateNewQuotas && (
                    <div>
                        <Button size="sm" onClick={this.newQuotas}>
                            Распределить квоты
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    renderQuotas = () => {
        return (
            <div className="Quotas-List">
                <div className="Quotas-List__Header">
                    <div className="Col-25">Год</div>
                    <div className="Col-25 Col-25--right">Распределено</div>
                    <div className="Col-25 Col-25--right">Использовано</div>
                    <div className="Col-20 Col-20--right">Остаток</div>
                    <div className="Col-5 Col-5--right"></div>
                </div>
                <div className="Quotas-List__Body Quotas-List__Body--no-footer">
                    {this.renderQuotasList()}
                </div>
            </div>
        );
    };

    renderQuotasList = () => {
        return this.state.quotas.map((x) => {
            const canEditQuota =
                !x.exisingQuotaInfo.isCommitted && isSameOrAfter(x.year, new Date());
            const quota = x.exisingQuotaInfo.existingQuota;
            return (
                <div className="Line" key={quota.id}>
                    <div className="Col-25">
                        <Link
                            to={
                                canEditQuota
                                    ? quotasEditRoute.buildUrl({ id: quota.id })
                                    : quotasViewRoute.buildUrl({ id: quota.id })
                            }
                        >
                            {capitalizeFirstLetter(moment(x.year).format('YYYY'))}
                        </Link>
                    </div>
                    <div className="Col-25 Col-25--right">
                        {quota.totalAmount + x.parentGroupCorrectionsAmount}
                    </div>
                    <div className="Col-25 Col-25--right">
                        {!quota.finalizedOn
                            ? 0
                            : quota.totalAmount +
                              x.parentGroupCorrectionsAmount -
                              this.countUsedQuotas(quota?.groupQuotaPointBalances)}
                    </div>
                    <div className="Col-20 Col-20--right">
                        {!quota.finalizedOn
                            ? quota.totalAmount
                            : this.countUsedQuotas(quota?.groupQuotaPointBalances)}
                    </div>
                    <div className="Col-5 Col-5--right">
                        {canEditQuota ? (
                            <RoundButton
                                type="edit"
                                onClick={() =>
                                    this.props.push(quotasEditRoute.buildUrl({ id: quota.id }))
                                }
                            />
                        ) : (
                            <Link
                                to={quotasViewRoute.buildUrl({ id: quota.id })}
                                className="Action-Btn"
                            >
                                <FontAwesomeIcon icon="arrow-circle-right"></FontAwesomeIcon>
                            </Link>
                        )}
                    </div>
                </div>
            );
        });
    };

    countUsedQuotas = (balances) => {
        const balanceKeys = Object.keys(balances);
        let balance = 0;
        for (const key of balanceKeys) {
            balance += balances[key];
        }
        return balance;
    };

    newQuotas = () => {
        this.props.push(quotasNewRoute.url);
    };

    editQuotas = (id) => {
        return () => {
            this.props.push(quotasEditRoute.buildUrl({ id: id }));
        };
    };

    renderFooter = () => {
        return <div className="Quotas-Container__Footer"></div>;
    };

    render() {
        return (
            <div className="Quotas-Container">
                {this.renderHeader()}
                <div className="Quotas-Container__Body">
                    {!!this.state.quotas.length
                        ? this.renderQuotas()
                        : this.state.loaded && <div className="No-Data">Данные не найдены</div>}
                </div>
                {this.renderFooter()}
            </div>
        );
    }

    componentDidMount() {
        this.loadQuotas();
    }

    loadQuotas = async () => {
        const quotas = await this.callEffect(() => getQuotasInfo(false));
        this.setState((state) => {
            return {
                ...state,
                quotas: this.filterActiveQuotas(quotas.data),
                loaded: true,
                canCreateNewQuotas: this.canCreateNewQuotas(quotas.data),
            };
        });
    };

    filterActiveQuotas = (quotas) => {
        return quotas.filter((x) => x.exisingQuotaInfo);
    };

    canCreateNewQuotas = (quotas) => {
        return quotas.find((x) => x.status === 'QuotaNotCreated');
    };

    callEffect = async (callback) => {
        this.props.showPageLoader();
        try {
            return await callback();
        } catch (error) {
            this.props.showErrorAlert(error.message);
        } finally {
            this.props.hidePageLoader();
        }
    };
}

const actions = {
    push,
    showPageLoader,
    hidePageLoader,
    showErrorAlert,
};

export default connect(null, actions)(Quotas);
