import React, { Component } from 'react';
import { push, replace } from 'connected-react-router';
import { connect } from 'react-redux';
import { fetchAmount, fetchSelect, setSearchData, setCriteria } from 'ducks/ExternalSearch';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
    fetchCatalog,
    INDUSTRIES,
    FEDERAL_DISTRICTS,
    REGIONS,
    WORK_AREAS,
    MANAGEMENT_LEVELS,
    MANAGEMENT_EXPERIENCES,
    EMPLOYEES_NUMBERS,
    STUDY_FORMS,
    EDUCATIONAL_LEVELS,
    SEX,
    IDENTITY_DOCUMENTS,
    FAMILY_STATUS,
    CHILDREN_STATUS,
    EXPERIENCE_LEVELS,
    DATA_ORIGINS,
    LANGUAGES,
    LANGUAGE_LEVELS,
} from 'ducks/Catalog';
import { fetchDictionaries } from 'ducks/Dictionary';
import { showErrorAlert } from 'ducks/Alert';
import InfiniteScroll from 'react-infinite-scroller';
import { ACTIVE_LIST_CARD } from 'components/Lk/Basic/constants';
import {
    FILTERS_FILTER_MENU_ID,
    SEARCH_TEMPLATE_MENU_ID,
    FAVORITES_MENU_ID,
    SEARCH_TERM_FILTER,
    filtersMenu,
} from './constants';
import SearchResultCard from './SearchResultCard';
import SearchCardFiltersMenu from './SearchCardFiltersMenu';
import SearchCardFilter from './SearchCardFilter';
import RoundedButton from 'components/Lk/Uikit/Buttons/RoundedButton';
import GoBackButton from 'components/Lk/Uikit/Buttons/GoBackButton';
import Input from 'components/Lk/Uikit/Input';
import './SearchCard.scss';
import Loader from 'components/common/Loader';
import StickyFooter from 'components/Lk/Common/StickyFooter';
import ActiveFilters from './ActiveFilters';
import queryString from 'query-string';
import { getSearchTemplate, getSearchRequest } from 'api';
import { compose } from 'redux';
import {
    setMainFilter,
    setMenuId,
    setFilterId,
    setExtraFields,
    setSearchTerm,
    setDynamicSearchTerm,
} from 'ducks/FilterSearch';
import pako from 'pako';
import EventMenu from 'components/Lk/Common/EventMenu';
import PersonMassActionMenu from 'components/Lk/Common/EventMenu/PersonMassActionMenu';
import { MENU_ACTION } from 'components/Lk/Common/EventMenu/constants';
import ManageWishlists from './ManageWishlists';
import { inversionOperation, queryOperation } from 'components/Search/QueryOperation';
import { FIELDS_TYPE } from './SearchFilters/constants';
import SearchResultSorters from './SearchResultSorters';
import { GROUPS_DICTIONARY_NAME } from 'constants.js';
import SearchRequestInput from 'components/Lk/Common/SearchRequestInput';
import { setSearchFilter } from './utils';
import ModalSearchCardCommentsBlock from './ModalSearchCardCommentsBlock';
import { isNullOrWhitespace } from 'utils.js';
import { WISHLIST_FILTER_ID } from './constants';

const initialState = {
    searchTerm: '',
    dynamicSearchTerm: '',
    competencies: [],
    hasSearchButton: true,
    activePerson: [],
    zipSearch: '',
    menu: null,
    currentPersons: [],
    activeTemplate: null,
    selectedWishlists: [],
    searchFieldKeyPress: false,
    searchRequest: [],
    searchPanelHistory: [],
    selectedPersonId: null,
    isOpenCommentsModal: false,
};
class SearchCard extends Component {
    state = initialState;
    timer = null;

    clearAllFilters = () => {
        this.props.setMainFilter([]);
        this.props.setSearchTerm('');
        this.props.setDynamicSearchTerm('');
    };

    loadFilters = async (id) => {
        if (!id) {
            return;
        }
        const { setExtraFields, setSearchTerm, menuId } = this.props;
        const result = await getSearchTemplate(id);

        result.data.withExtraFields && setExtraFields(result.data.withExtraFields);
        result.data.searchTerm ? setSearchTerm(result.data.searchTerm) : setSearchTerm('');
        this.props.setMainFilter(JSON.parse(result.data.filtersRaw));
        menuId === FILTERS_FILTER_MENU_ID && this.getResultAmount();
    };

    setSorters = (sorters) => this.props.setCriteria({ sorters });

    onSearch = (clearData = false, mounting = false, isSaveRequest = true) => {
        const {
            fetchSelect,
            mainFilter,
            searchTerm,
            dynamicSearchTerm,
            criteria: { paging, sorters },
        } = this.props;

        const fullNameFilter = dynamicSearchTerm.trim() ? this.getFullNameFilter() : [];
        const zipSearchState = this.getStateFromUrlParams();

        if (
            (searchTerm === null || searchTerm?.trim() === '') &&
            this.countActiveFilters() === 0 &&
            !mounting
        ) {
            return;
        }

        const globalFilter = mounting ? zipSearchState.mainFilter : mainFilter;

        if (clearData) {
            this.props.setCriteria({
                paging: { pageNum: 1, pageSize: 10 },
                sorters,
            });
            this.props.setSearchData([], false);
        }

        const fields = globalFilter
            .filter((x) => x !== undefined && x.childs.length !== 0)
            .concat(!isSaveRequest ? fullNameFilter : []);

        const criteria = {
            searchTerm: searchTerm,
            paging: clearData ? { pageNum: 1, pageSize: 10 } : paging,
            filter: {
                fields: fields ? this.getFilter(fields) : fields,
                fieldsRaw:
                    fields && Array.isArray(fields) && fields.length > 0
                        ? JSON.stringify(this.getFilter(fields))
                        : null,
            },
            idList: true,
            sorting: sorters.length > 0 ? sorters : null,
            isSaveRequest,
        };

        fetchSelect(criteria);
        const profileMenuId = queryString.parse(this.props.location.search).profileMenuId;
        clearData &&
            this.props.push({
                pathname: this.props.location.pathname,
                search: queryString.stringify({
                    q: this.state.zipSearch,
                    ...(profileMenuId && { profileMenuId }),
                }),
            });

        isSaveRequest &&
            fullNameFilter &&
            fullNameFilter?.length !== 0 &&
            this.onSearch(true, false, false);

        this.removeFilter();
    };

    handleLoadMore = () => {
        this.onSearch(false, false, false);
    };

    onSearchFieldKeyPress = (e) => {
        const { menuId } = this.props;
        if (e.key === 'Enter' && menuId === FILTERS_FILTER_MENU_ID) {
            e.preventDefault();
            e.target.setSelectionRange(e.target?.value?.length || 0, e.target?.value?.length || 0);
            this.setState({ searchFieldKeyPress: true, activePerson: [] });
            this.props.setSearchTerm(this.state.searchTerm);
            this.getResultAmount();
        }
    };

    onChangeSearchTerm = (e) => {
        const { menuId } = this.props;
        if (menuId === FILTERS_FILTER_MENU_ID) {
            this.props.setSearchTerm(e.target.value);
            this.setState({ hasSearchButton: true });
        } else {
            this.props.setDynamicSearchTerm(e.target.value);
        }
    };

    removeFilter = () => {
        this.setState({ hasSearchButton: false });
        this.props.setMenuId(null);
    };

    getFilter = (filter) => {
        const tempFilter = JSON.parse(JSON.stringify(filter));
        let prevIsDateOrNumber = false;
        return tempFilter.map((f) => {
            if (f.label) {
                if (f.excludeFromSearch) {
                    if (
                        f.type === FIELDS_TYPE.number ||
                        f.type === FIELDS_TYPE.year ||
                        f.type === FIELDS_TYPE.date
                    ) {
                        if (prevIsDateOrNumber) {
                            prevIsDateOrNumber = false;
                            f.joinOperation = f.joinOperation === 'OR' ? 'AND' : 'OR';
                        } else {
                            prevIsDateOrNumber = true;
                        }
                    }
                    f.operation = inversionOperation[f.operation];
                    f.excludeFromSearch = false;
                    f.indicateExcludedFromSearch = true;
                }
                return f;
            }
            if (f.childs) {
                f.childs = this.getFilter(f.childs);
            }
            return f;
        });
    };

    getResultAmount = (searchState) => {
        const { searchTerm } = searchState || this.props;
        const { fetchAmount, mainFilter } = this.props;

        const fields = mainFilter.filter(
            (x) => x !== undefined && x.childs.length !== 0 && x.id !== SEARCH_TERM_FILTER.id,
        );

        const criteria = {
            searchTerm: searchTerm,
            filter: {
                fields: fields ? this.getFilter(fields) : fields,
            },
        };
        return (
            (mainFilter.filter((x) => x.childs.length !== 0).length !== 0 || !!searchTerm) &&
            fetchAmount(criteria)
        );
    };

    countActiveFilters = () => {
        const { mainFilter } = this.props;
        return mainFilter.filter((x) => x.childs.length !== 0).length;
    };

    renderSearchButton = (found = 0) => {
        const { menuId, searchTerm, searchLoading } = this.props;
        const { searchFieldKeyPress } = this.state;

        if (menuId === SEARCH_TEMPLATE_MENU_ID || menuId === FAVORITES_MENU_ID) {
            return null;
        }

        if (
            ((this.countActiveFilters() !== 0 && !searchTerm.trim()) ||
                (searchTerm.trim() && searchFieldKeyPress)) &&
            menuId
        ) {
            return (
                <RoundedButton
                    loading={!!searchLoading}
                    hideContent={!!searchLoading}
                    onClick={() => this.onSearch(true)}
                    disabled={found === 0}
                >
                    {found ? `Найдено совпадений: ${found}` : `Совпадений не найдено`}
                </RoundedButton>
            );
        } else {
            return (
                <RoundedButton
                    loading={!!searchLoading}
                    hideContent={!!searchLoading}
                    onClick={() => this.onSearch(true)}
                >
                    Поиск
                </RoundedButton>
            );
        }
    };

    cleanActiveTemplate = () => this.setState({ activeTemplate: null });

    setInitialData = async () => {
        const { fetchCatalog, showErrorAlert, location, fetchDictionaries } = this.props;
        try {
            await Promise.all([
                fetchCatalog(INDUSTRIES, null, true),
                fetchCatalog(FEDERAL_DISTRICTS, null, true),
                fetchCatalog(REGIONS, null, true),
                fetchCatalog(WORK_AREAS, null, true),
                fetchCatalog(MANAGEMENT_LEVELS, null, true),
                fetchCatalog(STUDY_FORMS, null, true),
                fetchCatalog(EDUCATIONAL_LEVELS, null, true),
                fetchCatalog(MANAGEMENT_EXPERIENCES, null, true),
                fetchCatalog(EMPLOYEES_NUMBERS, null, true),

                fetchCatalog(SEX, null, true),
                fetchCatalog(IDENTITY_DOCUMENTS, null, true),
                fetchCatalog(FAMILY_STATUS, null, true),
                fetchCatalog(CHILDREN_STATUS, null, true),
                fetchCatalog(EXPERIENCE_LEVELS, null, true),
                fetchCatalog(DATA_ORIGINS, null, true),
                fetchDictionaries([GROUPS_DICTIONARY_NAME]),
                fetchCatalog(LANGUAGES, null, true),
                fetchCatalog(LANGUAGE_LEVELS, null, true),
            ]);

            const searchParams = queryString.parse(location.search);
            if (searchParams) {
                this.loadFilters(searchParams.filter);
            }
        } catch (error) {
            showErrorAlert(error.message);
        }
    };

    compress = (str) => pako.deflate(str, { to: 'string' });
    decompress = (binStr) => pako.inflate(binStr, { to: 'string' });

    filtersToQueryString = compose(window.btoa, this.compress, JSON.stringify);
    filtersFromQueryString = compose(JSON.parse, this.decompress, window.atob);

    setTemplate = (activeTemplate) => {
        this.setState({
            activeTemplate: { ...activeTemplate },
        });
    };

    getStateFromUrlParams = () => {
        const searchParams = queryString.parse(this.props.location.search);
        return (searchParams.q && this.filtersFromQueryString(searchParams.q)) || '';
    };

    setStateFromUrl() {
        const { setMainFilter, setMenuId, setSearchTerm, menuId } = this.props;
        const zipSearchState = this.getStateFromUrlParams();
        if (zipSearchState) {
            this.setState((state) => ({
                ...state,
                searchTerm: zipSearchState.searchTerm,
            }));
            setMainFilter(zipSearchState.mainFilter);
            setMenuId(zipSearchState.menuId);
            setSearchTerm(zipSearchState.searchTerm);
            menuId === FILTERS_FILTER_MENU_ID && this.getResultAmount(zipSearchState);
        } else {
            setMenuId(FILTERS_FILTER_MENU_ID);
            setMainFilter([]);
            setSearchTerm('');
        }
    }

    onBackButtonClick = () => {
        this.changeSearchPanelHistory(null, false);
    };

    setSelectedWishlists = (newSelection) => {
        this.setState({ selectedWishlists: newSelection });
    };

    onMountSearch = () => {
        const zipSearchState = this.getStateFromUrlParams();
        zipSearchState && !this.props.menuId && this.onSearch(true, true);
    };

    unregisterHistoryListener = () => {};
    async componentDidMount() {
        this.setInitialData();
        await this.setStateFromUrl();
        this.unregisterHistoryListener = this.props.history.listen((location) => {
            queryString.parse(location.search).q !== this.state.zipSearch &&
                this.setState({ urlUpdated: new Date() });
        });

        const res = await getSearchRequest(this.props.searchTerm);
        this.setState({ searchRequest: res.data.payload });
        this.onMountSearch();
        this.setState({
            searchPanelHistory: [
                { ...queryString.parse(this.props.location.search), menuId: this.props.menuId },
            ],
        });
    }

    getFullNameFilter = () => {
        const { dynamicSearchTerm } = this.props;
        return dynamicSearchTerm.trim()
            ? [
                  {
                      filterId: SEARCH_TERM_FILTER.id,
                      joinOperation: 'AND',
                      childs: [
                          setSearchFilter(
                              'Persons',
                              'FullName',
                              queryOperation.Like,
                              dynamicSearchTerm,
                              '',
                              'AND',
                              SEARCH_TERM_FILTER.id,
                              null,
                          ),
                      ],
                  },
              ]
            : [];
    };

    componentDidUpdate = async (prevProps, prevState) => {
        const {
            searchResult,
            mainFilter,
            menuId,
            searchTerm,
            dynamicSearchTerm,
            criteria: { paging },
        } = this.props;
        const { searchPanelHistory, selectedWishlists } = this.state;

        if (prevProps.searchResult !== searchResult) {
            this.props.setCriteria({ paging: { ...paging, pageNum: paging.pageNum + 1 } });
            this.props.setSearchData(searchResult);
        }

        if (prevProps.searchTerm !== searchTerm) {
            this.setState({ searchTerm: searchTerm, searchFieldKeyPress: false });
            this.countActiveFilters() !== 0 &&
                !searchTerm.trim() &&
                menuId === FILTERS_FILTER_MENU_ID &&
                this.getResultAmount();
        }

        if (prevProps.menuId !== menuId && searchTerm.trim()) {
            this.setState({ searchFieldKeyPress: true });
        }

        if (
            JSON.stringify(prevProps.mainFilter) !== JSON.stringify(mainFilter) ||
            prevProps.searchTerm !== this.props.searchTerm ||
            prevProps.menuId !== menuId ||
            isNullOrWhitespace(this.props.location.search)
        ) {
            const zipStr = this.filtersToQueryString({
                mainFilter,
                searchTerm: this.props.searchTerm,
                menuId,
            });

            const profileMenuId = queryString.parse(this.props.location.search).profileMenuId;

            const urlParams = {
                pathname: this.props.location.pathname,
                search: queryString.stringify({
                    q: zipStr,
                    ...(profileMenuId && { profileMenuId }),
                }),
            };

            if (this.state.zipSearch !== zipStr) {
                if (prevProps.menuId !== menuId) {
                    this.changeSearchPanelHistory(urlParams, true, false);
                    this.props.push(urlParams);
                } else {
                    this.changeSearchPanelHistory(urlParams, true, true);
                    this.props.replace(urlParams);
                    this.setState({ activePerson: [] });
                }
            }
            if (!isNullOrWhitespace(this.props.location.search)) {
                menuId === FILTERS_FILTER_MENU_ID && this.getResultAmount();
                this.setState({ zipSearch: zipStr });
            }
        }

        if (
            prevState.urlUpdated !== this.state.urlUpdated &&
            prevState.zipSearch === this.state.zipSearch
        ) {
            const zipStr = queryString.parse(this.props.location.search).q;
            this.setState({ zipSearch: zipStr });
            await this.setStateFromUrl();
            if (!searchPanelHistory[searchPanelHistory.length - 1].menuId) {
                this.onSearch(true);
            }
        }

        if (prevProps.criteria.sorters !== this.props.criteria.sorters) {
            this.onSearch(true);
        }

        if (prevProps.dynamicSearchTerm !== dynamicSearchTerm && !menuId) {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.onSearch(true, false, false);
            }, 200);
        }

        const excludeWishlistsFromSelected = this.props.location.state
            ?.excludeWishlistsFromSelected;

        if (
            excludeWishlistsFromSelected &&
            excludeWishlistsFromSelected.length !== 0 &&
            prevProps.location.state?.excludeWishlistsFromSelected !== excludeWishlistsFromSelected
        ) {
            const newSelectedWishlists = selectedWishlists.filter(
                (x) => !excludeWishlistsFromSelected.find((z) => +z === +x),
            );
            this.setSelectedWishlists(newSelectedWishlists);
            const newMainFilter = mainFilter.filter((x) => x.filterId !== WISHLIST_FILTER_ID);
            this.props.setMainFilter(newMainFilter);
        }
    };

    componentWillUnmount() {
        this.clearAllFilters();
        this.props.setMenuId();
        this.props.setFilterId(null);
        this.props.setSearchTerm('');
        this.unregisterHistoryListener();
    }

    onCheck = (personId, isCheck) => {
        const { activePerson } = this.state;
        const newList = isCheck
            ? [...activePerson, personId]
            : activePerson.filter((x) => x !== personId);
        this.setActivePerson(newList);
    };

    onSetMenu = (menu) => this.setState({ menu });

    setActivePerson = (activePerson) => this.setState({ activePerson });

    onMultipleMenu = (menu, personId) => {
        this.setState({ menu, currentPersons: personId ? [personId] : this.state.activePerson });
    };

    onClickCardMenu = (id) => this.onMultipleMenu(MENU_ACTION, id);

    onClickCommentsBlank = (id) => {
        this.setState({ selectedPersonId: id, isOpenCommentsModal: true });
    };

    setCommentModalVisible = (visible) => {
        this.setState({ isOpenCommentsModal: visible });
    };

    countFilters = (items) => {
        return items.reduce((p, c) => {
            if (c.label && c.parent) {
                return p + 1;
            }
            if (c.childs) {
                p += this.countFilters(c.childs);
                return p;
            }
            return !c.parent ? p + 1 : p;
        }, 0);
    };

    onSelectRequest = async (_, item) => {
        const { setMenuId, setSearchTerm, menuId } = this.props;
        setMenuId(FILTERS_FILTER_MENU_ID);
        this.props.setMainFilter([]);
        await setSearchTerm(item.searchTerm);
        await this.props.setMainFilter(JSON.parse(item.filters));
        menuId === FILTERS_FILTER_MENU_ID && this.getResultAmount();
        item.searchTerm.trim() && this.setState({ searchFieldKeyPress: true });
    };

    changeSearchPanelHistory = (urlParams, add = true, replace = false) => {
        const { menuId } = this.props;
        const newHistory = [...this.state.searchPanelHistory];

        if (add) {
            const strSearchQuery = queryString.parse(urlParams.search) || '';
            replace && newHistory.pop();
            newHistory.push({ ...strSearchQuery, menuId });
        } else {
            if (newHistory.length > 1) {
                newHistory.pop();
            }
            const urlProps = newHistory[newHistory.length - 1];
            const profileMenuId = queryString.parse(this.props.location.search).profileMenuId;
            this.props.push({
                pathname: this.props.location.pathname,
                search: queryString.stringify({
                    q: urlProps?.q || '',
                    ...(profileMenuId && { profileMenuId }),
                }),
            });
        }

        this.setState({ searchPanelHistory: newHistory });
    };

    handlePersonUpdate = () => {};

    render() {
        const {
            competencies,
            activeTemplate,
            activePerson,
            menu,
            currentPersons,
            selectedPersonId,
            isOpenCommentsModal,
        } = this.state;

        const {
            activeCard,
            setActiveCard,
            searchLoading,
            totalFound,
            mainFilter,
            setMainFilter,
            menuId,
            searchMeta,
            history,
            wishListsMode,
            setSearchTerm,
            setDynamicSearchTerm,
            dynamicSearchTerm,
            searchTerm,
            user,
            searchData,
            criteria: { sorters, hasMore },
        } = this.props;

        const isMobileActive = activeCard === ACTIVE_LIST_CARD;
        setActiveCard(ACTIVE_LIST_CARD);

        const totalPersonIds = searchMeta && searchMeta.ids;
        const menuItem = filtersMenu.find((x) => x.id === menuId);

        return (
            <>
                <div
                    className={classnames('ListCard LKSearchCard', {
                        'ListCard--isMobileActive': isMobileActive,
                        'LKSearchCard--isMobileActive': isMobileActive,
                    })}
                >
                    {this.props.filterId === null && wishListsMode !== 'edit' && (
                        <>
                            <div className="LKSearchCardInput">
                                <div className="LKSearchCardInput__BackButton">
                                    <GoBackButton action={this.onBackButtonClick} />
                                </div>
                                <div className="LKSearchCardInput__Input">
                                    {menuId !== FILTERS_FILTER_MENU_ID ? (
                                        <Input
                                            className={classnames('LKSearchCardInput__Search', {
                                                'LKSearchCardInput__Search--close-icon': dynamicSearchTerm.trim(),
                                            })}
                                            value={dynamicSearchTerm}
                                            onChange={this.onChangeSearchTerm}
                                            onSubmit={() => this.onSearch(true)}
                                            onKeyPress={this.onSearchFieldKeyPress}
                                            preIcon={
                                                menuItem
                                                    ? filtersMenu.find((x) => x.id === menuId)
                                                          .preIcon
                                                    : 'search_input_results'
                                            }
                                            postIcon={
                                                dynamicSearchTerm.trim() ? 'search_input_clear' : ''
                                            }
                                            postIconClick={() => setDynamicSearchTerm('')}
                                        />
                                    ) : (
                                        <SearchRequestInput
                                            className="LKSearchCardInput__Search"
                                            value={searchTerm}
                                            onChange={this.onChangeSearchTerm}
                                            onSubmit={() => this.onSearch(true)}
                                            onKeyPress={this.onSearchFieldKeyPress}
                                            onSelect={this.onSelectRequest}
                                            preIcon="search_input_filter"
                                            postIcon={searchTerm.trim() ? 'search_input_clear' : ''}
                                            postIconClick={() => setSearchTerm('')}
                                            searchCallback={getSearchRequest}
                                        />
                                    )}
                                </div>
                            </div>

                            <ActiveFilters clearable={false} countFilters={this.countFilters} />
                            <SearchCardFiltersMenu countFilters={this.countFilters} />

                            {!menuId && (
                                <EventMenu
                                    menuType="search"
                                    menu={menu}
                                    onSetMenu={this.onMultipleMenu}
                                    data={searchData}
                                    onCheck={this.setActivePerson}
                                    activeSelection={activePerson}
                                    allIdsList={totalPersonIds}
                                    withCounter
                                />
                            )}
                            <PersonMassActionMenu
                                persons={currentPersons}
                                data={searchData}
                                type={menu}
                                setMenu={this.onSetMenu}
                                location={history.location}
                                handlePersonUpdate={this.handlePersonUpdate}
                            />
                            {!menuId && totalFound > 0 && (
                                <SearchResultSorters
                                    sorters={sorters}
                                    setSorters={this.setSorters}
                                />
                            )}
                        </>
                    )}
                    {menuId !== FAVORITES_MENU_ID ? (
                        <div className="LKSearchCard__List">
                            {menuId ? (
                                <SearchCardFilter
                                    loadTemplates={this.loadFilters}
                                    menuId={menuId}
                                    competencies={competencies}
                                    mainFilter={mainFilter}
                                    setMainFilter={setMainFilter}
                                    getResultAmount={this.getResultAmount}
                                    totalFound={totalFound}
                                    clearAllFilters={this.clearAllFilters}
                                    activeTemplate={activeTemplate}
                                    setTemplate={this.setTemplate}
                                    onSearch={this.onSearch}
                                    cleanActiveTemplate={this.cleanActiveTemplate}
                                    getFilter={this.getFilter}
                                    searchTerm={searchTerm}
                                    setSearchTerm={setSearchTerm}
                                    dynamicSearchTerm={dynamicSearchTerm}
                                />
                            ) : (
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={this.handleLoadMore}
                                    hasMore={hasMore}
                                    initialLoad={false}
                                    useWindow={false}
                                >
                                    <SearchResultCard
                                        searchResult={searchData}
                                        activePerson={activePerson}
                                        onCheckClick={this.onCheck}
                                        onClickMenu={this.onClickCardMenu}
                                        onClickCommentBlock={this.onClickCommentsBlank}
                                        searchString={this.state.zipSearch}
                                        searchLoading={searchLoading}
                                        withComments={true}
                                        user={user}
                                    />
                                    {searchLoading && <Loader />}
                                    <ModalSearchCardCommentsBlock
                                        personId={selectedPersonId}
                                        user={user}
                                        isOpen={isOpenCommentsModal}
                                        setIsOpen={this.setCommentModalVisible}
                                        handlePersonUpdate={this.handlePersonUpdate}
                                    />
                                </InfiniteScroll>
                            )}
                        </div>
                    ) : (
                        <ManageWishlists
                            onSearch={this.onSearch}
                            setMainFilter={setMainFilter}
                            zipSearch={this.state.zipSearch}
                            updated={this.props.location?.state?.updated}
                            setSelectedWishlists={this.setSelectedWishlists}
                            selectedWishlists={this.state.selectedWishlists}
                            setSearchTerm={setSearchTerm}
                            dynamicSearchTerm={dynamicSearchTerm}
                        />
                    )}
                    {(menuId === FILTERS_FILTER_MENU_ID || this.state.hasSearchButton) &&
                        menuId !== FAVORITES_MENU_ID &&
                        menuId !== SEARCH_TEMPLATE_MENU_ID &&
                        this.props.filterId === null && (
                            <div className="LKSearchCard__Footer">
                                <StickyFooter>{this.renderSearchButton(totalFound)}</StickyFooter>
                            </div>
                        )}
                </div>
            </>
        );
    }
}

SearchCard.propTypes = {
    activeCard: PropTypes.string.isRequired,
    setActiveCard: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    personId: state.auth.user.personId,
    criteria: state.externalSearch.criteria,
    searchResult: state.externalSearch.data.payload,
    searchData: state.externalSearch.searchData,
    searchMeta: state.externalSearch.data.meta,
    searchLoading: state.externalSearch.loading,
    totalFound: state.externalSearch.totalAmount,
    loaded: state.externalSearch.loadComplete,
    mainFilter: state.filterSearch.mainFilter,
    filterId: state.filterSearch.filterId,
    menuId: state.filterSearch.menuId,
    wishListsMode: state.wishlists.mode,
    searchTerm: state.filterSearch.searchTerm,
    dynamicSearchTerm: state.filterSearch.dynamicSearchTerm,
});

const actions = {
    push,
    replace,
    fetchCatalog,
    fetchAmount,
    fetchSelect,
    showErrorAlert,
    setMainFilter,
    setMenuId,
    setFilterId,
    setExtraFields,
    setSearchTerm,
    setDynamicSearchTerm,
    fetchDictionaries,
    setSearchData,
    setCriteria,
};

export default connect(mapStateToProps, actions)(SearchCard);
