import React from 'react';
import PropTypes from 'prop-types';
import Field from 'components/uikit/Field';
import Input from 'components/uikit/Input';
import Label from 'components/uikit/Label';
import Select from 'react-select';
import DatePicker from 'components/uikit/DatePicker';
import Button from 'components/uikit/Button';
import classnames from 'classnames';
import StyledCheckBox from 'components/Lk/Uikit/StyledCheckBox';

const Filters = (props) => {
    const {
        typeOptions,
        statusOptions,
        filter,
        handleStateChange,
        userPage,
        setFilter,
        cleanFilter,
    } = props;

    const customSelectStyles = {
        control: (base) => ({
            ...base,
            padding: 0,
            backgroundColor: '#f9f9ff',
            borderColor: '#d2d5ea',
        }),
        input: (base) => ({
            ...base,
            height: 33,
            padding: 0,
            margin: 0,
        }),
    };

    return (
        <div
            className={classnames('SmsEmailGate__Filter', {
                SmsEmailGate__LineFilter: userPage,
            })}
        >
            {!userPage && (
                <Field>
                    <Label>Получатель</Label>
                    <Input
                        placeholder="Введите номер телефона или Email"
                        value={filter.recipient}
                        maxLength="100"
                        onChange={(e) =>
                            handleStateChange('filter', {
                                recipient: e.target.value,
                            })
                        }
                    />
                </Field>
            )}
            <Field
                className={classnames('', {
                    'SmsEmailGate__LineFilter--full-width': userPage,
                })}
            >
                <Label>Дата создания с</Label>
                <DatePicker
                    selected={filter.createdFrom}
                    onChange={(e) => handleStateChange('filter', { createdFrom: e })}
                    placeholderText="Выберите дату отправки"
                />
            </Field>
            <Field
                className={classnames('', {
                    'SmsEmailGate__LineFilter--full-width': userPage,
                })}
            >
                <Label>Дата создания по</Label>
                <DatePicker
                    selected={filter.createdTo}
                    onChange={(e) => handleStateChange('filter', { createdTo: e })}
                    placeholderText="Выберите дату отправки"
                />
            </Field>
            <Field
                className={classnames('', {
                    'SmsEmailGate__LineFilter--full-width': userPage,
                })}
            >
                <Label>Тип отправки</Label>
                <Select
                    value={filter.type}
                    placeholder={<div>Выберите тип</div>}
                    options={typeOptions}
                    onChange={(item) => handleStateChange('filter', { type: item })}
                    isMulti
                    styles={customSelectStyles}
                />
            </Field>
            <Field
                className={classnames('', {
                    'SmsEmailGate__LineFilter--full-width': userPage,
                })}
            >
                <Label>Статус отправки</Label>
                <Select
                    value={filter.status}
                    placeholder={<div>Выберите статус</div>}
                    options={statusOptions}
                    onChange={(item) => handleStateChange('filter', { status: item })}
                    isMulti
                    styles={customSelectStyles}
                />
            </Field>
            {!userPage && (
                <Field
                    className={classnames('', {
                        'SmsEmailGate__LineFilter--full-width': userPage,
                    })}
                >
                    <Label></Label>
                    <StyledCheckBox
                        checked={!!filter.isOnlyOpened}
                        title="Показать только прочитанные"
                        onChange={(check) => handleStateChange('filter', { isOnlyOpened: check })}
                    />
                </Field>
            )}
            <div
                className={classnames('SmsEmailGate__FilterActions', {
                    SmsEmailGate__LineFilterActions: userPage,
                })}
            >
                <Field>
                    {userPage && <Label>&nbsp;</Label>}
                    <Button size="xs" onClick={setFilter}>
                        Применить
                    </Button>
                </Field>
                <Field>
                    {userPage && <Label>&nbsp;</Label>}
                    <Button size="xs" onClick={cleanFilter} closeLink={!userPage}>
                        Очистить фильтр
                    </Button>
                </Field>
            </div>
            {/* @TODO пока так, потом надо переверстать */}
            {userPage && (
                <Field
                    className={classnames('', {
                        'SmsEmailGate__LineFilter--full-width': userPage,
                    })}
                >
                    <StyledCheckBox
                        checked={!!filter.isOnlyOpened}
                        title="Показать только прочитанные"
                        onChange={(check) => handleStateChange('filter', { isOnlyOpened: check })}
                    />
                </Field>
            )}
        </div>
    );
};

Filters.defaultProps = {
    userPage: false,
};

Filters.propTypes = {
    typeOptions: PropTypes.array,
    statusOptions: PropTypes.array,
    filter: PropTypes.object,
    handleStateChange: PropTypes.func,
    userPage: PropTypes.bool,
    setFilter: PropTypes.func,
    cleanFilter: PropTypes.func,
};

export default Filters;
