import { lkSearch, instructionsRoute } from "routes";

export const menuItems = [
    {
        id: 'searchTop',
        name: 'Поиск',
        icon: 'search-white',
        link: lkSearch.url,
    },
    {
        id: 'help',
        name: 'Помощь',
        icon: 'question-white',
        link: instructionsRoute.url,
    }
];