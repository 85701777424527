import React from 'react';
import CheckField from 'components/uikit/CheckField';
import ChevronButton from 'components/uikit/ChevronButton';
import RoundButton from 'components/uikit/Button/RoundButton';
import classnames from 'classnames';

const DataCardTitle = (props) => {
    const {
        hasOpen,
        showDrag,
        title,
        isOpen,
        onToggle,
        onDrag,
        withCheck,
        onCheck,
        checked,
        partlyChecked,
        toggleDisabled,
    } = props;

    return (
        <div className="DataCard__Title">
            <div>
                {withCheck && (
                    <CheckField
                        checked={checked}
                        onChange={onCheck}
                        partlyChecked={partlyChecked}
                        disabled={toggleDisabled}
                    />
                )}
                <div className={classnames('', { DataCard__TitleCheck: withCheck })}>{title}</div>
            </div>
            {hasOpen && (
                <div className="DataCardActions">
                    <ChevronButton
                        className="kr_right"
                        isUp={isOpen}
                        onClick={!toggleDisabled ? onToggle: undefined}
                        disabled={toggleDisabled}
                    />
                    {showDrag && <RoundButton type="drag" onClick={onDrag} />}
                </div>
            )}
        </div>
    );
};

export default DataCardTitle;
