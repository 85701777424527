import React, { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { setActiveFilter, setMainFilter, setFilterId, setMenuId, setRestoreMenuId } from 'ducks/FilterSearch';
import { FILTERS_FILTER_MENU_ID, FAVORITES_MENU_ID, SEARCH_TERM_FILTER } from './constants';
import IconButton from 'components/Lk/Uikit/Buttons/IconButton';
import Icons from "components/Lk/Uikit/Icons/Icons";

const ActiveFilters = props => {
    const {
        mainFilter,
        setActiveFilter,
        setFilterId,
        filterId,
        setMainFilter,
        activeFilter,
        clearable = true,
        clickable = true,
        setMenuId,
        setRestoreMenuId,
        menuId,
        countFilters,
        searchTerm,
    } = props;

    const [isActiveFiltersOpen, setActiveFiltersOpen] = useState(false);
    const [filtersCount, setFiltersCount] = useState(null);

    const plainFilter = JSON.stringify(mainFilter);
    useEffect(() => {
        setActiveFiltersOpen(false);
        countFilters && setFiltersCount((countFilters(mainFilter.filter(x => x && !x.parent)) || null) + (searchTerm.trim() ? 1 : 0));
        // eslint-disable-next-line
    }, [plainFilter, countFilters, searchTerm]);

    const activefilters = useMemo(
        () => mainFilter
                .filter(x => !filterId || x.filterId === filterId)
                .concat(searchTerm.trim() ? {filterId: SEARCH_TERM_FILTER.id, childs: [{id: SEARCH_TERM_FILTER.id, label: `${SEARCH_TERM_FILTER.label} ${searchTerm}`}]} : []),
        [mainFilter, filterId, searchTerm],
    );

    const onClick = item => () => {
        setMenuId(FILTERS_FILTER_MENU_ID);
        setActiveFilter(item);
        setFilterId(item.filterId);
        setRestoreMenuId(menuId);
    };

    const removeChild = id => item => {
        if (id === item.id) {
            return undefined;
        }

        let childs = undefined;
        if (item.childs) {
            childs = item.childs.map(removeChild(id)).filter(x => x);
            if (childs.length === 0) {
                return undefined;
            }
        }

        return { ...item, childs };
    };

    const removeActiveFilterById = (filterId, id) => e => {
        e.stopPropagation();
        const updatedSection = activefilters.find(x => x.filterId === filterId);

        const filters = updatedSection.childs
            .map(removeChild(id))
            .filter(x => x && (!x.childs || x.childs.length > 0))
            .filter(f => f.field !== 'IsCurrent');
        setMainFilter(filters, filterId, true);
    };

    const setFilters = item => {
        let childs = null;
        if (item.childs && !item.group) {
            childs = item.childs.filter(x => !x.parent).map(setFilters);
        }
        
        if (childs && childs.length) {
            return childs;
        }

        return (
            <React.Fragment key={item.id}>
                {item.label && (
                    <div
                        className={classnames('LKActiveSearchFilter', {
                            'LKActiveSearchFilter--search-filter': item.id === SEARCH_TERM_FILTER.id,
                            'LKActiveSearchFilter--clickable': clickable && item.id !== SEARCH_TERM_FILTER.id,
                            'LKActiveSearchFilter--excluded': item.excludeFromSearch || item.indicateExcludedFromSearch,
                            'LKActiveSearchFilter--active':
                                activeFilter &&
                                (activeFilter.id === item.id ||
                                    (activeFilter.group && activeFilter.group === item.group)),
                        })}
                        onClick={clickable && item.id !== SEARCH_TERM_FILTER.id ? onClick(item) : undefined}
                    >
                        <div className="LKActiveSearchFilter__Text">{item.label}</div>
                        {clearable && (
                            <div
                                className="LKActiveSearchFilter__Button"
                                onClick={removeActiveFilterById(item.filterId, item.id)}
                            />
                        )}
                    </div>
                )}
            </React.Fragment>
        );
    };

    const activeFiltersHint = () => {
        return (
            isActiveFiltersOpen 
            ?
                <div className="LKSearchCardVisibleFIlterLineActiveFilters__TopClose">
                    <IconButton 
                        onClick={() => setActiveFiltersOpen(false)}
                        type='cross'
                        fill='#007AFF'
                    >
                        Свернуть
                    </IconButton>
                </div>
            :
                <div className='LKSearchCardVisibleFilterHint' onClick={() => setActiveFiltersOpen(true)}>
                    <div className='LKSearchCardVisibleFilterHint__Icon'>
                        <Icons name='filter' fill='#007AFF' />
                    </div>
                    <div className='LKSearchCardVisibleFilterHint__Text'>
                        Показать выбранные фильтры ({filtersCount})
                    </div>
                </div>
        );
    };

    return (activefilters && activefilters.length !== 0 && menuId !== FAVORITES_MENU_ID &&
        <div className='LKSearchCardVisibleFIlterLineActiveFilters'>
            {!clearable && activeFiltersHint()}
            {(isActiveFiltersOpen  || clearable) && <div className="LKSearchCardVisibleFIlterLineActiveFilters__Filters">
                {activefilters.map(activeSection => 
                    activeSection.childs
                    .filter(x => clearable ? x.id !== SEARCH_TERM_FILTER.id : x)
                    .map(setFilters),
                )}
            </div>}
        </div>
    );
};

ActiveFilters.propTypes = {
    setMainFilter: PropTypes.func,
    mainFilter: PropTypes.array,
};

const mapStateToProps = state => ({
    mainFilter: state.filterSearch.mainFilter,
    activeFilter: state.filterSearch.activeFilter,
    menuId: state.filterSearch.menuId,
    searchTerm: state.filterSearch.searchTerm,
});

export default connect(
    mapStateToProps,
    {
        setMainFilter,
        setActiveFilter,
        setFilterId,
        setMenuId,
        setRestoreMenuId,
    },
)(ActiveFilters);
