import React from 'react';
import CardItemBlock from 'components/Lk/Common/CardItemBlock';
import CardItemBlockType from 'components/Lk/Common/CardItemBlockType';
import { EVALUATION_TYPE } from 'components/Lk/Basic/constants';

const EvaluatePersonWizard = props => {
    const { setPageType, setIsAcceptPage, setAcceptPage, onPersonSend } = props;

    const onTestClick = async (test) => {
        setIsAcceptPage(true);
        setAcceptPage({
            title: `Направить на оценку "${test.title}"?`,
            info: 'Задача о направлении резервиста на оценку будет направлена эксперту по управлению талантами, который закреплен за Вами и вашими командами.',
            acceptAction: onPersonSend('Evaluation', test.title),
        });
        setPageType('accept');

    };

    return (
            <div className='LKModalSummaryCardEvaluatePerson'>
                <div className='LKModalSummaryCardEvaluatePerson__Header'>
                    Выберите тип оценки
                </div>
                {EVALUATION_TYPE.length !== 0 && EVALUATION_TYPE.filter(x => x.forEvaluation).map(x => 
                    <CardItemBlock grey key={x.id}>
                        <CardItemBlockType
                            id={x.id}
                            title={x.title}
                            tooltip={x.description}
                            onClick={() => onTestClick(x)}
                            icon="small"
                            normalText
                        />
                    </CardItemBlock>                   
                )}
            <div className='LKModalSummaryCardEvaluatePerson__Footer'>
                <div className='LKModalSummaryCardEvaluatePerson__Button LKModalSummaryCardEvaluatePerson__Button--red' onClick={() => setPageType('')}>Отменить</div>
            </div>
        </div>
    );
};


export default EvaluatePersonWizard;