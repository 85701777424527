import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';

import './AccessBlock.scss';

import Button from 'components/uikit/Button';
import { DataCard } from 'components/common/DataCard';
import { Select } from 'components/uikit/Select';
import { addAccess } from 'ducks/Access';

import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import InputText from 'components/uikit/InputText';
import ModalDialog from "components/common/ModalDialog";
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { accessSelectOptions, ACCESS_STATUS, ACCESS_DIRECTION, ROLE_CONTENT_EDITOR, ROLE_USER } from 'constants.js';

import { Row, Col } from 'react-flexbox-grid';
import { getUserShortName, isNullOrWhitespace } from 'utils';

export class AccessBlock extends React.Component {
    state = {
        selectedRole: null,
        removeRoleReason: '',
        isOpenRemoveRoleModal: false,
        accessRequestDirection: null,
    };
    
    onClickSend = async () => {
        const { personId, addAccess, access } = this.props;
        const { selectedRole, removeRoleReason, accessRequestDirection } = this.state;
        const { lastOrderingKey } = access.data;

        await addAccess({
            personId,
            role: selectedRole.value,
            id: uuid(),
            lastOrderingKey,
            accessRequestDirection,
            removeRoleReason,
        });

        this.setState(state => {
            return {
                ...state,
                removeRoleReason: '',
                isOpenRemoveRoleModal: false,
            }
        });
    };

    closeRemoveRoleModal = () => {
        this.setState(state => {
            return {
                ...state,
                isOpenRemoveRoleModal: false,
            }
        });
    };
    
    onChangeAccess = accessRequestDirection => {

        if (accessRequestDirection === ACCESS_DIRECTION.ADD_ACCESS) {
            this.setState({accessRequestDirection}, () => {
                this.onClickSend();
            });
        }
        
        if (accessRequestDirection === ACCESS_DIRECTION.REMOVE_ACCESS) {
            this.setState({isOpenRemoveRoleModal: true, accessRequestDirection});
        }
    };

    handleTextChange = e => this.setState({ removeRoleReason: e.target.value });
    onSelectRole = role => this.setState({ selectedRole: role });
    onAcceptRemoveRole = () => {
        if (isNullOrWhitespace(this.state.removeRoleReason)) {
            return;
        }
        this.onClickSend();  
    };

    renderFields() {
        const { selectedRole } = this.state;
        const {
            access: { loading },
            user,
            isDisabled = false,
        } = this.props;
        
        const isUserHasSelectedRole = !!selectedRole && user && user.roles ? user.roles.filter(x => x.name === selectedRole.value).length > 0 : false;
        
        const { accessRequests } = this.props.access.data;
        
        const roleOptions = accessSelectOptions.filter(x => x.value !== ROLE_CONTENT_EDITOR && x.value !== ROLE_USER);

        const currRequestsRoleNames = accessRequests ? accessRequests.filter(x => 
               (x.accessRequestDirection === ACCESS_DIRECTION.ADD_ACCESS || x.accessRequestDirection === ACCESS_DIRECTION.REMOVE_ACCESS) 
            && (x.status === ACCESS_STATUS.WAITING_FOR_REVIEW || x.status === ACCESS_STATUS.APPROVED_BY_IT)).map(x => x.role) : [];
        
        return (
            <div className="AccessBlockFields">
                <Select
                    className="AccessBlockFields__Select"
                    value={selectedRole}
                    onChange={this.onSelectRole}
                    options={roleOptions.filter(x => !currRequestsRoleNames.includes(x.value))}
                    placeholder="Выберите роль"
                    isDisabled={isDisabled}
                />
                <Button
                    className="AccessBlockFields__Button"
                    loading={loading}
                    onClick={() => this.onChangeAccess(isUserHasSelectedRole 
                        ? ACCESS_DIRECTION.REMOVE_ACCESS : ACCESS_DIRECTION.ADD_ACCESS)}
                    disabled={!selectedRole || isDisabled}
                >
                    {!isUserHasSelectedRole ? 'Добавить роль' : 'Убрать роль'}
                </Button>
            </div>
        );
    }

    renderInfo() {
        const { access } = this.props;

        const { lastAccessRequest } = access.data;

        const isRejected =
            [ACCESS_STATUS.REJECTED_BY_IT, ACCESS_STATUS.REJECTED_BY_SECURITY].indexOf(
                lastAccessRequest && lastAccessRequest.status,
            ) !== -1;

        const role = lastAccessRequest && lastAccessRequest.role
            && accessSelectOptions.find(x => x.value === lastAccessRequest.role);

        if (isRejected) {
            const { ReviewedBySecurityOn, ReviewedByITOn, rejectionComment } = lastAccessRequest;
            return (
                <Row>
                    <Col md={12} className="AccessBlock__Info AccessBlock__Info--reject AccessBlockReject">
                        Запрос отклонен от{' '}
                        {moment(ReviewedBySecurityOn || ReviewedByITOn).format('DD MMM Y')} (
                        <span
                                className="AccessBlockReject__Description"
                                data-tip=""
                                data-for="reject-comment"
                            >
                                причина
                        </span>
                        )
                        <Button
                            className="AccessBlockReject__RejectButton"
                            faIcon="redo"
                            onClick={this.onRetryClick}
                        />
                        <ReactTooltip
                            id="reject-comment"
                            effect={'solid'}
                            border={true}
                            type={'light'}
                            data-offset="{'top': 0, 'left': 0}"
                        >
                            <p className="tooltip">{rejectionComment}</p>
                        </ReactTooltip>
                    </Col>
                </Row>
            );
        }

        return lastAccessRequest && lastAccessRequest.finishedOn ? (<Row>
            <Col md={12} className="AccessBlock__Info AccessBlock__Info--approve">
                Подключена роль «{role && role.label}» с {moment(lastAccessRequest.finishedOn).format('DD MMM Y')}
            </Col>
            <Col md={12} className="AccessBlock__Manager">
                Эксперт по управлению талантами: {getUserShortName(lastAccessRequest.requester)}
            </Col>
        </Row>
        ) : (
                <Row>
                    <Col md={12} className="AccessBlock__Info">
                        Запрос на роль «{role && role.label}» отправлен {lastAccessRequest && moment(lastAccessRequest.requestedOn).format('DD MMM Y')}
                    </Col>
                </Row>
            );
    }

    renderComment = () => {
        return (
            <div className="AccessBlock__Reject RejectInfo">
                <span className="RejectInfo__Title">ОТКАЗ</span>
                <span className="RejectInfo__Comment">причина</span>
            </div>
        );
    };

    render() {
        const { removeRoleReason } = this.state;
        return (
            <>
                <DataCard shadow>
                    <Row className="AccessBlock">
                        <Col md={3} className="AccessBlockWrapper">
                            <span className="AccessBlock__Text">Доступ</span>
                        </Col>
                        <Col md={9}>
                            {this.renderFields()}
                        </Col>
                    </Row>
                </DataCard>
                <ModalDialog
                    onClick={this.onAcceptRemoveRole}
                    onCloseModal={this.closeRemoveRoleModal}
                    modalOpen={this.state.isOpenRemoveRoleModal}
                    modalHeader="Укажите причину исключения роли"
                    btnOktext="Подтвердить"
                    btnCanceltext="Отменить"
                    isValidForm={!isNullOrWhitespace(removeRoleReason)}
                >
                    <div className="Modal-Children">
                        <div className="Modal-Children__Line">
                            <Field 
                                required 
                                filled={!isNullOrWhitespace(removeRoleReason)} 
                                invalid={isNullOrWhitespace(removeRoleReason)}
                            >
                                <Label>Причина</Label>
                                <InputText 
                                    value={removeRoleReason} 
                                    maxLength={250} 
                                    onChange={this.handleTextChange} 
                                />
                            </Field>
                        </div>
                    </div>
                </ModalDialog>
            </>
        );
    }
}

AccessBlock.propTypes = {
    access: PropTypes.object,
    personId: PropTypes.number,
};

const mapStateToProps = ({ access }) => {
    return {
        access,
    }
};

export default connect(mapStateToProps, { addAccess })(AccessBlock);
