import React from "react";
import classnames from "classnames";

const ModalPopupActionButton = props => {
    const { ok = false, cancel = false, fileUpload = false, minimal = true, fixed, disabled, onClick, onChange } = props;

    const finalClass = classnames('LKModalPopupActions__ActionButton',
        {
            [`LKModalPopupActions__ActionButton--ok`]: ok,
            [`LKModalPopupActions__ActionButton--cancel`]: cancel,
            [`LKModalPopupActions__ActionButton--minimal`]: minimal,
            [`LKModalPopupActions__ActionButton--fixed`]: fixed,
            [`LKModalPopupActions__ActionButton--disabled`]: disabled,
        }
    );

    return (
        !fileUpload
            ?
            <button className={finalClass} disabled={disabled} onClick={onClick}>
                {props.children}
            </button>
            :
            <div className={finalClass}>
                <input type="file" className="LKModalFileUpload" onChange={onChange} />
            </div>
    );
};

export default ModalPopupActionButton;