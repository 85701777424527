import React from 'react';
import InfoField from 'components/Lk/Uikit/InfoField';
import uuid from 'uuid/v4';
import HeaderStep from './HeaderStep';

const AssistantStep = props => {
    const { currentPlan, changeHandler } = props;

    const [assistanceDetail = {fullName: '', position: '', assistanceDetails: ''}] = currentPlan.personGrowthAssistants;

    const onChangeAssistance = name => value => {
        let newRecord = {
            ...assistanceDetail,
            id: assistanceDetail.id || uuid(),
            [name]: value.trim() ? value : '',
        };

        changeHandler('personGrowthAssistants', [newRecord]);
    };

    const checkInvalid = name =>
        (assistanceDetail.fullName ||
            assistanceDetail.position ||
            assistanceDetail.assistanceDetails) &&
        !assistanceDetail[name];

    return (
        <>
            <HeaderStep
                title="4. Кто поможет в развитии"
                info="Шаги 4 и 5 являются не обязательными и заполняются по Вашему усмотрению."
                descr="Не забудьте проинформировать коллегу, что Вы выбрали его наставником для развития выбранных компетенций."
            />
            <InfoField
                isEdit
                title="ФИО"
                invalid={checkInvalid('fullName')}
                value={assistanceDetail.fullName}
                onChange={onChangeAssistance('fullName')}
            />
            <InfoField
                isEdit
                title="Должность"
                invalid={checkInvalid('position')}
                value={assistanceDetail.position}
                onChange={onChangeAssistance('position')}
            />
            <InfoField
                isEdit
                title="Чем поможет"
                invalid={checkInvalid('assistanceDetails')}
                value={assistanceDetail.assistanceDetails}
                onChange={onChangeAssistance('assistanceDetails')}
            />
        </>
    );
};

export default AssistantStep;
