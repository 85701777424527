import ru from 'date-fns/locale/ru';
import React, { useState } from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import './DatePicker.scss';
import DataPickerPortal from './DataPickerPortal';
import ReactDatePicker from 'libs/react-datepicker'
import classnames from 'classnames';

registerLocale('ru', ru);
setDefaultLocale('ru');
const maxDate = new Date('9999 1 1');

function DatePicker(props) {
    const { className, inPortal, noDataBtn, onChange, placeholderText, autoComplete } = props;
    const finalClass = className ? ' ' + className : '';

    const [datePickerRef, setDatePickerRef] = useState(false);

    const closeCalendar = () => {
        datePickerRef.setOpen(false);
    };


    return (
        <ReactDatePicker
            className={finalClass}
            focusSelectedMonth

            autoComplete={autoComplete || "off"}
            maxDate={maxDate}
            popperClassName={classnames({ 'react-datepicker-portal': inPortal })}
            popperContainer={inPortal && DataPickerPortal}
            placeholderText={placeholderText ? placeholderText : noDataBtn ? 'н/д' : undefined}
            ref={r => {
                setDatePickerRef(r);
            }}
            calendarClassName={classnames(props.calendarClassName, {"with-time":props.showTimeSelect})}
            value={props.selected}
            {...props}
        >
            {noDataBtn && (
                <div className="noDataBtn__Container">
                    <button
                        type="button"
                        className="noDataBtn"
                        onClick={() => {
                            closeCalendar();
                            onChange(undefined);
                        }}
                    >
                        {'Нет данных'}
                    </button>
                </div>
            )}
        </ReactDatePicker>
    );
}

export default DatePicker;
