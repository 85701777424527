import { HubConnectionBuilder, HttpTransportType, LogLevel, HubConnectionState } from '@microsoft/signalr';
import { invokers, subscribers } from './socketActions';

const OPEN_HUB = 'hub/OPEN';
const CLOSE_HUB = 'hub/CLOSE';

export const openHub = () => ({type: OPEN_HUB});
export const closeHub = () => ({type: CLOSE_HUB});

const SocketMiddlware = ({ getState, dispatch }) => {
    const hub = new HubConnectionBuilder()
        .withUrl('mainHub', {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets
        }).configureLogging(LogLevel.Error)
        .withAutomaticReconnect()
        .build();

    const invoke = invokers => invokers.map(x => hub.invoke(x));
    const subscribe = subscribers => subscribers.map(x => hub.on(x.name, payload => {
        dispatch(x.action(payload)) ;
    }));
    
    const open = async () => {
        try {
            const { user } = getState().auth;
            if (user) {
                hub.state !== HubConnectionState.Disconnected && await hub.stop();
                await hub.start();
                await invoke(invokers);
                await subscribe(subscribers);
            }
        } catch(e) {
            console.error('middleware error')
        }
    };

    return next => action => {
        switch(action.type) {
            case OPEN_HUB:
                open();
                break;
            case CLOSE_HUB:
                hub.stop();
                break;
            default:
                return next(action);
        }
        return next(action);
    }
};

export default SocketMiddlware;