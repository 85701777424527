import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getAge } from 'utils';
import {
    fullTextSearchSelect,
    fullTextSearchUsers,
    searchPersons,
    searchPersonsWithProgress,
} from '../api';
import { appName } from '../constants';
import RequestError from '../RequestError';
import { showErrorAlert } from './Alert';
import {
    CHILDREN_STATUS,
    DATA_ORIGINS,
    EDUCATIONAL_LEVELS,
    EMPLOYEES_NUMBERS,
    EXPERIENCE_LEVELS,
    FAMILY_STATUS,
    FEDERAL_DISTRICTS,
    GOVT_SERVICE_KINDS,
    IDENTITY_DOCUMENTS,
    INDUSTRIES,
    LANGUAGES,
    LANGUAGE_LEVELS,
    MANAGEMENT_EXPERIENCES,
    MANAGEMENT_LEVELS,
    REGIONS,
    REGION_LOCALITIES,
    SEX,
    WORK_AREAS,
} from './Catalog';
import { withPageLoader } from './PageLoader';

const moduleName = 'search';
export const SEARCH = `${appName}/${moduleName}/SEARCH`;
export const FULL_TEXT_SEARCH = `${appName}/${moduleName}/FULL_TEXT_SEARCH`;
export const FULL_TEXT_USER_SEARCH = `${appName}/${moduleName}/FULL_TEXT_USER_SEARCH`;
export const FETCH_START = `${appName}/${moduleName}/FETCH_START`;
export const FETCH_SUCCESS = `${appName}/${moduleName}/FETCH_SUCCESS`;
export const FETCH_FAILED = `${appName}/${moduleName}/FETCH_FAILED`;
export const UPDATE_PERSON_DATA = `${appName}/${moduleName}/UPDATE_PERSON_DATA`;
export const CLEAR_SEARCH = `${appName}/${moduleName}/CLEAR_SEARCH`;
export const PERSONS_SEARCH = `${appName}/${moduleName}/PERSONS`;

export const personalInfoBlockCatalogs = [
    FEDERAL_DISTRICTS,
    REGIONS,
    REGION_LOCALITIES,
    IDENTITY_DOCUMENTS,
    SEX,
];
export const educationBlockCatalogs = [EDUCATIONAL_LEVELS];
export const workInfoBlockCatalogs = [
    INDUSTRIES,
    WORK_AREAS,
    MANAGEMENT_LEVELS,
    MANAGEMENT_EXPERIENCES,
    EMPLOYEES_NUMBERS,
];
export const governmentServiceInfoBlockCatalogs = [GOVT_SERVICE_KINDS];
export const languagesBlockCatalogs = [LANGUAGES, LANGUAGE_LEVELS];
export const familyStatusBlockCatalogs = [FAMILY_STATUS, CHILDREN_STATUS];
export const personnelInfoBlockCatalogs = [DATA_ORIGINS, EXPERIENCE_LEVELS];
export const allSearchCatalogs = [
    ...personalInfoBlockCatalogs,
    ...educationBlockCatalogs,
    ...workInfoBlockCatalogs,
    ...governmentServiceInfoBlockCatalogs,
    ...languagesBlockCatalogs,
    ...familyStatusBlockCatalogs,
    ...personnelInfoBlockCatalogs,
];

const initialState = {
    loading: false,
    loadComplete: false,
    data: {
        payload: [],
        meta: {
            foundCount: 0,
            pageCount: 0,
        },
    },
    error: '',
};

export default function reducer(state = initialState, action) {
    const { type, payload, error } = action;
    switch (type) {
        case FETCH_START:
            return {
                ...state,
                loading: true,
                loadComplete: false,
                error: '',
            };

        case FETCH_SUCCESS:
            return {
                ...initialState,
                loading: false,
                loadComplete: true,
                data: payload.data,
            };

        case UPDATE_PERSON_DATA:
            const newState = {
                ...state,
                data: {
                    ...state.data,
                    payload: state.data.payload.map((p) =>
                        p.id === payload.person.id
                            ? {
                                  ...p,
                                  ...payload.person,
                              }
                            : p,
                    ),
                },
            };
            return newState;

        case FETCH_FAILED:
            return {
                ...initialState,
                loadComplete: true,
                error: error.message,
                loading: false,
            };
        case CLEAR_SEARCH:
            return { ...initialState };
        default:
            return state;
    }
}

export const search = (criteria) => {
    return {
        type: SEARCH,
        payload: { criteria },
    };
};

export const searchForImport = (criteria) => {
    return {
        type: PERSONS_SEARCH,
        payload: { criteria },
    };
};

export const fullTextSearch = (criteria) => {
    return {
        type: FULL_TEXT_SEARCH,
        payload: { criteria },
    };
};

export const fullTextUserSearch = (criteria) => {
    return {
        type: FULL_TEXT_USER_SEARCH,
        payload: { criteria },
    };
};

export const fetchStart = () => {
    return {
        type: FETCH_START,
    };
};

export const fetchSuccess = (data) => {
    return {
        type: FETCH_SUCCESS,
        payload: { data },
    };
};

export const updatePersonData = (person) => {
    return {
        type: UPDATE_PERSON_DATA,
        payload: { person },
    };
};

export const clearSearch = () => ({ type: CLEAR_SEARCH });

export const fetchFailed = (error) => {
    return {
        type: FETCH_FAILED,
        error,
    };
};

export const allActions = {
    search,
    fetchStart,
    fetchSuccess,
    fetchFailed,
    updatePersonData,
};

export const searchForFavorits = async (criteria) => {
    try {
        const response = await searchPersons(criteria);
        return {
            ok: {
                payload: response,
            },
        };
    } catch (error) {
        return {
            error: {
                message: 'При поиске резервистов произошла ошибка',
                payload: error,
            },
        };
    }
};

export const searchForFavoritsWithProgress = async (criteria) => {
    try {
        const response = await searchPersonsWithProgress(criteria);
        return {
            ok: {
                payload: response,
            },
        };
    } catch (error) {
        return {
            error: {
                message: 'При поиске резервистов произошла ошибка',
                payload: error,
            },
        };
    }
};

export const searchForPersons = async (criteria) => {
    const searchFunc = getSearchApiFuncByCriteria(criteria);

    try {
        const response = await searchFunc(criteria);
        return {
            ok: {
                payload: response,
            },
        };
    } catch (error) {
        return {
            error: {
                message: 'При поиске резервистов произошла ошибка',
                payload: error,
            },
        };
    }
};

export const searchForUsers = async (criteria) => {
    try {
        const response = await fullTextSearchUsers(criteria);
        return {
            ok: {
                payload: response,
            },
        };
    } catch (error) {
        return {
            error: {
                message: 'При поиске пользователей произошла ошибка',
                payload: error,
            },
        };
    }
};

function* searchForImportSaga(action) {
    yield put(fetchStart());

    const { criteria } = action.payload;

    try {
        const response = yield call(withPageLoader, async () => await searchForFavorits(criteria));
        if (response.ok) {
            yield put(fetchSuccess(response.ok.payload.data));
        }
    } catch (e) {
        const reqError = new RequestError(e.payload, e.message);
        yield all([put(fetchFailed(reqError)), put(showErrorAlert(reqError.message))]);
    }
}

function* searchSaga(action) {
    yield put(fetchStart());

    const { criteria } = action.payload;

    try {
        const response = criteria?.noLoader
            ? yield call(searchForPersons, criteria)
            : yield call(withPageLoader, async () => await searchForPersons(criteria));
        if (response.ok) {
            yield put(fetchSuccess(response.ok.payload.data));
        }
    } catch (e) {
        const reqError = new RequestError(e.payload, e.message);
        yield all([put(fetchFailed(reqError)), put(showErrorAlert(reqError.message))]);
    }
}

function* fullTextUserSearchSaga(action) {
    yield put(fetchStart());
    const { criteria } = action.payload;
    const response = yield call(withPageLoader, async () => await searchForUsers(criteria));

    if (response.ok) {
        yield put(fetchSuccess(response.ok.payload.data));
    } else {
        const reqError = new RequestError(response.error.payload, response.error.message);
        yield all([put(fetchFailed(reqError)), put(showErrorAlert(reqError.message))]);
    }
}

export function* saga() {
    yield takeLatest([SEARCH, FULL_TEXT_SEARCH], searchSaga);
    yield takeLatest(FULL_TEXT_USER_SEARCH, fullTextUserSearchSaga);
    yield takeLatest(PERSONS_SEARCH, searchForImportSaga);
}

export const personToCard = (person) => {
    return {
        id: person.id,
        fio: `${person.lastName} ${person.firstName} ${person.middleName}`,
        age: getAge(person.birthDate),
        residence: `${person.region}${person.locality ? ', ' + person.locality : ''}`,
        phone: person.phone,
        email: person.email,
        educationLevel: person.educationLevel,
        university: person.university,
        specialty: person.specialty,
        currentCompany: person.currentCompany,
        currentPosition: person.currentPosition,
        industry: person.industry,
        managementLevel: person.managementLevel,
        managementExperience: person.managementExperience,
        currentGovtServiceKind: person.currentGovtServiceKind,
        currentGovtServiceBranch: person.currentGovtServiceBranch,
        currentGovtServiceRank: person.currentGovtServiceRank,
        isRussianCitizen: person.isRussianCitizen,
        readyMoveToRussia: person.readyMoveToRussia,
        languages: person.languages,
        region: person.region,
        locality: person.locality,
        childrenStatus: person.childrenStatus,
        familyStatus: person.familyStatus,
        socials: person.socials,
        roles: person.roles,
        isFavorite: person.isFavorite,
    };
};

export const selectSearchProps = (searchState) => {
    const { payload, meta } = searchState.data;

    return {
        loadComplete: searchState.loadComplete,
        loading: searchState.loading,
        data: payload.map(personToCard),
        ids: meta.ids,
        foundCount: meta.foundCount,
        pageCount: meta.pageCount,
    };
};

export function selectEmptySearchProps() {
    return {
        loadComplete: true,
        data: [],
        ids: [],
        foundCount: 0,
        pageCount: 1,
    };
}

function getSearchApiFuncByCriteria() {
    return fullTextSearchSelect;
}
