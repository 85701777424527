import { group } from 'routes';

export const mainMenuIds = {
    personInfo: 'personInfo',
    actions: 'actions',
    leaders: 'leaders',
    myAccesses: 'myAccesses',
    accessesForMe: 'accessesForMe',
    issues: 'issues',
    myEvaluations: 'myEvaluations',
    evaluationsForMe: 'evaluationsForMe',
    messages: 'messages',
};

export const nameProps = ['lastName', 'firstName', 'middleName'];

export const userProps = [
    { id: 'roleIds', label: 'Роли', dictionary: 'roles' },
    { id: 'phone', label: 'Телефон' },
    {
        id: 'groupId',
        label: 'Группы',
        dictionary: 'groups',
        link: { route: group, params: [{ key: 'id', propId: 'groupId' }] },
    },
    { id: 'email', label: 'E-mail' },
    { id: 'supportStatus', label: 'Уровень техподдержки', dictionary: 'supportStatus' },
];

export const personProps = [
    { id: 'id', label: 'Id' },
    { id: 'phone', label: 'Телефон' },
    { id: 'email', label: 'E-mail' },
];

export const leaderProps = [
    { id: 'id', label: 'Id', maxWidth: 100, isColumn: true },
    { id: 'phone', label: 'Телефон', isColumn: true },
    { id: 'email', label: 'E-mail', isColumn: true },
    { id: 'groupId', label: 'Группы', dictionary: 'groups', isColumn: true },
];

export const expertProps = [
    { id: 'id', label: 'Id', maxWidth: 100, isColumn: true },
    { id: 'phone', label: 'Телефон', isColumn: true },
    { id: 'email', label: 'E-mail', isColumn: true },
    {
        id: 'groupId',
        label: 'Группы',
        dictionary: 'groups',
        isColumn: true,
        link: { route: group, params: [{ key: 'id', propId: 'groupId' }] },
    },
];

export const accessProps = [
    { id: 'id', label: 'Id' },
    { id: 'requestedOn', label: 'Дата подачи заявки', isColumn: true, isDate: true },
    { id: nameProps, label: 'Пользователь', isColumn: true, inside: 'person', concat: true },
    { id: 'role', label: 'Роль', isColumn: true, dictionary: 'accessRoles' },
    { id: 'email', label: 'E-mail', inside: 'person' },
    { id: 'phone', label: 'Телефон', inside: 'person' },
    {
        id: 'accessRequestDirection',
        label: 'Тип заявки',
        isColumn: true,
        dictionary: 'accessDirectionType',
    },
    { id: 'status', label: 'Статус', isColumn: true, dictionary: 'accessStatus' },
];

export const issueProps = [
    { id: 'id', label: 'Id' },
    { id: 'title', label: 'Задача', isColumn: true },
    { id: 'authorName', label: 'Автор', isColumn: true },
    { id: 'created', label: 'Создана', isDate: true },
    { id: 'dueDate', label: 'Выполнить до', isDate: true, onlyDate: true },
    { id: 'status', label: 'Статус', maxWidth: 150, isColumn: true, dictionary: 'issueStatus' },
    { id: 'isViewed', label: 'Прочитано', isBoolean: true },
];

export const evaluationProps = [
    { id: 'id', label: 'Id' },
    { id: 'evaluationTestName', label: 'Оценка', isColumn: true },
    { id: 'createdOn', label: 'Создана', isDate: true },
    { id: 'requesterUserName', label: 'Запрошена', isColumn: true },
    { id: 'reviewerUserName', label: 'Рассмотрена', isColumn: true },
    { id: 'reviewedOn', label: 'Дата рассмотрения', isDate: true },
    { id: 'reviewRejectionComment', label: 'Комментарий' },
    {
        id: 'status',
        label: 'Статус',
        maxWidth: 150,
        isColumn: true,
        dictionary: 'evaluationRequestStatus',
    },
];

export const mainMenuItems = [
    { id: mainMenuIds.personInfo, title: 'Пользователь', roles: ['admin', 'support'] },
    { id: mainMenuIds.actions, title: 'Действия', roles: ['admin', 'support'] },
    {
        id: mainMenuIds.leaders,
        title: 'Прикрепленные пользователи',
        roles: ['support'],
        props: leaderProps,
    },
    {
        id: mainMenuIds.accessesForMe,
        title: 'Доступы по пользователю',
        roles: ['admin', 'support'],
        props: accessProps,
    },
    {
        id: mainMenuIds.myAccesses,
        title: 'Доступы, запрошенные пользователем',
        roles: ['admin', 'support'],
        props: accessProps,
    },
    { id: mainMenuIds.issues, title: 'Задачи', roles: ['support'], props: issueProps },
    {
        id: mainMenuIds.evaluationsForMe,
        title: 'Заявки на оценку пользователя',
        roles: ['support'],
        props: evaluationProps,
    },
    {
        id: mainMenuIds.myEvaluations,
        title: 'Заявки пользователя на оценку',
        roles: ['support'],
        props: evaluationProps,
    },
    {
        id: mainMenuIds.messages,
        title: 'Сообщения пользователю',
        roles: ['support'],
        //props: evaluationProps,
    },
];
