import React from 'react';
import InfoField from 'components/Lk/Uikit/InfoField';
import Icons from 'components/Lk/Uikit/Icons';
import Loader from 'components/common/Loader';
import WithKeyboardHandler from 'components/Lk/Hooks/WithKeyboardHandler';
import LoadingUrlBlocker from 'components/Lk/Common/LoadingUrlBlocker';
import UnloadBlocker from 'components/Lk/Common/UnloadBlocker/UnloadBlocker';
import './EditPersonComment.scss';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { refreshSearchPersons } from 'ducks/ExternalSearch';
import ConfirmButtons from './ConfirmButtons';
import ConfirmModal from './ConfirmModal';
import RadioVisible from './RadioVisible';
import { isDevice } from 'utils';

const MODAL_ACTION = {
    SAVE: 'save',
    SHOW: 'show',
};

class EditPersonComment extends React.Component {
    state = {
        comment: this.props.comment ? this.props.comment : { text: '', visibleToAll: false },
        initialComment: this.props.comment
            ? { ...this.props.comment }
            : { text: '', visibleToAll: false },
        loading: false,
        openConfirmationModal: false,
        isUrlBlocker: false,
        forceClose: false,
        modalAction: null,
        redirectUrl: null,
    };

    onCancel = (e) => {
        e.preventDefault();
        this.props.onClose && this.props.onClose();
    };

    onCloseModal = () => {
        this.setState({ openConfirmationModal: false });

        return;
    };

    setComment = async () => {
        this.props.onSubmit &&
            (await this.props.onSubmit(this.props.personIds, true, this.state.comment));
        this.props.onClose && this.props.onClose(true);
    };

    getModalWindow = (url) => {
        this.setState({
            openConfirmationModal: true,
            isUrlBlocker: true,
            modalAction: MODAL_ACTION.SHOW,
            redirectUrl: url,
        });

        this.props.onModalShown && this.props.onModalShown(true);
    };

    onOkModal = async () => {
        const { modalAction, redirectUrl } = this.state;

        switch (modalAction) {
            case MODAL_ACTION.SHOW:
                this.setState({ openConfirmationModal: false, forceClose: true }, () => {
                    this.props.push(redirectUrl);
                    this.props.onModalShown && this.props.onModalShown(false);
                });
                return;
            case MODAL_ACTION.SAVE:
                this.saveComment();
                return;
            default:
                return;
        }
    };

    saveComment = async () => {
        await this.setComment();

        this.onCloseModal();
    };

    openModal = () => {
        this.setState({
            openConfirmationModal: true,
            isUrlBlocker: false,
            modalAction: MODAL_ACTION.SAVE,
        });

        this.props.onModalShown && this.props.onModalShown(true);
    };

    onTextChange = (value) => this.setState({ comment: { ...this.state.comment, text: value } });
    onVisibleChnage = (value) =>
        this.setState({ comment: { ...this.state.comment, visibleToAll: value } });

    onSubmitButton = (e) => {
        const {
            comment: { visibleToAll },
        } = this.state;
        e.preventDefault();

        if (visibleToAll) {
            this.openModal();
            return;
        }
        this.setComment();
    };

    onClear = () => () => {
        this.setState({ comment: { ...this.state.comment, text: '' } });
    };

    clearAction = {
        icon: <Icons name="deleteIcon" />,
        iconClick: this.onClear,
    };

    render() {
        const {
            comment: { text, visibleToAll },
            initialComment,
            openConfirmationModal,
            loading,
            isUrlBlocker,
            forceClose,
        } = this.state;
        const { isFocused, withStickyFooter, withScroll, externalInfo } = this.props;
        const isChanges =
            initialComment.text !== text || initialComment.visibleToAll !== visibleToAll;

        return loading ? (
            <Loader />
        ) : (
            <UnloadBlocker isSaving={isChanges}>
                <LoadingUrlBlocker
                    isLoading={!forceClose && isChanges}
                    onLoad={this.getModalWindow}
                    message={false}
                />
                <div className="LKEditPersonCommentWrapper">
                    <div
                        className={classnames('LkEditPersonComment', {
                            'LkEditPersonComment--isFocus':
                                isFocused && withScroll && withStickyFooter,
                            'LkEditPersonComment--scroll': withScroll,
                        })}
                        id="EditPersonComment"
                    >
                        <div className="LkEditPersonComment__Item">
                            <div className="LKEditPersonCommentVisibleSelector">
                                <div className="LKEditPersonCommentVisibleSelector__Item LKEditPersonCommentVisibleSelector__Item--header">
                                    Настройка видимости создаваемой заметки
                                </div>
                                <RadioVisible
                                    onClick={() => this.onVisibleChnage(true)}
                                    checked={visibleToAll}
                                    title="Общая"
                                    subtitle="Доступна всем"
                                />
                                <RadioVisible
                                    onClick={() => this.onVisibleChnage(false)}
                                    checked={!visibleToAll}
                                    title="Персональная"
                                    subtitle="Доступна только Вам"
                                />
                            </div>
                        </div>
                        <div className="LkEditPersonComment__Item">
                            <InfoField
                                type="textarea"
                                isEdit
                                noIcon
                                noBorder
                                inputProps={{
                                    maxLength: 250,
                                    rows: 3,
                                    dataTest: 'LkEditPersonComment__Item--input',
                                }}
                                maxLength={250}
                                iconToTop={true}
                                placeholder="Ваша заметка..."
                                value={text}
                                rightActionIcon={text.trim() && this.clearAction}
                                onChange={this.onTextChange}
                            />
                        </div>
                        <div className="LkEditPersonComment__HelpMessage">
                            <div>Можно ввести не более 250 символов.</div>
                            <div>&nbsp;{text ? 'Вы ввели ' + text.length : ''}</div>
                        </div>
                        {externalInfo}

                        <ConfirmButtons
                            withStickyFooter={withStickyFooter}
                            disabled={!text.trim() || !isChanges}
                            isFocused={isDevice.SM() && isFocused}
                            onCancel={this.onCancel}
                            onSubmitButton={this.onSubmitButton}
                        />
                    </div>
                </div>
                <ConfirmModal
                    isOpen={openConfirmationModal}
                    onConfrim={this.onOkModal}
                    onClose={this.onCloseModal}
                    isUrlBlocker={isUrlBlocker}
                />
            </UnloadBlocker>
        );
    }
}

EditPersonComment.propTypes = {
    comment: PropTypes.object,
    withStickyFooter: PropTypes.bool,
    withScroll: PropTypes.bool,
    onModalShown: PropTypes.func,
};

EditPersonComment.defaultProps = {
    withStickyFooter: true,
    withScroll: true,
};

const actions = {
    push,
    refreshSearchPersons,
};

export default connect(null, actions)(WithKeyboardHandler(EditPersonComment, 'EditPersonComment'));
