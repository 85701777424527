import React, { Component } from 'react';
import InputMask from '../InputMask';
import { phoneMaskWithoutRegion, dialCode } from 'constants.js';
import './PhoneInput.scss';
import Select from '../Select';
import { isNullOrWhitespace } from 'utils';

class PhoneInput extends Component {
    onlyPhoneNumbers = this.props.value ? this.props.value.replace(/[^0-9]/g, '') : null;
    regionCode = this.onlyPhoneNumbers && this.onlyPhoneNumbers.length > 10 ? '+' + this.onlyPhoneNumbers.replace(/[^0-9]/g, '').slice(0, -10) : null;
    phone = this.props.value && this.regionCode ? this.props.value.slice(this.regionCode.length) : '';
    
    state = {
        regionValue: this.regionCode && dialCode.find(c => c.id === this.regionCode) ? this.regionCode : '+7',
        phoneValue: this.phone,
        regionCodeFromProps: this.props.value ? this.props.value.replace(/[^0-9]/g, '') : null
    };

    handlePhoneChange = (phone) => {
        if (isNullOrWhitespace(phone)) {
            this.props.onChange('');
            this.setState({ phoneValue: '' });
            return;
        }
        
        let region = this.state.regionValue;
        if (region && this.props.onChange) {
            let fullPhone = region.concat(' ', phone.trim());
            this.props.onChange(fullPhone);
        }

        this.setState({ phoneValue: phone });
    };

    handleRegionChange = (region) => {
        let phoneValue = this.state.phoneValue ? this.state.phoneValue.trim() : '';
        let regionValue = region.id;
        
        if (regionValue && this.props.onChange) {
            let fullPhone = regionValue.concat(' ', phoneValue);
            this.props.onChange(fullPhone);
        }
        
        this.setState({ regionValue: regionValue });
    };

    render() {
        const { dataTest } = this.props;
        
        return (
            <div className="PhoneInput__Container" data-test={`${dataTest}--select`} >
                <Select
                    className="RegionSelect"
                    id="phoneRegion"
                    value={this.state.regionValue}
                    onChange={e => this.handleRegionChange(e)}
                    items={dialCode}
                    isDisabled={this.props.disabled}
                />
                <InputMask
                    mask={phoneMaskWithoutRegion} maskChar="_"
                    className="PhoneInput"
                    value={this.state.phoneValue}
                    onChange={e => this.handlePhoneChange(e.target.value)}
                    disabled={this.props.disabled}
                />
            </div>

        )
    }
}

export default PhoneInput;