import React from 'react';
import StickyFooter from 'components/Lk/Common/StickyFooter';
import classnames from 'classnames';
import './ManageWishlistsBottomActions.scss';

const ManageWishlistsBottomActions = props => {

    const { buttons = ['Отменить', 'Применить'], cancel = () => {}, apply = () => {}, enabled = true, isFocused } = props;
    
    return (
        <StickyFooter unstick={isFocused}>
            <div className='LKManageWishlistsBottomActions'>
                <div  onClick={cancel} className='LKManageWishlistsBottomActions__ActionButton LKManageWishlistsBottomActions__ActionButton--red'>
                    {buttons[0]}
                </div>
                <div onClick={enabled ? apply : null} className={classnames(
                    'LKManageWishlistsBottomActions__ActionButton LKManageWishlistsBottomActions__ActionButton--blue',
                    {'LKManageWishlistsBottomActions__ActionButton--disabled': !enabled}
                )}>
                    {buttons[1]}
                </div>
            </div>
        </StickyFooter>
    );
};

export default ManageWishlistsBottomActions;