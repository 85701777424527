import React from 'react';
import './IconButton.scss';
import Icons from 'components/Lk/Uikit/Icons';
import Badge from 'components/Lk/Uikit/Badge';
import classnames from 'classnames'

const IconButton = props => {
    const { type, fill, onClick, children, className = '', badge, iconPosition = 'left', fullWidth,  dataTest, autoSize = false, inline = false, ...tail } = props;
    const handleClick = e => {
        e.stopPropagation();
        if (onClick) {
            onClick(e);
        }
    };

    const items = [
        type && <div key="button" className={classnames("IconButton__Icon", {"IconButton__Icon--autosize": autoSize})}>
            <Icons name={type} fill={fill} />
        </div>,
        children,
        badge && <div key="badge"  className="IconButton__Badge">
            <Badge color={badge.color}>{badge.text}</Badge>
        </div>
    ];
    return (
        <button
            className={classnames(
                'IconButton', 
                className, 
                { 
                    'IconButton--full': fullWidth, 
                    "IconButton--autosize": autoSize ,
                    "IconButton--inline": inline,
                }
            )}
            {...tail}
            onClick={handleClick}
            data-test={dataTest}
        >
            {iconPosition === 'left' ? items : items.reverse()}
        </button>
    );
};

export default IconButton;
