import {
    EMPLOYEES_NUMBERS, INDUSTRIES, MANAGEMENT_EXPERIENCES, MANAGEMENT_LEVELS, WORK_AREAS, FEDERAL_DISTRICTS,
    IDENTITY_DOCUMENTS, REGIONS, REGION_LOCALITIES, SEX, EDUCATIONAL_LEVELS, GOVT_SERVICE_KINDS, LANGUAGES, LANGUAGE_LEVELS,
    CHILDREN_STATUS, FAMILY_STATUS, DATA_ORIGINS, EXPERIENCE_LEVELS
} from 'ducks/Catalog';

const SearchMenuJSON = {
    PopularBlockFields: {
        fields: [
            {
                id: 0,
                visible: true,
                label: 'Возраст',
                type: 'InputGroup',
                bind: 'personalInfo',
                'InputGroup': [
                    {
                        mask: '999',
                        maskChar: null,
                        value: 'ageStart',
                        placeholder: "c",
                        bind: 'personalInfo'
                        
                    },
                    {
                        mask: '999',
                        maskChar: null,
                        value: 'ageEnd',
                        placeholder: "по",
                        bind: 'personalInfo'
                    }
                ]
            },
            {
                id: 1,
                visible: true,
                label: 'Место работы',
                type: 'Input',
                value: 'company',
                bind: 'workInfo',
                withCurrent: true,
                withCurrentText: 'только текущее',
            },
            {
                id: 2,
                visible: true,
                label: 'Должность',
                type: 'Input',
                value: 'position',
                bind: 'workInfo',
                withCurrent: true,
                withCurrentText: 'только текущая',
            },
            {
                id: 3,
                visible: true,
                label: 'Отрасль',
                type: 'Select',
                inputId: "selectedIndustry",
                value: 'selectedIndustry',
                options: INDUSTRIES,
                bind: 'workInfo'
            },
            {
                id: 4,
                visible: true,
                label: 'Регион',
                type: 'Select',
                inputId: "regions",
                value: 'selectedRegions',
                options: REGIONS,
                onChange: 'handleRegionChange',
                isMulti: true,
                bind: 'personalInfo',
                checked:'checkedRegions',
                onCheck:'handleRegionCheckChange',
                withCurrent: true,
                withCurrentText: 'только текущий',
            },
            {
                id: 5,
                visible: true,
                label: 'Уровень менеджмента',
                type: 'Select',
                inputId: "selectedManagementLevel",
                value: 'selectedManagementLevel',
                options: MANAGEMENT_LEVELS,
                bind: 'workInfo'
            },

        ]
    },
    PersonalInfoBlock: {
        blockName: 'personalInfo',
        title: 'Личные данные',
        defaultIsOpen: false,
        fields: [
            {
                id: 0,
                visible: true,
                label: 'Фамилия',
                type: 'Input',
                value: 'lastName',
                maxLength: 100
            },
            {
                id: 1,
                visible: true,
                label: 'Имя',
                type: 'Input',
                value: 'firstName',
                maxLength: 100
            },
            {
                id: 2,
                visible: true,
                label: 'Отчество',
                type: 'Input',
                value: 'middleName',
                maxLength: 100
            },
            {
                id: 3,
                visible: false,
                label: 'Возраст',
                type: 'InputGroup',
                value: 'age',
                'InputGroup': [
                    {
                        mask: '999',
                        maskChar: null,
                        value: 'ageStart',
                        placeholder: "c"
                    },
                    {
                        mask: '999',
                        maskChar: null,
                        value: 'ageEnd',
                        placeholder: "по"
                    }
                ]
            },
            {
                id: 4,
                visible: true,
                label: 'Пол',
                type: 'Toggle',
                value: 'selectedSex',
                options: SEX,
                htmlId: 'search-page-filter-sex-',
            },
            {
                id: 5,
                visible: true,
                label: 'Документ',
                type: 'Select',
                inputId: "documentType",
                value: 'selectedDocument',
                options: IDENTITY_DOCUMENTS,
            },
            {
                id: 6,
                visible: true,
                label: 'Номер документа',
                type: 'InputMask',
                htmlFor: "documentNumber",
                mask: 'selectedDocument',
                maskFunc: 'getDocumentNumberFormat',
                value: 'documentNumber',
            },
            {
                id: 7,
                visible: 'isForeignDocument',
                visibleAtributs: 'selectedDocument',                
                label: 'Название документа',
                type: 'Input',
                value: 'documentName',
                maxLength: 200
            },
            {
                id: 8,
                visible: true,
                label: 'Место рождения',
                type: 'Input',
                value: 'birthPlace',
                maxLength: 200
            },
            {
                id: 9,
                visible: true,
                label: 'Телефон',
                type: 'Input',
                value: 'phone',
                maxLength: 100
            },
            {
                id: 10,
                visible: true,
                label: 'E-mail',
                type: 'Input',
                value: 'email',
                maxLength: 100
            },
            {
                id: 11,
                visible: true,
                label: 'Федеральный округ',
                type: 'Select',
                inputId: "federalDistricts",
                value: 'selectedDistricts',
                options: FEDERAL_DISTRICTS,
                onChange: 'handleDistrictChange',
                isMulti: true
            },
            {
                id: 12,
                visible: true,
                label: 'Населенный пункт',
                type: 'Select',
                inputId: "localities",
                value: 'selectedLocalities',
                options: REGION_LOCALITIES,
                onInputChange: 'handleLocalityChange',
                onChange: 'selectLocality',
                isMulti: true,
            },
            // TODO: Надо потом подумать как это правильно прикрутить к предыдущим местам проживания
            /* {
                id: 13,
                visible: true,
                label: 'Год начала проживания',
                type: 'InputGroup',
                value: 'residenceStartYear',
                'InputGroup': [
                    {
                        mask: '9999',
                        maskChar: null,
                        value: 'residenceStartYearStart',
                        placeholder: "c"
                    },
                    {
                        mask: '9999',
                        maskChar: null,
                        value: 'residenceStartYearEnd',
                        placeholder: "по"
                    }
                ]
            }, */
            {
                id: 14,
                visible: true,
                label: 'Биографическая справка',
                type: 'Input',
                value: 'extraInfo'
            }
        ]
    },
    EducationBlock: {
        blockName: 'educationInfo',
        title: 'Образование',
        defaultIsOpen: false,
        fields: [
            {
                id: 1,
                visible: true,
                label: 'Уровень',
                type: 'Select',
                inputId: "selectedEducationLevel",
                value: 'selectedEducationLevel',
                options: EDUCATIONAL_LEVELS,
            },
            {
                id: 2,
                visible: true,
                label: 'ВУЗ',
                type: 'Input',
                value: 'university'
            },
            {
                id: 3,
                visible: true,
                label: 'Специальность',
                type: 'Input',
                value: 'specialty'
            },
            {
                id: 4,
                visible: true,
                label: 'Год окончания',
                type: 'InputGroup',
                'InputGroup': [
                    {
                        mask: '9999',
                        maskChar: null,
                        value: 'graduationYearStart',
                        placeholder: "c"
                    },
                    {
                        mask: '9999',
                        maskChar: null,
                        value: 'graduationYearEnd',
                        placeholder: "по"
                    }
                ]
            },
            {
                id: 5,
                visible: true,
                label: 'Повышение квалификации и другая информация о дополнительном образовании',
                type: 'Input',
                value: 'extraInfo'
            }
        ]
    },
    WorkBlock: {
        blockName: 'workInfo',
        title: 'Опыт работы',
        defaultIsOpen: false,
        fields: [
            {
                id: 0,
                visible: false,
                label: 'Место работы',
                type: 'Input',
                value: 'company'
            },
            {
                id: 1,
                visible: false,
                label: 'Должность',
                type: 'Input',
                value: 'position'
            },
            {
                id: 2,
                visible: false,
                label: 'Отрасль',
                type: 'Select',
                inputId: "selectedIndustry",
                value: 'selectedIndustry',
                options: INDUSTRIES,
            },
            {
                id: 3,
                visible: true,
                label: 'Область деятельности',
                type: 'Select',
                inputId: "selectedWorkArea",
                value: 'selectedWorkArea',
                options: WORK_AREAS,
            },
            {
                id: 4,
                visible: false,
                label: 'Уровень менеджмента',
                type: 'Select',
                inputId: "selectedManagementLevel",
                value: 'selectedManagementLevel',
                options: MANAGEMENT_LEVELS,
            },
            {
                id: 5,
                visible: true,
                label: 'Опыт управления',
                type: 'Select',
                inputId: "selectedExperience",
                value: 'selectedExperience',
                options: MANAGEMENT_EXPERIENCES,
            },
            {
                id: 6,
                visible: true,
                label: 'Кол-во человек в подчинении',
                type: 'Select',
                inputId: "selectedEmployeesNumber",
                value: 'selectedEmployeesNumber',
                options: EMPLOYEES_NUMBERS,
            },
            {
                id: 7,
                visible: true,
                label: 'Год трудоустройства',
                type: 'InputGroup',
                'InputGroup': [
                    {
                        mask: '9999',
                        maskChar: null,
                        value: 'hireYearStart',
                        placeholder: "c"
                    },
                    {
                        mask: '9999',
                        maskChar: null,
                        value: 'hireYearEnd',
                        placeholder: "по"
                    }
                ]
            },
            {
                id: 8,
                visible: true,
                label: 'Профессиональные достижения',
                type: 'Input',
                value: 'professionalAchievements'
            },
        ]
    },
    GovernmentServiceBlock: {
        blockName: 'governmentServiceInfo',
        title: 'Сведения о службе',
        defaultIsOpen: false,
        fields: [
            {
                id: 0,
                visible: true,
                label: 'Тип службы',
                type: 'Select',
                inputId: "selectedGovernmentServiceKind",
                value: 'selectedGovernmentServiceKind',
                options: GOVT_SERVICE_KINDS,
            },
            {
                id: 1,
                visible: true,
                label: 'Вид службы (род войск)',
                type: 'Input',
                value: 'branch',
                maxLength: 250
            },
            {
                id: 2,
                visible: true,
                label: 'Должность',
                type: 'Input',
                value: 'position',
                maxLength: 250
            },
            {
                id: 3,
                visible: true,
                label: 'Звание',
                type: 'Input',
                value: 'rank',
                maxLength: 250
            },
            {
                id: 4,
                visible: true,
                label: 'Год начала службы',
                type: 'InputGroup',
                'InputGroup': [
                    {
                        mask: '9999',
                        maskChar: null,
                        value: 'startYearFrom',
                        placeholder: "c"
                    },
                    {
                        mask: '9999',
                        maskChar: null,
                        value: 'startYearTo',
                        placeholder: "по"
                    }
                ]
            },
        ]
    },
    EvaluationBlock: {
        blockName: '',
        title: 'Результаты оценки',
        defaultIsOpen: false,
        fields: [
            {
                id: 0,
                visible: true,
                type: 'EvaluationBlock',
                value: 'Добавить',
                add: true,
            }
        ]
    },
    LanguagesBlock: {
        blockName: 'languagesInfo',
        title: 'Иностранные языки',
        defaultIsOpen: false,
        fields: [
            {
                id: 0,
                visible: true,
                label: 'Язык',
                type: 'Select',
                inputId: "Язык",
                value: 'language',
                options: LANGUAGES,
                onChange: 'handleOneChange',
            },
            {
                id: 1,
                visible: true,
                label: 'Уровень',
                type: 'Select',
                inputId: "Уровень",
                value: 'languageLevel',
                options: LANGUAGE_LEVELS,
                isDisabled: 'isDisabledLanguageLevel',
                onChange: 'handleOneChange',
            }
        ]
    },
    FamilyStatusBlock: {
        blockName: 'familyInfo',
        title: 'Семейное положение',
        defaultIsOpen: false,
        fields: [
            {
                id: 0,
                visible: true,
                label: 'Статус',
                type: 'Select',
                inputId: "selectedFamilyStatus",
                value: 'selectedFamilyStatus',
                options: FAMILY_STATUS,
            },
            {
                id: 2,
                visible: true,
                label: 'Кол-во детей',
                type: 'Select',
                inputId: "selectedChildrenStatus",
                value: 'selectedChildrenStatus',
                options: CHILDREN_STATUS,
            },
        ]
    },
    PersonnelInfoBlock: {
        blockName: 'personnelInfo',
        title: 'Дополнительная информация',
        defaultIsOpen: false,
        fields: [
            {
                id: 0,
                visible: true,
                label: 'Источник данных',
                type: 'Select',
                inputId: "dataOrigin",
                value: 'selectedDataOrigin',
                options: DATA_ORIGINS,
            },         
            {
                id: 2,
                visible: true,
                label: 'Уровень подготовленности участника',
                type: 'Select',
                inputId: "experienceLevel",
                value: 'selectedExperienceLevel',
                options: EXPERIENCE_LEVELS,
            },
            {
                id: 3,
                visible: true,
                label: 'Рекомендатель',
                type: 'Input',
                value: 'recommenderName',
                maxLength: 250
            },
            {
                id: 4,
                visible: true,
                label: 'Куратор',
                type: 'Input',
                value: 'curatorName',
                maxLength: 250
            },
            {
                id: 5,
                visible: true,
                label: 'Социальная активность',
                type: 'Input',
                value: 'socialActivity',
                maxLength: 200
            },
            {
                id: 6,
                visible: true,
                label: 'Членство в общественных организациях',
                type: 'Input',
                value: 'memberOfSocialOrganizations',
                maxLength: 450
            },
            {
                id: 7,
                visible: true,
                label: 'Деятельность в качестве руководителя',
                type: 'Input',
                value: 'socialAchivements',
            },
            {
                id: 8,
                visible: true,
                label: 'Дополнительные сведения',
                type: 'Input',
                value: 'extraInfo',
            },
        ]
    },
    SystemInfoBlock: {
        blockName: 'systemInfo',
        title: 'Системная информация',
        defaultIsOpen: false,
        operation:true,
        fields: [   
            {
                id: 0,
                visible: true,
                label: 'Группа',
                type: 'Select',
                inputId: "Groups",
                value: 'selectedGroups',
                options: 'allowedGroups',
                isMulti: true,
                hideTypeOfAction: true,
                maxLength: 200
            },         
            {
                id: 1,
                visible: true,
                label: 'Подборка',
                type: 'Select',
                inputId: "Wishlists",
                value: 'wishlists',
                options: 'wishlistCatalog',
                isMulti: true,
                hideTypeOfAction: true,

                maxLength: 200
            },
            
           
        ]
    },
};

export default SearchMenuJSON;