import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/uikit/Button';

const Instrument = (props) => {
    const { type, text } = props;

    const generateIntrument = type => {
        let instrumentBody = "";
        switch(type) {
            case "Button":
                instrumentBody = <div className="TemplateInstrument__Body">
                                    <div className="TemplateInstrument__Body--icon"><Button className="TemplateEmailButton" size="sm"></Button></div>
                                    <div className="TemplateInstrument__Body--text">Кнопка</div>
                                </div>
                                ;
                break;
            case "Paragraph":
                instrumentBody = <div className="TemplateInstrument__Body">
                                    <div className="TemplateInstrument__Body--icon"><FontAwesomeIcon icon="align-left" size="2x" /></div>
                                    <div className="TemplateInstrument__Body--text">Параграф</div>
                                </div>;
                break;
            case "UserMessage":
                instrumentBody = <div className="TemplateInstrument__Body">
                                    <div className="TemplateInstrument__Body--icon"><FontAwesomeIcon icon="comment" size="2x" /></div>
                                    <div className="TemplateInstrument__Body--text">Сообщение пользователю</div>
                                </div>;
                break;
            case "Header":
                    instrumentBody = <div className="TemplateInstrument__Body">
                        <div className="TemplateInstrument__Body--icon"><FontAwesomeIcon icon="heading" size="2x" /></div>
                        <div className="TemplateInstrument__Body--text">Заголовок</div>
                    </div>;
                break;                                
            default:
                instrumentBody = <div></div>;
        }
        return (
            <div className="TemplateInstrument" draggable data-type={type} data-text={text}>
                <div className="TemplateInstrument__Body">
                    {instrumentBody}
                </div>
                <div className="TemplateInstrument__Grip">
                    <FontAwesomeIcon icon="grip-vertical" />
                </div>
            </div>
        );
    };

    return generateIntrument(type);
    
    
};

Instrument.propTypes = {
    type: PropTypes.string.isRequired,
    text: PropTypes.string,
};

export default Instrument;