import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { showErrorAlert } from 'ducks/Alert';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { searchCompetencyModels, canCreateModel } from 'api';
import {
    competenceModelEditRoute,
    competenceModelNewRoute,
    competenceModelCopyRoute,
    competenceModelsRoute,
} from 'routes';
import Button from 'components/uikit/Button';
import Page from 'components/common/Page/Page';
import ListTemplate from 'components/uikit/ListTemplate';
import DataGrid from 'components/common/DataGrid';
import { selectDictionaries, fetchDictionaries } from 'ducks/Dictionary';
import { GROUPS_DICTIONARY_NAME } from 'constants.js';
import { parse as queryStringParse, stringify } from 'query-string';
import useUpdateEffect from 'components/Lk/Hooks/useUpdateEffect';

const getUrlFilterParams = (state) => {
    return {
        page: state.paging.pageNum !== null ? Number(state.paging.pageNum) : 1,
        pageSize: state.paging.pageSize !== null ? Number(state.paging.pageSize) : 10,
        name: state.filter.name,
        groups: !!state.filter.groups ? state.filter.groups : [],
        onlyContainerModels: state.onlyContainerModels,
    };
};

const ModelList = (props) => {
    const {
        showPageLoader,
        hidePageLoader,
        showErrorAlert,
        groups,
        fetchDictionaries,
        userGroupId,
        push,
    } = props;

    const initialCriteria = {
        filter: { name: '', groups: [userGroupId], onlyContainerModels: true },
        sorting: { name: 'asc' },
        paging: { pageNum: 1, pageSize: 10 },
    };

    const getStateFromUrl = () => {
        if (!props.location.search) {
            setCriteria(initialCriteria);
            setFilter(initialCriteria.filter);

            return initialCriteria;
        }
        const { name = '', page = 1, pageSize = 10 } = queryStringParse(props.location.search);
        const queryParams = new URLSearchParams(props.location.search);
        const groups = queryParams.getAll('groups');

        const newCriteria = {
            filter: {
                name,
                groups: groups.map((x) => +x),
                onlyContainerModels: true,
            },
            sorting: { name: 'asc' },
            paging: {
                ...criteria.paging,
                pageSize: +pageSize,
                pageNum: +page,
            },
        };

        setCriteria(newCriteria);
        setFilter(newCriteria.filter);

        return newCriteria;
    };

    const [models, setModels] = useState([]);

    const [filter, setFilter] = useState(initialCriteria.filter);
    const [criteria, setCriteria] = useState(initialCriteria);
    const [stringCriteria, setStringCriteria] = useState(JSON.stringify(initialCriteria));

    const [count, setCount] = useState({ foundCount: 0, pageCount: 0 });
    const [loading, setloading] = useState(true);
    const [userCanCreateModel, setUserCanCreateModel] = useState(false);

    useEffect(() => {
        const fetchModels = async () => {
            try {
                const newCriteria = getStateFromUrl();

                setloading(true);
                showPageLoader();
                const res = await searchCompetencyModels(newCriteria);
                const { payload, meta } = res.data;

                setModels(payload);
                setCount({
                    foundCount: meta.foundCount,
                    pageCount: meta.pageCount,
                });

                const canCreateModelResponse = await canCreateModel();
                setUserCanCreateModel(canCreateModelResponse.data);
            } catch (error) {
                showErrorAlert(error.message);
            } finally {
                hidePageLoader();
                setloading(false);
            }
        };

        fetchModels();

        // eslint-disable-next-line
    }, [
        props.location.search,
        showPageLoader,
        hidePageLoader,
        showErrorAlert,
    ]);

    useUpdateEffect(() => {
        const updateUrl = () => {
            const filter = getUrlFilterParams(JSON.parse(stringCriteria));
            const baseFilter = { ...filter };

            push({
                pathname: competenceModelsRoute.url,
                search: stringify(baseFilter),
            });
        };

        updateUrl();
    }, [stringCriteria, push]);

    useEffect(() => {
        if (!groups) {
            fetchDictionaries([GROUPS_DICTIONARY_NAME]);
        }
    }, [fetchDictionaries, groups]);

    const onPageChange = (pageIndex) => {
        const newCriteria = {
            ...criteria,
            filter,
            paging: { ...criteria.paging, pageNum: pageIndex + 1 },
        };

        setCriteria(newCriteria);
        setStringCriteria(JSON.stringify(newCriteria));
    };

    const changePageAmount = (pageSize) => {
        const newCriteria = {
            ...criteria,
            paging: { pageNum: 1, pageSize },
        };

        setCriteria(newCriteria);
        setStringCriteria(JSON.stringify(newCriteria));
    };

    const onSortedChange = (sorted) => {
        const newCriteria = {
            ...criteria,
            filter,
            sorting: { ...criteria.sorting, [sorted[0].id]: sorted[0].desc ? 'desc' : 'asc' },
        };
        setCriteria(newCriteria);
        setStringCriteria(JSON.stringify(newCriteria));
    };

    const buildColumns = (search) => [
        {
            Header: 'Название моделей компетенций',
            accessor: 'name',
            resizable: false,
            sortable: true,
            Cell: ({ original }) => {
                return (
                    <Link to={competenceModelEditRoute.buildUrl({ id: original.id }, search)}>
                        {original.name}
                    </Link>
                );
            },
        },
        {
            ...(userCanCreateModel && {
                Header: 'Действие',
                maxWidth: 190,
                resizable: false,
                sortable: false,
                Cell: ({ original }) => (
                    <Button
                        size="sm"
                        onClick={() => copyModel(original.id)}
                        className="resourcesEditBtn"
                    >
                        Копировать
                    </Button>
                ),
            }),
        },
        {
            Header: '',
            maxWidth: 190,
            resizable: false,
            sortable: false,
            Cell: ({ original }) =>
                original.isEditable && (
                    <Button
                        size="sm"
                        onClick={() => editModel(original.id)}
                        className="resourcesEditBtn"
                    >
                        Редактировать
                    </Button>
                ),
        },
    ];

    const editModel = (modelId) => {
        push({
            pathname: competenceModelEditRoute.buildUrl({ id: modelId }),
            search: props.location.search,
            state: {
                isEdit: true,
                modelNames: models.map((x) => x.name),
            },
        });
    };

    const copyModel = (modelId) => {
        push({
            pathname: competenceModelCopyRoute.buildUrl({ id: modelId }),
            search: props.location.search,
            state: { modelNames: models.map((x) => x.name), isEdit: true },
        });
    };

    const addNewModel = () => {
        push({
            pathname: competenceModelNewRoute.url,
            search: props.location.search,
            state: { modelNames: models.map((x) => x.name) },
        });
    };

    const headBtn = userCanCreateModel
        ? {
              onClick: addNewModel,
              value: 'Добавить',
              addLink: true,
          }
        : null;

    const handleFilterTitleInputChange = (e) => {
        setFilter({ ...filter, name: e.target.value });
    };

    const handleEnterKeyPressed = (e) => {
        if (e.key === 'Enter') {
            handleFilterTitleInputChange(e);
            const newCriteria = {
                ...criteria,
                filter: filter,
                paging: { ...criteria.paging, pageNum: 1 },
            };

            setCriteria(newCriteria);
            setStringCriteria(JSON.stringify(newCriteria));
        }
    };

    const handleFilterOnClick = () => {
        const newCriteria = {
            ...criteria,
            filter: filter,
            paging: { ...criteria.paging, pageNum: 1 },
        };

        setCriteria(newCriteria);
        setStringCriteria(JSON.stringify(newCriteria));
    };

    const handleClearFilters = (e) => {
        e.preventDefault();
        setFilter(initialCriteria.filter);
        setCriteria(initialCriteria);
        setStringCriteria(JSON.stringify(initialCriteria));
    };

    const handleFilterGroupOnChange = (item) => {
        setFilter({ ...filter, groups: item ? item.map((x) => x.value) : [] });
    };

    const fillSelect = (dictionary) => {
        if (dictionary) {
            return dictionary
                .filter((x) => !x.isScope)
                .map((x) => {
                    return { value: x.id, label: x.name };
                });
        } else {
            return [];
        }
    };

    const customSelectStyles = {
        control: (base) => ({
            ...base,
            backgroundColor: '#f9f9ff',
            borderColor: '#e6dfff',
        }),
        input: (base) => ({
            ...base,
            padding: 0,
            margin: 0,
        }),
        menu: (base) => ({
            ...base,
            color: '#767268',
        }),
        singleValue: (base) => ({
            ...base,
            color: '#767268',
        }),
        valueContainer: (base) => ({
            ...base,
        }),
    };

    const valueGroup =
        groups && fillSelect(groups.filter((x) => filter.groups.indexOf(x.id) !== -1));

    const filterForm = {
        title: 'Фильтр',
        line: [
            {
                type: 'Input',
                label: 'Поиск по наименованию',
                id: 'name',
                name: 'name',
                value: filter.name,
                onChange: handleFilterTitleInputChange,
                onKeyDown: handleEnterKeyPressed,
            },
            {
                type: 'select',
                label: 'Группа',
                value: valueGroup,

                id: 'group',
                name: 'group',
                placeholder: 'Выберите группу',
                options: fillSelect(groups),
                onChange: handleFilterGroupOnChange,
                isMulti: true,
                styles: customSelectStyles,
            },
        ],
        btnOnClick: handleFilterOnClick,
        btnClearOnClick: handleClearFilters,
    };

    return (
        <Page>
            <div className="resources-wrapper">
                <ListTemplate title={'Модели компетенций'} headBtn={headBtn} form={filterForm}>
                    <DataGrid
                        data={models}
                        foundCount={count.foundCount}
                        columns={buildColumns(props.location.search)}
                        loading={loading}
                        showPagination={true}
                        showPageSizeOptions={false}
                        onSortedChange={onSortedChange}
                        pages={count.pageCount}
                        page={criteria.paging.pageNum}
                        onPageChange={onPageChange}
                        manual
                        pageSize={criteria.paging.pageSize}
                        changePageAmount={changePageAmount}
                    />
                </ListTemplate>
            </div>
        </Page>
    );
};

const actions = { showErrorAlert, showPageLoader, hidePageLoader, push, fetchDictionaries };

const mapStateToProps = (state) => {
    const dictionaries = selectDictionaries(state.dictionary, GROUPS_DICTIONARY_NAME);
    return {
        groups: dictionaries,
        userGroupId: state.auth.user.groupId,
    };
};

export default connect(mapStateToProps, actions)(ModelList);
