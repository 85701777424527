import React from "react";
import classnames from "classnames";
import "./InputText.scss";

function InputText(props) {
    const { className, value, forwardRef, selectionchange, ...tale } = props;
    
    const finalClass = classnames("InputText", className);

    return (
        <textarea 
            {...tale}
            ref={forwardRef}
            type="textarea"
            className={finalClass}
            value={value || ''}
            onSelect={selectionchange}
        />
    );
}

export default InputText;
