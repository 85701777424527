import { appName } from 'constants.js';
import { takeLatest, put, call } from 'redux-saga/effects';
import { getIssueActiveAmount } from '../api';
import RequestError from '../RequestError';
import { showErrorAlert } from './Alert';

const moduleName = 'events';
const ISSUES_AMOUNT_FETCH_START = `${appName}/${moduleName}/ISSUES_AMOUNT_FETCH_START`;
const ISSUES_AMOUNT_FETCH_SUCCESS = `${appName}/${moduleName}/ISSUES_AMOUNT_FETCH_SUCCESS`;
const ISSUES_AMOUNT_FETCH_ERROR = `${appName}/${moduleName}/ISSUES_AMOUNT_FETCH_ERROR`;

const initialState = {
    menu: {
        issuesAmount: 0,
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ISSUES_AMOUNT_FETCH_START:
            return state;

        case ISSUES_AMOUNT_FETCH_SUCCESS:
            return state;
        case ISSUES_AMOUNT_FETCH_ERROR:
            return state;

        default:
            return state;
    }
}

export function fetchIssuesAmount() {
    return {
        type: ISSUES_AMOUNT_FETCH_START,
    };
}

const fetchIssuesAmountSuccess = data => {
    return {
        type: ISSUES_AMOUNT_FETCH_SUCCESS,
        payload: data,
    };
};

const fetchIssuesAmountError = data => {
    return {
        type: ISSUES_AMOUNT_FETCH_ERROR,
        payload: { data },
    };
};

function* fetchIssuesAmountSaga() {
    try {
        const response = yield call(getIssueActiveAmount);
        yield put(fetchIssuesAmountSuccess(response.data));
    } catch (error) {
        const reqError = new RequestError(error, 'При загрузке количества задач произошла ошибка');

        yield put(fetchIssuesAmountError(reqError));
        yield put(showErrorAlert(reqError.message));
    }
}

export function* saga() {
    yield takeLatest(ISSUES_AMOUNT_FETCH_START, fetchIssuesAmountSaga);
}
