import ru from 'date-fns/locale/ru';
import React, { useEffect, useState, useCallback } from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import './DatePicker.scss';
import DataPickerPortal from './DataPickerPortal';
import classnames from 'classnames';
import MobileDatePicker from 'react-mobile-datepicker';
import ProfileButton from '../Buttons/Button/ProfileButton';
import { formatDate } from 'utils';

import ReactDatePicker from 'libs/react-datepicker';
import Icons from '../Icons';
import Loader from 'components/common/Loader';

registerLocale('ru', ru);
setDefaultLocale('ru');
const maxDate = new Date('9999 1 1');

const defaultDateConfig = {
    year: {
        format: 'YYYY',
        step: 1,
    },
    month: {
        format: 'MM',
        step: 1,
    },
    date: {
        format: 'DD',
        step: 1,
    },
};

function DatePicker(props) {
    const {
        className = '',
        inPortal,
        selected,
        onChange,
        placeholder,
        dateFormat = 'dd.MM.yyyy',
        mobileFormat = dateFormat,
        showMonthYearPicker = false,
        dateConfig = defaultDateConfig,
        isClearable,
        minDate,
        minDateMobile,
        dataTest,
        openToDate,
        openTime = 300,
        ...tail
    } = props;
    const finalClass = classnames('LKDatePicker', className);
    const [isMobile, setMobile] = useState(false);
    const [value, setValue] = useState(selected || new Date());
    const [isOpen, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const windowWidth = window.innerWidth;

    const onResize = useCallback(() => {
        const isMobileWidth = window.innerWidth <= 500;

        if (isMobile !== isMobileWidth) {
            setMobile(isMobileWidth);
            setOpen(false);
        }
    }, [isMobile]);

    useEffect(() => {
        setValue(selected);
        onChangeDate(selected);
        // eslint-disable-next-line
    }, [dateConfig]);

    const onOpenMobile = () => {
        if (loading) {
            return;
        }
        setTimeout(() => {
            setOpen(true);
            setLoading(false);
        }, openTime);
        setLoading(true);
    };

    useEffect(() => {
        window.addEventListener('resize', onResize);
        onResize();
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [windowWidth, onResize]);

    const onChangeDate = (date) => {
        onChange(date ? formatDate(date, 'YYYY-MM-DD HH:mm:ss') : date);
    };

    const onSelect = (date) => {
        onChangeDate(date);
        setOpen(false);
    };

    const blockInputHandler = (e) => {
        e.preventDefault();
    };

    const onCancel = () => {
        setValue(selected);
        setOpen(false);
    };

    const clearDate = (e) => {
        e.stopPropagation();
        setValue(null);
        onChangeDate(null);
    };

    const format = [].concat(dateFormat).concat(['ddMMyyyy', 'dd MM yyyy']);

    return (
        <div className="LKDatePicker" data-test={dataTest}>
            {isMobile ? (
                <>
                    <Loader overlay show={loading} />
                    <ProfileButton
                        onClick={onOpenMobile}
                        className="LKDataPickerMobile"
                        dataTest="LKDataPickerMobile_AddDate--button"
                    >
                        {(selected && formatDate(selected, mobileFormat)) || 'Добавить'}
                        {selected && isClearable && (
                            <div className="LKDataPickerMobile__Icon" onClick={clearDate}>
                                <Icons name="cross" />
                            </div>
                        )}
                    </ProfileButton>
                    <MobileDatePicker
                        min={minDateMobile || new Date(minDate)}
                        max={maxDate}
                        isOpen={isOpen}
                        isMobile={isMobile}
                        dateConfig={dateConfig}
                        theme={'ios'}
                        confirmText="ОК"
                        cancelText="Отменить"
                        headerFormat={mobileFormat}
                        onChange={setValue}
                        onSelect={onSelect}
                        onCancel={onCancel}
                        {...tail}
                        value={value || (openToDate ? openToDate : new Date())}
                    />
                </>
            ) : (
                <ReactDatePicker
                    className={finalClass}
                    showMonthYearPicker={showMonthYearPicker}
                    maxDate={maxDate}
                    onChangeRaw={showMonthYearPicker ? blockInputHandler : undefined}
                    popperClassName={classnames({ 'react-datepicker-portal': inPortal })}
                    popperContainer={inPortal && DataPickerPortal}
                    placeholderText={placeholder}
                    {...props}
                    onChange={onChangeDate}
                    dateFormat={format}
                    openToDate={openToDate}
                />
            )}
        </div>
    );
}

export default DatePicker;
