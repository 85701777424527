import React, { useEffect} from 'react';
import Instrument from './Instrument'

const InstrumentsPane = () => {
    useEffect(() => {
        const handleDragStart = e => {
            e.target.style.opacity = '0.4';
            const data = {
                type: e.target.dataset.type,
                text: e.target.dataset.text,
            }
            e.dataTransfer.setData("text", JSON.stringify(data));
        }
        const handleDragEnd = e => e.target.style.opacity = '1';

        const instruments = document.querySelectorAll('.TemplateInstrument');
        for (const i of instruments) {
            i.addEventListener('dragstart', handleDragStart, false);
            i.addEventListener('dragend', handleDragEnd, false);
        }
       
        return () => {
            for (const i of instruments) {
                i.removeEventListener('dragstart', handleDragStart);
                i.removeEventListener('dragend', handleDragEnd);
            }
        };

    }, []);

    return (
        <>
            <Instrument type="Header" text="Заголовок" />
            <Instrument type="Paragraph"  text="Пожалуйста, укажите текст параграфа" />
            <Instrument type="UserMessage"  text="Сообщение пользователю" />
            <Instrument type="Button" className="EditEmailTemplateButton" text="Кнопка" />
        </>
    );
};

export default InstrumentsPane;