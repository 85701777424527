import React, { Component } from 'react';
import Page from "components/common/Page";
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import { importRoute } from '../../routes';
import { ALLOWED_IMPORT_ROLES } from '../../rightsController';
import "./Import.scss";
import ReactDropzone from 'react-dropzone';
import Row from 'components/uikit/Row';
import classnames from "classnames";
import Expander from 'components/common/Expander';
import StepWizard from '../common/StepWizard';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { home } from "routes";
import { allActions } from "ducks/Import";
import { showErrorAlert } from 'ducks/Alert';
import { getImportTemplateLink } from "api";
import { searchForImport, selectSearchProps } from 'ducks/Search';
import { initialize } from 'ducks/Import';
import PersonList from "components/PersonList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/uikit/Button";
import uuid from "uuid/v4";

const pages = {
    LOAD_DATA: "load",
    PREVIEW: "preview",
    RESULT: "result"
}

const InfoBlock = (props) => {

    const className = classnames({
        "ImportPage_InfoBlock": true,
        "ImportPage_InfoBlock__Success": props.loaded && !props.showError,
        "ImportPage_InfoBlock__Unknown": !props.loaded && !props.showError,
        "ImportPage_InfoBlock__Error": props.showError && !props.loaded
    });

    const title = props.loaded
        ? "Данные успешно загружены"
        : props.showError ? "Найдены ошибки в загруженных данных" : "Данные не загружены";


    const errors = (props.showError && props.errors && props.errors.payload && props.errors.payload.groups)
        ? props.errors.payload.groups.map((group, index) => {
            return (
                <Expander key={index} title={group.title}>
                    <ul className="ImportPage__ErrorList">
                        {group.items ? group.items.map((item) => <li key={uuid()}>Строка №{item.row} [{item.column}]: {item.message}</li>) : null}
                        {group.semantics ? group.semantics.map((item) => <li key={uuid()}>{item}</li>) : null}
                    </ul>
                </Expander>
            )
        }) : null;

    return (
        <>
            <div className={className}>{title}</div>
            {props.showError ? <div className="ImportPage__TitleError">Ошибки</div> : null}
            {errors}
        </>
    );
}

const LoadDataPage = (props) => {
    return (
        <>
            <div className="ImportPage__Template">
                <a className="ImportPage__TemplateIcon" download href={getImportTemplateLink()}>
                    <span>xlsx</span>
                    Шаблон для загрузки данных
                </a>
            </div>
            <Row>
                <ReactDropzone
                    onDrop={props.onFilesUpload}
                    children={props.content}
                    onClick={evt => evt.preventDefault()}
                />
            </Row>
            <InfoBlock showResult={true} {...props.info} />
        </>
    )
}

const PreviewPage = (props) => {
    return (
        <PersonList
            persons={props.searchResult.data}
            pageNum={props.paging.pageNum}
            onPaginate={props.handlePaginate}
            pageCount={props.searchResult.pageCount}
            totalCount={props.searchResult.foundCount}
            selection={null}
            onChangeSelection={props.handleSelectionChange}
            editMode={true}
            isImport={true}
        />
    )
}

const ResultPage = () => {
    return (
        <div className="StepWizard__Step">
            <div style={{ padding: 40, display: "flex", justifyContent: "center" }}>
                <h1><FontAwesomeIcon icon="check-circle" /> Данные успешно загружены </h1>
            </div>
        </div>
    )
}

const initialState = {
    selectedId: "load",
    selection: {
        personIds: []
    },
    searchResult: null,
    paging: {
        pageNum: 1,
        pageSize: 10,
    },
}

class Import extends Component {
    state = initialState;

    search = () => {
        if (!this.props.fileId) return;
        
        const criteria = {
            filter: {
                personImportId: this.props.fileId
            },
            paging: this.state.paging,
        };

        this.props.searchForImport(criteria);
    }

    onNextPage = (next, page) => {
        switch (page) {
            case pages.RESULT:
                this.props.newImport();
                break;
            case pages.PREVIEW:
                this.props.saveImport(this.props.fileId, next);
                window.scrollTo(0, 0);
                break;
            default:
                window.scrollTo(0, 0);
                this.props.setStep(next);
        }
    }

    onPreviousPage = (next, page) => {
        switch (page) {
            case pages.RESULT:
                this.props.push(home.buildUrl());
                break;
            default:
                window.scrollTo(0, 0);
                this.props.setStep(next);
        }
    }

    onCancel = () => {
        this.props.cancelImport(this.props.fileId);
    }

    previewIsValid = () => {
        return true;
    }

    onValid = exp => {
        return exp;
    };

    componentDidMount() {
        this.search();
    }

    componentDidUpdate(prevProps) {
        if (this.props.fileId !== prevProps.fileId) {
            this.search();
        }
    }

    handlePaginate = (pageNum) => {
        const paging = { ...this.state.paging, pageNum };

        this.setState({ paging }, () => {
            this.search();
            window.scrollTo(0, 0);
        });
    }

    handleSelectionChange = (selection) => {
        this.setState({ selection });
    }

    onFilesUpload = (files) => {
        if (files && files.length > 1) {
            this.props.showErrorAlert("Можно загрузить только один файл");
            return;
        }

        this.props.uploadImportFile(files[0]);
    }

    renderContent = ({ getRootProps, getInputProps, isDragActive, open }) => {

        if (this.props.upload) {
            return (
                <div className="ImportPage__DropZone ImportPage__DropZone_Upload">
                    <FontAwesomeIcon icon="circle-notch" spin />
                </div>
            )
        }

        if (this.props.fileLoaded) {
            return (
                <div className="ImportPage__DropZone">
                    <div className="ImportPage__DropZoneDisabledText">Файл уже загружен</div>
                </div>
            )
        }

        return (
            <div {...getRootProps()} className="ImportPage__DropZone">
                <input {...getInputProps()} />
                <div className="ImportPage__DropZoneText">
                    {isDragActive ? "Отпустите шаблон для загрузки" : "Перетащите заполненный шаблон сюда или"}
                </div>
                {isDragActive ? null : <div>
                    <Button size="sm" onClick={open}>Выберите файл</Button>
                    <div> <small>* Размер файла не должен превышать 100 Мб</small></div>
                    </div>}
            </div>
        );
    }

    render() {
        if (!this.props.showImport) {
            return (
                <Page>
                    <h3 className="mt-3 mb-3">
                        Раздел находится в разработке
                    </h3>
                </Page>
            )
        }
        const { searchResult } = this.props;
        const { selection, sorting, paging } = this.state;
        const steps = [
            {
                id: pages.LOAD_DATA, title: "Загрузка данных", content: () =>
                    <LoadDataPage files={[]}
                        content={this.renderContent}
                        disabled={this.props.disableFileDrop}
                        onFilesUpload={this.onFilesUpload}
                        info={{
                            loaded: this.props.fileLoaded,
                            showError: this.props.showError,
                            errors: this.props.fileErrors
                        }}
                    />
                , isValid: () => !!this.props.fileId
            },
            {
                id: pages.PREVIEW, title: "Предварительный просмотр", content: () => <PreviewPage
                    selection={selection}
                    sorting={sorting}
                    paging={paging}
                    searchResult={searchResult}
                    handlePaginate={this.handlePaginate}
                    handleSelectionChange={this.handleSelectionChange}
                />,
                nextText: "Сохранить"
            },
            {
                id: pages.RESULT, title: "Результат импорта", content: () => <ResultPage />, previousText: "На главную",
                nextText: "Новый импорт", isValid: this.previewIsValid
            },
        ];

        return (
            <Page id="ImportPage" className="ImportPage" w1790>
                <StepWizard steps={steps}
                    onNextPage={this.onNextPage}
                    onPreviousPage={this.onPreviousPage}
                    onCancel={this.onCancel}
                    initialize={this.props.initialize}
                    wizard={this.props.wizard}
                    disableCancel={!!this.props.fileId === false}
                    disableTitle={"Отменить импорт"}
                    onValid={this.onValid}
                />
            </Page>
        );
    }
}

const props = state => {
    const searchResult = selectSearchProps(state.search);
    return {
        fileId: state.import.fileId,
        fileErrors: state.import.fileErrors,
        fileLoaded: !!state.import.fileId,
        upload: state.import.upload,
        showImport: state.config.loadComplete,
        showResult: !!state.import.fileId || !!state.import.fileErrors,
        showError: state.import.fileErrors
            && state.import.fileErrors.payload
            && state.import.fileErrors.payload.groups,
        searchResult,
        wizard: state.import
    }
}

const actions = {
    ...allActions,
    showErrorAlert,
    push,
    searchForImport,
    initialize
}

const protect = (Component) => () => <ProtectedRoute path={importRoute.url} component={Component} allowedRoles={ALLOWED_IMPORT_ROLES} />

export default protect(connect(props, actions)(Import));