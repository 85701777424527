import React from 'react';
import Select from 'react-select';
const CustomSelect = props => {
    
    const attachClearIndicatorTouchEndHandler = ref => {
        if (ref) {
            ref.select.onClearIndicatorTouchEnd = () => {
                ref.onChange(null);
            };
        }
    };

    return (
        <Select
            {...props}
            noOptionsMessage={() =>'Нет данных'}
            ref={!props.isSearchable && props.isClearable ? attachClearIndicatorTouchEndHandler : null}
            styles={{
                ...props.styles,
                menu: base => ({ ...base, zIndex: 20 }),
                menuList: base => ({
                    ...base,
                    fontFamily: 'Roboto',
                    color: '#595959',
                    fontSize: '14px',
                    lineHeight: '22px',
                    zIndex: 30,
                }),
            }}
        />
    );
};

export default CustomSelect;
