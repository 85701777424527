import { appName } from '../constants';
import { takeLatest, put, call, all, select, takeEvery } from 'redux-saga/effects';
import { getCustomAttribute, addCustomAttribute, editCustomAttribute } from '../api';
import RequestError from '../RequestError';
import { showErrorAlert } from './Alert';
import { attributes as attributesRoute } from 'routes';
import { push } from 'connected-react-router';
import { needUpdateAttributes } from 'ducks/Attributes';
import { serviceResultCode, getError } from "serviceErrors";
import { withPageLoader } from './PageLoader';

const moduleName = 'attribute';
export const FETCH_REQUEST = `${appName}/${moduleName}/FETCH_REQUEST`;
export const FETCH_START = `${appName}/${moduleName}/FETCH_START`;
export const FETCH_SUCCESS = `${appName}/${moduleName}/FETCH_SUCCESS`;
export const FETCH_FAILED = `${appName}/${moduleName}/FETCH_FAILED`;
export const SAVE_REQUEST = `${appName}/${moduleName}/SAVE_REQUEST`;
export const SAVE_START = `${appName}/${moduleName}/SAVE_START`;
export const SAVE_SUCCESS = `${appName}/${moduleName}/SAVE_SUCCESS`;
export const SAVE_FAILED = `${appName}/${moduleName}/SAVE_FAILED`;
const CANCEL = `${appName}/${moduleName}/CANCEL`;

const initialState = {
    loading: false,
    loadComplete: false,
    loadTime: new Date(0),
    data: [],
    id: null,
    error: '',
};

export default function reducer(state = initialState, action) {
    const { type, payload, error } = action;
    switch (type) {
        case FETCH_START:
            return {
                ...initialState,
                loading: true,
                id: payload.id,
            };
        case FETCH_FAILED:
            return {
                ...initialState,
                loadComplete: true,
                loading: false,
                loadTime: payload.loadTime,
                id: payload.id,
                error: error.message,
            };
        case SAVE_START:
            return {
                ...state,
                saving: true,
                id: payload.id,
            };
        case FETCH_SUCCESS:
        case SAVE_SUCCESS:
            return {
                ...initialState,
                loadComplete: true,
                loadTime: payload.loadTime,
                id: payload.data.id,
                data: payload.data,
            };
        case SAVE_FAILED:
            return {
                ...state,
                saving: false,
                loadTime: payload.loadTime,
                id: payload.id,
                error: error.message,
            };
        default:
            return state;
    }
}

export const fetchAttribute = id => {
    return {
        type: FETCH_REQUEST,
        payload: { id },
    }
};

export const fetchStart = (id = 0) => {
    return {
        type: FETCH_START,
        payload: { id },
    }
};

export const fetchSuccess = (data, loadTime) => {
    return {
        type: FETCH_SUCCESS,
        payload: { data, loadTime },
    }
};

export const fetchFailed = (id, error, loadTime) => {
    return {
        type: FETCH_FAILED,
        payload: { id, loadTime },
        error,
    }
};

export const saveStart = id => {
    return {
        type: SAVE_START,
        payload: { id },
    }
};

export const saveSuccess = (data, loadTime) => {
    return {
        type: SAVE_SUCCESS,
        payload: { data, loadTime },
    }
};

export const saveFailed = (id, error, loadTime) => {
    return {
        type: SAVE_FAILED,
        payload: { id, loadTime },
        error,
    }
};

export const saveAttribute = attribute => {
    return {
        type: SAVE_REQUEST,
        payload: { attribute },
    }
};

export function cancel() {
    return {
        type: CANCEL,
    };
}

export const fetchAttributeSaga = function* (action) {
    const { id } = action.payload;
    yield put(fetchStart(id));
    try {
        const response = yield call(withPageLoader, () => getCustomAttribute(id));
        const attributeData = response.data;
        yield put(fetchSuccess(attributeData, new Date()));
    } catch (error) {
        const reqError = new RequestError(error, 'При загрузке дополнительного поля произошла ошибка');
        yield all([
            put(fetchFailed(reqError, new Date())),
            put(showErrorAlert(reqError.message))
        ]);
    }
};

export const saveAttributeSaga = function* (action) {
    const { attribute } = action.payload;
    const id = attribute.id;
    const state = yield select();
    const currentLocation = state.router.location;

    yield put(saveStart(id));

    const isLocationChanged = state => currentLocation !== state.router.location;
    const isAttributeChanged = state => state.attribute.id !== id;
    try {
        const response = yield call(withPageLoader,
            () => !id ? addCustomAttribute(attribute) : editCustomAttribute(attribute), true);
        const savedAttribute = response.data;
        const state = yield select();

        if (!isAttributeChanged(state)) {
            yield put(saveSuccess(savedAttribute, new Date()));
        }

        if (!isLocationChanged(state)) {
            yield put(push(attributesRoute.buildUrl()));
        }

        yield put(needUpdateAttributes());
    } catch (error) {
        const reqError = getError(error, getAttributeError(attribute));

        if (!isAttributeChanged(yield select())) {
            yield put(saveFailed(id, reqError, new Date()));
        }
        yield put(showErrorAlert(reqError.message));
    }
};

function* cancelSaga() {
    yield put(push(attributesRoute.buildUrl()));
}

const getAttributeError = (attribute) => (code) => {
    switch (code) {
        case serviceResultCode.NotFound:
            return `Дополнительное поле с именем "${attribute.name}" не найдено`;
        case serviceResultCode.AttributeChangeType:
            return "Невозможно изменить тип существующего дополнительного поля";
        case serviceResultCode.AttributeInvalidNameLength:
            return `Имя дополнительного поля превышает максимально допустимое кол-во символов`
        default:
            return "Произошла непредвиденная ошибка"
    }
}

export const saga = function* () {
    yield all([
        takeLatest(FETCH_REQUEST, fetchAttributeSaga),
        takeEvery(SAVE_REQUEST, saveAttributeSaga),
        takeEvery(CANCEL, cancelSaga),
    ]);
};

const isAttributeActual = attribute => {
    return attribute.loadComplete && !attribute.error;
}

export const newAttributeSelector = () => {
    return {
        loadComplete: true,
        isActual: true,
        id: 0,
        type: 'string',
        typeView: 'Строка',
        name: '',
        canDelete: false,
        payload: [],
    }
};

export const attributeFullSelector = (state, id) => {
    let { attribute } = state;
    if (!attribute.loadComplete) {
        return { loadComplete: false };
    }

    const { data } = attribute;

    return {
        loadComplete: true,
        isActual: id === attribute.id && isAttributeActual(attribute),
        id: attribute.id,
        saveInProgress: data.saving,
        type: data.type,
        name: data.name,
        canDelete: data.canDelete,
        payload: data.payload && JSON.parse(data.payload),
    }
};