import React from 'react';
import classnames from 'classnames';
import Switcher from 'components/Lk/Uikit/Switcher';

const SearchGroupCondition = props => {
    const { onClick, checked = false, title='Условия группировки' } = props;
    return (
        <div className='LKSearchGroupCondition'>
            {title && <div className='LKSearchGroupCondition__Item'>{title}</div>}
            <div className={classnames('LKSearchGroupCondition__Item', {'LKSearchGroupCondition__Item--on': !checked})}>И</div>
            <div className='LKSearchGroupCondition__Item'><Switcher onClick={onClick} checked={checked} /></div>
            <div className={classnames('LKSearchGroupCondition__Item', {'LKSearchGroupCondition__Item--on': checked})}>ИЛИ</div>
        </div>
    );
};

export default SearchGroupCondition;