export const COMPETENCIES = 'current';
export const TITLE = 'target';
export const RESOURCES = 'tools';
export const ASSISTANT = 'person';
export const GROWTH = 'object';
export const DATE = 'date';

export const STEPS = [COMPETENCIES, TITLE, RESOURCES, ASSISTANT, GROWTH, DATE];

export const helpObjectsTypes = [
    {
        id: 1,
        name: 'Гемба',
        value: 'Gemba',
        tip:
            'Изучение ситуации или процесса непосредственно на месте, где реализуется продукция или предоставляется услуга.',
    },
    {
        id: 2,
        name: 'Стажировка',
        value: 'Internship',
        tip:
            'Приобретение новых профессиональных навыков и знакомство с обязанностями на рабочем месте.',
    },
    {
        id: 3,
        name: 'Проект',
        value: 'Project',
        tip:
            'Последовательность взаимосвязанных действий, которые направлены на решение конкретной ситуации и ограничены по времени. Реализация проекта позволяет приобрести новый профессиональный опыт.',
    },
    {
        id: 4,
        name: 'Задача',
        value: 'Task',
        tip:
            'Кратковременное действие в рамках проекта или операционной деятельности. Решение задачи позволяет приобрести новый профессиональный опыт.',
    },
    {
        id: 5,
        name: 'Shadowing',
        value: 'Shadowing',
        tip:
            'Обучение на рабочем месте на опыте руководителя или более опытного сотрудника. Во время обучения резервист наблюдает за процессом работы руководителя, выезжает вместе с ним на переговоры, самостоятельно выполняет задания и задает вопросы.',
    },
];

export const RESOURCE_RECOMEND = 'recomend';
export const RESOURCE_ALL = 'all';
export const RESOURCE_SELECTED = 'selected';

export const RESOURCE_TYPES = [
    { key: 0, title: 'Рекомендованные', type: RESOURCE_RECOMEND },
    { key: 1, title: 'Все', type: RESOURCE_ALL },
    { key: 2, title: 'Выбранные', type: RESOURCE_SELECTED },
];

export const RESOURCE_FILTER_TYPES = [
    { id: 1, type: 'Video', title: 'Видео' },
    { id: 2, type: 'Book', title: 'Книги' },
    { id: 3, type: 'File', title: 'Статьи' },
    { id: 4, type: 'Microcourse', title: 'Курсы' },
    { id: 5, type: 'Link', title: 'Ссылки' },
    { id: 6, type: 'AudioBook', title: 'Аудиокниги' },
];