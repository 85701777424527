import React from 'react';
import PropTypes from 'prop-types';

const InputNumber = props => {
    const { value, onChange, double = false, dataTest, ...tail } = props;
    const onChangeValue = e => {
        const regexp = double ? /^([0-9]+(\.|,)?([0-9]+)?)?$/g : /^([0-9]+)?$/g;
        const val = e.target.value;
        if (regexp.test(val) === false) {
            e.preventDefault();
            return;
        }
        onChange(val);
    };

    return <input {...tail} onChange={onChangeValue} value={value} type="text" data-test={dataTest} />;
};

InputNumber.propTypes = {
    double: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
};

export default InputNumber;
