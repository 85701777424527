import React, { useState, useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import classnames from 'classnames';
import './MenuFilter.scss';

const MenuFilter = props => {

    const { options, value, onChange, size } = props;
    
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const menuRef = useRef({});
    
    const handleClickOutside = e => {
        if (menuRef.current.contains(e.target)) {
            return;
        }
        setIsMenuOpen(false);
    };

    useEffect(() => {
        if (isMenuOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isMenuOpen]);

    const handleMenuClick = (menuId) => e => {
        e.preventDefault();
        onChange(menuId);
        toggleMenu(!isMenuOpen);
    };

    const selectedMenuItem = options.find(x => x.type === value);
    
    return (
        <div className={classnames(`LKMenuFilter`, {'LKMenuFilter--small': size === 'small'})} onClick={toggleMenu} >
            { selectedMenuItem && selectedMenuItem.name }
            <div className={`LKMenuFilter__Icon--${isMenuOpen ? 'up' : 'down'}`} />
            <div ref={menuRef} >
                { isMenuOpen &&
                <div className='LKMenuFilterMenu'>
                    {options.map(x => (
                        <div key={x.id} 
                            className={classnames('LKMenuFilterMenu__MenuItem', {'LKMenuFilterMenu__MenuItem--small': size === 'small'})} 
                            onClick={handleMenuClick(x.id)}
                        >
                            <div>
                                {x.name}
                            </div>
                        </div>
                    ))}
                </div>}
            </div>
        </div>
    );
};

MenuFilter.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    size: PropTypes.string,
};

export default MenuFilter;