import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import Input from 'components/uikit/Input';
import InputText from 'components/uikit/InputText';
import Button from 'components/uikit/Button';
import Chip from 'components/uikit/Chip';

import './CompetenceCard.scss';

class CompetenceCard extends Component {
    state = {
        checked: [],
        expanded: [],
        modalOpen: false,
        isProcessing: false,
    };

    closeModal = () => {
        this.setState({ modalOpen: false });
    };

    noIndicatorsBlock = (competenceId) => {
        return (
            this.props.isEditMode && (
                <div>
                    <div className="CompetenceCard__HelpText CompetenceCard__HelpText--invalid">
                        Нужно добавить минимум 1 индикатор
                    </div>
                    <div className="CompetenceCard__IndicatorsActions">
                        <Button onClick={() => this.props.handleIndicators(competenceId)} size="sm">
                            Добавить
                        </Button>
                    </div>
                </div>
            )
        );
    };

    indicatorsBlock = (competence) => {
        const { getIndicatorById, isEditMode, clearIndicators } = this.props;
        return (
            <div>
                <div className="CompetenceCard__Chips">
                    {competence?.indicatorIds?.map((x) => (
                        <Chip
                            text={getIndicatorById(x).label}
                            key={x}
                            canDelete={isEditMode}
                            onDelete={() => clearIndicators(competence.id, x)}
                        />
                    ))}
                </div>
                {isEditMode && (
                    <div className="CompetenceCard__IndicatorsActions">
                        <Button editLink onClick={() => this.props.handleIndicators(competence.id)}>
                            Редактировать
                        </Button>
                        <Button clearLink onClick={() => clearIndicators(competence.id)}>
                            Очистить все
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    render() {
        const {
            isEditMode,
            competence,
            handleCompetenceNameChange,
            handleCompetenceDescriptionChange,
            isInvalidCompetencyName,
        } = this.props;

        return (
            <div className="CompetenceCard">
                <div className="CompetenceCard__Line">
                    <Field
                        filled={competence?.name}
                        required={isEditMode}
                        invalid={isEditMode && isInvalidCompetencyName(competence.name)}
                    >
                        <Label>Название компетенции</Label>
                        {isEditMode ? (
                            <Input
                                value={competence.name || ''}
                                competenceid={competence.id}
                                onChange={handleCompetenceNameChange}
                                name={`competenceName_${competence.id}`}
                            />
                        ) : (
                            <p>{competence.name}</p>
                        )}
                    </Field>
                </div>
                <div className="CompetenceCard__Line">
                    <Field>
                        <Label>Поведенческие индикаторы </Label>
                        {competence.indicatorIds && competence.indicatorIds.length !== 0
                            ? this.indicatorsBlock(competence)
                            : this.noIndicatorsBlock(competence.id)}
                    </Field>
                </div>
                <div className="CompetenceCard__Line">
                    <Field required={isEditMode}>
                        <Label>Описание</Label>
                        {isEditMode ? (
                            <InputText
                                value={competence.description || ''}
                                competenceid={competence.id}
                                onChange={handleCompetenceDescriptionChange}
                                maxLength={4000}
                                name={`competenceDescription_${competence.id}`}
                            />
                        ) : (
                            <div className="CompetenceCard__HelpText">
                                {competence.description || 'Нет описания'}
                            </div>
                        )}
                    </Field>
                </div>
            </div>
        );
    }
}

CompetenceCard.defaultProps = {
    isEditMode: false,
};

CompetenceCard.propTypes = {
    competence: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        indicatorIds: PropTypes.array,
    }).isRequired,
    handleCompetenceNameChange: PropTypes.func.isRequired,
    isInvalidCompetencyName: PropTypes.func.isRequired,
    handleCompetenceDescriptionChange: PropTypes.func.isRequired,
    handleIndicators: PropTypes.func,
    isEditMode: PropTypes.bool,
    getIndicatorById: PropTypes.func,
    clearIndicators: PropTypes.func,
};

export default CompetenceCard;
