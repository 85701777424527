import React, { Component } from 'react';
import ToggleOpen from 'components/hoc/ToggleOpen';
import { trimStart } from 'utils';
import { DataCard } from "components/common/DataCard";
import EditAttributesBlock from './Edit/AttributesBlock';
import { getAttributeAttachmentDownloadLink } from 'api';
import { getFileSize, getFileExtension } from 'utils';

class AttributesBlock extends Component {
    formatReturnValue = (type, value) => {
        switch (type.toLowerCase()) {
            case 'date':
                return new Date(value).toLocaleDateString();
            case 'hyperlink':
                value = trimStart(value, 'http://');
                value = trimStart(value, 'https://');
                return <a href={`https://${value}`} target='__blank'>{value}</a>
            case "select":
                return value && value.name;
            case "multiselect":
                return value && value.map((x, index) => <div key={index}>{x.name}</div>);                
            default:
                return value;
        }
    }


    renderAttribute = (attributeInfo, index) => {
        const { attribute } = attributeInfo;
        let value = attributeInfo.attributeValue;

        if(attribute.type == null || value == null){return null}

        value = this.formatReturnValue(attribute.type?.toLowerCase(), value);
        return (
            <div className="kr_tr" key={index}>
                <div className="kr_td">{attribute.name}</div>
                <div className="kr_td">
                    { attribute.type !== 'file' ? value : this.getFileInfo(attributeInfo) }
                </div>
            </div>
        );
    }

    getFileInfo = (attributeInfo) => {
        const {attachmentId, fileInfo, previewFileId, id } = attributeInfo;
        const link = getAttributeAttachmentDownloadLink(this.props.personId, id, attachmentId);
        const imgPreviewLink = previewFileId ?  getAttributeAttachmentDownloadLink(this.props.personId, id, previewFileId) : link;
        if (fileInfo !== undefined) {
            return (
                <div className="kr_like-table kr_like-table_files">
                    <div className="kr_tr">
                        {!!~fileInfo.mimeType.indexOf("image") 
                        ?
                            <div className="kr_td clean">
                                <a href={link}><img src={imgPreviewLink} alt="" /></a>
                            </div>
                        :
                            <div className="kr_td">
                                <a href={link}><span className="kr_files_pdf">{fileInfo !== "undefined" ? getFileExtension(attributeInfo.fileInfo.name): ""}</span></a>
                            </div>
                        }       										
                        <div className="kr_td">
                            {fileInfo.name}
                            <div>{getFileSize(fileInfo.size)}</div>
                        </div>
                    </div>										
                </div>
            )
        } else {
            return (
                <div>
                <h2>Файл загружается...</h2>
            </div>
            )
        } 
    }

    renderView() {
        const { attributes } = this.props;
        
        return (
            <DataCard
                shadow
                title='Дополнительные поля'
                className="kr_profile-anketa-view  Person__AttributesBlock"
                onChange={this.props.canEditPerson ? () => this.props.setEditBlock('AttributesBlock') : null}
            >
                <div className="kr_like-table">
                    {attributes && attributes.map(this.renderAttribute)}
                    {(!attributes || !attributes.size) ? this.props.renderEmptyBlock() : null}
                </div>
            </DataCard>
        );
    }

    renderEdit() {
        return (
            <EditAttributesBlock {...this.props} setEditBlock={this.props.setEditBlock} />
        );
    }

    render() {
        return this.props.isEditBlock ? this.renderEdit() : this.renderView();
    }
}

export default (ToggleOpen(AttributesBlock));