import React from 'react';
import classnames from 'classnames';

const ResourceCard = props => {
    const { itemId, name, url,mobile, className, mainClass = 'LKProfileProgressResourceCard' } = props;
    return (
        <div className={classnames(mainClass, className,{ [`${mainClass}--mobile`]: mobile })}>
            <a href={url} target="_blank" rel="noopener noreferrer">
                <div className={`${mainClass}__ImageContainer`}>
                    <img src={`api/personGrowthResource/${itemId}/file/download`} alt="" />
                </div>
                <div className={classnames(`${mainClass}__Text`)}>{name}</div>
            </a>
        </div>
    );
};

export default ResourceCard;
