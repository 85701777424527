import React from 'react';
import classnames from 'classnames';
import './SelectMenu.scss';

const SelectMenu = props => {
    const { activeId, items, onChange, className = '' } = props;

    const onChoose = item => () => {
        onChange(item);
    };

    return (
        <ul className={classnames('SelectMenu', className)}>
            {items.map(item => (
                <li
                    key={item.id}
                    onClick={onChoose(item)}
                    className={classnames('SelectMenu__Item', {
                        'SelectMenu__Item--active': activeId === item.id,
                    })}
                >
                    {item.name}
                </li>
            ))}
        </ul>
    );
};

export default SelectMenu;
