import React from 'react';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import './ControlGroup.scss';

const ControlGroup = (props) => {
    const { children, buttons } = props;

    const calcWidth =
        buttons && Array.isArray(buttons) ? `calc(100% - ${buttons.length * 55}px)` : '100%';

    return (
        <div className="ControlGroup">
            <div className="ControlGroup__InputGroup" style={{ width: calcWidth }}>
                {children}
            </div>
            <div className="ControlGroup__Buttons">
                {buttons &&
                    buttons.map((x, index) => {
                        return (
                            <Field key={index}>
                                <Label>&nbsp;</Label>
                                {x}
                            </Field>
                        );
                    })}
            </div>
        </div>
    );
};

export default ControlGroup;
