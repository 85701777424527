import React from 'react';
import classnames from 'classnames';
import Toggle from "components/common/Toggle";

import { faLongArrowAltUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function DirectionButton(props) {
    const {
        currentDirection,
        direction,
        onClick
    } = props;

    const className = classnames("order-" + direction, {
        "active": currentDirection === direction,
    });

    return <span className={className} onClick={onClick}>
        <FontAwesomeIcon icon={faLongArrowAltUp} />
    </span>;
}

function getSortingFromKeys(sortingKeys, isAscending) {
    return sortingKeys.reduce((result, key) => {
        result[key] = isAscending ? "asc" : "desc";
        return result;
    }, {});
}

function ToggleTitle(props) {
    const { sorting, onSort, name, sortingKeys } = props;

    const sortingFirstColumnDirection = sorting[sortingKeys[0]];

    return (
        <>
            {name}
            <DirectionButton
                currentDirection={sortingFirstColumnDirection}
                direction="asc"
                onClick={(e) => {
                    e.preventDefault();
                    onSort(getSortingFromKeys(sortingKeys, true));
                }}
            />
            <DirectionButton
                currentDirection={sortingFirstColumnDirection}
                direction="desc"
                onClick={(e) => {
                    e.preventDefault();
                    onSort(getSortingFromKeys(sortingKeys, false));
                }}
            />
        </>
    );
}

function toggleValueCompare(actualValue, optionValue) {
    return Object.keys(actualValue).indexOf(optionValue) !== -1;
}

export default function Sorting(props) {
    const { sorting, onSort, options } = props;

    return (
        <div className="kr_filter_radio">
            <Toggle
                value={sorting}
                options={options.map((item) => {
                    return {
                        htmlId: item.htmlId,
                        title: <ToggleTitle
                            key={item.htmlId}
                            name={item.title}
                            sorting={sorting}
                            sortingKeys={item.keys}
                            onSort={onSort}
                        />,
                        value: item.value,
                        defaultDirection: item.defaultDirection
                    }
                })
                }
                onChange={(sortingKey, sortItem) => onSort({ [sortingKey]: sortItem.defaultDirection })}
                compare={toggleValueCompare}
                className="with-spans"
            />
        </div>
    );
}
