import "./InputGroup.scss";
import React from "react";

function InputGroup(props) {
    const { children } = props;

    return (
        <div className="InputGroup">
            {children}
        </div>
    );
}

export default InputGroup;
