import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CheckboxTree from 'react-checkbox-tree';
import '../../../../../node_modules/font-awesome/scss/font-awesome.scss';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import Checkbox from 'components/uikit/Checkbox';
import './IndicatorsTree.scss';

class IndicatorsTree extends Component {
    state = {
        checked: [],
        expanded: [],
    };

    componentDidMount() {
        const { checked, expanded } = this.props;
        this.setState({ checked, expanded });
    }

    componentDidUpdate(prevProps) {
        const { checked } = this.props;
        if (prevProps.checked !== checked) {
            this.setState({ checked });
        }
    }

    render() {
        const { indicatorsTree, setChecked } = this.props;

        return (
            <div className="IndicatorTree">
                <CheckboxTree
                    nodes={indicatorsTree.filter(x => x.children.length !== 0)}
                    checked={this.state.checked}
                    expanded={this.state.expanded}
                    onCheck={(checked) => setChecked(checked)}
                    onExpand={(expanded) => this.setState({ expanded })}
                    showNodeIcon={false}
                    icons={{
                        check: <Checkbox checked />,
                        halfCheck: <Checkbox halfChecked/>,
                        uncheck: <Checkbox />,
                        expandClose: <span className="nodeChevron nodeChevron--closed" />,
                        expandOpen: <span className="nodeChevron nodeChevron--open" />,
                    }}
                />
            </div>
        );
    }
}

IndicatorsTree.propTypes = {
    indicatorsTree: PropTypes.array,
    setChecked: PropTypes.func,
    checked: PropTypes.array,
};

export default IndicatorsTree;
