import React from 'react';
import Icons from 'components/Lk/Uikit/Icons';

const SearchField = props => {
    const { onChange, value } = props;
    return (
        <div className="LKProgressSearch">
            <Icons name="search" fill="#848488" />
            <input className="LKProgressSearch__Input" onChange={onChange} value={value} />
        </div>
    );
};

export default SearchField;
