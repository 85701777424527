import React from 'react';
import { connect } from 'react-redux';
import PersonCommentsComponent from 'components/Lk/Common/PersonComments';
import { fetchComments, removeComment, saveComments } from 'ducks/Person';

const ProfileComments = props => {
    const {
        comments,
        removeComment,
        fetchComments,
        pageSize = 10,
        hasMore,
        pageNum,
        saveComments,
        personId,
        loading,
        isLoaded,
        user,
    } = props;

    const onFetch = (personId, pageNum = 1, loadMore) =>
        fetchComments(personId, { pageNum, pageSize }, loadMore);

    return (
        <PersonCommentsComponent
            onFetch={onFetch}
            comments={comments}
            onDelete={removeComment}
            hasMore={hasMore}
            pageNum={pageNum}
            isLoaded={isLoaded}
            onSave={saveComments}
            loading={loading}
            personId={personId}
            user={user}
        />
    );
};

const mapStateToProps = ({ person: { comments }, auth }) => ({
    comments: comments.data,
    hasMore: comments.hasMore,
    pageNum: comments.criteria.pageNum,
    loading: comments.loading,
    isLoaded: comments.isLoaded,
    user: auth.user,
});

const actions = { fetchComments, saveComments, removeComment };

export default connect(
    mapStateToProps,
    actions,
)(ProfileComments);
