import React, { useState } from 'react';
import { connect } from 'react-redux';
import { signOut } from 'ducks/Auth';
import { canSelectInterface } from 'rightsController';
import './Header.scss';
import Dropdown from 'components/common/Dropdown/Dropdown';
import { interfaceSelectorRoute } from 'routes';
import { Link } from 'react-router-dom';

const Exit = (props) => {
    const { signOut, user, ...tail } = props;
    const [showMenu, setMenuState] = useState(false);

    const contextMenu = ({ onToggle, children }) => (
        <div className="LayoutHeaderMenuItem__Exit" onClick={onToggle} {...tail}>
            <div onClick={onToggle}>{children}</div>
        </div>
    );

    const onSignOut = () => {
        signOut();
        setMenuState(false);
    };

    const appVersion = JSON.parse(document.getElementById('root').dataset?.version || null);

    return (
        <Dropdown className="Menu" defaultOpen={showMenu} ToggleButtonComponent={contextMenu}>
            <div className="Menu__List">
                {canSelectInterface(user) && (
                    <Link
                        to={interfaceSelectorRoute.url}
                        data-test="UserMenu_SelectInterface--button"
                    >
                        Сменить интерфейс
                    </Link>
                )}
                {appVersion && (
                    <div className="Menu__Item">
                        {`Версия ${appVersion.Name || appVersion.Version}`}
                    </div>
                )}
                <div className="Menu__Item" onClick={onSignOut}>
                    Выход
                </div>
            </div>
        </Dropdown>
    );
};

const actions = { signOut };

export default connect(null, actions)(Exit);
