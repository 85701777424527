import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './EvaluationPersonPercent.scss';

const EvaluationPersonPercent = (props) => {
    const { value = undefined, name } = props;

    const blockClassName = classnames(
        "EvaluationPersonPercent"
        ,{ "EvaluationPersonPercent--low": (value*1 < 40) }
        ,{ "EvaluationPersonPercent--middle": (value*1 > 39 &&  value*1 < 70)}
    );
    return (
        <>
            <div className='EvaluationPersonPercent__Name' >
                {name}
            </div>
            {value === undefined && <div className='EvaluationPerson__Content-NoData' >
                Нет результатов оценки
            </div>}
            {value !== undefined && <div className={blockClassName} >
                {`${value}%`}
            </div>}
        </>
    );
};

EvaluationPersonPercent.propTypes = {
    value: PropTypes.any,
};

export default EvaluationPersonPercent;