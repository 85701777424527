import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchUser } from 'ducks/Auth';

const getAuth = (OriginalComponent) => class Auth extends Component {
    static propTypes = {
        auth: PropTypes.object.isRequired,
        fetchUser: PropTypes.func.isRequired,
    }

    componentDidMount() {
        this.ensureUserLoaded();
    }

    componentDidUpdate() { 
        this.ensureUserLoaded();
    }

    ensureUserLoaded = () => {
        if (!this.isUserLoaded()) {
            this.props.fetchUser();
        }
    }

    isUserLoaded = () => {
        const { auth } = this.props;
        return auth.user != null || +auth.loadTime !== +new Date(0);
    }

    render() {
        const { auth } = this.props;

        if (!this.isUserLoaded() && !auth.signInInProgress && !auth.twoFactorRequired) {
            // Возможно пользователь всё-таки авторизован, попробуем сначала проверить на сервере
            return null;
        }

        return <OriginalComponent {...this.props} />;
    }
}

const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = { fetchUser };

export default (OriginalComponent) => connect(mapStateToProps, mapDispatchToProps)(getAuth(OriginalComponent));