import React from 'react';
import { showWarningAlert } from 'ducks/Alert';
import { Prompt } from 'react-router';
import PropTypes from 'prop-types';

const LoadingUrlBlocker = props => {
    const { isLoading, message = 'Пожалуйста, подождите.', onLoad, except = [] } = props;

    const onLoadingFileWarning = uri => {
        if (except.indexOf(uri?.pathname) >= 0 || uri?.state?.ignorePrompt || uri.hash==='#fixed') {
            return true;
        }
        message && showWarningAlert(message);
        onLoad && onLoad(uri);
        return false;
    };

    return <Prompt when={isLoading} message={onLoadingFileWarning} />;
};

LoadingUrlBlocker.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default LoadingUrlBlocker;
