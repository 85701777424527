import React from 'react';
import RoundButton from 'components/uikit/Button/RoundButton';

const DataCardControls = (props) => {
    const { controls, onEdit } = props;

    return (
        <div className="DataCard__Controls">
            {onEdit && (
                <div className="DataCard__EditButton">
                    <RoundButton type="edit" position="absolute" onClick={onEdit} />
                </div>
            )}
            {controls}
        </div>
    );
};

export default DataCardControls;
