import React, { useEffect, useRef } from 'react';
import EvaluationType from './EvaluationType/EvaluationType';
import EvaluationCardItemBlock from './EvaluationCardItemBlock';

export const acceptChoice = (onChange, basket) => {
    onChange("evaluate", basket);
};

export const renderEvaluationType = (obj, filter) => {
  const result = obj.filter(item =>  filter ? item.forEvaluation : true );
  return result.map((item, key) => (
    <EvaluationCardItemBlock grey key={key}>
        <EvaluationType {...item} icon="evaluateType" tooltip/>
    </EvaluationCardItemBlock>
    )
  )
}

export function usePrevious(value) {
  const ref = useRef([])
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
