import React, { Component } from 'react';
import { getCatalog } from 'api';
import { yearMask, federalCities } from 'constants.js';
import Field from 'components/uikit/Field';
import InputMask from 'components/uikit/InputMask';
import Label from 'components/uikit/Label';
import Select from 'components/uikit/Select';
import { REGION_LOCALITIES, LOCALITIES } from 'ducks/Catalog';
import { requireValidator, yearRangeValidator } from 'libs/validators';
import { setItem } from 'utils.js';
import DataList from '../DataList';
import ItemButton from '../ItemButton';
import { debounce } from 'lodash';
import './PastResidencesTable.scss';

const keySelector = (entry) => entry.indexAsKey;

const compareEntries = (_, currentEntry, index) => index === keySelector(currentEntry);

const setEntry = (setter, currentEntry, entries) =>
    setItem(setter, compareEntries, currentEntry, entries);

export default class PastResidencesTable extends Component {
    handleAdd = () => {
        const { newEntry, entries, onChangeEntries } = this.props;
        onChangeEntries([...entries, newEntry]);
    };

    handleRemove = (currentEntry) => {
        const { entries, onChangeEntries } = this.props;
        onChangeEntries(entries.filter((_, index) => index !== keySelector(currentEntry)));
    };

    handleUpdate = (currentEntry, setter) => {
        const { entries, onChangeEntries } = this.props;
        const newEntries = setEntry(setter, currentEntry, entries);
        onChangeEntries(newEntries);
    };

    handleRegionChange = (currentEntry) => (selectedRegion) => {
        const selectedDistrict = selectedRegion
            ? this.props.catalogs.federalDistricts.find(
                  (x) => x.id === selectedRegion.federalDistrictId,
              )
            : null;
        this.handleUpdate(currentEntry, (entry) => ({
            ...entry,
            selectedLocality: null,
            selectedDistrict,
            selectedRegion,
            ...this.props.catalogs,
        }));
    };

    handleLocalityChange = (currentEntry) => (selectedLocality) => {
        let selectedRegion = null,
            selectedDistrict = null;

        if (selectedLocality) {
            selectedRegion = this.props.catalogs.regions.find(
                (x) => x.code === selectedLocality.regionCode,
            );
            selectedDistrict = this.props.catalogs.federalDistricts.find(
                (x) => x.id === selectedRegion.federalDistrictId,
            );
        }
        
        this.handleUpdate(currentEntry, (entry) => ({
            ...entry,
            selectedDistrict,
            selectedRegion,
            selectedLocality,
        }));
    };

    handleYearFromChange = (currentEntry) => (e) => {
        this.handleUpdate(currentEntry, (entry) => ({ ...entry, yearFrom: e.target.value }));
    };

    handleYearToChange = (currentEntry) => (e) => {
        this.handleUpdate(currentEntry, (entry) => ({ ...entry, yearTo: e.target.value }));
    };

    isRegionFederal = (region) => {
        if (region) return federalCities.includes(region.id.toUpperCase());
        return false;
    };

    setRegionalLocalities = (currentEntry) => {
        const { selectedRegion } = currentEntry;
        if (selectedRegion) {
            const params = [selectedRegion.id];
            getCatalog(REGION_LOCALITIES, params).then((res) => {
                const selectedLocality = res.data.find((x) => x.id === currentEntry.localityId);
                this.handleUpdate(currentEntry, (entry) => ({
                    ...entry,
                    selectedLocality,
                    regionLocalities: res.data,
                }));
            });
        }
    };

    getLocality = (currentEntry) => (searchTerm) => {
        if (!searchTerm) {
            this.handleUpdate(currentEntry, (entry) => ({
                ...entry,
                selectedLocality: entry.selectedLocality,
                regionLocalities: [],
            }));
            return;
        }
        try {
            getCatalog(
                LOCALITIES,
                { searchTerm, Region: currentEntry.selectedRegion?.id },
                true,
            ).then((res) => {
                const selectedLocality = res.data.find((x) => x.id === currentEntry.localityId);
                this.handleUpdate(currentEntry, (entry) => ({
                    ...entry,
                    selectedLocality,
                    regionLocalities: res.data,
                }));
            });
        } catch (e) {
            this.props.showErrorAlert('Ошибка загрузки населенных пунктов');
        }
    };

    renderPastResidence = (entry, canRemove, handleRemove) => {
        const {
            catalogs: { federalDistricts, regions },
            isInputFilled,
            isInputInvalid,
            isDisabled = false,
        } = this.props;
        const { selectedDistrict, selectedRegion, selectedLocality, yearFrom, yearTo } = entry;

        const key = keySelector(entry);
        const getId = (name) => `${name}_${key}`;
        return (
            <div className="past-residences-wrapper">
                <div className="past-residences-wrapper__block">
                    <Field required filled={isInputFilled(selectedDistrict, true)} size="33">
                        <Label htmlFor={getId('federalDistrict')}>Федеральный округ</Label>
                        <Select
                            key={getId('federalDistrict')}
                            inputId={getId('federalDistrict')}
                            value={selectedDistrict}
                            options={federalDistricts}
                            isDisabled
                            catalog
                        />
                    </Field>
                    <Field
                        required
                        filled={isInputFilled(selectedRegion, true)}
                        invalid={isInputInvalid(
                            'selectedRegion',
                            [requireValidator('Выберите регион')],
                            key,
                            entry,
                            getId('selectedRegion'),
                        )}
                        size="33"
                    >
                        <Label htmlFor={getId('region')}>Регион</Label>
                        <Select
                            inputId={getId('region')}
                            value={selectedRegion}
                            onChange={this.handleRegionChange(entry)}
                            options={regions}
                            catalog
                            isDisabled={isDisabled}
                        />
                    </Field>
                    <Field filled={isInputFilled(selectedLocality)} size="33">
                        <Label htmlFor={getId('locality')}>Населённый пункт</Label>
                        <Select
                            inputId="locality"
                            value={selectedLocality}
                            onChange={this.handleLocalityChange(entry)}
                            onInputChange={debounce(this.getLocality(entry), 500)}
                            options={entry.regionLocalities}
                            isClearable
                            catalog
                            isDisabled={isDisabled}
                        />
                    </Field>
                    <Field
                        required
                        filled={isInputFilled(yearFrom && yearTo, true)}
                        invalid={isInputInvalid(
                            'yearFrom',
                            [
                                yearRangeValidator(
                                    `Год начала проживания должен быть раньше года окончания проживания`,
                                    yearFrom,
                                    yearTo,
                                ),
                            ],
                            key,
                            entry,
                            getId('yearFrom'),
                        )}
                        className="kr_select_with_text"
                        size="33"
                        disabled={isDisabled}
                    >
                        <Label>Период проживания</Label>
                        <div className="PastResidencesTable__DateRange">
                            <InputMask
                                mask={yearMask}
                                maskChar={null}
                                id="yearFrom"
                                value={yearFrom || ''}
                                placeholder="с"
                                onChange={this.handleYearFromChange(entry)}
                            />
                            <InputMask
                                mask={yearMask}
                                maskChar={null}
                                id="yearTo"
                                value={yearTo || ''}
                                placeholder="по"
                                onChange={this.handleYearToChange(entry)}
                            />
                        </div>
                    </Field>
                </div>
                <div className="past-residences-wrapper__btn">
                    {canRemove && !isDisabled && <ItemButton type="remove" onClick={handleRemove} />}
                </div>
            </div>
        );
    };

    render = () => {
        const { entries } = this.props;
        return (
            <DataList
                customRemove={true}
                isCompact={true}
                onAdd={this.handleAdd}
                onRemove={this.handleRemove}
                canRemove={() => true}
                entries={entries.map((entry, index) => ({ ...entry, indexAsKey: index }))}
                keySelector={keySelector}
                renderEntry={this.renderPastResidence}
                isDisabled={this.props.isDisabled}
            />
        );
    };
}
