import React from 'react';
import './OptionButton.scss';

export const OptionButton = ({ label, value, onClick, selected = false }) => {
    return (
        <div className="OptionButton__Container">
            <label className="OptionButton__Label">{label}</label>
            <div
                className={
                    selected ? 'OptionButton__Box OptionButton__Selected' : 'OptionButton__Box'
                }
                onClick={onClick}
            >
                <div className="OptionButton__Dot">
                    {selected && (
                        <div className="DotSelected">
                            <div className="InnerDot"></div>
                        </div>
                    )}
                </div>
                <button className="OptionButton__Button">{value}</button>
            </div>
        </div>
    );
};
