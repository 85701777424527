import React from 'react';
import './BlockHeader.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import './Sticky.scss';

const BlockHeader = props => {
    const { header, isWrap, setVisibleWrapSection, setWrap, setScrollTo, scrollElem, isEdit } = props;

    const wrap = () => {
        if(isWrap) {
            setWrap(false);
            setVisibleWrapSection(null);
            setScrollTo(scrollElem);
        }
    };

    return (!isEdit &&
        <div className={classnames('LKProfileBlockHeader', {'LKProfileBlockHeader--clickable': isWrap})} onClick={wrap}>
            {isWrap && 
                <div className='LKProfileBlockHeader__Back'>
                    <FontAwesomeIcon icon='chevron-left' />
                </div>
            }
            <div className='LKProfileBlockHeader__Text'>
                {header}
            </div>
        </div>            
    );
};

export default BlockHeader;