import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import { getError } from 'serviceErrors';
import { issuesRoute, issuesEditRoute } from 'routes';
import { push as pushLocation } from 'connected-react-router';
import { parse as queryStringParse, stringify } from 'query-string';
import { Link } from 'react-router-dom';
import DataGrid from 'components/common/DataGrid';
import ListTemplate from 'components/uikit/ListTemplate/ListTemplate';
import Page from 'components/common/Page';
import { formatDate, startOf, endOf } from 'utils';
import { searchIssues } from 'api';
import { ISSUE_STATUS, ISSUE_TYPE, ISSUE_TYPE_ENUM, ISSUE_STATUS_ENUM } from 'constants.js';
import { isModerator, isLeader } from 'rightsController';
import classnames from 'classnames';
import './Issues.scss';

const initalState = {
    criteria: {
        filter: {
            type: null,
            status: null,
            to: null,
            from: null,
        },
        sorting: { isViewed: 'Asc', dueDate: 'Desc' },
        paging: {
            pageNum: 1,
            pageSize: 10,
        },
    },
    payload: {
        data: [],
        meta: {
            foundCount: 0,
            pageCount: 0,
        },
    },
    sorted: [
        { id: 'isViewed', desc: true },
        { id: 'dueDate', desc: true },
    ],
};

class Issues extends Component {
    _mounted = true;

    state = {
        ...initalState,
    };

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            JSON.stringify(prevProps.queryParams) !== JSON.stringify(this.props.queryParams) ||
            prevState.criteria.paging.pageSize !== this.state.criteria.paging.pageSize
        ) {
            this.fetchData();
        }
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    getAsArray = (items) => {
        return items && !Array.isArray(items) ? [items] : items;
    };

    fetchData = () => {
        this.callEffect(async () => {
            const criteria = {
                filter: {
                    onlyActive: true,
                    type: this.getAsArray(this.props.queryParams.type),
                    status: this.getAsArray(this.props.queryParams.status),
                    dateTo:
                        this.props.queryParams.to &&
                        endOf(this.props.queryParams.to, 'day').format(),
                    dateFrom:
                        this.props.queryParams.from &&
                        startOf(this.props.queryParams.from, 'day').format(),
                },
                sorting: this.state.criteria.sorting,
                paging: {
                    ...this.state.criteria.paging,
                    pageNum: +this.props.queryParams.page || 1,
                },
            };

            const result = await searchIssues(criteria, true);

            this._mounted &&
                this.setState((state) => ({
                    criteria: {
                        ...state.criteria,
                        filter: {
                            onlyActive: true,
                            type: this.props.queryParams.type || null,
                            status: this.props.queryParams.status || null,
                            to:
                                (this.props.queryParams.to &&
                                    new Date(this.props.queryParams.to)) ||
                                null,
                            from:
                                (this.props.queryParams.from &&
                                    new Date(this.props.queryParams.from)) ||
                                null,
                        },
                        sorting: this.state.sorting,
                        paging: {
                            ...state.criteria.paging,
                            pageNum: +this.props.queryParams.page || 1,
                        },
                    },
                    payload: {
                        data: [...result.data.payload],
                        meta: { ...result.data.meta },
                    },
                }));
        });
    };

    callEffect = async (callback) => {
        this.props.showPageLoader();
        try {
            return await callback();
        } catch (error) {
            const reqError = getError(error, this.getDropSessionError);
            this.props.showErrorAlert(reqError.message);
        } finally {
            this.props.hidePageLoader();
        }
    };

    onPageChange = (pageIndex) => {
        const urlParams = {
            ...this.props.queryParams,
            page: pageIndex + 1,
        };

        this.props.pushLocation({ pathname: issuesRoute.url, search: stringify(urlParams) });
    };

    onFilterSubmit = () => {
        const { criteria } = this.state;

        const urlParams = {
            page: 1,
            type: criteria.filter.type,
            status: criteria.filter.status,
            from: criteria.filter.from && startOf(criteria.filter.from, 'day').format(),
            to: criteria.filter.to && endOf(criteria.filter.to, 'day').format(),
        };

        this.props.pushLocation({ pathname: issuesRoute.url, search: stringify(urlParams) });
    };

    onSortedChange = (sorted) => {
        this.setState(
            {
                sorted,
                criteria: {
                    ...this.state.criteria,
                    sorting: { [sorted[0].id]: sorted[0].desc ? 'desc' : 'asc' },
                },
            },
            () => this.fetchData(),
        );
    };

    onClearFilters = () => {
        this.setState((state) => {
            return {
                ...initalState,
                payload: {
                    data: [...state.payload.data],
                    meta: { ...state.payload.meta },
                },
            };
        });
        this.props.pushLocation({ pathname: issuesRoute.url, search: stringify({ page: 1 }) });
    };

    editIssue = (id) => {
        this.props.pushLocation(issuesEditRoute.buildUrl({ id }));
    };

    newIssue = () => {
        this.props.pushLocation({
            pathname: issuesEditRoute.buildUrl({ id: 'new' }),
            state: {
                status: this.props.queryParams.status,
                page: this.props.queryParams.page,
                from: this.props.queryParams.from,
                to: this.props.queryParams.to,
            },
        });
    };

    onFilterChange = (value) => {
        this.setState((state) => ({
            criteria: {
                ...state.criteria,
                filter: {
                    ...state.criteria.filter,
                    ...value,
                },
            },
        }));
    };

    changePageAmount = (pageSize) => {
        const urlParams = {
            ...this.props.queryParams,
            page: 1,
        };

        this.setState((state) => ({
            criteria: {
                ...state.criteria,
                paging: {
                    ...state.criteria.paging,
                    pageSize,
                },
            },
        }));

        this.props.pushLocation({ pathname: issuesRoute.url, search: stringify(urlParams) });
    };

    onSelectChange = (name, values) => {
        this.setState((state) => ({
            criteria: {
                ...state.criteria,
                filter: {
                    ...state.criteria.filter,
                    [name]: values && values.map((x) => x.value),
                },
            },
        }));
    };

    render() {
        const buildColumns = [
            {
                Header: 'Заголовок',
                resizable: false,
                accessor: 'title',
                sortable: true,
                Cell: ({ original }) => (
                    <Link
                        className={classnames({
                            'Issues__DataGridCol--bold': !original.isViewed,
                        })}
                        to={{
                            pathname: issuesEditRoute.buildUrl({
                                id: original.id,
                                mode: 'view',
                            }),
                            state: {
                                status: this.props.queryParams.status,
                                page: this.props.queryParams.page,
                                from: this.props.queryParams.from,
                                to: this.props.queryParams.to,
                            },
                        }}
                    >
                        {original?.title || 'Черновик'}
                    </Link>
                ),
            },
            {
                Header: 'Статус',
                maxWidth: 190,
                resizable: false,
                accessor: 'status',
                sortable: true,
                Cell: ({ original }) =>
                    original.status
                        ? ISSUE_STATUS.find((x) => x.value === original.status)?.label
                        : null,
            },
            {
                Header: 'Выполнить до',
                maxWidth: 190,
                resizable: false,
                accessor: 'dueDate',
                sortable: true,
                Cell: ({ original }) =>
                    original.dueDate ? (
                        <div
                            className={classnames('Issues', {
                                'Issues__DataGridCol--bold': !original.isViewed,
                            })}
                        >
                            {formatDate(original.dueDate)}
                        </div>
                    ) : null,
            },
        ];

        const customSelectStyles = {
            control: (base) => ({
                ...base,
                backgroundColor: '#f9f9ff',
                borderColor: '#e6dfff',
            }),
            input: (base) => ({
                ...base,
                padding: 0,
                margin: 0,
            }),
            menu: (base) => ({
                ...base,
                color: '#767268',
            }),
            singleValue: (base) => ({
                ...base,
                color: '#767268',
            }),
            valueContainer: (base) => ({
                ...base,
            }),
        };

        const { filter, paging } = this.state.criteria;
        const { queryParams, currentUser } = this.props;

        const selectedTypes =
            filter && filter.type && ISSUE_TYPE.filter((x) => filter.type.indexOf(x.value) >= 0);

        const selectedStatuses =
            filter &&
            filter.status &&
            ISSUE_STATUS.filter((x) => filter.status.indexOf(x.value) >= 0);

        const getTypeOptions = (currentUser) => {
            if (isLeader(currentUser)) {
                return ISSUE_TYPE;
            } else if (isModerator(currentUser)) {
                return ISSUE_TYPE.filter(
                    (x) => x.value !== ISSUE_TYPE_ENUM.ApprovePersonGrowthPlan,
                );
            }

            return ISSUE_TYPE.filter((x) => x.value === ISSUE_TYPE_ENUM.Default);
        };

        const filterForm = {
            title: 'Фильтры',
            line: [
                {
                    type: 'select',
                    label: 'Тип',
                    value: selectedTypes,
                    inputId: 'selectedType',
                    placeholder: 'Выберите тип',
                    options: getTypeOptions(currentUser),
                    onChange: (values) => this.onSelectChange('type', values),
                    isMulti: true,
                    styles: customSelectStyles,
                },
                {
                    type: 'select',
                    label: 'Статус',
                    value: selectedStatuses,
                    inputId: 'selectedTag',
                    placeholder: 'Выберите статус',
                    options: ISSUE_STATUS.filter((x) => x.value !== ISSUE_STATUS_ENUM.Deleted),
                    onChange: (values) => this.onSelectChange('status', values),
                    isMulti: true,
                    styles: customSelectStyles,
                },
                {
                    type: 'DatePicker',
                    label: 'Дата с',
                    id: 'dateFrom',
                    name: 'dateFrom',
                    selected: filter.from,
                    onChange: (value) => this.onFilterChange({ from: value }),
                },
                {
                    type: 'DatePicker',
                    label: 'Дата по',
                    id: 'dateTo',
                    name: 'dateTo',
                    selected: filter.to,
                    onChange: (value) => this.onFilterChange({ to: value }),
                },
            ],
            btnOnClick: this.onFilterSubmit,
            btnClearOnClick: this.onClearFilters,
        };

        const headBtn = {
            onClick: this.newIssue,
            value: 'Добавить',
            addLink: true,
        };

        const { payload } = this.state;

        return (
            <Page>
                <ListTemplate title={'Список задач'} headBtn={headBtn} form={filterForm}>
                    <DataGrid
                        data={payload.data}
                        foundCount={payload.meta.foundCount}
                        columns={buildColumns}
                        showPagination={true}
                        showPageSizeOptions={false}
                        sorted={this.state.sorted}
                        onSortedChange={this.onSortedChange}
                        pages={payload.meta.pageCount}
                        page={+queryParams.page || 1}
                        onPageChange={this.onPageChange}
                        manual
                        pageSize={paging.pageSize}
                        changePageAmount={this.changePageAmount}
                    />
                </ListTemplate>
            </Page>
        );
    }
}

const props = (state) => {
    return {
        queryParams: queryStringParse(state.router.location.search),
        currentUser: state.auth.user,
    };
};

const actions = {
    showPageLoader,
    hidePageLoader,
    showErrorAlert,
    pushLocation,
};

export default connect(props, actions)(Issues);
