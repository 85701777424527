import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

function ItemButton(props) {
    const { 
        type, 
        onClick,
        className,
    } = props;

    const buttonClass = classNames("kr_btn_remove", className);

    return (
        type === "remove"
            ? <div className={buttonClass} onClick={onClick} />
            : null
    );
}

ItemButton.propTypes = {
    type: PropTypes.oneOf(["remove"]).isRequired,
    onClick: PropTypes.func.isRequired,
    withoutTitle: PropTypes.bool,
};

export default ItemButton;
