import { faGripVertical, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Grid, Row, Col } from 'react-flexbox-grid';

const renderRow = (children, isDraggable, dragHandleProps) => {
    return (
        <Row>
            <Col xs={isDraggable ? 11 : 12}>
                <div className='Card__Content'>
                    {children}
                </div>
            </Col>
            {isDraggable &&
                <Col xs={1} className='DNDBlock' {...dragHandleProps}>
                    <div>
                        <FontAwesomeIcon icon={faGripVertical} />
                    </div>
                </Col>
            }
        </Row>
    );
};

const Card = (props) => {
    const {
        className,
        children,
        isDraggable,
        isClosable,
        onClose = () => {},
        fade,
        dragHandleProps = []
    } = props;

    const containerClassName = classnames('Card', {'Card--fade': fade}, className);

    const containerWorkaround = isDraggable ? { marginRight: '8px' } : {};
    const gridWorkaround = isDraggable ? { paddingRight: 0 } : {};
    const closeButtonWorkaround = isDraggable ? { right: '-8px' } : {};

    return (
        <div className={containerClassName} style={containerWorkaround}>
            <Grid fluid style={gridWorkaround}>
                {renderRow(children, isDraggable, dragHandleProps)}
            </Grid>
            {isClosable &&
                <div className='Card__CloseButton'>
                    <label className='CardCloseButton' style={closeButtonWorkaround}>
                        <input
                            type='button'
                            className='CardCloseButton__Input'
                            onClick={onClose}
                        />
                        <div className='CardCloseButton__Icon'>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </label>
                </div>
            }
        </div>
    );
};

Card.propTypes = {
    children: PropTypes.node,
    isDraggable: PropTypes.bool,
    isClosable: PropTypes.bool,
    onClose: PropTypes.func,
    fade: PropTypes.bool,
    dragHandleProps: PropTypes.object
};

export default Card;