import uuid from "uuid/v4";

const getNewCriteria = () => {
    return {
        evaluationTestId: "",
        gradesInAscendingOrder: "",
        id: uuid(),
        personGrowthTagId: "",
        position: 1,
        scaleLowerBound: 0,
        scaleStep: 1,
        scaleUpperBound: 10,
        tags: [],
        title: "",
        valueKind: "Scale",
        description: "",
        extraDescription: "",
    };
};

const BlankTest = {
    competencyModelId: "",
    createdOn: new Date(),
    criteria: [getNewCriteria()],
    criteriaOrder: [],
    description: "",
    externalTestId: "",
    id: "",
    isUsed: null,
    kind: "",
    testProviderId: "",
    status: "",
    timeLimit: "",
    title: "",
    unknownKindTitle: null,
    unknownProviderTitle: null,
    visualizationKind: "",
    validForYears: 1,
};

export default BlankTest;