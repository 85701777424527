import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { templatesRoute, templatesEditRoute, templatesCreateRoute } from 'routes';
import Templates from 'pages/manager/moderation/templates';
import EditTemplate from 'pages/manager/moderation/templates/EditTemplate';
import NotFound from 'components/NotFound';
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import { ALLOWED_MODERATE_TEMPLATES } from 'rightsController';

class TemplatesRoutes extends Component {
    renderEditTemplates = ({ match, location }) => {
        const { params } = match;
        return <EditTemplate id={params.id} location={location} />;
    };

    renderCreateTemplate = ({ match, location }) => {
        const { params } = match;
        return <EditTemplate id={params.id} location={location} isNewTemplate={true} />;
    };

    renderNotFound = () => {
        return <NotFound />;
    };

    render() {
        return (
            <Switch>
                <ProtectedRoute
                    exact
                    path={templatesRoute.url}
                    component={Templates}
                    allowedRoles={ALLOWED_MODERATE_TEMPLATES}
                />
                <ProtectedRoute
                    exact
                    path={templatesEditRoute.url}
                    component={this.renderEditTemplates}
                    allowedRoles={ALLOWED_MODERATE_TEMPLATES}
                />
                <ProtectedRoute
                    exact
                    path={templatesCreateRoute.url}
                    component={this.renderCreateTemplate}
                    allowedRoles={ALLOWED_MODERATE_TEMPLATES}
                />
                <Route component={this.renderNotFound} />
            </Switch>
        );
    }
}

export default TemplatesRoutes;
