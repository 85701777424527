import React, { useEffect, useState } from 'react';
import Avatar from 'components/Lk/Common/Avatar';
import { Link } from 'react-router-dom';
import RoundedButton from 'components/Lk/Uikit/Buttons/RoundedButton';
import CustomButtons from './CustomButtons';
import { getPersonAlreadyHavePersonGrowthPlan } from 'api';

const ModalSummaryCard = props => {
    const {
        person,
        buttons,
        profileRoute,
        profileUrl,
        linkParams,
        onAcceptSend,
        onSetIssue,
        onEvaluatePerson,
        onClose,
        isUser,
    } = props;

    const [personAlreadyHavePersonGrowthPlan, setPersonAlreadyHavePersonGrowthPlan] = useState(true);
    
    const isPersonSendInValid = () => {
        if (Array.isArray(person.percent)) {
            return false;
        }
        return person.percent || person.progressPercent;
    };

    useEffect(() => {
        let mounted = true;

        const loadPlan = async () => {
            const personAlreadyHavePersonGrowthPlan = await getPersonAlreadyHavePersonGrowthPlan(person.id);
            mounted && setPersonAlreadyHavePersonGrowthPlan(personAlreadyHavePersonGrowthPlan.data);
        };

        loadPlan();
        return () => {
            mounted = false;
        };
    }, [person.id]);
    

    const renderButtons = () => {
        return (
            <>
                {person.userId && (
                    <div>
                        <RoundedButton
                            modifier="withoutPaddingBottom"
                            inline="true"
                            onClick={onSetIssue}
                        >
                            Поставить задачу
                        </RoundedButton>
                    </div>
                )}

                <div>
                    <RoundedButton
                        modifier="withoutPaddingBottom"
                        disabled={isPersonSendInValid() || personAlreadyHavePersonGrowthPlan}
                        inline="true"
                        onClick={onAcceptSend}
                    >
                        Направить на создание ИПР
                    </RoundedButton>
                </div>
                <div>
                    <RoundedButton
                        modifier="withoutPaddingBottom"
                        inline="true"
                        onClick={onEvaluatePerson}
                    >
                        Направить на оценку
                    </RoundedButton>
                </div>
                <div className="LKModalSummaryCard__Buttons--link">
                    <Link
                        to={{
                            pathname: profileRoute
                                ? profileRoute.buildUrl({ id: person.id })
                                : profileUrl || '/',
                            state: linkParams,
                        }}
                        onClick={onClose}
                    >
                        Посмотреть анкету
                    </Link>
                </div>
                <div className="LKModalSummaryCard__Buttons--link">
                    <a href={`mailto: ${person.email}`}>Отправить письмо</a>
                </div>
            </>
        );
    };

    const position = person.currentPosition || person.currentJobTitle;

    return (
        <div className="LKModalSummaryCard">
            <div className="LKModalSummaryCard__Photo">
                <Avatar person={person} isUser={isUser} />
            </div>
            <div className="LKModalSummaryCard__Name">
                <div>{person.lastName}</div>
                <div>{person.firstName}</div>
                <div>{person.middleName}</div>
            </div>
            {position && <div className="LKModalSummaryCard__Position">{position}</div>}
            <div className="LKModalSummaryCard__Buttons">
                {!buttons ? renderButtons() : <CustomButtons buttons={buttons} />}
            </div>
        </div>
    );
};

export default ModalSummaryCard;
