import memoize from 'fast-memoize';
import React from 'react';
import { connect } from "react-redux";
import ToggleOpen from 'components/hoc/ToggleOpen';
import ValidBlock from 'components/hoc/ValidBlock';
import { DATA_ORIGINS, EXPERIENCE_LEVELS } from 'ducks/Catalog';
import { showErrorAlert, showWarningAlert } from 'ducks/Alert';
import { requireValidator } from 'libs/validators';
import Select from 'components/uikit/Select';
import Input from 'components/uikit/Input';
import InputText from "components/uikit/InputText";
import { DataCard, DataCardBlock } from "components/common/DataCard";
import Field from "components/uikit/Field";
import Label from "components/uikit/Label";
import Row from "components/uikit/Row";
import Form from "components/common/Form";
import DataList from "./DataList";
import ItemButton from "./ItemButton";
import { setItem } from "utils.js";
import ReactTooltip from 'react-tooltip'
import './PersonnelInfoBlock.scss';
import DatePicker from 'components/uikit/DatePicker';
import {EMPLOYEE_LEVEL_DICT, GEO_LEVEL_DICT} from 'constants.js';
import {isNullOrWhitespace} from 'utils';

const removePredicate = () => true;
const keySelector = award => award.indexAsKey;
const compareAwards = (_, currentAward, index) => index === keySelector(currentAward);
const setEntry = (setter, currentEntry, entries) =>
    setItem(setter, compareAwards, currentEntry, entries);

class PersonnelInfoBlock extends React.Component {

    componentDidMount() {
        const { geoLevel, managerLevel, selectedGeoLevel, selectedManagerLevel} = this.props;

        this.createSelectValue(geoLevel, selectedGeoLevel,"selectedGeoLevel", GEO_LEVEL_DICT );
        this.createSelectValue(managerLevel, selectedManagerLevel, "selectedManagerLevel", EMPLOYEE_LEVEL_DICT );
    }

    createSelectValue = (value, selectedValue, key, dictionary) => {
        const {handleStateChange} = this.props;
        if (selectedValue === undefined && value && !isNullOrWhitespace(value)) {
            handleStateChange({
                [key]: {
                    value: value, label: dictionary.find(x => x.value === value).label,
                }
            })
        }
    }

    renderAward = (award, canRemove, handleRemove) => {
        const { competitionLevels, isInputFilled, isInputInvalid } = this.props;
        const { selectedCompetitionLevel, name } = award;
        const awardKey = keySelector(award);
        const getFieldId = fieldKey => `${fieldKey}_${awardKey}`;
        const errorKey = `award_${awardKey}`;

        return (
            <div className='past-residences-wrapper'>
                <Field
                    required
                    filled={isInputFilled(selectedCompetitionLevel)}
                    invalid={isInputInvalid("selectedCompetitionLevel", [this.validator(selectedCompetitionLevel, "Выберите уровень конкурса")], errorKey)}
                    size="50"
                >
                    <Label htmlFor={getFieldId("competitionLevel")}>Уровень конкурса</Label>
                    <Select
                        inputId={getFieldId("competitionLevel")}
                        value={selectedCompetitionLevel}
                        onChange={this.handleChangeAwardCompetitionLevel(award)}
                        options={competitionLevels.data}
                        catalog
                    />
                </Field>
                <Field
                    required
                    filled={isInputFilled(name)}
                    invalid={isInputInvalid("name", [this.validator(name, "Введите вид награды")], errorKey)}
                    size="50"
                    className="kr_input_with_text"
                >
                    <Label htmlFor={getFieldId("name")}>Вид награды</Label>
                    <Input
                        className="Awards__Input"
                        id={getFieldId("name")}
                        value={name || ''}
                        onChange={this.handleChangeAwardName(award)}
                        maxLength={500}
                    />
                    {canRemove && <ItemButton type="remove" onClick={handleRemove} />}
                </Field>
            </div>
        );
    };

    renderAwards = memoize((awards) => {
        return (
            <DataCardBlock title="Награды / достижения">
                <DataList
                    customRemove
                    isCompact
                    onAdd={this.handleAddAward}
                    onRemove={this.handleRemoveAward}
                    canRemove={removePredicate}
                    entries={awards.map((award, index) => ({ ...award, indexAsKey: index }))}
                    keySelector={keySelector}
                    renderEntry={this.renderAward}
                />
            </DataCardBlock>
        );
    });

    render() {
        const {
            isInputFilled,
            isInputInvalid,
            handleStateChange,
            isEditBlock,
            renderEditBlock,
            selectedDataOrigin,
            recommenderName,
            selectedExperienceLevel,
            socialActivity,
            memberOfSocialOrganizations,
            socialAchivements,
            extraInfo,
            inclusionDate,
            inclusionReason,
            reserveLevel,
            governmentWorkPositions,
            curatorName,
            selectedGeoLevel,
            selectedManagerLevel,
        } = this.props;

        return (
            <DataCard
                shadow
                title="Дополнительная информация"
                className="kr_profile-anketa"
                isOpen={this.props.hasValidationErrors}
            >
                <Form autoComplete={false} className="EditPerson__Form">
                    <Row>
                        <Field
                            filled={isInputFilled(socialActivity, false)}
                            size="100"
                        >
                            <Label>
                                Социальная активность
                                <span className="tooltip-link" data-tip data-for='socialActivityHint'>?</span>
                                <ReactTooltip id='socialActivityHint' effect={'solid'} border={true} type={'light'} data-offset="{'top': 0, 'left': 0}">
                                    <p className="tooltip">
                                        Информация о формах социальной и профессиональной активности, не относящаяся к служебной деятельности:
                                        волонтерская активность, работа на конференциях, форумах, круглых столах, ссылки на материалы в СМИ от
                                        лица участника резерва, проектная деятельность (в том числе совместная с другими участниками Резерва) и т.д
                                    </p>
                                </ReactTooltip>
                            </Label>
                            <InputText
                                id="socialActivity"
                                value={socialActivity || ""}
                                maxLength={4000}
                                onChange={e => handleStateChange({ socialActivity: e.target.value })}
                            />
                        </Field>
                    </Row>
                    <Row>
                        <Field
                            filled={isInputFilled(memberOfSocialOrganizations, false)}
                            size="50"
                        >
                            <Label>Членство в общественных организациях
                                <span className="tooltip-link" data-tip data-for='memberOfSocialOrganizationsHint'>?</span>
                                <ReactTooltip id='memberOfSocialOrganizationsHint' effect='solid' border={true} type={'light'} data-offset="{'top': 0, 'left': 100}">
                                    <p className="tooltip">
                                        Информация о членстве в общественных организациях (период, название организации и роль).
                                        Также здесь указывается работа в комиссиях, рабочих группах.
                                    </p>
                                </ReactTooltip>
                            </Label>
                            <InputText
                                id="memberOfSocialOrganizations"
                                value={memberOfSocialOrganizations || ""}
                                maxLength={450}
                                onChange={e => handleStateChange({ memberOfSocialOrganizations: e.target.value })}
                            />
                        </Field>
                        <Field
                            filled={isInputFilled(socialAchivements, false)}
                            size="50"
                        >
                            <Label>Деятельность в качестве руководителя
                                <span className="tooltip-link" data-tip data-for='socialAchivementsHint'>?</span>
                                <ReactTooltip id='socialAchivementsHint' effect='solid' border={true} type={'light'} data-offset="{'top': 0, 'left': 0}">
                                    <p className="tooltip">
                                        Деятельность в качестве инициатора, руководителя, координатора
                                        при реализации социально- и экономически- значимых проектов регионального (федерального) и
                                        местного уровней (в том числе не связанная с участием в Резерве); описание достижений.
                                    </p>
                                </ReactTooltip>
                            </Label>
                            <InputText
                                id="socialAchivements"
                                value={socialAchivements || ""}
                                maxLength={450}
                                onChange={e => handleStateChange({ socialAchivements: e.target.value })}
                            />
                        </Field>
                    </Row>
                    {this.renderAwards(this.props.awards)}
                    <Row>
                        <Field
                            required
                            filled={isInputFilled(selectedDataOrigin)}
                            invalid={isInputInvalid('selectedDataOrigin', [requireValidator('Выберите источник данных')])}
                            size="50"
                        >
                            <Label htmlFor="selectedDataOrigin">
                                Источник данных
                            </Label>
                            <Select
                                inputId="selectedDataOrigin"
                                value={selectedDataOrigin}
                                onChange={selectedDataOrigin => handleStateChange({ selectedDataOrigin })}
                                options={this.props[DATA_ORIGINS].data}
                                isClearable
                                catalog
                            />
                        </Field>
                        <Field
                            filled={isInputFilled(selectedExperienceLevel, false)}
                            size="50"
                        >
                            <Label htmlFor="selectedExperienceLevel">
                                Уровень подготовленности участника
                            </Label>
                            <Select
                                inputId="selectedExperienceLevel"
                                value={selectedExperienceLevel}
                                onChange={newSelectedExperienceLevel => handleStateChange({ selectedExperienceLevel: newSelectedExperienceLevel })}
                                options={this.props[EXPERIENCE_LEVELS].data}
                                isClearable
                                catalog
                            />
                        </Field>
                    </Row>
                    <Row >
                        <Field
                            filled={isInputFilled(recommenderName, false)}
                            size="50"
                        >
                            <Label htmlFor="recommenderName">
                                Рекомендатель
                            </Label>
                            <Input
                                id="recommenderName"
                                maxLength={250}
                                value={recommenderName || ""}
                                onChange={e => handleStateChange({ recommenderName: e.target.value })}
                            />
                        </Field>

                        <Field
                            filled={isInputFilled(curatorName, false)}
                            size="50"
                        >
                            <Label htmlFor="сuratorName">
                                Куратор
                            </Label>
                            <Input
                                id="curatorName"
                                maxLength={250}
                                onChange={(e) => handleStateChange({ curatorName: e.target.value })}
                                value={curatorName || ''}
                            />
                        </Field>
                    </Row>
                    <Row>
                    <Field
                            filled={isInputFilled(selectedGeoLevel, false)}
                            size="50"
                        >
                            <Label htmlFor="geoLevel">
                            Географический уровень
                            </Label>
                            <Select
                                id="geoLevel"
                                options={GEO_LEVEL_DICT}
                                onChange={(newSelectedGeoLevel) => handleStateChange({ selectedGeoLevel: newSelectedGeoLevel })}
                                value={(selectedGeoLevel && selectedGeoLevel) }
                                isClearable
                            />
                        </Field>
                        <Field
                            filled={isInputFilled(selectedManagerLevel, false)}
                            size="50"
                        >
                            <Label htmlFor="managerLevel">
                            Уровень менеджмента
                            </Label>
                            <Select
                                id="managerLevel"
                                options={EMPLOYEE_LEVEL_DICT}
                                onChange={(newSelectedManagerLevel) => handleStateChange({ selectedManagerLevel: newSelectedManagerLevel })}
                                value={selectedManagerLevel && selectedManagerLevel}
                                isClearable
                            />
                        </Field>
                    </Row>
                    <Row>
                        <Field
                            size="50"
                        >
                            <Label htmlFor="inclusionDate">Дата включения в резерв</Label>
                            <DatePicker
                                id="inclusionDate"
                                selected={inclusionDate ? new Date(inclusionDate) : null}
                                onChange={value => handleStateChange({ inclusionDate: value && value.toDateString() })}
                                noDataBtn
                            />
                        </Field>
                        <Field
                            filled={isInputFilled(inclusionReason, false)}
                            size="50"
                        >
                            <Label htmlFor="inclusionReason">
                                Основание включения
                            </Label>
                            <Input
                                id="inclusionReason"
                                value={inclusionReason || ""}
                                maxLength={450}
                                onChange={e => handleStateChange({ inclusionReason: e.target.value })}
                            />
                        </Field>
                    </Row>
                    <Row>
                        <Field
                            filled={isInputFilled(reserveLevel, false)}
                            size="100"
                        >
                            <Label htmlFor="reserveLevel">
                                Уровень резерва
                            </Label>
                            <Input
                                id="reserveLevel"
                                value={reserveLevel || ""}
                                maxLength={250}
                                onChange={e => handleStateChange({ reserveLevel: e.target.value })}
                            />
                        </Field>
                    </Row>
                    <Row>
                        <Field
                            filled={isInputFilled(governmentWorkPositions, false)}
                            size="100"
                        >
                            <Label htmlFor="governmentWorkPositions">
                                Работа на выборных должностях
                            </Label>
                            <InputText
                                id="governmentWorkPositions"
                                value={governmentWorkPositions || ""}
                                maxLength={450}
                                onChange={e => handleStateChange({ governmentWorkPositions: e.target.value })}
                            />
                        </Field>
                    </Row>
                    <Row>
                        <Field
                            filled={isInputFilled(extraInfo, false)}
                            size="100"
                        >
                            <Label htmlFor="extraInfo">
                                Дополнительные сведения
                            </Label>
                            <InputText
                                value={extraInfo || ""}
                                maxLength={4000}
                                onChange={e => handleStateChange({ extraInfo: e.target.value })}
                            />
                        </Field>
                    </Row>
                    <Row>
                        {isEditBlock ? renderEditBlock() : null}
                    </Row>
                </Form>
            </DataCard>
        );
    }

    validator = (value, errorMessage) => {
        return () => {
            return !!value ? null : errorMessage;
        };
    }

    handleAddAward = () => {
        const { awards, handleStateChange } = this.props;
        const newAwards = [...awards, {}];
        handleStateChange({ awards: newAwards });
    };

    handleRemoveAward = currentAward => {
        const { awards, handleStateChange } = this.props;
        const newAwards = awards.filter((award, index) => !compareAwards(award, currentAward, index));
        handleStateChange({ awards: newAwards });
    };

    handleChangeAwardCompetitionLevel = currentAward => selectedCompetitionLevel => {
        const { awards, handleStateChange } = this.props;
        const newAwards = setEntry(entry => ({ ...entry, selectedCompetitionLevel }), currentAward, awards);
        handleStateChange({ awards: newAwards });
    };

    handleChangeAwardName = currentAward => e => {
        const { awards, handleStateChange } = this.props;
        const newName = e.currentTarget.value;
        const newAwards = setEntry(entry => ({ ...entry, name: newName }), currentAward, awards);
        handleStateChange({ awards: newAwards });
    };
}

const actions = {
    showWarningAlert,
    showErrorAlert,
};

export default connect(null, actions)(ToggleOpen(ValidBlock(PersonnelInfoBlock)));