import React from 'react';

const HeaderStep = ({ title, info, descr }) => (
    <div className="LKProgressPlanHeader">
        <p className="LKProgressPlanHeader__Title">{title}</p>
        <div className="LKProgressPlanHeader__Info">{info}</div>
        <p className="LKProgressPlanHeader__Description">{descr}</p>
    </div>
);

export default HeaderStep;
