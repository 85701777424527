import React from "react";
import classNames from 'classnames';
import "./LinkButton.scss";

 const LinkButton = (props) => {
    const {
        children,
        add,
        close,
        ...tail
    } = props;

    const finalClass = classNames (
        "LinkButton", 
        {"LinkButton__add": add},
        {"LinkButton__close": close}
    );

    return (
        <button className={finalClass}  {...tail}>
            {children}
        </button>
    );
}

export default LinkButton