import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './EvaluationPersonPercent.scss';

const EvaluationPersonDecimal = props => {
    const { value = undefined, name } = props;

    const blockClassName = classnames('EvaluationPersonPercent', 'EvaluationPersonPercent--decimal');

    return (
        <>
            <div className="EvaluationPersonPercent__Name">{name}</div>
            {value === undefined && (
                <div className="EvaluationPerson__Content-NoData">Нет результатов оценки</div>
            )}
            {value !== undefined && <div className={blockClassName}>{`${value}`}</div>}
        </>
    );
};

EvaluationPersonDecimal.propTypes = {
    value: PropTypes.number,
    normalizedValue: PropTypes.number,
};

export default EvaluationPersonDecimal;
