import React from 'react';
import { getUserFullName, formatDate } from 'utils';
import ChevronButton from 'components/uikit/ChevronButton';
import DataGrid from 'components/common/DataGrid';

export const mainMenuIds = {
    groupInfo: 'groupInfo',
    experts: 'experts',
    quotas: 'quotas',
    competencies: 'competencies',
};

export const TYPE_SCOPE = {
    scope: 'Scope',
    default: 'Default',
};

export const expertColumns = [
    {
        id: 'id',
        Header: 'Id',
        maxWidth: 100,
        resize: false,
        sortable: false,
        Cell: ({ original }) => {
            return original.id;
        },
    },
    {
        id: 'fullName',
        Header: 'Ф.И.О.',
        resize: false,
        sortable: false,
        Cell: ({ original }) => {
            return getUserFullName(original);
        },
    },
    {
        id: 'phoneNumber',
        Header: 'Телефон',
        resize: false,
        sortable: false,
        Cell: ({ original }) => {
            return original.phone;
        },
    },
    {
        id: 'email',
        Header: 'E-mail',
        sortable: false,
        resize: false,
        Cell: ({ original }) => {
            return original.email;
        },
    },
];

export const quotasColumns = [
    {
        id: 'period',
        Header: 'Период',
        resize: false,
        sortable: false,
        Cell: ({ original }) => {
            return formatDate(original.period, 'YYYY');
        },
    },
    {
        id: 'total',
        Header: 'Распределено',
        resize: false,
        sortable: false,
        Cell: ({ original }) => {
            return original.total;
        },
    },
    {
        id: 'balance',
        Header: 'Остаток',
        resize: false,
        sortable: false,
        Cell: ({ original }) => {
            return original.balance;
        },
    },
];

export const competencyModelsColumns = [
    {
        id: 'name',
        Header: 'Название',
        resize: false,
        sortable: false,
        Cell: ({ original }) => {
            return original.name;
        },
    },
    {
        id: '',
        expander: true,
        width: 65,
        Expander: ({ isExpanded }) => <ChevronButton isUp={isExpanded} />,
        style: {
            cursor: 'pointer',
            fontSize: 25,
            padding: '0',
            textAlign: 'center',
            userSelect: 'none',
        },
    },
];

export const columns = {
    [mainMenuIds.competencies]: competencyModelsColumns,
    [mainMenuIds.experts]: expertColumns,
    [mainMenuIds.quotas]: quotasColumns,
};

const competenciesColumn = [
    {
        Header: 'Компетенция',
        resize: false,
        sortable: false,
        Cell: ({ original }) => {
            return original.name;
        },
    }
];

export const extraProps = {
    [mainMenuIds.competencies]: {
        SubComponent: ({ original }) => {
            return original.competencies ? (
                <div className="GroupExtraInfo__Table GroupExtraInfo__Table--sub">
                    <DataGrid
                        showHeader={false}
                        data={original.competencies}
                        columns={competenciesColumn}
                        showPagination={false}
                        showPageSizeOptions={false}
                    />
                </div>
            ) : null;
        },
    },
};

export const mainMenuItems = [
    { id: mainMenuIds.groupInfo, title: 'Группа', roles: ['admin', 'support'], scope: true },
    { id: mainMenuIds.experts, title: 'Эксперты', roles: ['support'] },
    { id: mainMenuIds.quotas, title: 'Квоты', roles: ['support'] },
    { id: mainMenuIds.competencies, title: 'Модели компетенций ', roles: ['support'] },
];
