import React from 'react';
import PropTypes from 'prop-types';
import Popover from 'components/Lk/Uikit/Popover';
import './Chip.scss';

const Chip = (props) => {
    const { text, onDelete, canDelete } = props;
    return (
        <Popover tooltip={text} autoPlacement={false}>
            <div className="Chip">
                <div className="Chip__TextArea">{text}</div>
                {canDelete && <div className="Chip__CloseBtn" onClick={onDelete} />}
            </div>
        </Popover>
    );
};

Chip.defaultProps = {
    onDelete: () => null,
    canDelete: false,
};

Chip.propTypes = {
    text: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    canDelete: PropTypes.bool,
};

export default Chip;
