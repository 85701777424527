import { appName } from '../constants';
import { LOCATION_CHANGE } from 'connected-react-router';
import { put, call } from 'redux-saga/effects';

const moduleName = 'page-loader';
export const SHOW_PAGE_LOADER = `${appName}/${moduleName}/SHOW_PAGE_LOADER`;
export const HIDE_PAGE_LOADER = `${appName}/${moduleName}/HIDE_PAGE_LOADER`;

const initialState = {
    visible: false,
    showOverlay: false,
    amount: 0,
}

export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SHOW_PAGE_LOADER:
            return {
                visible: true,
                showOverlay: payload.showOverlay,
                amount: state.amount + 1
            };

        case HIDE_PAGE_LOADER:
            const amount = state.amount > 1
                ? state.amount - 1
                : 0;

            return {
                ...state,
                visible: amount > 0,
                showOverlay: state.showOverlay && amount > 0,
                amount: amount
            };

        case LOCATION_CHANGE:
            return initialState;

        default:
            return state;
    }
}

export const showPageLoader = (showOverlay = true) => {
    return {
        type: SHOW_PAGE_LOADER,
        payload: { showOverlay: !!showOverlay },
    }
}

export const hidePageLoader = () => {
    return {
        type: HIDE_PAGE_LOADER,
    }
}

export const withPageLoader = function* (apiCall, overlay = true) {
    yield put(showPageLoader(overlay));

    try {
        return yield call(apiCall);
    }
    finally {
        yield put(hidePageLoader());
    }
}

export const allActions = {
    showPageLoader, hidePageLoader
}