import { appName } from '../constants';
import { getCatalog } from '../api';
import { takeEvery, put, call, select, all } from 'redux-saga/effects';
import { mapEnumToCatalog, indexArray } from 'utils';
import { 
    sex as sexEnum, 
    familyStatus as familyStatusEnum,
    sexFamilyStatus as sexFamilyStatusEnum
} from '../enums';
import { showErrorAlert } from './Alert';
import RequestError from '../RequestError';

export const FEDERAL_DISTRICTS = 'federalDistricts';
export const REGIONS = 'regions';
export const REGION_LOCALITIES = 'regionLocalities';
export const LOCALITIES = 'localities';
export const IDENTITY_DOCUMENTS = 'identityDocuments';
export const EDUCATIONAL_LEVELS = 'educationalLevels';
export const STUDY_FORMS = 'studyForms';
export const INDUSTRIES = 'industries';
export const WORK_AREAS = 'workAreas';
export const MANAGEMENT_LEVELS = 'managementLevels';
export const MANAGEMENT_EXPERIENCES = 'managementExperiences';
export const EMPLOYEES_NUMBERS = 'employeesNumbers';
export const LANGUAGES = 'languages';
export const LANGUAGE_LEVELS = 'languageLevels';
export const SOCIAL_NETWORKS = 'socialNetworks';
export const COUNTRIES = 'countries';
export const SEX = 'sexCatalog';
export const FAMILY_STATUS = 'familyStatuses';
export const SEX_FAMILY_STATUS = 'sexFamilyStatuses';
export const CHILDREN_STATUS = 'childrenStatus';
export const DATA_ORIGINS = 'dataOrigins';
export const EXPERIENCE_LEVELS = 'experienceLevels';
export const GOVT_SERVICE_KINDS = 'governmentServiceKinds';
export const COMPETITION_LEVELS = 'competitionLevels';

const moduleName = 'catalog';
export const FETCH_REQUEST = `${appName}/${moduleName}/FETCH_REQUEST`;
export const FETCH_REQUEST_ALL = `${appName}/${moduleName}/FETCH_REQUEST_ALL`;
export const FETCH_START = `${appName}/${moduleName}/FETCH_START`;
export const FETCH_SUCCESS = `${appName}/${moduleName}/FETCH_SUCCESS`;
export const FETCH_FAILED = `${appName}/${moduleName}/FETCH_FAILED`;
export const CLEAR_CATALOG = `${appName}/${moduleName}/CLEAR_CATALOG`;

const sexCatalog = mapEnumToCatalog(sexEnum, true);
const familyStatusCatalog = mapEnumToCatalog(familyStatusEnum, true);
const sexFamilyStatusData = {
    All:familyStatusCatalog,
    Male: mapEnumToCatalog(sexFamilyStatusEnum.Male, true),
    Female: mapEnumToCatalog(sexFamilyStatusEnum.Female, true),
}

const catalogInitialState = {
    loading: false,
    loadComplete: false,
    data: [],
    indexedData: {},
    error: '',
};

const enumCatalogInitialState = {
    ...catalogInitialState,
    loadComplete: true,
};

const initialState = {
    [FEDERAL_DISTRICTS]: catalogInitialState,
    [REGIONS]: catalogInitialState,
    [REGION_LOCALITIES]: { ...catalogInitialState, loadComplete: true }, // Если регион не указан, то пустой массив это и есть данные
    [LOCALITIES]: { ...catalogInitialState},
    [IDENTITY_DOCUMENTS]: catalogInitialState,
    [EDUCATIONAL_LEVELS]: catalogInitialState,
    [STUDY_FORMS]: catalogInitialState,
    [INDUSTRIES]: catalogInitialState,
    [WORK_AREAS]: catalogInitialState,
    [MANAGEMENT_LEVELS]: catalogInitialState,
    [MANAGEMENT_EXPERIENCES]: catalogInitialState,
    [EMPLOYEES_NUMBERS]: catalogInitialState,
    [LANGUAGES]: catalogInitialState,
    [LANGUAGE_LEVELS]: catalogInitialState,
    [SOCIAL_NETWORKS]: catalogInitialState,
    [COUNTRIES]: catalogInitialState,
    [SEX]: { ...enumCatalogInitialState, data: sexCatalog, indexedData: indexArray(sexCatalog) },
    [FAMILY_STATUS]: { 
        ...enumCatalogInitialState, 
        data: familyStatusCatalog,
        sexData:sexFamilyStatusData,
        indexedData: indexArray(familyStatusCatalog) 
    },
    [CHILDREN_STATUS]: catalogInitialState,
    [DATA_ORIGINS]: catalogInitialState,
    [EXPERIENCE_LEVELS]: catalogInitialState,
    [GOVT_SERVICE_KINDS]: catalogInitialState,
    [COMPETITION_LEVELS]: catalogInitialState,
};

const defaultParams = {
    [REGION_LOCALITIES]: [],
    [LOCALITIES]: [],
};

export default function reducer(state = initialState, action) {
    const { type, payload, error } = action;
    switch (type) {
        case FETCH_START:
            return {
                ...state,
                [payload.catalogName]: {
                    ...catalogInitialState,
                    params: payload.params,
                    loading: true,
                },
            };

        case FETCH_SUCCESS:
            return {
                ...state,
                [payload.catalogName]: {
                    ...catalogInitialState,
                    loadComplete: true,
                    data: payload.data,
                    indexedData: indexArray(payload.data),
                    params: payload.params,
                },
            };

        case FETCH_FAILED:
            return {
                ...state,
                [payload.catalogName]: {
                    ...catalogInitialState,
                    loadComplete: true,
                    params: payload.params,
                    error: error.message,
                },
            };
        case CLEAR_CATALOG:
            return {
                ...state,
                [payload.catalogName]: {
                    ...catalogInitialState,
                    loadComplete: true,
                },
            };
        default:
            return state;
    }
}

export const fetchCatalog = (catalogName, params, isNormalizedForReactSelect = false, filter = null) => {
    return {
        type: FETCH_REQUEST,
        payload: { catalogName, params, isNormalizedForReactSelect, filter },
    }
}

export const fetchCatalogs = (catalogs) => {
    return {
        type: FETCH_REQUEST_ALL,
        payload: { catalogs },
    }
}

export const fetchStart = (catalogName, params) => {
    return {
        type: FETCH_START,
        payload: { catalogName, params },
    }
}

export const fetchSuccess = (catalogName, data, params) => {
    return {
        type: FETCH_SUCCESS,
        payload: { catalogName, data, params }
    }
}

export const fetchFailed = (catalogName, error, params) => {
    return {
        type: FETCH_FAILED,
        payload: { catalogName, params },
        error,
    }
}

export const allActions = {
    fetchCatalog, fetchStart, fetchSuccess, fetchFailed
};

export const clearCatalog = catalogName => {
    return {
        type: CLEAR_CATALOG,
        payload: { catalogName },
    }
};

const catalogExceptions = [
    REGIONS,
    REGION_LOCALITIES,
    LOCALITIES
];

const normalizeForReactSelect = (data) => {
    return data.map(x => ({
        ...x, 
        value: x.id,
        label: x.name,
    }));
};

// отфильтровать каталог по полю filterName
// params - массив значений
const filterCatalog = (catalog, filterName, params = []) => {
    return catalog.filter(x => params.includes(x[filterName]));
};

const fetchCatalogSaga = function* (action) {
    const { catalogName, params, isNormalizedForReactSelect, filter = null } = action.payload;
    const state = yield select();
    const catalog = state.catalogs[catalogName];
    if (catalog == null) {
        console.error(`Catalog ${catalogName} does not exist`);
        return;
    }
    
    if ((catalog.loading || (catalog.loadComplete && !catalog.error)) && !catalogExceptions.includes(catalogName)) {
        return;
    }
    
    yield put(fetchStart(catalogName, params));

    const isRequestActual = state => state.catalogs[catalogName].params === params;
    try {
        const response = yield call(getCatalog, catalogName, params || defaultParams[catalogName]);
        if (isRequestActual(yield select()) || filter) {
            let data = yield  isNormalizedForReactSelect ? normalizeForReactSelect(response.data) : response.data;
            data = filter ? filterCatalog(data, filter.name, filter.params) : data;
            yield put(fetchSuccess(catalogName, data, params));
        }
    } catch (error) {
        if (isRequestActual(yield select())) {
            const reqError = new RequestError(error, `При загрузке справочника "${catalogName}" произошла ошибка`);
            yield all([
                put(fetchFailed(catalogName, reqError, params)),
                put(showErrorAlert(reqError.message))
            ]);
        }
    }
}

function* fetchCatalogsSaga(action) {
    const { catalogs } = action.payload;
    if (Array.isArray(catalogs)) {
        yield all(catalogs.map(c => put(fetchCatalog(c, null, true))));
    } else {
        yield call(fetchCatalogSaga, {catalogName: catalogs});
    }
}


export const saga = function* () {
    yield takeEvery(FETCH_REQUEST, fetchCatalogSaga);
    yield takeEvery(FETCH_REQUEST_ALL, fetchCatalogsSaga);
}