import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import { getAdminUserIssues } from 'api';
import DataGrid from 'components/common/DataGrid';
import { ISSUE_STATUS, ISSUE_TYPE } from 'constants.js';
import { localizeTimezone } from 'utils';
import ChevronButton from 'components/uikit/ChevronButton';
import Select from 'components/uikit/Select';
import Button from 'components/uikit/Button';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import DatePicker from 'components/uikit/DatePicker';
import InfoCard from "./InfoCard";
import { mainMenuItems } from './constants';
import moment from 'moment';

const statusNameResolver = statusValue => {
    const result = ISSUE_STATUS.find(x => x.value === statusValue);
    return <div>{result && result.label}</div>;
};

const defaultFilter = {
    dateFrom: null,
    dateTo: null,
    status: [],
    type: [],
};

class UserIssues extends Component {
    state = {
        userIssues: [],
        pageCount: 0,
        foundCount: 0,
        paging: { pageNum: 1, pageSize: 50 },
        filter: defaultFilter,
        sorting: {
            created: 'desc',
        },
    };

    expander = {
        expander: true,
        Expander: ({ isExpanded }) => {
            return <ChevronButton isUp={isExpanded} />;
        },
        headerStyle: { textAlign: 'left' },
    };

    columns = [
        {
            Header: 'Задача',
            accessor: 'title',
            Cell: ({ original }) => <div className={original.isViewed ? '' : 'UserIssues--bold'}>{original.title}</div>,
        },
        {
            Header: 'Автор',
            accessor: 'authorName',
            sortable: false,
            Cell: ({ original }) => <div className={original.isViewed ? '' : 'UserIssues--bold'}>{original.authorName}</div>,
        },
        {
            Header: 'Выполнить до',
            accessor: 'dueDate',
            Cell: ({ original }) => <div className={original.isViewed ? '' : 'UserIssues--bold'}>{localizeTimezone(original.dueDate, 'L')}</div>,
        },
        {
            Header: 'Статус',
            accessor: 'status',
            maxWidth: 200,
            Cell: ({ original }) => <div className={original.isViewed ? '' : 'UserIssues--bold'}>{statusNameResolver(original.status)}</div>,
        },
    ];

    clearFilter = () => {
        const { paging } = this.state;
        this.setState({ paging: { ...paging, pageNum: 1 }, filter: { ...defaultFilter } }, () => {
            this.searchIssues();
        });
    };

    searchIssues = async (resetPaging = false) => {
        const { paging, filter, sorting } = this.state;
        const { showPageLoader, hidePageLoader, showErrorAlert, userId } = this.props;

        const newFilter = { ...filter, 
            status: filter.status ? filter.status.map(x => x.value) : [], 
            type: filter.type ? filter.type.map(x => x.value) : [],
            dateFrom: filter.dateFrom ? moment(filter.dateFrom).format() : null,
            dateTo: filter.dateTo ? moment(filter.dateTo).endOf('day').format() : null
        };
        const criteria = {
            paging: resetPaging ? { ...paging, pageNum: 1 } : paging,
            filter: { ...newFilter, userIds: [userId] },
            sorting,
        };

        resetPaging && this.setState({ paging: { ...paging, pageNum: 1 } });

        try {
            showPageLoader();
            const data = await getAdminUserIssues(userId, criteria);
            
            this.setState({
                userIssues: data.data.payload,
                pageCount: data.data.meta.pageCount,
                foundCount: data.data.meta.foundCount,
            });
            
        } catch (e) {
            showErrorAlert('Ошибка загрузки задач.');
        } finally {
            hidePageLoader();
        }
    };

    onPageChange = pageIndex => {
        this.setState({ expanded: {}, paging: { ...this.state.paging, pageNum: pageIndex + 1 } });
    };

    onSortedChange = sorted => {
        this.setState({
            sorting: { [sorted[0].id]: sorted[0].desc ? 'desc' : 'asc' },
        });
    };

    onFilterChange = (key, value) => {
        this.setState({
            filter: {
                ...this.state.filter,
                [key]: value,
            },
        });
    };

    componentDidMount() {
        this.searchIssues();
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            paging: { pageNum },
            sorting,
        } = this.state;

        if (
            prevState.paging.pageNum !== pageNum ||
            JSON.stringify(prevState.sorting) !== JSON.stringify(sorting)
        ) {
            this.searchIssues();
        }
    }

    changePageAmount = (pageSize) => {
        this.setState({ paging: { pageNum: 1, pageSize } }, () =>
            this.searchIssues(),
        );
    };

    render() {
        const {
            userIssues,
            paging,
            pageCount,
            foundCount,
            expanded,
            filter,
        } = this.state;

        const { header, getPropInfo } = this.props;

        const customSelectStyles = {
            input: base => ({
                ...base,
                height: 33,
                minHeight: 33,
                padding: 0,
                margin: 0,
            }),
            indicatorsContainer: base => ({
                ...base,
                height: 33,
                padding: 0,
                margin: 0,
            }),
            indicatorSeparator: base => ({
                ...base,
                height: 33,
            }),
        };

        const subComponent = {
            ...({
                    SubComponent: ({ original }) => {
                        return (
                            original && (
                                <InfoCard
                                    key={original.id}
                                    header=""
                                    data={original}
                                    propsArray={mainMenuItems.find(x => x.id === 'issues')?.props}
                                    getPropInfo={getPropInfo}
                                    wrapped
                                />
                            )
                        );
                    },
                }),
        };

        return (
            <div className="UserIssues">
                {header}
                <div className="UserIssues__Filter">
                    <Field className="UserIssues__Filter--full-width">
                        <Label>Статус</Label>
                        <Select
                            options={ISSUE_STATUS}
                            value={filter.status}
                            onChange={e => this.onFilterChange('status', e)}
                            isMulti
                            styles={customSelectStyles}
                        />
                    </Field>
                    <Field className="UserIssues__Filter--full-width">
                        <Label>Тип</Label>
                        <Select
                            options={ISSUE_TYPE}
                            value={filter.type}
                            onChange={e => this.onFilterChange('type', e)}
                            isMulti
                            styles={customSelectStyles}
                        />
                    </Field>
                    <Field>
                        <Label>Дата с</Label>
                        <DatePicker
                            selected={filter.dateFrom}
                            onChange={e => this.onFilterChange('dateFrom', e)}
                        />
                    </Field>
                    <Field>
                        <Label>Дата по</Label>
                        <DatePicker
                            selected={filter.dateTo}
                            onChange={e => this.onFilterChange('dateTo', e)}
                        />
                    </Field>
                    <Field>
                        <Label>&nbsp;</Label>
                        <Button size="xs" onClick={() => this.searchIssues(true)}>
                            Применить
                        </Button>
                    </Field>
                    <Field>
                        <Label>&nbsp;</Label>
                        <Button size="xs" onClick={this.clearFilter}>
                            Очистить фильтр
                        </Button>
                    </Field>
                </div>
                <div className="UserIssues__Table">
                    <DataGrid
                        data={userIssues}
                        foundCount={foundCount}
                        columns={[...this.columns, this.expander]}
                        showPagination
                        showPageSizeOptions={false}
                        onSortedChange={this.onSortedChange}
                        pages={pageCount}
                        page={paging.pageNum}
                        onPageChange={this.onPageChange}
                        manual
                        expanded={expanded}
                        {...subComponent}
                        pageSize={paging.pageSize}
                        changePageAmount={this.changePageAmount}
                    />
                </div>
            </div>
        );
    }
}

UserIssues.propTypes = {
    userId: PropTypes.number.isRequired,
    header: PropTypes.object,
    currentUser: PropTypes.object,
    isSupport: PropTypes.bool,
};

const actions = {
    showPageLoader,
    hidePageLoader,
    showErrorAlert,
};

export default connect(
    null,
    actions,
)(UserIssues);
