import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { personGrowthTagsRoute } from 'routes';
import PersonGrowthTags from './PersonGrowthTags';
import NotFound from '../../NotFound';
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import { ALLOWED_MODERATE_PERSONGROWTHTAGS } from '../../../rightsController';

class PersonGrowthTagsRoutes extends Component {
    render() {
        return (
            <Switch>
                <ProtectedRoute exact path={personGrowthTagsRoute.url} component={PersonGrowthTags} allowedRoles={ALLOWED_MODERATE_PERSONGROWTHTAGS} />
                <Route component={NotFound} />
            </Switch>
        );
    }
}

export default PersonGrowthTagsRoutes;