import { appName } from '../constants';
import { takeLatest, call, all, put } from 'redux-saga/effects';
import { getPersonTeams } from 'api';
import { showErrorAlert } from './Alert';
import RequestError from 'RequestError';
import { isModerator } from 'rightsController';

const moduleName = 'teams';
const FETCH = `${appName}/${moduleName}/FETCH`;
const SET = `${appName}/${moduleName}/SET`;
const CLEAR = `${appName}/${moduleName}/CLEAR`;
const REQUEST = `${appName}/${moduleName}/REQUEST`;
const SUCCESS = `${appName}/${moduleName}/SUCCESS`;

const initialState = {
    leading: [],
    following: [],
    curator: null,
    personId: null,
    isLoaded: false,
    loading: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET:
            const { leading, following, curator } = action.payload;
            return { ...state, leading, following, curator };
        case CLEAR:
            return initialState;
        case REQUEST:
            return { ...state, isLoaded: false, personId: action.payload, loading: true };
        case SUCCESS:
            return { ...state, isLoaded: true, loading: false };
        default:
            return state;
    }
}

export const fetchTeams = payload => ({ type: FETCH, payload });
export const setTeams = payload => ({ type: SET, payload });
export const clearTeams = () => ({ type: CLEAR });

const onLoadTeams = payload => ({ type: SUCCESS, payload });
const onRequestTeams = payload => ({ type: REQUEST, payload });

function* fetchTeamsSaga({ payload: personId }) {
    try {
        yield put(onRequestTeams(personId));
        const response = yield call(getPersonTeams, personId);
        yield put(setTeams(response.data));
        yield put(onLoadTeams(personId));
    } catch (error) {

        // Возвращается 404 когда выключены команды
        if (error && error.response && error.response.status === 404) {
            return;
        }

        const reqError = new RequestError(error, 'При загрузке команды произошла ошибка');
        yield put(showErrorAlert(reqError.message));
    }
}

export function* saga() {
    yield all([takeLatest(FETCH, fetchTeamsSaga)]);
}

// @TODO перенести в сервисы
const getFeatures = config => config && config.data && config.data.features;

export const isAllowedRegion = (features, user) => {
    return (
        features &&
        features.allowedTeamGroups &&
        features.allowedTeamGroups.indexOf(user.groupId) !== -1
    );
};

export const isAccessEnabled = state => {
    const {
        config,
        auth: { user },
    } = state;
    const features = getFeatures(config);
    if (!features) {
        return false;
    }

    const { personAccess } = features;
    if (!personAccess) {
        return false;
    }

    return isAllowedRegion(features, user) && isModerator(user);
};
