import React from "react";

function Row(props) {
    const { children } = props;

    return (
        <div className="Row__line">
            {children}
        </div>
    );
}

export default Row;