export const FILTERS_FILTER_MENU_ID = 'filters';
export const SEARCH_TEMPLATE_MENU_ID = 'searchTemplate';
export const FAVORITES_MENU_ID = 'favorites'

export const filtersMenu = [
    {
        id: FILTERS_FILTER_MENU_ID,
        name: 'Фильтры',
        preIcon: 'search_input_filter',
    },
    {
        id: SEARCH_TEMPLATE_MENU_ID,
        name: 'Запросы',
        preIcon: 'search_input_templates',
    },
    {
        id: FAVORITES_MENU_ID,
        name: 'Подборки',
        preIcon: 'search_input_wishlists',
    },
];
export const MIN_AGE = 14
export const MAX_AGE = 100;

export const MAX_TITLE_LENGTH = 128;

export const PERSON_FILTER_ID = 'person';
export const AGE_FILTER_ID = 'age';
export const PROFESSION_FILTER_ID = 'profession';
export const EDUCATION_FILTER_ID = 'education';
export const REGIONS_FILTER_ID = 'regions';
export const COMPITENCIES_FILTER_ID = 'competencies';
export const SOCIAL_FILTER_ID = 'social';
export const MANAGEMENT_FILTER_ID = 'management';
export const WISHLIST_FILTER_ID = 'wishlist';
export const EVALUATION_FILTER_ID = 'evaluation';

export const filters = [
    {id: AGE_FILTER_ID, name: 'Личные данные', menuFilterId: FILTERS_FILTER_MENU_ID},
    {id: EVALUATION_FILTER_ID, name: 'Оценка', menuFilterId: FILTERS_FILTER_MENU_ID},
    {id: PROFESSION_FILTER_ID, name: 'Профессия', menuFilterId: FILTERS_FILTER_MENU_ID},
    {id: EDUCATION_FILTER_ID, name: 'Образование', menuFilterId: FILTERS_FILTER_MENU_ID},
    {id: REGIONS_FILTER_ID, name: 'Связь с регионами', menuFilterId: FILTERS_FILTER_MENU_ID},
    {id: SOCIAL_FILTER_ID, name: 'Общественная деятельность', menuFilterId: FILTERS_FILTER_MENU_ID},
    {id: MANAGEMENT_FILTER_ID, name: 'Управленческий уровень', menuFilterId: FILTERS_FILTER_MENU_ID},
    {id: WISHLIST_FILTER_ID, name: 'Подборки и системные данные', menuFilterId: FILTERS_FILTER_MENU_ID},
];

export const SEARCH_TERM_FILTER = {id: 'searchTermFilter', label: 'Поиск:'};