import React, { Component } from 'react';
import Page from 'components/common/Page';
import EmailsList from './EmailsList';
import History from './History';
import { connect } from 'react-redux';
import { push as pushLocation, replace as replaceLocation } from 'connected-react-router';
import { parse as queryStringParse, stringify } from 'query-string';
import { massEmailLists } from 'routes';
import MenuSwitcher from 'components/common/MenuSwitcher';

const emailsSection = 'emails';
const historySection = 'history';

const menuItems = [
    { id: emailsSection, title: 'Письма' },
    { id: historySection, title: 'История рассылок' },
];

const defaultUrlParams = { section: emailsSection, page: 1 };

class MassEmail extends Component {
    setVisibleSection = (section) => {
        const urlParams = {
            ...this.props.queryParams,
            section: section,
            page: 1,
        };
        this.props.pushLocation({ pathname: massEmailLists.url, search: stringify(urlParams) });
    };

    componentDidMount() {
        !this.props.queryParams.section &&
            this.props.replaceLocation({
                pathname: massEmailLists.url,
                search: stringify(defaultUrlParams),
            });
    }

    render() {
        const {
            queryParams: { section },
        } = this.props;

        const switcher = (
            <MenuSwitcher
                menu={menuItems}
                selectedMenuId={section}
                onMenuClick={this.setVisibleSection}
            />
        );

        return (
            <Page>
                {section === emailsSection && (
                    <EmailsList setVisibleSection={this.setVisibleSection} menu={switcher} />
                )}
                {section === historySection && (
                    <History setVisibleSection={this.setVisibleSection} menu={switcher} />
                )}
            </Page>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryParams: queryStringParse(state.router.location.search),
    };
};

const actions = { pushLocation, replaceLocation };

export default connect(mapStateToProps, actions)(MassEmail);
