import React, { Component } from 'react';
import { push } from 'connected-react-router';
import moment from 'moment';
import pako from 'pako';
import { parse as queryStringParse, stringify } from 'query-string';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { showErrorAlert, showWarningAlert } from 'ducks/Alert';
import {
    fetchCatalog,
    fetchCatalogs,
    INDUSTRIES,
    REGION_LOCALITIES,
    LOCALITIES,
    REGIONS,
} from 'ducks/Catalog';
import {
    allSearchCatalogs,
    educationBlockCatalogs,
    familyStatusBlockCatalogs,
    governmentServiceInfoBlockCatalogs,
    languagesBlockCatalogs,
    personalInfoBlockCatalogs,
    search,
    updatePersonData,
    selectSearchProps,
    workInfoBlockCatalogs,
} from 'ducks/Search';
import { fetchAllowedGroups } from 'ducks/Groups';
import { setNeedAddFavorites } from 'ducks/Wishlists';
import Button from 'components/uikit/Button';
import PersonList from 'components/PersonList';
import Page, { PageTitle } from 'components/common/Page';
import PersonReportHeader from 'components/common/PersonReportHeader';
import PersonalInfoBlock from './PersonalInfoBlock';
import EducationBlock from './EducationBlock';
import WorkBlock from './WorkBlock';
import GovernmentServiceBlock from './GovernmentServiceBlock';
import EvaluationBlock from './EvaluationBlock';
import LanguagesBlock from './LanguagesBlock';
import FamilyStatusBlock from './FamilyStatusBlock';
import PersonnelInfoBlock from './PersonnelInfoBlock';
import SystemInfoBlock from './SystemInfoBlock';
import Form from 'components/common/Form';
import { personnelInfoBlockCatalogs } from 'ducks/Person';
import Sorting from './Sorting';
import Select from 'components/uikit/Select';
import ModalDialog from 'components/common/ModalDialog';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import InputGroup from 'components/uikit/InputGroup';
import uuid from 'uuid/v4';
import { search as searchRoute } from 'routes';
import InputNumeric from 'components/uikit/InputNumeric/InputNumeric';
import {
    getEvaluationTestList,
    getTestProvidersActive,
    getCatalog,
    getWishLists,
    getEvaluationSummaryScales,
    fullTextSearchSelect,
} from 'api.js';
import SearchTopMenu from './SearchTopMenu/';
import PopularBlockFields from './PopularBlockFields';
import {
    associativeArray,
    filtersName,
    altValues,
    searchBlocks,
    evaluationFields,
    queryOperation,
    getEvaluationLabel,
    categoryArray,
} from './QueryOperation';
import ActiveFilters from './ActiveFilters';
import memoize from 'fast-memoize';
import Input from 'components/uikit/Input';
import { debounce } from 'lodash';
import { queryOperationExtProps } from './QueryOperation';
import Loader from 'components/common/Loader';
import './Search.scss';

const compress = (str) => pako.deflate(str, { to: 'string' });
const decompress = (binStr) => pako.inflate(binStr, { to: 'string' });

const searchParamsToQueryString = compose(window.btoa, compress, JSON.stringify);

const searchParamsFromQueryString = compose(JSON.parse, decompress, window.atob);

const getBaseSearchParams = (state) => {
    return {
        dataDate: state.dataDate,
        paging: { pageNum: Number(state.paging.pageNum), pageSize: Number(state.paging.pageSize) },
        sorting: state.sorting,
        personalInfo: state.personalInfo,
        educationInfo: state.educationInfo,
        workInfo: state.workInfo,
        governmentServiceInfo: state.governmentServiceInfo,
        evaluationInfo: state.evaluationInfo,
        familyInfo: state.familyInfo,
        personnelInfo: state.personnelInfo,
        languagesInfo: state.languagesInfo,
        systemFilters: state.systemFilters,
        searchTerm: state.searchTerm,
        groupCondition: state.groupCondition,
        conditions: state.conditions,
        systemInfo: state.systemInfo,
    };
};

export const goToSearch = (push, filter) => {
    const search = {
        filter: searchParamsToQueryString({
            ...getBaseSearchParams(initialState),
            ...filter,
        }),
    };

    push({ pathname: searchRoute.url, search: stringify(search) });
};

const operation = { id: 0, code: '=', name: 'Равно' };

const initialState = {
    loading: false,
    groupCondition: 'AND',
    conditions: { system: 'AND' },
    dataDate: 'all',
    selecting: false,
    selection: {
        personIds: [],
    },
    searchResult: null,
    clearFilters: false,
    activeFilters: [],
    paging: {
        pageNum: 1,
        pageSize: 10,
    },
    sorting: {
        FullName: 'asc',
    },
    searchTerm: '',
    personalInfo: {
        lastName: { operation, value: '' },
        firstName: { operation, value: '' },
        middleName: { operation, value: '' },
        selectedSex: { operation, value: null },
        birthPlace: { operation, value: '' },
        email: { operation, value: '' },
        phone: { operation, value: '' },
        selectedDistricts: { operation, value: null },
        selectedRegions: {
            operation,
            value: null,
            extraFields: { duplicate: 'personResidence', value: true },
        },
        selectedLocalities: { operation, value: null },
        selectedDocument: { operation, value: null },
        documentNumber: { operation, value: '' },
        documentName: { operation, value: '' },
        ageStart: { operation: { code: '>=' }, value: '' },
        ageEnd: { operation: { code: '<=' }, value: '' },
        extraInfo: { operation, value: '' },
        residenceStartYearStart: { operation: { code: '>=' }, value: '' },
        residenceStartYearEnd: { operation: { code: '<=' }, value: '' },
        [REGION_LOCALITIES]: { data: [], loadComplete: true },
    },
    educationInfo: {
        selectedEducationLevel: { operation, value: null },
        university: { operation, value: '' },
        specialty: { operation, value: '' },
        graduationYear: { operation, value: '' },
        extraInfo: { operation, value: '' },
        graduationYearStart: { operation: { code: '>=' }, value: '' },
        graduationYearEnd: { operation: { code: '<=' }, value: '' },
    },
    workInfo: {
        company: {
            operation,
            value: '',
            addInfo: [{ name: 'isCurrent', operation: '=', value: true }],
        },
        position: { operation, value: '' },
        selectedIndustry: { operation, value: null },
        selectedWorkArea: { operation, value: null },
        selectedManagementLevel: { operation, value: null },
        selectedExperience: { operation, value: null },
        selectedEmployeesNumber: { operation, value: null },
        hireYearStart: { operation: { code: '>=' }, value: '' },
        hireYearEnd: { operation: { code: '<=' }, value: '' },
        professionalAchievements: { operation, value: '' },
    },
    governmentServiceInfo: {
        selectedGovernmentServiceKind: { operation, value: null },
        branch: { operation, value: '' },
        position: { operation, value: '' },
        rank: { operation, value: '' },
        startYearFrom: { operation: { code: '>=' }, value: '' },
        startYearTo: { operation: { code: '<=' }, value: '' },
    },
    evaluationInfo: {
        criteria: [],
    },
    familyInfo: {
        selectedFamilyStatus: { operation, value: null },
        selectedChildrenStatus: { operation, value: null },
    },
    languagesInfo: {
        language: { operation, value: null },
        languageLevel: { operation, value: null },
    },
    personnelInfo: {
        selectedDataOrigin: { operation, value: null },
        selectedExperienceLevel: { operation, value: null },
        recommenderName: { operation, value: '' },
        curatorName: { operation, value: '' },
        socialActivity: { operation, value: '' },
        memberOfSocialOrganizations: { operation, value: '' },
        socialAchivements: { operation, value: '' },
        extraInfo: { operation, value: '' },
    },
    systemInfo: {
        selectedGroups: {
            operation: { id: 4, code: 'checkgroupscopes', name: 'Равно' },
            value: null,
        },
        wishlists: { operation, value: null },
        selectedDataOrigin: { operation, value: null },
    },
    systemFilters: {
        createdStart: { operation: { id: 4, code: '>=', name: 'Больше или равно' }, value: null },
    },
    profileSearchValue: {},
    evaluationDialogIsVisible: false,
    evaluationDialog: {},
    evaluationProviders: [],
    evaluationSummaryScales: [],
    evaluationTests: [],

    isOpen: false,
    isCloseSearchFilter: false,
};

class Search extends Component {
    state = { ...initialState, wishlistCatalog: [] };

    cardRefs = {
        SearchFilter: React.createRef(),
    };

    static getDerivedStateFromProps = (props, state) => {
        if (JSON.stringify(props.searchResult) === JSON.stringify(state.searchResult)) {
            return state;
        }

        if (state.clearFilters === true) {
            return {
                ...initialState,
                clearFilters: false,
            };
        }

        const newState = {
            ...state,
            searchResult: props.searchResult,
            dataDate: initialState.dataDate,
            systemFilters: initialState.systemFilters,
        };

        if (props.queryParams.filter) {
            const searchParams = searchParamsFromQueryString(props.queryParams.filter);

            return {
                ...newState,
                personIds: searchParams.personIds || state.personIds,
                dataDate: searchParams.dataDate,
                paging: { ...newState.paging, pageNum: searchParams.paging.pageNum },
                sorting: searchParams.sorting,
                personalInfo: searchParams.personalInfo,
                educationInfo: searchParams.educationInfo,
                workInfo: searchParams.workInfo,
                governmentServiceInfo: searchParams.governmentServiceInfo,
                evaluationInfo: searchParams.evaluationInfo,
                familyInfo: searchParams.familyInfo,
                personnelInfo: searchParams.personnelInfo,
                languagesInfo: searchParams.languagesInfo,
                systemFilters: searchParams.systemFilters,
                searchTerm: searchParams.searchTerm,
                groupCondition: searchParams.groupCondition,
                conditions: searchParams.conditions,
                systemInfo: searchParams.systemInfo,
                extraInfo: {},
            };
        }

        return newState;
    };

    updateURL = () => {
        goToSearch(this.props.push, getBaseSearchParams(this.state));
    };

    loadWishlists = async () => {
        const result = await getWishLists({});
        const wishlists = result.data.payload.map((x) => ({
            ...x,
            value: x.id,
            label: (x.isDefault ? 'Моё избранное' : x.name) || 'Подборка',
            name: x.isDefault ? 'Моё избранное' : x.name,
        }));

        this.setState({ wishlistCatalog: wishlists });
    };

    addFavoritesToWishlists = () => {
        if (this.props.needAddFavorites) {
            this.props.setNeedAddFavorites(false);
            const fav = this.state.wishlistCatalog.find((x) => x.isDefault);
            if (!fav) return;

            const { wishlists } = this.state.systemInfo;
            const newWishlists = { ...wishlists, value: [fav] };
            this.setState({ systemInfo: { ...this.state.systemInfo, wishlists: newWishlists } });
        }
    };

    componentDidMount = async () => {
        try {
            this.setState({ loading: true }, async () => {
                await this.props.fetchCatalogs(allSearchCatalogs);
                await this.loadWishlists();
                this.setEvaluations();
                this.setEvaluationProviders();
                this.setEvaluationSummaryScales();
                this.getValuesFromAnalytics();
                this.performFetchAllowedGroups();
                this.addFavoritesToWishlists();
                this.search();
            });
        } catch {
            this.setState({ loading: false });
        }
    };

    componentDidUpdate = async (prevProps) => {
        if (JSON.stringify(prevProps.queryParams) !== JSON.stringify(this.props.queryParams)) {
            const prevSearchParams = prevProps?.queryParams?.filter ? searchParamsFromQueryString(prevProps.queryParams.filter) : {};
            const currSearchParams = this.props?.queryParams?.filter ? searchParamsFromQueryString(this.props.queryParams.filter) : {};
            delete prevSearchParams.paging;
            delete currSearchParams.paging;
            if (JSON.stringify(prevSearchParams) !== JSON.stringify(currSearchParams)) {
                this.setState({ selection: { ...this.state.selection, personIds: [] } });
            }
            this.search();
        }

        if (this.props.needUpdateAllowedGroups) {
            this.performFetchAllowedGroups();
        }
    };

    getLocalities = (searchTerm) => {
        if (!searchTerm) {
            this.setState({ [REGION_LOCALITIES]: { data: [], loadComplete: true } });
            return;
        }
        this.searchLocalities(searchTerm);
    };

    searchLocalities = debounce((searchTerm) => {
        try {
            this.setState({ [REGION_LOCALITIES]: { data: [], loadComplete: false } });
            getCatalog(LOCALITIES, { searchTerm }, true).then((res) => {
                const updatedRes = { ...res, loadComplete: true };
                this.setState({ [REGION_LOCALITIES]: updatedRes });
            });
        } catch (e) {
            this.props.showErrorAlert('Ошибка загрузки городов');
        }
    }, 300);

    setLocality = (locality) => {
        const {
            personalInfo,
            personalInfo: { selectedLocalities },
        } = this.state;

        this.setState({
            personalInfo: {
                ...personalInfo,
                selectedLocalities: {
                    ...selectedLocalities,
                    value: locality,
                },
            },
        });
    };

    getValuesFromAnalytics = () => {
        if (this.props.location.state && this.props.catalogs) {
            if (this.props.location.state.mapRegionId) {
                const { mapRegionId } = this.props.location.state;
                const dataRegions = this.props.catalogs[REGIONS].data;
                const region = dataRegions.filter((x) => x.id === mapRegionId);

                this.handleStateChange('personalInfo', ['selectedRegions', region]);
            } else if (this.props.location.state.analycitsIndustryId) {
                const { analycitsIndustryId } = this.props.location.state;
                const dataIndustries = this.props.catalogs[INDUSTRIES].data;
                const industry = dataIndustries.filter((x) => x.id === analycitsIndustryId);

                this.handleStateChange('workInfo', ['selectedIndustry', industry]);
            }
        }
    };

    setEvaluations = () => {
        getEvaluationTestList().then((data) => {
            this.setState({
                evaluationTests: [...data.data],
            });
        });
    };

    setEvaluationProviders = () => {
        getTestProvidersActive().then((data) => {
            const providers = data.data.map((x) => ({ ...x, name: x.title }));
            this.setState({ evaluationProviders: providers });
        });
    };

    setEvaluationSummaryScales = () => {
        getEvaluationSummaryScales().then((data) => {
            const summaryScalesResult = data.data
                .filter((x) => x.scaleLowerBound !== null && x.scaleUpperBound !== null)
                .map((x) => ({ ...x, name: x.title, value: x.id }));
            this.setState({ evaluationSummaryScales: summaryScalesResult });
        });
    };

    handleConditionChange = (name) => () => {
        const { conditions = {} } = this.state;

        const value = conditions[name] === 'AND' ? 'OR' : 'AND';

        this.setState(({ conditions }) => ({ conditions: { ...conditions, [name]: value } }));
    };

    handleStateChange = (blockStateKey, blockState, addInfo = false) => {
        const [objectField, value, extraFields] = blockState;

        if (!addInfo) {
            this.setState((state) => {
                return {
                    ...state,
                    paging: {
                        ...state.paging,
                        pageNum: 1,
                    },
                    [blockStateKey]: {
                        ...state[blockStateKey],
                        [objectField]: {
                            ...state[blockStateKey][objectField],
                            value,
                            extraFields:
                                extraFields ?? state[blockStateKey][objectField].extraFields,
                        },
                    },
                };
            });
        } else {
            this.setState((state) => {
                return {
                    ...state,
                    paging: {
                        ...state.paging,
                        pageNum: 1,
                    },
                    [blockStateKey]: {
                        ...state[blockStateKey],
                        [objectField]: {
                            ...state[blockStateKey][objectField],
                            addInfo: value,
                            extraFields,
                        },
                    },
                };
            });
        }
    };

    handleTypeOfAction = (blockStateKey, blockState) => {
        this.setState((state) => {
            const objectField = blockState[0];
            return {
                ...state,
                [blockStateKey]: {
                    ...state[blockStateKey],
                    [objectField]: {
                        ...state[blockStateKey][objectField],
                        operation: blockState[1],
                    },
                },
            };
        });
    };

    handleSelectionChange = (selection) => {
        this.setState({ selection });
    };

    handlePersonChange = (person) => {
        this.props.updatePersonData(person);
    };

    handleSelectAll = async () => {
        const ids = this.props?.searchResult?.foundCount ? await this.search(true) : [];
        this.setState({ selection: { personIds: ids }, selecting: false });
    };

    handleClearAll = () => {
        this.setState({ selection: { personIds: [] } });
    };

    getCatalogs = (names) => (catalogs) => {
        return names.reduce((obj, name) => {
            obj[name] = catalogs[name];
            return obj;
        }, {});
    };

    loadLocalities = (regions) => {
        this.props.fetchCatalog(REGION_LOCALITIES, regions);
    };

    showIntervalBlock = () => {
        return this.getCreatedEnd() && this.getCreatedStart();
    };

    selectedSearchValue = (item, key) => {
        switch (key) {
            case 'selectedRegions':
            case 'selectedLocalities':
            case 'selectedSex':
            case 'selectedFamilyStatus':
            case 'selectedDataOrigin':
            case 'selectedExperienceLevel':
            case 'selectedChildrenStatus':
            case 'language':
            case 'languageLevel':
            case 'provider':
            case 'wishlists':
                return item.id;

            case 'selectedDocument':
                return item.name;

            case 'FederalDistrictName':
            case 'selectedDistricts':
            case 'selectedEducationLevel':
            case 'selectedIndustry':
            case 'selectedWorkArea':
            case 'selectedManagementLevel':
            case 'selectedExperience':
            case 'selectedEmployeesNumber':
            case 'selectedGovernmentServiceKind':
            case 'selectedGroups':
                return item.code;
            default:
                return item;
        }
    };

    collectField(category, key, data, value, joinOperation, labelValue) {
        return {
            id: uuid(),
            category: category ? associativeArray[category] : categoryArray[key],
            field: associativeArray[key],
            label: labelValue,
            operation: data[key].operation.code,
            value: this.selectedSearchValue(value, key),
            joinOperation: joinOperation,
        };
    }

    collectAddField(category, key, operationCode, value, joinOperation) {
        return {
            id: uuid(),
            category: associativeArray[category],
            field: associativeArray[key],
            operation: operationCode,
            value: this.selectedSearchValue(value, key),
            joinOperation: joinOperation,
        };
    }

    getExtraFields = (key, data) => {
        if (['selectedRegions' /*'selectedLocalities'*/].indexOf(key) !== -1) {
            return data['selectedRegions'].extraFields;
        }

        return data[key].extraFields;
    };

    selectedEvaluations = (data) => {
        const { criteria: dataValue = [] } = data;
        if (dataValue.size === 0 || dataValue.length === 0) {
            return [];
        }
        const childs = dataValue.map((x) => {
            let children = [];

            for (let name in x) {
                const field = evaluationFields[name];
                if (field == null || x[name] == null || x[name] === '') {
                    continue;
                }
                children = children.concat(
                    this.collectAddField('evaluationInfo', name, field.operation, x[name], 'AND'),
                );
            }
            if (children.length > 0) {
                children = children.concat(
                    this.collectAddField(
                        'evaluationInfo',
                        'ResultStatus',
                        queryOperation.Equal,
                        'Relevant',
                        'AND',
                    ),
                );
            }

            const label = getEvaluationLabel(x);

            return {
                id: uuid(),
                label: label,
                joinOperation: this.state.groupCondition,
                childs: children,
                table: 'PersonsSearch',
                operation: queryOperation.Exists,
            };
        });

        const res = {
            joinOperation: 'AND',
            filterId: 'evaluation',
            childs,
        };

        return [res];
    };

    selectedGroup = (data, nameCondition, category) => {
        const condition = this.state.conditions[nameCondition];
        const childs = this.selectedSearchField(category, data, condition);
        const { groupCondition } = this.state;
        const res = {
            joinOperation: groupCondition,
            filterId: 'systemInfo',
            parent: true,
            childs,
        };

        return [].concat(childs.length > 0 ? res : []);
    };

    selectedSearchField = (category, data, condition) => {
        const { groupCondition: defaultCondition } = this.state;
        const groupCondition = condition || defaultCondition;
        let result = [];
        for (let key in data) {
            let dataValue = Array.isArray(data[key]) ? data[key] : data[key].value;

            if (!dataValue || dataValue.id === 'Any') continue;

            if (Array.isArray(dataValue)) {
                if (dataValue.length === 0) {
                    continue;
                }
                const extraFields = this.getExtraFields(key, data);
                result = result.concat({
                    joinOperation: groupCondition,
                    field: associativeArray[key],
                    join: key === 'wishlists' ? 'WishlistEntries' : undefined,
                    parent: true,
                    childs: dataValue
                        .map((mapItem) => {
                            const label = this.getLabel(category, key, data, mapItem);
                            return this.collectField(category, key, data, mapItem, 'OR', label);
                        })
                        .concat(
                            extraFields?.value === true && extraFields?.duplicate
                                ? dataValue.map((mapItem) => {
                                      return this.collectField(
                                          extraFields.duplicate,
                                          key,
                                          data,
                                          mapItem,
                                          'OR',
                                      );
                                  })
                                : [],
                        ),
                });
            } else {
                const value = altValues[key] ? altValues[key](dataValue) : dataValue;
                const label = this.getLabel(category, key, data, dataValue);
                const childs = this.collectField(category, key, data, value, 'AND', label);
                const addInfo = data[key].addInfo
                    ? data[key].addInfo.map((mapItem) => {
                          return this.collectAddField(
                              category,
                              mapItem.name,
                              mapItem.operation,
                              mapItem.value,
                              'AND',
                              key,
                          );
                      })
                    : [];

                result = result.concat({
                    joinOperation: groupCondition,
                    field: childs.field,
                    parent: true,
                    childs: [childs, ...addInfo],
                });
            }
        }

        return result;
    };

    getLabel(category = '', key, data, value) {
        let operationInfo = queryOperationExtProps[data[key].operation?.code]?.text;
        operationInfo = operationInfo ? ` ${operationInfo}` : '';
        let postfix = '';
        switch (key) {
            case 'selectedRegions':
                postfix = data['selectedRegions'].extraFields?.value === false ? ' (тек.)' : '';
                return `${
                    filtersName[category + key] ?? filtersName[key]
                }${postfix}${operationInfo}: ${value?.name}`;
            default:
                const [info] = data[key]?.addInfo || [];
                postfix = info && info.name === 'isCurrent' && info.value ? ' (тек.)' : '';
                return `${
                    filtersName[category + key] ?? filtersName[key]
                }${postfix}${operationInfo}: ${value?.name || value}`;
        }
    }

    performFetchAllowedGroups = () => {
        const { groupId } = this.props.currentUser;

        this.props.fetchAllowedGroups(groupId);
    };

    search = async (withIdsList = false) => {
        try {
            const { paging, sorting } = this.state;

            const personalCriteria = await this.selectedSearchField(
                'personalInfo',
                this.state.personalInfo,
            );
            const educationCriteria = await this.selectedSearchField(
                'educationInfo',
                this.state.educationInfo,
            );
            const workCriteria = await this.selectedSearchField('workInfo', this.state.workInfo);
            const governmentServiceCriteria = await this.selectedSearchField(
                'governmentServiceInfo',
                this.state.governmentServiceInfo,
            );
            const evaluationCriteria = await this.selectedEvaluations(this.state.evaluationInfo);

            const familyCriteria = await this.selectedSearchField(
                'familyInfo',
                this.state.familyInfo,
            );
            const languagesCriteria = await this.selectedSearchField(
                'languagesInfo',
                this.state.languagesInfo,
            );
            const personnelCriteria = await this.selectedSearchField(
                'personnelInfo',
                this.state.personnelInfo,
            );
            const systemInfoCriteria = await this.selectedGroup(this.state.systemInfo, 'system');
            const systemFilters = await this.selectedSearchField(
                'systemInfo',
                this.state.systemFilters,
            );

            let criteriaFields = personalCriteria.concat(
                educationCriteria,
                workCriteria,
                governmentServiceCriteria,
                familyCriteria,
                languagesCriteria,
                personnelCriteria,
                systemInfoCriteria,
                evaluationCriteria,
            );

            this.setState({ activeFilters: criteriaFields, loading: false });

            criteriaFields = criteriaFields.reduce((r, a) => {
                const field = r.find((x) => x.field === a.field);
                if (field) {
                    return r.map((x) =>
                        x.field === a.field ? { ...x, childs: [...x.childs, ...a.childs] } : x,
                    );
                }
                return r.concat(a);
            }, []);

            const searchFilter =
                systemFilters && systemFilters.length > 0
                    ? [
                          criteriaFields?.length > 0 && {
                              joinOperation: 'AND',
                              childs: criteriaFields,
                          },
                          { joinOperation: 'AND', childs: systemFilters },
                      ].filter((x) => x)
                    : criteriaFields;

            const outputSorting = [];

            for (let prop in sorting) {
                outputSorting.push({
                    category: 'Persons',
                    field: prop,
                    direction: sorting[prop] === 'asc' ? 'Asc' : 'Desc',
                });
            }

            let personIds = [];

            if (this.props.analyticsIndustryPersonIds && !this.state.clearFilters) {
                personIds = this.props.analyticsIndustryPersonIds;
            }

            if (this.props.mapRegionPersonIds && !this.state.clearFilters) {
                personIds = this.props.mapRegionPersonIds;
            }

            const criteria = {
                noLoader: true,
                filter: {
                    fields: searchFilter,
                    personIds: personIds,
                    fieldsRaw:
                        searchFilter && Array.isArray(searchFilter) && searchFilter.length > 0
                            ? JSON.stringify(searchFilter)
                            : null,
                },
                sorting: outputSorting,
                paging,
                idList: withIdsList,
                searchTerm: this.state.searchTerm,
            };

            if (!withIdsList) {
                await this.props.search(criteria);
            } else {
                this.setState({ selecting: true });
                const searchResult = await fullTextSearchSelect(criteria);
                return searchResult?.data?.meta?.ids || [];
            }
        } catch (e) {
            this.setState({ selecting: false });
            showErrorAlert('Ошибка поиска.');
        }
    };

    renderIntervalBlock = () => {
        const { dataDate } = this.state;

        const defaultIntervalBlock = (
            <div className="kr_filter_radio">
                <div className="kr_like-checkbox-items">
                    <input
                        type="radio"
                        name="filter-search-date"
                        id="filter-search-date1"
                        value="all"
                        onChange={this.onChangeDataDate}
                        checked={dataDate === 'all'}
                    />
                    <label htmlFor="filter-search-date1" className="kr_left-border">
                        Все
                    </label>
                    <input
                        type="radio"
                        name="filter-search-date"
                        id="filter-search-date2"
                        value="last-week"
                        onChange={this.onChangeDataDate}
                        checked={dataDate === 'last-week'}
                    />
                    <label htmlFor="filter-search-date2" className="kr_right-border">
                        Последняя неделя
                    </label>
                    <input
                        type="radio"
                        name="filter-search-date"
                        id="filter-search-date3"
                        value="last-month"
                        onChange={this.onChangeDataDate}
                        checked={dataDate === 'last-month'}
                    />
                    <label htmlFor="filter-search-date3" className="kr_right-border">
                        Последний месяц
                    </label>
                </div>
            </div>
        );

        const createdStart = this.getCreatedStart();
        const createdEnd = this.getCreatedEnd();

        const textIntervalBlock = (
            <div className="kr_filter_interval">
                {createdStart} - {createdEnd}
            </div>
        );

        return this.showIntervalBlock() ? textIntervalBlock : defaultIntervalBlock;
    };

    renderFullTextSearch = () => {
        return (
            <Input
                name="Name"
                type="search"
                aria-label="Поиск"
                placeholder="Поиск"
                className="FullTextSearch"
                value={this.state.searchTerm}
                onChange={this.handleChangeSearchTerm}
            />
        );
    };

    handleChangeSearchTerm = (e) => {
        this.setState({ searchTerm: e.target.value });
    };

    getCreatedStart() {
        if (this.state.systemFilters.createdStart && this.state.systemFilters.createdStart.value) {
            return moment(this.state.systemFilters.createdStart.value).format('MMMM YYYY');
        }

        return null;
    }

    getCreatedEnd() {
        if (this.state.systemFilters.createdEnd && this.state.systemFilters.createdEnd.value) {
            return moment(this.state.systemFilters.createdEnd.value)
                .add(1, 'days')
                .format('MMMM YYYY');
        }

        return null;
    }

    handlePaginate = (pageNum) => {
        const paging = { ...this.state.paging, pageNum };

        this.setState({ paging }, () => {
            this.scrollTop();
            this.updateURL();
        });
    };

    clearFilters = () => {
        this.setState({ ...initialState, clearFilters: true, personIds: [] }, () => {
            this.updateURL();
            this.setEvaluations();
            this.setEvaluationProviders();
            this.setEvaluationSummaryScales();
            this.scrollTop();
        });
    };

    onChangeDataDate = (e) => {
        const dataDate = e.target.value;
        let value;
        switch (dataDate) {
            case 'all':
                value = null;
                break;
            case 'last-week':
                value = moment().add(-7, 'day').toDate();
                break;
            case 'last-month':
                value = moment().add(-1, 'months').toDate();
                break;
            default:
                value = null;
                break;
        }
        this.setState(
            (state) => ({
                dataDate,
                systemFilters: {
                    createdStart: {
                        ...state.systemFilters.createdStart,
                        value,
                    },
                },
            }),
            () => {
                this.handlePaginate(1);
            },
        );
    };

    onSort = (newSorting) => {
        this.setState({ sorting: newSorting }, () => {
            this.handlePaginate(1);
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.setState(
            (state) => ({
                ...state,
                isCloseSearchFilter: true,
                paging: {
                    pageNum: 1,
                    pageSize: state.paging.pageSize,
                },
            }),
            () => this.updateURL(),
        );
    };

    conditionMenu = (condition, scrollTo) => {
        if (condition) {
            this.setState({ isCloseSearchFilter: false });

            window.scrollTo({
                top: scrollTo,
                behavior: 'smooth',
            });
        }
    };

    onClearFilters = (e) => {
        e.preventDefault();
        this.clearFilters();
    };

    // TODO: поиск по профилю скрыт
    /* handleProfileSelect = item => {
        let value = item.value;
        switch (value) {
            case 1:
                this.setState(
                    {
                        profileSearchValue: item,
                        ...mayorState,
                    },
                    () => {
                        this.updateURL();
                    },
                );

                break;
            case 2:
                this.setState(
                    {
                        profileSearchValue: item,
                        ...innovationState,
                    },
                    () => {
                        this.updateURL();
                    },
                );
                break;
            case 3:
                this.setState(
                    {
                        profileSearchValue: item,
                        ...viceMinister,
                    },
                    () => {
                        this.updateURL();
                    },
                );
                break;
            default:
                break;
        }
    }; */

    acceptEvaluationDialog = () => {
        if (!this.isValidField('testId') && !this.isValidField('evaluationSummaryScaleId')) {
            this.props.showWarningAlert(
                'Для корректной работы фильтра необходимо указать тест или сводную шкалу',
            );
            return;
        }

        this.setState((state) => {
            const selectedTest = state.evaluationTests.find(
                (item) => item.id === state.evaluationDialog.testId,
            );
            const criteriaName =
                selectedTest && selectedTest.criteria[state.evaluationDialog.criteriaId]
                    ? selectedTest.criteria[state.evaluationDialog.criteriaId].title
                    : '';

            const summaryScaleName = state.evaluationSummaryScales.find(
                (item) => item.id === state.evaluationDialog.evaluationSummaryScaleId,
            );

            let criteria = [...state.evaluationInfo.criteria];

            if (state.evaluationDialog.id) {
                criteria = criteria.filter((x) => x.id !== state.evaluationDialog.id);
            }

            const list = criteria.concat({
                ...state.evaluationDialog,
                testName: selectedTest && selectedTest.title,
                criteriaName: criteriaName,
                summaryScaleName: summaryScaleName && summaryScaleName.title,
                isNew: false,
            });

            return {
                evaluationInfo: {
                    criteria: list,
                },
                evaluationDialogIsVisible: false,
            };
        });
    };

    openEvaluationDialog = (e) => {
        e.preventDefault();
        this.setState({
            evaluationDialogIsVisible: true,
            evaluationDialog: {
                id: uuid(),
                provider: null,
                testId: null,
                criteriaId: null,
                isNew: true,
            },
        });
    };

    closeEvaluationDialog = () => {
        this.setState({
            evaluationDialogIsVisible: false,
            evaluationDialog: {},
        });
    };

    evaluationOnRemove = (item) => {
        this.setState((state) => {
            return {
                evaluationInfo: {
                    criteria: state.evaluationInfo.criteria.filter((x) => x.id !== item),
                },
            };
        });
    };

    evaluationOnSelect = (itemId) => {
        let criteriaItem = this.state.evaluationInfo.criteria.find((x) => x.id === itemId);

        if (criteriaItem) {
            this.setState({
                evaluationDialogIsVisible: true,
                evaluationDialog: { ...criteriaItem },
            });
        }
    };

    onHandleDialogProviderChange = (values) => {
        this.setState({
            evaluationDialog: {
                ...this.state.evaluationDialog,
                ...values,
                provider: this.state.evaluationProviders.find((x) => x.code === values.provider),
            },
        });
    };

    onHandleDialogChange = (values) => {
        this.setState({
            evaluationDialog: {
                ...this.state.evaluationDialog,
                ...values,
            },
        });
    };

    isValidField = (key) => !!this.state.evaluationDialog[key];

    isValidEvaluationFilter = (selectedCriteria, selectedSummaryScale) => {
        const {
            provider,
            testId,
            evaluationSummaryScaleId,
            additionalCriterionId,
        } = this.state.evaluationDialog;
        return (
            (!additionalCriterionId || (!!additionalCriterionId && !!provider && !!testId)) &&
            this.isValidEvaluationFilterCriteria(selectedCriteria) &&
            (!evaluationSummaryScaleId ||
                (this.isValidEvaluationFilterSummaryScale(selectedSummaryScale) &&
                    !!evaluationSummaryScaleId))
        );
    };

    isRangeValid = (valueFrom, valueTo, scaleLowerBound, scaleUpperBound) => {
        let result = true;
        if (valueFrom && valueTo) {
            result =
                valueFrom >= scaleLowerBound &&
                valueFrom <= scaleUpperBound &&
                valueTo >= scaleLowerBound &&
                valueTo <= scaleUpperBound &&
                valueFrom <= valueTo;
        } else if (valueFrom && !valueTo) {
            result = valueFrom >= scaleLowerBound && valueFrom <= scaleUpperBound;
        } else if (!valueFrom && valueTo) {
            result = valueTo >= scaleLowerBound && valueTo <= scaleUpperBound;
        }
        return result;
    };

    isValidEvaluationFilterCriteria = (selectedCriteria) => {
        if (!selectedCriteria) {
            return true;
        }
        let res = true;
        const { scaleLowerBound, scaleUpperBound } = selectedCriteria;
        const { valueFrom, valueTo } = this.state.evaluationDialog;
        if (selectedCriteria && selectedCriteria.valueKind === 'Scale') {
            res = this.isRangeValid(valueFrom, valueTo, scaleLowerBound, scaleUpperBound);
        }
        return res;
    };

    isValidEvaluationFilterSummaryScale = (selectedSummaryScale) => {
        if (!selectedSummaryScale) {
            return true;
        }
        let res = true;
        const { scaleLowerBound, scaleUpperBound } = selectedSummaryScale;
        const {
            evaluationSummaryScaleValueFrom,
            evaluationSummaryScaleValueTo,
        } = this.state.evaluationDialog;

        res = this.isRangeValid(
            evaluationSummaryScaleValueFrom,
            evaluationSummaryScaleValueTo,
            scaleLowerBound,
            scaleUpperBound,
        );

        return res;
    };

    changeGroupCondition = (e) => {
        const groupCondition = e.currentTarget.checked ? 'OR' : 'AND';

        this.setState({ groupCondition });
    };

    isBlockFilled = (blockStateKey) => {
        return (
            searchBlocks[blockStateKey].filter((x) => this.state[blockStateKey][x].value).length !==
            0
        );
    };

    scrollTop = () => {
        const resultsBlock = document.getElementById('SearchFilter');
        resultsBlock.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    };

    changePageAmount = (pageSize) => {
        this.setState({ paging: { pageNum: 1, pageSize } }, this.updateURL);
        this.scrollTop();
    };

    getPersonalBlockCatalogs = memoize(this.getCatalogs(personalInfoBlockCatalogs));
    getEducationBlockCatalogs = memoize(this.getCatalogs(educationBlockCatalogs));
    getWorkBlockCatalogs = memoize(this.getCatalogs(workInfoBlockCatalogs));
    getGovernmentServiceBlockCatalogs = memoize(
        this.getCatalogs(governmentServiceInfoBlockCatalogs),
    );
    getLanguagesCatalogs = memoize(this.getCatalogs(languagesBlockCatalogs));
    getFamilyStatusCatalogs = memoize(this.getCatalogs(familyStatusBlockCatalogs));
    getPersonnelCatalogs = memoize(this.getCatalogs(personnelInfoBlockCatalogs));

    render() {
        const { searchResult } = this.props;
        const { selection, selecting, sorting, paging } = this.state;
        const selectedSummaryScale = this.state.evaluationSummaryScales.find(
            (item) => item.id === this.state.evaluationDialog.evaluationSummaryScaleId,
        );

        const testsCatalog = this.state.evaluationTests
            .filter(
                (option) =>
                    option.testProviderId ===
                        ((this.state.evaluationDialog.provider &&
                            this.state.evaluationDialog.provider.id) ||
                            null) &&
                    (!selectedSummaryScale ||
                        selectedSummaryScale.testsCriteria.some(
                            (testCriteria) => testCriteria.evaluationTestId === option.id,
                        )),
            )
            .map((item) => ({ code: item.id, name: item.title }));

        const selectedTest = this.state.evaluationTests.find(
            (item) => item.id === this.state.evaluationDialog.testId,
        );

        const criteriaCatalog =
            selectedTest &&
            Object.values(selectedTest.criteria)
                .filter(
                    (criteria) =>
                        !selectedSummaryScale ||
                        selectedSummaryScale.testsCriteria.some(
                            (testCriteria) =>
                                testCriteria.evaluationTestCriterionId === criteria.id,
                        ),
                )
                .map((item) => ({ code: item.id, name: item.title }));

        const selectedCriteria =
            selectedTest &&
            Object.values(selectedTest.criteria).find(
                (option) => option.id === this.state.evaluationDialog.criteriaId,
            );

        const evaluationValue = this.state.evaluationDialog.value;
        const selectEvaluationValue = { code: evaluationValue, name: evaluationValue };

        const groupCatalog =
            this.props.allowedGroups &&
            this.props.allowedGroups.map((item) => ({
                id: item.id,
                code: item.id,
                name: item.title,
            }));

        return (
            <div>
                <PersonReportHeader
                    selection={selection}
                    selecting={selecting}
                    totalCount={searchResult.foundCount}
                    onSelectAll={this.handleSelectAll}
                    onClearAll={this.handleClearAll}
                />

                <Page id="search-page" className="search" w1790>
                    <Form onSubmit={this.onSubmit}>
                        <SearchTopMenu
                            isCloseSearchFilter={this.state.isCloseSearchFilter}
                            condition={this.conditionMenu}
                            groupCondition={this.state.groupCondition}
                            handleGroupConditionChange={this.changeGroupCondition}
                            fullTextSearch={this.renderFullTextSearch}
                        >
                            <>
                                <PopularBlockFields
                                    handleStateChange={this.handleStateChange}
                                    handleTypeOfAction={this.handleTypeOfAction}
                                    {...this.state.personalInfo}
                                    {...this.getPersonalBlockCatalogs(this.props.catalogs)}
                                    {...this.state.workInfo}
                                    {...this.getWorkBlockCatalogs(this.props.catalogs)}
                                    regionLocalities={this.state.regionLocalities}
                                />
                            </>
                            <>
                                <PersonalInfoBlock
                                    handleStateChange={this.handleStateChange}
                                    handleTypeOfAction={this.handleTypeOfAction}
                                    getLocalities={this.getLocalities}
                                    setLocality={this.setLocality}
                                    {...this.state.personalInfo}
                                    {...this.getPersonalBlockCatalogs(this.props.catalogs)}
                                    regionLocalities={this.state.regionLocalities}
                                    isFilled={this.isBlockFilled('personalInfo')}
                                />
                                <EvaluationBlock
                                    handleStateChange={this.handleStateChange.bind(
                                        null,
                                        'evaluationInfo',
                                    )}
                                    handleTypeOfAction={this.handleTypeOfAction.bind(
                                        null,
                                        'evaluationInfo',
                                    )}
                                    {...this.state.evaluationInfo}
                                    onClick={this.openEvaluationDialog}
                                    onSelect={this.evaluationOnSelect}
                                    onRemove={this.evaluationOnRemove}
                                    isFilled={this.state.evaluationInfo.criteria.length > 0}
                                />
                                <EducationBlock
                                    handleStateChange={this.handleStateChange}
                                    handleTypeOfAction={this.handleTypeOfAction}
                                    {...this.state.educationInfo}
                                    {...this.getEducationBlockCatalogs(this.props.catalogs)}
                                    regionLocalities={this.state.regionLocalities}
                                    isFilled={this.isBlockFilled('educationInfo')}
                                />
                                <WorkBlock
                                    handleStateChange={this.handleStateChange}
                                    handleTypeOfAction={this.handleTypeOfAction}
                                    {...this.state.workInfo}
                                    {...this.getWorkBlockCatalogs(this.props.catalogs)}
                                    regionLocalities={this.state.regionLocalities}
                                    isFilled={this.isBlockFilled('workInfo')}
                                />
                                <GovernmentServiceBlock
                                    handleStateChange={this.handleStateChange}
                                    handleTypeOfAction={this.handleTypeOfAction}
                                    {...this.state.governmentServiceInfo}
                                    {...this.getGovernmentServiceBlockCatalogs(this.props.catalogs)}
                                    regionLocalities={this.state.regionLocalities}
                                    isFilled={this.isBlockFilled('governmentServiceInfo')}
                                />
                                <LanguagesBlock
                                    handleStateChange={this.handleStateChange}
                                    handleTypeOfAction={this.handleTypeOfAction}
                                    {...this.state.languagesInfo}
                                    {...this.getLanguagesCatalogs(this.props.catalogs)}
                                    regionLocalities={this.state.regionLocalities}
                                    isFilled={this.isBlockFilled('languagesInfo')}
                                />
                                <FamilyStatusBlock
                                    handleStateChange={this.handleStateChange}
                                    handleTypeOfAction={this.handleTypeOfAction}
                                    {...this.state.familyInfo}
                                    {...this.getFamilyStatusCatalogs(this.props.catalogs)}
                                    regionLocalities={this.state.regionLocalities}
                                    isFilled={this.isBlockFilled('familyInfo')}
                                />
                                <PersonnelInfoBlock
                                    handleStateChange={this.handleStateChange}
                                    handleTypeOfAction={this.handleTypeOfAction}
                                    {...this.state.personnelInfo}
                                    {...this.getPersonnelCatalogs(this.props.catalogs)}
                                    regionLocalities={this.state.regionLocalities}
                                    isFilled={this.isBlockFilled('personnelInfo')}
                                />
                                <SystemInfoBlock
                                    handleStateChange={this.handleStateChange}
                                    handleTypeOfAction={this.handleTypeOfAction}
                                    handleConditionChange={this.handleConditionChange}
                                    conditions={this.state.conditions}
                                    condition="system"
                                    {...this.state.systemInfo}
                                    {...this.getPersonnelCatalogs(this.props.catalogs)}
                                    allowedGroups={{ data: groupCatalog, loadComplete: true }}
                                    wishlistCatalog={{
                                        data: this.state.wishlistCatalog,
                                        loadComplete: true,
                                    }}
                                    regionLocalities={this.state.regionLocalities}
                                    isFilled={this.isBlockFilled('systemInfo')}
                                />
                            </>
                            <div className="Search__Filter">
                                <Button type="submit" onClick={this.onSubmit}>
                                    Найти
                                </Button>
                                <Button onClick={this.onClearFilters} color="danger">
                                    Очистить фильтр
                                </Button>
                            </div>
                        </SearchTopMenu>
                    </Form>
                    <ActiveFilters activefilters={this.state.activeFilters} />
                    <div className="kr_filter" ref={this.cardRefs.SearchFilter} id="SearchFilter">
                        <div className="kr_filter_left">
                            <PageTitle className="Search__Title">Сортировка</PageTitle>
                        </div>
                        <div className="kr_filter_left">
                            <Sorting
                                sorting={sorting}
                                onSort={this.onSort}
                                options={[
                                    {
                                        htmlId: 'search-page-order-by-age',
                                        title: 'Возраст',
                                        keys: ['Age'],
                                        value: 'Age',
                                        defaultDirection: 'asc',
                                    },
                                    {
                                        htmlId: 'search-page-order-by-name',
                                        title: 'ФИО',
                                        keys: ['FullName'],
                                        value: 'FullName',
                                        defaultDirection: 'asc',
                                    },
                                ]}
                            />
                            {this.renderIntervalBlock()}
                        </div>
                        <div className="kr_filter_right">
                            <div className="kr_filter_results">
                                Результаты ({searchResult.foundCount})
                                {/** TODO: пример вывода большого числа */}
                                {/* Результаты (88.8 тыс.) */}
                            </div>
                        </div>
                    </div>

                    <PersonList
                        persons={searchResult.data}
                        pageNum={paging.pageNum}
                        onPaginate={this.handlePaginate}
                        pageCount={searchResult.pageCount}
                        totalCount={searchResult.foundCount}
                        selection={selection}
                        onChangeSelection={this.handleSelectionChange}
                        onPersonChange={this.handlePersonChange}
                        changePageAmount={this.changePageAmount}
                        pageSize={paging.pageSize}
                    />

                    <ModalDialog
                        modalHeader={
                            (this.state.evaluationDialog &&
                            this.state.evaluationDialog.isNew === false
                                ? 'Изменить'
                                : 'Добавить') + ' фильтр результата оценки'
                        }
                        onClick={this.acceptEvaluationDialog}
                        onCloseModal={this.closeEvaluationDialog}
                        modalOpen={this.state.evaluationDialogIsVisible}
                        btnOktext={
                            this.state.evaluationDialog &&
                            this.state.evaluationDialog.isNew === false
                                ? 'Изменить'
                                : 'Добавить'
                        }
                        btnCanceltext="Отмена"
                        size="lg"
                        isValidForm={this.isValidEvaluationFilter(
                            selectedCriteria,
                            selectedSummaryScale,
                        )}
                    >
                        <Field>
                            <Label>Компетенции и способности</Label>
                            <Select
                                inputId="evaluationSummaryScale"
                                value={selectedSummaryScale}
                                onChange={(value) =>
                                    this.onHandleDialogProviderChange({
                                        evaluationSummaryScaleId: value ? value.id : null,
                                        evaluationSummaryScaleValueFrom: null,
                                        evaluationSummaryScaleValueTo: null,
                                        additionalCriterionId: null,
                                        provider: null,
                                        testId: null,
                                        criteriaId: null,
                                        value: '',
                                        valueFrom: '',
                                        valueTo: '',
                                    })
                                }
                                options={this.state.evaluationSummaryScales}
                                catalog
                                isClearable
                            />
                        </Field>
                        {selectedSummaryScale ? (
                            <Field
                                required
                                filled={this.isValidEvaluationFilterSummaryScale(
                                    selectedSummaryScale,
                                )}
                            >
                                <Label>
                                    Значение{' '}
                                    {`(от ${selectedSummaryScale.scaleLowerBound} до ${selectedSummaryScale.scaleUpperBound})`}
                                </Label>
                                <InputGroup>
                                    <InputNumeric
                                        placeholder="от"
                                        onChange={(e) =>
                                            this.onHandleDialogChange({
                                                evaluationSummaryScaleValueFrom: e,
                                            })
                                        }
                                        value={
                                            this.state.evaluationDialog
                                                .evaluationSummaryScaleValueFrom
                                        }
                                    />
                                    <InputNumeric
                                        placeholder="до"
                                        onChange={(e) =>
                                            this.onHandleDialogChange({
                                                evaluationSummaryScaleValueTo: e,
                                            })
                                        }
                                        value={
                                            this.state.evaluationDialog
                                                .evaluationSummaryScaleValueTo
                                        }
                                    />
                                </InputGroup>
                            </Field>
                        ) : null}
                        <Field>
                            <Label>Выберите дополнительный критерий</Label>
                            <Select
                                inputId="additionalCriterion"
                                value={this.state.evaluationDialog.additionalCriterionId}
                                onChange={(value) =>
                                    this.onHandleDialogProviderChange({
                                        additionalCriterionId: value,
                                        provider: null,
                                        testId: null,
                                        criteriaId: null,
                                        value: '',
                                        valueFrom: '',
                                        valueTo: '',
                                    })
                                }
                                options={[{ id: 1, name: 'Оценочная процедура', value: 1 }]}
                                catalog
                                isClearable
                            />
                        </Field>
                        {this.state.evaluationDialog.additionalCriterionId && (
                            <>
                                <Field
                                    required
                                    filled={
                                        !!(
                                            this.state.evaluationDialog.provider &&
                                            this.state.evaluationDialog.provider.id
                                        )
                                    }
                                >
                                    <Label>Поставщик</Label>
                                    <Select
                                        inputId="evaluationProvider"
                                        value={this.state.evaluationProviders.find(
                                            (option) =>
                                                option.id ===
                                                ((this.state.evaluationDialog.provider &&
                                                    this.state.evaluationDialog.provider.id) ||
                                                    null),
                                        )}
                                        onChange={(value) =>
                                            this.onHandleDialogProviderChange({
                                                provider: value ? value.code : null,
                                                testId: null,
                                                criteriaId: null,
                                                value: '',
                                                valueFrom: '',
                                                valueTo: '',
                                            })
                                        }
                                        options={this.state.evaluationProviders}
                                        catalog
                                        isClearable
                                    />
                                </Field>
                                <Field required filled={this.state.evaluationDialog.testId}>
                                    <Label>Тестирование</Label>
                                    <Select
                                        inputId="evaluationTest"
                                        value={
                                            testsCatalog.find(
                                                (option) =>
                                                    option.code ===
                                                    this.state.evaluationDialog.testId,
                                            ) || null
                                        }
                                        onChange={(value) =>
                                            this.onHandleDialogChange({
                                                testId: value ? value.code : null,
                                                criteriaId: null,
                                                value: '',
                                                valueFrom: '',
                                                valueTo: '',
                                            })
                                        }
                                        options={testsCatalog}
                                        catalog
                                        isClearable
                                    />
                                </Field>
                                <Field required>
                                    <Label>Шкала</Label>
                                    <Select
                                        inputId="evaluationCriteria"
                                        value={
                                            (criteriaCatalog &&
                                                criteriaCatalog.find(
                                                    (option) =>
                                                        option.code ===
                                                        this.state.evaluationDialog.criteriaId,
                                                )) ||
                                            null
                                        }
                                        onChange={(value) =>
                                            this.onHandleDialogChange({
                                                criteriaId: value ? value.code : null,
                                                value: '',
                                                valueFrom: '',
                                                valueTo: '',
                                            })
                                        }
                                        options={criteriaCatalog}
                                        catalog
                                        isClearable
                                    />
                                </Field>
                                {selectedCriteria && selectedCriteria.valueKind === 'Scale' ? (
                                    <Field
                                        required
                                        filled={this.isValidEvaluationFilterCriteria(
                                            selectedCriteria,
                                        )}
                                    >
                                        <Label>
                                            Значение{' '}
                                            {`(от ${selectedCriteria.scaleLowerBound} до ${selectedCriteria.scaleUpperBound})`}
                                        </Label>
                                        <InputGroup>
                                            <InputNumeric
                                                placeholder="от"
                                                onChange={(e) =>
                                                    this.onHandleDialogChange({ valueFrom: e })
                                                }
                                                value={this.state.evaluationDialog.valueFrom}
                                            />
                                            <InputNumeric
                                                placeholder="до"
                                                onChange={(e) =>
                                                    this.onHandleDialogChange({ valueTo: e })
                                                }
                                                value={this.state.evaluationDialog.valueTo}
                                            />
                                        </InputGroup>
                                    </Field>
                                ) : null}
                                {selectedCriteria && selectedCriteria.valueKind !== 'Scale' ? (
                                    <Field
                                        required
                                        filled={this.isValidEvaluationFilterCriteria(
                                            selectedCriteria,
                                        )}
                                    >
                                        <Label>
                                            Значение{' '}
                                            {`(${selectedCriteria.gradesInAscendingOrder} )`}
                                        </Label>
                                        {
                                            <Select
                                                options={selectedCriteria.gradesInAscendingOrder
                                                    .split(',')
                                                    .map((x) => ({ code: x, name: x }))}
                                                value={selectEvaluationValue}
                                                onChange={(e) =>
                                                    this.onHandleDialogChange({ value: e.code })
                                                }
                                                catalog
                                            />
                                        }
                                    </Field>
                                ) : null}
                            </>
                        )}
                    </ModalDialog>
                </Page>
                <Loader show={this.props.searchResult.loading || this.state.loading} overlay />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const searchResult = selectSearchProps(state.search);
    const { groups } = state;

    let analyticsData = {
        analyticsPersonIds: [],
        analycitsIndustryId: '',
        mapPersonIds: [],
        mapRegionId: '',
    };

    if (state.router.location.state) {
        const {
            analyticsPersonIds,
            analycitsIndustryId,
            mapPersonIds,
            mapRegionId,
        } = state.router.location.state;

        analyticsData.analyticsPersonIds = analyticsPersonIds;
        analyticsData.analycitsIndustryId = analycitsIndustryId;
        analyticsData.mapPersonIds = mapPersonIds;
        analyticsData.mapRegionId = mapRegionId;
    }

    const filteredCatalogs = allSearchCatalogs.filter((x) => x !== REGION_LOCALITIES);
    const catalogs = filteredCatalogs.reduce((obj, name) => {
        obj[name] = state.catalogs[name];
        return obj;
    }, {});
    return {
        searchResult,
        catalogs,
        queryParams: queryStringParse(state.router.location.search),
        analyticsIndustryPersonIds: analyticsData.analyticsPersonIds,
        analycitsIndustryId: analyticsData.analycitsIndustryId,
        mapRegionPersonIds: analyticsData.mapPersonIds,
        mapRegionId: analyticsData.mapRegionId,
        currentUser: state.auth.user,
        allowedGroupsLoadComplete: groups.loadCompleteAllowed,
        allowedGroups: groups.dataAllowed,
        needUpdateAllowedGroups: groups.needUpdateAllowed,
        needAddFavorites: state.wishlists.needAddFavorites,
    };
}

const mapDispatchToProps = (dispatch) => ({
    search: bindActionCreators(search, dispatch),
    updatePersonData: bindActionCreators(updatePersonData, dispatch),
    fetchCatalog: bindActionCreators(fetchCatalog, dispatch),
    fetchCatalogs: bindActionCreators(fetchCatalogs, dispatch),
    showErrorAlert: bindActionCreators(showErrorAlert, dispatch),
    showWarningAlert: bindActionCreators(showWarningAlert, dispatch),
    push: bindActionCreators(push, dispatch),
    fetchAllowedGroups: bindActionCreators(fetchAllowedGroups, dispatch),
    setNeedAddFavorites: bindActionCreators(setNeedAddFavorites, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
