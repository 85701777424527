import React from 'react';
import './ProfileButton.scss';
import classnames from 'classnames';

const ProfileButton = ({ onClick, children = 'push me', className = '', type, disabled, noPaddings = false, dataTest }) => {
    const baseClass = 'ProfileButton';

    const finalClass = classnames(baseClass, className, {
        [`${baseClass}--${type}`]: type,
        [`${baseClass}--no-paddings`]: noPaddings,
    });

    return (
        <button className={finalClass} disabled={disabled} onClick={!disabled && onClick ? onClick : undefined} data-test={dataTest}>
            {children}
        </button>
    );
};

export default ProfileButton;
