import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    fetchAttributes,
    attributesSelector,
    deleteAttribute,
    changeCriteria,
} from 'ducks/Attributes';
import { showWarningAlert } from 'ducks/Alert';
import { attributeEdit, attributeNew } from 'routes';
import { push } from 'connected-react-router';
import Page from 'components/common/Page';
import ListTemplate from 'components/uikit/ListTemplate';
import DataGrid from 'components/common/DataGrid';
import Button from 'components/uikit/Button';
import { isITAdmin } from 'rightsController';
import './Attributes.scss';

const initialState = {
    filter: {},
    sorting: { name: 'Asc' },
    paging: { pageNum: 1, pageSize: 10 },
};

const renderEditButton = (id, editAttribute) => {
    return (
        <Button size="xxs" onClick={() => editAttribute(id)}>
            Редактировать
        </Button>
    );
};

const renderDeleteButton = (attribute, canDelete, deleteAttribute) => {
    return (
        <Button color="danger" size="xxs" onClick={() => deleteAttribute(attribute)}>
            Удалить
        </Button>
    );
};

const buildColumns = ({ currentUser, deleteAttribute, editAttribute }) => [
    {
        Header: '',
        width: 20,
    },
    {
        Header: 'Название',
        accessor: 'name',
        resizable: false,
    },
    {
        Header: 'Тип',
        id: 'type',
        accessor: 'typeView',
        resizable: false,
        Cell: ({ original }) => <div className={'Attributes__TypeColumn'}>{original.typeView}</div>,
    },
    {
        Header: 'Действие',
        sortable: false,
        resizable: false,
        maxWidth: 230,
        show: isITAdmin(currentUser),
        Cell: ({ original }) => {
            return (
                <div className="Attributes__ActionsButtons">
                    {renderEditButton(original.id, editAttribute)}
                    {renderDeleteButton(original, original.canDelete, deleteAttribute)}
                </div>
            );
        },
    },
];

export class Attributes extends Component {
    state = initialState;

    componentDidMount() {
        if (this.props.loadComplete) {
            return;
        }
        this.performFetchAttributes();
    }

    componentDidUpdate(prevProps) {
        const { paging } = this.state;
        const { data } = this.props;

        if (this.props.needUpdate) {
            this.performFetchAttributes();
        }

        if (data === prevProps.data) {
            return;
        }

        if (data && data.meta.pageCount < paging.pageNum && data.meta.pageCount !== 0) {
            this.setState({ paging: { ...paging, pageNum: data.meta.pageCount } }, () => {
                this.performFetchAttributes();
            });
        }
    }

    changePageAmount = (pageSize) => {
        this.setState({ paging: { pageNum: 1, pageSize } }, () =>
            this.performFetchAttributes(),
        );
    };

    performFetchAttributes = () => {
        const { paging, sorting } = this.state;

        const criteria = { paging, sorting };

        this.props.fetchAttributes(criteria);
    };

    onPageChange = pageIndex => {
        const { paging } = this.state;
        this.setState(
            {
                paging: { ...paging, pageNum: pageIndex + 1 },
            },
            () => this.performFetchAttributes(),
        );
    };

    newAttribute = () => {
        this.props.push(attributeNew.buildUrl({}));
    };

    deleteAttribute = attribute => {
        this.props.deleteAttribute(attribute);
    };

    editAttribute = id => {
        this.props.push(attributeEdit.buildUrl({ id }));
    };

    onSortedChange = sorted => {
        this.props.changeCriteria('sorted', sorted);
        const criteria = {
            ...this.state,
            sorting: {
                [sorted[0].id]: sorted[0].desc ? 'Desc' : 'Asc',
            },
        };

        this.setState(criteria, () => this.performFetchAttributes());
    };

    headBtn = () => {
        return isITAdmin(this.props.currentUser)
            ? {
                  onClick: this.newAttribute,
                  value: 'Создать дополнительное поле',
                  addLink: true,
                  size: 'xs',
              }
            : null;
    };
    render() {
        if (!this.props.loadComplete) {
            return null;
        }

        const { pageCount } = this.props.data.meta;
        const { paging } = this.state;

        const columns = buildColumns({
            currentUser: this.props.currentUser,
            deleteAttribute: this.deleteAttribute,
            editAttribute: this.editAttribute,
        });

        return (
            <Page>
                <ListTemplate title={' '} headBtn={this.headBtn()}>
                    <div className="Attributes">
                        <DataGrid
                            data={this.props.data.payload}
                            foundCount={this.props.data.meta.foundCount}
                            columns={columns}
                            loading={!this.props.loadComplete}
                            showPageSizeOptions={false}
                            sorted={this.props.dataGrid.sorted}
                            onSortedChange={this.onSortedChange}
                            showPagination
                            pages={pageCount}
                            page={paging.pageNum}
                            onPageChange={this.onPageChange}
                            manual
                            pageSize={paging.pageSize}
                            changePageAmount={this.changePageAmount}
                        />
                    </div>
                </ListTemplate>
            </Page>
        );
    }
}

const action = {
    showWarningAlert,
    fetchAttributes,
    push,
    deleteAttribute,
    changeCriteria,
};

export default connect(
    state => {
        return attributesSelector(state);
    },
    action,
)(Attributes);
