import React, { useState } from 'react';
import classnames from 'classnames';
import './Sorter.scss';

const Sorter = props => {
    const { size, className, value, onClick } = props;

    const sorterStates = [undefined, 'asc', 'desc'];
    const getNextSorterState = () => sorterStates[sorterStates.indexOf(state) + 1];

    const [state, setState] = useState(sorterStates[0]);

    const handleSorter = () => {
        const nextState = getNextSorterState();
        setState(nextState || sorterStates[0]);
        onClick && onClick(nextState || null);
    }

    const getIconClassName = () => `LKSorter__Icon--${state || 'unsorted'}`;

    const finalClassName = classnames('LKSorter', className, {
        [`LKSorter--${size}`]: size,
    });

    return (
        <div className={finalClassName} onClick={handleSorter}>
            {value}<span className={`LKSorter__Icon ${getIconClassName()}`}></span>
        </div>
    );
};

export default Sorter;