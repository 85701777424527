import { Route, Switch } from 'react-router';
import React, { Component } from 'react';
import EditPerson from './Edit';
import Person from './Person';
import { personNew, person, forbidden, personProfileBlock, educationEventRoute } from 'routes';
import NotFound from 'pages/common/NotFound';
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import { ROLE_IT_ADMIN, ROLE_MODERATOR, ROLE_OPERATOR } from 'constants.js';
import Forbidden from 'components/Forbidden';
import EducationEvent from 'components/Person/EducationEvents/EducationEvent/EducationEvent';


const allowedRoles = [ROLE_IT_ADMIN, ROLE_MODERATOR, ROLE_OPERATOR];

class PersonRoutes extends Component {
    renderEditPerson = ({ match }) => {
        const { params } = match;
        return <EditPerson id={+params.id} />;
    }

    renderPerson = ({ match }) => {
        const { params } = match;
        return <Person id={+params.id} personProfileBlock={params.profileBlock} />;
    }
    
    renderEducationEvent = (props) => {
        const { params } = props.match;
        return <EducationEvent {...props} id={+params.id} eventId={params.eventId} regime={params.regime} />
    };

    renderNotFound = () => {
        return <NotFound />;
    }

    renderForbidden = () => {
        return <Forbidden />;
    }

    render() {
        return (
            <Switch>
                <ProtectedRoute exact path={personNew.url} component={EditPerson} allowedRoles={allowedRoles} />
                <Route exact path={personProfileBlock.url} component={this.renderPerson} />
                <Route exact path={person.url} component={this.renderPerson} />
                <Route exact path={educationEventRoute.url} component={this.renderEducationEvent} />
                <Route path={forbidden.buildUrl()} component={this.renderForbidden} />
                <Route component={this.renderNotFound} />
            </Switch>
        );
    }
}

export default PersonRoutes;