import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showErrorAlert } from 'ducks/Alert';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { push } from 'connected-react-router';
import Page from 'components/common/Page';
import { stringify } from 'query-string';
import { massEmailLists } from 'routes';
import MenuSwitcher from 'components/common/MenuSwitcher';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CampaignInfo from './CampaignInfo';
import CampaignRecitients from './CampaignRecitients';
import { getRecipientsByCampaignId } from 'api';
import './Email.scss';

const campaign = 'campaign';
const recipients = 'recipients';

class Campaign extends Component {
    state = {
        menuItems: [
            { id: campaign, title: 'Рассылка' },
            { id: recipients, title: 'Адресаты' },
        ],
        section: campaign,
        recipients: [],
        recipientsPaging: { pageNum: 1, pageSize: 10 },
        recipientsMeta: {},
    };

    urlParams = {
        section: 'history',
        page: 1,
    };

    goBack = () => {
        this.props.push({
            pathname: massEmailLists.url,
            search: stringify(this.urlParams),
        });
    };

    addMenuInfo = () => {
        const { recipientsMeta } = this.state;
        return [
            {
                menuId: recipients,
                info: !!recipientsMeta.foundCount ? ` (${recipientsMeta.foundCount})` : '',
            },
        ];
    };

    getActiveTab = () => {
        const { section, recipients, recipientsMeta, recipientsPaging } = this.state;
        const {
            match: {
                params: { id },
            },
        } = this.props;

        return section === campaign ? (
            <CampaignInfo campaignId={id} />
        ) : (
            <CampaignRecitients
                getRecipients={this.getRecipients}
                recipients={recipients}
                recipientsMeta={recipientsMeta}
                recipientsPaging={recipientsPaging}
            />
        );
    };

    setVisibleSection = (section) => {
        this.setState({ section });
    };

    getRecipients = async (criteria = { paging: { ...this.state.recipientsPaging } }) => {
        const {
            showPageLoader,
            hidePageLoader,
            showErrorAlert,
            match: {
                params: { id },
            },
        } = this.props;

        try {
            showPageLoader();
            const res = await getRecipientsByCampaignId(id, criteria);
            const recipients = res.data;

            this.setState({
                recipients: recipients.payload.map((x) => x.person),
                recipientsMeta: { ...recipients.meta },
                recipientsPaging: { ...criteria.paging },
            });
        } catch (e) {
            showErrorAlert('Ошибка загрузки адресатов.');
        } finally {
            hidePageLoader();
        }
    };

    componentDidMount() {
        this.getRecipients();
    }

    render() {
        const { menuItems, section } = this.state;
        return (
            <Page className="MassEmailCampaign">
                <div className="MassEmailCampaignCard">
                    <div className="MassEmailCampaignCard__TopActions">
                        <div className="MassEmailCampaignCard__GoBackButton" onClick={this.goBack}>
                            <FontAwesomeIcon size="lg" icon="arrow-left" /> К списку рассылок
                        </div>
                    </div>
                    <MenuSwitcher
                        menu={menuItems}
                        selectedMenuId={section}
                        onMenuClick={this.setVisibleSection}
                        tabs
                        activeTab={this.getActiveTab()}
                        addMenuInfo={this.addMenuInfo()}
                    />
                </div>
            </Page>
        );
    }
}

const actions = { push, showPageLoader, hidePageLoader, showErrorAlert };

export default connect(null, actions)(Campaign);
