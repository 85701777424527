import React from 'react';
import { connect } from 'react-redux';
import { setSearchFilter } from '../utils';
import { PROFESSION_FILTER_ID } from '../constants';
import Field from 'components/Lk/Uikit/Field';
import StyledCheckBox from 'components/Lk/Uikit/StyledCheckBox';
import { queryOperation } from 'components/Search/QueryOperation';
import FilterTemplate from './FilterTemplate';
import { FIELDS_TYPE } from '../SearchFilters/constants';

const Fields = [
    {
        type: FIELDS_TYPE.input,
        label: 'Место работы',
        column: 'CompanyName',
        query: queryOperation.Contain,
        value: 'workPlace',
        table: 'PersonJobs',
        isDefault: true,
    },
    {
        type: FIELDS_TYPE.input,
        label: 'Должность',
        column: 'Position',
        query: queryOperation.Contain,
        value: 'Position',
        table: 'PersonJobs',
        isDefault: true,
    },
    {
        type: FIELDS_TYPE.multiSelect,
        label: 'Область деятельности',
        value: 'WorkAreaId',
        column: 'WorkAreaId',
        catalog: 'workAreas',
        table: 'PersonJobs',
        isDefault: true,
        query: queryOperation.InArray,
    },
    {
        type: FIELDS_TYPE.multiSelect,
        label: 'Отрасль',
        value: 'IndustryId',
        column: 'IndustryId',
        catalog: 'industries',
        table: 'PersonJobs',
        isDefault: true,
        query: queryOperation.InArray,
    },
    {
        type: FIELDS_TYPE.multiSelect,
        label: 'Опыт управления',
        value: 'ManagementExperienceId',
        column: 'ManagementExperienceId',
        catalog: 'managementExperiences',
        table: 'PersonJobs',
        query: queryOperation.InArray,
    },
    {
        label: 'Год трудоустройства',
        table: 'PersonJobs',
        value: 'startquitdate',
        child: [
            {
                type: FIELDS_TYPE.year,
                label: 'Год трудоустройства с',
                query: queryOperation.GreaterOrEqual,
                value: 'HireDate',
                column: 'HireDate',
                table: 'PersonJobs',
            },
            {
                type: FIELDS_TYPE.year,
                label: 'Год трудоустройства по',
                query: queryOperation.LessOrEqual,
                value: 'QuitDate',
                column: 'QuitDate',
                table: 'PersonJobs',
                lastDay: true,
            },
        ],
    },
    {
        type: FIELDS_TYPE.input,
        label: 'Профессиональные достижения',
        value: 'ProfessionalAchievements',
        column: 'ProfessionalAchievements',
        table: 'PersonJobs',
        query: queryOperation.Contain,
    },
];
class ProfessionFilter extends React.Component {
    state = { groupCondition: null, withCurrentSection: false };

    setStateCurrentSection = withCurrentSection => {
        this.setState({ withCurrentSection }, () => {
            this.setFiltersState(null);
        });
    };

    componentDidMount() {
        const [activeFilters] = this.props.filtersState.filter(
            x => x.filterId === PROFESSION_FILTER_ID,
        );

        const { childs: filters = [] } = activeFilters || {};

        this.setState({
            groupCondition: this.props.getGroupCondition(PROFESSION_FILTER_ID, true),
            withCurrentSection:
                filters.length === 0 || filters.map(x => x.field).indexOf('IsCurrent') > -1,
        });
    }

    setFiltersState = (currentFilter) => {
        const { filtersState, activeFilter, setFiltersState } = this.props;
        const [activeFilters] = filtersState.filter(x => x.filterId === PROFESSION_FILTER_ID);
        const { childs: filters = [] } = activeFilters || {};
        const { withCurrentSection } = this.state;
        const [filterFields = {}] = filters.filter(x => x && x.field !== 'IsCurrent');

        let newFilters = [
            {
                ...filterFields,
                joinOperation: 'AND',
                filterId: PROFESSION_FILTER_ID,
                childs: (filterFields.childs || [])
                    .concat(currentFilter)
                    .filter(
                        x =>
                            x &&
                            (!currentFilter || !activeFilter || activeFilter.group !== x.group),
                    )
                    .map(x => ({
                        ...x,
                        label: x.label
                            .replace(' (тек.)', '')
                            .concat(withCurrentSection ? ' (тек.)' : ''),
                    })),
                parent: true,
            },
        ].filter(x => x && x.childs.length > 0);

        newFilters = newFilters.concat(
            newFilters.length > 0 && withCurrentSection
                ? setSearchFilter(
                      'PersonJobs',
                      'IsCurrent',
                      queryOperation.Equal,
                      true,
                      null,
                      'AND',
                      PROFESSION_FILTER_ID,
                      null,
                      false,
                      true,
                  )
                : [],
        );

        const cleanChilds = newFilters[0]?.childs.reduce((acc, x) => {
            return acc.filter(f => f.label === x.label).length === 0 ? acc.concat(x) : acc; 
        }, []);
        
        if (cleanChilds) { newFilters[0].childs = cleanChilds; }
        
        setFiltersState(newFilters, PROFESSION_FILTER_ID, true);
    };

    setGroupCondition = groupCondition => this.setState({ groupCondition });
    render() {
        return (
            <FilterTemplate
                {...this.props}
                closeMenuOnSelect={false}
                setFiltersState={this.setFiltersState}
                fields={Fields}
                setCondition={this.setGroupCondition}
                extraFields={!this.props.activeFilter && 
                    <div className="LKSearchCardVisibleFIlterLine">
                        <Field>
                            <StyledCheckBox
                                onChange={this.setStateCurrentSection}
                                checked={this.state.withCurrentSection}
                                title="Текущее место работы"
                            />
                        </Field>
                    </div>
                }
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        workAreas: state.catalogs.workAreas.data,
        mainFilter: state.filterSearch.mainFilter,
        activeFilter: state.filterSearch.activeFilter,
    };
};

export default connect(mapStateToProps)(ProfessionFilter);
