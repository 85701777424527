import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Page from 'components/common/Page';
import PersonReportHeader from 'components/common/PersonReportHeader';
import { PersonListView } from 'components/PersonList';
import Sorting from 'components/Search/Sorting';
import { showErrorAlert } from 'ducks/Alert';
import { hidePageLoader, showPageLoader } from 'ducks/PageLoader';
import { selectSearchProps } from 'ducks/Search';
import {
    getFullWishlistDuck as getFullWishlist,
    updateWishlistDeprecated as updateWishlist,
} from 'ducks/Wishlists';
import { removePersonFromDefaultWishlist } from 'api';
import './Favorites.scss';
import { getError, serviceResultCode } from 'serviceErrors';

const filterSelection = (selection, newPersonIds) => {
    return {
        ...selection,
        personIds: selection.personIds.filter((selectionId) =>
            newPersonIds.some((id) => selectionId === id),
        ),
    };
};

const getWishlistError = (code, payload) => {
    switch (code) {
        case serviceResultCode.NotFound:
            return payload ? `${payload}` : 'Не найден';
        case serviceResultCode.Forbidden:
            return payload ? `${payload}` : 'Доступ запрещен';
        case serviceResultCode.PersonAlreadyExists:
            return payload ? `${payload}` : 'Пользователь уже добавлен';
        default:
            return 'Произошла непредвиденная ошибка';
    }
};

class Favorites extends Component {
    state = {
        selection: {
            personIds: [],
        },
        criteria: {
            includeIds: true,
            paging: {
                pageNum: 1,
                pageSize: 10,
            },
            sorting: {
                FullName: 'asc',
            },
        },
        favorites: null,
    };

    componentDidMount = async () => {
        await this.loadFavorites();
    };

    loadFavorites = async () => {
        try {
            const { getFullWishlist, showErrorAlert } = this.props;
            let criteria = { ...this.state.criteria };

            this.props.showPageLoader();

            const result = await getFavorites(getFullWishlist, criteria);
            if (result.ok) {
                const favorites = result.ok.payload;
                this.setState((state) => ({
                    favorites,
                    selection: filterSelection(state.selection, favorites.ids),
                }));
            } else {
                showErrorAlert(result.error.message);
            }
        } finally {
            this.props.hidePageLoader();
        }
    };

    handleFavoriteChange = (personId, callback) => async (isFavorite) => {
        if (isFavorite) {
            return;
        }

        const { favorites } = this.state;

        try {
            await removePersonFromDefaultWishlist(personId);

            const isSinglePersonOnPage = favorites.data.length === 1;
            const pageNum = this.state.criteria.paging.pageNum;
            if (isSinglePersonOnPage && pageNum > 1) {
                const newPaging = { ...this.state.criteria.paging, pageNum: pageNum - 1 };
                this.setState((state) => ({ criteria: { ...state.criteria, paging: newPaging } }));
            }

            await this.loadFavorites();

            if (callback) {
                callback(isFavorite);
            }
        } catch (error) {
            const reqError = getError(error, getWishlistError);
            this.props.showErrorAlert(reqError.message);

            if (callback) {
                callback(!isFavorite);
            }
        }
    };

    handlePaginate = (pageNum) => {
        const newPaging = { ...this.state.criteria.paging, pageNum };
        this.setState(
            (state) => ({ criteria: { ...state.criteria, paging: newPaging } }),
            this.loadFavorites,
        );
        this.scrollTop();
    };

    handleSort = (newSorting) => {
        const { pageSize } = this.state.criteria.paging;

        this.setState(
            (state) => ({
                criteria: {
                    ...state.criteria,
                    sorting: newSorting,
                    paging: {
                        pageNum: 1,
                        pageSize: pageSize,
                    },
                },
            }),
            this.loadFavorites,
        );
    };

    handleSelectionChange = (selection) => {
        this.setState({ selection });
    };

    handleSelectAll = () => {
        if (!this.state.favorites) {
            return;
        }

        const { favorites } = this.state;
        if (!favorites || !favorites.ids || favorites.ids.length === 0) {
            return;
        }

        this.setState({ selection: { personIds: favorites.ids } });
    };

    handleClearAll = () => {
        this.setState({ selection: { personIds: [] } });
    };

    scrollTop = () => {
        const layout = document.querySelector('.Layout');
        const resultsBlock = document.getElementById('SearchResults');
        layout.scrollTo(0, resultsBlock?.offsetTop - 120);
    };

    changePageAmount = (pageSize) => {
        this.setState(
            (state) => ({ criteria: { ...state.criteria, paging: { pageNum: 1, pageSize } } }),
            this.loadFavorites,
        );
        this.scrollTop();
    };

    render = () => {
        const { criteria, favorites, selection } = this.state;
        const { sorting } = criteria;

        const doShowContent = favorites !== null;

        return (
            <div>
                <PersonReportHeader
                    selection={selection}
                    totalCount={doShowContent && favorites.foundCount}
                    onSelectAll={this.handleSelectAll}
                    onClearAll={this.handleClearAll}
                />
                <Page className="Favorites" w1790>
                    <div className="Favorites__Header">
                        <div className="Favorites__Sorting">
                            <Sorting
                                sorting={sorting}
                                onSort={this.handleSort}
                                options={[
                                    {
                                        htmlId: 'favorites-page-order-by-age',
                                        title: 'Возраст',
                                        keys: ['Age'],
                                        value: 'Age',
                                        defaultDirection: 'asc',
                                    },
                                    {
                                        htmlId: 'favorites-page-order-by-name',
                                        title: 'ФИО',
                                        keys: ['FullName'],
                                        value: 'FullName',
                                        defaultDirection: 'asc',
                                    },
                                ]}
                            />
                        </div>
                        {doShowContent && (
                            <div className="Favorites__Total">Всего ({favorites.foundCount})</div>
                        )}
                    </div>
                    {doShowContent && this.renderCards(favorites)}
                </Page>
            </div>
        );
    };

    renderCards = (favorites) => {
        const { criteria, selection } = this.state;

        return (
            <PersonListView
                persons={favorites.data}
                pageNum={criteria.paging.pageNum}
                pageSize={criteria.paging.pageSize}
                onPaginate={this.handlePaginate}
                pageCount={favorites.pageCount}
                totalCount={favorites.foundCount}
                favoriteIds={favorites.ids}
                onChangeFavorite={this.handleFavoriteChange}
                selection={selection}
                onChangeSelection={this.handleSelectionChange}
                changePageAmount={this.changePageAmount}
            />
        );
    };
}

const getFavorites = async (getFullWishlist, criteria = {}) => {
    const wishlistCriteria = {
        ...criteria,
        filter: {
            ...criteria.filter,
        },
    };
    const searchResult = await getFullWishlist(wishlistCriteria);
    if (searchResult.error) {
        return { error: searchResult.error };
    }

    const searchApiResult = selectSearchProps(searchResult.ok.payload);

    return {
        ok: {
            payload: {
                ...searchApiResult,
            },
        },
    };
};

const mapDispatchToProps = (dispatch) => ({
    showErrorAlert: bindActionCreators(showErrorAlert, dispatch),
    getFullWishlist,
    updateWishlist,
    showPageLoader: bindActionCreators(showPageLoader, dispatch),
    hidePageLoader: bindActionCreators(hidePageLoader, dispatch),
});

export default connect(null, mapDispatchToProps)(Favorites);
