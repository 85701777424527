import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getTemplatesInfoByGroupId, makeTemplatesDefault } from 'api';
import { serviceResultCode, getError } from 'serviceErrors';
import { TEMPLATES_TYPES_ENUM } from 'constants.js';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import Page from "components/common/Page";
import Select from "components/uikit/Select";
import Button from "components/uikit/Button";
import ListTemplate from 'components/uikit/ListTemplate';
import { Row, Col } from 'react-flexbox-grid';
import './TemplatesTypes.scss'


const TemplatesTypes = (props) => {

    const templatesTypes = TEMPLATES_TYPES_ENUM;
    
    const { user } = props;
    
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [loadComplete, setLoadComplete] = useState(false);
    
    const [selectedTemplates, setSelectedTemplates] = useState({});
    const [templatesToUpdateIds, setTemplatesToUpdateIds] = useState('');

    useEffect(() => {
        let mounted = true;
      
        const fetchData = async () => {
            try {
                showPageLoader();
                setLoadComplete(false);
                
                const templates = await getTemplatesInfoByGroupId(user.groupId);
                
                if (mounted && templates && templates.status === 200 && templates.data) {
                    const emailTemplates = templates.data.map(x => ({ 
                        value: x.id, label: x.name, type: x.type, id: x.id, isUsedByDefault: x.isUsedByDefault }));
                    setEmailTemplates(emailTemplates);
                    setLoadComplete(true);
                }
                
            } catch(error) {
                const reqError = getError(error, getTemplatesError);
                showErrorAlert(reqError.message);
            } finally {
                hidePageLoader();
            }
        };
        fetchData();

        return () => { mounted = false };
    }, [user]);
    
    useEffect(() => {
        let mounted = true;
        
        const fetchData = async () => {
            try {
                showPageLoader();
                
                if (mounted && Array.isArray(templatesToUpdateIds) && templatesToUpdateIds.length > 0) {
                    const updateResult = await makeTemplatesDefault(templatesToUpdateIds);
                    updateResult && updateResult.status === 200 && showSuccessAlert('Успешно сохранено');
                }

            } catch(error) {
                const reqError = getError(error, getTemplatesError);
                showErrorAlert(reqError.message);
            } finally {
                hidePageLoader();
            }
        };
        fetchData();

        return () => { mounted = false };
    }, [templatesToUpdateIds]);
    
    const getTemplatesError = (code, payload) => {
        switch (code) {
            case serviceResultCode.NotFound:
                return payload ? `${payload}` : 'Не найден';
            case serviceResultCode.Forbidden:
                return payload ? `${payload}` : 'Доступ запрещен';
            case serviceResultCode.EntityAlreadyExists:
                return payload ? `${payload}` : 'Сущность уже используется';
            case serviceResultCode.EmailSystemTemplateMakeDefaultError:
                return payload;
            default:
                return 'Произошла непредвиденная ошибка';
        }
    };
    
    const handleSaveBtnOnClick = () => {
        const templatesIds = Object.values(selectedTemplates).map(x => x.id);
        setTemplatesToUpdateIds(templatesIds);
    };
    
    const renderSaveButton = () => {
        return (
            <div className="TemplatesTypeContainer__SaveBtn">
                <Button size="sm" onClick={handleSaveBtnOnClick} disabled={Object.entries(selectedTemplates).length === 0}
                    >Сохранить
                </Button>
            </div>
        );
    };

    const handleTemplateSelectChange = () => template => {
        setSelectedTemplates({...selectedTemplates, [template.type]: template});
    };
    
    const renderTemplatesType = (type) => {
        return (
            <Row className="TemplatesTypeContainer__Item" key={type.value}>
                <Col xs={6} className="TemplatesTypeLabels">{type.label}</Col>
                <Col xs={6}>
                    <Select
                        options={emailTemplates.filter(x => x.type === type.value)}
                        value={
                            selectedTemplates[type.value] ||
                            emailTemplates.find(x => x.type === type.value && x.isUsedByDefault) ||
                            null
                        }
                        onChange={handleTemplateSelectChange(type)}
                        placeholder="Выберите шаблон"
                    />
                </Col>
            </Row>
        )
    };
    
    const renderContainer = () => {
        return (
            <Row>
                <Col xs={12} className="TemplatesTypeContainer">
                    <Row className="TemplatesTypeContainer__Heading">
                        <Col xs={6}>Типы уведомления</Col>
                        <Col xs={6}>Шаблоны</Col>
                    </Row>
                    { templatesTypes.map(renderTemplatesType) }
                    <Row end="xs">
                        { renderSaveButton() }
                    </Row>
                </Col>
            </Row>
        );
    };
    
    return (
        <Page>
            { loadComplete &&
            <ListTemplate title={'Выбор шаблона письма'} >
                { templatesTypes.length > 0
                    ? renderContainer()
                    : <div className="NoData">Шаблоны писем не найдены</div> }
            </ListTemplate> }
        </Page>
    );
};

const mapStateToProps = state => {
    return {
        user: state.auth.user
    }
};

const actions = { showPageLoader, hidePageLoader, showErrorAlert, showSuccessAlert };
export default connect(mapStateToProps, actions)(TemplatesTypes);