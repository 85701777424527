import React from 'react';
import { getChartParams, getDescription, renderLineHint } from '../chartsHelper';
import { EVALUATION_TYPE_CAREER, EVALUATIONTEST_CRITERIONRESULT_STATUS, EVALUATIONTEST_STATUS } from 'constants.js';
import LineChart from './LineChart';
import Popover from 'components/Lk/Uikit/Popover';
import Icons from 'components/Lk/Uikit/Icons/Icons';
import classnames from 'classnames';
import { isDevice } from 'utils';

const List = (props) => {
    const { data, getCompetenceDescription, withHeader, withDescr, fixedWidth } = props;
    const chartParams = getChartParams(data.kind);
    const description = getDescription(data, chartParams, true);
    
    const isTestActive = data.resultType === EVALUATIONTEST_STATUS.Relevant;

    const getProgressLineData = (
        criteriaHeaders,
        criteriaData,
        getCompetenceDescription,
        color,
    ) => {
        const description = getCompetenceDescription(
            criteriaData.evaluationTestResultId,
            criteriaData.evaluationTestCriterionId,
        );

        const isCriteriaActive = criteriaData.status === EVALUATIONTEST_CRITERIONRESULT_STATUS.Relevant;

        const value = Math.round(criteriaData.normalizedValue * 100);

        const title = Object.entries(criteriaHeaders).find(
            (x) => x[0] === criteriaData.evaluationTestCriterionId,
        )[1].title;

        const lineHint = renderLineHint(
            description,
            `lineHint${criteriaData.evaluationTestCriterionId}`,
        );

        return (
            <Popover
                tooltip={lineHint}
                key={`popover${criteriaData.evaluationTestCriterionId}`}
                autoPlacement={false}
            >
                <div 
                    className={classnames('LKCompetence', {'LKCompetence--notActive': !isTestActive || !isCriteriaActive})} 
                    key={criteriaData.evaluationTestCriterionId}
                >
                    <div
                        key={criteriaData.evaluationTestCriterionId}
                        className="LKCompetence__Line LKCompetenceLineChart"
                    >
                        <LineChart
                            title={title}
                            progress={value}
                            color={color}
                            alreadyHavePopover={!!lineHint}
                        />
                    </div>
                </div>
            </Popover>
        );
    };

    // хард код для карьерной мотивации
    if (data.id === EVALUATION_TYPE_CAREER) {
        const getPosition = (evaluationTestCriterionId) => {
            if (!evaluationTestCriterionId) {
                return 0;
            }
            return data.criteria?.[evaluationTestCriterionId]?.position || 0;
        };

        const criterias = data.result.criteria
            .sort((a, b) => getPosition(a.evaluationTestCriterionId) - getPosition(b.evaluationTestCriterionId));
        
        return (
            <div key={data.result.id} className="LKEvaluate-Block--full">
                {withHeader && !isDevice.SM() && (
                    <div className="LKEvaluate-Block__Header">
                        <div>{data.title}</div>
                        {description && (
                            <Popover tooltip={description} autoPlacement={false}>
                                <div>
                                    <Icons name="help-alt" fill={'#007AFF'} />
                                </div>
                            </Popover>
                        )}
                    </div>
                )}
                {isDevice.SM() && withDescr && <div className="LKEvaluate-Block__Descr">{description}</div>}
                <div className="LKEvaluate-Block__Wrapper">
                    <div>
                        {criterias.filter(x => getPosition(x.evaluationTestCriterionId) <= 5).length > 0 && (
                            <div className="LKCompetenceHeader">
                                <label>Внутренняя мотивация</label>
                            </div>)}
                        {criterias
                            .map((x) =>
                                getPosition(x.evaluationTestCriterionId) <= 5 &&
                                getProgressLineData(
                                    data.criteria,
                                    x,
                                    getCompetenceDescription,
                                    '#63C9B6',
                                ),
                        )}
                    </div>
                    <div>
                        {criterias.filter(x => getPosition(x.evaluationTestCriterionId) > 5).length > 0 && (
                        <div className="LKCompetenceHeader">
                            <label>Внешняя мотивация</label>
                        </div>)}
                        {criterias
                            .map(x =>
                                getPosition(x.evaluationTestCriterionId) > 5 &&
                                getProgressLineData(
                                    data.criteria,
                                    x,
                                    getCompetenceDescription,
                                    '#A6B2C4',
                                ),
                        )}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div
            key={data.result.id}
            className={classnames('LKEvaluate-Block', {
                'LKEvaluate-Block--fixed-width': fixedWidth,
            })}
        >
            {withHeader && !isDevice.SM() && (
                <div className="LKEvaluate-Block__Header">
                    <div>{data.title}</div>
                    {description && (
                        <Popover tooltip={description} autoPlacement={false}>
                            <Icons name="help-alt" fill={'#007AFF'} />
                        </Popover>
                    )}
                </div>
            )}
            {isDevice.SM() && withDescr && <div className="LKEvaluate-Block__Descr">{description}</div>}
            {data.result.criteria.map((x) =>
                getProgressLineData(data.criteria, x, getCompetenceDescription),
            )}
        </div>
    );
};

export default List;
