import React from 'react';
import { teamEditMenuItems, ADD_PERSON_MENU_ID } from './constants';
import './TeamEditMenu.scss';

const TeamEditMenu = props => {

    const { handleMenuClick, teamId, hasPersons } = props;
    const addItem = teamEditMenuItems.find(x => x.id === ADD_PERSON_MENU_ID);

    return (
        <div className='LKTeamEditMenu'>
            { hasPersons 
            ? 
            teamEditMenuItems.map(x => (
                <div key={x.action} 
                    className={`LKTeamEditMenuIcon LKTeamEditMenuIcon__${x.action}`} 
                    onClick={() => handleMenuClick({action: x.action, teamId: teamId})} 
                />
            ))
            :
                <div
                    className={`LKTeamEditMenuIcon LKTeamEditMenuIcon__${addItem.action}`} 
                    onClick={() => handleMenuClick({action: addItem.action, teamId: teamId})} 
               />
        }
        </div>
    );
};

export default TeamEditMenu;