import React from 'react';
import ToggleOpen from 'components/hoc/ToggleOpen';
import { isForeignDocument } from 'ducks/Person';
import SearchMenuJSON from './SearchMenuJSON';
import RenderDataCard from './renderDataCard';

const PersonalInfoBlock = React.memo((props) => {
    const methods = {
        handleDistrictChange: (selectedDistricts) => {
            props.handleStateChange('personalInfo', selectedDistricts);
        },

        handleLocalityChange: (searchTerm) => {
            props.getLocalities(searchTerm[1]);
        }, 

        selectLocality: (locality) => {
            props.setLocality(locality[1]);
        },

        getDocumentNumberFormat: (documentFormat) => {
            return documentFormat && documentFormat.numberFormat;
        },
    };

    return (
        <RenderDataCard
            obj={SearchMenuJSON.PersonalInfoBlock}
            {...props}
            {...methods}
            isForeignDocument={isForeignDocument}
        />
    );
});

export default ToggleOpen(PersonalInfoBlock);
