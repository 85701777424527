import React from 'react';
import PropTypes from 'prop-types';
import Icons from "components/Lk/Uikit/Icons/Icons";

const Service = (props) => {
    const { onClick, title, text, icon } = props;

    return (
        <div
            className="LKService"
            onClick={onClick}
            >
            <div className="LKService__Icon">
                {icon 
                ? 
                   typeof(icon) === "function"
                   ? icon()
                   : <Icons name={icon} />

                :
                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="56" height="56" rx="8" fill="#0A84FF"/>
                </svg>
            }
                
            </div>
            <div className="LKService__Body">
                <h5 className="LKService__Title">
                    {title}
                </h5>
                <div className="LKService__Content">
                    {text}
                </div>                
            </div>
        </div>
    );
};

Service.propTypes = {
    onClick: PropTypes.func,
};

export default Service;