import React from 'react';
import "./DataCardProperty.scss";

export default function DataCardProperty(props) {
    const { title, children } = props;

    return (
        <div className="DataCardProperty">
            {title && <span>{title}</span>}
            {" "}
            {children}
        </div>
    );
}

export function DataCardPropertyBadge(props) {
    const { list } = props;

    return (
        <div className="DataCardProperty__Badge">
            {list && Array.isArray(list) && list.map(item => {
                return <span key={item}>{item}</span>;
            })}
        </div>
    );
}

export function DataCardTextProperty(props) {
    const { title, children } = props;

    return (
        <div className="DataCardProperty">
            <header>{title}</header>
            <section>
                <pre>
                    {children}
                </pre>
            </section>
        </div>
    );
}

export function DataCardListProperty(props) {
    const { title, children } = props;

    return (
        <div className="DataCardProperty">
            <header>{title}</header>
            <ul>
                {children.map((item, index) => <li key={index}>{item}</li>)}
            </ul>
        </div>
    );
}
