import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputText from 'components/uikit/InputText';
import Field from 'components/uikit/Field';
import PreviewContent from './PreviewContent';
import '../../TextEditor.scss';

class PreviewEditor extends Component {
    renderInputText = (isRequired) => {
        return (
            <Field required={isRequired} filled={this.props.value.trim() && isRequired}>
                <InputText
                    id={this.props.id}
                    forwardRef={this.props.textInput}
                    maxLength={this.props.maxLength}
                    rows={this.props.rows}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    onPaste={this.props.handlePaste}
                    selectionchange={this.props.selectionchange}
                />
            </Field>
        );
    };

    render() {
        const { backRender, value, disabled = false, isRequired } = this.props;
        return this.props.preview ? (
            <PreviewContent value={value} backRender={backRender} disabled={disabled} />
        ) : (
            this.renderInputText(isRequired)
        );
    }
}

PreviewEditor.propTypes = {
    maxLength: PropTypes.number,
    preview: PropTypes.bool,
    rows: PropTypes.number,
    value: PropTypes.string,
    onChange: PropTypes.func,
    handlePaste: PropTypes.func,
    isRequired: PropTypes.bool,
};

PreviewEditor.defaultProps = {
    rows: 10,
    preview: false,
    isRequired: false,
};

export default PreviewEditor;
