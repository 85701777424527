import React, { useState } from 'react';
import { connect } from 'react-redux';
import StickyFooter from 'components/Lk/Common/StickyFooter';
import InfoField from 'components/Lk/Uikit/InfoField';
import ProfileButton from 'components/Lk/Uikit/Buttons/Button/ProfileButton';
import { showErrorAlert } from 'ducks/Alert';
import { getMonths, isSameOrAfter, endOf } from 'utils';
import Loader from 'components/common/Loader';

const MIN_TITLE_LENGTH = 10;

const EditShtortPlan = props => {
    const { onSave, onCancel, plan, showErrorAlert } = props;
    const [record, setRecord] = useState(plan);
    const [loading, setLoading] = useState(false)

    const onChange = name => value => setRecord({ ...record, [name]: value });

    const titleLength = (record.title && record.title.length) || 0;

    const onSuccess = async () => {
        if (titleLength < MIN_TITLE_LENGTH) {
            showErrorAlert(`Наименование не должно быть меньше ${MIN_TITLE_LENGTH} символов.`);
            return;
        }

        if (!isSameOrAfter(record.end, new Date())) {
            showErrorAlert(`Срок выполнения не может быть меньше текущей даты`);
            return;
        }
        setLoading(true)
        await onSave(record);
        setLoading(false)
    };

    const onChangeDate = date => {
        if (endOf(date) && endOf(date)?.isSame(endOf(record.end)) === false) {
            setRecord({ ...record, end: date && endOf(date) })
        }
    };

    const monthFormat = value =>
        value && typeof value.getMonth == 'function' && getMonths()[value.getMonth()].label;

    const datePickerProps = {
        dateFormat: 'LLLL yyyy',
        mobileFormat: 'MMMM YYYY',
        type: 'date',
        onChange: onChangeDate,
        dataTest: "EditShortPlan_DueDate--datepicker",
        dateConfig: {
            month: {
                format: monthFormat,
                step: 1,
            },
            year: {
                format: 'YYYY',
                step: 1,
            },
        },
        showMonthYearPicker: true,
        locale: 'ru',
    };

    return (
        <div className="EditModal">
            {loading && <Loader absolute />}
            <div className="EditModal__Content">
                <div className="EditModal__Fields">
                    <InfoField
                        noIcon
                        title="Цель развития"
                        isEdit
                        onChange={onChange('title')}
                        value={record.title}
                        required
                        invalid={titleLength < MIN_TITLE_LENGTH}
                        footer={
                            !!titleLength &&
                            titleLength < MIN_TITLE_LENGTH &&
                            `Минимальное кол-во символов ${MIN_TITLE_LENGTH}. Осталось ${MIN_TITLE_LENGTH -
                            titleLength}`
                        }
                        dataTest="EditShortPlan_EditInput--input"
                    />
                    <InfoField
                        noIcon
                        title="Срок выполнения"
                        inputProps={datePickerProps}
                        required
                        isEdit
                        value={record.end}
                    />
                </div>
            </div>
            <StickyFooter>
                <div className="EditModal__Buttons">
                    <ProfileButton onClick={onCancel} type="cancel">Отменить</ProfileButton>
                    <ProfileButton onClick={onSuccess} disabled={loading}>Сохранить</ProfileButton>
                </div>
            </StickyFooter>
        </div>
    );
};

const actions = { showErrorAlert };

export default connect(null, actions)(EditShtortPlan);
