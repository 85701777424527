import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { selectPageAmountOptions } from 'constants.js';
import '../Pagination/Pagination.scss';

const customSelectStyles = {
    container: (base) => ({
        ...base,
        width: 100,
    }),
};

const defaultButton = (props) => <button {...props}>{props.children}</button>;

const getVisiblePages = (page, total) => {
    if (total < 7) {
        return filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
        if (page % 5 >= 0 && page > 4 && page + 2 < total) {
            return [1, page - 1, page, page + 1, total];
        } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
            return [1, total - 3, total - 2, total - 1, total];
        } else {
            return [1, 2, 3, 4, 5, total];
        }
    }
};

const filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter((page) => page <= totalPages);
};

export default class Pagination extends React.Component {
    state = {
        visiblePages: getVisiblePages(null, this.props.pages),
        pagesCount: 0,
    };

    static propTypes = {
        pages: PropTypes.number.isRequired,
        page: PropTypes.number.isRequired,
        pageSize: PropTypes.number,
        PageButtonComponent: PropTypes.any,
        onPageChange: PropTypes.func.isRequired,
        previousText: PropTypes.string,
        nextText: PropTypes.string,
        changePageAmount: PropTypes.func,
    };

    static getDerivedStateFromProps(props, state) {
        if (props.pages !== state.pagesCount || props.pages === 0) {
            return {
                visiblePages: getVisiblePages(props.page, props.pages),
                pagesCount: props.pages,
            };
        } else {
            return null;
        }
    }

    componentDidUpdate(prevProps) {
        const { page, pages } = this.props;
        if (prevProps.page !== page) {
            const visiblePages = getVisiblePages(page, pages);

            this.setState({
                visiblePages: filterPages(visiblePages, pages),
                pagesCount: pages,
            });
        }
    }

    renderVisiblePages() {
        const { PageButtonComponent = defaultButton, dataTest } = this.props;
        const { visiblePages } = this.state;
        const activePage = this.props.page;

        return visiblePages.map((page, index, array) => {
            const hasInactiveDividerBefore = array[index - 1] + 2 <= page;
            return (
                <React.Fragment key={'wrapper' + index}>
                    {hasInactiveDividerBefore ? (
                        <li>
                            <PageButtonComponent key={'page_' + page + 'D'} disabled>
                                ...
                            </PageButtonComponent>
                        </li>
                    ) : (
                        ''
                    )}

                    <li
                        className={activePage === page ? 'Pagination__Button_Active' : ''}
                        key={'page_' + page}
                    >
                        <PageButtonComponent
                            onClick={(e) => this.changePage(page, e)}
                            data-test={`${dataTest}--pageNum--${page}`}
                        >
                            {page}
                        </PageButtonComponent>
                    </li>
                </React.Fragment>
            );
        });
    }

    changePage = (page, event) => {
        if (event !== undefined) event.preventDefault();

        const activePage = this.props.page;

        if (page === activePage) return;

        const visiblePages = getVisiblePages(page, this.props.pages);

        this.setState({
            visiblePages: filterPages(visiblePages, this.props.pages),
            pagesCount: this.props.pages,
        });

        this.props.onPageChange(page - 1);
    };

    slidePage = (e, activePage, forward = true) => {
        e.preventDefault();
        forward ? this.changePage(activePage + 1) : this.changePage(activePage - 1);
    };

    onAmountChange = (item) => {
        this.props.changePageAmount(item.value);
    };

    render() {
        const { PageButtonComponent = defaultButton, dataTest } = this.props;
        const activePage = this.props.page;
        const { pageSize } = this.props;
        return (
            <React.Fragment>
                <div className="Pagination Pagination_Right">
                    <div className="Pagination__Text Pagination_Left">
                        {/* Показано {this.props.data.length} из {this.props.foundCount} */}
                        <div className="Pagination__TextBlock">Показывать по </div>
                        <div className="Pagination__TextBlock">
                            <Select
                                value={{ value: pageSize, label: pageSize }}
                                options={selectPageAmountOptions}
                                onChange={this.onAmountChange}
                                styles={customSelectStyles}
                                menuPlacement="top"
                                isSearchable={false}
                            />
                        </div>
                        <div className="Pagination__TextBlock">
                            на странице (всего: {this.props.foundCount})
                        </div>
                    </div>
                    <ul className="Pagination__Buttons Pagination_Right">
                        <li
                            className="Pagination__Button_Arrow Pagination__Button_Prev"
                            key="page_prev"
                        >
                            <PageButtonComponent
                                onClick={(e) => this.slidePage(e, activePage, false)}
                                disabled={activePage === 1}
                                data-test={`${dataTest}--prevPage`}
                            >
                                {this.props.previousText}
                            </PageButtonComponent>
                        </li>

                        {this.renderVisiblePages()}

                        <li
                            className="Pagination__Button_Arrow Pagination__Button_Next"
                            key="page_next"
                        >
                            <PageButtonComponent
                                onClick={(e) => this.slidePage(e, activePage)}
                                disabled={activePage === this.props.pages || !this.props.pages}
                                data-test={`${dataTest}--nextPage`}
                            >
                                {this.props.nextText}
                            </PageButtonComponent>
                        </li>
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}


