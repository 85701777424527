import { deepClone } from 'utils';
import { HAS_NO_PLAN_MENU_ID, ALL_MENU_ID } from './constants';

export const filterGroups = (groups, selectedFilter) => {
    switch (selectedFilter) {
        case ALL_MENU_ID:
            return groups;
        case HAS_NO_PLAN_MENU_ID:
            return groups.map(x => ({
                ...x,
                persons: x.persons.filter(x => !!!x.percent),
            }));
        default:
            return groups;
    }
};

const compareByPositionDesc = (a, b) => {
    return a.percent === b.percent
        ? 0
        : a.percent < b.percent || a.percent === undefined
        ? 1
        : -1;
};

const compareByPositionAsc = (a, b) => {
    return (a.percent || 0) === (b.percent || 0)
                ? 0
                : !b.percent || (b.percent === 0 && b.hasNoPlan)
                    ? -1
                    : !a.percent || (a.percent === 0 && a.hasNoPan) || (a.percent || 0) > (b.percent || 0) ? 1 : -1;
};

export const sortGroups = (groups, selectedSorting) => {
    switch (selectedSorting.type) {
        case 'desc':
            return deepClone(groups).map(x => ({
                ...x,
                persons: x.persons.sort((a, b) => compareByPositionDesc(a, b))
            }));
        case 'asc':
            return deepClone(groups).map(x => ({
                ...x,
                persons: x.persons.sort((a, b) => compareByPositionAsc(a, b)),
            }));
        default:
            return groups;
    }
};

export const altCardText = person => person.percent ? `План реализован на ${Math.round(person.percent)}%` : 'Нет данных';