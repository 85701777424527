
import { appName, GROUPS_DICTIONARY_NAME } from 'constants.js';
import { takeLatest, put, call } from 'redux-saga/effects';
import { getGroup, createGroup, updateGroup } from '../api';
import { push } from 'connected-react-router';
import { group as groupRoute } from 'routes';
import RequestError from '../RequestError';
import { showErrorAlert } from './Alert';
import { fetchDictionaries, isDictionariesLoaded, selectDictionaries, refetchDictionary } from 'ducks/Dictionary';
import { withPageLoader } from './PageLoader';
import { serviceResultCode, getError } from 'serviceErrors';
import { groupType } from 'constants.js';

const moduleName = 'group';
const NEW_GROUP = `${appName}/${moduleName}/NEW_GROUP`;
const FETCH = `${appName}/${moduleName}/FETCH`;
const FETCH_START = `${appName}/${moduleName}/FETCH_START`;
const FETCH_END = `${appName}/${moduleName}/FETCH_END`;
const SAVE = `${appName}/${moduleName}/SAVE`;
const SAVE_START = `${appName}/${moduleName}/SAVE_START`;
const SAVE_END = `${appName}/${moduleName}/SAVE_END`;

export const allGroupDictionaries = [GROUPS_DICTIONARY_NAME];

const initialState = {
    loading: false,
    group: {
        id: null,
        parentId: null,
        title: "",
        emailAdministrationTitle: "",
        type: groupType.Default.id,
        groupScopes: []
    },
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_START:
        case SAVE_START:
            return {
                ...state,
                loading: true,
            };

        case SAVE_END:
        case FETCH_END: {
            if (!action.error) {
                const { group } = action.payload;

                return {
                    loading: false,
                    group,
                };
            } else {
                return {
                    ...state,
                    loading: false,
                };
            }
        }
        case NEW_GROUP:
            return {
                ...state,
                group: { ...initialState.group }
            }

        default:
            return state;
    }
}

export function newGroup() {
    return {
        type: NEW_GROUP,
    };
}

export function fetchGroup(id) {
    return {
        type: FETCH,
        payload: { id },
    };
}

export function saveGroup(group) {
    return {
        type: SAVE,
        payload: { group },
    };
}

function fetchStart() {
    return {
        type: FETCH_START,
    };
}

function fetchSuccess(group) {
    return {
        type: FETCH_END,
        payload: { group },
    };
}

function fetchFailed(error) {
    return {
        type: FETCH_END,
        payload: { error },
        error: true,
    };
}

function saveStart() {
    return {
        type: SAVE_START,
    };
}

function saveSuccess(group) {
    return {
        type: SAVE_END,
        payload: { group },
    };
}

function saveFailed(error) {
    return {
        type: SAVE_END,
        payload: { error },
        error: true,
    };
}

function* newSaga() {
    yield put(fetchDictionaries(allGroupDictionaries));
}

function* fetchSaga(action) {
    const { id: groupId } = action.payload;

    yield put(fetchStart());
    yield put(fetchDictionaries(allGroupDictionaries));

    try {
        const response = yield call(withPageLoader, () => getGroup(groupId));

        yield put(fetchSuccess(response.data));
    } catch (error) {
        const reqError = new RequestError(error, 'При загрузке группы произошла ошибка');

        yield put(fetchFailed(reqError));
        yield put(showErrorAlert(reqError.message));
    }
}

function* saveSaga(action) {
    const { group } = action.payload;

    yield put(saveStart());

    try {
        const response = yield call(withPageLoader,
            () => !group.id ? createGroup(group) : updateGroup(group), true);

        const savedGroup = response.data;

        yield put(saveSuccess(savedGroup));
        yield put(refetchDictionary(GROUPS_DICTIONARY_NAME));
        yield put(push(groupRoute.buildUrl({ id: savedGroup.id })));
    } catch (error) {

        const reqError = getError(error, getGroupError(group));

        yield put(saveFailed(reqError));
        yield put(showErrorAlert(reqError.message));
    }
}

const getGroupError = (group) => (code) => {
    switch (code) {
        case serviceResultCode.NotFound:
            return `Группа с именем "${group.title}" не найдена`;
        case serviceResultCode.GroupIsInUse:
            return `Группу с именем "${group.title}" запрещено менять, т.к. она уже используется`;
        case serviceResultCode.GroupTitleAlreadyExists:
            return `Группа с именем "${group.title}" уже существует`;
        case serviceResultCode.GroupIdEqualsToParentId:
            return `Группа с именем "${group.title}" не может быть одновременно родителем себя`;
        case serviceResultCode.GroupCannotChangeType:
            return `У Группы с именем "${group.title}" запрещено менять тип, т.к. она уже используется`;
        case serviceResultCode.EmailAdministrationTitleIsEmpty:
            return `Название группы для электронной рассылки "${group.emailAdministrationTitle}" уже существует`;
        default:
            return "Произошла непредвиденная ошибка" 
    }
}

export function* saga() {
    yield takeLatest(NEW_GROUP, newSaga);
    yield takeLatest(FETCH, fetchSaga);
    yield takeLatest(SAVE, saveSaga);
}

export function selectGroup(state) {
    const { loading, group } = state.group;

    return {
        group,
        loading: !isDictionariesLoaded(state.dictionary, allGroupDictionaries) || loading,
        dictionaries: selectDictionaries(state.dictionary, allGroupDictionaries),
        currentUser: state.auth.user,
    };
}
