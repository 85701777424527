import React from 'react';
import { filtersMenu, FILTERS_FILTER_MENU_ID} from './constants';
import Badge from 'components/Lk/Uikit/Badge';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { setMenuId } from 'ducks/FilterSearch';

const SearchCardFiltersMenu = props => {
    const { setMenuId, menuId, mainFilter, countFilters } = props;
    const handleFilterChange = e => {
        const activeFilterId = e.currentTarget.dataset.id;
        setMenuId(activeFilterId);
    };
        
    const filtersCount = countFilters(mainFilter.filter(x => x && !x.parent)) || null;

    return (
        <div className='LKSearchCard__Filters'>
            {filtersMenu.map( x => 
                <div
                    key={x.id}
                    data-id={x.id}
                    className={classnames('LKSearchCardFilterLink', {'LKSearchCardFilterLink--active': x.id === menuId})} 
                    onClick={handleFilterChange}>
                        <div className='LKSearchCardFilterLink__Name'>{x.name}</div>
                        {x.id === FILTERS_FILTER_MENU_ID && filtersCount &&
                            <div className='LKSearchCardFilterLink__Count'>
                                <Badge>{filtersCount}</Badge>
                            </div>
                        }
                </div>)
            }
        </div>
    );
};

const mapStateToProps = state => ({
    mainFilter: state.filterSearch.mainFilter,
    menuId: state.filterSearch.menuId
});

const actions = { setMenuId };

export default connect(mapStateToProps, actions)(SearchCardFiltersMenu);