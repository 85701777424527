import React, { Component } from 'react';
import { getMassEmails, deleteMassEmailById } from 'api';
import { connect } from 'react-redux';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import { push as pushLocation } from 'connected-react-router';
import { parse as queryStringParse, stringify } from 'query-string';
import DataGrid from 'components/common/DataGrid';
import ListTemplate from 'components/uikit/ListTemplate/ListTemplate';
import { massEmailLists, newMassEmail } from 'routes';
import Loader from 'components/common/Loader';
import { NavLink } from 'react-router-dom';
import Button from 'components/uikit/Button';
import { editMassEmail } from 'routes';
import ModalDialog from 'components/common/ModalDialog';
import { formatDate, startOf, endOf } from 'utils';
import './Lists.scss';

const initialFilter = { subject: '', createdFrom: null, createdTo: null };

class EmailsList extends Component {
    state = {
        payload: {
            data: [],
            meta: {
                foundCount: 0,
                pageCount: 0,
            },
        },
        loading: false,
        isModalOpen: false,
        selectedEmailId: null,
        selectedEmailSubject: '',
        sorting: {
            created: 'desc',
        },
        filter: initialFilter,
        isDeleting: false,
    };

    onFilterChange = (value) => {
        this.setState({
            filter: {
                ...this.state.filter,
                ...value,
            },
        });
    };

    onFilterSubmit = () => {
        const urlParams = {
            ...this.props.queryParams,
            page: 1,
        };
        if (+this.props.queryParams.page === 1) {
            this.fetchData();
        } else {
            this.props.pushLocation({ pathname: massEmailLists.url, search: stringify(urlParams) });
        }
    };

    handleEnterKeyPressed = (e) => {
        if (e.key === 'Enter') {
            this.onFilterSubmit();
        }
    };

    onClearFilters = () => {
        this.setState({ filter: initialFilter }, () => {
            const urlParams = {
                ...this.props.queryParams,
                pageSize: 10,
                page: 1,
            };

            if (+this.props.queryParams.page === 1) {
                this.fetchData();
            } else {
                this.props.pushLocation({
                    pathname: massEmailLists.url,
                    search: stringify(urlParams),
                });
            }
        });
    };

    filterForm = () => ({
        title: 'Фильтры',
        line: [
            {
                type: 'Input',
                label: 'Поиск по теме',
                id: 'subject',
                name: 'subject',
                value: this.state.filter.subject,
                onChange: (e) => this.onFilterChange({ subject: e.target.value }),
                onKeyDown: this.handleEnterKeyPressed,
            },
            {
                type: 'DatePicker',
                label: 'Дата создания с',
                id: 'createdFrom',
                name: 'createdFrom',
                selected: this.state.filter.createdFrom,
                onChange: (value) =>
                    this.onFilterChange({ createdFrom: new Date(startOf(value, 'day').format()) }),
            },
            {
                type: 'DatePicker',
                label: 'Дата создания по',
                id: 'createdTo',
                name: 'createdTo',
                selected: this.state.filter.createdTo,
                onChange: (value) =>
                    this.onFilterChange({ createdTo: new Date(endOf(value, 'day').format()) }),
            },
        ],
        btnOnClick: this.onFilterSubmit,
        btnClearOnClick: this.onClearFilters,
    });

    gridColumns = [
        {
            Header: 'Тема',
            accessor: 'subject',
            resizable: false,
            sortable: true,
            Cell: ({ original }) => (
                <NavLink to={editMassEmail.buildUrl({ id: original.id }, `?${stringify(this.props.queryParams)}`)}>
                    {original.subject}
                </NavLink>
            ),
        },
        {
            Header: 'Дата создания',
            accessor: 'created',
            resizable: false,
            maxWidth: 200,
            sortable: true,
            Cell: ({ original }) => formatDate(original.created, 'YYYY-MM-DD HH:mm'),
        },
        {
            Header: 'Действие',
            accessor: 'id',
            maxWidth: 150,
            resizable: false,
            sortable: false,
            Cell: ({ original }) => (
                <>
                    <Button
                        size="xs"
                        color="danger"
                        onClick={() => this.openModal(original.id, original.subject)}
                        className="EmailsList__ActionButton"
                    >
                        Удалить
                    </Button>
                </>
            ),
        },
    ];

    createEmail = () => {
        this.props.pushLocation(newMassEmail.url);
    };

    headBtn = {
        onClick: this.createEmail,
        value: 'Новое письмо',
        addLink: true,
    };

    fetchData = async () => {
        const { sorting, filter } = this.state;
        const { showPageLoader, hidePageLoader, queryParams } = this.props;
        try {
            const criteria = {
                paging: { pageNum: queryParams.page, pageSize: queryParams.pageSize ? queryParams.pageSize : 10 },
                sorting,
                filter,
            };
            this.setState({ loading: true });
            showPageLoader();
            const emailsData = await getMassEmails(criteria);
            this.setState({
                payload: {
                    data: [...emailsData.data.payload],
                    meta: { ...emailsData.data.meta },
                },
            });
        } catch (e) {
            this.props.showErrorAlert('Ошибка получения списка писем');
        } finally {
            this.setState({ loading: false });
            hidePageLoader();
        }
    };

    onPageChange = (pageIndex) => {
        const urlParams = {
            ...this.props.queryParams,
            page: pageIndex + 1,
        };
        this.props.pushLocation({ pathname: massEmailLists.url, search: stringify(urlParams) });
    };

    changePageAmount = (pageSize) => {
        const urlParams = {
            ...this.props.queryParams,
            page: 1,
            pageSize,
        };
        this.props.pushLocation({ pathname: massEmailLists.url, search: stringify(urlParams) });
    };

    onSortedChange = (sorted) => {
        this.setState({
            sorting: { [sorted[0].id]: sorted[0].desc ? 'desc' : 'asc' },
        });
    };

    deleteEmail = async () => {
        const { showPageLoader, hidePageLoader } = this.props;
        try {
            this.setState({ isDeleting: true });
            showPageLoader();
            await deleteMassEmailById(this.state.selectedEmailId);
            this.closeModal();
            this.props.showSuccessAlert('Письмо успешно удалено.');
            this.fetchData();
        } catch (e) {
            this.props.showErrorAlert('Ошибка удаления письма.');
        } finally {
            hidePageLoader();
            this.setState({ isDeleting: false });
        }
    };

    openModal = (id, subject) => {
        this.setState({ isModalOpen: true, selectedEmailId: id, selectedEmailSubject: subject });
    };

    closeModal = () => {
        this.setState({ isModalOpen: false, selectedEmailId: null, selectedEmailSubject: '' });
    };

    async componentDidMount() {
        await this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        const { payload, sorting } = this.state;
        const { queryParams } = this.props;

        if (
            prevProps.queryParams.page !== queryParams.page ||
            JSON.stringify(prevState.sorting) !== JSON.stringify(sorting) ||
            prevProps.queryParams.pageSize !== queryParams.pageSize
        ) {
            this.fetchData();
        }

        if (JSON.stringify(prevState.payload.data) !== JSON.stringify(payload.data)) {
            if (+queryParams.page > payload.meta.pageCount) {
                const urlParams = {
                    ...queryParams,
                    page: payload.meta.pageCount,
                };
                this.props.pushLocation({
                    pathname: massEmailLists.url,
                    search: stringify(urlParams),
                });
            }
        }
    }

    render() {
        const { payload, loading, isModalOpen, selectedEmailSubject } = this.state;
        const { queryParams } = this.props;

        const currentPage = () => {
            return !queryParams.page || payload.meta.pageCount === 0 ? 1 : +queryParams.page;
        };

        const currentPageSize = () => {
            return !queryParams.pageSize ? 10 : +queryParams.pageSize;
        };

        return loading ? (
            <Loader overlay />
        ) : (
            (payload.data.length !== 0 || !loading) && (
                <>
                    <ListTemplate
                        title={this.props.menu}
                        headBtn={this.headBtn}
                        form={this.filterForm()}
                    >
                        <DataGrid
                            data={payload.data}
                            foundCount={payload.meta.foundCount}
                            columns={this.gridColumns}
                            showPagination={true}
                            showPageSizeOptions={false}
                            sorted={this.state.sorted}
                            onSortedChange={this.onSortedChange}
                            pages={payload.meta.pageCount}
                            page={currentPage()}
                            onPageChange={this.onPageChange}
                            manual
                            pageSize={currentPageSize()}
                            changePageAmount={this.changePageAmount}
                        />
                    </ListTemplate>
                    <ModalDialog
                        onClick={this.deleteEmail}
                        onCloseModal={this.closeModal}
                        modalOpen={isModalOpen}
                        processing={this.state.isDeleting}
                        modalHeader={`Вы уверены, что хотите удалить письмо "${selectedEmailSubject}"?`}
                        btnOktext="Удалить"
                        btnOkColor="danger"
                        btnCanceltext="Отмена"
                    />
                </>
            )
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryParams: queryStringParse(state.router.location.search),
    };
};

const actions = { showErrorAlert, showSuccessAlert, pushLocation, showPageLoader, hidePageLoader };

export default connect(mapStateToProps, actions)(EmailsList);
