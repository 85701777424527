import React from 'react';
import ChevronButton from 'components/uikit/ChevronButton';
import classnames from 'classnames';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { getEvaluationTestResultFileLink, getCombinedResultsFile, getCountResultFile } from 'api';
import RoundButton from 'components/uikit/Button/RoundButton';
import { connect } from 'react-redux';
import { fetchTestProviders } from 'ducks/TestProviders';
import { EVALUATIONTEST_STATUS } from 'constants.js';

const initialState = {
    testProviders: [],
    countResultFiles:0,
};

const TestItem = ({ test, testProviders, renderChart, personId, onClick, onToggle }) => {
    const testPassed = true; //TODO TEST FAILED ??????
    const showFileLink = test.result && test.result.resultFileId;
    const link = test.result && getEvaluationTestResultFileLink(test.result.id);
    const testProvider = testProviders.find(x => x.id === test.testProviderId);

    return (
        <div className="Line" key={test.result.id}>
            <a name={test.result.id} href="#/" className="PageAnchor">test.title</a>
            <div className="Sub-Line">
                <div className="Sub-Line__Left">
                    {test.title}
                    {showFileLink &&
                        <a className="FileDownloadLink" href={link}>
                            <FontAwesomeIcon icon={faFileDownload} size="lg" />
                        </a>
                    }
                </div>
                <div className={classnames("Sub-Line__Right", { "Sub-Line__Right--yes": testPassed, "Sub-Line__Right--no": !testPassed })}>
                    {testPassed ? "Пройден" : "Сбой в тестировании"}
                    {
                        <div className="Sub-Line__Right--chevron">
                            <div className="Test-Edit-Btn">
                                <RoundButton type="edit" resultid={test.result.id} onClick={onClick} />
                            </div>
                            <ChevronButton className="kr_right" isUp={test.isOpen} testid={test.result.id} onClick={() => onToggle(test.result.id)} />
                        </div>
                    }
                </div>

            </div>
            {test.isOpen &&
                <>
                    <div className="Sub-Line__Test">
                        {renderChart(personId, test, test.visualizationKind, {}, testProviders = [])}
                    </div>
                    <div className="Sub-Line Sub-Line--info">
                        <label>Оценка получена</label>{moment(test.result.completedOn).format('DD.MM.YYYY')}
                    </div>
                    <div className="Sub-Line Sub-Line--info">
                        <label>Проведено</label>{!!testProvider && testProvider.title}
                    </div>
                </>
            }
        </div>
    );
};

class Tests extends React.PureComponent {

    state = initialState;

    async componentDidMount() {
        if (!this.props.testProvidersStored) {
            this.callEffect( async() => {
                await this.props.fetchTestProviders();
            });
        }
      
        const result = await getCountResultFile(this.props.person.id);
        this.setState({countResultFiles:result.data})
    }

    renderTests = (actual = true) => {
        let { loaded, testsResults, person } = this.props;
        const {countResultFiles} = this.state;
        const link = getCombinedResultsFile(person.id);
        const isSectionOpen = actual ? this.props.isActiveTestsSectionOpen : this.props.isArchiveTestsSectionOpen;
        const testList = loaded && testsResults && testsResults.length > 0 && 
            testsResults.filter(x => x.resultType === (actual ? EVALUATIONTEST_STATUS.Relevant : EVALUATIONTEST_STATUS.Archived));
        
        if (testList && testList.length === 0 && !actual) {
            return;
        }

        return (loaded && testList && testList.length !== 0 &&
            <div className="Tests-Block" ref={actual ? this.props.evaluationRefs.actualTests : this.props.evaluationRefs.archiveTests}>
                <div className="Header">
                    <div className="Header__Text">
                        {actual ? "Актуальные" : "Архив"}

                    </div>
                    <div className="Header__Right">
                        <ChevronButton className="kr_right" isUp={isSectionOpen} onClick={() => this.props.handleToggle(actual ? 'isActiveTestsSectionOpen' : 'isArchiveTestsSectionOpen')} />
                    </div>
                </div>
                {isSectionOpen && (
                    <div className="Tests-Block__List">
                        {countResultFiles >0 && actual && person &&
                            <div className="CombinedFileReportButton__Container">
                                <a href={link} download={`Сводный_отчет_результаты_${person.lastName}`}>
                                    <div className="CombinedFileReportButton">Сводный отчет<FontAwesomeIcon className="CombinedFileReportButton__Icon" icon={faFileDownload} size="lg" /></div>
                                </a>
                            </div>}
                        {testList.map(x => <TestItem
                            key={x.result.id}
                            test={x}
                            actual={actual}
                            testProviders={this.props.testProviders}
                            onClick={this.props.handleTestEdit}
                            onToggle={this.props.handleTestToggle}
                            renderChart={this.props.renderChart}
                            personId={this.props.personId}
                        />)}
                    </div>
                )}
            </div>
        );
    }

    RenderTest = ({ test }) => this.renderActualTest(test);

    callEffect = async (callback) => {
        this.props.showPageLoader();
        try {
            return await callback();
        } catch (error) {
            this.props.showErrorAlert(error.message);
        }
        finally {
            this.props.hidePageLoader();
        }
    };

    render() {
        if (!this.props.testProvidersStored) {
            return null;
        }
        return (
            <>
                <div className="Person-Tests">
                    {this.renderTests()}
                </div>
                <div className="Person-Tests">
                    {this.renderTests(false)}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        person: state.person.data,
        testProviders: state.testProviders.data.payload,
        testProvidersStored: state.testProviders.loadComplete,
    }
};

const actions = { fetchTestProviders };

export default connect(mapStateToProps, actions)(Tests);