import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Title = props => {
    const { title, isSearchCard } = props;
    return (
        <div
            className={classnames('LKPersonCardTitle', {
                'LKPersonCardTitle--is-search': isSearchCard,
            })}
        >
            {title}
        </div>
    );
};

Title.defaultProps = {
    isSearchCard: false,
};

Title.propTypes = {
    title: PropTypes.string,
    isSearchCard: PropTypes.bool,
};

export default Title;
