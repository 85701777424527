import { appName } from '../constants';
import { takeLatest, put, call, all } from 'redux-saga/effects';
import { getAnalytics } from '../api';
import RequestError from '../RequestError';
import { showErrorAlert } from './Alert';
import { analytics as analyticsRoute } from 'routes';
import { push } from 'connected-react-router';
import { withPageLoader } from './PageLoader';

const moduleName = 'analytics';
export const FETCH_REQUEST = `${appName}/${moduleName}/FETCH_REQUEST`;
export const FETCH_START = `${appName}/${moduleName}/FETCH_START`;
export const FETCH_SUCCESS = `${appName}/${moduleName}/FETCH_SUCCESS`;
export const FETCH_FAILED = `${appName}/${moduleName}/FETCH_FAILED`;

const initialState = {
    loading: false,
    loadComplete: false,
    loadTime: new Date(0),
    ids: [],
    data: [],
    error: '',
};

export default function reducer(state = initialState, action) {
    const { type, payload, error } = action;
    switch (type) {
        case FETCH_START:
            return {
                ...initialState,
                loading: true,
                ids: payload.ids,
            };
        case FETCH_FAILED:
            return {
                ...initialState,
                loadComplete: true,
                loading: false,
                loadTime: payload.loadTime,
                error: error.message,
            };
        case FETCH_SUCCESS:
            return {
                ...state,
                loadComplete: true,
                loading: false,
                loadTime: payload.loadTime,
                data: payload.data,
            }
        default:
            return state;
    }
}

export const fetchData = ids => {
    return {
        type: FETCH_REQUEST,
        payload: { ids },
    }
};

export const fetchStart = (ids = []) => {
    return {
        type: FETCH_START,
        payload: { ids },
    }
};

export const fetchSuccess = (data, loadTime) => {
    return {
        type: FETCH_SUCCESS,
        payload: { data, loadTime },
    }
};

export const fetchFailed = (ids, error, loadTime) => {
    return {
        type: FETCH_FAILED,
        payload: { ids, loadTime },
        error,
    }
};

export const fetchDataSaga = function* (action) {
    const { ids } = action.payload;
    yield put(fetchStart(ids));
    try {
        const response = yield call(withPageLoader, () => getAnalytics(ids));
        const analyticsData = response;
        yield put(fetchSuccess(analyticsData, new Date()));
        yield put(push(analyticsRoute.url));
    } catch (error) {
        const reqError = new RequestError(error, 'Ошибка получения данных');
        yield all([
            put(fetchFailed(reqError, new Date())),
            put(showErrorAlert(reqError.message))
        ]);
    }
};


export const saga = function* () {
    yield takeLatest(FETCH_REQUEST, fetchDataSaga);
};
