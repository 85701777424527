import React from 'react';
import Toggle from 'components/common/Toggle';
import Select, { VirtualizedSelect } from 'components/uikit/Select';
import Field from 'components/uikit/Field';
import Input from 'components/uikit/Input';
import InputGroup from 'components/uikit/InputGroup';
import InputMask from 'components/uikit/InputMask';
import Label from 'components/uikit/Label';
import LinkButton from 'components/uikit/LinkButton';
import { operationFromSelect } from './QueryOperation';
import Switcher from 'components/Lk/Uikit/Switcher';
import classnames from 'classnames';
import SearchGroupCondition from 'components/Lk/Shared/Search/SearchGroupCondition';

const RenderDataCard = React.memo((props) => {
    const { handleStateChange, handleTypeOfAction, isFilled } = props;

    const handleSwitcherChange = (item) => (e) => {
        const blockState = [
            item.value,
            e.currentTarget.checked ? [{ name: 'isCurrent', operation: '=', value: true }] : null,
        ];
        handleStateChange(item.bind || props.obj.blockName, blockState, true);
    };

    const handleInputChange = (item) => (e) => {
        const blockState = [item.value, e.target.value];
        handleStateChange(item.bind || props.obj.blockName, blockState);
    };

    const handleTypeOfActionChange = (item) => (value) => {
        const blockState = [item.value, value];
        handleTypeOfAction(item.bind || props.obj.blockName, blockState);
    };

    const selectOnChange = (item) => (e) => {
        const blockState = [item.value, e && e.length !== 0 ? e : null];
        if (item.onChange) {
            props[item.onChange](blockState);
        } else {
            handleStateChange(item.bind || props.obj.blockName, blockState);
        }
    };

    const selectOnInputChange = (item) => (e) => {
        const blockState = [item.value, e && e.length !== 0 ? e : null];
        if (item.onInputChange) {
            props[item.onInputChange](blockState);
        }
    };

    const handleInputGroupChange = (item) => (e) => {
        const blockState = [item.value, e.target.value];
        handleStateChange(item.bind || props.obj.blockName, blockState);
    };

    const handleToggleChange = (item) => (e) => {
        const value = props[item.options].data.find((findItem) => findItem.id === e);
        const blockState = [item.value, value];
        handleStateChange(item.bind || props.obj.blockName, blockState);
    };

    const handleInputMaskChange = (item) => (e) => {
        const blockState = [item.value, e.target.value];
        handleStateChange(item.bind || props.obj.blockName, blockState);
    };

    const onCheck = (item) => (e) => {
        if (item.onCheck && typeof props[item.onCheck] === 'function') {
            props[item.onCheck](e);
            return;
        }
        handleSwitcherChange(item);
    };

    function renderFields(obj) {
        const fields = obj.map((item) => {
            if (
                !item.visible ||
                (typeof item.visible === 'string' &&
                    !props[item.visible](props[item.visibleAtributs].value))
            )
                return true;

            const checked = item.checked
                ? props[item.checked]()
                : (props[item.value]?.addInfo &&
                      props[item.value]?.addInfo.find((x) => x.name === 'isCurrent')?.value) ||
                  false;

            switch (item.type) {
                case 'Input':
                    return (
                        <Field key={item.id}>
                            <Label>
                                <div className="SearchTopMenu__LabelPlus">
                                    <div>{item.label}</div>
                                    <div className="SearchTopMenu__LabelPlus--text">
                                        {item.withCurrent && (
                                            <Switcher
                                                titleBefore={item.withCurrentText}
                                                checked={
                                                    (props[item.value].addInfo &&
                                                        props[item.value].addInfo.find(
                                                            (x) => x.name === 'isCurrent',
                                                        ).value) ||
                                                    false
                                                }
                                                onClick={handleSwitcherChange(item)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Label>
                            <div className="SearchTopMenuBlockWrapper">
                                <div className="SearchTopMenu__TypeOfAction">
                                    <Select
                                        className="SearchTopMenu__TypeOfAction--select"
                                        value={props[item.value] && props[item.value].operation}
                                        onChange={handleTypeOfActionChange(item)}
                                        options={operationFromSelect.String}
                                        catalog
                                    />
                                    <Input
                                        className="SearchTopMenu__TypeOfAction--input"
                                        value={props[item.value] && props[item.value].value}
                                        maxLength={item.maxLength}
                                        onChange={handleInputChange(item)}
                                    />
                                </div>
                            </div>
                        </Field>
                    );
                case 'Select':
                    return (
                        <Field key={item.label}>
                            <Label>
                                <div className="SearchTopMenu__LabelPlus">
                                    <div>{item.label}</div>
                                    <div className="SearchTopMenu__LabelPlus--text">
                                        {item.withCurrent && (
                                            <Switcher
                                                titleBefore={item.withCurrentText}
                                                checked={checked}
                                                onClick={onCheck(item)}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Label>
                            <div className="SearchTopMenu__TypeOfAction">
                                {!item.hideTypeOfAction && (
                                    <div className="SearchTopMenu__TypeOfActionLeftSelect">
                                        <Select
                                            value={props[item.value] && props[item.value].operation}
                                            onChange={handleTypeOfActionChange(item)}
                                            options={operationFromSelect.Boolean}
                                            catalog
                                        />
                                    </div>
                                )}
                                <div
                                    className={classnames('SearchTopMenu__Field', {
                                        SearchTopMenu__TypeOfActionRightSelect: !item.hideTypeOfAction,
                                    })}
                                >
                                    <Select
                                        value={props[item.value] && props[item.value].value}
                                        onChange={selectOnChange(item)}
                                        onInputChange={selectOnInputChange(item)}
                                        options={props[item.options]?.data}
                                        isClearable
                                        catalog
                                        isMulti={item.isMulti}
                                        isDisabled={props[item.isDisabled]}
                                        isLoading={
                                            props[item.options] && !props[item.options].loadComplete
                                        }
                                    />
                                </div>
                            </div>
                        </Field>
                    );
                case 'InputGroup':
                    return (
                        <Field key={item.id}>
                            <Label>{item.label}</Label>
                            <div className="SearchTopMenu__TypeOfAction">
                                <InputGroup>
                                    {item.InputGroup.map((mapItem) => {
                                        return (
                                            <InputMask
                                                key={mapItem.value}
                                                mask={mapItem.mask}
                                                maskChar={mapItem.maskChar}
                                                value={
                                                    props[mapItem.value] &&
                                                    props[mapItem.value].value
                                                }
                                                onChange={handleInputGroupChange(mapItem)}
                                                placeholder={mapItem.placeholder}
                                            />
                                        );
                                    })}
                                </InputGroup>
                            </div>
                        </Field>
                    );
                case 'Toggle':
                    return (
                        <Field key={item.id}>
                            <Label>{item.label}</Label>
                            <Toggle
                                value={props[item.value].value ? props[item.value].value.id : ''}
                                options={props[item.options].data.map((mapItem, index) => ({
                                    htmlId: item.htmlId + index,
                                    title: mapItem.name,
                                    value: mapItem.id,
                                }))}
                                onChange={handleToggleChange(item)}
                            />
                        </Field>
                    );
                case 'InputMask':
                    const mask = !item.maskFunc
                        ? item.mask
                        : props[item.maskFunc](props[item.mask].value);

                    return (
                        <Field key={item.id}>
                            <Label>{item.label}</Label>
                            <div className="SearchTopMenu__TypeOfAction">
                                <Select
                                    className="SearchTopMenu__TypeOfAction--select"
                                    value={props[item.value] && props[item.value].operation}
                                    onChange={handleTypeOfActionChange(item)}
                                    options={operationFromSelect.String}
                                    catalog
                                />
                                <InputMask
                                    className="SearchTopMenu__TypeOfAction--input"
                                    mask={mask}
                                    value={props[item.value] && props[item.value].value}
                                    maxLength={item.maxLength}
                                    onChange={handleInputMaskChange(item)}
                                />
                            </div>
                        </Field>
                    );
                case 'VirtualizedSelect':
                    return (
                        <Field key={item.id}>
                            <Label>{item.label}</Label>
                            <div className="SearchTopMenu__TypeOfAction">
                                <Select
                                    className="SearchTopMenu__TypeOfAction--select"
                                    key={item.id + 1}
                                    value={props[item.value] && props[item.value].operation}
                                    onChange={handleTypeOfActionChange(item)}
                                    options={operationFromSelect.Boolean}
                                    catalog
                                />

                                <VirtualizedSelect
                                    value={props[item.value] && props[item.value].value}
                                    onChange={selectOnChange(item)}
                                    options={props[item.options] ? props[item.options].data : []}
                                    isLoading={
                                        props[item.options] ? props[item.options].loading : []
                                    }
                                    isClearable
                                    catalog
                                    isMulti={item.isMulti}
                                />
                            </div>
                        </Field>
                    );
                case 'EvaluationBlock':
                    const { id, value, ...tail } = item;
                    delete tail.visible;
                    
                    return (
                        <React.Fragment key={id}>
                            <div>
                                <div className="EvaluationSearchTags">
                                    {props.criteria.map((mapItem) => (
                                        <props.tagName
                                            key={mapItem.id}
                                            {...mapItem}
                                            onRemove={props.onRemove}
                                            onSelect={props.onSelect}
                                        />
                                    ))}
                                </div>
                                <Field>
                                    <LinkButton onClick={props.onClick} {...tail} type="button">
                                        {value}
                                    </LinkButton>
                                </Field>
                            </div>
                        </React.Fragment>
                    );
                default:
                    return (
                        <Label key={item.id}>
                            {item.type} {item.label}
                        </Label>
                    );
            }
        });
        return fields;
    }

    return (
        <div
            className={classnames('SearchTopMenu__Block', {
                'SearchTopMenu__Block--filled': isFilled,
            })}
        >
            <div className="SearchTopMenu__Header">
                {props.obj.title && (
                    <h1 className="SearchTopMenu__Title--block">{props.obj.title}</h1>
                )}
                <div className="SearchTopMenu__Condition">
                    {props.condition && (
                        <>
                            <span>Условия группировки</span>
                            <SearchGroupCondition
                                checked={props.conditions[props.condition] === 'OR'}
                                onClick={props.handleConditionChange(props.condition)}
                                title={false}
                            />
                        </>
                    )}
                </div>
            </div>
            {renderFields(props.obj.fields)}
        </div>
    );
});

export default RenderDataCard;
