import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { push } from 'connected-react-router';
import { groupEdit } from 'routes';
import { groupNew as routeGroupNew, group as routeGroup } from 'routes';
import { fetchGroups, groupsSelector, deleteGroup } from 'ducks/Groups';
import ListTemplate from 'components/uikit/ListTemplate';
import DataGrid from 'components/common/DataGrid';
import Button from 'components/uikit/Button';
import Page from 'components/common/Page';
import './Groups.scss';
import { isSecurityAdmin, isITAdmin } from 'rightsController';
import ModalDialog from 'components/common/ModalDialog';
import { groupType } from 'constants.js';

const initialState = {
    modalOpen: false,
    groups: [],
    filter: {},
    sorting: {
        title: 'Asc',
    },
    paging: { pageNum: 1, pageSize: 10 },
};

class Groups extends Component {
    state = initialState;

    componentDidMount() {
        this.performFetchGroups();
    }

    componentDidUpdate() {
        if (this.props.needUpdate) {
            this.performFetchGroups();
        }
    }

    buildColumns(currentUser, editGroup, openDeleteDialog) {
        return [
            {
                Header: '',
                width: 20,
            },
            {
                Header: 'Название',
                accessor: 'title',
                resizable: false,
                Cell: ({ original: group }) => {
                    return (
                        <NavLink to={routeGroup.buildUrl({ id: group.id })}>{group.title}</NavLink>
                    );
                },
            },
            {
                Header: 'Тип',
                accessor: 'type',
                minWidth: 80,
                maxWidth: 350,
                resizable: false,
                sortable: false,
                Cell: ({ original: group }) => {
                    return groupType[group.type]?.value;
                },
            },
            {
                Header: 'Действия',
                maxWidth: 230,
                sortable: false,
                resizable: false,
                show: isITAdmin(currentUser),
                Cell: ({ original }) => {
                    return this.renderActionButtons(original, editGroup, openDeleteDialog);
                },
            },
            {
                Header: '',
                show: isSecurityAdmin(currentUser),
                maxWidth: 20,
                resizable: false,
            },
        ];
    }

    renderEditButton = id => {
        return (
            <Button onClick={() => this.editGroup(id)} size="xxs">
                Редактировать
            </Button>
        );
    };

    renderDeleteButton = group => {
        return (
            <Button color="danger" onClick={() => this.openDeleteDialog(group)} size="xxs">
                Удалить
            </Button>
        );
    };

    renderActionButtons = group => {
        return (
            <div className="Groups__ActionsButtons">
                {this.renderEditButton(group.id)}
                {this.renderDeleteButton(group)}
            </div>
        );
    };

    openDeleteDialog = id => {
        this.onOpenModal(id);
    };

    performFetchGroups = () => {
        const { paging, sorting } = this.state;
        const criteria = { paging, sorting };

        this.props.fetchGroups(criteria);
    };

    newGroup = () => {
        this.props.push(routeGroupNew.buildUrl({}));
    };

    onPageChange = pageIndex => {
        const { paging } = this.state;
        this.setState(
            {
                paging: { ...paging, pageNum: pageIndex + 1 },
            },
            () => this.performFetchGroups(),
        );
    };

    changePageAmount = (pageSize) => {
        this.setState({ paging: { pageNum: 1, pageSize } }, () =>
            this.performFetchGroups(),
        );
    };

    static getDerivedStateFromProps = (props, state) => {
        if (props.data && state.groups !== props.data.payload) {
            return {
                groups: props.data.payload,
            };
        }
        return null;
    };

    onSortedChange = sorted => {
        let sorting = { ...this.state.sorting };

        sorted.forEach(item => {
            sorting[item.id] = item.desc ? 'Desc' : 'Asc';
        });

        this.setState({ sorting }, () => this.performFetchGroups());
    };

    getSorted = () => {
        const sorted = [];
        const { sorting } = this.state;

        for (let key in sorting) {
            sorted.push({ id: key, desc: sorting[key] === 'Desc' });
        }

        return sorted;
    };

    editGroup = id => {
        this.props.push(groupEdit.buildUrl({ id }));
    };

    deleteGroup = group => {
        this.props.deleteGroup(group);
        this.onCloseModal();
    };

    onCloseModal = () => {
        this.setState({ modalOpen: false, modalGroupId: null });
    };

    onOpenModal = group => {
        this.setState({ modalOpen: true, modalGroup: group });
    };

    headBtn = () => {
        return isITAdmin(this.props.currentUser)
            ? { onClick: this.newGroup, value: 'Создать группу', addLink: true, size: 'xs' }
            : null;
    };

    render() {
        if (!this.props.loadComplete) {
            return null;
        }

        const { pageCount } = this.props.data.meta;
        const { paging } = this.state;

        const columns = this.buildColumns(
            this.props.currentUser,
            this.editGroup,
            this.openDeleteDialog,
        );
        return (
            <Page>
                <ListTemplate title={' '} headBtn={this.headBtn()}>
                    <div className="Groups">
                        <DataGrid
                            data={this.props.data.payload}
                            foundCount={this.props.data.meta.foundCount}
                            columns={columns}
                            loading={!this.props.loadComplete}
                            sorted={this.getSorted()}
                            onSortedChange={this.onSortedChange}
                            pages={pageCount}
                            page={paging.pageNum}
                            onPageChange={this.onPageChange}
                            showPageSizeOptions={false}
                            showPagination
                            manual
                            pageSize={paging.pageSize}
                            changePageAmount={this.changePageAmount}
                        />
                    </div>
                    <ModalDialog
                        onClick={() => this.deleteGroup(this.state.modalGroup)}
                        onCloseModal={this.onCloseModal}
                        modalOpen={this.state.modalOpen}
                        modalHeader={
                            this.state.modalGroup
                                ? `Вы действительно хотите удалить группу ${this.state.modalGroup.title}?`
                                : ''
                        }
                        btnOktext="Да"
                        btnOkColor="danger"
                        btnCancelColor="secondary"
                        btnCanceltext="Отмена"
                    />
                </ListTemplate>
            </Page>
        );
    }
}

const actions = {
    push,
    fetchGroups,
    deleteGroup,
};

export default connect(
    groupsSelector,
    actions,
)(Groups);
