import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { attributeFullSelector, fetchAttribute, newAttributeSelector, saveAttribute, cancel } from 'ducks/Attribute';
import { showWarningAlert } from 'ducks/Alert';
import Page from "components/common/Page";
import Select from 'components/uikit/Select';
import Button from 'components/uikit/Button';
import Label from 'components/uikit/Label';
import Input from 'components/uikit/Input';
import { customAttributesTypes } from 'enums.js';
import uuid from "uuid/v4";
import './Attributes.scss';
import LinkButton from 'components/uikit/LinkButton';
import Field from 'components/uikit/Field';
import { isSecurityAdmin } from 'rightsController';
import { isNullOrWhitespace } from 'utils';

class Attribute extends Component {
    static propTypes = {
        id: PropTypes.number,
        attribute: PropTypes.object.isRequired,
    }

    state = {
        attribute: {
            id: 0,
            type: '',
            name: '',
            canDelete: false,
            selectOption: "",
            payload: [],
        },
        loadComplete: null,
        isFormSubmitted: false,
    }

    static getDerivedStateFromProps(props, state) {
        if (state.loadComplete) {
            return null;
        }

        const { attribute } = props;

        let loadComplete = attribute.loadComplete;
        if (state.loadComplete == null) {
            loadComplete = loadComplete && attribute.isActual;
        }

        if (!loadComplete) {
            return { loadComplete };
        }

        return {
            loadComplete: true,
            attribute,
        }
    }

    componentDidMount() {
        if (this.state.loadComplete && this.state.attribute.id === this.props.id) {
            return;
        }

        if (this.isNewAttribute()) {
            this.props.newAttributeSelector();
        } else {
            this.props.fetchAttribute(this.props.id);
        }
    }

    isNewAttribute() {
        return !this.props.id;
    }

    getAttributeToSave = () => {
        let { id, type, name, canDelete, payload } = this.state.attribute;
        payload = JSON.stringify(payload);
        return { id, type, name, canDelete, payload };
    }

    getInputErrorKey(inputStateKey) {
        return `${inputStateKey}_error`;
    }

    getInputErrorMessage = (inputStateKey, inputErrorKey) => {
        const errorKey = inputErrorKey || this.getInputErrorKey(inputStateKey);
        return this[errorKey];
    }

    handleStateChange = (key, value) => {
        this.setState(state => {
            const attribute = {
                ...state.attribute,
                [key]: value
            }
            return {
                ...state,
                attribute: { ...attribute }
            }
        });
    }

    handleTypeChange = (type) => {
        this.handleStateChange('type', type);
    }

    handleSelectOptionChange = (value) => {
        this.handleStateChange('selectOption', value);
    }

    handleSelectOptionAdd = (option) => {
        if (!option) { return null }
        const newOptions = [...this.state.attribute.payload, { id: uuid(), value: option }];
        this.setState({ attribute: { ...this.state.attribute, selectOption: "", payload: [...newOptions] } })
    }

    handleSelectOptionRemove = (option) => {
        const newOptions = this.state.attribute.payload.filter(x => x.id !== option.id)
        this.setState({ attribute: { ...this.state.attribute, selectOption: "", payload: [...newOptions] } });
    }

    onSubmit = e => {
        e.preventDefault();
        if (this.isSelectAttribute() && !this.isSelectValid()) {
            this.props.showWarningAlert('Не добавлены элементы списка');
            return;
        }
        this.setState({ isFormSubmitted: true }, () => {
            if (this.isFormValid()) {
                this.props.saveAttribute(this.getAttributeToSave());
            } else {
                this.props.showWarningAlert('Не все поля корректно заполнены');
            }
        });
    }

    getAttributeTypes = () => {
        let attributes = [];
        for (const attrArr of Object.entries(customAttributesTypes)) {
            const attribute = { value: attrArr[0], label: attrArr[1] };
            attributes.push(attribute);
        }
        return attributes;
    }

    getAttribute = (type) => {
        const types = this.getAttributeTypes();
        return types.filter((x) => x.value === type);
    }

    onCancel = (e) => {
        e.preventDefault();
        this.props.cancel();
    }

    isSelectAttribute = () => {
        return this.state.attribute.type.toLowerCase() === "select" || this.state.attribute.type.toLowerCase() === "multiselect";
    }

    isSelectValid = () => {
        return this.state.attribute.payload && this.state.attribute.payload.length !== 0;
    }

    renderSelectAttributeBlock = () => {
        return (
            <>
                <div className="Attribute-line">
                    <div className="Attribute-line__input-100">
                        <Field>
                        <Input
                            type="text"
                            id="selectOption"
                            maxLength="70"
                            value={this.state.attribute.selectOption || ''}
                            onChange={e => this.handleSelectOptionChange(e.target.value)}
                            placeholder="Добавить элемент списка"
                        />
                        </Field>
                        <LinkButton
                            add
                            onClick={() => this.handleSelectOptionAdd(this.state.attribute.selectOption)}
                        />
                    </div>
                </div>
                {this.renderSelectAttributeBlockOptions(this.state.attribute.payload)}
            </>
        )
    }

    renderSelectAttributeBlockOptions = (options) => {
        return (
            <>
                {options && options.map(this.renderSelectAttributeOption)}
            </>
        )
    }

    renderSelectAttributeOption = (option) => {
        return (
            <div key={option.id} className="Attribute-line">
                <div className="Attribute-line__span-100">
                    <span>{option.value}</span>
                    <LinkButton
                        close
                        onClick={() => this.handleSelectOptionRemove(option)}
                    />
                </div>
            </div>
        );
    }

    attributeNameIsInvalid = (name) => {
        return isNullOrWhitespace(name) || (name && name.length > 70);
    }

    isFormValid = () => {
        return !this.attributeNameIsInvalid(this.state.attribute.name) 
    }

    render() {
        if (!this.state.loadComplete) {
            return null;
        }

        const {attribute} = this.state;

        const customSelectStyles = {
            control: base => ({
                ...base,
                height: 55,
                backgroundColor: '#f9f9ff',
                borderColor: '#d2d5ea',
            })
        };

        return (
            <Page>
                <div className="Attribute-wrapper">
                    <div className="Attribute-info">
                        <div className="Attribute-line">
                            <div className="Attribute-line__header">{this.isNewAttribute() ? "Новое дополнительное поле" : "Редактирование дополнительного поля"}</div>
                            <div className="Attribute-line__divider"><hr></hr></div>
                            <div className="Attribute-line__input-45">
                                <Field
                                    filled={attribute.name}
                                    required
                                    invalid={this.attributeNameIsInvalid(attribute.name)}
                                >
                                    <Label>Название</Label>
                                    <Input
                                        type="text"
                                        id="name"
                                        maxLength="70"
                                        value={attribute.name}
                                        onChange={e => this.handleStateChange('name', e.target.value)}
                                        disable={isSecurityAdmin(this.props.currentUser) ? 'true' : 'false'}
                                    />
                                </Field>
                            </div>
                            <div className="Attribute-line__select">
                                <Field>
                                <Label htmlFor="type">Тип</Label>
                                <Select
                                    value={this.getAttribute(attribute.type.toLowerCase())}
                                    inputId="selectedGroup"
                                    placeholder={<div>Выберите тип</div>}
                                    options={this.getAttributeTypes()}
                                    onChange={item => this.handleTypeChange(item.value)}
                                    styles={customSelectStyles}
                                    isDisabled={attribute.id !== 0 || isSecurityAdmin(this.props.currentUser)}
                                />
                                </Field>
                            </div>
                        </div>
                        {this.isSelectAttribute() && this.renderSelectAttributeBlock()}
                    </div>
                    {
                        isSecurityAdmin(this.props.currentUser) ? null :
                            <div className="Attribute-btn">
                                <Button onClick={this.onCancel} closeLink> Отменить</Button>                                
                                <Button onClick={this.onSubmit}>Сохранить</Button>
                            </div>
                    }
                </div>
            </Page>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const attribute = ownProps.id ? attributeFullSelector(state, ownProps.id) : newAttributeSelector();
    return {
        attribute,
        currentUser: state.auth.user,
    };
}

const actions = {
    fetchAttribute,
    newAttributeSelector,
    saveAttribute,
    showWarningAlert,
    push,
    cancel,
};

export default connect(mapStateToProps, actions)(Attribute);