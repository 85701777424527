import { Converter, extension } from 'showdown';
import TurndownService from 'turndown';

const anchorAttributes = {
    type: 'output',
    regex: /(<a.+<\/a>)\((.+=".+" ?)+\)/g,
    replace: (match, $1, $2) => {
        return $1.replace('">', `" ${$2}>`);
    }
};

extension(`anchor-extension`, {
    type: `lang`,
    filter: function (text) {
        let result = '';
        const groupsFirstLevelHeading = text.split('\n# ');
        
        for (let groupIndex = 0; groupIndex < groupsFirstLevelHeading.length; groupIndex++) {
            if (groupIndex > 0) {
                let secondLevelHeader = '';
                const groupsSecondLevelHeading = groupsFirstLevelHeading[groupIndex].split('\n## ');
                if (groupsSecondLevelHeading.length > 1) {
                    for (let i = 1; i < groupsSecondLevelHeading.length; i++) {
                        const heading = groupsSecondLevelHeading[i].substring(0, groupsSecondLevelHeading[i].indexOf('\n'));
                        secondLevelHeader += `- [${heading}](#${heading.replace(' ', '-').toLowerCase()}) \n`;
                    }
                }
                const idx = groupsFirstLevelHeading[groupIndex].indexOf('\n');
                if (secondLevelHeader) {
                    result += "# " + groupsFirstLevelHeading[groupIndex].slice(0, idx) + `\n${secondLevelHeader}` + groupsFirstLevelHeading[groupIndex].slice(idx);
                }
            }
            else {
                result += groupsFirstLevelHeading[groupIndex];
            }
            result += '\n';
        }
        return result;
    }});

extension('anchorAttributes', anchorAttributes);

export const converter = new Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
    underline: true,
    ghCompatibleHeaderId: true,
    extensions: ['anchorAttributes']
});

const Turndown = new TurndownService({});

Turndown.addRule('i', {
    filter: ['em', 'i', 'cite', 'var'],
    replacement: content => `*${content}*`,
});

Turndown.addRule('u', {
    filter: ['u'],
    replacement: content => `__${content}__`,
});

Turndown.addRule('s', {
    filter: ['s'],
    replacement: content => `~~${content}~~`,
});

Turndown.addRule('heading', {
    filter: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],

    replacement: (content, node) => {
        const hLevel = Number(node.nodeName.charAt(1));
        return `\n\n${Array(hLevel + 1).join('#')} ${content}\n\n`;
    },
});

export { Turndown };
