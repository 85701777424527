import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

const style = {
        fontSize: "33px",
        fontWeight: "400",
        marginTop: "200px",
};
const UnderConstruction = () => {
    return (
        <div style={style}>
            <Grid fluid>
                <Row>
                    <Col xs={12}>
                        <Row center="xs">
                            <Col xs={12}>Раздел находится в разработке</Col>
                        </Row>                    
                    </Col>
                </Row>
            </Grid>
        </div>
    );
};

export default UnderConstruction;