import { showWarningAlert, showErrorAlert } from 'ducks/Alert';
import { selectDictionaries } from 'ducks/Dictionary';
import { allUserDictionaries, cancel, fetchUser, newUser, newUserSelector, saveUser, userFullSelector } from 'ducks/User';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getRoles } from 'api.js'
import './EditUser.scss';
import { DictionarySelect } from "components/uikit/Select";
import CheckField from 'components/uikit/CheckField';
import {
    GROUPS_DICTIONARY_NAME,
    ROLES_DICTIONARY_NAME,
    internationalPhoneRegex,
    SUPPORT_STATUS,
    ROLE_REPORT_EDITOR_ID,
    ROLE_MODERATOR_ID,
    ROLE_LEADER_ID,
    USER_ROLES,
} from 'constants.js';
import Button from 'components/uikit/Button/';
import Page from "components/common/Page";
import Input from 'components/uikit/Input/';
import Label from "components/uikit/Label";
import Field from "components/uikit/Field";
import { isSecurityAdmin, isSupportLine1, isSupportLine2 } from 'rightsController';
import { isNullOrWhitespace, getRolesOptions } from 'utils';
import { emailValidator, passwordValidator } from 'libs/validators';
import { getMinimalPasswordLength } from 'ducks/Config';
import PhoneInput from "../../uikit/PhoneInput";

export class EditUser extends Component {
    static getDerivedStateFromProps(props, state) {
        if (state.loadComplete) {
            return null;
        }
        const { user } = props;

        let loadComplete = user.loadComplete;
        if (state.loadComplete == null) {
            loadComplete = loadComplete && user.isActual;
        }

        if (!loadComplete) {
            return { loadComplete };
        }

        const userRoles = user.roles;
        const accessibleRoles = props.dictionaries[ROLES_DICTIONARY_NAME];

        return {
            loadComplete: true,
            user: isSecurityAdmin(props.currentUser) 
                ? { ...user,  setNewPassword: false, newPassword: '' } 
                : user,
            selectedRoles: state.selectedRoles ||
                accessibleRoles.filter(r => userRoles.some(u => u.id === r.id)),
        };
    }

    state = {
        user: {},
        selectedRoles: null,
        loadComplete: null,
        isFormSubmitted: false,
        loadAppRolesComplete: false,
        appRoles: [],
        selectedSupportLevel: null,
    };

    loadRoles = async () => {
        const getRolesResponse = await this.callEffect(() => getRoles());
        this.setState({appRoles: getRolesResponse.data || [], loadAppRolesComplete: true });
    };

    componentDidMount() {
        this.loadRoles();

        if (this.state.loadComplete) {
            return;
        }

        if (this.isNewUser()) {
            this.props.newUser();
        } else {
            this.props.fetchUser(this.props.id);
        }
    }

    isNewUser() {
        return !this.props.id;
    }

    isSupport = () => {
        const { currentUser } = this.props;
        return isSupportLine1(currentUser) || isSupportLine2(currentUser);
    };

    getUserToSave = () => {
        const { user, selectedRoles } = this.state;
        const { id, lastName, firstName, middleName, email, phone, groupId, setNewPassword, newPassword, supportStatus } = user;

        return {
            id,
            lastName,
            firstName,
            middleName,
            roles: selectedRoles && selectedRoles.map(this.selectedRoleToRole),
            email,
            phone,
            groupId,
            setNewPassword,
            newPassword,
            supportStatus
        };
    };

    handleSetNewPasswordChange = (setNewPassword) => {
        this.setState(state => {
            let user = { ...state.user, setNewPassword: setNewPassword, newPassword: '' };
            
            return {
                ...state,
                user: { ...user }
            }
        });
    }

    handleStateChange = (key, value) => {
        this.setState(state => {
            const user = {
                ...state.user,
                [key]: value
            };
            return {
                ...state,
                user: { ...user }
            }
        });
    };
    
    isFormValid = () => {
        const { user } = this.state;
        if (isSecurityAdmin(this.props.currentUser)) {
            return user.setNewPassword ? this.isPasswordValid(user.newPassword) : true;
        }

        const valid = !isNullOrWhitespace(user.firstName)
            && this.isEmailValid(user.email)
            && this.isPhoneValid(user.phone);

        return valid;
    }

    isEmailValid = (email) => {
        return !isNullOrWhitespace(email) && !emailValidator()(email);
    }
    
    isPhoneValid = (phone) => {
        return !isNullOrWhitespace(phone) && phone.match(internationalPhoneRegex);
    };

    isPasswordValid = (password) => {
        const { minimalPasswordLength } = this.props;
        return !passwordValidator(minimalPasswordLength)(password);
    };

    isPasswordFieldInvalid = (user) => {
        return this.state.isFormSubmitted && user.setNewPassword && !this.isPasswordValid(user.newPassword);
    };
    
    getInputErrorKey(inputStateKey) {
        return `${inputStateKey}_error`;
    }

    getInputErrorMessage = (inputStateKey, inputErrorKey) => {
        const errorKey = inputErrorKey || this.getInputErrorKey(inputStateKey);
        return this[errorKey];
    }

    onSubmit = e => {
        e.preventDefault();
        this.setState({ isFormSubmitted: true }, () => {
            if (this.isFormValid()) {
                this.props.saveUser(this.getUserToSave());
            } else {
                this.props.showWarningAlert('Не все поля корректно заполнены');
            }
        });
    };

    onCancel = (e) => {
        const { user } = this.props;
        e.preventDefault();
        this.props.cancel(user.id);
    }

    fillSelect = (dictionary) => {
        if (dictionary) {
            return dictionary.map((x) => {
                return { value: x.id, label: x.name }
            });
        } else {
            return [];
        }
    }

    fillGroup = (groups) => {
        const group = groups.find((x) => x.id === this.state.user.groupId);
        return group ? { value: group.id, label: group.name } : null;
    }

    selectedRoleToRole = selectedRole => ({ id: selectedRole.id });
    roleToOptionValue = role => role.id;
    roleToOptionLabel = role => role.name;
    handleChangeRoles = newSelectedRoles => {
        const { user } = this.state;
        if (!user.personId && newSelectedRoles && newSelectedRoles.includes(ROLE_LEADER_ID)) {
            newSelectedRoles = newSelectedRoles.filter(x => x !== ROLE_LEADER_ID);
            this.props.showErrorAlert(`Невозможно добавить роль "${USER_ROLES.find(x => x.id === ROLE_LEADER_ID).name}" пользователю без анкеты`);
        }
        const roles = this.props.dictionaries[ROLES_DICTIONARY_NAME];
        
        if (newSelectedRoles && newSelectedRoles.includes(ROLE_REPORT_EDITOR_ID) && !newSelectedRoles.includes(ROLE_MODERATOR_ID)){
            newSelectedRoles = [...newSelectedRoles, ROLE_MODERATOR_ID]
        }

        this.setState({
            selectedRoles:
                newSelectedRoles
                    ? roles.filter(x => newSelectedRoles.includes(x.id))
                    : null
        })
    };

    callEffect = async (callback) => {
        this.props.showPageLoader();
        try {
            return await callback();
        } catch (error) {
            this.props.showErrorAlert(error.message);
        }
        finally {
            this.props.hidePageLoader();
        }
    };

    render() {
        if (!this.state.loadComplete || !this.state.loadAppRolesComplete) {
            return null;
        }

        const customSelectStyles = {
            control: base => ({
                ...base,
                height: 55,
                backgroundColor: '#f9f9ff',
                borderColor: '#d2d5ea',
                maxHeight: 200
            })
        };

        const { user, selectedRoles, appRoles } = this.state;
        const groups = this.props.dictionaries[GROUPS_DICTIONARY_NAME].filter(x => !x.isScope);
        const { currentUser, minimalPasswordLength } = this.props;

        const roles = selectedRoles
            ? getRolesOptions(selectedRoles, appRoles)
            : this.props.dictionaries[ROLES_DICTIONARY_NAME];

        return (
            <Page>
                <div className="user-wrapper">
                    <div className="user-info">
                        <div className="user-info-line">
                            <div className="user-info-line__header">
                                {this.isNewUser() ? "Новый пользователь" : "Редактирование"}
                            </div>
                            <div className="user-info-line__divider">
                                <hr></hr>
                            </div>
                        </div>
                        <div className="user-info-line">
                            <div className="user-info-line__input">
                                <Field>
                                    <Label>Фамилия</Label>
                                    <Input
                                        value={user.lastName}
                                        maxLength="100"
                                        onChange={e => this.handleStateChange('lastName', e.target.value)}
                                        disabled={isSecurityAdmin(this.props.currentUser) || this.isSupport(currentUser)}
                                    />
                                </Field>
                            </div>
                            <div className="user-info-line__input">
                                <Field invalid={isNullOrWhitespace(user.firstName)}>
                                    <Label>Имя</Label>
                                    <Input
                                        value={user.firstName}
                                        maxLength="100"
                                        onChange={e => this.handleStateChange('firstName', e.target.value)}
                                        disabled={isSecurityAdmin(this.props.currentUser) || this.isSupport(currentUser)}
                                    />
                                </Field>
                            </div>
                            <div className="user-info-line__input">
                                <Field>
                                    <Label>Отчество</Label>
                                    <Input
                                        value={user.middleName}
                                        maxLength="100"
                                        onChange={e => this.handleStateChange('middleName', e.target.value)}
                                        disabled={isSecurityAdmin(this.props.currentUser) || this.isSupport(currentUser)}
                                    />
                                </Field>
                            </div>
                        </div>
                        <div className="user-info-line">
                            <div className="user-info-line__input">
                                <Field invalid={!this.isEmailValid(user.email)}>
                                    <Label>E-mail</Label>
                                    <Input
                                        value={user.email}
                                        maxLength="100"
                                        onChange={e => this.handleStateChange('email', e.target.value.trim())}
                                        disabled={isSecurityAdmin(this.props.currentUser) || this.isSupport(currentUser)}
                                    />
                                </Field>
                            </div>
                            <div className="user-info-line__input">
                                <Field invalid={!this.isPhoneValid(user.phone)}>
                                    <Label>Телефон</Label>
                                    <PhoneInput
                                        id="phone"
                                        value={user.phone}
                                        onChange={e => this.handleStateChange('phone', e)}
                                        disabled={isSecurityAdmin(this.props.currentUser) || this.isSupport(currentUser)}
                                    />
                                    <small>* Формат телефона: +7 (123) 456-78-90</small>
                                </Field>
                            </div>
                        </div>
                        {
                            this.isNewUser() ? null :
                                <div className="user-info-line__select">
                                    <Label>Роли</Label>
                                    <DictionarySelect
                                        inputId="selectedRole"
                                        placeholder={<div>Выберите роли</div>}
                                        options={roles}
                                        value={selectedRoles && selectedRoles.map(x => x.id)}
                                        onChange={this.handleChangeRoles}
                                        isMulti
                                        styles={customSelectStyles}
                                        getOptionValue={this.roleToOptionValue}
                                        getOptionLabel={this.roleToOptionLabel}
                                        isDisabled={!isSecurityAdmin(this.props.currentUser)}
                                    />
                                </div>
                        }
                        {
                            this.isNewUser() ? null :
                                <div className="user-info-line__select">
                                    <Label>Группа</Label>
                                    <DictionarySelect
                                        inputId="selectedGroup"
                                        placeholder={<div>Выберите группу</div>}
                                        options={groups}
                                        value={user.groupId}
                                        onChange={newGroup => this.handleStateChange('groupId', newGroup)}
                                        styles={customSelectStyles}
                                        isDisabled={!isSecurityAdmin(this.props.currentUser)}
                                    />
                                </div>
                        }
                        {
                            this.isNewUser() ? null :
                                <div className="user-info-line__select">
                                    <Label>Уровень технической поддержки</Label>
                                    <DictionarySelect
                                        inputId="selectedSupportStatus"
                                        placeholder={<div>Выберите уровень технической поддержки</div>}
                                        options={SUPPORT_STATUS}
                                        value={user.supportStatus}
                                        onChange={supportStatus => this.handleStateChange('supportStatus', supportStatus)}
                                        styles={customSelectStyles}
                                        isDisabled={!this.isSupport(currentUser) || user?.id === currentUser?.id}
                                    />
                                </div>
                        }
                        {
                            !isSecurityAdmin(this.props.currentUser) ? null :
                                <div className="user-info-line">
                                    <div className="user-info-line__input">
                                        <CheckField
                                            id="setNewPassword"
                                            title="Установить пароль"
                                            checked={user.setNewPassword}
                                            onChange={setNewPassword => this.handleSetNewPasswordChange(setNewPassword)}
                                            size="100"
                                            className="set-new-password_wrap"
                                            modifier="EditUser"
                                        />
                                    </div>
                                    <div className="user-info-line__password-input">
                                        <Field required invalid={this.isPasswordFieldInvalid(user)}>
                                            <Label>Новый пароль</Label>
                                            <Input 
                                                type="password" 
                                                name="password" 
                                                id="password" 
                                                value={user.newPassword} 
                                                onChange={e => this.handleStateChange('newPassword', e.target.value)}
                                                placeholder="Новый пароль" 
                                                required
                                                disabled={!user.setNewPassword}
                                            />
                                            {
                                                !this.isPasswordFieldInvalid(user) ? null :
                                                    <div className="validation-message">
                                                        <ul>
                                                            {passwordValidator(minimalPasswordLength)(user.newPassword).map((message, index) => <li key={index}>{message}</li>)}
                                                        </ul>
                                                    </div>
                                            }
                                        </Field>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="user-wrapper__btn">
                        <Button onClick={this.onCancel} closeLink>
                            Отменить
                        </Button>
                        <Button onClick={this.onSubmit}>
                            Сохранить
                        </Button>
                    </div>
                </div>
            </Page>
        );
    }
}

EditUser.propTypes = {
    id: PropTypes.number,
    user: PropTypes.object,
    dictionaries: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    const user = ownProps.id ? userFullSelector(state, ownProps.id) : newUserSelector(state);
    const dictionaries = selectDictionaries(state.dictionary, allUserDictionaries);

    return {
        user,
        dictionaries,
        currentUser: state.auth.user,
        minimalPasswordLength: getMinimalPasswordLength(state),
    };
}

const actions = {
    showPageLoader,
    hidePageLoader,
    showWarningAlert,
    showErrorAlert,
    saveUser,
    fetchUser,
    newUser,
    cancel,
};

export default connect(mapStateToProps, actions)(EditUser);
