import React from 'react';

const CustomButtons = (props) => {

    const {buttons} = props;

    const renderButton = item => {
        switch (item.type) {
            case 'phone':
                return (
                    <a href={`tel:${item.value}`} key={item.id} className="LKModalSummaryCard__ContactButtons">
                        <p>Позвонить по телефону</p>
                        <div>{item.value}</div>
                    </a>
                );
            case 'mail':
                return (
                    <a href={`mailto:${item.value}`} key={item.id} className="LKModalSummaryCard__ContactButtons">
                        <p>Отправить письмо</p>
                        <div>{item.value}</div>
                    </a>
                );

            default:
                return null;
        }
    };

    return buttons.map(renderButton);
};

export default CustomButtons;