import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import {
    getAdminUserLeaders,
    getAdminUserEvaluationRequests,
    getAdminUserEvaluationRequestsMade,
    getAdminUserAccessRequests,
    getAdminUserAccessRequestsMade,
} from 'api';
import InfoCard from './InfoCard';
import { mainMenuIds, mainMenuItems, nameProps } from './constants';
import DataGrid from 'components/common/DataGrid';
import ChevronButton from 'components/uikit/ChevronButton';

const initialState = {
    data: [],
    paging: { pageNum: 1, pageSize: 20 },
    sorting: {},
};

class UserAddInfo extends Component {
    state = initialState;

    apiCall = async () => {
        const { paging, sorting } = this.state;
        const { userId, user, infoType } = this.props;

        const criteria = {
            filter: { groups: [user.groupId] },
            paging,
            sorting,
        };

        switch (infoType) {
            case 'leaders':
                return await getAdminUserLeaders(userId, criteria);
            case 'accessesForMe':
                const accessesForMeCriteria = {...criteria, filter: {}, sorting: {requestedOn: 'desc'}};
                return await getAdminUserAccessRequests(userId, accessesForMeCriteria);
            case 'myAccesses':
                const myAccessesCriteria = {...criteria, filter: {}, sorting: {requestedOn: 'desc'}};
                return await getAdminUserAccessRequestsMade(userId, myAccessesCriteria);
            case 'myEvaluations':
                const myEvaluations = {...criteria, filter: {}, sorting: {createdOn: 'desc'}};
                return await getAdminUserEvaluationRequestsMade(userId, myEvaluations);
            case 'evaluationsForMe':
                const evaluationsForMe = {...criteria, filter: {}, sorting: {createdOn: 'desc'}};
                return await getAdminUserEvaluationRequests(userId, evaluationsForMe);
            default:
                return;
        }
    };

    fetchData = async () => {
        const { showPageLoader, hidePageLoader, showErrorAlert } = this.props;
        try {
            showPageLoader();
            const res = await this.apiCall();
            this.setState({ data: res?.data });
        } catch (e) {
            showErrorAlert('Ошибка загрузки данных.');
        } finally {
            hidePageLoader();
        }
    };

    onPaginate = pageNum => {
        this.setState({ paging: { ...this.state.paging, pageNum: pageNum + 1 } });
    };

    withName = section => {
        switch (section) {
            case mainMenuIds.leaders:
                return true;
            default:
                return false;
        }
    };

    withSubDetails = section => {
        switch (section) {
            case mainMenuIds.leaders:
                return false;
            default:
                return true;
        }
    };

    makeColumns = () => {
        const { infoType, getPropInfo } = this.props;
        const props = mainMenuItems.find(x => x.id === infoType)?.props;

        const columns = props
            .filter(col => col.isColumn)
            .map(x => ({
                Header: x.label,
                accessor: !x.concat ? x.id : null,
                sortable: false,
                resizable: false,
                ...(x.maxWidth && { maxWidth: x.maxWidth }),
                Cell: ({ original }) => {
                    if (x.concat) {
                        const concatField = x.id.reduce((acc, curr) => {
                            return (
                                acc +
                                (x.inside ? original[x.inside][curr] + ' ' : original[curr] + ' ')
                            );
                        }, '');
                        return getPropInfo(concatField, x, original);
                    }
                    return getPropInfo(x.inside ? original[x.inside][x.id] : original[x.id], x, original);
                },
            }));

        if (this.withName(infoType)) {
            const fullNameCol = {
                Header: 'Ф.И.О',
                sortable: false,
                Cell: ({ original }) => {
                    const fullName = nameProps.reduce((acc, curr) => {
                        return acc + original[curr] + ' ';
                    }, '');
                    return fullName;
                },
            };
            columns.splice(1, 0, fullNameCol);
        }

        if (this.withSubDetails(infoType)) {
            columns.push({
                expander: true,
                width: 65,
                Expander: ({ isExpanded }) => <ChevronButton isUp={isExpanded} />,
                style: {
                    cursor: 'pointer',
                    fontSize: 25,
                    padding: '0',
                    textAlign: 'center',
                    userSelect: 'none',
                },
            });
        }

        return columns;
    };

    onSortedChange = sorted => {
        this.setState({
            sorting: { [sorted[0].id]: sorted[0].desc ? 'desc' : 'asc' },
        });
    };

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        const { sorting } = this.state;
        const { infoType } = this.props;
        if (prevProps.infoType !== infoType) {
            this.setState(initialState, () => {
                this.fetchData();
            });
        }

        if (
            prevState.paging.pageNum !== this.state.paging.pageNum ||
            JSON.stringify(prevState.sorting) !== JSON.stringify(sorting)
        ) {
            this.fetchData();
        }
    }

    changePageAmount = (pageSize) => {
        this.setState({ paging: { pageNum: 1, pageSize } }, () =>
            this.fetchData(),
        );
    };

    render() {
        const { data, paging } = this.state;
        const { header, infoType, getPropInfo } = this.props;

        const subComponent = {
            ...(this.withSubDetails(infoType)
                ? {
                      SubComponent: ({ original }) => {
                          return (
                              original && (
                                  <InfoCard
                                      key={origin.id}
                                      header=""
                                      data={original}
                                      propsArray={mainMenuItems.find(x => x.id === infoType)?.props}
                                      getPropInfo={getPropInfo}
                                      wrapped
                                  />
                              )
                          );
                      },
                  }
                : {}),
        };

        return (
            <>
                <div>{header}</div>
                {!!data?.payload && (
                    <div className="UserActions__Table">
                        <DataGrid
                            data={data.payload}
                            foundCount={data.meta.foundCount}
                            columns={this.makeColumns()}
                            showPagination
                            showPageSizeOptions={false}
                            onSortedChange={this.onSortedChange}
                            pages={data.meta.pageCount}
                            page={paging.pageNum}
                            onPageChange={this.onPaginate}
                            manual
                            {...subComponent}
                            pageSize={paging.pageSize}
                            changePageAmount={this.changePageAmount}
                        />
                    </div>
                )}
            </>
        );
    }
}

UserAddInfo.propTypes = {
    userId: PropTypes.number.isRequired,
    header: PropTypes.object,
    user: PropTypes.object,
    infoType: PropTypes.oneOf(['leaders', 'accessesForMe','myAccesses', 'evaluationsForMe', 'myEvaluations'])
        .isRequired,
    getPropInfo: PropTypes.func,
};

const actions = {
    showPageLoader,
    hidePageLoader,
    showErrorAlert,
};

export default connect(
    null,
    actions,
)(UserAddInfo);
