import React, { useState } from 'react';
import ChevronButton from 'components/uikit/ChevronButton';
import DataGrid from 'components/common/DataGrid';
import UserActionCard from 'components/Admin/UserActions/UserActionCard';
import { formatDate } from 'utils';
import { search, fetch as fetchUserAction } from 'ducks/UserActions';
import { userActionStatusName } from 'constants.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './UserActionTable.scss';

const columns = [
    {
        Header: '',
        width: 20,
    },
    {
        Header: 'Дата',
        accessor: 'created',
        resizable: false,
        maxWidth: 200,
        Cell: ({ original }) => formatDate(original.created, 'DD-MM-YYYY kk:mm:ss'),
    },
    {
        Header: 'Пользователь',
        id: 'user.userName',
        accessor: 'userName',
        resizable: false,
        maxWidth: 250,
    },
    { Header: 'Действие', accessor: 'actionName', sortable: false },
    {
        Header: 'Статус',
        accessor: 'status',
        maxWidth: 150,
        Cell: ({ original }) => statusNameResolver(original.status),
    },
    {
        expander: true,
        width: 50,
        Expander: ({ isExpanded }) => {
            return <ChevronButton isUp={isExpanded} />;
        },
        headerStyle: { textAlign: 'left' },
    },
];

const statusNameResolver = (statusValue) => {
    const result = userActionStatusName.find((x) => x.value === statusValue);
    return <div className={result && result.class}>{result && result.name}</div>;
};

const UserActionTable = (props) => {
    const [data, setData] = useState({});

    const fetchUserActions = async () => {
        const { sorting, paging, defaultUserId } = props;

        const filter = {
            userIds: !!defaultUserId && [defaultUserId],
        };

        const criteria = { filter, sorting, paging };

        const data = await props.search(criteria);

        setData({
            userActions: data.userActions,
            pageCount: data.pageCount,
            foundCount: data.foundCount,
            selectedUserAction: null,
        });
    };

    if (!props.userActions && data.userActions === undefined && !props.loading) {
        fetchUserActions();
    }

    const addDetailCard = (row, selectedUserAction) => {
        if (selectedUserAction && row.original.id === selectedUserAction.id) {
            return <UserActionCard {...selectedUserAction} />;
        }
        return null;
    };

    const handleRowExpanded = (newExpanded, [index]) => {
        if (props.handleExtendChange) {
            props.handleExtendChange(index, newExpanded);
        }
        if (newExpanded[index]) {
            const { userActions } = !props.userActions ? data : props;
            fetchUserAction(userActions[index].id);
        }
    };

    const fetchUserAction = async (userActionId) => {
        if (props.fetchUserAction) {
            const userAction = await props.fetchUserAction(userActionId);
            if (props.handleStateChange) {
                props.handleStateChange('selectedUserAction', userAction);
            }
        }
    };

    const {
        selectedUserAction,
        foundCount,
        expanded,
        pageCount,
        page,
        showPagination,
        pageSize,
        changePageAmount,
    } = props;
    return (
        <DataGrid
            data={props.userActions || data.userActions}
            selectedRow={selectedUserAction}
            foundCount={foundCount}
            columns={columns}
            showPageSizeOptions={false}
            manual
            showPagination={showPagination}
            pages={pageCount}
            page={page}
            onPageChange={props.onPageChange}
            sorted={props.getSorted}
            onSortedChange={props.onSortedChange}
            expanded={expanded}
            onExpandedChange={handleRowExpanded}
            SubComponent={(row) => addDetailCard(row, selectedUserAction)}
            pageSize={pageSize}
            changePageAmount={changePageAmount}
        />
    );
};

function mapDispatchToProps(dispatch) {
    const actions = {
        search,
        fetchUserAction,
    };
    return {
        ...bindActionCreators(actions, dispatch),
        search,
        fetchUserAction,
    };
}

export default connect(mapDispatchToProps)(UserActionTable);
