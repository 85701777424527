import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import "./BlockButton.scss";

function BlockButton(props) {
    const { 
        type,
        children, 
        onClick,
        topDelimeter,
    } = props;

    const finalClass = classnames("BlockButton", {
        "kr_profile-link-plus": type === "add",
        "kr_profile-link-close": type === "remove",
        "kr_v2": topDelimeter,
    });

    return (
        <div className={finalClass} onClick={onClick}>
            {children}
        </div>
    );
}

BlockButton.propTypes = {
    type: PropTypes.oneOf(["add", "remove"]).isRequired,
    children: PropTypes.any.isRequired,
    onClick: PropTypes.func.isRequired,
    topDelimeter: PropTypes.bool,
};

export default BlockButton;
