import React from 'react';
import classnames from 'classnames';
import Option from '../Option';
import './IconOption.scss';

function IconOption(props) {
    const { data, children, className } = props;

    const finalClass = classnames('IconOption', 'IconOption_' + data.code);

    return (
        <Option {...props} className={className}>
            <div className={finalClass}>
                <i />
                <span>{children}</span>
            </div>
        </Option>
    );
}

export default IconOption;
