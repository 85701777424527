import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import InfoField from 'components/Lk/Uikit/InfoField';
import { fetchCatalog, clearCatalog, REGION_LOCALITIES } from 'ducks/Catalog';
import StyledCheckBox from 'components/Lk/Uikit/StyledCheckBox/StyledCheckBox';
import classnames from 'classnames';
import PhoneInput from '../../Uikit/PhoneInput';
import { internationalPhoneRegex, BIRTH_DATE_DISPLAY_MODES, defaultDocumentCode, defaultForeignDocumentCode, } from 'constants.js';
import { isNullOrWhitespace } from 'utils';
import { DATE_CONFIG, YEAR_DATE_CONFIG } from './constants';
import { startOf } from 'utils';
import PastResidences from './PastResidences';
import moment from 'moment';
import { checkName } from 'libs/validators';

const PersonalInfo = props => {
    const {
        person,
        sexCatalog,
        onChange,
        isEdit,
        districts,
        regions,
        countries,
        identityDocuments,
        regionLocalities,
        clearCatalog,
        fetchCatalog,
        setWrap,
        isWrap,
    } = props;

    const isPhoneValid = phone => {
        if (isNullOrWhitespace(phone)) {
            return true;
        }
        return phone.match(internationalPhoneRegex);
    };

    const { personalInfo } = person;

    const { district, region, localityId, nationality, document, locality } = personalInfo;
    const currentWorkplace = person?.workInfo?.workPlaces.find(x => x.isActual) || {};

    const onChangeState = name => value => {
        onChange(
            { ...person, personalInfo: { ...person.personalInfo, [name]: value } },
            'PersonalInfoBlock',
        );
    };

    const onChangeDocumentType = name => value => {
        onChange(
            { ...person, personalInfo: { ...person.personalInfo, [name]: value, documentNumber: '' } },
            'PersonalInfoBlock',
        );
    };

    const onChangeDocumentNumber = name => e => {
        onChange(
            { ...person, personalInfo: { ...person.personalInfo, [name]: e.currentTarget.value } },
            'PersonalInfoBlock',
        );
    };

    const changeBirthdayMode = check => {
        const mode = check ? BIRTH_DATE_DISPLAY_MODES.Year : BIRTH_DATE_DISPLAY_MODES.FullDate;
        onChange(
            { ...person, personalInfo: { ...person.personalInfo, birthDateDisplayMode: mode } },
            'PersonalInfoBlock',
        );
    };

    const onIsRussianCitizenChanged = isRussian => {
        const documentCode = isRussian ? defaultDocumentCode : defaultForeignDocumentCode;
        onChange(
            { 
                ...person, 
                personalInfo: { 
                    ...person.personalInfo, 
                    isRussianCitizen: isRussian,
                    document: identityDocuments.find(x => x.code === documentCode),
                } 
            },
            'PersonalInfoBlock',
        );
    };

    useEffect(() => {
        if (region && isEdit) {
            fetchCatalog(REGION_LOCALITIES, [region.id], true);
        } else {
            clearCatalog(REGION_LOCALITIES);
        }
    }, [region, fetchCatalog, clearCatalog, isEdit]);

    const onChangeRegion = async value => {
        const district = districts.find(x => x.id === value.federalDistrictId);

        await onChange(
            {
                ...person,
                personalInfo: { ...person.personalInfo, district, region: value, localityId: null },
            },
            'PersonalInfoBlock',
        );
    };

    const onChangeDistrict = value => {
        onChange(
            {
                ...person,
                personalInfo: {
                    ...person.personalInfo,
                    district: value,
                    region: null,
                    localityId: null,
                },
            },
            'PersonalInfoBlock',
        );
    };

    const docMask = document && document.numberFormat;
    const shortBirthdate = personalInfo.birthDateDisplayMode === BIRTH_DATE_DISPLAY_MODES.Year;
    const docMaskLengthProp = { ...docMask ? {} : {maxLength: 50}}; 

    const Profile = () => {
        return (
            <>
                <InfoField
                    invalid={!checkName(personalInfo.lastName,true)}
                    value={personalInfo.lastName}
                    title="Фамилия"
                    required
                    onChange={onChangeState('lastName')}
                    isEdit={isEdit}
                    maxLength={100}
                    dataTest="PersonalInfo_LastName--input"
                    noIcon
                />
                <InfoField
                    value={personalInfo.firstName}
                    invalid={!checkName(personalInfo.firstName,true)}
                    title="Имя"
                    required
                    isEdit={isEdit}
                    maxLength={100}
                    onChange={onChangeState('firstName')}
                    dataTest="PersonalInfo_FirstName--input"
                    noIcon
                />
                <InfoField
                    value={personalInfo.middleName}
                    invalid={!checkName(personalInfo.middleName,false)}
                    title="Отчество"
                    isEdit={isEdit}
                    maxLength={100}
                    onChange={onChangeState('middleName')}
                    dataTest="PersonalInfo_MiddleName--input"
                    noIcon
                />
                {!isEdit && (
                    <>
                        <InfoField
                            value={currentWorkplace.position}
                            title="Должность"
                            dataTest="PersonalInfo_WorkplacePosition--text"
                            noIcon
                        />
                        <InfoField
                            value={currentWorkplace.company}
                            title="Место работы"
                            dataTest="PersonalInfo_WorkplacePosition--text"
                            noIcon
                        />
                    </>
                )}
                <InfoField
                    value={personalInfo.sex && personalInfo.sex.id}
                    title="Пол"
                    required
                    isEdit={isEdit}
                    inputProps={{
                        type: 'select',
                        items: sexCatalog,
                        onChange: onChangeState('sex'),
                    }}
                    dataTest="PersonalInfo_Sex--select"
                    noIcon
                >
                    {personalInfo.sex && personalInfo.sex.name}
                </InfoField>

                {isEdit && (
                    <InfoField
                        title="Дата рождения"
                        value={shortBirthdate ? startOf(personalInfo.birthDate, 'year') : personalInfo.birthDate ? moment(personalInfo.birthDate).format() : null }
                        isEdit={isEdit}
                        inputProps={{
                            dataTest: 'PersonalInfo_EditBirthDate--datepicker',
                            ...shortBirthdate ? YEAR_DATE_CONFIG : DATE_CONFIG,
                            showYearPicker: shortBirthdate,
                        }}
                        type="date"
                        onChange={onChangeState('birthDate')}
                        noIcon
                        footer={
                            <StyledCheckBox
                                title="ДР не определена"
                                checked={shortBirthdate}
                                onChange={changeBirthdayMode}
                            />                        
                        }
                    >
                    </InfoField>
                )}
                {!isEdit && (
                    <InfoField
                        value={personalInfo.age}
                        title="Возраст"
                        type="number"
                        isEdit={false}
                        onChange={onChangeState('age')}
                        dataTest="PersonalInfo_Age--input"
                        noIcon
                    />
                )}
                <InfoField noIcon>
                    <StyledCheckBox
                        title="Гражданин РФ"
                        checked={personalInfo.isRussianCitizen}
                        onChange={isEdit ? onIsRussianCitizenChanged : undefined}
                        dataTest="PersonalInfo_IsRussianCitizen--checkbox"
                    />
                </InfoField>
                {!personalInfo.isRussianCitizen && (
                    <InfoField noIcon>
                        <StyledCheckBox
                            title="Готов к переезду в РФ"
                            checked={personalInfo.readyMoveToRussia}
                            onChange={isEdit ? onChangeState('readyMoveToRussia') : undefined}
                            dataTest="PersonalInfo_ReadyMoveToRussia--checkbox"
                        />
                    </InfoField>
                )}
                {personalInfo.isRussianCitizen && (
                    <InfoField
                        value={district && district.id}
                        title="Федеральный округ"
                        required
                        isEdit={isEdit}
                        inputProps={{
                            type: 'select',
                            items: districts,
                            onChange: onChangeDistrict,
                        }}
                        dataTest="PersonalInfo_District--select"
                        noIcon
                    >
                        {district && district.name}
                    </InfoField>
                )}
                {personalInfo.isRussianCitizen && (
                    <>
                        <InfoField
                            value={region && region.id}
                            title="Регион"
                            required
                            isEdit={isEdit}
                            inputProps={{
                                type: 'select',
                                items: regions,
                                onChange: onChangeRegion,
                            }}
                            dataTest="PersonalInfo_Region--select"
                            noIcon
                        >
                            {region && region.name}
                        </InfoField>
                        <InfoField
                            value={localityId}
                            title="Населенный пункт"
                            isEdit={isEdit}
                            inputProps={{
                                type: 'select',
                                items: regionLocalities,
                                onChange: e => e.id && onChangeState('localityId')(e.id),
                            }}
                            dataTest="PersonalInfo_Locality--select"
                            noIcon
                        >
                            {locality?.name}
                        </InfoField>
                        <InfoField
                            value={personalInfo.residenceStartYear || ''}
                            title="Год начала проживания"
                            type="number"
                            onChange={onChangeState('residenceStartYear')}
                            isEdit={isEdit}
                            maxLength={4}
                            dataTest="PersonalInfo_ResidenceStartYear--input"
                            noIcon
                        />                         
                    </>
                )}
                {!personalInfo.isRussianCitizen && (
                    <>
                        <InfoField
                            value={nationality && nationality.id}
                            title="Гражданство"
                            required
                            isEdit={isEdit}
                            inputProps={{
                                type: 'select',
                                items: countries,
                                onChange: onChangeState('nationality'),
                            }}
                            dataTest="PersonalInfo_Nationality--select"
                            noIcon
                        >
                            {nationality && nationality.name}
                        </InfoField>
                        <InfoField
                            value={personalInfo.foreignRegion || ''}
                            title="Регион"
                            onChange={onChangeState('foreignRegion')}
                            isEdit={isEdit}
                            maxLength={200}
                            dataTest="PersonalInfo_ForeignRegion--input"
                            noIcon
                        />
                        <InfoField
                            value={personalInfo.foreignLocality || ''}
                            title="Населенный пункт"
                            onChange={onChangeState('foreignLocality')}
                            isEdit={isEdit}
                            maxLength={200}
                            dataTest="PersonalInfo_ForeignLocality--input"
                            noIcon
                        />
                        <InfoField
                            value={personalInfo.residenceStartYear || ''}
                            title="Год начала проживания"
                            type="number"
                            onChange={onChangeState('residenceStartYear')}
                            isEdit={isEdit}
                            maxLength={4}
                            dataTest="PersonalInfo_ResidenceStartYear--input"
                            noIcon
                        />                                                        
                    </>
                )}
                <InfoField
                    value={personalInfo.extraInfo}
                    title="Биографическая справка"
                    isEdit={isEdit}
                    onChange={onChangeState('extraInfo')}
                    dataTest="PersonalInfo_ExtraInfo--input"
                    maxLength={4000}
                    noIcon
                />
                <InfoField
                    value={personalInfo.phone}
                    svgIcon={{ name: 'phone', fill: '#0A84FF' }}
                    invalid={!isPhoneValid(personalInfo.phone)}
                    editComponent={
                        <PhoneInput
                            value={personalInfo.phone}
                            onChange={onChangeState('phone')}
                            dataTest="PersonalInfo_PhoneInput"
                        />
                    }
                    isEdit={isEdit}
                    onChange={onChangeState('phone')}
                />
                <InfoField
                    value={personalInfo.email}
                    svgIcon={{ name: 'mail', fill: '#0A84FF' }}
                    isEdit={isEdit}
                    onChange={onChangeState('email')}
                    dataTest="PersonalInfo_Email--input"
                />
                <InfoField
                    value={document && document.id}
                    title="Документ"
                    required
                    isEdit={isEdit}
                    inputProps={{
                        type: 'select',
                        items: identityDocuments,
                        onChange: onChangeDocumentType('document'),
                    }}
                    dataTest="PersonalInfo_Document--select"
                    noIcon
                >
                    {document && document.name}
                </InfoField>
                <InfoField
                    value={personalInfo.documentNumber}
                    title="Номер документа"
                    isEdit={isEdit}
                    onChange={docMask ? onChangeDocumentNumber('documentNumber') : onChangeState('documentNumber')}
                    dataTest="PersonalInfo_DocumentNumber--input"
                    noIcon
                    {...docMaskLengthProp}
                    type={docMask ? 'inputMask' : 'text'}
                    mask={docMask}
                    maskchar='_'
                />
                {document && document.label === 'Другое' && (
                    <InfoField
                        value={personalInfo.documentName}
                        required
                        title="Название документа"
                        isEdit={isEdit}
                        onChange={onChangeState('documentName')}
                        dataTest="PersonalInfo_DocumentName--input"
                        noIcon
                        maxLength={100}
                    />
                )}
                <InfoField
                    value={personalInfo.birthPlace}
                    title="Место рождения"
                    isEdit={isEdit}
                    onChange={onChangeState('birthPlace')}
                    dataTest="PersonalInfo_BirthPlace--input"
                    maxLength={100}
                    noIcon
                />            
            </>
        );
    };

    return (
        <div className={classnames('PersonalInfo', { 'PersonalInfo--bottom-margin': isEdit })}>
            {!isWrap && Profile()}
            <PastResidences 
                isEdit={isEdit} 
                data={personalInfo.pastResidences} 
                onChange={onChange}
                person={person}
                setWrap={setWrap}
                isWrap={isWrap}
                districts={districts}
                regions={regions}
            />
        </div>
    );
};
const mapStateToProps = (state) => ({
    sexCatalog: state.catalogs.sexCatalog.data,
    districts: state.catalogs.federalDistricts.data,
    regions: state.catalogs.regions.data,
    countries: state.catalogs.countries.data,
    identityDocuments: state.catalogs.identityDocuments.data,
    regionLocalities: state.catalogs.regionLocalities.data,
});

const actions = { fetchCatalog, clearCatalog };

export default connect(
    mapStateToProps,
    actions,
)(PersonalInfo);
