import React from 'react';
import { connect } from 'react-redux';
import { fetchPersonAccesses, reviewAccessRequest } from 'ducks/Access';
import Page from 'components/common/Page';
import ListTemplate from 'components/uikit/ListTemplate';
import DataGrid from 'components/common/DataGrid';
import Button from 'components/uikit/Button';
import { isITAdmin, isSecurityAdmin } from 'rightsController';
import { ACCESS_STATUS, ACCESS_STATUS_TEXT, USER_ROLES } from 'constants.js';
import RejectModal from './RejectModal';
import { getUserFullName } from 'utils';
import moment from 'moment';
import Popover from 'components/Lk/Uikit/Popover';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'components/common/Loader';
import './Access.scss';

const baseColumns = ({ renderButtonsColumn }) => [
    {
        Header: '',
        width: 20,
    },
    {
        Header: () => <div data-test="Access_RequestDateHeader--text">Дата подачи заявки</div>,
        accessor: 'requestedOn',
        width: 180,
        resizable: false,
        sortable: true,
        Cell: ({ original }) => original.requestedOn && moment(original.requestedOn).format('L'),
    },
    {
        Header: 'Пользователь',
        resizable: false,
        sortable: false,
        Cell: ({ original }) => original.person && getUserFullName(original.person),
    },
    {
        Header: 'Телефон',
        accessor: 'userInfoSnapshot.phoneNumber.e164Value',
        resizable: false,
        sortable: false,
    },
    {
        Header: 'E-mail',
        accessor: 'userInfoSnapshot.email',
        resizable: false,
        sortable: false,
    },
    {
        Header: () => (
            <div
                className="Access__RequestDirectionHeader"
                data-test="Access_RequestDirectionHeader--text"
            >
                Тип заявки
            </div>
        ),
        accessor: 'accessRequestDirection',
        minWidth: 90,
        maxWidth: 90,
        resizable: false,
        sortable: true,
        Cell: ({ original }) => {
            return original.accessRequestDirection === 'AddAccess' ? (
                <div className="Access__RequestDirectionTableValue">
                    <Popover
                        tooltip={'Заявка на добавление роли'}
                        autoPlacement={false}
                        tooltipPlacement={'top'}
                        childrenClassName={'CenterContent'}
                    >
                        <FontAwesomeIcon icon={faPlusCircle} size="lg" color={'#207245'} />
                    </Popover>
                </div>
            ) : (
                <div className="Access__RequestDirectionTableValue">
                    <Popover
                        tooltip={'Заявка на исключение роли'}
                        autoPlacement={false}
                        tooltipPlacement={'top'}
                        childrenClassName={'CenterContent'}
                    >
                        <FontAwesomeIcon icon={faMinusCircle} size="lg" color={'#cb4a32'} />
                    </Popover>
                </div>
            );
        },
    },
    {
        Header: 'Роль',
        minWidth: 160,
        maxWidth: 160,
        resizable: false,
        sortable: false,
        Cell: ({ original }) => {
            return (
                <>
                    <span>{original.userInfoSnapshot.role.title} </span>
                    <br />
                    {!original.userInfoSnapshot.role.isActive && (
                        <div className="Access__Info--reject">Роль не активна</div>
                    )}
                    {original.accessRequestDirection === 'RemoveAccess' &&
                        original.roleRemoveReason && (
                            <div>
                                <Popover
                                    childrenClassName={'InlineFlex'}
                                    tooltip={original.roleRemoveReason}
                                    autoPlacement={false}
                                    tooltipPlacement={'top'}
                                >
                                    (&nbsp;<span className="Access__RoleRemoveInfo">причина</span>
                                    &nbsp;)
                                </Popover>
                            </div>
                        )}
                </>
            );
        },
    },

    {
        Header: () => <div className="Access__RequestAuthor">Региональный координатор</div>,
        resizable: false,
        sortable: false,
        Cell: ({ original }) => {
            return (
                <span>
                    {original.requester && getUserFullName(original.requester)}
                    {original.requester &&
                        original.requester.group &&
                        ` (${original.requester.group.name})`}
                </span>
            );
        },
    },
    {
        Header: 'Статус заявки',
        resizable: false,
        sortable: false,
        Cell: ({ original }) => {
            return <>{renderButtonsColumn(original)}</>;
        },
    },
];

export class Access extends React.Component {
    state = {
        filter: null,
        sorting: { requestedOn: 'Desc' },
        sorted: [{ id: 'requestedOn', desc: false }],
        paging: { pageNum: 1, pageSize: 20 },
        columns: baseColumns,
        isRejectModalOpen: false,
        editId: null,
    };

    componentDidMount() {
        const {
            access: { accesses },
            fetchPersonAccesses,
        } = this.props;

        const { filter, sorting, paging } = this.state;

        if (accesses.length === 0) {
            fetchPersonAccesses({ filter, sorting, paging });
        }
    }

    componentDidUpdate(prevProps) {
        const {
            access: { needUpdate },
            fetchPersonAccesses,
        } = this.props;

        if (needUpdate && needUpdate !== prevProps.access.needUpdate) {
            const { filter, sorting, paging } = this.state;
            fetchPersonAccesses({ filter, sorting, paging });
        }
    }

    renderButtonsColumn = (original) => {
        const {
            user,
            access: { loading },
        } = this.props;
        let isVisible = true;

        if (isITAdmin(user)) {
            isVisible = original.status === ACCESS_STATUS.WAITING_FOR_REVIEW;
        } else if (isSecurityAdmin(user)) {
            isVisible = original.status === ACCESS_STATUS.APPROVED_BY_IT;
        }

        isVisible =
            isVisible &&
            [ACCESS_STATUS.REJECTED_BY_IT, ACCESS_STATUS.REJECTED_BY_SECURITY].indexOf(
                original.status,
            ) === -1;

        if (!isVisible) {
            const renderStatusTest = (original) => {
                let StatusText = ACCESS_STATUS_TEXT[original.status];
                if (
                    original.status === ACCESS_STATUS.REJECTED_BY_SECURITY ||
                    original.status === ACCESS_STATUS.REJECTED_BY_IT ||
                    original.status === ACCESS_STATUS.FAILED_TO_CREATE_USER
                ) {
                    return (
                        <div className="Access__Info  Access__Info--reject">
                            {StatusText +
                                ` от ${moment(
                                    original.ReviewedBySecurityOn || original.ReviewedByITOn,
                                ).format('DD MMM Y')} `}
                            {original.rejectionComment && (
                                <Popover
                                    childrenClassName={'InlineFlex'}
                                    tooltip={original.rejectionComment}
                                    autoPlacement={false}
                                    tooltipPlacement={'top'}
                                >
                                    (&nbsp;
                                    <span className="AccessReject__Description">причина</span>
                                    &nbsp;)
                                </Popover>
                            )}
                        </div>
                    );
                } else if (original.status === ACCESS_STATUS.WAITING_FOR_REVIEW) {
                    return (
                        <div className="Access__Info Access__Info--review">
                            {StatusText + ` от ${moment(original.requestedOn).format('DD MMM Y')}`}
                        </div>
                    );
                } else if (original.status === ACCESS_STATUS.CANCELLED) {
                    return (
                        <div className="Access__Info Access__Info--review">
                            {`Отменена  ${moment(original.requestedOn).format('DD MMM Y')}`}
                        </div>
                    );
                } else {
                    return (
                        <div className="Access__Info Access__Info--approve">
                            {StatusText +
                                ` от ${moment(
                                    original.finishedOn ||
                                        original.ApprovedByIT ||
                                        original.ApprovedBySecurity ||
                                        original.UserCreatedOrLinked,
                                ).format('DD MMM Y')}`}
                        </div>
                    );
                }
            };

            return renderStatusTest(original);
        }

        const { accesses } = this.props.access;
        const accessRequest = accesses && accesses.find((x) => x.id === original.id);
        const accessRequestDirection = accessRequest && accessRequest.accessRequestDirection;
        const isRoleActive = USER_ROLES.find((x) => x.id === original?.userInfoSnapshot?.role?.id)
            ?.isActive;

        return (
            <div className="AccessWrapper__Buttons">
                {isRoleActive && (
                    <Button
                        disabled={loading}
                        onClick={this.onReview({ accessRequestId: original.id })}
                        size="xxs"
                    >
                        {accessRequestDirection === 'AddAccess' ? 'Добавить' : 'Удалить'}
                    </Button>
                )}
                <Button
                    color="danger"
                    disabled={loading}
                    onClick={this.onReject(original.id)}
                    size="xxs"
                >
                    Отклонить
                </Button>
            </div>
        );
    };

    onPageChange = (pageIndex) => {
        const { filter, sorting, paging } = this.state;
        const newPaging = { ...paging, pageNum: pageIndex + 1 };

        this.setState({ paging: newPaging }, () =>
            this.props.fetchPersonAccesses({ filter, sorting, paging: newPaging }),
        );
    };

    changePageAmount = (pageSize) => {
        const { filter, sorting, paging } = this.state;
        const newPaging = { ...paging, pageSize };

        this.setState({ paging: { pageNum: 1, pageSize } }, () =>
            this.props.fetchPersonAccesses({ filter, sorting, paging: newPaging }),
        );
    };

    onSortedChange = (sorted) => {
        const { filter, paging } = this.state;

        const newSorting = { [sorted[0].id]: sorted[0].desc ? 'Asc' : 'Desc' };

        this.setState({ sorted, sorting: newSorting }, () =>
            this.props.fetchPersonAccesses({ filter, sorting: newSorting, paging }),
        );
    };

    onReject = (id) => () => {
        this.setState({ isRejectModalOpen: true, editId: id });
    };

    onReview = (data) => () => this.onReviewRequest(data);

    onReviewRequest = (data) => this.props.reviewAccessRequest(data);

    onClose = () => this.setState({ isRejectModalOpen: false, editId: null });

    render() {
        const { access, accesses } = this.props;
        const { meta, loading } = access;

        const { paging, isRejectModalOpen, editId, sorted } = this.state;

        return (
            <Page>
                <div className="AccessWrapper__Table">
                    <ListTemplate title={' '}>
                        <DataGrid
                            data={accesses}
                            foundCount={meta.foundCount}
                            columns={baseColumns({ renderButtonsColumn: this.renderButtonsColumn })}
                            showPageSizeOptions={false}
                            sorted={sorted}
                            onSortedChange={this.onSortedChange}
                            showPagination={!!meta.pageCount}
                            pages={meta.pageCount}
                            page={paging.pageNum}
                            onPageChange={this.onPageChange}
                            manual
                            pageSize={paging.pageSize}
                            changePageAmount={this.changePageAmount}
                        />
                    </ListTemplate>
                </div>
                <RejectModal
                    isOpen={isRejectModalOpen}
                    accessRequestId={editId}
                    onClose={this.onClose}
                    onReview={this.onReviewRequest}
                />
                <Loader overlay show={loading} />
            </Page>
        );
    }
}

const mapStateToProps = ({ access, auth, dictionary }) => {
    const accesses =
        access.accesses &&
        access.accesses.map((x) => {
            return {
                ...x,
                requester: {
                    ...x.requester,
                    group:
                        dictionary.groups &&
                        dictionary.groups.dictionary &&
                        dictionary.groups.dictionary.find((e) => e.id === x.requester.groupId),
                },
            };
        });
    return {
        user: auth.user,
        groups: dictionary.groups,
        accesses,
        access,
    };
};

export default connect(mapStateToProps, {
    fetchPersonAccesses,
    reviewAccessRequest,
})(Access);
