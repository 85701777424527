import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

const DirectionType = {
    horizontal: ['chevron-right', 'chevron-left'],
    rightUp: ['chevron-right', 'chevron-up'],
    vertical: ['chevron-down', 'chevron-up'],
};

const ExpandedBlock = (props) => {
    const {
        header = '',
        subHeader = '',
        direction = 'horizontal',
        fitted,
        footer,
        actions,
    } = props;
    const [isExpanded, setIsExpanded] = useState(false);
    const iconType = DirectionType[direction];
    const onExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={classnames('LKExpandedBlock', { 'LKExpandedBlock--fitted': fitted })}>
            <div className="LKExpandedBlock__Container" onClick={onExpand}>
                <div className="LKExpandedBlock__Header">
                    <div className="LKExpandedBlock__HeaderWrapper">    
                        {header}
                    </div>
                    <div className="LKExpandedBlock__Header--subheader">{subHeader}</div>
                </div>

                <div className="LKExpandedBlock__Expander">
                    <FontAwesomeIcon icon={iconType[+isExpanded]} />
                </div>
            </div>
            {isExpanded && (
                <>
                    {actions}
                    <div className="LKExpandedBlock__Data">{props.children}</div>
                </>
            )}
            {footer}
        </div>
    );
};

export default ExpandedBlock;
