import React from 'react';
import { Row, Col } from 'react-flexbox-grid';
import PropTypes from 'prop-types';

const CardFooter = (props) => {
    const { hasButtons, actionButtons } = props;

    return (
        <div className="Card__Footer">                    
            {hasButtons 
            ?   <>
                <Col xs={10}>{props.children}</Col>
                <Col xs={2}>
                    <Row end="xs">
                        <Col xs={12}>
                            {actionButtons}
                        </Col>
                    </Row>
                </Col>
                </>
            :
                <Col xs={12}>
                    {props.children}
                </Col>
            }
        </div>
    );
};

CardFooter.propTypes = {
    hasButtons: PropTypes.bool,
    actionButtons: PropTypes.any,
};

export default CardFooter;