import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { fetchTeams } from 'ducks/Teams';
import { ACTIVE_LIST_CARD } from 'components/Lk/Basic/constants';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';
import CardItemBlock from 'components/Lk/Common/CardItemBlock';
import CardItemPerson from 'components/Lk/Common/CardItemPerson';
import CardItemPersonData from 'components/Lk/Common/CardItemPerson/CardItemPersonData';
import { DataCard } from 'components/Lk/Uikit/DataCard';
import uuid from 'uuid/v4';
import Loader from 'components/common/Loader';
import classnames from 'classnames';
import { getDashboardTeamGrowth } from 'api';
import { lkWidgetsTeamGrowthProfile } from 'routes';
import { getUserFullName } from 'utils';
import { wordForm } from 'utils';
import './Widgets.scss';
import { fetchTeamGrowth } from 'ducks/Dashboard';

const initialState = {
    loaded: false,
    updated: false,
    data: [],
    teams: [],
};

class TeamGrowth extends Component {
    _isMounted = false;

    state = initialState;

    fetchData = async () => {
        try {
            this.props.fetchTeams(this.props.personId);
            const result = await getDashboardTeamGrowth(this.props.personId);
            this.props.fetchTeamGrowth(this.props.personId);

            this._isMounted &&
                this.setState({
                    loaded: true,
                    data: result.data,
                });
        } catch {
            this._isMounted &&
                this.setState({
                    loaded: true,
                });
        }
    };

    componentDidMount() {
        this._isMounted = true;
        this.fetchData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    static getDerivedStateFromProps(props, state) {
        if (state.loaded && props.teams && !state.updated) {
            return {
                updated: true,
                teams: props.teams.map(team => ({
                    ...team,
                    followerPersons: team.followerPersons.map(person => {
                        const percentItem = state.data.find(
                            x => x.teamId === team.id && x.personId === person.id,
                        );

                        return {
                            ...person,
                            percent: percentItem && percentItem.percent,
                        };
                    }),
                })),
            };
        }

        return null;
    }

    renderTeam() {
        if (!this.state.teams.some(x => x.followerPersons?.length > 0)) {
            return (
                <div className="FavoritesSearchCard__NotFound">В Ваших командах нет участников</div>
            );
        }
        return this.state.teams.map(x => {
            if (x.followerPersons && x.followerPersons.length !== 0) {
                return (
                    <DataCard
                        key={x.id}
                        title={x.name || 'Команда'}
                        className="LKDataCard--Line"
                        titleModifire="accordion"
                        defaultIsOpen
                    >
                        {x.followerPersons.map(p => (
                            <CardItemBlock key={p.id}>
                                <CardItemPerson
                                    profileRoute={lkWidgetsTeamGrowthProfile}
                                    clickable
                                    id={uuid()}
                                    person={p}
                                >
                                    <CardItemPersonData
                                        normalText
                                        noData={!p.percent}
                                        data={getUserFullName(p)}
                                        text={
                                            p.percent
                                                ? `План реализован на ${Math.round(p.percent)}%`
                                                : 'Нет данных'
                                        }
                                    />
                                </CardItemPerson>
                            </CardItemBlock>
                        ))}
                    </DataCard>
                );
            }
            return null;
        });
    }

    renderContent() {
        const { teamGrowth } = this.props;

        if (
            !this.state.teams ||
            !Array.isArray(this.state.teams) ||
            this.state.teams.length === 0
        ) {
            return (
                <div className="FavoritesSearchCardDetails">
                    <h3 className="LKLayout__Title">У вас нет ни одной команды</h3>
                </div>
            );
        }

        if (!this.state.teams.some(x => x.followerPersons?.length > 0)) {
            return (
                <div className="FavoritesSearchCardDetails">
                    <h3 className="LKLayout__Title">В Ваших командах нет участников</h3>
                </div>
            );
        }

        return (
            <div className="FavoritesSearchCardDetails">
                {teamGrowth && teamGrowth.amount !== undefined && teamGrowth.amount > 0 ? (
                    <h3 className="LKLayout__Title">
                        {`Индивидуальный план развития составлен у ${
                            teamGrowth.amount
                        } (${teamGrowth.percent.toFixed(2)}%) ${wordForm(teamGrowth.amount, [
                            'резервиста',
                            'резервистов',
                            'резервистов',
                        ])}:`}
                    </h3>
                ) : (
                    <h3 className="LKLayout__Title">
                        У резервистов Ваших команд не составлен план развития
                    </h3>
                )}

                {this.renderTeam()}
            </div>
        );
    }

    render() {
        const { activeCard, setActiveCard } = this.props;
        const isMobileActive = activeCard === ACTIVE_LIST_CARD;
        setActiveCard(ACTIVE_LIST_CARD);

        return (
            <>
                <div
                    className={classnames('ListCard FavoritesSearchCard', {
                        'ListCard--isMobileActive': isMobileActive,
                        'FavoritesSearchCard--isMobileActive': isMobileActive,
                    })}
                >
                    <MainCardMenu defaultMenuId="favorite" />
                    {!this.state.loaded && !this.state.updated && <Loader />}

                    {this.state.loaded && <>{this.renderContent()}</>}
                </div>
            </>
        );
    }
}

TeamGrowth.propTypes = {
    activeCard: PropTypes.string.isRequired,
    setActiveCard: PropTypes.func.isRequired,
};

const actions = { push, fetchTeams, fetchTeamGrowth };

const props = state => {
    const teams = state.teams.isLoaded && state.teams.leading;

    return {
        personId: state.auth && state.auth.user && state.auth.user.personId,
        teams,
        teamGrowth: state.dashboard.teamGrowth,
    };
};

export default connect(
    props,
    actions,
)(TeamGrowth);
