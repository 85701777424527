import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { setSearchFilter } from '../utils';
import Field from 'components/Lk/Uikit/Field';
import Select from 'components/Lk/Uikit/Select/CustomSelect';
import SearchGroupCondition from '../SearchGroupCondition';
import ActiveFilters from '../ActiveFilters';
import uuid from 'uuid/v4';
import { connect } from 'react-redux';
import { queryOperation } from 'components/Search/QueryOperation';
import SuccessButton from '../SuccessButton';
import { FIELDS_TYPE } from '../SearchFilters/constants';
import FilterField from './FilterField';
import StyledCheckBox from 'components/Lk/Uikit/StyledCheckBox';
import moment from 'moment';
import Button from 'components/Lk/Uikit/Buttons/Button';
import { debounce } from 'lodash';

const FilterTemplate = props => {
    const {
        selectStyles,
        setFiltersState,
        updateFiltersWithCondition,
        getGroupCondition,
        mainFilter,
        activeFilter,
        onSuccess,
        filterId,
        catalogs,
        fields,
        extraFields,
        setCondition,
        condition,
        showCondition = true,
        defaultFilters,
        setDefaultFilters,
        isFocused,
        withCurrent,
        loadCatalogs,
    } = props;

    const [state, setState] = useState({});
    const [visibleSection, setVisibleSection] = useState(null);
    const [groupCondition, changeGroupCondition] = useState(
        condition || getGroupCondition(filterId, withCurrent),
    );
    const [excludeFromSearch, setExcludeFromSearch] = useState(false);

    const setGroupCondition = con => {
        changeGroupCondition(con);
        setCondition && setCondition(con);
    };

    const handleCriteriaCHange = e => {
        setVisibleSection(e);
    };

    const clearFields = () => {
        setState({});
        setExcludeFromSearch(false);
        setVisibleSection(null);
    };

    const onSubmit = async isClose => {
        await AddFilter();
        clearFields();
        isClose && onSuccess();
        !isClose && props.onAddFilter && props.onAddFilter();
    };

    const isDefaultFilter = activeFilter => {
        return defaultFilters.find(x => x.isDefault && x.value === activeFilter.section);
    };

    const isOnceFilter = activeFilter => {
        return activeFilter && fields.find(x => x.once && x.value === activeFilter.section);
    };

    const countActiveDefaultFilters = defaultFilters => {
        return defaultFilters.reduce((res, val) => {
            if (val.child) {
                state[val.value] && res++;
                res = res + countActiveDefaultFilters(val.child);
            } else {
                state[val.value] && res++;
            }
            return res;
        }, 0);
    };

    const onChange = (state, isMainChange = false) => {
        setState(x => ({ ...x, ...state }));
        props.onChange && props.onChange(state, isMainChange);
    };

    const onChangeField = (name, updateDependence, child = null, isMainChange = false) => value => {
        if (updateDependence && value) {
            const updateValue = updateDependence.catalog
                ? getCatalogValue(updateDependence.catalog, value[updateDependence.catalogKey], updateDependence.catalogProp ? updateDependence.catalogProp : 'id')
                : null;
            
            if (child) {
                const parentDependence = child && child.find(x => x.value === updateDependence.stateField);
                if (parentDependence) {
                    onChangeField(updateDependence.stateField, parentDependence.updateDependence)(updateValue);
                }
            } else {
                onChange({ [updateDependence.stateField]: updateValue });
            }
        }
        if (updateDependence?.clearable) {
            onChange({
                ...updateDependence.clearable.reduce((cur, name) => ({ ...cur, [name]: null }), {}),
            });
        }
        onChange({ [name]: value }, isMainChange);
    };
    
    const onInputChangeField = (name) => value => {
        props.onInputChange && props.onInputChange({ [name]: value })
    };

    const onFocusField = (value) => {
        props.onFocusField && props.onFocusField(value)
    };

    const getGroupValues = (filters, groupId) => {
        return filters.reduce(
            (x, g) =>
                g.childs
                    ? x.concat(getGroupValues(g.childs, groupId))
                    : x.concat(g.group === groupId ? g : []),
            [],
        );
    };

    useEffect(() => {
        setDefaultFilters(fields.filter(x => x.isDefault));
    }, [fields, setDefaultFilters]);


    useEffect(() => {
        const setFields = (section, value) => {
            if (!value || !section) {
                return;
            }

            if (section.type === FIELDS_TYPE.year) {
                onChange({ [section.value]: new Date(value).getFullYear() });
            } else if (section.type === FIELDS_TYPE.multiSelect) {
               const val = catalogs[section?.catalog]?.data?.filter(x =>
                    section.isValueName
                        ? activeFilter.values.indexOf(x.name) >= 0
                        : activeFilter.values.indexOf(x.id) >= 0,
                );

                onChange({ [section.value]: val });
            } else onChange({ [section.value]: value });
        };

        if (activeFilter === null) {
            setVisibleSection(null);
            return;
        }

        const section = fields?.find(x => x.value === activeFilter.section);
        setVisibleSection(section);

        if (section?.child) {
            const values = getGroupValues(mainFilter, activeFilter.group);

            section.child.forEach(x => {
                const child = values.find(v => v.field === x.column && v.operation === x.query);
                setFields(x, child?.value);
                if (child?.excludeFromSearch) {
                    setExcludeFromSearch(child?.excludeFromSearch);
                }
            });
        } else {
            setExcludeFromSearch(activeFilter.excludeFromSearch);
            setFields(section, activeFilter.value || activeFilter.values);
        }
        // eslint-disable-next-line
    }, [activeFilter, mainFilter, loadCatalogs]);

    const buttonEnabled =
        (visibleSection && state[visibleSection.value]) ||
        visibleSection?.child?.some(x => state[x.value]) ||
        countActiveDefaultFilters(defaultFilters) !== 0;

    const AddFilter = () => {
        const getValues = x => {
            if (state[(x?.value)]) {
                switch (x.type) {
                    case FIELDS_TYPE.select:
                        const record = catalogs[x.catalog]?.data.find(
                            c => c.id === (state[(x?.value)]?.value || state[(x?.value)]),
                        );
                        return record
                            ? { name: record.name, value: record.id, type: x.type }
                            : { name: null, value: null, type: x.type };

                    case FIELDS_TYPE.multiSelect:
                        const ids = state[(x?.value)].map(x => x?.id || x);
                        const records = catalogs[x.catalog]?.data.filter(
                            c => ids?.indexOf(c.id) >= 0,
                        );
                        return records?.length > 0
                            ? {
                                  name: records.map(x => x.name).join(', '),
                                  value: ids,
                                  type: x.type,
                              }
                            : { name: null, value: null, type: x.type };

                    case FIELDS_TYPE.year:
                        return state[(x?.value)]
                            ? {
                                  name: state[(x?.value)],
                                  value: x.lastDay
                                      ? moment(new Date(state[(x?.value)], 11, 31)).format(
                                            'YYYY-MM-DD',
                                        )
                                      : moment(new Date(state[(x?.value)], 0, 1)).format(
                                            'YYYY-MM-DD',
                                        ),
                                  type: x.type,
                              }
                            : { name: null, value: null, type: x.type };
                    default:
                        return { name: state[(x?.value)], value: state[(x?.value)] };
                }
            } else {
                return { name: null, value: null };
            }
        };

        let res = null;
        const group = uuid();

        if (buttonEnabled && (defaultFilters.length !== 0 || visibleSection)) {
            const makeDefaultFiltersChild = (defaultFilters, section = '') => {
                return defaultFilters.reduce((res, val) => {
                    if (val.child) {
                        state[val.value] && res.concat(val);
                        res = res.concat(makeDefaultFiltersChild(val.child, val.section));
                    } else {
                        if (state[val.value])
                            res = res.concat({ ...val, section: section || val.section });
                    }
                    return res;
                }, []);
            };

            const defaultFiltersChild = makeDefaultFiltersChild(defaultFilters);

            let resChild = [];
            let section = null;

            if (visibleSection?.child) {
                const filteredVisualSection = visibleSection.child.filter(
                    x => !defaultFiltersChild.find(v => v.column === x.column),
                );
                const resChild = filteredVisualSection.concat(defaultFiltersChild);
                res = resChild
                    .map(x => {
                        const {
                            label,
                            column,
                            table,
                            query = queryOperation.Equal,
                            type,
                            isDefault = false,
                        } = x;
                        const { name = '', value = null } = getValues(x);
                        const result = setSearchFilter(
                            table,
                            column,
                            query,
                            value,
                            `${label}: ${name}`,
                            groupCondition,
                            filterId,
                            !x.isDefault ? visibleSection?.value : x?.value,
                            excludeFromSearch,
                            null,
                            !isDefault ? group : uuid(),
                            type,
                            name,
                        );
                        return value && { ...result, id: result.id };
                    })
                    .filter(x => x);
            } else {
                if (activeFilter) {
                    resChild = [visibleSection];
                    section = visibleSection.value;
                } else {
                    resChild =
                        visibleSection && state[visibleSection.value]
                            ? defaultFiltersChild.concat([{ ...visibleSection }])
                            : defaultFiltersChild;
                    section =
                        visibleSection && state[visibleSection.value] ? visibleSection.value : null;
                }

                res = resChild.map(x => {
                    const {
                        label,
                        column,
                        table,
                        query = queryOperation.Equal,
                        type,
                        isDefault = false,
                    } = x;
                    const { name = '', value = null } = getValues(x);

                    const result = setSearchFilter(
                        table,
                        column,
                        query,
                        value,
                        `${label}: ${name}`,
                        groupCondition,
                        filterId,
                        !x.isDefault ? section : x?.value,
                        excludeFromSearch,
                        null,
                        !isDefault ? group : uuid(),
                        type,
                        name,
                        isDefault,
                    );
                    return value && { ...result, id: activeFilter?.id || result.id };
                });
            }
        }
        return res && setFiltersState(res.filter(f => f), filterId, false, visibleSection?.extra);
    };

    const handleGroupConditionChange = e => {
        const condition = e.currentTarget.checked ? 'OR' : 'AND';
        updateFiltersWithCondition(filterId, condition, withCurrent);
        setGroupCondition(condition);
    };

    const getCatalogValue = (catalog, value, catalogProp = 'id') => {
        return catalogs[catalog]?.data.find(c => c[catalogProp] === (value?.id || value));
    };

    const renderDefaultFilters = (activeFilter = null) => {
        const finalFilters = activeFilter
            ? defaultFilters.filter(x => x.value === activeFilter.section)
            : defaultFilters;
        return finalFilters.map(x => (
            <React.Fragment key={x.label}>
                {x.withHeader && (
                    <div className="LKSearchCardVisibleFIlterLine__GroupHeader">{x.label}</div>
                )}
                {renderFilters(x)}
            </React.Fragment>
        ));
    };

    const renderFilters = (defaultFilter = null) => {
        if (!visibleSection && !defaultFilter) {
            return null;
        }

        const { child } = defaultFilter || visibleSection;

        if (child) {
            return child.map(x => {
                const { value, type, catalog, size = 50, label, updateDependence, extra } = x;
                const finalValue =
                    type === FIELDS_TYPE.select
                        ? getCatalogValue(catalog, state[value])
                        : state[value];
                return (
                    <FilterField
                        size={size}
                        key={x.name || x.value}
                        placeholder={defaultFilter ? label : ''}
                        onChange={onChangeField(value, updateDependence, child, true)}
                        onInputChange={debounce(onInputChangeField(value), 500)}
                        onFocus={() => onFocusField(value)}
                        type={type}
                        value={finalValue}
                        items={catalogs[catalog]?.data || []}
                        extra={extra}
                    />
                );
            });
        } else {
            const { value, type, catalog, label, extra } = defaultFilter || visibleSection;
            const finalValue =
                type === FIELDS_TYPE.select ? getCatalogValue(catalog, state[value]) : state[value];
            return (
                <FilterField
                    onChange={onChangeField(value)}
                    placeholder={defaultFilter ? label : ''}
                    type={type}
                    value={finalValue}
                    items={catalogs[catalog]?.data || []}
                    closeMenuOnSelect={props.closeMenuOnSelect}
                    extra={extra}
                />
            );
        }
    };

    const selectOptions = fields.filter(x => !x.isDefault);
    return (
        <div>
            {showCondition && (
                <div className="LKSearchCardVisibleFIlterLine">
                    <SearchGroupCondition
                        checked={groupCondition === 'OR'}
                        onClick={e => handleGroupConditionChange(e)}
                    />
                </div>
            )}
            <ActiveFilters filterId={filterId} />

            {(!activeFilter || (activeFilter && isDefaultFilter(activeFilter))) && (
                <div className={classnames('LKSearchCardVisibleFIlterLine')}>
                    {renderDefaultFilters(activeFilter)}
                </div>
            )}
            {(!activeFilter || (activeFilter && !isDefaultFilter(activeFilter))) &&
            selectOptions.length !== 0 && (
                <>
                    {!isOnceFilter(activeFilter) && (
                        <div className="LKSearchCardVisibleFIlterLine">
                            <Field>
                                <Select
                                    styles={selectStyles}
                                    placeholder="Выберите дополнительный критерий"
                                    options={selectOptions}
                                    onChange={handleCriteriaCHange}
                                    value={visibleSection}
                                    isClearable={!activeFilter}
                                />
                            </Field>
                        </div>
                    )}

                    <div className={classnames('LKSearchCardVisibleFIlterLine')}>
                        {renderFilters()}
                    </div>
                </>
            )}
            {extraFields}
            <div
                className={classnames('LKSearchCardVisibleFIlterLine', {
                    'LKSearchCardVisibleFIlterLine--hidden': !buttonEnabled,
                })}
            >
                <Field>
                    <StyledCheckBox
                        onChange={setExcludeFromSearch}
                        checked={excludeFromSearch}
                        title="Исключить из поиска"
                        style={{display: 'inline-block'}}
                    />
                </Field>
            </div>
            {buttonEnabled && (
                <div className="LKSearchCardVisibleFIlterLine--right">
                    <Button
                        icon="plus"
                        onClick={() => onSubmit(false)}
                        disabled={!buttonEnabled}
                    >
                        {activeFilter ? 'Изменить' : 'Добавить'}
                    </Button>
                </div>
            )}

            <SuccessButton
                onSubmit={onSubmit}
                buttonEnabled={buttonEnabled}
                activeFilter={activeFilter}
                visibleSection={false}
                isFocused={isFocused}
            />
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        mainFilter: state.filterSearch.mainFilter,
        activeFilter: state.filterSearch.activeFilter,
        catalogs: ownProps.catalogs || state.catalogs,
    };
};

export default connect(mapStateToProps)(FilterTemplate);
