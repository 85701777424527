import React from 'react';
import classnames from 'classnames';
import RoundButton from '../Button/RoundButton'

const ChangeVertical = props => {
    const {onUp = () => {}, onDown = () => {}, index, size, disabled = false} = props;

    return (
        size > 1 &&
        <div className='ChangeVertical'>
            <div className={classnames('ChangeVertical__Item', {'ChangeVertical__Item--hidden': index === 0})} onClick={onUp}>
                <RoundButton icon='arrow-up' disabled={disabled} color='LightBlue' />
            </div>
            <div className={classnames('ChangeVertical__Item', {'ChangeVertical__Item--hidden': index + 1 === size})} onClick={onDown}>
                <RoundButton icon='arrow-down' disabled={disabled} color='LightBlue' />
            </div>
        </div>
    );
};

export default ChangeVertical;