import React from 'react';
import Icons from 'components/Lk/Uikit/Icons';
import { helpEmail, helpPhone } from 'constants.js';
import './ErrorContent.scss';

const ErrorContent = () => {
    return (
        <div className="ErrorContentWrapper">
            <div className="ErrorContent">
                <div className="ErrorContent__Header">Произошла ошибка</div>
                <div className="ErrorContent__Text">
                    Попробуйте обновить страницу. Если проблема не решится, пожалуйста, свяжитесь с
                    нашей технической поддержкой:
                </div>
                <div className="ErrorContent__TechSupportLine">
                    <div className="ErrorContent__TechSupportLineImg">
                        <Icons fill="#368ef1" name="mail" />
                    </div>
                    <div>
                        <a href={`mailto: ${helpEmail}`}>{helpEmail}</a>
                    </div>
                </div>
                <div className="ErrorContent__TechSupportLine">
                    <div className="ErrorContent__TechSupportLineImg">
                        <Icons fill="#368ef1" name="phone" />
                    </div>
                    <div>
                        <a href={`tel: ${helpPhone.dial}`}>{helpPhone.show}</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorContent;
