import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import { getStateFromTextArea, selectWord } from 'utils';
import insertText from 'insert-text-at-cursor';

import ModalDialog from 'components/common/ModalDialog';
import Input from 'components/uikit/Input';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import CheckField from 'components/uikit/CheckField';
import Toolbar from 'components/common/TextEditor/components/Toolbar/Toolbar';
import PreviewEditor from 'components/common/TextEditor/components/PreviewEditor/PreviewEditor';

import './TextEditor.scss';

import { Turndown } from 'libs/showdown';
import { resetMarkdown, getSelectedText } from 'utils';

class TextEditor extends Component {
    state = {
        isOpenLinkModal: false,
        linkModal: {
            name: '',
            url: '',
            blank: false,
        },
        selectedText: ''
    };

    getSelection = () => {
        const { current } = this.props.textInput;
        const state = getStateFromTextArea(current);
        const newSelectionRange = selectWord({
            text: state.text,
            selection: state.selection,
        });
        return this.setSelectionRange(newSelectionRange);
    };

    replaceSelection = text => {
        const { current } = this.props.textInput;
        insertText(current, text);
        return getStateFromTextArea(current);
    };

    setSelectionRange = selection => {
        const { current } = this.props.textInput;
        current.focus();
        current.selectionStart = selection.start;
        current.selectionEnd = selection.end;
        return getStateFromTextArea(current);
    };

    setBold = () => {
        const state1 = this.getSelection();
        const state2 = this.replaceSelection(`**${state1.selectedText}**`);
        this.setSelectionRange({
            start: state2.selection.end - 2 - state1.selectedText.length,
            end: state2.selection.end - 2,
        });
    };

    setItalic = () => {
        const state1 = this.getSelection();
        const state2 = this.replaceSelection(`*${state1.selectedText}*`);
        this.setSelectionRange({
            start: state2.selection.end - 1 - state1.selectedText.length,
            end: state2.selection.end - 1,
        });
    };

    setStrike = () => {
        const state1 = this.getSelection();
        const state2 = this.replaceSelection(`~~${state1.selectedText}~~`);
        this.setSelectionRange({
            start: state2.selection.end - 2 - state1.selectedText.length,
            end: state2.selection.end - 2,
        });
    };

    setLink = () => {
        const { url, blank, name } = this.state.linkModal;

        let correctUrl = '';
        
        if (url) {
            correctUrl = url.replace('https://', '').replace('http://', '');
        }
        
        const str = blank
            ? `[${name}](https://${correctUrl})(target="_blank") `
            : `[${name}](https://${correctUrl}) `;

        this.replaceSelection(str);
        
        setTimeout(() => {
            this.props.textInput?.current?.blur &&
            this.props.textInput.current.blur()
        }, 1000)
    };
    
    setHeading = level => {
        const state1 = this.getSelection();
       
        const prefix = '#'.repeat(level);
        const state2 = this.replaceSelection(`${prefix} ${state1.selectedText}`);
        this.setSelectionRange({
            start: state2.selection.end - state1.selectedText.length,
            end: state2.selection.end,
        });
    };

    setList = () => {
        const state1 = this.getSelection();
        const selectedTexts = state1.selectedText.split('\n');
        let iterations = selectedTexts.length;
        for (const selectedText of selectedTexts) {
            if (!--iterations) {
                this.replaceSelection(`- ${selectedText} `);
            } else {
                this.replaceSelection(`- ${selectedText}\n`);
            }
        }
    };

    setListOl = () => {
        const state1 = this.getSelection();
        const selectedTexts = state1.selectedText.split('\n');
        let i = 1;
        for (const selectedText of selectedTexts) {
            if (i === selectedTexts.length) {
                this.replaceSelection(`${i}. ${selectedText} `);
            } else {
                this.replaceSelection(`${i}. ${selectedText}\n`);
                i++;
            }
        }
    };

    setUnderline = () => {
        const state1 = this.getSelection();
        const state2 = this.replaceSelection(`__${state1.selectedText}__`);
        this.setSelectionRange({
            start: state2.selection.end - 2 - state1.selectedText.length,
            end: state2.selection.end - 2,
        });
    };

    onExecute = (command, level) => {
        switch (command) {
            case 'bold':
                this.setBold();
                return;
            case 'italic':
                this.setItalic();
                return;
            case 'strike':
                this.setStrike();
                return;
            case 'link':
                this.openLinkModal();
                return;
            case 'heading':
                this.setHeading(level);
                return;
            case 'list':
                this.setList();
                return;
            case 'listOl':
                this.setListOl();
                return;
            case 'underline':
                this.setUnderline();
                return;
            default:
                return;
        }
    };

    onAdd = async uploadFiles => {
        const formData = new FormData();
        for (const file of uploadFiles) {
            formData.append('files', file);
        }

        await this.uploadFiles(formData);
    };

    handlePaste = async e => {
        const clipboardData = e.clipboardData || e.originalEvent.clipboardData;

        if (clipboardData.types.indexOf('Files') >= 0 && clipboardData.files.length === clipboardData.items.length) {
            const items = clipboardData.items;
            const formData = new FormData();
            for (const item of items) {
                if (item.type.indexOf('image') === 0) {
                    formData.append('files', item.getAsFile());
                }
            }

            await this.uploadFiles(formData);
        } else if (clipboardData.types.indexOf('text/html') >= 0) {
            e.preventDefault();
            const text = clipboardData.getData('text/html');
            const html = document.createElement('html');
            html.innerHTML = text;

            const markdown = Turndown.turndown(html.querySelector('body').innerHTML)
                .replace(/[\u2018\u2019\u00b4]/g, "'")
                .replace(/[\u201c\u201d\u2033]/g, '"')
                .replace(/[\u2212\u2022\u00b7\u25aa]/g, '-')
                .replace(/[\u2013\u2015]/g, '--')
                .replace(/\u2014/g, '---')
                .replace(/\u2026/g, '...')
                .replace(/[ ]+\n/g, '\n')
                .replace(/\s*\\\n/g, '\\\n')
                .replace(/\s*\\\n\s*\\\n/g, '\n\n')
                .replace(/\s*\\\n\n/g, '\n\n')
                .replace(/\n-\n/g, '\n')
                .replace(/\n\n\s*\\\n/g, '\n\n')
                .replace(/\n\n\n*/g, '\n\n')
                .replace(/[ ]+$/gm, '')
                .replace(/^\s+|[\s\\]+$/g, '');
            const state2 = this.replaceSelection(markdown);

            this.setSelectionRange({
                start: state2.selection.end,
                end: state2.selection.end,
            });
        }
    };

    uploadFiles = async formData => {
        if (formData.has('files') && this.props.onPasteFiles) {
            const result = await this.props.onPasteFiles(formData);

            let str = '';
            for (const file of result.data) {
                if (new RegExp(['jpeg', 'jpg', 'png', 'gif'].join('|')).test(file.fileName)) {
                    str += `![${file.fileName}](/${this.props.prefixFileApi}/${file.id}) `;
                } else {
                    str += `[${file.fileName.replace('__', '\\__')}](/${this.props.prefixFileApi}/${
                        file.id
                    }) \n\n`;
                }
            }
            const state2 = this.replaceSelection(str);
            this.setSelectionRange({
                start: state2.selection.end,
                end: state2.selection.end,
            });
        }
    };

    openLinkModal = () => {
        const state1 = this.getSelection();
        if (state1.selectedText) {
            const name = state1.selectedText.substring(
                state1.selectedText.indexOf('[') + 1,
                state1.selectedText.indexOf(']'),
            );
            let url = state1.selectedText.substring(
                state1.selectedText.indexOf('(') + 1,
                state1.selectedText.indexOf(')'),
            );
            const blank = !!state1.selectedText.substring(
                state1.selectedText.indexOf('{') + 1,
                state1.selectedText.indexOf('}'),
            );

            this.setState({
                isOpenLinkModal: true,
                linkModal: {
                    name: name ? name : state1.selectedText,
                    url: url,
                    blank: blank,
                },
            });
        } else {
            this.setState({
                isOpenLinkModal: true,
                linkModal: {
                    name: '',
                    url: '',
                    blank: false,
                },
            });
        }
    };

    acceptModal = () => {
        this.setState({ isOpenLinkModal: false }, () => {
            this.setLink();
        });
    };

    onCancelModal = () => {
        this.setState({ isOpenLinkModal: false }, () => {
            setTimeout(() => {
                this.props.textInput?.current?.blur &&
                this.props.textInput.current.blur()
            }, 1000)
        });
    };

    handleUrlInLinkModal = e => {
        const linkModal = {
            ...this.state.linkModal,
            url: e.target.value,
        };

        this.handleStateChange('linkModal', linkModal);
    };

    handleNameInLinkModal = e => {
        const linkModal = {
            ...this.state.linkModal,
            name: e.target.value,
        };

        this.handleStateChange('linkModal', linkModal);
    };

    handleBlankInLinkModal = check => {
        const linkModal = {
            ...this.state.linkModal,
            blank: check,
        };

        this.handleStateChange('linkModal', linkModal);
    };

    handleStateChange = (key, value) => this.setState(state => ({ ...state, [key]: value }));


    renderLinkModal = () => {
        const { url, name, blank } = this.state.linkModal;
        return (
            <ModalDialog
                onClick={this.acceptModal}
                onCloseModal={this.onCancelModal}
                modalOpen={this.state.isOpenLinkModal}
                modalHeader="Конфигурация ссылки"
                btnOktext="Сохранить"
                btnCanceltext="Отмена"
                size="lg"
                noHeaderDivider
            >
                <div className="Modal-Children">
                    <div className="Modal-Children__Line">
                        <Field>
                            <Label>Ссылка</Label>
                            <Input
                                maxLength="200"
                                value={url}
                                onChange={this.handleUrlInLinkModal}
                            />
                        </Field>
                    </div>
                    <div className="Modal-Children__Line">
                        <Field>
                            <Label>Название ссылки</Label>
                            <Input
                                maxLength="200"
                                value={name}
                                onChange={this.handleNameInLinkModal}
                            />
                        </Field>
                    </div>
                    <div className="Modal-Children__Line">
                        <CheckField
                            id="targetBlank"
                            title="В новой вкладке"
                            checked={blank}
                            onChange={this.handleBlankInLinkModal}
                            required
                            size="33"
                            className="targetBlank"
                            modifier="TextEditor"
                        />
                    </div>
                </div>
            </ModalDialog>
        );
    };

    onChangePreview = value => {
        if (this.props.onChangePreview) {
            this.props.onChangePreview(value);
        }
        value && this.setState({selectedText: ''});
    };

    onSelectionChange = () => {
        const selectedText = getSelectedText('PreviewEditor');
        this.setState({selectedText});
    };

    clearMarkdown = () => {
        const { selectedText } = this.state;
        const el = document.getElementById('PreviewEditor');
        const newVal = el.value.replace(selectedText, resetMarkdown(selectedText));
        this.props.onChange({target: {value: newVal}});
        this.setState({selectedText: ''});
    };

    render() {
        return (
            <div className="TextEditor">
                <Toolbar
                    onAddFile={this.onAdd}
                    onChangePreview={this.onChangePreview}
                    onExecute={this.onExecute}
                    extendedToolbar={this.props.extendedToolbar}
                    preview={this.props.preview}
                    showToolbar={this.props.showToolbar}
                    showTabs={this.props.showTabs}
                    clearMarkdown={this.state.selectedText ? this.clearMarkdown : undefined}
                ></Toolbar>
                <Row>
                    <Col
                        md={12}
                        className="TextEditor__Content"
                        style={{ minHeight: this.props.minHeight }}
                    >
                        <PreviewEditor
                            id='PreviewEditor'
                            textInput={this.props.textInput}
                            maxLength={this.props.maxLength}
                            rows={this.props.rows}
                            value={this.props.value}
                            onChange={this.props.onChange}
                            handlePaste={this.handlePaste}
                            preview={this.props.preview}
                            selectionchange={this.onSelectionChange}
                            backRender={this.props.backRender}
                            disabled={this.props.disabled}
                            isRequired={this.props.notEmptyText}
                        ></PreviewEditor>
                    </Col>
                </Row>
                {this.renderLinkModal()}
            </div>
        );
    }
}

TextEditor.propTypes = {
    preview: PropTypes.bool,
    rows: PropTypes.number,
    showToolbar: PropTypes.bool,
    minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChangePreview: PropTypes.func,
    onChange: PropTypes.func,
    renderDropZone: PropTypes.bool,
    extendedToolbar: PropTypes.bool,
    onPasteFiles: PropTypes.func,
    prefixFileApi: PropTypes.string,
    showTabs: PropTypes.bool,
    textInput: PropTypes.object,
    notEmptyText: PropTypes.bool,
};

TextEditor.defaultProps = {
    preview: false,
    showToolbar: true,
    rows: 10,
    minHeight: 300,
    prefixFileApi: 'api',
    showTabs: true,
    extendedToolbar: false,
    textInput: React.createRef(),
    notEmptyText: false,
};

export default TextEditor;
