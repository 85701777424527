import React, { Component } from 'react';
import { connect } from 'react-redux';
import './NewsPicture.scss';
import memoize from 'fast-memoize';
import PhotoBlock from 'components/common/PhotoBlock';
import Image from 'components/common/Image';
import { getError } from 'serviceErrors';
import { hidePageLoader, showPageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import {
    getNewsPictureUri,
    getTempFileUrl,
    uploadNewsPicture,
    uploadTempFile as uploadTempFileApi,
} from 'api';

class NewsPicture extends Component {
    state = {
        refetchKey: null,
    };

    callEffect = async (callback) => {

        const {showPageLoader, showErrorAlert, hidePageLoader} = this.props;
        showPageLoader();
        try {
            return await callback();
        } catch (error) {
            const reqError = getError(error, this.getNewsError);
            showErrorAlert(reqError.message);
        } finally {
            hidePageLoader();
        }
    };

    handlePhotoOpened = () => async photoFile => {
        const { newsId, onPictureChanged } = this.props;
        if (!photoFile) {
            return;
        }
    
        await this.callEffect(async () => {
            const response =
                newsId && newsId !== 'new'
                    ? await uploadNewsPicture(newsId, photoFile)
                    : await uploadTempFileApi(photoFile, () => {});
    
            if (response && response.status === 200) {
                onPictureChanged(response.data.id);
                this.setState({
                    refetchKey: new Date().getTime()
                });
            }
        });
    };

    handleNewsPreviewOpened = memoize(this.handlePhotoOpened);

    render() {
        const { newsId, pictureFileId, viewMode } = this.props;
        const { refetchKey } = this.state;

        const tmpRefetchKey = viewMode ? undefined : refetchKey;
        const photoUri =
            pictureFileId &&
            (newsId
                ? getNewsPictureUri(newsId, pictureFileId, tmpRefetchKey)
                : getTempFileUrl(pictureFileId, tmpRefetchKey));

        return (
            <div className="NewsPicture">
                {viewMode && <Image className="NewsPicture__Image--viewSize" photoUri={photoUri} />}
                {!viewMode && (
                    <div className="NewsPicture__Image--resized">
                        <PhotoBlock
                            id={`photo-block_${newsId}`}
                            photoUri={photoUri || undefined}
                            photoLink={photoUri || undefined}
                            onPhotoOpened={this.handleNewsPreviewOpened()}
                            readOnly={false}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const actions = {
    showPageLoader,
    hidePageLoader,
    showErrorAlert,
};

export default connect(
    null,
    actions,
)(NewsPicture);
