import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";

import Page from "components/common/Page";
import Button from "components/uikit/Button";
import Input from "components/uikit/Input";
import Label from "components/uikit/Label";

import { serviceResultCode, getError } from "serviceErrors";

import './Wizard.scss';

const getConfirmError = () => (code) => {
    switch (code) {
        case serviceResultCode.NotFound:
            return `Данный пользователь не найден в системе`;
        case serviceResultCode.SmsWrongNumber:
            return `Указан неверный номер телефона`;
        case serviceResultCode.SmsSendFail:
            return "Ошибка отправки сообщения";
        case serviceResultCode.UserMaintenanceAlreadyConfirmed:
            return `Телефон уже подтвержден`;
        case serviceResultCode.UserMaintenanceInvalidCode:
            return "Неправильный код подтверждения";
        case serviceResultCode.UserMaintenanceInvalidToken:
            return "Некорректный или истекший токен";
        default:
            return "Произошла непредвиденная ошибка"
    }
}

const requestCode = async (requestCodeApi) => {
    try {
        const response = await requestCodeApi();
        return {
            ok: {
                codeTimerValue: parseInt(response.data.codeResendCooldown),
                maskedPhoneNumber: response.data.maskedPhoneNumber,
                phoneConfirmed: response.data.phoneConfirmed,
                passwordGenerated: response.data.passwordGenerated,
            }
        };
    } catch (error) {
        return getError(error, getConfirmError(error));
    }
};

export default class ConfirmPhoneStep extends Component {
    constructor(props) {
        super(props);

        this.state = {
            initialErrorMsg: null,
            code: "",
            codeTimerId: null,
            codeTimerValue: null,
            maskedPhoneNumber: null,
            requestingCode: false,
            submitting: false,
        }
    }

    componentDidMount = async () => {
        const result = await requestCode(this.props.onRequestCode);
        if (!result.ok) {
            this.setState({ initialErrorMsg: result.message });
            return;
        }

        if (result.ok && result.ok.phoneConfirmed && !result.ok.passwordGenerated) {
            this.props.onSubmitSuccess();
            return;
        }

        const codeTimerId = setInterval(() => {
            this.setState((state) => {
                let codeTimerValue = state.codeTimerValue;
                if (codeTimerValue === null || codeTimerValue === 0) {
                    return {};
                }
                return { codeTimerValue: codeTimerValue - 1 };
            });
        }, 1000);

        this.setState({ ...result.ok, codeTimerId });
    }

    componentWillUnmount = () => {
        if (this.state.codeTimerId) {
            clearInterval(this.state.codeTimerId);
        }
    }

    isFrozen = () => this.state.requestingCode || this.state.submitting;

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        if (this.isFrozen()) { return; }

        const { onConfirmPhone, onError, onSubmitSuccess } = this.props;
        const { code } = this.state;

        this.setState({ submitting: true });

        try {
            await onConfirmPhone(code);
            onSubmitSuccess();
        } catch (error) {
            const requestError = getError(error, getConfirmError(error));
            onError(requestError.message);
        }

        this.setState({ submitting: false });
    }

    requestNewCode = async () => {
        if (this.isFrozen()) { return; }

        const { onRequestCode, onError } = this.props;

        this.setState({ requestingCode: true });

        const result = await requestCode(onRequestCode);
        if (result.ok) {
            this.setState({ ...result.ok });
        } else {
            onError(result.error);
        }

        this.setState({ requestingCode: false });
    }

    renderLoaded = () => {
        const { login } = this.props;
        const { code, codeTimerValue, maskedPhoneNumber, requestingCode, submitting } = this.state;
        const isFrozen = this.isFrozen();

        return (
            <Page>
                <div className="outer-wizard-wrapper">
                    <div className="wizard-wrapper">
                        <div className="wizard-confirm-form">
                            <div className="wizard-confirm-form__header">
                                Подтверждение телефона
                            </div>
                            <div className="wizard-confirm-form__divider">
                                <hr></hr>
                            </div>
                            <div className="wizard-confirm-form-line">
                                <div className="wizard-confirm-form-line__input">
                                    <Label htmlFor="login">Логин</Label>
                                    <Input id="login" name="login" value={login} readOnly />
                                </div>
                            </div>
                            <div className="wizard-confirm-form-line">
                                <div className="wizard-confirm-form-line__input">
                                    Введите код подтверждения, отправленный на номер: {maskedPhoneNumber}
                                </div>
                            </div>
                            <div className="wizard-confirm-form-line">
                                <div className="wizard-confirm-form-line__input">
                                    <Label htmlFor="code">Код подтвержения</Label>
                                    <Input
                                        id="code" name="code" required autoFocus
                                        value={code}
                                        onChange={this.handleChange}
                                        disabled={isFrozen}
                                    />
                                </div>
                            </div>
                            <div>
                                {codeTimerValue > 0
                                    ? `Код можно будет запросить повторно через ${codeTimerValue} сек`
                                    : (
                                        <Button
                                            type="button"
                                            disabled={isFrozen}
                                            loading={requestingCode}
                                            onClick={this.requestNewCode}
                                        >
                                            Запросить код
                                        </Button>
                                    )
                                }
                            </div>
                        </div>
                        <div className="btn">
                            <Button type="submit" loading={submitting} disabled={isFrozen}>
                                Подтвердить
                            </Button>
                        </div>
                    </div>
                </div>
            </Page>
        );
    }

    renderLoader = () => {
        return (
            <Page>
                <div className="outer-wizard-wrapper">
                    <div className="wizard-wrapper">
                        <div className="wizard-confirm-form">
                            <div className="wizard-confirm-form__header">
                                <FontAwesomeIcon icon="circle-notch" size="lg" spin />
                                Идет загрузка
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        );

    }

    renderError = (errorMsg) => {
        return (
            <Page>
                <div className="outer-wizard-wrapper">
                    <div className="wizard-wrapper">
                        <div className="wizard-confirm-form">
                            <div className="wizard-confirm-form__header-error">
                                Ошибка!
                            </div>
                            <div className="wizard-confirm-form__divider">
                                <hr></hr>
                            </div>
                            <div className="wizard-confirm-form__error">
                                {errorMsg}
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        );
    }

    render = () => {
        let content;
        if (this.state.initialErrorMsg) {
            content = this.renderError(this.state.initialErrorMsg);
        } else if (!this.state.maskedPhoneNumber) {
            content = this.renderLoader();
        } else {
            content = this.renderLoaded();
        }

        return (
            <form onSubmit={this.handleSubmit} autoComplete="off">
                {content}
            </form>
        );
    }
}
