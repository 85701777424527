import { Link } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { getUserShortName, formatDate, isMobileWidth as isMobileWindowWidth } from 'utils.js';
import { ISSUE_STATUS_ENUM, ISSUE_HISTORY_RECORD_TYPES, ISSUE_PROPERTIES } from 'constants.js';
import { Col, Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './IssueHistory.scss';

const SORT_FIELDS = [
    ISSUE_PROPERTIES.AddAssignees,
    ISSUE_PROPERTIES.RemoveAssignees,
    ISSUE_PROPERTIES.Title,
    ISSUE_PROPERTIES.Description,
    ISSUE_PROPERTIES.DueDate,
    ISSUE_PROPERTIES.Status,
    ISSUE_PROPERTIES.AddFiles,
    ISSUE_PROPERTIES.RemoveFiles,
];

const IssueHistory = props => {
    const { history, forceMobileView, lkUrl } = props;

    const [isMobile, setMobile] = useState(false);
    const windowWidth = window.innerWidth;

    const onResize = useCallback(() => {
        const isMobileWidth = isMobileWindowWidth();

        if (isMobile !== isMobileWidth) {
            setMobile(isMobileWidth);
        }
    }, [isMobile]);

    useEffect(() => {
        window.addEventListener('resize', onResize);
        onResize();
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [windowWidth, onResize]);

    const renderProperties = item => {

        if ([ISSUE_HISTORY_RECORD_TYPES.Created].indexOf(item.issueHistoryRecordType) >= 0) {
            return null;
        }
        const renderHis = (title, prev, val, id, index) => {
            return isMobile || forceMobileView ? (
                <div key={id} className={classnames('IssueHistory__PropertyWrapper')}>
                    <div className="IssueHistory__PropertyChange">
                        <div data-test={`IssueHistory_PropTitle--text index_${index}`}>{title}</div>
                        <div data-test={`IssueHistory_PropValue--text index_${index}`}>
                            {prev && (
                                <>
                                    <span 
                                        className="IssueHistory__PrevValue" 
                                        data-test={`IssueHistory_PropPrevValue--text index_${index}`}
                                    >{prev}</span>
                                    <span className="IssueHistory__Arrow"> →</span>
                                </>
                            )}
                            {val}
                        </div>
                    </div>
                </div>
            ) : (
                <Row key={id} between="xs">
                    <Col xs data-test={`IssueHistory_PropTitle--text index_${index}`}>{title}</Col>
                    {(prev || val) && (
                        <Col 
                            xs 
                            className="IssueHistory__PropertyChange--right" 
                            data-test={`IssueHistory_PropValue--text index_${index}`}
                        >
                            {prev && (
                                <>
                                    <span 
                                        className="IssueHistory__PrevValue"  
                                        data-test={`IssueHistory_PropPrevValue--text index_${index}`}
                                    >{prev}</span>
                                    <span className="IssueHistory__Arrow"> →</span>
                                </>
                            )}
                            {val}
                        </Col>
                    )}
                </Row>
            );
        };

        const getPropFriendlyName = property => {
            switch (property) {
                case ISSUE_PROPERTIES.DueDate:
                    return 'Срок выполнения';
                case ISSUE_PROPERTIES.Description:
                    return 'Текст задачи';
                case ISSUE_PROPERTIES.Title:
                    return 'Заголовок';
                case ISSUE_PROPERTIES.Status:
                    return 'Статус';
                case ISSUE_PROPERTIES.AddAssignees:
                    return 'Добавлены ответственные';
                case ISSUE_PROPERTIES.RemoveAssignees:
                    return 'Убраны ответственные';

                case ISSUE_PROPERTIES.AddFiles:
                    return 'Добавлены файлы';
                case ISSUE_PROPERTIES.RemoveFiles:
                    return 'Удалены файлы';
                default:
                    return 'Свойство задачи';
            }
        };

        const getPropValueFriendlyName = value => {
            switch (value) {
                case ISSUE_STATUS_ENUM.New:
                    return 'Новая задача';
                case ISSUE_STATUS_ENUM.InProgress:
                    return 'Выполняется';
                case ISSUE_STATUS_ENUM.Completed:
                    return 'Выполнена';
                case ISSUE_STATUS_ENUM.Canceled:
                    return 'Отменена';
                case ISSUE_STATUS_ENUM.Deleted:
                    return 'Удалена';
                case ISSUE_STATUS_ENUM.Closed:
                    return 'Закрыта';
                case ISSUE_STATUS_ENUM.Draft:
                    return 'Черновик';
                default:
                    return value;
            }
        };

        const getVal = (val,property) => {
            switch(property){
                case ISSUE_PROPERTIES.DueDate: 
                return formatDate(val);
                default:
                    return getPropValueFriendlyName(val);
            }
        }

        if (item.issuePropertyChanges.length === 0) {
            return renderHis(item.description, null, null, item.id);
        }

        return (
            <div>
                {item.issuePropertyChanges
                    .filter(prop => prop.currentValue !== ISSUE_STATUS_ENUM.Completed)
                    .sort(
                        (a, b) => SORT_FIELDS.indexOf(a.property) - SORT_FIELDS.indexOf(b.property),
                    )
                    .map((prop, index) =>
                        renderHis(
                            getPropFriendlyName(prop.property),
                            getVal(prop.previousValue, prop.property),
                            getVal(prop.currentValue, prop.property),
                            prop.id,
                            index
                        ),
                    )}
            </div>
        );
    };

    const renderDueDateDelay = (item, index) => {
        const getIssueTypeDescription = issueHistoryRecordType => {
            const issueIsCompleted = !!item.issuePropertyChanges.find(
                pc => pc.currentValue === ISSUE_STATUS_ENUM.Completed,
            );
            switch (issueHistoryRecordType) {
                case ISSUE_HISTORY_RECORD_TYPES.Created:
                    return 'Создана задача';
                case ISSUE_HISTORY_RECORD_TYPES.Edited:
                    return issueIsCompleted ? 'Выполнена задача' : 'Внесены изменения';
                case ISSUE_HISTORY_RECORD_TYPES.Deferred:
                    return 'Отложена задача';
                case ISSUE_HISTORY_RECORD_TYPES.Completed:
                    return 'Завершена задача';
                case ISSUE_HISTORY_RECORD_TYPES.Canceled:
                    return 'Отменена задача';
                case ISSUE_HISTORY_RECORD_TYPES.Deleted:
                    return 'Удалена задача';
                case ISSUE_HISTORY_RECORD_TYPES.StatusUpdated:
                    return issueIsCompleted ? 'Выполнена задача' : 'Изменен статус';
                default:
                    return 'Внесены изменения';
            }
        };

        const reportValue =
            ([
                ISSUE_HISTORY_RECORD_TYPES.Deferred,
                ISSUE_HISTORY_RECORD_TYPES.StatusUpdated,
            ].indexOf(item.issueHistoryRecordType) >= 0 &&
                item.description && (
                    <div data-test={`IssueHistory_Description--text index_${index}`}>
                        {item.issueHistoryRecordType === ISSUE_HISTORY_RECORD_TYPES.Deferred
                            ? 'Комментарий'
                            : 'Отчет'}
                        : {item.description}
                    </div>
                )) ||
            null;

        const renderAuthorLink = item => {
            return item?.author?.personId ? (
                <Link
                    data-test={`IssueHistory_Author--link id_${item?.author?.personId} index_${index}`}
                    to={lkUrl.buildUrl({
                        id: item?.author?.personId,
                    })}
                >
                    {getUserShortName(item.author)}
                </Link>
            ) : (
                getUserShortName(item.author)
            );
        };

        return (
            <div key={item.id} className="IssueHistory__Wrapper">
                {isMobile || forceMobileView ? (
                    <div>
                        <div data-test="Issue_Author--link">{renderAuthorLink(item)}</div>
                        <div>
                            <strong data-test={`Issue_CreatedOn--text index=${index}`}>
                                {getIssueTypeDescription(item.issueHistoryRecordType)}{' '}
                                {formatDate(item.createdOn)}
                            </strong>
                        </div>
                        {reportValue}
                    </div>
                ) : (
                    <Row>
                        <Col xs={12} data-test="Issue_Author--link">
                            {renderAuthorLink(item)}
                        </Col>
                        <Col xs={12}>
                            <strong data-test={`Issue_CreatedOn--text index_${index}`}>
                                {getIssueTypeDescription(item.issueHistoryRecordType)}{' '}
                                {formatDate(item.createdOn)}
                            </strong>
                        </Col>
                        {reportValue && <Col xs={12}>{reportValue}</Col>}
                    </Row>
                )}
                <div>{renderProperties(item)}</div>
            </div>
        );
    };

    return history.map((item, index) => renderDueDateDelay(item, index));
};

IssueHistory.propTypes = {
    history: PropTypes.array.isRequired,
};

export default IssueHistory;
