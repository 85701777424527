import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Button = props => {
    const { color = 'blue', icon, disabled } = props;
    const finalClass = classnames('LKButton', 
        { 
            [`LKButton--color-${color}`]: true,
            [`LKButton--icon-${icon}`]: icon,
            [`LKButton--disabled`]: disabled,
        }
    );
    
    return (
        <button className={finalClass} {...props}>
            {props.children}
        </button>
    );
};

Button.propTypes = {
    color: PropTypes.string,
};

export default Button;