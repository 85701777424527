import { uploadTempFile, getAttributeAttachmentInfo } from "api";
import FileUploadButton from "components/uikit/FileUploadButton";
import contramapProps from "components/hoc/contramapProps";

const getUploader = () => async (file) => {
    try {
        const response = await uploadTempFile(file);
        return response.data;
    } catch (e) {
        return null;
    }
}

const getFileInfoRequester = (attributeId, personId) => async (fileId) => {
    try {
        const response = attributeId > 0
            ? await getAttributeAttachmentInfo(personId, attributeId, fileId)
            : Promise.resolve(null);
        return response.data;
    } catch (e) {
        return null;
    }
}

const injectFileApis = ({ personId = null, attributeId = null, fileId, onFileIdChange, fileTypes = "image/jpeg,image/png,video/*", setFileUploading = () => {}}) =>
    ({
        fileId,
        onFileIdChange,
        fileTypes,
        onUpload: getUploader(attributeId),
        getFileInfo: getFileInfoRequester(attributeId, personId),
        setFileUploading,
    });

export const AttributeFileUploadButton = contramapProps(injectFileApis, FileUploadButton);
