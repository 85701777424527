import React, { useRef } from 'react';
import Field from 'components/uikit/Field';
import InputText from 'components/uikit/InputText';
import Button from 'components/uikit/Button';
import Tabs from 'components/uikit/Tabs';
import insertText from 'insert-text-at-cursor';
import { getStateFromTextArea, selectWord } from 'utils';
import { converter } from 'libs/showdown';
import { faBold, faItalic, faStrikethrough, faLink } from '@fortawesome/free-solid-svg-icons';

export const ParagraphModal = props => {
    const { className, editItem, handleAttributesChange } = props;
    const textArea = useRef();

    const onChange = e => handleAttributesChange(editItem.index, 'text', e.target.value);

    const setBold = () => {
        const state1 = getSelection();
        const state2 = replaceSelection(`**${state1.selectedText}**`);
        setSelectionRange({
            start: state2.selection.end - 2 - state1.selectedText.length,
            end: state2.selection.end - 2,
        });
    };

    const setItalic = () => {
        const state1 = getSelection();
        const state2 = replaceSelection(`*${state1.selectedText}*`);
        setSelectionRange({
            start: state2.selection.end - 1 - state1.selectedText.length,
            end: state2.selection.end - 1,
        });
    };

    const setStrike = () => {
        const state1 = getSelection();
        const state2 = replaceSelection(`~~${state1.selectedText}~~`);
        setSelectionRange({
            start: state2.selection.end - 2 - state1.selectedText.length,
            end: state2.selection.end - 2,
        });
    };

    const setLink = () => {
        const state1 = getSelection();
        const state2 = replaceSelection(`[${state1.selectedText}](https://url)`);
        setSelectionRange({
            start: state1.selection.end + 11,
            end: state2.selection.end - 1,
        });
    };

    const onExecute = type => () => {
        switch (type) {
            case 'bold':
                setBold();
                return;
            case 'italic':
                setItalic();
                return;
            case 'strike':
                setStrike();
                return;
            case 'link':
                setLink();
                return;
            default:
                return;
        }
    };

    const getSelection = () => {
        const { current } = textArea;
        const state = getStateFromTextArea(current);
        const newSelectionRange = selectWord({
            text: state.text,
            selection: state.selection,
        });
        return setSelectionRange(newSelectionRange);
    };

    const checkUrl = text => {
            const checkUrlReg = /\((?!(http(s?):\/\/))(\S*?\.\S{1,4})\)/g;
            const newUrl = "(https://$3)";
            return text.trim().replace(checkUrlReg, newUrl);
    };

    const replaceSelection = text => {
        const { current } = textArea;
        insertText(current, text);
        return getStateFromTextArea(current);
    };

    const setSelectionRange = selection => {
        const { current } = textArea;
        current.focus();
        current.selectionStart = selection.start;
        current.selectionEnd = selection.end;
        return getStateFromTextArea(current);
    };

    const onChangeTab = () => {
        handleAttributesChange(editItem.index, 'text', checkUrl(editItem.attributes.text));
    };

    return (
        <div className={className}>
            <Tabs defaultTab={0} className="ParagraphModal" onChangeTab={onChangeTab}>
                <div label="Редактор">
                    <div className="ParagraphModal__Tab">
                        <Field size={'100'} className="ParagraphModal__Edit">
                            <div className="ButtonsWrapper">
                                <Button
                                    onClick={onExecute('bold')}
                                    className="ButtonsWrapper__Item"
                                    size="md"
                                    faIcon={faBold}
                                />
                                <Button
                                    onClick={onExecute('italic')}
                                    className="ButtonsWrapper__Item"
                                    size="md"
                                    faIcon={faItalic}
                                />

                                <Button
                                    onClick={onExecute('strike')}
                                    className="ButtonsWrapper__Item"
                                    size="md"
                                    faIcon={faStrikethrough}
                                />
                                <Button
                                    onClick={onExecute('link')}
                                    className="ButtonsWrapper__Item"
                                    size="md"
                                    faIcon={faLink}
                                />
                            </div>
                            <InputText
                                className="ParagraphModal__Text"
                                forwardRef={textArea}
                                maxLength={4000}
                                value={editItem.attributes.text}
                                onChange={onChange}
                            />
                        </Field>
                    </div>
                </div>
                <div label="Просмотр">
                    <div className="ParagraphModal__Tab">
                        <div
                            className="ParagraphModal__Preview"
                            dangerouslySetInnerHTML={{
                                __html: converter.makeHtml(editItem.attributes.text),
                            }}
                        />
                    </div>
                </div>
            </Tabs>
        </div>
    );
};
