import React from 'react';
import { queryOperationExtProps } from './QueryOperation';
import classnames from "classnames";
import uuid from "uuid/v4";

const ActiveFilters = (props) => {
    const { activefilters, searchTerm } = props;

    const setFilters = (item) => {
        let childs = null;
        if (item.childs && !item.label) {
            childs = item.childs.filter((x) => !x.parent).map(setFilters);
        }

        if (childs && childs.length) {
            return childs;
        }

        const background = queryOperationExtProps[item.operation] || {};
        const style = background;

        return (
            <React.Fragment key={item.id}>
                {item.label && (
                    <div className="ActiveSearchFilter__Item" style={style}>
                        <div className={classnames('ActiveSearchFilter__Text', {
                            'ActiveSearchFilter__Text--excluded': item.excludeFromSearch || item.indicateExcludedFromSearch
                        })}>{item.label}</div>
                    </div>
                )}
            </React.Fragment>
        );
    };

    const renderActiveFilters =
        activefilters || searchTerm ? (
            <div className="ActiveSearchFilter">
                {!!searchTerm && setFilters({label: `Поиск: ${searchTerm}`, id: uuid()})}
                {activefilters && activefilters.length !== 0 && activefilters.map((activeSection) =>
                    activeSection.childs.map(setFilters),
                )}
            </div>
        ) : null;

    return renderActiveFilters;
};

export default ActiveFilters;
