import React, {Component} from 'react';
import {Route, Switch} from 'react-router';
import {templatesTypesRoute } from 'routes';
import TemplatesTypes from './TemplatesTypes';
import NotFound from 'components/NotFound'
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import { ALLOWED_MODERATE_NOTIFICATION_TYPES } from 'rightsController';

class NotificationTypesRoutes extends Component {

    renderNotFound = () => {
        return <NotFound/>;
    };

    render() {
        return (
            <Switch>
                <ProtectedRoute exact path={templatesTypesRoute.url} component={TemplatesTypes} allowedRoles={ALLOWED_MODERATE_NOTIFICATION_TYPES} />
                <Route component={this.renderNotFound}/>
            </Switch>
        );
    }
}

export default NotificationTypesRoutes;