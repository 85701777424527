import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    AGE_FILTER_ID,
    PROFESSION_FILTER_ID,
    REGIONS_FILTER_ID,
    SOCIAL_FILTER_ID,
    MANAGEMENT_FILTER_ID,
    EDUCATION_FILTER_ID,
    WISHLIST_FILTER_ID,
    EVALUATION_FILTER_ID,
} from './constants';
import ProfessionFilter from './Filters/ProfessionFilter';
import RegionFilter from './Filters/RegionFilter';
import SocialFilter from './Filters/SocialFilter';
import ManagementFilter from './Filters/ManagementFilter';
import EducationFilter from './Filters/EducationFilter';
import WishListFilters from './Filters/WishListFilters';
import PersonFilters from './Filters/PersonFilters';
import EvaluationFilter from './Filters/EvaluationFilter';
import GoBackButton from 'components/Lk/Uikit/Buttons/GoBackButton';
import CloseButton from 'components/Lk/Uikit/Buttons/CloseButton';
import WithKeyboardHandler from 'components/Lk/Hooks/WithKeyboardHandler';
import classnames from 'classnames';

const SearchFilter = props => {
    const {
        filterId,
        catalogs,
        mainFilter,
        setMainFilter: setFilters,
        name,
        hideFilter,
        activeFilter,
        restoreMenuId,
        setMainFilterCopy,
        extraFields,
        searchTerm,
        setSearchTerm,
        isFocused
    } = props;

    const [defaultFilters, setDefaultFilters] = useState([]);
    const [previousFilter] = useState(mainFilter);

    const filterRef = useRef();

    useEffect(() => {
        setMainFilterCopy(mainFilter);
    }, [mainFilter, setMainFilterCopy]);

    const updateMainFilter = async () => {
        await setMainFilterCopy(mainFilter);
        hideFilter({});
    };

    const renderFilter = filterId => {
        return <div className={classnames('LKSearchCardVisibleFIlter__Filter', {
            'LKSearchCardVisibleFIlter__Filter--isFocused': isFocused,
        })} id="SearchFilter">{makeFIlter(filterId)}</div>;
    };

    const setFiltersState = (filters, filterId, replace, othersFields) => {
        if (!filters?.length) {
            return true;
        }

        const cleanFilters = replace 
            ? filters 
            : filters.filter(x => !checkDuplicate(x));

        setSearchTerm(searchTerm);
        setFilters(cleanFilters, filterId, replace, othersFields);
        return true;
    };

    const selectStyles = {
        container: base => ({
            ...base,
            flex: 1,
        }),

    };

    const updateFiltersWithCondition = (filterId, condition, withCurrent = false) => {
        const updatedSection = mainFilter.find(x => x.filterId === filterId);
        const filters = 
        withCurrent
        ?
            updatedSection?.childs.map(x => {
                return {
                    ...x,
                ...x.childs && {childs: x.childs.map(c => ({...c, joinOperation: condition}))}
                };
            })
        :
            updatedSection?.childs.map(x => {
                return {
                    ...x,
                    joinOperation: condition,
                };
            });

        setFilters(filters, filterId, true);
    };

    const getGroupCondition = (filterId, withCurrent = false) => {
        const activeSection = mainFilter.filter(x => x && x.filterId === filterId);
        const filters = activeSection && activeSection[0] ? activeSection[0].childs : [];

        return withCurrent
            ? filters[0]?.childs
                ? filters[0].childs[0].joinOperation
                : 'AND'
            : filters.length !== 0
                ? filters[0].joinOperation
                : 'AND';
    };

    const commonProps = {
        initialRef: filterRef,
        selectStyles,
        setFiltersState,
        catalogs,
        filtersState: mainFilter,
        updateFiltersWithCondition,
        getGroupCondition,
        activeFilter,
        onSuccess: updateMainFilter,
        extraFields,
        defaultFilters, 
        setDefaultFilters,
        isFocused
    };


    const formatFilters = (filter = []) => {
        return filter.reduce((acc, c) => {
            if (c?.childs && !c?.group) {
                acc = acc.concat(formatFilters(c.childs));
            } else {
                acc = c !== undefined ? acc.concat(c) : acc;
            }
            return acc;
        }, []);
    };

    const checkDuplicate = filter => {
        const wrapper = mainFilter.find(x => x && x.filterId === filterId);
        const oldFilters = formatFilters(wrapper?.childs || []);
        const newFilters = formatFilters([filter]);

        if (!wrapper) {
            return false;
        }
       
            
        let duplicate = true;
        for (const f of newFilters) {
            if (
                f?.label &&
                !oldFilters.find(
                    x =>
                        (!activeFilter || (x.id !== activeFilter.id && x.group !== activeFilter.group)) &&
                        (x.label === f.label && x.value===f.value) &&
                        x.excludeFromSearch === f.excludeFromSearch &&
                        x.withCurrentSection === f.withCurrentSection,
                )
            ) {
                duplicate = false;
            }
        }

        return duplicate;
    };

    const makeFIlter = filterId => {
        switch (filterId) {
            case AGE_FILTER_ID:
                return <PersonFilters {...commonProps} filterId={AGE_FILTER_ID} />;
            case PROFESSION_FILTER_ID:
                return <ProfessionFilter {...commonProps} filterId={filterId} withCurrent />;
            case EDUCATION_FILTER_ID:
                return <EducationFilter {...commonProps} filterId={filterId} />;
            case REGIONS_FILTER_ID:
                return <RegionFilter {...commonProps} filterId={filterId} />;
            case SOCIAL_FILTER_ID:
                return <SocialFilter {...commonProps} filterId={filterId} />;
            case MANAGEMENT_FILTER_ID:
                return <ManagementFilter {...commonProps} filterId={filterId} />;
            case WISHLIST_FILTER_ID:
                return <WishListFilters {...commonProps} filterId={filterId} />;
            case EVALUATION_FILTER_ID:
                return <EvaluationFilter {...commonProps} filterId={filterId} />;
            default:
                return null;
        }
    };

    const restoreFilter = (restoreMenu, flushFilter) => () => {
        hideFilter({ restoreMenu, flushFilter, previousFilter });
    };

    return (
        <>
            <div className="LKSearchCardVisibleFIlterHeader">
                {!restoreMenuId && (
                    <div className="LKSearchCardVisibleFIlterHeader__BackButton">
                        <GoBackButton action={restoreFilter(false, true)} />
                    </div>
                )}

                <div className="LKSearchCardVisibleFIlterHeader__Name">{name}</div>
                {restoreMenuId && (
                    <div className="LKSearchCardVisibleFIlterHeader__Close">
                        <CloseButton onClick={restoreFilter(true, true)} />
                    </div>
                )}
            </div>
            {renderFilter(filterId)}
        </>
    );
};

const mapStateToProps = state => {
    return {
        catalogs: state.catalogs,
        activeFilter: state.filterSearch.activeFilter,
        restoreMenuId: state.filterSearch.restoreMenuId,
        extraFields: state.filterSearch.extraFields,
    };
};

export default connect(mapStateToProps)(WithKeyboardHandler(SearchFilter, 'SearchFilter', {
    block: 'start',
    inline: 'center'
}));
