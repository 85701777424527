import React, { useEffect, useState } from 'react';
import './AssignedIssue.scss';
import classnames from 'classnames';
import { ACTIVE_DETAIL_CARD } from 'components/Lk/Basic/constants';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';
import { getIssueAssigned, updateIssueMultiple } from 'api';
import InfoField from 'components/Lk/Uikit/InfoField';
import { getUserShortName, formatDate } from 'utils.js';
import StickyFooter from 'components/Lk/Common/StickyFooter';
import ProfileButton from 'components/Lk/Uikit/Buttons/Button/ProfileButton';
import AssignedSatus from './AssignedSatus';
import { push } from 'connected-react-router';

import { ISSUE_STATUS_ENUM, ISSUE_HISTORY_RECORD_TYPES, ISSUE_TYPE_ENUM } from 'constants.js';
import { connect } from 'react-redux';
import { lkIssueTracking, lkIssueTrackingProfile, lkIssueTrackingEdit, lkIssue } from 'routes';
import { showSuccessAlert, showErrorAlert } from 'ducks/Alert';
import { setUpdateTracking } from 'ducks/Issues';
import { getError } from 'serviceErrors';
import { converter } from 'libs/showdown';
import Icons from 'components/Lk/Uikit/Icons';
import { Link } from 'react-router-dom';
import IssueHistory from './IssueHistory';
import { MENU_PROFILE } from '../Profile/constants';
import IssueFile from '../Tasks/IssueFile';
import Loader from 'components/common/Loader';

const AssignedIssue = props => {
    const {
        push,
        setUpdateTracking,
        match: { params = {} },
        activeCard,
        setActiveCard,
        authUser,
    } = props;

    const [issue, setIssue] = useState();
    const [loading, setLoad] = useState(false);
    const [isWrap, setWrap] = useState(true);

    useEffect(() => {
        let mounted = true;

        const load = async id => {
            mounted && setLoad(true);
            const result = await getIssueAssigned(id);
            mounted && setIssue(result.data);
            mounted && setLoad(false);
            if (
                result.data.issues.length === 1 &&
                result.data.id === result.data.issues[0].id &&
                result.data.issues[0].assigneeId === authUser.id
            ) {
                push(
                    result.data.status === ISSUE_STATUS_ENUM.Completed
                        ? '/'
                        : lkIssue.buildUrl({ id }),
                );
            }
        };

        if (params.id) {
            load(params.id);
        }

        return () => {
            mounted = false;
        };
    }, [params.id, authUser.id, push]);

    const issues = issue?.issues || [];

    const disabledBtn =
        loading ||
        (issues.length &&
            issues.filter(
                x =>
                    [
                        ISSUE_STATUS_ENUM.Completed,
                        ISSUE_STATUS_ENUM.Deleted,
                        ISSUE_STATUS_ENUM.Canceled,
                    ].indexOf(x.status) === -1,
            ).length !== 0);

    const disableEdit = issue && issue.status === ISSUE_STATUS_ENUM.Closed;

    setActiveCard(ACTIVE_DETAIL_CARD);
    const isMobileActive = activeCard === ACTIVE_DETAIL_CARD;

    const getCommonError = (_, payload) => payload || `Произошла непредвиденная ошибка`;

    const setRequest = criteria => {
        const formData = new FormData();
        formData.append('data', JSON.stringify(criteria));

        return formData;
    };

    const onSave = async () => {
        try {
            setLoad(true);
            const criteria = {
                ...issue,
                assigneeIds: issues.map(x => x.assigneeId),
                status: ISSUE_STATUS_ENUM.Closed,
            };
            await updateIssueMultiple(setRequest(criteria));
            setUpdateTracking(true);
            push(lkIssueTracking.url);
            showSuccessAlert('Статус задачи обновлен');
        } catch (error) {
            const reqError = getError(error, getCommonError);
            showErrorAlert(reqError.message);
        } finally {
            setLoad(false);
        }
    };

    const onEdit = () => {
        push(lkIssueTrackingEdit.buildUrl({ id: params.id }));
    };

    const openIssueHistory = () => {
        setWrap(!isWrap);
    };

    const renderIssue = item => {
        const fullName = getUserShortName(item.assignee);
        const reportRecord =
            item.status === ISSUE_STATUS_ENUM.Completed &&
            item.issueHistoryRecords.find(
                x => x.issueHistoryRecordType === ISSUE_HISTORY_RECORD_TYPES.StatusUpdated,
            );

        const defferedRecord = item.issueHistoryRecords.find(
            x => x.issueHistoryRecordType === ISSUE_HISTORY_RECORD_TYPES.Deferred,
        );

        return (
            <div className="AssignedPerson" key={item.id}>
                <div
                    className="AssignedPerson__Title"
                    data-test={`AssignedPerson_Title-text userId_${item?.assignee?.id}`}
                >
                    {item?.assignee?.personId ? (
                        <Link
                            to={lkIssueTrackingProfile.buildUrl({
                                id: item?.assignee?.personId,
                            })}
                            data-test="AssignedPerson_Title-link"
                        >
                            {fullName}
                        </Link>
                    ) : (
                        fullName
                    )}
                </div>
                {reportRecord && reportRecord.description && (
                    <div>
                        Отчет:{' '}
                        <i
                            className="AssignedPerson__Description"
                            data-test={`AssignedPerson_Description--text userId_${item?.assignee?.id}`}
                        >
                            "
                            {reportRecord && reportRecord.description
                                ? reportRecord.description
                                : ''}
                            "
                        </i>
                    </div>
                )}
                {!reportRecord && defferedRecord && defferedRecord.description && (
                    <div>
                        Отложена:{' '}
                        <i
                            className="AssignedPerson__Description"
                            data-test={`AssignedPerson_Description--text userId_${item?.assignee?.id}`}
                        >
                            "
                            {defferedRecord && defferedRecord.description
                                ? defferedRecord.description
                                : ''}
                            "
                        </i>
                    </div>
                )}
                <div className="AssignedStatus">
                    <AssignedSatus
                        status={item.status}
                        dataTest={`AssignedPerson userId_${item?.assignee?.id}`}
                    />
                </div>
            </div>
        );
    };

    const onClose = () => {
        push(lkIssueTracking.url);
    };

    const authorName =
        issue?.author &&
        `${issue.author.lastName} ${issue.author.firstName} ${issue.author.middleName}`;

    const issueCard = () => {
        return (
            <>
                <div className="AssignedIssuePageDetail">
                    <div className="AssignedIssuePageDetail__Header">
                        <h3 className="LKLayout__Title LKLayout__Title--JustifyStart">Задача</h3>
                        <span
                            className="LKLayout__IconClose"
                            onClick={onClose}
                            data-test="AssignedIssue_PageClose--button"
                        >
                            <Icons name="iconClose" />
                        </span>
                    </div>

                    {issue && (
                        <div className="AssignedIssuePageDetail__Content">
                            <div className="AssignedStatus AssignedStatus--border">
                                {issue &&
                                issue.status &&
                                issue.status === ISSUE_STATUS_ENUM.Closed ? (
                                    <AssignedSatus
                                        status={ISSUE_STATUS_ENUM.Closed}
                                        label="Закрыта"
                                        dataTest={`AssignedIssue_Closed--text`}
                                    />
                                ) : issue &&
                                  issue.type !== ISSUE_TYPE_ENUM.ChangePersonGrowthPlan ? (
                                    issue &&
                                    issue.issues &&
                                    issue.issues.every(
                                        x => x.status === ISSUE_STATUS_ENUM.Completed,
                                    ) ? (
                                        <AssignedSatus
                                            status="InProgress"
                                            label="Ожидает подтверждения"
                                            dataTest={`AssignedIssue_Approved--text`}
                                        />
                                    ) : (
                                        <AssignedSatus
                                            status="InProgress"
                                            label="Ожидает выполнения"
                                            dataTest={`AssignedIssue_ToDo--text`}
                                        />
                                    )
                                ) : null}
                            </div>
                            <h3
                                className="AssignedIssuePageDetail__Title"
                                data-test="AssignedIssue_Title--text"
                            >
                                {issue.title}
                            </h3>
                            <div
                                className="AssignedIssuePageDetail__Description"
                                dangerouslySetInnerHTML={{
                                    __html: converter.makeHtml(
                                        issue.description.replace(/\/Person/g, '/services/profile'),
                                    ),
                                }}
                                data-test="AssignedIssue_Description--text"
                            ></div>

                            <div className="AssignedIssuePageDetail__AssignedWrapper">
                                <span className="AssignedIssuePageDetail__Assigned">
                                    Ответственные:
                                </span>
                                {issues.map(renderIssue)}
                            </div>

                            <InfoField title="Срок выполнения" noIcon>
                                {issue.dueDate && (
                                    <>
                                        {new Date(issue.dueDate) < new Date() ? (
                                            <>
                                                <div
                                                    className="AssignedIssuePageDetail__DueDate--red"
                                                    data-test="AssignedIssue_DueDate--text"
                                                >
                                                    {formatDate(issue.dueDate)}
                                                </div>
                                                <div className="AssignedStatus">
                                                    <AssignedSatus
                                                        status="canceled"
                                                        label="Просрочена"
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            <div data-test="AssignedIssue_DueDate--text">
                                                {formatDate(issue.dueDate)}
                                            </div>
                                        )}
                                    </>
                                )}
                            </InfoField>
                            <InfoField title="Файлы" noIcon>
                                <IssueFile files={issue.issueFiles} />
                            </InfoField>
                            {/*<InfoField title="Подпись" noIcon>*/}
                            {/*    {issue.signature}*/}
                            {/*</InfoField>*/}
                            <div className="IssueItemPage__Director">
                                {issue.author && issue.author.personId ? (
                                    <Link
                                        to={{
                                            pathname: lkIssueTrackingProfile.buildUrl({
                                                id: issue.author.personId,
                                            }),
                                            search: `type=${MENU_PROFILE}`,
                                        }}
                                        data-test="AssignedIssue_Director--link"
                                    >
                                        {authorName}
                                    </Link>
                                ) : (
                                    <>{authorName}</>
                                )}
                            </div>
                            {issue?.latestHistory?.length > 0 && (
                                <InfoField
                                    title="История изменений"
                                    isWrap={isWrap}
                                    onWrap={openIssueHistory}
                                    value={true}
                                    tooltip={false}
                                    isWrapIcon={true}
                                    iconToTop={true}
                                    noIcon
                                >
                                    {isWrap && (
                                        <IssueHistory
                                            lkUrl={lkIssueTrackingProfile}
                                            history={issue?.latestHistory}
                                        />
                                    )}
                                </InfoField>
                            )}
                        </div>
                    )}
                </div>
                <StickyFooter>
                    <div className="AssignedIssuePageDetail__Buttons">
                        <ProfileButton
                            className="AssignedIssuePageDetail__Button AssignedIssuePageDetail__Button--left"
                            onClick={onEdit}
                            dataTest="AssignedIssue_Edit--button"
                            disabled={disableEdit}
                        >
                            Редактировать
                        </ProfileButton>

                        <ProfileButton
                            className="AssignedIssuePageDetail__Button AssignedIssuePageDetail__Button--right"
                            disabled={disabledBtn}
                            onClick={onSave}
                            dataTest="AssignedIssue_Approve--button"
                        >
                            Подтвердить ознакомление
                        </ProfileButton>
                    </div>
                </StickyFooter>
            </>
        );
    };

    return (
        <div
            className={classnames('DetailsCard', { 'DetailsCard--isMobileActive': isMobileActive })}
        >
            <MainCardMenu />
            {loading ? <Loader /> : issueCard()}
        </div>
    );
};

const mapStateToProps = store => ({ authUser: store.auth.user });

export default connect(
    mapStateToProps,
    { push, setUpdateTracking },
)(AssignedIssue);
