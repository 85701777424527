import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { lkIssueDone, lkIssue, home } from 'routes';
import { ACTIVE_DETAIL_CARD } from 'components/Lk/Basic/constants';
import { updateIssueStatus, updateDueDate } from 'api';
import { push } from 'connected-react-router';
import InfoField from 'components/Lk/Uikit/InfoField';
import StickyFooter from 'components/Lk/Common/StickyFooter';
import StickyBottomAcceptMenu from 'components/Lk/Uikit/Buttons/StickyBottomAcceptMenu';
import { showSuccessAlert, showErrorAlert } from 'ducks/Alert';
import { updatedIssues } from 'ducks/Issues';
import { serviceResultCode, getError, getCode } from 'serviceErrors';
import { endOf } from 'utils';
import './IssueItemPageDetail.scss';
import { ISSUE_STATUS_ENUM } from 'constants.js';
import Loader from 'components/common/Loader';
import moment from 'libs/moment';

const IssueItemPageDetail = (props) => {
    const {
        activeCard,
        setActiveCard,
        pathname,
        issuesProps,
        showSuccessAlert,
        showErrorAlert,
        push,
        updatedIssues,
        match: {
            params: { id },
        },
    } = props;

    const isMobileActive = activeCard === ACTIVE_DETAIL_CARD;
    setActiveCard(ACTIVE_DETAIL_CARD);

    const [comment, setComment] = useState('');
    const [issue, setIssue] = useState({});
    const [defferDate, setDefferDate] = useState();
    const [loading, setLoading] = useState(false);

    const choicePage = pathname === lkIssueDone.buildUrl({ id });

    useEffect(() => {
        let mounted = true;

        const getDefferDate = (dueDate) => {
            return moment(dueDate).isSameOrAfter(new Date())
                ? new Date(moment(dueDate).add(1, 'days'))
                : new Date(moment(new Date()).add(1, 'days'));
        };

        if (issuesProps.data.length !== 0) {
            const result = issuesProps.data.find((x) => x.id === id);
            if (!id || !result) {
                push(home.buildUrl());
                return () => {
                    mounted = false;
                };
            }
            mounted && setIssue(result);
            mounted && setDefferDate(getDefferDate(result.dueDate));
        }

        return () => (mounted = false);
    }, [issuesProps, id, push]);

    const handlerOnAccept = async () => {
        const IssueUpdateError = (code, payload) => {
            switch (code) {
                case serviceResultCode.UnableToOpenOrModifyDeletedEntity:
                    return 'Невозможно изменить задачу т.к. задача была удалена.';
                case serviceResultCode.NotFound:
                    return `${payload}`;
                case serviceResultCode.ValidationErrors:
                    return `${payload}`;
                default:
                    return `Произошла непредвиденная ошибка`;
            }
        };

        try {
            setLoading(true);
            const result = choicePage
                ? await updateIssueStatus(id, ISSUE_STATUS_ENUM.Completed, comment)
                : await updateDueDate(id, issue.dueDate, comment);

            result?.status === 200 && showSuccessAlert('Статус задачи успешно обновлен');
            updatedIssues();
            push('/');
        } catch (error) {
            setLoading(false);
            const reqError = getError(error, IssueUpdateError);
            if (getCode(error) === serviceResultCode.UnableToOpenOrModifyDeletedEntity) {
                showErrorAlert('Невозможно изменить задачу т.к. задача была удалена.');
                push(home.buildUrl());
            } else {
                showErrorAlert(reqError.message);
            }
        }
    };

    const handlerOnCancel = () => {
        push(lkIssue.buildUrl({ id: issue.id }));
    };

    const onChangeDatePicker = (date) => {
        if (!date) {
            return;
        }

        const dueDate = endOf(date, 'day');

        if (
            issue.dueDate &&
            !dueDate.isSame(issue.dueDate, 'day') &&
            dueDate.isSameOrAfter(defferDate, 'day')
        ) {
            setIssue({ ...issue, dueDate: date ? dueDate.format() : null });
        }
    };

    const datePickerProps = {
        dateFormat: 'dd.MM.yyyy',
        mobileFormat: 'DD.MM.YYYY',
        minDate:  new Date(defferDate),
        dataTest: 'EditIssue_DueDate--datepicker',
        type: 'date',
        dateConfig: {
            date: {
                format: 'D',
                caption: 'Day',
                step: 1,
            },
            month: {
                format: 'M',
                caption: 'Mon',
                step: 1,
            },
            year: {
                format: 'YYYY',
                caption: 'Year',
                step: 1,
            },
        },
    };

    const MenuSetup = {
        background: false,
        btns: [
            {
                title: 'Отменить',
                onClick: handlerOnCancel,
                type: 'Cancel',
                dataTest: 'IssueItemPageDetail_Cancel--button',
            },
            {
                title: 'Подтвердить',
                onClick: handlerOnAccept,
                type: 'Accept',
                disable: !issue.dueDate,
                dataTest: 'IssueItemPageDetail_Accept--button',
            },
        ],
    };

    return (
        <div
            className={classnames('DetailsCard', { 'DetailsCard--isMobileActive': isMobileActive })}
        >
            <div className="IssueItemPageDetail">
                <h3 className="LKLayout__Title LKLayout__Title--JustifyStart">
                    {choicePage ? 'Выполнить' : 'Отложить задачу'}
                </h3>
                <div className="IssueItemPageDetail__Content">
                    {choicePage && (
                        <InfoField
                            type="textarea"
                            title="Комментарий / отчет"
                            isEdit={true}
                            value={comment}
                            onChange={setComment}
                            placeholder="Оставьте комментарий или отчет"
                            autoFocus
                            noIcon
                            inputProps={{
                                dataTest: 'IssueItemPageDetail_EditTextarea--input',
                            }}
                        />
                    )}
                    {!choicePage && (
                        <>
                            <InfoField
                                title="Срок выполнения задачи"
                                inputProps={datePickerProps}
                                value={new Date(moment.max([moment(issue.dueDate), moment(defferDate)]))}
                                onChange={onChangeDatePicker}
                                placeholder="Добавить дату"
                                isEdit={true}
                                noIcon
                            />
                            <InfoField
                                type="textarea"
                                title="Комментарий"
                                isEdit={true}
                                value={comment}
                                onChange={setComment}
                                placeholder="добавить"
                                autoFocus
                                noIcon
                                inputProps={{
                                    dataTest: 'IssueItemPageDetail_EditComment--input',
                                }}
                            />
                        </>
                    )}
                </div>
            </div>
            <StickyFooter>
                <StickyBottomAcceptMenu {...MenuSetup} />
            </StickyFooter>
            {loading && <Loader overlay />}
        </div>
    );
};

const mapStateToProps = (store) => ({
    issuesProps: store.issues,
    pathname: store.router.location.pathname,
});

const actions = { showSuccessAlert, showErrorAlert, push, updatedIssues };
export default connect(mapStateToProps, actions)(IssueItemPageDetail);
