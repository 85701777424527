import React, { useState, useRef, useEffect } from 'react';
import './ProfileEvaluate.scss';
import CardItemBlock from 'components/Lk/Common/CardItemBlock';
import Service from 'components/Lk/Common/Service';
import { connect } from 'react-redux';
import EvaluateBlock from './EvaluateBlock';
import { getTestProvidersActive, getCompetencies } from 'api';
import { groups } from 'components/Person/Evaluate/chartsHelper';
import Tests from './Tests';
import { EVALUATIONTEST_STATUS } from 'constants.js';

const EVALUATE_ITEMS = 'evaluate';
const EVALUATE_ACTUAL = 'actual';
const EVALUATE_ARCHIVE = 'archive';

const EvaluateItems = [
    { id: 1, icon: 'evaluateIcon', title: 'Сводные результаты', type: EVALUATE_ITEMS },
    { id: 2, icon: 'actualResults', title: 'Актуальные результаты', type: EVALUATE_ACTUAL },
    { id: 3, icon: 'archiveResults', title: 'Архив результатов', type: EVALUATE_ARCHIVE },
];

export const ProfileEvaluate = props => {
    const {
        person: { id, testsResults, requestsData },
    } = props;

    const containerRef = useRef();

    const [active, setAvctive] = useState(EvaluateItems.find(x => x.type === EVALUATE_ITEMS));

    const [state, setState] = useState({});

    useEffect(() => {
        let mounted = true;
        const updateData = async () => {
            const testProviders = await getTestProvidersActive();
            const compenciesModels = await getCompetencies(id);

            const newData =
                testsResults.data &&
                testsResults.data.flatMap(x => ({
                    ...x.evaluationTest,
                    resultType: x.status === 'Relevant' ? EVALUATIONTEST_STATUS.Relevant : EVALUATIONTEST_STATUS.Archived,
                    isOpen: x.status === 'Relevant' ? true : false,
                    result: {
                        id: x.id,
                        completedOn: x.completedOn,
                        criteria: x.criteria,
                        evaluationTestId: x.evaluationTestId,
                        linkToResult: x.linkToResult,
                        personId: x.personId,
                        resultFileId: x.resultFileId,
                    },
                }));

            mounted && setState({
                ...state,
                competenciesModels: compenciesModels.data,
                testProviders:
                    (testProviders &&
                        testProviders.data &&
                        testProviders.data.map(x => ({
                            ...x,
                            code: x.id,
                            name: x.title,
                            id: x.id,
                        }))) ||
                    [],
                testsResults: newData,
                requests: requestsData ? requestsData.data : [],
                loaded: true,
                selectedGroup: groups[0],
            });
        };
        updateData();

        return () => {
            mounted = false;
        };
        
        //TODO: переписать, пока так, обновляет постоянно
        // eslint-disable-next-line
    }, [testsResults, id]);

    const renderTests = () => {
        if (!active) {
            return null;
        }
        switch (active.type) {
            case EVALUATE_ITEMS:
            default:
                return (
                    <EvaluateBlock
                        testsResults={state.testsResults}
                        testProviders={state.testProviders}
                        competenciesModels={state.competenciesModels}
                        personId={id}
                    />
                );

            case EVALUATE_ACTUAL:
                return (
                    <Tests
                        testsResults={state.testsResults}
                        testProviders={state.testProviders}
                        competenciesModels={state.competenciesModels}
                        personId={id}
                        actual={true}
                    />
                );

            case EVALUATE_ARCHIVE:
                return (
                    <Tests
                        testsResults={state.testsResults}
                        testProviders={state.testProviders}
                        competenciesModels={state.competenciesModels}
                        personId={id}
                        actual={false}
                    />
                );
        }
    };

    const onActiveChange = item => () => {
        setAvctive(!active || item.type !== active.type ? item : null);
    };

    return (
        <div className="LKProfileEvaluate" ref={containerRef}>
            <div></div>

            {EvaluateItems.map(item => (
                <div className="LKProfileEvaluate__MenuBlock" key={item.id}>
                    <CardItemBlock grey key={item.id}>
                        <Service
                            title={item.title}
                            icon={item.icon}
                            onClick={onActiveChange(item)}
                        />
                    </CardItemBlock>

                    {active && active.type === item.type && (
                        <div>
                            {active && item.type === active && (
                                <div className="LKProfileEvaluate__Header">{active.title}</div>
                            )}
                            {renderTests()}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

const mapStateToProps = state => ({
    person: state.person.data,
});

export default connect(mapStateToProps)(ProfileEvaluate);
