import React from 'react';
import { connect } from 'react-redux';
import { queryOperationExtProps, queryJoinOperation } from './QueryOperation';
import classnames from 'classnames';
import uuid from 'uuid/v4';

const UserActionActiveFilters = (props) => {
    const { activefilters, searchTerm, roles, groups, isSearch } = props;

    const getLabel = (item) => {
        switch (item.Type) {
            case 'Roles':
                return `${item.Name}: ${roles
                    .filter((x) =>
                        item.Value.split(',')
                            .map((x) => +x)
                            .includes(x.id),
                    )
                    .map((x) => x.name)
                    .join(', ')}`;
            case 'Groups':
                return `${item.Name}: ${groups
                    .filter((x) =>
                        item.Value.split(',')
                            .map((x) => +x)
                            .includes(x.id),
                    )
                    .map((x) => x.name)
                    .join(', ')}`;
            default:
                return `${item.Name}: ${item.Value}`;
        }
    };

    const setFilters = (item) => {
        return (
            <React.Fragment key={uuid()}>
                {item.Name && (
                    <div className="UserActionActiveSearchFilter__Item">
                        <div className="UserActionActiveSearchFilter__Text">{getLabel(item)}</div>
                    </div>
                )}
            </React.Fragment>
        );
    };

    const setSearchFilters = (item, index, length, isFirst = true) => {
        let childs = null;
        if (item.parent && item.childs && !item.label) {
            const filteredChilds = item.childs.filter(
                (x) => (!x.parent && x.label) || (x.parent && x.childs),
            );
            childs = filteredChilds.map((child, index) =>
                setSearchFilters(child, index, filteredChilds.length - 1, false),
            );
        }

        const joinOperation = queryJoinOperation[item.joinOperation];

        if (childs && childs.length) {
            return (
                <React.Fragment key={uuid()}>
                    {isFirst ? (
                        <>
                            <div className="UserActionActiveSearchFilter__Item">{childs}</div>
                            {index !== length && (
                                <i className="UserActionActiveSearchFilter__Text--mainJoinOperation UserActionActiveSearchFilter__Text--padding">
                                    {joinOperation}
                                </i>
                            )}
                        </>
                    ) : (
                        <>
                            {childs}
                            {index !== length && (
                                <i className="UserActionActiveSearchFilter__Text--mainJoinOperation">
                                    {joinOperation}
                                </i>
                            )}
                        </>
                    )}
                </React.Fragment>
            );
        }

        const background = queryOperationExtProps[item.operation] || {};
        const style = background;

        return (
            item.label && (
                <React.Fragment key={item.id}>
                    <div className="UserActionActiveSearchFilter__Text">
                        <span
                            style={style}
                            className={classnames({
                                'UserActionActiveSearchFilter__Text--excluded':
                                    item.excludeFromSearch || item.indicateExcludedFromSearch,
                            })}
                        >
                            {item.label}
                        </span>
                        {index !== length && (
                            <i className="UserActionActiveSearchFilter__Text--joinOperation">
                                &nbsp;&nbsp;{joinOperation}&nbsp;&nbsp;
                            </i>
                        )}
                    </div>
                </React.Fragment>
            )
        );
    };

    const renderActiveSearchFilters =
        activefilters || searchTerm ? (
            <div className="UserActionActiveSearchFilter">
                {!!searchTerm && setSearchFilters({ label: `Поиск: ${searchTerm}`, id: uuid() })}
                {activefilters &&
                    activefilters.length !== 0 &&
                    activefilters.map((filter, index) =>
                        setSearchFilters(
                            { ...filter, parent: true },
                            index,
                            activefilters.length - 1,
                        ),
                    )}
            </div>
        ) : null;

    const renderActiveFilters =
        activefilters && activefilters.length !== 0 ? (
            <div className="UserActionActiveSearchFilter">
                {activefilters.map((activeSection) => setFilters(activeSection))}
            </div>
        ) : null;

    return isSearch ? renderActiveSearchFilters : renderActiveFilters;
};

const mapStateToProps = ({ dictionary }) => {
    return {
        groups: dictionary.groups.dictionary,
        roles: dictionary.roles.dictionary,
    };
};

export default connect(mapStateToProps, null)(UserActionActiveFilters);
