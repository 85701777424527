import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import {
    getSmsEmailGateMessageById,
    getSmsEmailGateMessages,
    getSmsEmailGateMessagesByUserId,
} from 'api';
import { formatDate } from 'utils';
import Page from 'components/common/Page';
import DataGrid from 'components/common/DataGrid';
import ListTemplate from 'components/uikit/ListTemplate';
import { icons, statuses, types, generateOptions, eventTypes } from '../utils';
import Filters from './Filters';
import ModalDialog from 'components/common/ModalDialog';
import classnames from 'classnames';
import './SmsEmailGate.scss';

const initialFilter = {
    recipient: '',
    status: [],
    type: [],
    createdFrom: null,
    createdTo: null,
};

const initialPaging = { pageNum: 1, pageSize: 50 };
const initialSorting = { created: 'desc' };
const initialCriteria = {
    filter: initialFilter,
    sorted: [{ id: 'created', desc: true }],
};

class SmsEmailGate extends Component {
    state = {
        data: [],
        paging: initialPaging,
        foundCount: 0,
        pageCount: 0,
        statusOptions: [],
        typeOptions: [],
        showDetails: false,
        processing: false,
        message: null,
        filter: initialFilter,
        sorting: initialSorting,
        criteria: initialCriteria,
    };

    columns = () => [
        {
            Header: '',
            width: 20,
            resizable: false,
            sortable: false,
        },
        {
            Header: '',
            accessor: 'type',
            sortable: false,
            resizable: false,
            width: 50,
            Cell: ({ original }) =>
                original.type === types.SMS.value
                    ? icons('#368ef1').sms
                    : original.isOpened
                    ? icons('#b9b9b9').mailOpen
                    : icons('#368ef1').mail,
        },
        {
            Header: 'Дата создания',
            accessor: 'created',
            resizable: false,
            sortable: false,
            maxWidth: 200,
            Cell: ({ original }) => formatDate(original.created, 'DD-MM-YYYY kk:mm:ss'),
        },
        {
            Header: 'Получатель',
            accessor: 'recipient',
            resizable: false,
            sortable: false,
            Cell: ({ original }) =>
                original.status === statuses.ERROR.value ? (
                    <div
                        className={classnames("SmsEmailGate__MessageLink SmsEmailGate__MessageLink--error")}
                        onClick={() => original?.events?.length > 0 && this.getMessage(original.id)}
                    >
                        {original.recipient}
                    </div>
                ) : (
                    <div
                    className="SmsEmailGate__MessageLink"
                    onClick={() => original?.events?.length > 0 && this.getMessage(original.id)}
                >
                    {original.recipient}
                </div>
                ),
        },
        {
            Header: 'Начало отправки',
            accessor: 'gateIn',
            resizable: false,
            sortable: false,
            maxWidth: 200,
            Cell: ({ original }) => formatDate(original.gateIn, 'DD-MM-YYYY kk:mm:ss'),
        },
        {
            Header: 'Отправлено',
            accessor: 'gateOut',
            resizable: false,
            sortable: false,
            maxWidth: 200,
            Cell: ({ original }) => formatDate(original.gateOut, 'DD-MM-YYYY kk:mm:ss'),
        },
        {
            Header: '',
            accessor: 'status',
            resizable: false,
            sortable: false,
            width: 50,
            Cell: ({ original }) => {
                switch (original.status) {
                    case statuses.NEW.value:
                        return icons('#368ef1').process;
                    case statuses.ERROR.value:
                        return icons('#e54848').error;
                    case statuses.SENT.value:
                        return icons('#28d028').ok;
                }

                return '';
            },
        },
        {
            Header: '',
            width: 20,
            resizable: false,
            sortable: false,
        },
    ];

    getData = async (criteria) => {
        const { showPageLoader, hidePageLoader, showErrorAlert, userId } = this.props;
        try {
            showPageLoader();
            const res = this.props.userPage
                ? await getSmsEmailGateMessagesByUserId(userId, criteria)
                : await getSmsEmailGateMessages(criteria);

            const { payload, meta } = res?.data || {};
            this.setState({
                data: payload,
                foundCount: meta?.foundCount,
                pageCount: meta?.pageCount,
            });
        } catch (e) {
            showErrorAlert('Ошибка загрузки данных.');
        } finally {
            hidePageLoader();
        }
    };

    handleStateChange = (blockStateKey, blockState) => {
        this.setState((state) => {
            return {
                [blockStateKey]: {
                    ...state[blockStateKey],
                    ...blockState,
                },
            };
        });
    };

    fillOptions = () => {
        const typeOptions = generateOptions(types);
        const statusOptions = generateOptions(statuses);
        this.setState((state) => ({ ...state, typeOptions, statusOptions }));
    };

    getMessage = async (id) => {
        try {
            this.setState({ processing: true });
            const res = await getSmsEmailGateMessageById(id);
            this.setState({ message: res.data, showDetails: true });
        } catch (e) {
            showErrorAlert('Ошибка загрузки сообщения.');
        } finally {
            this.setState({ processing: false });
        }
    };

    closeMessage = () => {
        this.setState({ showDetails: false, message: null });
    };

    changePageAmount = (pageSize) => {
        this.setState({ paging: { pageNum: 1, pageSize } });
    };

    changePage = (pageNum) => {
        this.setState({ paging: { ...this.state.paging, pageNum: pageNum + 1 } });
    };

    setFilter = () => {
        const { paging, filter, criteria } = this.state;
        this.setState({
            paging: { ...paging, pageNum: 1 },
            sorting: initialSorting,
            criteria: { ...criteria, filter },
        });
    };

    cleanFilter = () => {
        this.setState({
            paging: initialPaging,
            filter: initialFilter,
            sorting: initialSorting,
            criteria: initialCriteria,
        });
    };

    onSortedChange = (newSort) => {
        this.setState({
            sorting: { [newSort[0].id]: newSort[0].desc ? 'desc' : 'asc' },
            criteria: {
                ...this.state.criteria,
                sorted: newSort,
            },
        });
    };

    componentDidMount() {
        this.fillOptions();
        const { paging, criteria, sorting } = this.state;
        const searchCriteria = { paging, filter: criteria.filter, sorting };
        this.getData(searchCriteria);
    }

    componentDidUpdate(prevProps, prevState) {
        const { paging, criteria, sorting } = this.state;
        if (
            +prevState.paging.pageNum !== +paging.pageNum ||
            +prevState.paging.pageSize !== +paging.pageSize ||
            JSON.stringify(prevState.criteria) !== JSON.stringify(criteria)
        ) {
            const type = criteria.filter.type?.map((x) => x.value) || [];
            const status = criteria.filter.status?.map((x) => x.value) || [];
            const searchCriteria = {
                paging,
                filter: { ...criteria.filter, type, status },
                sorting,
            };
            this.getData(searchCriteria);
        }
    }

    getTrProps = (state, rowInfo) => {
        if (rowInfo) {
            return {
                className: rowInfo.original?.isOpened ? 'SmsEmailGate__Row--open' : '',
            };
        }
        return {};
    };

    grid = () => {
        const {
            data,
            foundCount,
            pageCount,
            paging: { pageNum, pageSize },
            filter,
            typeOptions,
            statusOptions,
            showDetails,
            processing,
            message,
        } = this.state;
        return (
            <div
                className={classnames('SmsEmailGate__Grid', {
                    'SmsEmailGate__Grid--line': this.props.userPage,
                })}
            >
                <Filters
                    typeOptions={typeOptions}
                    statusOptions={statusOptions}
                    filter={filter}
                    handleStateChange={this.handleStateChange}
                    userPage={this.props.userPage}
                    setFilter={this.setFilter}
                    cleanFilter={this.cleanFilter}
                />
                <DataGrid
                    className="SmsEmailGate__Table"
                    data={data}
                    foundCount={foundCount}
                    columns={this.columns()}
                    showPageSizeOptions={false}
                    manual
                    showPagination
                    pages={pageCount}
                    page={pageNum}
                    onPageChange={this.changePage}
                    sorted={this.state.criteria.sorted}
                    pageSize={pageSize}
                    changePageAmount={this.changePageAmount}
                    onSortedChange={this.onSortedChange}
                    getTrProps={this.getTrProps}
                />
                <ModalDialog
                    onCloseModal={this.closeMessage}
                    modalOpen={showDetails}
                    modalHeader={message && message.type + ' ' + message.recipient}
                    btnOktext=""
                    btnCanceltext=""
                    processing={processing}
                >
                    {message?.events &&
                        message.events.map((x, idx) => (
                            <div className="Message" key={idx}>
                                <div className="Message__DT">
                                    {formatDate(x.created, 'DD-MM-YYYY kk:mm:ss')}
                                </div>
                                <div className="Message__Type">{eventTypes[x.type]}</div>
                                <div className="Message__Description">{x.description}</div>
                            </div>
                        ))}
                </ModalDialog>
            </div>
        );
    };

    render() {
        return this.props.userPage ? (
            <>
                <div>{this.props.header}</div>
                <div>{this.grid()}</div>
            </>
        ) : (
            <Page>
                <ListTemplate title={' '}>{this.grid()}</ListTemplate>
            </Page>
        );
    }
}

const actions = { showPageLoader, hidePageLoader, showErrorAlert };

SmsEmailGate.defaultProps = {
    userPage: false,
    header: null,
    userId: null,
};

SmsEmailGate.propTypes = {
    userPage: PropTypes.bool,
    header: PropTypes.object || null,
    userId: PropTypes.number,
};

export default connect(null, actions)(SmsEmailGate);
