import React from 'react';
import { connect } from 'react-redux';
import { queryOperation } from 'components/Search/QueryOperation';
import { getWishLists } from 'api';

import { fetchCatalog } from 'ducks/Catalog';
import FilterTemplate from './FilterTemplate';
import { FIELDS_TYPE } from '../SearchFilters/constants';

const Fields = [
    {
        type: FIELDS_TYPE.multiSelect,
        label: 'Подборка',
        query: queryOperation.InArray,
        value: 'WishlistId',
        column: 'WishlistId',
        table: 'Wishlist',
        catalog: 'wishlists',
        isDefault: true,
        extra: { join: 'WishlistEntries' },
    },
    {
        type: FIELDS_TYPE.multiSelect,
        label: 'Источник данных',
        query: queryOperation.InArray,
        value: 'DataOriginId',
        column: 'DataOriginId',
        table: 'PersonPersonnels',
        isDefault: true,
        catalog: 'dataOrigins',
    },
    {
        type: FIELDS_TYPE.multiSelect,
        label: 'Группа',
        query: queryOperation.CheckGroupScopesInArray,
        value: 'GroupId',
        column: 'GroupId',
        table: 'Persons',
        isDefault: true,
        catalog: 'groups',
    },
];
class WishListFilters extends React.Component {
    state = { catalogs: {}, loadCatalogs: false };

    componentDidMount() {
        getWishLists({}).then(result => {
            const wishlists = result.data.payload.map(x => ({
                ...x,
                value: x.id,
                label: (x.isDefault ? 'Моё избранное' : x.name) || 'Подборка',
                name: x.isDefault ? 'Моё избранное' : x.name,
            }));
            
            const catalogs = {
                ...this.props.catalogs,

                groups: {
                    data: this.props.groups?.map(x => ({
                        ...x,
                        value: x.id,
                        label: x.name || 'Подборка',
                    })),
                },
                wishlists: { data: wishlists },
            };
            this.setState({ catalogs, loadCatalogs: true });
        });
    }

    setFiltersState = (filters, filterId, replace, _, ...extra) => {
        this.props.setFiltersState(
            filters,
            filterId,
            replace,
            filters.some(x => x.field === 'WishlistId') ? { join: 'WishlistEntries' } : null,
            ...extra,
        );
    };
    render() {
        return (
            <FilterTemplate
                {...this.props}
                catalogs={this.state.catalogs}
                fields={Fields}
                loadCatalogs={this.state.loadCatalogs}
                setFiltersState={this.setFiltersState}
                closeMenuOnSelect={false}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        catalogs: ownProps.catalogs || state.catalogs,
        activeFilter: state.filterSearch.activeFilter,
        groups: state.dictionary.groups.dictionary,
    };
};

const actions = { fetchCatalog };

export default connect(
    mapStateToProps,
    actions,
)(WishListFilters);
