import React from 'react';
import ReactDOM from 'react-dom';

export class DataPickerPortal extends React.Component {
    constructor(props) {
        super(props);
        this.container = document.body;
    }
    render() {
        const { children } = this.props;
        return ReactDOM.createPortal(children, this.container);
    }
}

export default DataPickerPortal;
