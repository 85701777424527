import React, { Component } from 'react';
import Selected from 'components/common/PersonSelector/Selected';

class CampaignRecitients extends Component {
    render() {
        const { recipients, recipientsMeta, recipientsPaging, getRecipients } = this.props;
        return (
            <div>
                <Selected 
                    recipients={recipients}
                    recipientsMeta={recipientsMeta}
                    recipientsPaging={recipientsPaging}
                    getRecipients={getRecipients}
                    viewMode
                />
            </div>
        );
    }
}

export default CampaignRecitients;
