import memoize from "fast-memoize";
import React from "react";
import { connect } from "react-redux";
import uuid from "uuid/v4";
import ToggleOpen from "components/hoc/ToggleOpen";
import ValidBlock from "components/hoc/ValidBlock";
import { showErrorAlert, showWarningAlert } from "ducks/Alert";
import { LANGUAGES, LANGUAGE_LEVELS } from "ducks/Catalog";
import Select from "components/uikit/Select";
import { DataCard } from "components/common/DataCard";
import Field from "components/uikit/Field";
import Label from "components/uikit/Label";
import Row from "components/uikit/Row";
import Form from "components/common/Form";
import DataList from "./DataList";
import ItemButton from "./ItemButton";
import LangFileUploadButton from "./LangFileUploadButton";
import { MAX_BYTES_PER_LANG_FILE } from 'constants.js';
import "./LanguagesBlock.scss";

class LanguagesBlock extends React.Component {

    languageKeySelector = (language) => {
        return language._id || language.id;
    }

    handleChange = (key, changes) => {
        const knownLanguages = this.props.knownLanguages.map((item) => {
            const itemKey = this.languageKeySelector(item);
            return key === itemKey ? { ...item, ...changes } : item;
        });
        this.props.handleStateChange({ knownLanguages });
    }

    getAvailableLanguagesMemoized = memoize((knownLanguages, allLanguages) => {
        const alreadySelected = knownLanguages.filter(x => x.language).map(x => x.language.id);
        return allLanguages.filter(x => !alreadySelected.includes(x.id));
    });

    getAvailableLanguages = () => this.getAvailableLanguagesMemoized(this.props.knownLanguages, this.props[LANGUAGES].data);

    validator = (key) => {
        return knownLanguages => {
            const langInfo = knownLanguages.find(item => key === this.languageKeySelector(item));
            const isValid = !langInfo.language || (langInfo.language && langInfo.languageLevel);
            return isValid ? null : "Выберите уровень";
        }
    }
    addLanguage = () => {
        const knownLanguages = this.props.knownLanguages.push({ language: null, languageLevel: null, _id: uuid() });
        this.props.handleStateChange({ knownLanguages });
    }

    removeLanguage = (language) => {
        const key = this.languageKeySelector(language);
        const knownLanguages = this.props.knownLanguages.filter((item) => key !== this.languageKeySelector(item));
        this.props.handleStateChange({ knownLanguages });
    }

    renderLanguageBlock = (personId) => (langInfo, canRemove, handleRemove) => {
        const key = this.languageKeySelector(langInfo);
        const langErrorKey = `knownLanguage_${key}`;

        return (
            <React.Fragment key={key}>
                <Field
                    size="33"
                    filled={this.props.isInputFilled(langInfo.language)}
                >
                    <Label>Язык</Label>
                    <Select
                        value={langInfo.language}
                        onChange={item => this.handleChange(key, { language: item })}
                        options={this.getAvailableLanguages()}
                        isClearable
                        catalog
                    />
                </Field>
                <Field
                    invalid={this.props.isInputInvalid("knownLanguages", [this.validator(key)], langErrorKey)}
                    filled={this.props.isInputFilled(langInfo.languageLevel)}
                    size="33"
                    required={this.props.isInputFilled(langInfo.language)}
                >
                    <Label>Уровень</Label>
                    <Select
                        value={langInfo.languageLevel}
                        onChange={item => this.handleChange(key, { languageLevel: item })}
                        options={this.props[LANGUAGE_LEVELS].data}
                        catalog
                    />
                </Field>
                <Field className="kr_not_labeled LanguagesBlockLineControlsRow">
                    <div className="LanguagesBlockLineControls">
                        <LangFileUploadButton
                            personId={personId}
                            fileId={langInfo.fileAttachmentId}
                            onFileIdChange={item => this.handleChange(key, { fileAttachmentId: item.id })}
                            maxBytesPerFile={MAX_BYTES_PER_LANG_FILE}
                        />
                        {canRemove &&
                            <ItemButton
                                className="LanguagesBlockLineControls__RemoveButton"
                                type="remove"
                                onClick={handleRemove}
                            />
                        }
                    </div>
                    <small>* Размер файла не должен превышать 100 Мб</small>
                </Field>
            </React.Fragment>
        );
    }

    render() {

        this.props.isInputFilled(this.props.knownLanguages.size);

        return (
            <DataCard
                shadow
                title="Иностранные языки"
                className="kr_profile-anketa"
                isOpen={this.props.hasValidationErrors}
            >
                <Form autoComplete={false} className="EditPerson__Form">
                    <Row>
                        <DataList
                            customRemove={true}
                            isCompact={true}
                            onAdd={this.addLanguage}
                            onRemove={this.removeLanguage}
                            canRemove={() => true}
                            entries={this.props.knownLanguages.map((item, index) => ({ ...item, indexAsKey: index }))}
                            keySelector={this.languageKeySelector}
                            renderEntry={this.renderLanguageBlock(this.props.personId)}
                        />
                    </Row>
                    <Row>
                        {this.props.isEditBlock ? this.props.renderEditBlock() : null}
                    </Row>
                </Form>
            </DataCard>
        );
    }
}

export default connect(null, { showWarningAlert, showErrorAlert })(ToggleOpen(ValidBlock(LanguagesBlock)));