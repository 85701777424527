import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {HiddenInfoField as InfoField} from 'components/Lk/Uikit/InfoField';
import ProfileButton from 'components/Lk/Uikit/Buttons/Button/ProfileButton';
import { GEO_LEVEL_DICT, EMPLOYEE_LEVEL_DICT } from 'constants.js';
import { formatDate } from 'utils';
import classnames from 'classnames';
import { DATE_CONFIG } from './constants';

const formatedGeoLevel = GEO_LEVEL_DICT.map(x => ({ ...x, id: x.value, name: x.label }));
const formatedManagerLevel = EMPLOYEE_LEVEL_DICT.map(x => ({ ...x, id: x.value, name: x.label }));

const PersonnelInfo = props => {
    const { person, competitionLevels, dataOrigins, isEdit, onChange, experienceLevels } = props;

    const {
        personnelInfo,
        personnelInfo: { awards },
    } = person;

    const renderAward = item => (
        <Fragment key={item.id}>
            <InfoField
                icon={
                    isEdit && {
                        icon: 'minus-circle',
                        size: 'xs',
                        color: 'red',
                        onClick: onRemove(item.id),
                    }
                }
                noIcon={!isEdit}
                title="Уровень конкурса"
                required
                value={item.selectedCompetitionLevel && item.selectedCompetitionLevel.id}
                isEdit={isEdit}
                inputProps={{
                    type: 'select',
                    items: competitionLevels,
                    onChange: onChangeAward('selectedCompetitionLevel', item.id),
                }}
            >
                {item.selectedCompetitionLevel && item.selectedCompetitionLevel.name}
            </InfoField>
            <InfoField
                title="Вид награды"
                required
                value={item.name}
                isEdit={isEdit}
                maxLength={500}
                onChange={onChangeAward('name', item.id)}
                noIcon
            />
        </Fragment>
    );

    const onAddAward = () => {
        const id = -new Date().getTime();
        const newRecord = { id };
        onChange(
            { ...person, personnelInfo: { ...personnelInfo, awards: [...awards, newRecord] } },
            'AwardsBlock',
        );
    };

    const onRemove = id => e => {
        e.stopPropagation();
        onChange(
            {
                ...person,
                personnelInfo: { ...personnelInfo, awards: awards.filter(a => a.id !== id) },
            },
            'AwardsBlock',
        );
    };

    const onChangeAward = (name, id) => value => {
        const newRecords = awards.map(item => (item.id === id ? { ...item, [name]: value } : item));
        onChange(
            { ...person, personnelInfo: { ...personnelInfo, awards: newRecords } },
            'AwardsBlock',
        );
    };

    const onChangeState = name => value => {
        onChange(
            { ...person, personnelInfo: { ...person.personnelInfo, [name]: value } },
            'PersonnelInfoBlock',
        );
    };

    return (
        <div className={classnames("PersonalInfo", {'PersonalInfo--bottom-margin': isEdit})}>
            <InfoField
                title="Социальная активность"
                value={personnelInfo.socialActivity}
                tooltip="Информация о формах социальной и профессиональной активности, не относящаяся к служебной деятельности:
                волонтерская активность, работа на конференциях, форумах, круглых столах, ссылки на материалы в СМИ от
                лица участника резерва, проектная деятельность (в том числе совместная с другими участниками Резерва) и т.д"
                isEdit={isEdit}
                maxLength={4000}
                onChange={onChangeState('socialActivity')}
                inputProps={{
                    dataTest: "PersonnelInfo_SocialActivity--input"
                }}
                noIcon
            />
            <InfoField
                title="Членство в общественных организациях"
                value={personnelInfo.memberOfSocialOrganizations}
                tooltip="Информация о членстве в общественных организациях (период, название организации и роль).
                Также здесь указывается работа в комиссиях, рабочих группах."
                isEdit={isEdit}
                maxLength={450}
                onChange={onChangeState('memberOfSocialOrganizations')}
                inputProps={{
                    dataTest: "PersonnelInfo_MemberOfSocialOrganizations--input"
                }}
                noIcon
            />
            <InfoField
                title="Деятельность в качестве руководителя"
                value={personnelInfo.socialAchivements}
                isEdit={isEdit}
                maxLength={450}
                tooltip="Деятельность в качестве инициатора, руководителя, координатора
                при реализации социально- и экономически- значимых проектов регионального (федерального) и
                местного уровней (в том числе не связанная с участием в Резерве); описание достижений."
                onChange={onChangeState('socialAchivements')}
                inputProps={{
                    dataTest: "PersonnelInfo_SocialAchivements--input"
                }}
                noIcon
            />
            <InfoField title="Награды/достижения" show={isEdit || awards?.length !== 0} noIcon>
                {awards?.length !== 0 && awards.map(renderAward)}
                {isEdit && (
                    <InfoField show>
                        <ProfileButton onClick={onAddAward}>добавить</ProfileButton>
                    </InfoField>
                )}
            </InfoField>
            <InfoField
                title="Источник данных"
                show
                required
                value={personnelInfo.dataOrigin && personnelInfo.dataOrigin.id}
                isEdit={isEdit}
                inputProps={{
                    type: 'select',
                    items: dataOrigins,
                    onChange: onChangeState('dataOrigin')
                }}
                dataTest='PersonnelInfo_DataOrigin--text'
                noIcon
            >
                {personnelInfo.dataOrigin && personnelInfo.dataOrigin.name}
            </InfoField>
            <InfoField
                title="Уровень подготовленности участника"
                value={personnelInfo.experienceLevel && personnelInfo.experienceLevel.id}
                isEdit={isEdit}
                inputProps={{
                    type: 'select',
                    items: experienceLevels,
                    onChange: onChangeState('experienceLevel'),
                }}
                dataTest="PersonnelInfo_ExperienceLevel--text"
                noIcon
            >
                {personnelInfo.experienceLevel && personnelInfo.experienceLevel.name}
            </InfoField>

            <InfoField
                title="Рекомендатель"
                value={personnelInfo.recommenderName}
                isEdit={isEdit}
                maxLength={250}
                onChange={onChangeState('recommenderName')}
                dataTest="PersonnelInfo_RecommenderName--text"
                noIcon
            />
            <InfoField
                value={personnelInfo.curatorName}
                title="Куратор"
                isEdit={isEdit}
                maxLength={250}
                onChange={onChangeState('curatorName')}
                dataTest="PersonnelInfo_CuratorName--text"
                noIcon
            >
                {personnelInfo.curatorName}
            </InfoField>
            <InfoField
                title="Географический уровень"
                value={personnelInfo.geoLevel}
                isEdit={isEdit}
                inputProps={{
                    type: 'select',
                    items: formatedGeoLevel,
                    onChange: e => onChangeState('geoLevel')(e.id),
                }}
                dataTest="PersonnelInfo_GeoLevel--text"
                noIcon
            >
                {(formatedGeoLevel.find(x => x.value === personnelInfo.geoLevel) || {}).label}
            </InfoField>
            <InfoField
                title="Уровень менеджмента"
                value={personnelInfo.managerLevel}
                isEdit={isEdit}
                inputProps={{
                    type: 'select',
                    items: formatedManagerLevel,
                    onChange: e => onChangeState('managerLevel')(e.id),
                }}
                dataTest="PersonnelInfo_ManagerLevel--text"
                noIcon
            >
                {
                    (formatedManagerLevel.find(x => x.value === personnelInfo.managerLevel) || {})
                        .label
                }
            </InfoField>
            <InfoField
                title="Дата включения в резерв"
                value={ personnelInfo.inclusionDate }
                isEdit={isEdit}
                inputProps={{ 
                    dataTest: "PersonnelInfo_EditInclusionDate--input", 
                    ...DATE_CONFIG
                }}
                type="date"
                onChange={onChangeState('inclusionDate')}
                dataTest="PersonnelInfo_InclusionDate--text"
                noIcon
            >
                { personnelInfo.inclusionDate && formatDate(personnelInfo.inclusionDate, "LL") }
            </InfoField>
            <InfoField
                title="Основание включения"
                value={personnelInfo.inclusionReason}
                isEdit={isEdit}
                maxLength={450}
                onChange={onChangeState('inclusionReason')}
                inputProps={{
                    dataTest: "PersonnelInfo_EditInclusionReason--input"
                }}
                dataTest="PersonnelInfo_InclusionReason--text"
                noIcon
            />
            <InfoField
                title="Уровень резерва"
                value={personnelInfo.reserveLevel}
                isEdit={isEdit}
                onChange={onChangeState('reserveLevel')}
                inputProps={{
                    dataTest: "PersonnelInfo_EditReserveLevel--input"
                }}
                dataTest="PersonnelInfo_ReserveLevel--text"
                maxLength={250}
                noIcon
            />
            <InfoField
                title="Работа на выборных должностях"
                value={personnelInfo.governmentWorkPositions}
                isEdit={isEdit}
                onChange={onChangeState('governmentWorkPositions')}
                inputProps={{
                    dataTest: "PersonnelInfo_GovernmentWorkPositions--input"
                }}
                dataTest="PersonnelInfo_GovernmentWorkPositions--text"
                maxLength={450}
                noIcon
            />
            <InfoField
                title="Дополнительные сведения"
                value={personnelInfo.extraInfo}
                isEdit={isEdit}
                onChange={onChangeState('extraInfo')}
                inputProps={{
                    dataTest: "PersonnelInfo_EditExtraInfo--input"
                }}
                dataTest="PersonnelInfo_ExtraInfo--text"
                maxLength={4000}
                noIcon
            />
        </div>
    );
};

const mapStateToProps = state => ({
    competitionLevels: state.catalogs.competitionLevels.data,
    dataOrigins: state.catalogs.dataOrigins.data,
    experienceLevels: state.catalogs.experienceLevels.data,
});

export default connect(mapStateToProps)(PersonnelInfo);
