import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import Dropzone from 'react-dropzone';

import Button from 'components/uikit/Button';
import {
    faBold,
    faItalic,
    faStrikethrough,
    faLink,
    faList,
    faListOl,
    faPaperclip,
    faUnderline,
} from '@fortawesome/free-solid-svg-icons';

import '../../TextEditor.scss';
import HeadingMenu from 'components/common/TextEditor/components/HeadingMenu/HeadingMenu';

class Toolbar extends Component {
    onExecuteHeading = level => {
        this.props.onExecute('heading', level);
    };

    renderToolbar = () => {
        return this.props.extendedToolbar
            ? this.renderExtendedToolbar()
            : this.renderShortToolbar();
    };

    renderShortToolbar = () => {
        return (
            <>
                <Button
                    onClick={() => this.props.onExecute('bold')}
                    size="md"
                    faIcon={faBold}
                    tooltipHeader="Полужирный"
                    tooltip="Применение полужирного начертания к тексту."
                />
                <Button
                    onClick={() => this.props.onExecute('italic')}
                    size="md"
                    faIcon={faItalic}
                    tooltipHeader="Курсив"
                    tooltip="Применение курсивного начертания к тексту."
                />
                <Button
                    onClick={() => this.props.onExecute('underline')}
                    size="md"
                    faIcon={faUnderline}
                    tooltipHeader="Подчеркнутый"
                    tooltip="Подчеркивание текста."
                />
                <Button
                    onClick={() => this.props.onExecute('strike')}
                    size="md"
                    faIcon={faStrikethrough}
                    tooltipHeader="Зачеркнутый"
                    tooltip="Зачеркивание текста линией."
                />
                <Button
                    onClick={() => this.props.onExecute('link')}
                    size="md"
                    faIcon={faLink}
                    tooltipHeader="Вставить гиперссылку"
                    tooltip="Создание ссылки в документе для быстрого доступа к веб-странице."
                />
            </>
        );
    };

    renderExtendedToolbar = () => {
        return (
            <>
                {this.renderShortToolbar()}
                <HeadingMenu
                    onExecute={level => this.onExecuteHeading(level)}
                    tooltipHeader="Заголовки"
                    tooltip="Выбор уровня заголовка."
                    tooltipPlacement="top"
                ></HeadingMenu>
                <Button
                    onClick={() => this.props.onExecute('list')}
                    size="md"
                    faIcon={faList}
                    tooltipHeader="Маркеры"
                    tooltip="Создание маркированного списка."
                    tooltipPlacement="top"
                />
                <Button
                    onClick={() => this.props.onExecute('listOl')}
                    size="md"
                    faIcon={faListOl}
                    tooltipHeader="Нумерация"
                    tooltip="Создание нумерованного списка."
                    tooltipPlacement="top"
                />

                <Dropzone onDrop={this.props.onAddFile}>
                    {({ getRootProps, getInputProps }) => (
                        <div className="TextEditor__Tabs--file-btn">
                            <div
                                {...getRootProps({
                                    className: 'dropzone',
                                    onDrop: event => event.stopPropagation(),
                                })}
                            >
                                <input {...getInputProps()} />
                                <Button
                                    size="md"
                                    faIcon={faPaperclip}
                                    tooltipHeader="Вставить изображение/файл"
                                    tooltip="Вставка изображения или прикрепление файла к документу."
                                    tooltipPlacement="top"
                                />
                            </div>
                        </div>
                    )}
                </Dropzone>
                <Button
                    onClick={this.props.clearMarkdown}
                    size="md"
                    tooltipHeader="Очистить форматирование"
                    tooltip="Очистить форматирование в выделенном тексте."
                    disabled={!this.props.clearMarkdown}
                    svgIcon={{name: "clean_text_format", fill: "#fff"}}
                />
            </>
        );
    };

    onChangePreview = value => {
        if (this.props.onChangePreview) {
            this.props.onChangePreview(value);
        }
    };

    renderTabs = () => {
        return (
            <>
                <Button
                    onClick={() => this.onChangePreview(false)}
                    size="sm"
                    color={this.props.preview ? 'light_primary' : 'primary'}
                >
                    Редактирование
                </Button>
                <Button
                    onClick={() => this.onChangePreview(true)}
                    size="sm"
                    color={this.props.preview ? 'primary' : 'light_primary'}
                >
                    Быстрый просмотр
                </Button>
            </>
        );
    };

    render() {
        return this.props.showToolbar ? (
            <div className="TextEditor__Buttons"><Row start="md">
                <Col md={7} className="TextEditor__Toolbar">
                    {!this.props.preview && this.renderToolbar()}
                </Col>
                <Col md={5} className="TextEditor__Tabs">
                    {this.props.showTabs && this.renderTabs()}
                </Col>
            </Row></div>
        ) : null;
    }
}

Toolbar.propTypes = {
    showToolbar: PropTypes.bool,
    onExecute: PropTypes.func,
    onAddFile: PropTypes.func,
    onChangePreview: PropTypes.func,
    extendedToolbar: PropTypes.bool,
    preview: PropTypes.bool,
    showTabs: PropTypes.bool,
    clearMarkdown: PropTypes.func
};

Toolbar.defaultProps = {
    showToolbar: true,
    extendedToolbar: false,
    showTabs: true,
};

export default Toolbar;
