import React, { Component } from "react";
import classnames from "classnames";
import { Col, Row } from "react-flexbox-grid";
import ActionButton from "./ModalPopupActionButton.js";

class ModalPopupFooter extends Component {
    static ActionButton = ActionButton;

    render() {
        
        const {
            show = false,
            actionButtons = ['Отменить', 'Подтвердить'],
            enabled = true,
            fileUpload = false,
            fitScreenSize = false,
            additionalActions = [],
            fixedButtons = false,
            onConfirm,
            onCancel,
            unstickFooter,
        } = this.props;

        if (!show) {
            return null;
        }

        return (
            <div
                className={classnames('LKModalPopup__Footer', {
                    'LKModalPopup__Footer--fitScreenSize': fitScreenSize,
                    'LKModalPopup__Footer--unstickFooter': unstickFooter,
                })}
            >
                {additionalActions?.length > 0 &&
                <div className="LKModalAdditionalPopupActions">
                    {additionalActions.map(x => (
                        <div key={x.id} className="LKModalAdditionalPopupActions--action" onClick={x.action}>
                            {x.name}
                        </div>))}
                </div>}
                {!fixedButtons &&
                <div className="LKModalPopupActions">
                    <ActionButton cancel onClick={() => onCancel(false)}>
                        {actionButtons[0]}
                    </ActionButton>
                    {!fileUpload
                        ? <ActionButton ok onClick={onConfirm} disabled={!enabled}>
                            {actionButtons[1]}
                        </ActionButton>
                        : <ActionButton fileUpload onChange={onConfirm} disabled={!enabled}/>}
                </div>}
                {fixedButtons &&
                <Row className="LKModalPopupActions">
                    <Col xs={6}>
                        <ActionButton cancel fixed onClick={() => onCancel(false)}>
                            {actionButtons[0]}
                        </ActionButton>
                    </Col>
                    <Col xs={6}>
                        {!fileUpload
                            ? <ActionButton ok fixed onClick={onConfirm} disabled={!enabled}>
                                {actionButtons[1]}
                            </ActionButton>
                            : <ActionButton fileUpload fixed onChange={onConfirm} disabled={!enabled}/>}
                    </Col>
                </Row>}
            </div>
        );
    }
}

export default ModalPopupFooter;