import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Modal from 'react-responsive-modal';
import { Grid, Row } from 'react-flexbox-grid';
import Button from 'components/uikit/Button';
import './ModalDialog.scss';

const getStyles = (size = '', fitHeight) => {
    let modal = {
        borderRadius: '5px',
        padding: '20px 20px 10px',
        ...(fitHeight && { height: 'calc(100vh - 7%)', overflow: 'auto' }),
    };
    let style = {
        modal: modal,
        closeButton: { outline: 'none' },
    };

    switch (size) {
        case 'sm':
            modal = { ...modal, width: '300px', padding: '0' };
            style = { ...style, modal };
            return style;
        case 'lg':
            modal = { ...modal, width: '800px' };
            style = { ...style, modal };
            return style;
        case 'xl':
            modal = { ...modal, width: '1000px', maxWidth: '1300px' };
            style = { ...style, modal };
            return style;
        case 'xxl':
            modal = { ...modal, width: '1300px', maxWidth: '1300px' };
            style = { ...style, modal };
            return style;
        default:
            return style;
    }
};

const ModalDialog = (props) => {
    const {
        btnExtra = [],
        btnOktext = 'Да',
        btnCanceltext = 'Нет',
        btnOkColor = 'primary',
        btnCancelColor = 'default',
        isDangerHeader = false,
        size,
        processing,
        isValidForm = true,
        buttonsSize = 'lg',
        modalOpen,
        onCloseModal,
        modalHeader,
        buttonsAside,
        children,
        onClick,
        withoutButtons,
        noHeaderDivider,
        sideButtons,
        actions,
        accept,
        dataTest,
        fitHeight,
    } = props;

    return (
        <Modal open={modalOpen} onClose={onCloseModal} center styles={getStyles(size, fitHeight)}>
            <Grid fluid>
                <Row>
                    <div className="Modal">
                        <div
                            className={classnames('Modal__Header', {
                                'Modal__Header--danger': isDangerHeader,
                                'Modal__Header--small': size === 'sm',
                                'Modal__Header--no-divider': noHeaderDivider,
                            })}
                        >
                            {modalHeader}
                        </div>
                        <div className={classnames({ 'Modal-Flex': buttonsAside })}>
                            <div className="Modal__Children">{children}</div>
                        </div>

                        <div
                            className={classnames({
                                Modal__Buttons: !buttonsAside,
                                'Modal__Buttons-Line': buttonsAside,
                                'Modal__Buttons--sm': size === 'sm',
                            })}
                        >
                            {!withoutButtons && (
                                <>
                                    {btnExtra.length !== 0 && (
                                        <div className="Modal__Buttons--left">
                                            {btnExtra.map((item, i) => {
                                                return (
                                                    <Button
                                                        key={i}
                                                        faIcon={item.icon}
                                                        onClick={item.onClick}
                                                        color={btnCancelColor}
                                                        disabled={processing}
                                                    >
                                                        {item.text}
                                                    </Button>
                                                );
                                            })}
                                        </div>
                                    )}
                                    <div
                                        className={classnames('Modal__Buttons--right', {
                                            'Modal__Buttons--sides': sideButtons,
                                            'Modal__Buttons--bottom': fitHeight,
                                        })}
                                    >
                                        {actions ? (
                                            <>
                                                {actions.map((action, index) => (
                                                    <Button
                                                        key={index}
                                                        color={action.color}
                                                        onClick={() => accept(action.value)}
                                                    >
                                                        {action.title}
                                                    </Button>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                <div>
                                                    {btnCanceltext && (
                                                        <Button
                                                            tabIndex={1}
                                                            onClick={onCloseModal}
                                                            color={btnCancelColor}
                                                            disabled={processing}
                                                            size={buttonsSize}
                                                            data-test={`${dataTest}Cancel--button`}
                                                        >
                                                            {btnCanceltext}
                                                        </Button>
                                                    )}
                                                </div>
                                                <div>
                                                    {btnOktext && (
                                                        <Button
                                                            color={btnOkColor}
                                                            onClick={onClick}
                                                            loading={processing}
                                                            disabled={
                                                                !isValidForm ||
                                                                processing ||
                                                                !modalOpen
                                                            }
                                                            size={buttonsSize}
                                                            data-test={`${dataTest}Approve--button`}
                                                        >
                                                            {btnOktext}
                                                        </Button>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </Row>
            </Grid>
        </Modal>
    );
};

ModalDialog.defaultProps = {
    fitHeight: false,
};

ModalDialog.propTypes = {
    onClick: PropTypes.func,
    onCloseModal: PropTypes.func.isRequired,
    modalOpen: PropTypes.bool.isRequired,
    modalHeader: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    buttonsAside: PropTypes.bool,
    buttonsSize: PropTypes.string,
    size: PropTypes.string,
    btnOktext: PropTypes.string,
    btnCanceltext: PropTypes.string,
    processing: PropTypes.bool,
    isValidForm: PropTypes.bool,
    withoutButtons: PropTypes.bool,
    fitHeight: PropTypes.bool,
};

export default ModalDialog;
