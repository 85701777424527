import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Image from 'components/Lk/Uikit/Image';
import { photoSizes, getPersonPhotoUri } from 'api.js';
import classnames from 'classnames';
import Header from './Header';
import Icons from 'components/Lk/Uikit/Icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Logo.scss';
import { MENU_PROFILE } from './constants';
import ManageFavorites from './ManageFavorites';

const Logo = props => {
    const {
        personalInfo,
        isWrapped = false,
        setLogoWrapped,
        setMenu,
        isEdit,
        setEditState,
        container,
        setCancelSave,
        setWrap,
        setVisibleWrapSection,
        canLeaderEdit,
        menuSelect,
        lastLocation,
        push,
        isSecondStep,
        editPhoto,
        favoriteBlock,
        isManageFavoritesOpen, 
        setManageFavoritesOpen,
        personWishlists,
        refetchKey
    } = props;
    
    const { lastName, firstName, middleName, id: personId } = personalInfo;
    const photoUri = getPersonPhotoUri(photoSizes.small, personId, refetchKey);

    const toggleEdit = () => {
        setEditState(!isEdit);
        setVisibleWrapSection(null);
        setCancelSave(isEdit);
        setWrap(false);
    };

    const goBack = e => {
        e.stopPropagation();
        push(lastLocation ? { ...lastLocation } : { pathname: '/' });
    };

    const scrollTop = () => container?.scrollTo(0, 0);

    const headerProps = {
        lastName,
        firstName,
        middleName,
        photoUri,
        show: isWrapped,
        setMenu,
        setLogoWrapped,
        isEdit,
        container,
        canLeaderEdit,
        menuSelect,
        toggleEdit,
        goBack,
        editPhoto,
    };

    const openManageFavorites = () => setManageFavoritesOpen(true);
    const closeManageFavorites = () => setManageFavoritesOpen(false);

    const actions = () => {
        const canEdit = canLeaderEdit();
        if (canEdit) {
            return <div className="LkProfileLogo__Edit"></div>;
        } else {
            return (
                <>
                    <div className="LkProfileLogo__ManageFavorite" onClick={openManageFavorites}>
                        <FontAwesomeIcon icon="users-cog" />
                    </div>
                    <div className="LkProfileLogo__Favorite">{favoriteBlock}</div>
                </>
            );
        }
    };

    return (
        !!personalInfo && (
            <>
                <div
                    className={classnames('LkProfileLogo', {
                        'LkProfileLogo--hidden': isWrapped,
                        LkProfileLogo__SecondStep: isSecondStep,
                    })}
                >
                    <div className="LkProfileLogo__Back" onClick={goBack}>
                        <FontAwesomeIcon icon="chevron-left" />
                    </div>
                    <div className="LkProfileLogo__Name" onClick={scrollTop}>
                        <div
                            className="LkProfileLogo__ImageContainer"
                            onClick={isEdit ? editPhoto : undefined}
                        >
                            <div className="LkProfileLogo__Image">
                            
                                <Image photoUri={photoUri} />
                            </div>
                        </div>

                        <div className="LkProfileLogo__Text">
                            <div className="LkProfileLogo__Item">
                                <span data-test="LeaderLastName--text">{lastName} </span>
                                <span data-test="LeaderFirstName--text">{firstName} </span>
                                <span data-test="LeaderMIddleName--text">{middleName} </span>
                            </div>
                        </div>

                        <div className="LkProfileLogo__Save"></div>
                    </div>
                    {canLeaderEdit() && menuSelect === MENU_PROFILE ? (
                        <div onClick={() => toggleEdit(true)} className="LkProfileLogo__Edit">
                            <Icons name={classnames({ pencil: !isEdit, pencil_commit: isEdit })} />
                        </div>
                    ) : (
                        actions()
                    )}
                </div>
                <Header {...headerProps} />
                <ManageFavorites
                    isOpen={isManageFavoritesOpen}
                    onClose={closeManageFavorites}
                    personId={personalInfo.id}
                    personWishlists={personWishlists}
                />
            </>
        )
    );
};

export default connect(
    ({user})=>({refetchKey:user.refetchKey}),
    { push },
)(Logo);
