import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import {
    lkPersonProfile,
    lkPersonPlanComment,
    lkIssueTrackingSelect,
    lkIssueEdit,
    home,
    lkMainServices,
} from 'routes';
// import { NavLink } from 'react-router-dom';  TODO Временно скрыт Список резервистов пока нет функционала на бэке
import { formatDate } from 'utils';
import classnames from 'classnames';
import { ACTIVE_LIST_CARD } from 'components/Lk/Basic/constants';
import { lkIssueDone, lkIssueDeferred } from 'routes';
import Icons from 'components/Lk/Uikit/Icons/Icons';
import StickyFooter from 'components/Lk/Common/StickyFooter';
import StickyBottomAcceptMenu from 'components/Lk/Uikit/Buttons/StickyBottomAcceptMenu';
import { updatedIssues } from 'ducks/Issues';
import { converter } from 'libs/showdown';
import Loader from 'components/common/Loader';
import './IssueItemPage.scss';
import {
    updateIssueStatus,
    getIssue,
    getPersonGrowthPlan,
    getPersonGrowthReportPdfDownloadLink,
    updatePersonGrowthPlanApprove,
} from 'api';
import { ISSUE_STATUS_ENUM, ISSUE_TYPE_ENUM, ISSUE_ATTRIBUTE_TYPES } from 'constants.js';
import AcceptPage from 'components/Lk/Common/AcceptPage';
import { showSuccessAlert, showErrorAlert } from 'ducks/Alert';
import { Link } from 'react-router-dom';
import { MENU_PROFILE } from 'components/Lk/Shared/Profile/constants';
import IssueHistory from 'components/Lk/Shared/Tracking/IssueHistory';
import InfoField from 'components/Lk/Uikit/InfoField';
import IconButton from 'components/Lk/Uikit/Buttons/IconButton';
import IssueFile from '../Tasks/IssueFile';
import { serviceResultCode, getCode } from 'serviceErrors';

const IssueItemPage = (props) => {
    const {
        activeCard,
        setActiveCard,
        lkUrl = lkPersonProfile,
        push,
        currentUser,
        match: {
            params: { id },
        },
        issuesProps
    } = props;
    const isMobileActive = activeCard === ACTIVE_LIST_CARD;
    setActiveCard(ACTIVE_LIST_CARD);

    const [issue, setIssue] = useState({});
    const [isRemove, setRemoveState] = useState(false);
    const [issueHistory, setIssueHistory] = useState([]);
    const [isWrap, setWrap] = useState(true);
    const [plan, setPlan] = useState(null);
    const [personName, setPersonName] = useState('');
    const [isPlanToBeApproved, setIsPlanToBeApproved] = useState(false);
    const [loading, setLoading] = useState(false);

    const isApprovePlanIssue = useMemo(
        () => issue && issue.type === ISSUE_TYPE_ENUM.ApprovePersonGrowthPlan,
        [issue],
    );

    
    const issues = issuesProps && issuesProps.data;
    const issuesLoaded = issuesProps.loadComplete;

    useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            let isApprovePlanIssue = {}
            setLoading(true);
            try {
                if (id && issues && issues.length) {
                    const getIssueResponse = await getIssue(id);
                    if (
                        mounted &&
                        getIssueResponse &&
                        getIssueResponse.status === 200 &&
                        getIssueResponse.data
                    ) {
                        setIssue({ ...getIssueResponse.data });
                        const issueHistory = getIssueResponse.data.issueHistory;
                        setIssueHistory(issueHistory);

                        const issueAttributes = getIssueResponse.data.issueAttributes;

                        const issue = getIssueResponse.data;
                        isApprovePlanIssue = issue && issue.type === ISSUE_TYPE_ENUM.ApprovePersonGrowthPlan;
                        
                        if (isApprovePlanIssue && issueAttributes && issueAttributes.length > 0) {
                            const personNameIssueAttribute = issueAttributes.find(
                                (x) =>
                                    x.issueId === id &&
                                    x.issueAttributeType === ISSUE_ATTRIBUTE_TYPES.PersonName,
                            );
                            const personName =
                                personNameIssueAttribute && personNameIssueAttribute.stringValue;
                            mounted && setPersonName(personName);

                            const planIdIssueAttribute = issueAttributes.find(
                                (x) =>
                                    x.issueId === id &&
                                    x.issueAttributeType ===
                                        ISSUE_ATTRIBUTE_TYPES.PersonGrowthPlanId,
                            );

                            const planId = planIdIssueAttribute && planIdIssueAttribute.guidValue;
                            const getPlanResponse = await getPersonGrowthPlan(planId);
                            if (
                                mounted &&
                                getPlanResponse &&
                                getPlanResponse.status === 200 &&
                                getPlanResponse.data
                            ) {
                                const plan = getPlanResponse.data;
                                if (plan && !plan.approverIds.some(x => x === currentUser.id)){
                                    showErrorAlert('Задача не найдена.');
                                    push(home.buildUrl());
                                }
                                mounted && setPlan(plan);
                            }
                        }
                    }
                }
                if (id && issues && Array.isArray(issues) && !issues.length && issuesLoaded) {
                    push(home.buildUrl());
                }
            } catch (error) {
                isApprovePlanIssue && setPlan(null);
                if (getCode(error) === serviceResultCode.UnableToOpenOrModifyDeletedEntity) {
                    showErrorAlert('Задачу открыть не удалось.');
                    push(home.buildUrl());
                } else if (getCode(error) === serviceResultCode.UnableToOpenCanceledIssue) {
                    showErrorAlert('Задача была отменена.');
                    push(home.buildUrl());
                } else if (getCode(error) === serviceResultCode.NotFound) {
                    showErrorAlert('Задача не найдена.');
                    push(home.buildUrl());
                } else {
                    const payload = error.response?.data?.payload;
                    if (payload && JSON.stringify(payload) !== JSON.stringify({})) {
                        showErrorAlert(payload);
                    } else {
                        error.response.status === 404 && isApprovePlanIssue
                            ? showErrorAlert('ИПР не найден.')
                            : showErrorAlert('Ошибка загрузки задачи');
                    }
                }
            } finally {
                mounted && setLoading(false);
            }
        };
        fetchData();

        return () => {
            mounted = false;
        };
    }, [id, push, issues, issuesLoaded, currentUser]);

    const handlerOnAccept = () => {
        push(lkIssueDone.buildUrl({ id }));
    };

    const handlerOnCancel = () => {
        push(lkIssueDeferred.buildUrl({ id }));
    };

    const onAcceptPlan = (state) => () => {
        setIsPlanToBeApproved(state);
    };

    const handlerOnAcceptPlan = async () => {
        const approve =
            currentUser && currentUser.id
                ? plan.approves.find((x) => x.approverId === currentUser.id)
                : null;
        if (plan && approve && approve.personGrowthPlanId) {
            const updatePlanResponse = await updatePersonGrowthPlanApprove({
                ...approve,
                status: 'Approved',
            });
            updatePlanResponse &&
                updatePlanResponse.status &&
                updatePlanResponse.status === 200 &&
                showSuccessAlert('ИПР согласован');
            await updateIssueStatus(issue.id, ISSUE_STATUS_ENUM.Completed);
        }
        await props.updatedIssues();
        onClose();
    };

    const handlerOnComment = () => {
        const planId = plan && plan.id ? plan.id : null;
        const approve =
            currentUser && currentUser.id
                ? plan.approves.find((x) => x.approverId === currentUser.id)
                : null;
        const approveId = approve && approve.id ? approve.id : null;

        if (planId && approveId) {
            push(lkPersonPlanComment.buildUrl({ id: id, planId: planId, approveId: approveId }));
        }
    };

    const onClose = () => {
        push(lkMainServices.url);
    };

    const onRemove = async () => {
        await updateIssueStatus(issue.id, ISSUE_STATUS_ENUM.Deleted);
        onClose();
        showSuccessAlert('Задача успешно удалена');
        await props.updatedIssues();
    };
    const onSetRemove = (state) => () => {
        setRemoveState(state);
    };

    const onEdit = () => {
        push(lkIssueEdit.buildUrl({ id: issue.id }));
    };

    const openIssueHistory = () => {
        setWrap(!isWrap);
    };

    const isSinleSelfIssue = useMemo(
        () => issue.authorId === currentUser.id && issue.parentIssueId == null,
        [currentUser, issue],
    );

    const isClosedOrCompleted = () => {
        if (isApprovePlanIssue) {
            return plan && plan.isApproved;
        }
        return (
            issue.status === ISSUE_STATUS_ENUM.Closed ||
            issue.status === ISSUE_STATUS_ENUM.Completed
        );
    };

    const MenuSetup = {
        background: false,
        btns: [
            {
                title: 'Отложить',
                onClick: handlerOnCancel,
                type: 'Cancel',
                hide: isSinleSelfIssue,
                disable: !issue.dueDate || isClosedOrCompleted(),
                dataTest: 'IssueItemPage_Deffer--button',
            },

            {
                title: 'Удалить',
                onClick: onSetRemove(true),
                type: 'Cancel',
                hide: !isSinleSelfIssue,
                disable: isClosedOrCompleted(),
                dataTest: 'IssueItemPage_Remove--button',
            },
            {
                title: 'Редактировать',
                onClick: onEdit,
                type: 'Accept',
                hide: !isSinleSelfIssue,
                disable: isClosedOrCompleted(),
                dataTest: 'IssueItemPage_Edit--button',
            },
            {
                title: 'Выполнить',
                onClick: handlerOnAccept,
                type: 'Accept',
                hide: isApprovePlanIssue,
                disable: isClosedOrCompleted(),
                dataTest: 'IssueItemPage_Accept--button',
            },
            {
                title: 'Комментировать',
                onClick: handlerOnComment,
                type: 'Cancel',
                hide: !isApprovePlanIssue,
                disable: isClosedOrCompleted() || !plan,
                dataTest: 'IssueItemPage_Comment--button',
            },
            {
                title: 'Согласовать',
                onClick: onAcceptPlan(true),
                type: 'Accept',
                hide: !isApprovePlanIssue,
                disable: isClosedOrCompleted() || !plan,
                dataTest: 'IssueItemPage_AcceptPlan--button',
            },
        ],
    };

    const renderPlan = (plan) => {
        return renderFullPlan(plan);
    };

    const handleDownload = async (planId) => {
        if (planId.length === 0) {
            return;
        }
        const downloadLink = await getPersonGrowthReportPdfDownloadLink(planId);
        window.location = downloadLink;
    };

    const renderFullPlan = (plan) => {
        return (
            <IconButton
                className="IssueItemPage__Download"
                onClick={() => handleDownload(plan.id)}
                type="iconDownload"
                iconPosition="right"
                fullWidth
                dataTest={'IssueItemPage_DownloadPersonPlan--button'}
            >
                Скачать ИПР
            </IconButton>
        );
    };

    const authorName =
        issue.author &&
        `${issue.author.lastName} ${issue.author.firstName} ${issue.author.middleName}`;

    const isSelfIssue = useMemo(() => issue.authorId === currentUser.id, [currentUser, issue]);

    return (
        <div
            className={classnames('ListCard ', {
                'ListCard--isMobileActive': isMobileActive,
            })}
        >
            {loading && <Loader absolute />}
            <div className="IssueItemPage">
                <h3 className="LKLayout__Title">
                    <span>Задача</span>
                    <span
                        className="LKLayout__IconClose"
                        onClick={onClose}
                        data-test="IssueItemPage_PageClose--button"
                    >
                        <Icons name="iconClose" />
                    </span>
                </h3>
                <div className="IssueItemPage__Body">
                    <div className="IssueItemPage__Date">
                        {issue.created && formatDate(issue.created, 'dddd, DD.MM')}
                    </div>
                    <div className="IssueItemPage__Content">
                        <h4 className="IssueItemPage__Title" data-test="IssueItemPage_Title--text">
                            {issue.title}
                        </h4>
                        {isApprovePlanIssue && plan && plan?.id ? (
                            <div className="IssueItemPage__Description">
                                {issue.description && typeof issue.description === 'string'
                                    ? issue.description.replace(personName, '')
                                    : issue.description}
                                <Link
                                    data-test="IssueItemPage_Description--text"
                                    to={{
                                        pathname: lkPersonProfile.buildUrl({ id: plan.personId }),
                                        search: `type=${MENU_PROFILE}`,
                                    }}
                                >
                                    {personName}
                                </Link>
                            </div>
                        ) : (
                            <div
                                data-test="IssueItemPage_Description--text"
                                className="IssueItemPage__Description"
                                dangerouslySetInnerHTML={{
                                    __html: converter.makeHtml(issue.description),
                                }}
                            />
                        )}
                        {/* 
                        // TODO Временно скрыт Список резервистов пока нет функционала на бэке
                        <div className="IssueItemPage__ListReservists"> 
                            <NavLink to={'/'}>Список резервистов</NavLink>
                            <span className="IssueItemPage__ListReservists--Icon"><Icons name="iconDownload" /></span>
                        </div> 
                        */}
                        {isApprovePlanIssue && plan?.id
                            ? renderPlan(plan)
                            : isApprovePlanIssue &&
                              !loading && (
                                  <div className="IssueItemPage__NoPlan">ИПР не найден</div>
                              )}
                        <div
                            className="IssueItemPage__Description"
                            data-test="IssueItemPage_DueDate--text"
                        >
                            {issue.dueDate &&
                                `Выполнить до ${formatDate(issue.dueDate, 'DD.MM.YYYY')}`}
                        </div>
                        {issue?.parentIssueId && isSelfIssue && (
                            <div className="IssueItemPage__Director">
                                <Link
                                    data-test="IssueItemPage_Director--link"
                                    to={lkIssueTrackingSelect.buildUrl({
                                        id: issue?.parentIssueId,
                                    })}
                                >
                                    Открыть в контроле исполнения
                                </Link>
                            </div>
                        )}
                    </div>

                    <InfoField title="Файлы" noIcon>
                        <IssueFile files={issue.issueFiles} />
                    </InfoField>
                    <div className="IssueItemPage__Director">
                        {issue.author && issue.author.personId ? (
                            <Link
                                data-test={`IssueItemPage_Director--link id_${issue?.author?.personId}`}
                                to={{
                                    pathname: lkUrl.buildUrl({ id: issue.author.personId }),
                                    search: `type=${MENU_PROFILE}`,
                                }}
                            >
                                {authorName}
                            </Link>
                        ) : (
                            <div data-test={`IssueItemPage_Director--text`}>{authorName}</div>
                        )}
                    </div>
                    {issueHistory && Array.isArray(issueHistory) && issueHistory.length > 0 && (
                        <InfoField
                            title="История изменений"
                            isWrap={isWrap}
                            onWrap={openIssueHistory}
                            value={true}
                            tooltip={false}
                            isWrapIcon={true}
                            iconToTop={true}
                            noIcon
                        >
                            {isWrap && (
                                <IssueHistory
                                    lkUrl={lkUrl}
                                    history={issueHistory}
                                    forceMobileView={true}
                                />
                            )}
                        </InfoField>
                    )}
                </div>
            </div>
            <StickyFooter withPseudo>
                {!loading && <StickyBottomAcceptMenu {...MenuSetup} />}
            </StickyFooter>
            <AcceptPage
                show={isPlanToBeApproved}
                title={'Согласовать ИПР?'}
                acceptAction={handlerOnAcceptPlan}
                setIsAcceptPage={onAcceptPlan(false)}
            />
            <AcceptPage
                show={isRemove}
                title={'Вы действительно хотите удалить задачу?'}
                acceptAction={onRemove}
                setIsAcceptPage={onSetRemove(false)}
            />
        </div>
    );
};

IssueItemPage.propTypes = {
    issuesProps: PropTypes.object.isRequired,
    push: PropTypes.func.isRequired,
    activeCard: PropTypes.string.isRequired,
    setActiveCard: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
    issuesProps: store.issues,
    currentUser: store.auth.user,
});

const actions = { push, updatedIssues };
export default connect(mapStateToProps, actions)(IssueItemPage);
