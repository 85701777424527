import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { lkDetailsEditWishList } from 'routes';
import InfiniteScroll from 'react-infinite-scroller';
import { showSuccessAlert, showErrorAlert } from 'ducks/Alert';
import { setWishlistCommentsUpdated } from 'ducks/Wishlists';
import { getWishlistById, addWishlistsComment, updateWishlistBatch } from 'api';
import WishlistItem from './WishlistItem';
import Loader from 'components/common/Loader';
import { visibilityFilterOptions } from '../constants';
import ManageWishlistsBottomActions from '../ManageWishlistsBottomActions';
import ModalPopup from 'components/Lk/Uikit/ModalPopup';
import WithKeyboardHandler from 'components/Lk/Hooks/WithKeyboardHandler';
import Comment from 'components/Lk/Common/CommentsBlock/Comment';
import ModalCommentsBlock from 'components/Lk/Common/CommentsBlock/ModalCommentsBlock';
import './Wishlists.scss';
import 'components/Lk/Common/CommentsBlock/CommentsBlock.scss';

class Wishlists extends Component {
    state = {
        editWishLists: [],
        isModalOpen: false,
        modalHeader: '',
        modalConfirm: () => {},
        modalActionButtons: [],
        canRename: true,
        lastLoaded: new Date(),
        saving: false,
        showModalCloseIcon: false,
        loadingComments: false,
        isCommentsModalOpen: false,
        comments: [],
        wishlistId: null,
        isNameModified: false,
    };

    setLastLoaded = () => this.setState({ lastLoaded: new Date() });

    updateEditList = (id) => (e) => {
        const { editWishLists } = this.state;
        const newList = editWishLists.map((x) =>
            x.id === id ? { ...x, name: e.target.value } : { ...x },
        );
        this.setState({ editWishLists: newList, isNameModified: true });
    };

    onCancelRenameWishlists = () => {
        const { wishLists, handleStateChange } = this.props;
        this.setState({ editWishLists: wishLists, isNameModified: false });
        handleStateChange({ renameWishlists: false, isMassEditDisabled: false });
    };

    onRenameWishlists = async () => {
        this.setState({
            isModalOpen: true,
            modalConfirm: this.renameWishLists,
            modalHeader: 'Переименовать подборки?',
            modalActionButtons: ['Отменить', 'Переименовать'],
            showModalCloseIcon: false,
            modalBody: '',
            fitScreenSizeModal: false,
        });
    };

    renameWishLists = async () => {
        const {
            renameWishlists,
            wishLists,
            selectedWishlists,
            loadLists,
            handleStateChange,
        } = this.props;
        const { editWishLists } = this.state;
        const readyWishlists = !renameWishlists ? wishLists : editWishLists;

        try {
            this.setState({ saving: true, isNameModified: false });
            const updatedWishlists = readyWishlists.filter(
                (x) => selectedWishlists?.includes(x.id) && !x.isDefault,
            );

            if (updatedWishlists.length > 0) {
                await updateWishlistBatch(updatedWishlists);
                showSuccessAlert('Подборки успешно переименованы');
            }

            loadLists();
            handleStateChange({
                renameWishlists: false,
                isMassEditDisabled: false,
                loading: false,
            });
        } catch (e) {
            showErrorAlert('Ошибка при переименовании подборок');
        } finally {
            this.setState({ isModalOpen: false, saving: false });
        }
    };

    canRename = () => {
        const { editWishLists } = this.state;
        const { selectedWishlists = [] } = this.props;

        const canRename =
            editWishLists.filter((x) => selectedWishlists?.indexOf(x.id) >= 0 && !x.isDefault)
                .length !== 0;

        this.setState({ canRename });
    };

    showComments = (wishlistId) => async () => {
        const comments = await this.fetchComments(wishlistId);
        this.setState({
            isCommentsModalOpen: true,
            comments,
            wishlistId,
        });
    };

    fetchComments = async (wishlistId) => {
        try {
            this.setState({ loadingComments: true });
            const data = await getWishlistById(wishlistId);
            const wishlist = data.data;
            return wishlist.comments;
        } catch (e) {
            showErrorAlert('Ошибка получения подборки');
        } finally {
            this.setState({ loadingComments: false });
        }
    };

    prepareComments = (comments) => {
        return comments.map((x) => <Comment key={x.operationId} comment={x} />);
    };

    addComment = async (comment) => {
        const { wishlistId } = this.state;
        try {
            this.setState({ saving: false });
            await addWishlistsComment([wishlistId], comment);
            await this.props.setWishlistCommentsUpdated({ isWishlistCommentsUpdated: true });
            showSuccessAlert('Заметка успешно добавлена');
        } catch (e) {
            showErrorAlert('Ошибка добавления заметки');
        } finally {
            this.setState({ isModalOpen: false, saving: false });
        }
    };

    componentDidMount() {
        const { wishLists, handleStateChange } = this.props;
        this.setState({ editWishLists: wishLists });
        handleStateChange({ loading: false });
    }

    componentDidUpdate(prevProps, prevState) {
        const { wishLists, loadLists, handleStateChange } = this.props;

        if (JSON.stringify(prevProps.wishLists) !== JSON.stringify(wishLists)) {
            this.setState({ editWishLists: wishLists });
        }

        if (
            JSON.stringify(prevState.editWishLists) !== JSON.stringify(this.state.editWishLists) ||
            JSON.stringify(prevProps.selectedWishlists) !==
                JSON.stringify(this.props.selectedWishlists)
        ) {
            this.canRename();
        }

        if (this.props.updated && this.props.updated > this.state.lastLoaded) {
            this.setState({ lastLoaded: new Date(), paging: {} });
            loadLists();
            handleStateChange({ loading: false });
        }
    }

    render() {
        const {
            isFocused,
            loading,
            wishLists,
            selectedWishlists,
            setSelectedWishlists,
            handleLoadMore,
            hasMore,
            mode,
            renameWishlists,
            push,
            zipSearch,
            withoutComments = false,
        } = this.props;

        const {
            editWishLists,
            isModalOpen,
            modalHeader,
            modalConfirm,
            modalActionButtons,
            saving,
            showModalCloseIcon,
            modalBody,
            fitScreenSizeModal,
            loadingComments,
            isCommentsModalOpen,
            comments,
            isNameModified,
        } = this.state;

        const readyWishlists = !renameWishlists ? wishLists : editWishLists;

        return (
            <div className="LKWishLists" id="Wishlists">
                {readyWishlists && readyWishlists.length !== 0 ? (
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={handleLoadMore}
                        hasMore={hasMore}
                        initialLoad={false}
                        useWindow={false}
                    >
                        {readyWishlists.map((x) => (
                            <WishlistItem
                                key={x.id}
                                isSelected={!!selectedWishlists.find((s) => s === x.id)}
                                wishlistId={x.id}
                                name={x.isDefault ? 'Моё избранное' : x.name}
                                amount={x.entriesCount}
                                visibility={
                                    visibilityFilterOptions.find((f) => f.type === x.visibility)
                                        .name
                                }
                                modified={x.modified}
                                setSelectedWishlists={setSelectedWishlists}
                                selectedWishlists={selectedWishlists}
                                withAction={mode === 'edit'}
                                editMode={renameWishlists}
                                updateEditList={this.updateEditList}
                                showComments={this.showComments}
                                commentCount={x.commentCount}
                                withoutComments={withoutComments}
                                isDefault={x.isDefault}
                                action={
                                    mode === 'edit' && !renameWishlists
                                        ? () =>
                                              push({
                                                  pathname: lkDetailsEditWishList.buildUrl({
                                                      id: x.id,
                                                  }),
                                                  search: queryString.stringify({
                                                      q: zipSearch,
                                                  }),
                                              })
                                        : null
                                }
                            />
                        ))}
                    </InfiniteScroll>
                ) : (
                    !loading && <div className="NotResult">Подборки не найдены</div>
                )}
                <Loader absolute={loadingComments} show={loadingComments || loading} />
                <ModalPopup
                    isModalOpen={isModalOpen}
                    confirm={modalConfirm}
                    header={modalHeader}
                    onModalClose={() => this.setState({ isModalOpen: false })}
                    actionButtons={modalActionButtons}
                    processing={saving}
                    showCloseIcon={showModalCloseIcon}
                    fitScreenSize={fitScreenSizeModal}
                >
                    {modalBody}
                </ModalPopup>
                <ModalCommentsBlock
                    comments={comments}
                    isOpen={isCommentsModalOpen}
                    closeModal={() => this.setState({ isCommentsModalOpen: false })}
                    onSubmit={this.addComment}
                    fitScreenSize
                    actionButtons={['Отменить', 'Сохранить']}
                    processing={saving}
                    commentIdKey="operationId"
                    type="wishlist"
                />

                {renameWishlists && (
                    <ManageWishlistsBottomActions
                        cancel={this.onCancelRenameWishlists}
                        apply={this.state.canRename ? this.onRenameWishlists : () => {}}
                        enabled={isNameModified}
                        isFocused={isFocused}
                    />
                )}
            </div>
        );
    }
}

const actions = { push, setWishlistCommentsUpdated };
export default connect(null, actions)(WithKeyboardHandler(Wishlists, 'Wishlists'));
