import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { signIn as signInRoute, home } from 'routes';
import { fetchUser, selectInterface } from 'ducks/Auth';
import Logo from 'components/common/Logo';
import Button from 'components/uikit/Button';
import CheckField from 'components/uikit/CheckField';
import './InterfaceSelector.scss';
import { showInfoAlert, showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import { USER_INTERFACE_MODERATOR, USER_INTERFACE_LEADER } from 'constants.js';
import { canSelectInterface } from 'rightsController';
import Auth from '../Auth/Auth';
import Popover from 'components/Lk/Uikit/Popover';

const LeaderButton = (props) => {
    return (
        <>
            {props.noCard ? (
                <Popover
                    tooltip={
                        'Для перехода в основной интерфейс необходима прикрепленная анкета. Обратитесь в службу поддержки.'
                    }
                    autoPlacement={false}
                    tooltipPlacement={'top'}
                    childrenClassName={'CenterContent'}
                >
                    {props.children}
                </Popover>
            ) : (
                props.children
            )}
        </>
    );
};
class InterfaceSelector extends Component {
    state = {
        remember: false,
    };

    componentDidMount() {
        const { auth } = this.props;

        if (!auth || !auth.user) {
            this.props.push(signInRoute.buildUrl({}));
            return;
        }

        if (!canSelectInterface(auth.user)) {
            this.props.push(home.buildUrl({}));
        }
    }

    onSubmit = async (e, userInterface) => {
        e.preventDefault();

        this.props.selectInterface(userInterface, this.state.remember);
    };

    onIsRememberChanged = (isRemember) => {
        this.setState({
            remember: isRemember,
        });
    };

    renderInterfaceBox() {
        const { auth } = this.props;

        return (
            <div className="kr_form_wrap">
                <>
                    <div className="kr_form_title" data-test="MainPageSelectInterfaceTitle--text">
                        Вход
                    </div>
                    <div className="kr_form_description">
                        Выберите интерфейс для дальнейшей работы в системе
                    </div>
                    <div>
                        <div className="Interface__StageFormContainer">
                            <div className="kr_btn_wrap">
                                {auth && (
                                    <LeaderButton noCard={!auth.user.personId}>
                                        <Button
                                            onClick={(e) => this.onSubmit(e, USER_INTERFACE_LEADER)}
                                            disabled={
                                                auth.selectInterfaceInProgress ||
                                                !auth.user.personId
                                            }
                                            data-test="GoToMain--button"
                                        >
                                            Основной
                                        </Button>
                                    </LeaderButton>
                                )}
                            </div>
                            <div className="kr_btn_wrap">
                                <Button
                                    onClick={(e) => this.onSubmit(e, USER_INTERFACE_MODERATOR)}
                                    disabled={auth.selectInterfaceInProgress}
                                    data-test="GoToService--button"
                                >
                                    Служебный
                                </Button>
                            </div>

                            <div className="kr_checkbox_wrap">
                                <CheckField
                                    id="isRemember"
                                    title="Запомнить выбор"
                                    checked={this.state.remember}
                                    onChange={this.onIsRememberChanged}
                                    size="100"
                                    modifier="InterfaceSelector"
                                    disabled={auth.selectInterfaceInProgress}
                                />
                            </div>
                        </div>
                    </div>
                </>
            </div>
        );
    }

    render() {
        return (
            <div className="InterfaceSelectorBackground">
                <div className="InterfaceSelector">
                    <div className="InterfaceSelectorLeft">
                        <div className="InterfaceSelectorLeft__Title">
                            <h1 className="InterfaceSelector__LogoWrapper">
                                <Logo svgLogo size="lg" transparent noUrl />
                            </h1>
                            <h2 className="InterfaceSelectorLeft__Welcome">
                                Добро пожаловать в «Интеллектуальную Систему Кадрового Резерва»!
                            </h2>
                        </div>
                    </div>
                    <div className="InterfaceSelectorForm">
                        <div className="BlurContainer">
                            <div className="BlurContainer__Blur" />
                        </div>
                        <div className="InterfaceSelectorForm__LoginBox">
                            {this.renderInterfaceBox()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const props = (state) => {
    return {
        auth: state.auth,
    };
};

const actions = {
    selectInterface,
    fetchUser,
    push,
    showInfoAlert,
    showErrorAlert,
    showSuccessAlert,
};

export default connect(props, actions)(Auth(InterfaceSelector));
