import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LoadingComponent = ({ loading }) => {
    if (!loading) {
        return null;
    }

    return (
        <div className="-loading -active">
            <div className="-loading-inner">
                <div className="GridLoader">
                    Загрузка данных...
                    <FontAwesomeIcon icon="spinner" spin size="2x" />
                </div>
            </div>
        </div>
    );
}

export default LoadingComponent;