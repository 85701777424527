import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { NavLink } from 'react-router-dom';
import uuid from 'uuid/v4';

import { resourceProviderRoute, resourceProvidersRoute } from 'routes';
import { searchResourceProviders, deleteResourceProviderById } from 'api';
import { serviceResultCode, getError } from 'serviceErrors';

import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';

import Page from 'components/common/Page';
import DataGrid from 'components/common/DataGrid';
import Button from 'components/uikit/Button';
import ListTemplate from 'components/uikit/ListTemplate';
import ModalDialog from 'components/common/ModalDialog';
import './ResourceProviders.scss';
import { parse as queryStringParse, stringify } from 'query-string';
import useUpdateEffect from 'components/Lk/Hooks/useUpdateEffect';

const getUrlFilterParams = (state) => {
    return {
        page: state.paging.pageNum !== null ? Number(state.paging.pageNum) : 1,
        pageSize: state.paging.pageSize !== null ? Number(state.paging.pageSize) : 1,
        title: state.filter.title,
    };
};

const initialProviders = {
    payload: [],
    meta: { foundCount: 0, pageCount: 0 },
    loaded: false,
};

const initialCriteria = {
    filter: { title: '' },
    sorting: { title: 'asc' },
    paging: { pageNum: 1, pageSize: 10 },
};

const ResourceProviders = (props) => {
    const { push } = props;

    const [filter, setFilter] = useState(initialCriteria.filter);
    const [criteria, setCriteria] = useState(initialCriteria);
    const [stringCriteria, setStringCriteria] = useState(JSON.stringify(initialCriteria));
    const [providers, setProviders] = useState(initialProviders);
    const [showModal, setShowModal] = useState(false);
    const [currentProvider, setCurrentProvider] = useState(null);
    const [isDeleting, setDeleteState] = useState(false);

    useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            try {
                showPageLoader();

                const newCriteria = getStateFromUrl();

                const providersData = await searchResourceProviders(newCriteria);
                if (
                    mounted &&
                    providersData &&
                    providersData.status === 200 &&
                    providersData.data.payload &&
                    providersData.data.meta
                ) {
                    setProviders({
                        payload: providersData.data.payload,
                        meta: providersData.data.meta,
                        loaded: true,
                    });
                }
            } catch (error) {
                const reqError = getError(error, getResourceProvidersError);
                showErrorAlert(reqError.message);
            } finally {
                hidePageLoader();
            }
        };
        fetchData();

        return () => {
            mounted = false;
        };

        // eslint-disable-next-line
    }, [props.location.search]);

    useUpdateEffect(() => {
        const updateUrl = () => {
            const filter = getUrlFilterParams(JSON.parse(stringCriteria));
            const baseFilter = { ...filter };

            push({
                pathname: resourceProvidersRoute.url,
                search: stringify(baseFilter),
            });
        };

        updateUrl();
    }, [stringCriteria, push]);

    const getStateFromUrl = () => {
        if (!props.location.search) {
            setCriteria(initialCriteria);
            setFilter(initialCriteria.filter);

            return initialCriteria;
        }
        const { page = 1, pageSize = 10, title = '' } = queryStringParse(props.location.search);

        const newCriteria = {
            sorting: { title: 'asc' },
            filter: { title },
            paging: {
                ...criteria.paging,
                pageSize: +pageSize,
                pageNum: +page,
            },
        };

        setCriteria(newCriteria);
        setFilter(newCriteria.filter);

        return newCriteria;
    };

    const getResourceProvidersError = (code, payload) => {
        switch (code) {
            case serviceResultCode.NotFound:
                return `${payload}`;
            case serviceResultCode.EntityAlreadyExists:
                return `${payload}`;
            default:
                return `Произошла непредвиденная ошибка`;
        }
    };

    const newResourceProvider = () => {
        props.push(
            resourceProviderRoute.buildUrl({ id: uuid(), regime: 'new' }, props.location.search),
        );
    };

    const headBtn = {
        onClick: newResourceProvider,
        value: 'Добавить',
        addLink: true,
    };

    const columns = [
        {
            Header: 'Провайдер',
            accessor: 'title',
            resizable: false,
            sortable: true,
            Cell: ({ original }) => (
                <NavLink
                    to={resourceProviderRoute.buildUrl(
                        { id: original.id, regime: 'view' },
                        props.location.search,
                    )}
                >
                    {original.title}
                </NavLink>
            ),
        },
        {
            Header: 'Контактное лицо',
            accessor: 'agentName',
            resizable: false,
            sortable: false,
        },
        {
            Header: 'Телефон',
            accessor: 'phone',
            resizable: false,
            sortable: false,
        },
        {
            Header: 'Email',
            accessor: 'email',
            resizable: false,
            sortable: false,
        },
        {
            Header: 'Действие',
            minWidth: 110,
            resizable: false,
            maxWidth: 390,
            sortable: false,
            Cell: ({ original }) => (
                <>
                    <Button
                        size="sm"
                        className="ResourceProvidersActionButton"
                        onClick={() =>
                            props.push(
                                resourceProviderRoute.buildUrl(
                                    { id: original.id, regime: 'edit' },
                                    props.location.search,
                                ),
                            )
                        }
                    >
                        Редактировать
                    </Button>
                    <Button
                        size="sm"
                        color="danger"
                        className="ResourceProvidersActionButton"
                        onClick={() => onOpenModal(original)}
                    >
                        Удалить
                    </Button>
                </>
            ),
        },
    ];

    const onPageChange = (pageIndex) => {
        const newCriteria = {
            ...criteria,
            filter,
            paging: { ...criteria.paging, pageNum: pageIndex + 1 },
        };

        setCriteria(newCriteria);
        setStringCriteria(JSON.stringify(newCriteria));
    };

    const onSortedChange = (sorted) => {
        const newCriteria = {
            ...criteria,
            filter,
            sorting: { ...criteria.sorting, [sorted[0].id]: sorted[0].desc ? 'desc' : 'asc' },
        };

        setCriteria(newCriteria);
        setStringCriteria(JSON.stringify(newCriteria));
    };

    const changePageAmount = (pageSize) => {
        const newCriteria = { ...criteria, paging: { ...criteria.paging, pageSize } };

        setCriteria(newCriteria);
        setStringCriteria(JSON.stringify(newCriteria));
    };

    const renderProviders = () => {
        return (
            <DataGrid
                data={providers.payload}
                foundCount={providers.meta.foundCount}
                columns={columns}
                loading={!providers.loaded}
                showPagination={true}
                showPageSizeOptions={false}
                onSortedChange={onSortedChange}
                pages={providers.meta.pageCount}
                page={criteria.paging.pageNum}
                onPageChange={onPageChange}
                manual
                pageSize={criteria.paging.pageSize}
                changePageAmount={changePageAmount}
            />
        );
    };

    const onCloseModal = () => {
        setShowModal(false);
    };

    const onOpenModal = (provider) => {
        setCurrentProvider(provider);
        setShowModal(true);
    };

    const deleteResourceProvider = async () => {
        try {
            setDeleteState(true);
            showPageLoader();
            await deleteResourceProviderById(currentProvider.id);
            const providersData = await searchResourceProviders(criteria);
            showSuccessAlert('Провайдер успешно удален');
            setProviders({
                payload: providersData.data.payload,
                meta: providersData.data.meta,
                loaded: true,
            });
        } catch (error) {
            const err = getError(error, getProviderError());
            showErrorAlert(err.message);
            setDeleteState(false);
        } finally {
            hidePageLoader();
            onCloseModal();
        }
    };

    const getProviderError = () => (code) => {
        switch (code) {
            case serviceResultCode.NotFound:
                return 'Провайдер не найден';
            case serviceResultCode.PersonGrowthResourceProviderIsInUse:
                return 'Невозможно удалить провайдера, так как он используется';
            default:
                return 'Произошла непредвиденная ошибка';
        }
    };

    const renderModal = () => {
        return (
            <ModalDialog
                onClick={deleteResourceProvider}
                onCloseModal={onCloseModal}
                modalOpen={showModal}
                processing={isDeleting}
                modalHeader={`Вы действительно хотите удалить провайдера ${
                    currentProvider && currentProvider.title
                }?`}
                btnOktext="Да"
                btnOkColor="danger"
                btnCanceltext="Нет"
            />
        );
    };

    const handleFilterTitleInputChange = (e) => {
        setFilter({ ...filter, title: e.target.value });
    };

    const handleEnterKeyPressed = (e) => {
        if (e.key === 'Enter') {
            handleFilterTitleInputChange(e);
            const newCriteria = {
                ...criteria,
                filter: filter,
                paging: { ...criteria.paging, pageNum: 1 },
            };
            setCriteria(newCriteria);
            setStringCriteria(JSON.stringify(newCriteria));
        }
    };

    const handleFilterOnClick = () => {
        const newCriteria = {
            ...criteria,
            filter: filter,
            paging: { ...criteria.paging, pageNum: 1 },
        };
        setCriteria(newCriteria);
        setStringCriteria(JSON.stringify(newCriteria));
    };

    const handleClearFilters = (e) => {
        e.preventDefault();
        setFilter(initialCriteria.filter);
        setCriteria(initialCriteria);
        setStringCriteria(JSON.stringify(initialCriteria));
    };

    const filterForm = {
        title: 'Фильтр',
        line: [
            {
                type: 'Input',
                label: 'Провайдер',
                placeholder: 'Укажите провайдера',
                id: 'title',
                name: 'title',
                value: filter.title,
                onChange: handleFilterTitleInputChange,
                onKeyDown: handleEnterKeyPressed,
            },
        ],
        btnOnClick: handleFilterOnClick,
        btnClearOnClick: handleClearFilters,
    };

    return (
        <Page>
            <ListTemplate title={'Провайдеры'} headBtn={headBtn} form={filterForm}>
                {renderProviders()}
            </ListTemplate>
            {renderModal()}
        </Page>
    );
};

const actions = { showPageLoader, hidePageLoader, showErrorAlert, push };
export default connect(null, actions)(ResourceProviders);
