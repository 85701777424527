import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { sidebarRoutes as routes } from 'routes';
import Page from 'components/common/Page';
import { canEditPerson, canUsePersonnel, canEditInstruction, isModerator, canEditFromModeratorInterface, canImportPerson } from '../../rightsController';
import { getDashboardInfo as fetchData } from 'api.js';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import * as view from 'view.js';
import PersonChart from './PersonChart';

class Dashbord extends PureComponent {
    state = {
        personsInSystem: 0,
        personsInGroup: 0,
        regions: 0,
        personProfilesFilling: 0,
    };

    componentDidMount() {
        this.props.showPageLoader();
        fetchData()
            .then(this.handleData, this.handleError)
            .then(this.props.hidePageLoader);
    }

    render() {
        const { personsInSystem, personsInGroup, regions, personProfilesFilling } = this.state;

        return (
            <>
                <Page id="home-stats">
                    <div className="kr_home-stats_numbers kr_left">
                        <div className="kr_item v2">
                            <div className="kr_item_text">Всего анкет</div>
                            <div className="kr_item_number">{personsInSystem}</div>
                        </div>

                        <div className="kr_item">
                            <div className="kr_item_text">В моей группе</div>
                            <div className="kr_item_number">{personsInGroup}</div>
                        </div>

                        <div className="kr_text"></div>

                        <div className="kr_item v2">
                            <div className="kr_item_text">
                                Всего
                                <br />
                                регионов / ведомств
                            </div>
                            <div className="kr_item_number">{regions}</div>
                        </div>

                        <div className="kr_item v2">
                            <div className="kr_item_text">Процент заполненности</div>
                            <div className="kr_item_number">
                                {view.numberAsPercentages(personProfilesFilling)}
                            </div>
                        </div>
                    </div>
                    <PersonChart viewOnly={!canUsePersonnel(this.props.user)}/>
                </Page>

                <Page id="home-navigation">
                    <div className="kr_menu">
                        <ul>
                            {canUsePersonnel(this.props.user) && canEditFromModeratorInterface(this.props.user) && (
                                <>
                                    {canEditPerson(this.props.user) && (
                                        <li className="kr_item2">
                                            <Link to={routes.personNew.url}>
                                                <svg
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="444.9695653169651"
                                                    height="531.8181895072913"
                                                    viewBox="0 0 444.9695653169651 531.8181895072913"
                                                >
                                                    <g transform="matrix(1,0,0,1,-43.90921734151749,0.09090524635439579)">
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <circle
                                                                        cx="262.294"
                                                                        cy="149.655"
                                                                        r="149.655"
                                                                    />
                                                                    <path d="M338.097,427.767c0-51.082,41.41-92.49,92.49-92.49c1.323,0,2.635,0.045,3.943,0.1     c-21.247-41.729-45.704-75.837-45.704-75.837s-57.806,62.998-138.735,57.806c-80.929-5.191-115.612-57.806-115.612-57.806     S7.304,375.151,53.549,467.641s300.593,57.807,300.593,57.807s19.505-1.461,43-11.451     C362.599,500.588,338.097,467.05,338.097,427.767z" />
                                                                    <path d="M476.832,416.206h-34.684v-34.685c0-6.384-5.177-11.562-11.563-11.562c-6.384,0-11.56,5.177-11.56,11.562v34.685h-34.684     c-6.386,0-11.562,5.176-11.562,11.562s5.176,11.562,11.562,11.562h34.684v34.684c0,6.386,5.176,11.563,11.56,11.563     c6.386,0,11.563-5.177,11.563-11.563V439.33h34.684c6.386,0,11.562-5.176,11.562-11.562S483.218,416.206,476.832,416.206z" />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                Добавление резервиста
                                            </Link>
                                        </li>
                                    )}

                                    <li className="kr_item3">
                                        <Link to={routes.search.url}>
                                            <svg
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="250.31199999999998"
                                                height="250.31199999999998"
                                                viewBox="0 0 250.31199999999998 250.31199999999998"
                                            >
                                                <g transform="matrix(1,0,0,1,-0.1560000000000059,-0.1560000000000059)">
                                                    <g id="Search">
                                                        <path d="M244.186,214.604l-54.379-54.378c-0.289-0.289-0.628-0.491-0.93-0.76   c10.7-16.231,16.945-35.66,16.945-56.554C205.822,46.075,159.747,0,102.911,0S0,46.075,0,102.911   c0,56.835,46.074,102.911,102.91,102.911c20.895,0,40.323-6.245,56.554-16.945c0.269,0.301,0.47,0.64,0.759,0.929l54.38,54.38   c8.169,8.168,21.413,8.168,29.583,0C252.354,236.017,252.354,222.773,244.186,214.604z M102.911,170.146   c-37.134,0-67.236-30.102-67.236-67.235c0-37.134,30.103-67.236,67.236-67.236c37.132,0,67.235,30.103,67.235,67.236   C170.146,140.044,140.043,170.146,102.911,170.146z" />
                                                    </g>
                                                </g>
                                            </svg>
                                            Расширенный поиск
                                        </Link>
                                    </li>
                                    <li className="kr_item4">
                                        <Link to={routes.analytics.url}>
                                            <svg
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="469.3270000785104"
                                                height="512"
                                                viewBox="0 0 469.3270000785104 512"
                                            >
                                                <g transform="matrix(1,0,0,1,-20.99950003925521,0)">
                                                    <g>
                                                        <g>
                                                            <path d="M469.331,42.666H277.333V21.333C277.333,9.552,267.781,0,256,0s-21.333,9.552-21.333,21.333v21.333H42.669    c-11.782,0-21.333,9.552-21.333,21.333v341.328c0,11.78,9.551,21.333,21.333,21.333h93.482l-27.23,54.461    c-5.269,10.539-0.998,23.353,9.54,28.622c10.54,5.269,23.353,0.997,28.62-9.542l36.771-73.541h144.296l36.77,73.541    c3.738,7.477,11.271,11.799,19.097,11.799c3.206,0,6.461-0.727,9.523-2.257c10.539-5.269,14.809-18.083,9.54-28.622l-27.23-54.461    h93.482c11.782,0,21.333-9.553,21.333-21.333V63.999C490.664,52.218,481.112,42.666,469.331,42.666z M401.819,179.354    L301.253,279.919c-4.001,4.001-9.428,6.248-15.084,6.248c-5.659,0-11.085-2.249-15.085-6.251l-45.253-45.255l-85.48,85.48    c-4.166,4.167-9.625,6.251-15.084,6.251c-5.46,0-10.92-2.084-15.084-6.251c-8.334-8.33-8.334-21.837-0.001-30.168l100.566-100.566    c4.001-4.001,9.426-6.248,15.084-6.248s11.085,2.247,15.085,6.248l45.253,45.256l85.48-85.48c8.331-8.33,21.839-8.33,30.169,0    C410.15,157.515,410.15,171.022,401.819,179.354z" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            Аналитика
                                        </Link>
                                    </li>
                                    <li className="kr_item5">
                                        <Link to={routes.reports.url}>
                                            <svg
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="408"
                                                height="510"
                                                viewBox="0 0 408 510"
                                            >
                                                <g transform="matrix(1,0,0,1,-51,0)">
                                                    <g>
                                                        <g id="drive-file">
                                                            <path d="M102,0C73.95,0,51,22.95,51,51v408c0,28.05,22.95,51,51,51h306c28.05,0,51-22.95,51-51V153L306,0H102z M280.5,178.5V38.25    L420.75,178.5H280.5z" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            Конструктор отчётов
                                        </Link>
                                    </li>
                                    {canImportPerson(this.props.user) && (
                                        <li className="kr_item6">
                                            <Link to={routes.importRoute.url}>
                                                <svg
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="47.2950016783271"
                                                    height="52.75599999999999"
                                                    viewBox="0 0 47.2950016783271 52.75599999999999"
                                                >
                                                    <g transform="matrix(1,0,0,1,-2.3785008391635483,-0.3780000000000001)">
                                                        <g>
                                                            <g>
                                                                <g>
                                                                    <path d="M26.106,29.059l10.523-10.523c1.047-1.024,0.813-1.746-0.649-1.746h-5.873L30.108,4c0-2.209-1.79-4-4-4l0,0     c-2.209,0-4,1.791-4,4l-0.001,12.789h-5.876c-1.462-0.001-1.693,0.723-0.646,1.747L26.106,29.059z" />
                                                                    <path d="M49.027,25.77h-6.049c-0.554,0-1,0.447-1,1v17.939H10.78V26.77c0-0.553-0.447-1-1-1H3.731c-0.553,0-1,0.447-1,1v20.464     c0,3.045,2.479,5.522,5.524,5.522h36.248c3.046,0,5.523-2.479,5.523-5.522V26.77C50.027,26.217,49.581,25.77,49.027,25.77z" />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                Импорт
                                            </Link>
                                        </li>
                                    )}
                                </>
                            )}
                            {canEditInstruction(this.props.user) && !isModerator(this.props.user) && (
                                <li className="kr_item5">
                                    <Link to={routes.instructionsEdit.url}>
                                        <svg
                                            version="1.1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="408"
                                            height="510"
                                            viewBox="0 0 408 510"
                                        >
                                            <g transform="matrix(1,0,0,1,-51,0)">
                                                <g>
                                                    <g id="drive-file">
                                                        <path d="M102,0C73.95,0,51,22.95,51,51v408c0,28.05,22.95,51,51,51h306c28.05,0,51-22.95,51-51V153L306,0H102z M280.5,178.5V38.25    L420.75,178.5H280.5z" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        Редактор инструкций
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </div>
                </Page>
            </>
        );
    }

    handleData = response => {
        this.setState(state => ({
            ...state,
            ...response.data,
        }));

        return Promise.resolve();
    };

    handleError = () => {
        this.props.user?.roles.length !== 0 && this.props.showErrorAlert('При загрузке данных произошла ошибка');

        return Promise.resolve();
    };
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
    };
}

const actions = {
    showPageLoader,
    hidePageLoader,
    showErrorAlert,
};

export default connect(
    mapStateToProps,
    actions,
)(Dashbord);
