import React from 'react';
import { connect } from 'react-redux';
import { PageLoader } from 'components/common/Loader';
import Header from 'components/Lk/Basic/Layout/Header';

const Layout = props => {
    const { currentUser } = props;

    return (
        <div className="LeaderLayout">
            <Header user={currentUser} />
            <div className="LeaderLayout__Wrapper">
                <div id="modal-root" />
                <div id="tooltip-root" />
                {props.children}
            </div>
            <PageLoader />
        </div>
    );
};

const props = state => {
    return {
        currentUser: state.auth.user,
    };
};
export default connect(props)(Layout);
