import { appName, WIDGET_TYPE_TEAM } from '../constants';
import { takeEvery, call, all, put, takeLatest, select } from 'redux-saga/effects';
import { getUserSettings, updateUserSettings, createUserSettings } from 'api';
import { showErrorAlert } from './Alert';
import RequestError from 'RequestError';
import uuid from 'uuid/v4';
import { withPageLoader } from './PageLoader';

const moduleName = 'widget';
const FETCH_SETTINGS = `${appName}/${moduleName}/FETCH_SETTINGS`;
const SET_SETTINGS = `${appName}/${moduleName}/SET_SETTINGS`;
const SET_WIDGETS_DATA = `${appName}/${moduleName}/SET_WIDGETS_DATA`;

const defaultSettings = {
    columns: [
        {
            id: uuid(),
            width: 3,
            widgets: [
                {
                    id: uuid(),
                    type: WIDGET_TYPE_TEAM,
                    settings: {},
                },
            ],
        },
        {
            id: uuid(),
            width: 6,
            widgets: [
                {
                    id: uuid(),
                    type: WIDGET_TYPE_TEAM,
                    settings: {},
                },
            ],
        },
        { id: uuid(), width: 3, widgets: [] },
    ],
};

const initialState = {
    id: null,
    type: '',
    settings: { columns: [] },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_SETTINGS: {
            return action.payload;
        }
        case SET_WIDGETS_DATA: {
            return { ...state, settings: { ...state.settings, columns: action.payload } };
        }
        default:
            return state;
    }
}

export const fetchSettings = payload => ({ type: FETCH_SETTINGS, payload });
const setSettings = payload => ({ type: SET_SETTINGS, payload });

export const setUserSettingsData = payload => ({ type: SET_WIDGETS_DATA, payload });

function* fetchSettingsSaga() {
    try {
        const resp = yield call(withPageLoader, () => getUserSettings());

        const { id, type, payload } = resp.data;

        yield put(
            setSettings({
                id,
                settings: (payload && JSON.parse(payload)) || defaultSettings,
                type,
            }),
        );
    } catch (error) {
        const reqError = new RequestError(error, 'При загрузке произошла ошибка');
        yield put(showErrorAlert(reqError.message));
    }
}

function* saveSettingsSaga() {
    try {
        const { settings, ...widget } = yield select(s => s.widget);

        const data = {
            ...widget,
            payload: JSON.stringify(settings),
        };

        const resp = yield call(widget.id ? updateUserSettings : createUserSettings, data);
        const { id, type, payload } = resp.data;
        yield put(
            setSettings({
                id,
                settings: JSON.parse(payload),
                type,
            }),
        );
    } catch (error) {
        const reqError = new RequestError(error, 'При сохранении произошла ошибка');
        yield put(showErrorAlert(reqError.message));
    }
}

export function* saga() {
    yield all([
        takeEvery(FETCH_SETTINGS, fetchSettingsSaga),
        takeLatest(SET_WIDGETS_DATA, saveSettingsSaga),
    ]);
}
