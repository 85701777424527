import {
    getDefaultWishlist,
    getWishlistPersonProgress,
    updateWishlist as updateWishlistApi,
    getWishLists,
    createWishList,
    addPersonsToWishlist,
    removePersonsFromWishlist,
    getFullWishlist,
    deleteWishlist as deleteWishlistApi,
    getWishlistsAllPersonIds,
    getWishListsByIds,
    updateWishlistsVisibility,
    updatePersonWihslists,
} from 'api';
import { appName } from 'constants.js';
import { all, takeEvery, put, call, takeLatest } from 'redux-saga/effects';
import RequestError from '../RequestError';
import { showErrorAlert, showSuccessAlert } from './Alert';

const moduleName = 'wishlists';
const FETCH_WISHLISTS_REQUEST = `${appName}/${moduleName}/FETCH_WISHLISTS_REQUEST`;
const FETCH_WISHLISTS_BY_IDS_REQUEST = `${appName}/${moduleName}/FETCH_WISHLISTS_BY_IDS_REQUEST`;
const CREATE_WISHLIST = `${appName}/${moduleName}/CREATE_WISHLIST`;
const SET_LAST_WISHLIST = `${appName}/${moduleName}/SET_LAST_WISHLIST`;
const SET_WISHLIST_PERSONS_UPDATED = `${appName}/${moduleName}/SET_WISHLIST_PERSONS_UPDATED`;
const SET_WISHLIST_COMMENTS_UPDATED = `${appName}/${moduleName}/SET_WISHLIST_COMMENTS_UPDATED`;
const ADD_TO_WISHLIST = `${appName}/${moduleName}/ADD_TO_WISHLIST`;
const REMOVE_FROM_WISHLIST = `${appName}/${moduleName}/REMOVE_FROM_WISHLIST`;
const UPDATE_PERSON_WISHLISTS = `${appName}/${moduleName}/UPDATE_PERSON_WISHLISTS`;
const SET_PERSON_CART = `${appName}/${moduleName}/SET_PERSON_CART`;
const SET_WISHLISTS_VISIBILITY = `${appName}/${moduleName}/SET_WISHLISTS_VISIBILITY`;
const SET_WISHLISTS_MODE = `${appName}/${moduleName}/SET_WISHLISTS_MODE`;

const DELETE_WISHLIST = `${appName}/${moduleName}/DELETE_WISHLIST`;

const FETCH_REQUEST = `${appName}/${moduleName}/FETCH_REQUEST`;
const CHANGE_REQUEST = `${appName}/${moduleName}/CHANGE_REQUEST`;
const FETCH_START = `${appName}/${moduleName}/FETCH_START`;
const FETCH_END = `${appName}/${moduleName}/FETCH_END`;
const FETCH_FAVORITES_END = `${appName}/${moduleName}/FETCH_FAVORITES_END`;
const FETCH_PERSONS_PROGRESS_REQUEST = `${appName}/${moduleName}/FETCH_PERSONS_PROGRESS_REQUEST`;

const FETCH_PERSON_IDS = `${appName}/${moduleName}/FETCH_PERSON_IDS`;
const FETCH_PERSON_IDS_SUCCESS = `${appName}/${moduleName}/FETCH_PERSON_IDS_SUCCESS`;
const SET_EDIT_LOADING = `${appName}/${moduleName}/SET_EDIT_LOADING`;

const SET_NEED_ADD_FAVORITES = `${appName}/${moduleName}/SET_NEED_ADD_FAVORITES`;

const getWishlistError = (error, defaultError = 'Произошла ошибка') => {
    return error.response && error.response.data && error.response.data.payload
        ? error.response.data.payload
        : defaultError;
};

export const templateAction = type => payload => ({ type, payload });

export const getFavoritesWishlist = templateAction(FETCH_REQUEST);
export const getAllWishlists = templateAction(FETCH_WISHLISTS_REQUEST);
export const getWishlistsByIds = templateAction(FETCH_WISHLISTS_BY_IDS_REQUEST);
export const createNewWishList = templateAction(CREATE_WISHLIST);
export const setLastWishlistId = templateAction(SET_LAST_WISHLIST);
export const setWishlistPersonsUpdated = templateAction(SET_WISHLIST_PERSONS_UPDATED);
export const setWishlistCommentsUpdated = templateAction(SET_WISHLIST_COMMENTS_UPDATED);
export const deleteWishlist = templateAction(DELETE_WISHLIST);
export const setPersonCart = templateAction(SET_PERSON_CART);
export const addToWishList = templateAction(ADD_TO_WISHLIST);
export const setEditLoading = templateAction(SET_EDIT_LOADING);
export const setWishListsVisibility = templateAction(SET_WISHLISTS_VISIBILITY);
export const setWishlistsMode = templateAction(SET_WISHLISTS_MODE);
export const updatePersonWishlists = templateAction(UPDATE_PERSON_WISHLISTS);

export const removeFromWishList = templateAction(REMOVE_FROM_WISHLIST);

export const setNeedAddFavorites = templateAction(SET_NEED_ADD_FAVORITES);

export const getFavoritesFullWishlist = criteria => {
    return {
        type: FETCH_PERSONS_PROGRESS_REQUEST,
        payload: { criteria },
    };
};

export function updateWishlist(wishlist) {
    return {
        type: CHANGE_REQUEST,
        payload: { wishlist },
    };
}

const fetchStart = templateAction(FETCH_START);

function fetchSuccess(wishlist) {
    return {
        type: FETCH_END,
        payload: { wishlist },
    };
}

function fetchAllWishlistsSuccess(wishlists) {
    return {
        type: FETCH_FAVORITES_END,
        payload: wishlists,
    };
}

function fetchFailed(error) {
    return {
        type: FETCH_END,
        payload: { error },
        error: true,
    };
}

export const fetchAllPersonIds = templateAction(FETCH_PERSON_IDS);
const fetchAllPersonIdsSuccess = templateAction(FETCH_PERSON_IDS_SUCCESS);

const getFavoritesWishlistError = `При получении подборки произошла ошибка`;
const updateWishlistError = `При обновлении подборки произошла ошибка`;
const createWishlistError = `При создании подборки произошла ошибка`;
const updatePersonWishlistsError = `При обновлении подборок резервиста произошла ошибка`;
const deleteWishlistError = `При удалении подборки произошла ошибка`;
const getFullWishlistError = `При получении резервистов подборки произошла ошибка`;
const fetchAllPersonIdsError = 'При получении списков резервистов произошла ошибка';

const initialState = {
    loading: false,
    loadComplete: false,
    ok: {},
    lastWishlistId: null,
    isWishlistPersonsUpdated: false,
    isWishlistCommentsUpdated: false,
    allPersonIds: [],
    loadingAllPersonIds: false,
    editLoading: false,
    mode: 'view',
    needAddFavorites: false,
};

export function reducer(state, action) {
    switch (action.type) {
        case FETCH_PERSONS_PROGRESS_REQUEST:
        case FETCH_START:
            return {
                ...state,
                loading: true,
            };
        case SET_LAST_WISHLIST:
            return {
                ...state,
                lastWishlistId: action.payload.lastWishlistId,
            };
        case SET_WISHLIST_PERSONS_UPDATED:
            return {
                ...state,
                isWishlistPersonsUpdated: action.payload.isWishlistPersonsUpdated,
            };
        case SET_WISHLIST_COMMENTS_UPDATED:
            return {
                ...state,
                isWishlistCommentsUpdated: action.payload.isWishlistCommentsUpdated,
            };
        case FETCH_PERSON_IDS:
            return {
                ...state,
                loadingAllPersonIds: true,
            };
        case FETCH_PERSON_IDS_SUCCESS: {
            return {
                ...state,
                loadingAllPersonIds: false,
                allPersonIds: action.payload,
            };
        }

        case FETCH_END:
            if (!action.error) {
                const { wishlist } = action.payload;

                return {
                    ...state,
                    loading: false,
                    loadComplete: true,
                    ok: wishlist.ok,
                };
            } else {
                return {
                    ...state,
                    loading: false,
                    loadComplete: false,
                    ok: {},
                };
            }
        case FETCH_FAVORITES_END:
            if (!action.error) {
                const wishlist = action.payload;

                return {
                    ...state,
                    loading: false,
                    loadComplete: true,
                    allWishLists: {
                        wishLists: wishlist.payload,
                        meta: wishlist.meta,
                        criteria: wishlist.criteria,
                    },
                };
            } else {
                return {
                    ...state,
                    loading: false,
                    loadComplete: false,
                    allWishLists: null,
                };
            }
        case SET_EDIT_LOADING:
            return { ...state, editLoading: action.payload };
        case SET_WISHLISTS_MODE:
            return { ...state, mode: action.payload };
        case SET_PERSON_CART:
            const { personIds, type } = action.payload;
            return {
                ...state,
                cart: { personIds, type },
            };
        case SET_NEED_ADD_FAVORITES:
            return {...state, needAddFavorites: action.payload}
        default:
            return state || initialState;
    }
}

function* fetchWishlistsSaga() {
    yield put(fetchStart());

    try {
        const response = yield call(getDefaultWishlist);
        const wishlists = {
            ok: {
                payload: response.data,
            },
        };

        yield put(fetchSuccess(wishlists));
        return wishlists;
    } catch (error) {
        const requestError = new RequestError(error, getFavoritesWishlistError);

        yield put(fetchFailed(requestError));
        yield put(showErrorAlert(requestError.message));

        return {
            error: {
                message: getFavoritesWishlistError,
                payload: error,
            },
        };
    }
}

function* fetchAllWishlistsSaga(action) {
    yield put(fetchStart());
    try {
        const criteria = action.payload;
        const response = yield call(getWishLists, criteria);
        const wishlists = { ...response.data, criteria };
        yield put(fetchAllWishlistsSuccess(wishlists));
    } catch (error) {
        const requestError = new RequestError(error, getFavoritesWishlistError);
        yield put(fetchFailed(requestError));
        yield put(showErrorAlert(requestError.message));
        return {
            error: {
                message: getFavoritesWishlistError,
                payload: error,
            },
        };
    }
}

function* fetchWishlistsByIdsSaga(action) {
    yield put(fetchStart());
    try {
        const criteria = action.payload;
        const response = yield call(getWishListsByIds, criteria);
        const wishlists = { payload: response.data, meta: { pageCount: 1 }, criteria };
        yield put(fetchAllWishlistsSuccess(wishlists));
    } catch (error) {
        const requestError = new RequestError(error, getFavoritesWishlistError);
        yield put(fetchFailed(requestError));
        yield put(showErrorAlert(requestError.message));
        return {
            error: {
                message: getFavoritesWishlistError,
                payload: error,
            },
        };
    }
}

function* createWishlistsSaga(action) {
    try {
        const criteria = action.payload;
        const newWishlist = yield call(createWishList, criteria);
        yield criteria.withPersons &&
            addToWishListSaga({
                ...criteria,
                payload: { id: newWishlist.data.id, personIds: criteria.personIds },
            });
        yield put(setLastWishlistId({ lastWishlistId: newWishlist.data.id }));
    } catch (error) {
        const requestError = new RequestError(error, createWishlistError);
        yield put(showErrorAlert(requestError.message));
    }
}

function* updatePersonWishlistsSaga(action) {
    try {
        const criteria = action.payload;
        yield call(updatePersonWihslists, criteria.personId, criteria);
        yield put(setWishlistPersonsUpdated({ isWishlistPersonsUpdated: true }));
        yield put(showSuccessAlert('Подборки, в которых состоит резервист, успешно обновлены'));
    } catch (error) {
        const requestError = new RequestError(error, updatePersonWishlistsError);
        yield put(showErrorAlert(requestError.message));
    }
}

function* deleteWishlistSaga(action) {
    try {
        const id = action.payload;
        const response = yield call(deleteWishlistApi, id);
        if (response.status === 200) {
            yield put(setWishlistPersonsUpdated({ isWishlistPersonsUpdated: true }));
            yield put(showSuccessAlert('Подборка успешно удалена'));
        }
    } catch (error) {
        const requestError = new RequestError(error, deleteWishlistError);
        yield put(showErrorAlert(requestError.message));
    }
}

function* addToWishListSaga(action) {
    try {
        const { id, personIds = [] } = action.payload;
        yield put(setEditLoading(true));
        yield call(addPersonsToWishlist, id, personIds);
        yield put(setWishlistPersonsUpdated({ isWishlistPersonsUpdated: true }));
        yield put(showSuccessAlert('Резервист(ы) успешно добавлен(ы)'));
    } catch (error) {
        yield put(showErrorAlert(getWishlistError(error)));
    } finally {
        yield put(setEditLoading(false));
    }
}

function* removeFromWishListSaga(action) {
    try {
        const { id, personIds = [] } = action.payload;
        yield put(setEditLoading(true));
        yield call(removePersonsFromWishlist, id, personIds);
        yield put(setWishlistPersonsUpdated({ isWishlistPersonsUpdated: true }));
        yield put(showSuccessAlert('Резервист(ы) успешно удален(ы)'));
    } catch (error) {
        yield put(showErrorAlert(getWishlistError(error)));
    } finally {
        yield put(setEditLoading(false));
    }
}

function* fetchPersonProgressSaga(action) {
    try {
        const { criteria } = action.payload;
        const response = yield call(getWishlistPersonProgress, criteria);
        const wishlists = {
            ok: {
                payload: response.data,
            },
        };

        yield put(fetchSuccess(wishlists));
        return wishlists;
    } catch (error) {
        const requestError = new RequestError(error, getFavoritesWishlistError);

        yield put(fetchFailed(requestError));
        yield put(showErrorAlert(requestError.message));

        return {
            error: {
                message: getFavoritesWishlistError,
                payload: error,
            },
        };
    }
}

function* changeWishlistsSaga(action) {
    const { wishlist } = action.payload;

    yield put(fetchStart());
    try {
        const response = yield call(updateWishlistApi(wishlist));
        const wishlists = {
            ok: {
                payload: response.data,
            },
        };
        yield put(fetchSuccess(wishlists));
        return wishlists;
    } catch (error) {
        const requestError = new RequestError(error, updateWishlistError);

        yield put(fetchFailed(requestError));
        yield put(showErrorAlert(requestError.message));
    }
}

function* setWishListsVisibilitySaga(action) {
    const { value, wishLists } = action.payload;
    try {
        yield call(updateWishlistsVisibility, value, wishLists);
    } catch (error) {
        const requestError = new RequestError(error, updateWishlistError);

        yield put(fetchFailed(requestError));
        yield put(showErrorAlert(requestError.message));
    }
}

function* fetchAllPersonIdsSaga() {
    try {
        const response = yield call(getWishlistsAllPersonIds);
        if (response.status === 200) {
            yield put(fetchAllPersonIdsSuccess(response.data));
        }
    } catch (error) {
        const requestError = new RequestError(error, fetchAllPersonIdsError);
        yield put(showErrorAlert(requestError.message));
    }
}

export function* saga() {
    yield all([
        takeEvery(FETCH_REQUEST, fetchWishlistsSaga),
        takeEvery(CHANGE_REQUEST, changeWishlistsSaga),
        takeEvery(FETCH_PERSONS_PROGRESS_REQUEST, fetchPersonProgressSaga),
        takeLatest(FETCH_WISHLISTS_REQUEST, fetchAllWishlistsSaga),
        takeLatest(FETCH_WISHLISTS_BY_IDS_REQUEST, fetchWishlistsByIdsSaga),
        takeLatest(CREATE_WISHLIST, createWishlistsSaga),
        takeLatest(DELETE_WISHLIST, deleteWishlistSaga),
        takeLatest(ADD_TO_WISHLIST, addToWishListSaga),
        takeLatest(REMOVE_FROM_WISHLIST, removeFromWishListSaga),
        takeLatest(FETCH_PERSON_IDS, fetchAllPersonIdsSaga),
        takeLatest(SET_WISHLISTS_VISIBILITY, setWishListsVisibilitySaga),
        takeLatest(UPDATE_PERSON_WISHLISTS, updatePersonWishlistsSaga),
    ]);
}

// DEPRECATED METHODS

export const getFullWishlistDuck = async criteria => {
    try {
        const response = await getFullWishlist(criteria);
        return {
            ok: {
                payload: response,
            },
        };
    } catch (error) {
        return {
            error: {
                message: getFullWishlistError,
                payload: error,
            },
        };
    }
};

export const updateWishlistDeprecated = async wishlist => {
    try {
        const response = await updateWishlistApi(wishlist);
        return {
            ok: {
                payload: response.data,
            },
        };
    } catch (error) {
        return {
            error: {
                message: updateWishlistError(wishlist.name),
                payload: error,
            },
        };
    }
};
