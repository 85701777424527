import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/Lk/Uikit/Buttons/IconButton';

const Comments = props => {
    return (
        <IconButton
            type='notes'
            className='LKPersonCardComments'
            onClick={props.onClick}
        />
    );
};

Comments.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default Comments;