import React, { useState, useEffect, useMemo } from 'react';
import {
    getWishLists,
    getWishListsWithPersons,
    getWishListsWithPersonsAndProgress,
    getPersonsForWishlist
} from 'api';
import GroupList from './GroupList';
import './GroupList.scss';

const WishList = props => {
    const { personProfileUrl, filters, wishListType, location, withSelect, withCheckboxes } = props;

    const [wishlists, setWishlists] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isGroupLoading, setGroupLoading] = useState(false);

    const canSelect =
        (location.state !== undefined && location.state ? location.state.canSelect : false) ||
        withSelect;

    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            mounted && setLoading(true);
            if (canSelect && withCheckboxes) {
                const result = await getWishListsWithPersons();
                mounted && setWishlists(result.data);
            } else {
                const criteria = { paging: { pageNum: 1, pageSize: 1000 } };
                const result = await getWishLists(criteria);
                mounted && setWishlists(result.data.payload);
            }
            mounted && setLoading(false);
        };

        fetchData();

        return () => {
            mounted = false;
        };
    }, [withCheckboxes, canSelect]);

    const loadPersons = wishListId => async () => {
        setGroupLoading(true);
        let persons = [];
        let readyPersons = [];
        try {
            switch (wishListType) {
                case 'progress':
                    persons = await getWishListsWithPersonsAndProgress(wishListId);
                    readyPersons = await persons.data.persons.map(x => ({
                        ...x,
                        percent: x.progressPercent,
                    }));
                    break;
                default:
                    persons = await getPersonsForWishlist(wishListId);
                    readyPersons = persons.data;
            }
        } finally {
            updateWishLists(wishListId, readyPersons);
            setGroupLoading(false);
        }
    };

    const updateWishLists = (wishListId, persons) => {
        const newWishLists = wishlists.map(x => {
            return {
                ...x,
                ...(x.id === wishListId && { persons }),
            };
        });
        setWishlists(newWishLists);
    };

    const readyWishLists = useMemo(() => {
        let readyWishLists = [];

        if (wishlists && wishlists.length !== 0) {
            for (const x of wishlists) {
                readyWishLists = [
                    ...readyWishLists,
                    {
                        teamId: x.id,
                        isDefault: x.isDefault,
                        teamName: x.name,
                        persons: x.persons || [],
                        entriesCount: x.entriesCount,
                    },
                ];
            }
        }

        if (filters) {
            for (const f of filters) {
                readyWishLists = f.func(readyWishLists, ...f.params);
            }
        }

        return readyWishLists;
    }, [wishlists, filters]);

    return (
        <>
            <GroupList
                {...props}
                items={readyWishLists}
                loading={loading}
                isCount
                loadPersons={loadPersons}
                isGroupLoading={isGroupLoading}
                personProfileUrl={personProfileUrl}
                className="LKDataCard--Line"
                noDataMsg="В этой подборке пока никого нет"
                isWishlist
            />
        </>
    );
};

export default WishList;
