import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';

import { connect } from 'react-redux';
import {
    lkMainServices,
    lkProgressTeam,
    lkSearch,
    lkDetailsEvaluateSelect,
    lkEvaluateSelectResults,
    lkListAdviceTags,
    lkNewIssue,
    lkIssueTracking,
} from 'routes';
import { ACTIVE_MAIN_CARD } from 'components/Lk/Basic/constants';
import CardItemExpert from 'components/Lk/Common/CardItemPerson/CardItemExpert';
import CardItemBlock from 'components/Lk/Common/CardItemBlock';
import CardLink from 'components/Lk/Common/CardLink';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';
import IssuesCard from 'components/Lk/Shared/Issues/IssuesCard';
import RoundedButton from 'components/Lk/Uikit/Buttons/RoundedButton';
import StickyFooter from 'components/Lk/Common/StickyFooter';
import { Link } from 'react-router-dom';

const services = [
    {
        icon: 'bestInGroup',
        name: 'Контроль исполнения',
        description: 'Отслеживание поставленных задач',
        router: lkIssueTracking,
        dataTest: "IssueTracking--button",
        params: { back: lkMainServices.url },
    },
    {
        icon: 'searchIcon',
        name: 'Поиск',
        description: 'Найти резервиста по основным критериям',
        router: lkSearch,
        dataTest: "Search--button",
        params: { back: lkMainServices.url },
    },
    {
        icon: 'evaluateIcon',
        name: 'Оценка',
        dataTest: "SendPersonsToEvaluation--button",
        description: 'Направить резервистов на оценку',
        router: lkDetailsEvaluateSelect,
        params: { back: lkMainServices.url },
    },
    {
        icon: 'evaluateResultIcon',
        name: 'Результаты оценки',
        description: 'Построить рейтинг и сравнить результаты',
        router: lkEvaluateSelectResults,
        dataTest: "EvaluationResults--button",
        params: { back: lkMainServices.url },
    },
    {
        name: 'План развития для группы',
        icon: 'progressTeamIcon',
        description: 'Дать поручение группе на создание ИПР',
        router: lkProgressTeam,
        dataTest: "ProgressPlan--group--button",
        params: { canSelect: true, back: lkMainServices.url },
    },
    {
        name: 'План развития для резервиста',
        icon: 'progressPersonIcon',
        description: 'Дать поручение резервисту на создание ИПР',
        router: lkProgressTeam,
        dataTest: "ProgressPlan--person--button",
        params: { canSelect: false, back: lkMainServices.url },
    },
    {
        name: 'Советы по развитию',
        description: 'Прочитать',
        icon: 'advices',
        router: lkListAdviceTags,
        dataTest: "AdvicesPage--button",
        params: { back: lkMainServices.url },
    },
];

const Services = props => {
    const { setActiveCard } = props;

    setActiveCard(ACTIVE_MAIN_CARD);

    return (
        <>
        <MainCardMenu main/>
            <div className='LKMainCardServices'>
                <CardItemExpert useShortInfo />
                <div className="LKMainCardServices__Item">
                    <IssuesCard />
                </div>
                <div className="LKMainCardServices__Item">
                    <h3 className="LKLayout__Title">Сервисы</h3>

                    {services.map((item, key) => (
                        <CardItemBlock grey key={key} mainServiceBlock dataTest={`${item.dataTest}`}>
                            <CardLink {...item} />
                        </CardItemBlock>
                    ))}
                </div>
            </div>
            <StickyFooter>
                <Link
                    to={{
                        pathname: lkNewIssue.url,
                        state: { back: lkMainServices.url },
                    }}
                    data-test="CreateNewIssue--button"
                >
                    <RoundedButton>Новая задача</RoundedButton>
                </Link>
            </StickyFooter>
        </>
    );
};

Services.propTypes = {
    activeCard: PropTypes.string.isRequired,
    setActiveCard: PropTypes.func.isRequired,
};

const actions = { push };

export default connect(
    null,
    actions,
)(Services);
