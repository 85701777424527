import { appName } from '../constants';
import { takeLatest, put, call, all } from 'redux-saga/effects';
import { getCustomAttributes, deleteCustomAttribute } from '../api';
import RequestError from '../RequestError';
import { showSuccessAlert, showErrorAlert } from './Alert';
import { show as showAcceptModal } from './AcceptModal';
import { customAttributesTypes } from '../enums';
import { withPageLoader } from './PageLoader';

const moduleName = 'attributes';
export const LOAD_REQUEST = `${appName}/${moduleName}/LOAD_REQUEST`;
export const FETCH_REQUEST = `${appName}/${moduleName}/FETCH_REQUEST`;
export const FETCH_START = `${appName}/${moduleName}/FETCH_START`;
export const FETCH_SUCCESS = `${appName}/${moduleName}/FETCH_SUCCESS`;
export const FETCH_FAILED = `${appName}/${moduleName}/FETCH_FAILED`;
export const DELETE_ATTRIBUTE = `${appName}/${moduleName}/DELETE_ATTRIBUTE`;
export const SEND_DELETE_ATTRIBUTE = `${appName}/${moduleName}/SEND_DELETE_ATTRIBUTE`;
export const NEED_UPDATE = `${appName}/${moduleName}/NEED_UPDATE`;
export const CHANGE_CRITERIA = `${appName}/${moduleName}/CHANGE_CRITERIA`;

const initialState = {
    loading: false,
    loadComplete: false,
    loadTime: new Date(0),
    needUpdate: false,
    data: {
        payload: [],
        meta: {
            foundCount: 0,
            pageCount: 0,
        },
    },
    dataGrid: {
        sorted: [
            { id: "name", desc: false }
        ]
    },
    error: '',
};

export default function reducer(state = initialState, action) {
    const { type, payload, error } = action;
    switch (type) {
        case FETCH_START:
            return {
                ...state,
                needUpdate: false,
                loading: true,
            };
        case FETCH_FAILED:
            return {
                ...state,
                loadComplete: true,
                needUpdate: false,
                loadTime: payload.loadTime,
                error: error.message,
            };
        case FETCH_SUCCESS:
            return {
                ...state,
                loadComplete: true,
                needUpdate: false,
                loadTime: payload.loadTime,
                data: payload.data,
            };
        case NEED_UPDATE: {
            return {
                ...state,
                needUpdate: true
            }
        }
        case CHANGE_CRITERIA:
            return {
                ...state,
                dataGrid: {
                    [payload.key]: payload.value,
                },
            };
        default:
            return state;
    }
}

export const fetchAttributes = (criteria) => {
    return {
        type: FETCH_REQUEST,
        payload: criteria
    }
};

export const loadAttributes = (criteria) => {
    return {
        type: LOAD_REQUEST,
        payload: criteria,
        skipLoading: true
    }
};

export const fetchStart = () => {
    return {
        type: FETCH_START,
    }
};

export const fetchSuccess = (data, loadTime) => {
    return {
        type: FETCH_SUCCESS,
        payload: { data, loadTime },
    }
};

export const fetchFailed = (error, loadTime) => {
    return {
        type: FETCH_FAILED,
        payload: { loadTime },
        error,
    }
};

export const needUpdateAttributes = () => {
    return {
        type: NEED_UPDATE
    }
};

export const deleteAttribute = (attribute) => {
    return {
        type: DELETE_ATTRIBUTE,
        payload: {attribute}
    }
};

export const changeCriteria = (key, value) => {
    return {
        type: CHANGE_CRITERIA,
        payload: { key, value },
    };
};

export const showAcceptForDeleteAttribute = function* (action) {
    const { attribute } = action.payload;

    const title = `Удаление дополнительного поля`;
    const question = `Вы действительно хотите удалить дополнительное поле "${attribute.name}"?`;
    const actions = [
        { title: "Отменить", value: null, color: "secondary" },
        { title: "Удалить", value: attribute, color: "primary" },
    ];

    yield put(showAcceptModal(title, question, actions, SEND_DELETE_ATTRIBUTE));
};

export const sendDeleteAttribute = function* (action) {
    const { value: attribute } = action.payload;

    if (!attribute) {
        return;
    }

    try {
        yield call(deleteCustomAttribute, attribute.id);
        yield put(showSuccessAlert(`Дополнительное поле "${attribute.name}" был успешно удалено`));
        yield put(needUpdateAttributes());
    } catch (error) {
        const reqError = new RequestError(error, `При удалении дополнительного поля "${attribute.name}" произошла ошибка`);
        yield put(showErrorAlert(reqError.message));
    }
};

export const fetchAttributesSaga = function* (action) {
    yield put(fetchStart());
    try {
        const response = action.skipLoading
            ? yield call(getCustomAttributes, action.payload)
            : yield call(withPageLoader, () => getCustomAttributes(action.payload));

        const attributesData = {
            payload: response.data.payload.map(x => {
                return { ...x, typeView: customAttributesTypes[x.type.toLowerCase()], payload: JSON.parse(x.payload) }
            }),
            meta: response.data.meta
        }
        yield put(fetchSuccess(attributesData, new Date()));
    } catch (error) {
        const reqError = new RequestError(error, 'При загрузке дополнительного поля произошла ошибка');
        yield all([
            put(fetchFailed(reqError, new Date())),
            put(showErrorAlert(reqError.message))
        ]);
    }
};

export const saga = function* () {
    yield all([
        takeLatest(FETCH_REQUEST, fetchAttributesSaga),
        takeLatest(LOAD_REQUEST, fetchAttributesSaga),
        takeLatest(DELETE_ATTRIBUTE, showAcceptForDeleteAttribute),
        takeLatest(SEND_DELETE_ATTRIBUTE, sendDeleteAttribute),
    ]);
};

export const attributesSelector = (state) => {
    let { attributes } = state;
    if (!attributes.loadComplete) {
        return { loadComplete: false };
    }

    const attributesData = attributes.data;

    return {
        loadComplete: true,
        needUpdate: attributes.needUpdate,
        data: {
            payload: attributesData.payload.map((elem) => ({
                id: elem.id,
                name: elem.name,
                type: elem.type.toLowerCase(),
                typeView: elem.typeView,
                canDelete: elem.canDelete,
            })),
            meta: { ...attributesData.meta }
        },
        currentUser: state.auth.user,
        dataGrid: { ...attributes.dataGrid }
    }
};