import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default (SelectComponent) =>
    class CategorySelect extends PureComponent {
        static propTypes = {
            catalog: PropTypes.bool,
        };

        render() {
            const { catalog, ...props } = this.props;

            return (
                <SelectComponent
                    getOptionValue={catalog ? this.getOptionValue : undefined}
                    getOptionLabel={catalog ? this.getOptionLabel : undefined}
                    {...props}
                />
            );
        }

        getOptionValue = (option) => option.id;

        getOptionLabel = (option) => option.name;
    };
