import { appName } from '../constants';
import { call, all, put, takeEvery } from 'redux-saga/effects';
import { logGoToPage } from 'api';
import RequestError from 'RequestError';
import allRoutes from "../routes";
import { matchPath } from "react-router";

const moduleName = 'logger';
const GO_TO_PAGE = `${appName}/${moduleName}/GO_TO_PAGE`;
const SET_PAGE_INFO = `${appName}/${moduleName}/SET_PAGE_INFO`;

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GO_TO_PAGE: {
            return state;
        }
        case SET_PAGE_INFO: {
            return { ...state, goToPage: action.payload };
        }
        default:
            return state;
    }
}

export const goToPage = (prevLocation, location) => ({ type: GO_TO_PAGE, payload: { prevLocation, location } });
const setPageInfo = payload => ({ type: SET_PAGE_INFO, payload });

function* goToPageSaga(action) {
    try {
        const { prevLocation, location } = action.payload;

        const pathFull = location.pathname + location.search;
        const prevPathFull = prevLocation.pathname + prevLocation.search;

        if (pathFull === prevPathFull) return;
        
        const route = Object.values(allRoutes).find(x => x.url === location.pathname || (matchPath(location.pathname, {
            path: x.url,
            exact: x.exact,
            strict: x.strict
        }) && x.url.includes(':')));
        
        yield call(logGoToPage, { title: route?.title, pathName: pathFull });

        yield put(
            setPageInfo({ title: route?.title, pathName: pathFull }),
        );
    } catch (error) {
        const reqError = new RequestError(error, 'При логировании произошла ошибка');
        console.error(reqError.message);
    }
}

export function* saga() {
    yield all([
        takeEvery(GO_TO_PAGE, goToPageSaga)
    ]);
}
