import React, { Component } from "react";
import Page from "components/common/Page";
import Button from "components/uikit/Button";
import Input from "components/uikit/Input";
import Label from "components/uikit/Label";

import './Wizard.scss';

export default class ResetPasswordStep extends Component {
    state = { password: "", submitting: false };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        const { onResetPassword, onError, onSubmitSuccess } = this.props;
        const { password } = this.state;

        this.setState({ submitting: true });

        try {
            await onResetPassword(password);
            onSubmitSuccess();
        } catch (error) {
            let messages = ["При сбросе пароля произошла ошибка"];
            if (error.response.status === 422) {
                const body = error.response.data.error;
                if (body.user && body.user.message) {
                    messages = [body.user.message];
                } else if (body.identity && body.identity.length > 0) {
                    messages = body.identity.filter((e) => e.message).map((e) => e.message);
                }
            }

            onError(messages);
        }

        this.setState({ submitting: false });
    }

    render = () => {
        const { login } = this.props;
        const { submitting, password } = this.state;

        return (
            <Page>
                <form className="signin-page-form" onSubmit={this.handleSubmit} autoComplete="off">
                    <div className="outer-wizard-wrapper">
                        <div className="wizard-wrapper">
                            <div className="wizard-confirm-form">
                                <div className="wizard-confirm-form__header">
                                    Изменение пароля
                                </div>
                                <div className="wizard-confirm-form__divider">
                                    <hr></hr>
                                </div>
                                <div className="wizard-confirm-form-line">
                                    <div className="wizard-confirm-form-line">
                                        <div className="wizard-confirm-form-line__input">
                                            <Label htmlFor="login" className="sr-only">Логин</Label>
                                            <Input id="login" name="login" className="user-login" value={login} readOnly />
                                        </div>
                                    </div>
                                    <div className="wizard-confirm-form-line">
                                        <div className="wizard-confirm-form-line__input">
                                            <Label htmlFor="password" className="sr-only">Новый пароль</Label>
                                            <Input type="password" name="password" id="password" value={password} onChange={this.handleChange}
                                                className="user-password" placeholder="Новый пароль" required
                                                disabled={submitting}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="btn">
                                <Button
                                    className="btn-block"
                                    type="submit"
                                    loading={submitting}
                                    disabled={submitting}
                                >
                                    Задать пароль
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </Page>
        );
    }
}
