import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'components/Lk/Uikit/Image';
import Icons from 'components/Lk/Uikit/Icons';
import classnames from 'classnames';
import { MENU_PROFILE } from './constants';
import './Header.scss';

const Header = props => {

    const { photoUri, lastName, firstName, middleName, show, isEdit, 
            canLeaderEdit, menuSelect, toggleEdit, goBack, container, editPhoto } = props;

    const scrollTop = () => container?.scrollTo(0, 0);

    return (show &&
        <div className='LKProfileHeader'>
            <div className='LKProfileHeader__Back' onClick={goBack}>
                <FontAwesomeIcon icon='chevron-left' />
            </div>
            <div className="LKProfileHeader__Image" onClick={!isEdit ? scrollTop : editPhoto}>
                <Image photoUri={photoUri} />
            </div>

            <div className="LKProfileHeader__Text" onClick={scrollTop}>
                <span className="LKProfileHeader__Item">{lastName}</span>
                <span className="LKProfileHeader__Item">{firstName} </span>
                <span className="LKProfileHeader__Item">{middleName} </span>
            </div> 
            {canLeaderEdit() && menuSelect === MENU_PROFILE 
                ?
                <div onClick={toggleEdit} className={classnames('LKProfileHeader__Edit', {'LKProfileHeader__Edit--commit': isEdit})} >
                    <Icons name={classnames({'pencil': !isEdit, 'pencil_commit': isEdit})} />
                </div>
                :<div className='LKProfileHeader__Edit--empty'></div>
            }
        </div>
    );
};

export default Header;