import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from 'react';

import { getLanguageAttachmentDownloadLink } from "api";
import ToggleOpen from 'components/hoc/ToggleOpen';
import { DataCard } from "components/common/DataCard";
import EditLanguagesBlock from './Edit/LanguagesBlock';

class LanguagesBlock extends Component {
    renderLanguage = (langInfo) => {
        const { fileAttachmentId, language, languageLevel } = langInfo;

        let link = null;
        if (fileAttachmentId && this.props.personId) {
            link = getLanguageAttachmentDownloadLink(this.props.personId, fileAttachmentId);
        }

        return (
            <div className="kr_tr" key={language.id}>
                <div className="kr_td">Язык</div>
                <div className="kr_td">{language.name}</div>

                <div className="kr_td">Уровень</div>
                <div className="kr_td">{languageLevel && languageLevel.name}</div>

                {link &&
                    <div className="kr_td_tail">
                        <a href={link}><FontAwesomeIcon icon={faFileDownload} /></a>
                    </div>
                }
            </div>
        );
    }

    renderView() {
        return (
            <DataCard
                shadow
                title="Иностранные языки"
                className="kr_profile-anketa-view"
                onChange={this.props.canEditPerson ? () => this.props.setEditBlock('LanguagesBlock') : null}
            >
                <div className="kr_like-table kr_like-table_4cols">
                    {this.props.knownLanguages.map(l => l.language ? this.renderLanguage(l) : null)}
                    {(!this.props.knownLanguages || !this.props.knownLanguages.size) ? this.props.renderEmptyBlock() : null}
                </div>
            </DataCard>
        );
    }

    renderEdit() {
        return (
            <EditLanguagesBlock {...this.props} setEditBlock={this.props.setEditBlock} />
        );
    }

    render() {
        return this.props.isEditBlock ? this.renderEdit() : this.renderView();
    }

}

export default (ToggleOpen(LanguagesBlock));