import { appName } from '../constants';
import { takeLatest, call, all, put } from 'redux-saga/effects';
import { getPersonComments, setPersonsComments, deleteComment } from 'api';
import { showErrorAlert, showSuccessAlert } from './Alert';
import RequestError from 'RequestError';
import { addComment as addPersonComment, remove as removePersonCompments } from './Person';
import { changePersonsData } from './ExternalSearch';

const moduleName = 'person-comments';

const FETCH_COMMENTS_START = `${appName}/${moduleName}/FETCH_COMMENTS_START`;
const FETCH_COMMENTS_END = `${appName}/${moduleName}/FETCH_COMMENTS_END`;
const SAVE_COMMENTS = `${appName}/${moduleName}/SAVE_COMMENTS`;
const ADD_COMMENT = `${appName}/${moduleName}/ADD_COMMENT`;
const REMOVE_COMMENT_START = `${appName}/${moduleName}/REMOVE_COMMENT_START`;
const REMOVE_COMMENT = `${appName}/${moduleName}/REMOVE_COMMENT`;
const SET_PAGING = `${appName}/${moduleName}/SET_PAGING`;

const initialState = {
    data: [],
    meta: {},
    personId: null,
    hasMore: false,
    isLoaded: false,
    loading: false,
    criteria: { pageNum: 1, pageSize: 10 },
    isEdit: false,
    saving: false,
};

export default function reducer(state = initialState, { payload, type }) {
    switch (type) {
        case FETCH_COMMENTS_START:
            return {
                ...state,
                data: payload.isMore ? state.data : [],
                hasMore: false,
                criteria: { ...state.criteria, ...payload.criteria },
                personId: payload.personId,
                isLoaded: payload.isMore ? true : false,
                loading: true,
            };
        case FETCH_COMMENTS_END:
            return {
                ...state,
                data: (payload.isMore ? state.data : [])?.concat(payload.comments.payload),
                meta: { ...payload.comments.meta },
                hasMore: payload.comments.meta.pageCount > state.criteria.pageNum ? true : false,
                isLoaded: true,
                loading: false,
            };
        case REMOVE_COMMENT_START:
        case SAVE_COMMENTS:
            return { ...state, loading: true };
        case ADD_COMMENT:
            const item = payload.find((x) => x.personId === state.personId);
            return {
                ...state,
                loading: false,
                data: item ? [item].concat(state.data.filter((x) => x.id !== item.id)) : state.data,
            };
        case REMOVE_COMMENT:
            return {
                ...state,
                loading: false,
                data: state.data.filter((x) => x.id !== payload),
            };
        case SET_PAGING:
            return {
                ...state,
                criteria: {...state.criteria, ...payload},
            };
        default:
            return state;
    }
}

export const fetchComments = (personId, criteria, isMore = false) => ({
    type: FETCH_COMMENTS_START,
    payload: { personId, criteria, isMore },
});

export const saveComments = (ids, forceUpdate, comment) => ({
    type: SAVE_COMMENTS,
    payload: { ids, comment, forceUpdate },
});

export const removeComment = (id) => ({
    type: REMOVE_COMMENT_START,
    payload: id,
});

export const remove = (id) => ({
    type: REMOVE_COMMENT,
    payload: id,
});

export const addComment = (payload) => ({
    type: ADD_COMMENT,
    payload,
});

const setComments = (comments, isMore = false) => ({
    type: FETCH_COMMENTS_END,
    payload: { comments, isMore },
});

export const setEditState = (isEdit) => ({
    type: FETCH_COMMENTS_END,
    payload: isEdit,
});

export const setPaging = (paging) => ({
    type: SET_PAGING,
    payload: paging,
});

function* fetchCommentsSaga({ payload = {} }) {
    try {
        const { criteria, personId, isMore } = payload;
        if (!personId) {
            return;
        }
        const response = yield call(getPersonComments, personId, criteria);
        yield put(setComments(response.data, isMore));
    } catch (error) {
        if (error && error.response && error.response.status === 404) {
            return;
        }

        const reqError = new RequestError(error, 'При загрузке комментариев произошла ошибка');
        yield put(showErrorAlert(reqError.message));
    }
}

function* saveCommentsSaga({ payload }) {
    try {
        const { ids, comment, forceUpdate } = payload;

        const response = yield call(
            setPersonsComments,
            ids,
            forceUpdate,
            comment.text,
            comment.visibleToAll,
        );
        yield put(addComment(response.data));

        yield put(addPersonComment(response.data));

        yield put(changePersonsData(ids.map((id) => ({ personId: id, hasComment: true }))));

        yield put(showSuccessAlert('Заметка успешно сохранена'));
    } catch (error) {
        const reqError = new RequestError(error, 'При сохранении комментариев произошла ошибка');
        yield put(showErrorAlert(reqError.message));
    }
}

function* removeCommentSaga({ payload }) {
    try {
        const response = yield call(deleteComment, payload);

        yield put(remove(payload));
        yield put(removePersonCompments(payload));
        yield put(changePersonsData([response.data]));

        yield put(showSuccessAlert('Заметка успешно удалена'));
    } catch (error) {
        const reqError = new RequestError(error, 'При удалении комментария произошла ошибка');
        yield put(showErrorAlert(reqError.message));
    }
}

export function* saga() {
    yield all([
        takeLatest(FETCH_COMMENTS_START, fetchCommentsSaga),
        takeLatest(SAVE_COMMENTS, saveCommentsSaga),
        takeLatest(REMOVE_COMMENT_START, removeCommentSaga),
    ]);
}
