import { appName } from '../constants';
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { getPersonCardConfig } from 'api';
import RequestError from '../RequestError';
import { showErrorAlert } from './Alert';

const moduleName = 'personCardConfig';
export const FETCH_REQUEST = `${appName}/${moduleName}/FETCH_REQUEST`;
export const FETCH_START = `${appName}/${moduleName}/FETCH_START`;
export const FETCH_SUCCESS = `${appName}/${moduleName}/FETCH_SUCCESS`;
export const FETCH_FAILED = `${appName}/${moduleName}/FETCH_FAILED`;

const initialState = {
    loading: false,
    loadComplete: false,
    loadTime: new Date(0),
    data: {},
    id: null,
    error: '',
};

export default function reducer(state = initialState, action) {
    const { type, payload, error } = action;
    switch (type) {
        case FETCH_START:
            return {
                ...initialState,
                loading: true,
                id: payload.id,
            };
        case FETCH_FAILED:
            return {
                ...initialState,
                loadComplete: true,
                loading: false,
                id: payload.id,
                error: error.message,
            };
        case FETCH_SUCCESS:
            return {
                ...initialState,
                loadComplete: true,
                loadTime: payload.loadTime,
                id: payload.data.id,
                data: payload.data,
            };
        default:
            return state;
    }
}

export const fetchPersonCardConfig = id => {
    return {
        type: FETCH_REQUEST,
        payload: { id },
    }
};

export const fetchStart = (id = 0) => {
    return {
        type: FETCH_START,
        payload: { id },
    }
};

export const fetchSuccess = (data, loadTime) => {
    return {
        type: FETCH_SUCCESS,
        payload: { data, loadTime },
    }
};

export const fetchFailed = (id, error, loadTime) => {
    return {
        type: FETCH_FAILED,
        payload: { id, loadTime },
        error,
    }
};

export const fetchPersonCardConfigSaga = function* (action) {
    const { id } = action.payload;
    yield put(fetchStart(id));
    try {
        const response = yield call(getPersonCardConfig, id);
        const personCardConfig = response.data;
        yield put(fetchSuccess(personCardConfig, new Date()));
    } catch (error) {
        const reqError = new RequestError(error, 'Ошибка загрузки конфигурации карточки резервиста');
        yield all([
            put(fetchFailed(reqError, new Date())),
            put(showErrorAlert(reqError.message))
        ]);
    }
};

export const saga = function* () {
    yield takeLatest(FETCH_REQUEST, fetchPersonCardConfigSaga);
};