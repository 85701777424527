import React from "react";
import classNames from "classnames";
import "./Page.scss";

function Page({ children, className, id, w1790, theme = "default", ...tale }) {
    className = classNames(className, "Page", `Page_theme_${theme}`, "kr_container", { w1790: w1790 });

    return (
        <div id={id}>
            <div className={className} {...tale}>
                {children}
            </div>
        </div>
    );
}

export default Page;
