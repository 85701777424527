import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CardItemBlock = (props) => {
    const { grey, green, done, shadow, selected, children, fitted, mainServiceBlock, draggable, isDragging, dataTest } = props;

    const blockClassName = classnames(
        "CardItemBlock",
        { "CardItemBlock--Gray": grey },
        { "CardItemBlock--Green": green || done },
        { "CardItemBlock--Shadow": shadow },
        { "CardItemBlock--Selected": selected },
        { "CardItemBlock--Fitted": fitted },
        { "CardItemBlock--main-service": mainServiceBlock},
        { "CardItemBlock--draggable": draggable},
        { "CardItemBlock--isDragging": isDragging},
    );

    return (
        <div className={blockClassName} data-test={dataTest}>
            {children}
        </div>
    );
};

CardItemBlock.propTypes = {
    grey: PropTypes.bool,
    green: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,        
      ]).isRequired,
};

export default CardItemBlock;