import React from 'react';
import BlockButton from '../BlockButton';
import './DataList.scss';
import Field from 'components/uikit/Field';

const renderRemoveButton = (handleRemoveEntry) => {
    return (
        <div className="FieldItem FieldItem__Width-full">
            <BlockButton type="remove" onClick={handleRemoveEntry}>
                Удалить
            </BlockButton>
        </div>
    );
};

const renderListEntry = (props) => (entry) => {
    const { customRemove, renderEntry, canRemove, onRemove, keySelector } = props;

    const canRemoveEntry = canRemove(entry);
    const handleRemoveEntry = () => onRemove(entry);

    return (
        <React.Fragment key={keySelector(entry)}>
            {renderEntry(entry, canRemoveEntry, handleRemoveEntry)}
            <Field size="100">
                {!customRemove && canRemoveEntry && renderRemoveButton(handleRemoveEntry)}
            </Field>
        </React.Fragment>
    );
};

const DataList = (props) => {
    const { onAdd, entries, isDisabled = false } = props;

    return (
        <>
            {entries.map(renderListEntry(props))}
            {!isDisabled && (
                <Field size="100">
                    <BlockButton type="add" onClick={onAdd} topDelimeter>
                        Добавить
                    </BlockButton>
                </Field>
            )}
        </>
    );
};

export default DataList;
