import React from 'react';
import { connect } from 'react-redux';
import InfoField from 'components/Lk/Uikit/InfoField';
import { Select } from 'components/Lk/Uikit/Select/Select';
import ProfileButton from 'components/Lk/Uikit/Buttons/Button/ProfileButton';
import classnames from 'classnames';
import NoData from './NoData';

const SocialNetworksInfo = props => {
    const {
        person,
        person: { socialNetworksInfo },
        socialNetworks,
        isEdit,
        onChange,
    } = props;

    const { networks } = socialNetworksInfo;

    const onAdd = () => {
        const id = -new Date().getTime();
        const newRecord = { id };
        onChange(
            { ...person, socialNetworksInfo: { networks: [...networks, newRecord] } },
            'SocialNetworksBlock',
        );
    };

    const onRemove = id => e => {
        e.stopPropagation();
        onChange(
            { ...person, socialNetworksInfo: { networks: networks.filter(a => a.id !== id) } },
            'SocialNetworksBlock',
        );
    };

    const onChangeState = (name, id) => value => {
        const newRecords = networks.map(item =>
            item.id === id ? { ...item, [name]: value } : item,
        );
        onChange(
            { ...person, socialNetworksInfo: { networks: newRecords } },
            'SocialNetworksBlock',
        );
    };

    return (
        <div className={classnames("PersonalInfo", {'PersonalInfo--bottom-margin': isEdit})}>
            {(networks.size && networks.size !== 0) || (networks.length && networks.length !== 0)
                ? networks.map(item => (
                    <React.Fragment key={item.id}>
                        {isEdit ? (
                            <>
                                <InfoField
                                    icon={
                                        isEdit && {
                                            icon: 'minus-circle',
                                            size: 'xs',
                                            color: 'red',
                                            onClick: onRemove(item.id),
                                        }
                                    }
                                    value={item.value}
                                    title="Соц. сеть"
                                    isEdit={isEdit}
                                    editComponent={
                                        <Select
                                            items={socialNetworks}
                                            value={item.network && item.network.id}
                                            onChange={onChangeState('network', item.id)}
                                        />
                                    }
                                    noIcon={!isEdit}
                                    noBorder
                                    wrapperBorder
                                    loweredIcon
                                />
                                <InfoField
                                    value={item.value}
                                    title="Значение"
                                    isEdit={isEdit}
                                    onChange={onChangeState('value', item.id)}
                                    maxLength={450}
                                    noIcon
                                    shrink
                                    wrapperBorder
                                />
                            </>
                        ) : (
                            <InfoField value={item.value} title={item.network?.name} noIcon/>
                        )}
                    </React.Fragment>
                    )
                )
                : (!isEdit && <NoData text='Нет данных' />)}
            {isEdit && (
                <InfoField noIcon shrink wrapperBorder>
                    <ProfileButton onClick={onAdd}>добавить</ProfileButton>
                </InfoField>
            )}
        </div>
    );
};

const mapStateToProps = state => ({
    socialNetworks: state.catalogs.socialNetworks.data,
});

export default connect(mapStateToProps)(SocialNetworksInfo);
