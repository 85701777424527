import React from 'react';
import PropTypes from 'prop-types';
import ModalDialog from 'components/common/ModalDialog';
import { showErrorAlert } from 'ducks/Alert';
import { connect } from 'react-redux';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import InputText from 'components/uikit/InputText';

export class RejectModal extends React.Component {
    state = { rejectionComment: '' };

    handleTextChange = e => this.setState({ rejectionComment: e.target.value });
    componentDidUpdate({ isOpen }) {
        if (this.props.isOpen && isOpen !== this.props.isOpen) {
            this.setState({ rejectionComment: '' });
        }
    }

    reviewRequest = () => {
        const { onClose, accessRequestId, onReview } = this.props;
        const { rejectionComment } = this.state;

        if (!rejectionComment) {
            this.props.showErrorAlert("Необходимо заполнить причину отказа")
            return;
        }

        onReview({ accessRequestId, decision: 'Reject', rejectionComment });
        onClose();
    };

    render() {
        const { isOpen, onClose } = this.props;
        const { rejectionComment } = this.state;
        return (
            <ModalDialog
                onClick={this.reviewRequest}
                onCloseModal={onClose}
                modalOpen={isOpen}
                modalHeader="Вы уверены, что хотите отклонить?"
                btnOktext="Отклонить"
                btnOkColor="danger"
                isDangerHeader
                btnCanceltext="Отмена"
            >
                <Field invalid={!rejectionComment}>
                    <Label>Причина</Label>
                    <InputText
                        value={rejectionComment}
                        maxLength={80}
                        onChange={this.handleTextChange}
                    ></InputText>
                </Field>
            </ModalDialog>
        );
    }
}

RejectModal.propTypes = {
    isOpen: PropTypes.bool,
    accessRequestId: PropTypes.string,
    onClose: PropTypes.func,
    onReview: PropTypes.func,
};

const actions = { 
    showErrorAlert 
};

export default connect(null, actions)(RejectModal);
