import React from 'react';
import PropTypes from 'prop-types';
import Label from 'components/uikit/Label';
import classnames from 'classnames';
import './EvaluationPerson.scss';
import Avatar from 'components/Lk/Common/Avatar';

const EvaluationPerson = props => {
    const { children, checkbox, onChange, onClick, basket, item, isUser, dataTest } = props;
    const Content = children ? (
        children
    ) : (
        <span className="EvaluationPerson__Content-NoData" data-test="EvaluationPerson-NoData">Нет данных</span>
    );

    const ImgClassName = classnames('EvaluationPerson__Img', {
        'EvaluationPerson__Img--isCheckbox': checkbox,
    });

    const getId = ({ id,userId }) => isUser ? userId : id;

    const ifSelected = () => {

        return !!basket?.persons?.find(x => x.person && getId(x.person) === getId(item));
    };

    return (
        <div className="EvaluationPerson" onClick={onClick && onClick(item.id, item)}>
            <div className={ImgClassName}>
                {checkbox && (
                    <>
                        <input
                            id={`Name__Id${item.id}`}
                            type="checkbox"
                            onChange={onChange(item.id, item)}
                            hidden
                            checked={ifSelected()}
                        />
                        <Label
                            htmlFor={`Name__Id${item.id}`}
                            className="EvaluationPerson__Img-Label"
                            data-test={`${dataTest}_CheckPerson--checkbox id_${item.id}`}
                        ></Label>
                    </>
                )}
                <Avatar person={item} isUser={isUser} size="sm" />
            </div>
            <div className="EvaluationPerson__Content">{Content}</div>
        </div>
    );
};

EvaluationPerson.propTypes = {
    checkbox: PropTypes.bool,
    children: PropTypes.any,
    onClick: PropTypes.func,
};

export default EvaluationPerson;
