import React, { Component } from 'react';
import Page from "components/common/Page";
import LeftMenu from "../common/LeftMenu";
import "./Reports.scss";
import ReportParams from './ReportParams';
import Report from './Report';
import MyReports from './MyReports';
import { DragDropContext } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import {
    uploadReportTemplate,
    getReportCreatorTemplates,
    deleteReportTemplate as deleteReportTemplateApi,
    getCreatorReportTemplates,
    getAllowedGroups as getGroupsListApi
} from 'api';
import { reportTemplateTypes } from 'enums';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';

const initialState = {
    loadComplete: false,
    reportId: "",
    isDefault: false,
    reportType: "myReports",
    title: "",
    reportSections: null,
    reportSettings: {
        title: "",
        font: "",
        format: ""
    },
    myTemplates: {},
}

class Reports extends Component {

    state = initialState;

    componentDidMount() {
        this.handleReportLoad(this.state.reportType);
    }

    onMenuItemChange = (item) => {
        this.setState(state => {
            return {
                ...state,
                reportType: item.id
            }
        }, this.handleReportLoad(item.id))
    }

    onReportSave = async (finalJSON) => {
        await this.callEffect(() => uploadReportTemplate(finalJSON));
        this.setState(state => {
            return {
                ...state,
                reportType: "myReports"
            }
        }, this.handleReportLoad("myReports"))
    }

    handleStateChange = (statkeKey, value, options = {}) => {
        const { reloadReport = false, reportType = "", id = "" } = options;
        this.setState(state => {
            const reportSettings = {
                ...state.reportSettings,
                [statkeKey]: value,
            }
            return {
                ...state,
                reportSettings: reportSettings,
            }
        }, () => (reloadReport && this.handleReportLoad(reportType, id)) || null);
    }

    handleReportLoad = (reportType, id = "") => {
        this.setState(state => {
            return {
                ...state,
                loadComplete: false,
            }
        })
        let report = {};
        let isNewReport = false;
        if (id) {
            // TODO
            // Пока ищем шаблон отчета по ID из списка всех шаблонов
            report = id && (this.getReports(this.state.myTemplates).find(x => x.id === id));
            report = report && { ...JSON.parse(report.config), isDefault: report.isDefault, reportType: report.base };
            this.handleReportState(report, reportType, id, isNewReport)
        } else {
            isNewReport = true;
            this.callEffect(() => this.getReportTemplate(reportType).then(report => {
                if (reportType !== "myReports") {
                    report = report && report.config && JSON.parse(report.config);
                }
                this.handleReportState(report, reportType, id, isNewReport)
                if (reportType === "myReports") {
                    this.getGroupsData();
                }
            }))
        }
    }

    handleReportState = (report, reportType, id, isNewReport) => {
        if (report && reportType && reportType !== 'myReports') {
            const { title, font, format, isDefault } = report;
            const reportSections = report.schema;
            const reportSectionsKeys = Object.keys(reportSections);
            let reportSettings = this.updateSectionSetting(reportSections, report.settings, reportSectionsKeys, id);

            if (isNewReport) {
                reportSettings = {
                    ...reportSettings,
                    title: title,
                    font: font,
                    format: format,
                }
            }

            this.setState({
                loadComplete: true,
                isDefault: isDefault,
                reportId: id,
                reportType: reportType,
                title: title,
                font: font,
                format: format,
                reportSections: reportSections,
                reportSettings: reportSettings,
            });
        } else {
            this.setState({
                ...initialState,
                reportType: reportType,
                loadComplete: true,
            });
        }
    }

    updateSectionSetting = (reportSections, reportSettings, reportSectionsKeys, reportId = "") => {
        if (reportId) { return reportSettings }
        for (const key of reportSectionsKeys) {
            reportSettings[key].title = reportSections[key].name;
        }

        return reportSettings;
    }

    getMenuItems = () => {
        return [
            { id: "profile", title: reportTemplateTypes["profile"], svgIcon: "Profile", },
            { id: "biography", title: reportTemplateTypes["biography"], svgIcon: "Group" },
            { id: "myReports", title: "Мои отчеты", svgIcon: "Folder" }
        ]
    }

    getReportTemplate = async (reportType) => {
        if (reportType === 'myReports') {
            return { ...this.loadMyReportTemplates() };
        } else {
            const allTemplates = await getCreatorReportTemplates();
            return allTemplates.data.filter(x => x.id === reportType)[0];
        }
    }


    handlSectionNameChange = (sectionKey, sectionField, value) => {
        this.setState(state => {
            const reportSettings = {
                ...state.reportSettings,
                [sectionKey]: { ...state.reportSettings[sectionKey], [sectionField]: value }
            }

            return {
                ...state,
                reportSettings: { ...reportSettings },
            }
        })
    }

    handleSwitchChange = (key, value) => {
        this.setState(state => {

            const reportSettings = {
                ...state.reportSettings,
                [key]: { ...state.reportSettings[key], enabled: value },
            }

            return {
                ...state,
                reportSettings: reportSettings,
            }
        });
    }

    handleSelectionChange = (key, fieldCode, action) => {
        this.setState(state => {
            let newFields = [];
            if (action === "add") {
                if (state.reportSettings[key].selectedFields) {
                    newFields = [
                        ...state.reportSettings[key].selectedFields,
                        {
                            code: fieldCode,
                            ...this.getAllOptions(key, fieldCode, true)
                        }]
                } else {
                    newFields = [{ code: fieldCode, ...this.getAllOptions(key, fieldCode, true) }]
                }

            } else {
                newFields = state.reportSettings[key].selectedFields.filter(x => x.code !== fieldCode)
            }

            const reportSettings = {
                ...state.reportSettings,
                [key]: { ...state.reportSettings[key], selectedFields: newFields },
            }

            return {
                ...state,
                reportSettings: { ...reportSettings },
            }
        })
    }

    getAllOptions = (sectionKey, fieldCode, addNewMultiField = false) => {
        const field = this.state.reportSections[sectionKey].fields.filter(x => x.code === fieldCode)[0];
        if (field.type === "simple") { return null }
        if (addNewMultiField) {
            return { selectedOptions: field.options.map(x => x.code) };
        } else {
            return field.options;
        }
    }

    getSelectedOptions = (sectionKey, fieldCode) => {
        const selectedFields = this.state.reportSettings[sectionKey].selectedFields;
        return selectedFields.filter(x => x.code === fieldCode)[0].selectedOptions;
    }

    handleOptionChange = (sectionKey, fieldCode, options) => {
        this.setState(state => {
            const selectedFields = [...state.reportSettings[sectionKey].selectedFields];
            const optionIndex = selectedFields.indexOf(selectedFields.find(x => x.code === fieldCode));
            selectedFields.splice(optionIndex, 1);
            selectedFields.splice(optionIndex, 0, { code: fieldCode, selectedOptions: options?.map(x => x.id) || [] });

            const reportSettings = {
                ...state.reportSettings,
                [sectionKey]: { ...state.reportSettings[sectionKey], selectedFields: selectedFields },
            }
            return {
                ...state,
                reportSettings: { ...reportSettings },
            }
        })
    }

    onDragEnd = result => {
        const { source, destination } = result;

        if (!source || !destination || source.droppableId !== destination.droppableId) { return }
        if (source.index === destination.index) { return }

        const selectedFields = this.state.reportSettings[destination.droppableId].selectedFields;
        const movedField = selectedFields[source.index];

        selectedFields.splice(source.index, 1);
        selectedFields.splice(destination.index, 0, movedField);

        this.setState(state => {
            const reportSettings = {
                ...state.reportSettings,
                [destination.droppableId]: { ...state.reportSettings[destination.droppableId], selectedFields: selectedFields },
            }

            return {
                ...state,
                reportSettings: { ...reportSettings },
            }
        })

    }

    loadMyReportTemplates = async () => {
        const myReportTemplates = await this.callEffect(() => getReportCreatorTemplates());
        this.setState(state => {
            return {
                ...state,
                myTemplates: myReportTemplates.data,
            }
        })
    }


    getGroupsData = async () => {
        const user = this.props.currentUser;
        const result = await getGroupsListApi(user.groupId);

        this.setState(state => {
            return {
                ...state,
                groups: result.data
            }
        })
    };

    getReports = (reports = []) => {
        return Object.keys(reports).flatMap(key => {
            return reports[key].map(report => {
                let groupResult;
                if (this.state.groups) {
                    const groups = this.state.groups.reduce((map, obj) => {
                        map[obj.id] = { title: obj.title };
                        return map;
                    }, {})
                    groupResult = groups[report.groupId];
                }

                const groupTitle = groupResult ? groupResult.title : ""

                return {
                    ...report,
                    baseRus: reportTemplateTypes[report.base],
                    isDefault: report.isDefault,
                    group: groupTitle,
                    isDefaultIcon: this.renderDefaultIcon(report.isDefault)
                }
            });
        })
    }


    renderDefaultIcon = (isDefault) => {
        return (
            isDefault && <div className="kr_item_check"></div>
        )
    }

    deleteReportTemplate = async (reportTemplateId) => {
        await this.callEffect(async () =>  {
            await deleteReportTemplateApi(reportTemplateId);
            this.props.showSuccessAlert("Шаблон отчета успешно удален");
            this.loadMyReportTemplates();
        });
    }

    toggleDefaultReport = (isDefault) => {
        this.setState(state => {
            return {
                ...state,
                isDefault: isDefault,
            }
        })
    }

    render() {
        if (!this.state.loadComplete) { return null }
        return (
            <Page id="ReportsPage" w1790>
                <div className="Page__MenuColumn">
                    <LeftMenu
                        items={this.getMenuItems()}
                        selected={this.state.reportType}
                        onChangePage={this.onMenuItemChange}
                    />
                </div>
                <div className="Page__ContentColumn">
                    {this.state.reportType === "myReports" ? this.renderMyReports() : this.renderReports()}
                </div>
            </Page>
        );
    }

    renderReports = () => {
        return (
            <>
                <ReportParams
                    title={"Параметры отчета"}
                    report={this.state}
                    handleStateChange={this.handleStateChange}
                    handleSelectionChange={this.handleSelectionChange}
                    handleSwitchChange={this.handleSwitchChange}
                />
                <DragDropContext onDragEnd={this.onDragEnd} >
                    <Report
                        report={this.state}
                        handleStateChange={this.handleStateChange}
                        handleSelectionChange={this.handleSelectionChange}
                        handleOptionChange={this.handleOptionChange}
                        getAllOptions={this.getAllOptions}
                        getSelectedOptions={this.getSelectedOptions}
                        handlSectionNameChange={this.handlSectionNameChange}
                        toggleDefaultReport={this.toggleDefaultReport}
                        saveReport={this.onReportSave}
                    />
                </DragDropContext>
            </>
        );
    }

    renderMyReports = () => {
        return (
            <MyReports
                myTemplates={this.state.myTemplates}
                handleStateChange={this.handleStateChange}
                reports={this.getReports(this.state.myTemplates)}
                deleteReportTemplate={this.deleteReportTemplate}
            />
        );
    }

    callEffect = async (callback) => {
        this.props.showPageLoader();
        try {
            return await callback();
        } catch (error) {
            this.props.showErrorAlert(error.message);
        }
        finally {
            this.props.hidePageLoader();
        }
    };
}

const props = state => {
    return {
        currentUser: state.auth.user
    }
}

const actions = {
    showPageLoader,
    hidePageLoader,
    showErrorAlert,
    showSuccessAlert,
};

export default connect(props, actions)(Reports);
