import React from 'react';
import classnames from 'classnames';

const Badge = props => {
    const { color } = props;
    return (
        <div className={classnames('LKBadge', {[`LKBadge--${color}`]: color})}>
            {props.children}
        </div>
    );
};

export default Badge;