import React, { PureComponent } from 'react';
import ToggleOpen from 'components/hoc/ToggleOpen';
import { DataCard } from "components/common/DataCard";
import SystemInfoBlockEditor from './Edit/SystemInfoBlock';
import moment from "moment";
import { USER_ROLES, ACCESS_STATUS } from 'constants.js';

class SystemInfoBlock extends PureComponent {
    render() {
        return this.props.isEditBlock ? this.renderEdit() : this.renderView();
    }
    
    renderAccessInfo = () => {
        const { access, accessEnabled } = this.props;

        const { lastAccessRequest } = access.data;

        const isRejected =
            [ACCESS_STATUS.REJECTED_BY_IT, ACCESS_STATUS.REJECTED_BY_SECURITY].indexOf(
                lastAccessRequest && lastAccessRequest.status,
            ) !== -1;

        const role = lastAccessRequest && lastAccessRequest.finishedOn && lastAccessRequest.role
            && USER_ROLES.find(x => x.code === lastAccessRequest.role);

        const { user } = this.props;
        
        const rolesWithDates = user.roles && user.roles
            .map(r => ({title: r.title, date: r.created}))
            .map(r => ` ${r.title} c ${moment(r.date).format('DD MMMM Y')}`).join();
        
        if (!!role) {
            return (<>
                {!isRejected && accessEnabled && <>
                    <div className="kr_tr">
                        <div className="kr_td">Роль</div>
                        <div className="kr_td">{rolesWithDates}</div>
                    </div>
                </>}
            </>);
        }

        return (<>
            {user && <>
                <div className="kr_tr">
                    <div className="kr_td">Роль</div>
                    <div className="kr_td">{rolesWithDates}</div>
                </div>
            </>}
        </>);
    };
    
    renderCuratorName = () => {
        return (<>
            {this.props.selectedCuratorName && <div className="kr_tr">
                <div className="kr_td">Эксперт по управлению талантами</div>
                <div className="kr_td">{this.props.selectedCuratorName}</div>
            </div>}
        </>);
    }
    
    renderView = () => {
        const groups = this.getSelectedGroups();
        const isExistGroups = groups && groups.length > 0;
        
        return (
            <DataCard
                shadow
                title="Системная информация"
                className="kr_profile-anketa-view"
                onChange={ this.props.canEditPerson ? this.handleChangeBlock : null }
            >
                <div className="kr_like-table">
                    <div className="kr_tr">
                        <div className="kr_td">Группы</div>
                        <div className="kr_td">{isExistGroups && groups.map(x => x.isScope ? `${x.name} (Транзитная)` : x.name).join(', ')}</div>
                    </div>
                    {this.props.isPersonAccessExist && this.renderAccessInfo()}
                    {this.renderCuratorName()}
                </div>
            </DataCard>
        );
    }

    renderEdit = () => 
        <SystemInfoBlockEditor {...this.props} setEditBlock={this.props.setEditBlock} />;
    
    handleChangeBlock = () => 
        this.props.setEditBlock('SystemInfoBlock');
    
    getSelectedGroups = () => {
        const { groups, selectedGroup, selectedPersonGroupIds } = this.props;
        
        return selectedPersonGroupIds ? groups.filter(g => g.id === selectedGroup || (g.isScope && selectedPersonGroupIds.includes(g.id))) : [];
    }
}

export default ToggleOpen(SystemInfoBlock);
