import React, {useState, useEffect} from 'react';
import moment from 'moment';
import uuid from "uuid/v4";

import { showErrorAlert, showWarningAlert } from 'ducks/Alert';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';

import PersonCard from "components/common/PersonCard";
import ChevronButton from 'components/uikit/ChevronButton';
import Button from "components/uikit/Button";
import RoundButton from 'components/uikit/Button/RoundButton';

import { getEducationEventsForPerson, getCompetencies } from 'api';
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { educationEventRoute } from 'routes';

import "./EducationEvents.scss";

const eventStatusTypes = [
    {id: 1, name: "В процессе", type: "InProcess"},
    {id: 2, name: "Завершено", type: "Completed"},
];

const EducationEvents = (props) => {
    
    const [events, setEvents] = useState([]);
    const [competencyInfo, setCompetencyInfo] = useState({});

    const personId = props.person && props.person.personalInfo && props.person.personalInfo.id;

    useEffect( () => {
        let mounted = true;

        if (!personId) {
            return () => { mounted = false };
        }

        const fetchData = async () => {
            try {
                showPageLoader();
                
            const competencyData = await getCompetencies(personId);
            mounted && competencyData && competencyData.data && setCompetencyInfo(competencyData.data);

            const educationEventsData = await getEducationEventsForPerson(personId);
            if (educationEventsData && educationEventsData.status === 200) {
                const eventsWithToggleData = educationEventsData.data.map(x => ({...x, isOpen: false }));
                setEvents(eventsWithToggleData);
            }
            } catch(error) {
                showErrorAlert(error.message);
            } finally {
                hidePageLoader();
            }
        };
        fetchData();

        return () => { mounted = false };
    }, [personId]);
   
    const renderEducationEventItem = (event) => {
        
        const editEducationEvent = () => {
            props.push(educationEventRoute.buildUrl({id: personId, eventId: event.id, mode: "edit" }))
        };

        const competencies = competencyInfo[0].competencyModel.competencies;
        
        const renderCompetency = (item) => {
            return <div key={item.id}>{item.name}</div>
        };
        
        const renderTitle = (event) => {
            return  (
                <>
                    <div className="Goal-Part__Header">
                        <div>{event.resource && event.resource.name}</div>
                        <div>
                            <RoundButton type="edit" onClick={() => editEducationEvent(event)} />
                        </div>
                    </div>
                </>
            )
        };

        const renderCompetencies = (event) => {
            return !!event && !!event.competencyIds && !!event.competencyIds.length &&
                <>
                    <div className="Goal-Part__Header">
                        Компетенции
                    </div>
                    <div className="Goal-Part__Line">
                        {competencies.filter(x => event.competencyIds.includes(x.id)).map(renderCompetency)}
                    </div>
                </>
        };
        
        const renderTarget = (event) => {
           return event.target &&
               <>
                   <div className="Goal-Part__Header">
                       Цель обучения
                   </div>
                   <div className="Goal-Part__Line">
                       {event.target}
                   </div>
               </>
        };
        
        const renderExpectedResult = (event) => {
            return event.expectedResult &&
                <>    
                    <div className="Goal-Part__Header">
                        Желаемый конечный результат
                    </div>
                    <div className="Goal-Part__Line">
                        {event.expectedResult}
                    </div>
                </>
        };
        
        const renderEducationDates = (event) => {
            return !!event.educationStart && !!event.educationEnd &&
                <>
                    <div className="Goal-Part__Header">
                        Период обучения
                    </div>
                    <div className="Goal-Part__Line">
                        {moment(event.educationStart).format('L') + ' - ' +
                        moment(event.educationEnd).format('L')}
                    </div>
                </>    
        };
        
        const renderStatus = (event) => {
            return !!event.status && 
            <>
                <div className="Goal-Part__Header">
                    Статус
                </div>
                <div className="Goal-Part__Line">
                    {eventStatusTypes.find(x => x.type === event.status).name}
                </div>
            </>    
        };
        
        const renderCreatedOn = (event) => {
            return !!event.createdOn && 
                <>
                    <div className="Goal-Part__Header">
                        Дата создания
                    </div>
                    <div className="Goal-Part__Line">
                        {event.createdOn && moment(event.createdOn).format("DD MMMM YYYY")}
                    </div>
                </>
        };

        return (
            <div className="Goal">
                <div className="Goal-Part">
                    { renderTitle(event) }
                    { renderCompetencies(event) }
                    { renderTarget(event) }
                    { renderExpectedResult(event) }
                    { renderEducationDates(event) }
                    { renderStatus(event) }
                    { renderCreatedOn(event) }
                </div>
            </div>
        );
    };

    const renderTargetsList = () => {

        const isEventOpen = (eventId) => {
            return events && events.find(x => x.id === eventId).isOpen;
        };

        const openEvent = (event) => () => {
            if (!!events) {
                const eventsToggled = events
                    .map(x => ({...x, isOpen: (x.id === event.id) ? !x.isOpen : x.isOpen }));
                setEvents(eventsToggled);
            }
        };

        if (!events || !events.length) {
            return <div className="No-Data">Планы обучения отсутствуют</div>
        } else {
            return (
                events.map(event => 
                    (
                        <div className="Line" key={event.id}>
                            
                            <div className="Sub-Line">
                                <div className="Sub-Line__Left Sub-Line__Header">
                                    {event && event.resource && event.resource.name}
                                </div>
                                <div className="Sub-Line__Right">
                                    <div className="Sub-Line__Right--chevron">
                                        <ChevronButton isUp={isEventOpen(event.id)} onClick={openEvent(event)}/>
                                    </div>
                                </div>
                            </div>
                            {isEventOpen(event.id) &&
                            <div className="Sub-Line">
                                {renderEducationEventItem(event)}
                            </div>}
                        </div>
                    )
                )
            );
        }
    };

    const renderTargetsBlock = () => {
        return (
            <div className="EducationEvents-Progress">
                <div className="Header">
                    <div className="Header__Text">
                        <div>Программы</div>
                    </div>
                </div>
                <div className="Body">
                    <div className="Body__List">
                        {renderTargetsList()}
                    </div>
                </div>
            </div>
        );
    };
   
    const renderEducationsCreatorBlock = () => {
        
        const newEducationEvent = () => {
            props.push(educationEventRoute.buildUrl({ id: personId, eventId: uuid(), mode: "new" }))
        };

        return (
            <div className="EducationEvents-Progress">
                <div className="Header">
                    <div className="Header__Text">
                        Мой план обучения
                    </div>
                </div>
                <div className="Body">
                    <div className="Body__Buttons-List">
                        <Button 
                            type="add" 
                            addLink
                            onClick={newEducationEvent}
                        >
                            Создать план обучения
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <PersonCard {...props.person} />
            {events && events.length && competencyInfo && competencyInfo.length
            ?
            <>
                { renderTargetsBlock() }
                { renderEducationsCreatorBlock() }
            </>
            :
            <>
                { renderEducationsCreatorBlock() }
                { renderTargetsBlock() }
            </>}
        </div> 
    );
};

const actions = {showErrorAlert, showPageLoader, hidePageLoader, showWarningAlert, push};
export default connect(null, actions)(EducationEvents);
