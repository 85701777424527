import { appName } from '../constants';
import { withPageLoader } from './PageLoader';
import { serviceResultCode, getError } from 'serviceErrors';
import { push } from 'connected-react-router';
import { evolutionPlan as planRoute } from 'routes';
import RequestError from '../RequestError';
import { showErrorAlert } from './Alert';
import {
    createPersonGrowthPlan as createPlan,
    updatePersonGrowthPlan as updatePlan,
    getPersonGrowthPlan as getPlan,
    getImportUserSession as getSessionApi,
    saveImportUserSession as saveSessionApi,
} from 'api';
import { put, all, takeLatest, select, call } from 'redux-saga/effects';

const moduleName = 'plan';
export const INIT_WIZARD_START = `${appName}/${moduleName}/INIT_WIZARD_START`;
export const INIT_WIZARD_FINISH = `${appName}/${moduleName}/INIT_WIZARD_FINISH`;
export const STEP_SET_START = `${appName}/${moduleName}/STEP_SET_START`;
export const STEP_SET_FINISH = `${appName}/${moduleName}/STEP_SET_FINISH`;

export const COMPLETE_STEP_START = `${appName}/${moduleName}/COMPLETE_STEP_START`;
export const COMPLETE_STEP_FINISH = `${appName}/${moduleName}/COMPLETE_STEP_FINISH`;
export const STEP_NEXT = `${appName}/${moduleName}/STEP_NEXT`;
export const STEP_PREVIOUS = `${appName}/${moduleName}/STEP_PREVIOUS`;

export const NEW_PLAN = `${appName}/${moduleName}/NEW_PLAN`;
export const FETCH = `${appName}/${moduleName}/FETCH`;
export const FETCH_RESOURCES = `${appName}/${moduleName}/FETCH_RESOURCES`; 
export const FETCH_START = `${appName}/${moduleName}/FETCH_START`;
export const FETCH_END = `${appName}/${moduleName}/FETCH_END`;
export const SAVE = `${appName}/${moduleName}/SAVE`;
export const SAVE_START = `${appName}/${moduleName}/SAVE_START`;
export const SAVE_END = `${appName}/${moduleName}/SAVE_END`;

const initialState = {
    initialize: false,
    existingPlan: false,
    plan: {
        id: 0,
        title: "",
        start: "",
        end: "",
        personId: 0,
        status: "",
        type: "",
    },
}

export default function planReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case INIT_WIZARD_FINISH:
                return {
                    initialized: true,
                    ...payload,
                };

        case STEP_SET_FINISH:
            return {
                ...state,
                activeStep: payload
            };

        case COMPLETE_STEP_FINISH:
            return {
                ...state,
                data: {
                    ...state.data,
                    [payload.id]: payload.data
                }
            };

        case FETCH_START:
        case SAVE_START:
        case FETCH_RESOURCES:
            return {
                ...state,
                loading: true,
            };

        case NEW_PLAN:
            return {
                ...state,
                existingPlan: false,
                plan: payload.plan,
            };

        case FETCH_END:
            return {
                ...state,
                existingPlan: true,
                plan: payload.plan,
            };

        default:
            return state;
    }
}

const getStateErrorText = "Произошла ошибка при загрузке плана. Пожалуйста, попробуйте повторить операцию позднее.";
const saveStateErrorText = "Произошла ошибка при сохранении плана. Пожалуйста, попробуйте повторить операцию позднее.";

function initComplete(payload) {
    return {
        type: INIT_WIZARD_FINISH,
        payload:payload
    };
}

function completeStepFinish(payload) {
    return {
        type: COMPLETE_STEP_START,
        payload: payload
    }
}

function setStepComplete(page) {
    return {
        type: STEP_SET_FINISH,
        payload: page
    }
}

export function newPlan() {
    return {
        type: NEW_PLAN,
    };
}

export function fetchPlan(id) {
    return {
        type: FETCH,
        payload: { id },
    };
}

export function fetchPlanResources(competencyIds) {
    return {
        type: FETCH_RESOURCES,
        payload: {competencyIds}
    };
}

export function savePlan(plan) {
    return {
        type: SAVE,
        payload: { plan },
    };
}

function fetchStart() {
    return {
        type: FETCH_START,
    };
}

function fetchSuccess(plan) {
    return {
        type: FETCH_END,
        payload: { plan },
    };
}

function fetchFailed(error) {
    return {
        type: FETCH_END,
        payload: { error },
        error: true,
    };
}

function saveStart() {
    return {
        type: SAVE_START,
    };
}

function saveSuccess(plan) {
    return {
        type: SAVE_END,
        payload: { plan },
    };
}

function saveFailed(error) {
    return {
        type: SAVE_END,
        payload: { error },
        error: true,
    };
}

function* fetchSaga(action) {
    const { id: planId } = action.payload;

    yield put(fetchStart());

    try {
        const response = yield call(withPageLoader, () => getPlan(planId));

        yield put(fetchSuccess(response.data));
    } catch (error) {
        const reqError = new RequestError(error, 'При загрузке плана произошла ошибка');

        yield put(fetchFailed(reqError));
        yield put(showErrorAlert(reqError.message));
    }
}

function* saveSaga(action) {
    const { plan } = action.payload;

    yield put(saveStart());

    try {
        const response = yield call(withPageLoader,
            () => !plan.id ? createPlan(plan) : updatePlan(plan), true);

        const savedPlan = response.data;

        yield put(saveSuccess(savedPlan));
        yield put(push(planRoute.buildUrl({ id: savedPlan.id })));
    } catch (error) {

        const reqError = getError(error, getPlanError(plan));

        yield put(saveFailed(reqError));
        yield put(showErrorAlert(reqError.message));
    }
}

const getPlanError = (plan) => (code) => {
    switch (code) {
        case serviceResultCode.NotFound:
            return `План с именем "${plan.title}" не найден`;
        case serviceResultCode.GroupIsInUse:
            return `План с именем "${plan.title}" запрещено менять, т.к. он уже используется`;
        case serviceResultCode.GroupTitleAlreadyExists:
            return `План с именем "${plan.title}" уже существует`;
        default:
            return "Произошла непредвиденная ошибка"
    }
}

const planInit = function* (action) {
    const result = yield call(getState, action.payload);
    if (result.error) {
        yield put(showErrorAlert(getStateErrorText));
        return;
    }

    yield put(initComplete(result.ok.payload));
}

const setStep = function* (action) {
    yield put(setStepComplete(action.payload));
}

const planCompleteStep = function* (action) {
    const state = yield select(state => state.plan);
    const ok = yield call(saveState, state);
    if (!ok) {
        yield put(showErrorAlert(saveStateErrorText));
        return;
    }

    yield put(completeStepFinish(action.payload));
}

const saveState = async (state) => {
    if (state.complete) { return; }

    try {
        await saveSessionApi(state);
        return true;
    }
    catch (error) {
        return false;
    }
}

const getState = async (fallbackState) => {
    try {
        const response = await getSessionApi();
        if (response && response.data) {
            return {
                ok: {
                    payload: response.data
                }
            };
        }

        return {
            ok: {
                payload: fallbackState
            }
        };
    }
    catch (error) {
        return {
            error: true
        };
    }
}

export const allActions = { fetchPlan, newPlan, savePlan, fetchPlanResources, setStep };

export function* saga() {
    yield all([
        takeLatest(INIT_WIZARD_START, planInit),
        takeLatest(STEP_SET_START, setStep),
        takeLatest(COMPLETE_STEP_START, planCompleteStep),
        takeLatest(FETCH, fetchSaga),
        takeLatest(FETCH_RESOURCES, fetchPlanResources),
        takeLatest(SAVE, saveSaga),
    ]);
}