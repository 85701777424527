import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import { searchEmailTemplates } from 'api';
import Button from 'components/uikit/Button';
import DataGrid from 'components/common/DataGrid';
import { templatesEditRoute, templatesCreateRoute, templatesRoute } from 'routes';
import ListTemplate from 'components/uikit/ListTemplate';
import { parse as queryStringParse, stringify } from 'query-string';

const getUrlFilterParams = (state) => {
    return {
        page: state.paging.pageNum !== null ? Number(state.paging.pageNum) : 1,
        pageSize: state.paging.pageSize !== null ? Number(state.paging.pageSize) : 1,
    };
};

const initialState = {
    filter: {
        title: '',
        types: [],
        personGrowthTags: [],
    },
    sorting: { name: 'asc' },
    paging: { pageNum: 1, pageSize: 10 },

    loaded: false,
    loadComplete: false,
    templates: {
        payload: [],
        meta: {
            foundCount: 0,
            pageCount: 0,
        },
    },
    sorted: [{ id: 'Name', desc: false }],
};

class TemplatesList extends Component {
    state = initialState;

    componentDidMount() {
        const { paging, filter } = this.getStateFromUrl();
        this.setState({ paging, filter }, () => this.loadTemplates());
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            const { paging, filter } = this.getStateFromUrl();
            this.setState({ paging, filter }, () => this.loadTemplates());
        }
    }

    render() {
        const headBtn = {
            onClick: this.handleCreateNewTemplateClick,
            value: 'Создать шаблон',
            addLink: true,
        };

        return (
            <ListTemplate title={'Редактор писем'} headBtn={headBtn}>
                {!!this.state.templates.payload.length
                    ? this.renderTemplates()
                    : this.state.loaded && <div className="NoData">Данные не найдены</div>}
            </ListTemplate>
        );
    }

    updateURL = () => {
        const filter = getUrlFilterParams(this.state);
        const baseFilter = { ...filter };

        this.props.push({
            pathName: templatesRoute.url,
            search: stringify(baseFilter),
        });
    };

    getStateFromUrl = () => {
        const { page = 1, pageSize = 10 } = queryStringParse(this.props.location.search);

        return {
            paging: {
                ...this.state.paging,
                pageSize: +pageSize,
                pageNum: +page,
            },
            filter: {},
        };
    };

    buildColumns = [
        {
            Header: 'Название',
            accessor: 'Name',
            resizable: false,
            sortable: true,
            Cell: ({ original }) => (
                <NavLink
                    to={templatesEditRoute.buildUrl(
                        { id: original.id },
                        this.props.location.search,
                    )}
                >
                    {original.name}
                </NavLink>
            ),
        },
        {
            Header: 'Действие',
            accessor: 'id',
            maxWidth: 190,
            resizable: false,
            sortable: false,
            Cell: ({ original }) => (
                <Button
                    size="sm"
                    onClick={() =>
                        this.props.push(
                            templatesEditRoute.buildUrl(
                                { id: original.id },
                                this.props.location.search,
                            ),
                        )
                    }
                    className="resourcesEditBtn"
                >
                    Редактировать
                </Button>
            ),
        },
    ];

    loadTemplates = async () => {
        const { filter, sorting, paging } = this.state;

        const criteria = {
            filter,
            sorting,
            paging,
        };

        const templates = await this.callEffect(() => searchEmailTemplates(criteria));

        this.setState({
            templates: templates.data,
            loaded: true,
            loadComplete: true,
        });
    };

    onPageChange = (pageIndex) => {
        this.setState(
            {
                paging: { ...this.state.paging, pageNum: pageIndex + 1 },
            },
            () => this.updateURL(),
        );
    };

    changePageAmount = (pageSize) => {
        this.setState({ paging: { pageNum: 1, pageSize } }, () => this.updateURL());
    };

    onSortedChange = (sorted) => {
        this.setState(
            {
                sorted,
                sorting: { [sorted[0].id]: sorted[0].desc ? 'desc' : 'asc' },
            },
            () => this.loadTemplates(),
        );
    };

    callEffect = async (callback) => {
        this.props.showPageLoader();

        try {
            return await callback();
        } catch (error) {
            this.props.showErrorAlert(error.message);
        } finally {
            this.props.hidePageLoader();
        }
    };

    renderTemplates = () => {
        const { templates, paging } = this.state;

        return (
            <DataGrid
                data={templates.payload}
                sorted={this.state.sorted}
                onSortedChange={this.onSortedChange}
                columns={this.buildColumns}
                loading={!this.state.loadComplete}
                showPagination={true}
                showPageSizeOptions={false}
                pages={templates.meta.pageCount}
                page={paging.pageNum}
                onPageChange={this.onPageChange}
                foundCount={templates.meta.foundCount}
                manual
                pageSize={paging.pageSize}
                changePageAmount={this.changePageAmount}
            />
        );
    };

    handleCreateNewTemplateClick = () => {
        this.props.push(templatesCreateRoute.buildUrl({ id: uuid() }, this.props.location.search));
    };
}

const actions = {
    push,
    showPageLoader,
    hidePageLoader,
    showErrorAlert,
};

export default connect(null, actions)(TemplatesList);
