import { startOf, endOf } from 'utils';

export const queryOperation = {
    Equal: '=',
    NotEqual: '!=',
    Greater: '>',
    Less: '<',
    GreaterOrEqual: '>=',
    LessOrEqual: '<=',
    InArray: 'in',
    NotInArray: 'notin',
    Contain: 'contains',
    NotContain: 'notcontains',
    Like: 'like',
    NotLike: 'notlike',
    Exists: 'exists',
    NotExists: 'notexists',
    CheckGroupScopesInArray: 'checkgroupscopesin',
    CheckGroupScopesNotInArray: 'checkgroupscopesnotin',
    CheckGroupScopes: 'checkgroupscopes',
};

export const queryOperationRus = {
    [queryOperation.Equal]: 'Равно',
    [queryOperation.NotEqual]: 'Не равно',
    [queryOperation.Greater]: 'Больше',
    [queryOperation.Less]: 'Меньше',
    [queryOperation.GreaterOrEqual]: 'Больше или равно',
    [queryOperation.LessOrEqual]: 'Меньше или равно',
    [queryOperation.InArray]: 'in',
    [queryOperation.NotInArray]: 'notin',
    [queryOperation.Contain]: 'Содержит',
    [queryOperation.NotContain]: 'Не содержит',
    [queryOperation.Like]: 'Содержит',
    [queryOperation.NotLike]: 'Не содержит',
};

export const queryOperationExtProps = {
    [queryOperation.NotEqual]: { background: '#fdd7d7' },
    [queryOperation.Greater]: { text: 'больше' },
    [queryOperation.Less]: { text: 'меньше' },
    [queryOperation.GreaterOrEqual]: {text: 'больше или равно'},
    [queryOperation.LessOrEqual]: {text: 'меньше или равно'},
    [queryOperation.Contain]: {text: 'включает'},
    [queryOperation.NotContain]: {text: 'не включает'},
    [queryOperation.Like]: {text: 'содержит'},
    [queryOperation.NotLike]: {text: 'не содержит'},
};

export const queryJoinOperation = {
    'OR': 'ИЛИ',
    'AND': 'И'
}

export const operation = {
    Number: [
        { Equal: queryOperation.Equal },
        { NotEqual: queryOperation.NotEqual },
        { Greater: queryOperation.Greater },
        { Less: queryOperation.Less },
        { GreaterOrEqual: queryOperation.GreaterOrEqual },
        { LessOrEqual: queryOperation.LessOrEqual },
        { CheckGroupScopes: queryOperation.CheckGroupScopes },
        { CheckGroupScopesInArray: queryOperation.CheckGroupScopesInArray },
        { CheckGroupScopesNotInArray: queryOperation.CheckGroupScopesNotInArray },
    ],
    String: [
        { Equal: queryOperation.Equal },
        { NotEqual: queryOperation.NotEqual },
        { Contain: queryOperation.Contain },
        { NotContain: queryOperation.NotContain },
        { Like: queryOperation.Like },
        { NotLike: queryOperation.NotLike },
    ],
    Boolean: [{ Equal: queryOperation.Equal }, { NotEqual: queryOperation.NotEqual }],
};

export const inversionOperation = {
    [queryOperation.Equal]: queryOperation.NotEqual,
    [queryOperation.NotEqual]: queryOperation.Equal,
    [queryOperation.Greater]: queryOperation.Less,
    [queryOperation.Less]: queryOperation.Greater,
    [queryOperation.GreaterOrEqual]: queryOperation.LessOrEqual,
    [queryOperation.LessOrEqual]: queryOperation.GreaterOrEqual,
    [queryOperation.InArray]: queryOperation.NotInArray,
    [queryOperation.NotInArray]: queryOperation.InArray,
    [queryOperation.Contain]: queryOperation.NotContain,
    [queryOperation.NotContain]: queryOperation.Contain,
    [queryOperation.Like]: queryOperation.NotLike,
    [queryOperation.NotLike]: queryOperation.Like,
    [queryOperation.Exists]: queryOperation.NotExists,
    [queryOperation.NotExists]: queryOperation.Exists,
    [queryOperation.CheckGroupScopesInArray]: queryOperation.CheckGroupScopesNotInArray,
    [queryOperation.CheckGroupScopesNotInArray]: queryOperation.CheckGroupScopesInArray,
};

export const operationFromSelect = {
    Number: [
        { id: 0, code: queryOperation.Equal, name: 'Равно' },
        { id: 1, code: queryOperation.NotEqual, name: 'Не равно' },
        { id: 2, code: queryOperation.Greater, name: 'Больше' },
        { id: 3, code: queryOperation.Less, name: 'Меньше' },
        { id: 4, code: queryOperation.GreaterOrEqual, name: 'Больше или равно' },
        { id: 5, code: queryOperation.LessOrEqual, name: 'Меньше или равно' },
        {
            id: 7,
            code: queryOperation.CheckGroupScopes,
            name: 'Находится в группах и их области видимости',
        },
        {
            id: 7,
            code: queryOperation.CheckGroupScopesInArray,
            name: 'Находится в группах и их области видимости',
        },
        {
            id: 8,
            code: queryOperation.CheckGroupScopesNotInArray,
            name: 'Не находится в группах и их области видимости',
        },
    ],
    String: [
        { id: 0, code: queryOperation.Equal, name: 'Равно' },
        { id: 1, code: queryOperation.NotEqual, name: 'Не равно' },
        { id: 2, code: queryOperation.Contain, name: 'Включает' },
        { id: 3, code: queryOperation.NotContain, name: 'Не включает' },
        { id: 4, code: queryOperation.Like, name: 'Содержит' },
        { id: 5, code: queryOperation.NotLike, name: 'Не содержит' },
    ],
    Boolean: [
        { id: 0, code: queryOperation.Equal, name: 'Равно' },
        { id: 1, code: queryOperation.NotEqual, name: 'Не равно' },
    ],
};

export const evaluationFields = {
    provider: { name: 'ProviderId', operation: queryOperation.Equal },
    testId: { name: 'EvaluationTestId', operation: queryOperation.Equal },
    criteriaId: { name: 'EvaluationTestCriterionId', operation: queryOperation.Equal },
    valueTo: { name: 'DecimalValue', operation: queryOperation.LessOrEqual },
    valueFrom: { name: 'DecimalValue', operation: queryOperation.GreaterOrEqual },
    evaluationSummaryScaleId: { name: 'EvaluationSummaryScaleId', operation: queryOperation.Equal },
    evaluationSummaryScaleValueTo: { name: 'DecimalValue', operation: queryOperation.LessOrEqual },
    evaluationSummaryScaleValueFrom: {
        name: 'DecimalValue',
        operation: queryOperation.GreaterOrEqual,
    },
};

export const categoryArray = {
    selectedGroups: 'SystemInfo',
    wishlists: 'Wishlist',
    selectedDataOrigin: 'PersonPersonnels',
};

export const associativeArray = {
    personResidence: 'PersonResidence',
    personalInfo: 'Persons',
    lastName: 'LastName',
    firstName: 'FirstName',
    middleName: 'MiddleName',
    selectedSex: 'Sex',
    birthDate: 'BirthDate',
    birthPlace: 'BirthPlace',
    email: 'Email',
    phone: 'Phone',
    selectedDistricts: 'FederalDistrictId',
    selectedRegions: 'RegionId',
    selectedLocalities: 'LocalityId',
    selectedDocument: 'IdentityDocumentName',
    selectedDataOrigin: 'DataOriginId',
    documentNumber: 'IdentityDocumentNumber',
    documentName: 'IdentityDocumentCustomName',
    ageStart: 'Age',
    ageEnd: 'Age',
    extraInfo: 'ExtraInfo',
    residenceStartYearStart: 'ResidenceStartYear',
    residenceStartYearEnd: 'ResidenceStartYear',

    educationInfo: 'PersonEducations',
    selectedEducationLevel: 'EducationLevelCode',
    university: 'University',
    specialty: 'Specialty',
    graduationYearStart: 'GraduationYear',
    graduationYearEnd: 'GraduationYear',

    workInfo: 'PersonJobs',
    company: 'CompanyName',
    position: 'Position',
    selectedIndustry: 'IndustryId',
    selectedWorkArea: 'WorkAreaId',
    selectedManagementLevel: 'ManagementLevelId',
    selectedExperience: 'ManagementExperienceId',
    selectedEmployeesNumber: 'EmployeesNumberId',
    hireYearStart: 'HireDate',
    hireYearEnd: 'QuitDate',
    professionalAchievements: 'ProfessionalAchievements',
    isCurrent: 'IsCurrent',

    governmentServiceInfo: 'PersonGovernmentServices',
    selectedGovernmentServiceKind: 'GovernmentServiceKindCode',
    branch: 'Branch',
    rank: 'Rank',
    startYearFrom: 'StartDate',
    startYearTo: 'EndDate',

    evaluationInfo: 'PersonEvaluationCriterionResults',
    provider: 'ProviderId',
    testId: 'EvaluationTestId',
    criteriaId: 'EvaluationTestCriterionId',
    valueTo: 'DecimalValue',
    valueFrom: 'DecimalValue',
    evaluationSummaryScaleId: 'EvaluationSummaryScaleId',
    evaluationSummaryScaleValueTo: 'DecimalValue',
    evaluationSummaryScaleValueFrom: 'DecimalValue',
    ResultStatus: 'ResultStatus',
    criteria: '',

    familyInfo: 'Persons',
    selectedFamilyStatus: 'FamilyStatus',
    selectedChildrenStatus: 'ChildrenStatusId',

    languagesInfo: 'PersonLanguages',
    language: 'LanguageId',
    languageLevel: 'LanguageLevelId',

    personnelInfo: 'PersonPersonnels',
    recommenderName: 'RecommenderName',
    curatorName: 'CuratorName',
    selectedExperienceLevel: 'ExperienceLevelId',
    socialActivity: 'SocialActivity',
    memberOfSocialOrganizations: 'MemberOfSocialOrganizations',
    socialAchivements: 'SocialAchivements',

    systemInfo: 'SystemInfo',
    systemFilters: 'SystemFilters',
    selectedGroups: 'GroupId',
    createdStart: 'Created',
    createdEnd: 'Created',

    wishlists: 'WishlistId',
};

export const filtersName = {
    lastName: 'Фамилия',
    firstName: 'Имя',
    middleName: 'Отчество',
    selectedSex: 'Пол',
    birthPlace: 'Место рождения',
    email: 'E-mail',
    phone: 'Телефон',
    selectedDistricts: 'Федеральный округ',
    selectedRegions: 'Регион',
    selectedLocalities: 'Город',
    selectedDocument: 'Документ',
    selectedDataOrigin: 'Источник данных',
    documentNumber: 'Номер документа',
    documentName: 'Название документа',
    ageStart: 'Возраст с',
    ageEnd: 'Возраст по',
    personalInfoextraInfo: 'Биографическая справка',
    residenceStartYearStart: 'Год начала проживания с',
    residenceStartYearEnd: 'Год начала проживания по',
    companyCurrent: 'Текущее место работы',
    positionCurrent: 'Текущая должность',

    educationInfoextraInfo: 'Другая информация о дополнительном образовании',
    selectedEducationLevel: 'Уровень образования',
    university: 'ВУЗ',
    specialty: 'Специальность',
    graduationYearStart: 'Год окончания с ',
    graduationYearEnd: 'Год окончания по',

    position: 'Должность',
    company: 'Место работы',
    selectedIndustry: 'Отрасль',
    selectedWorkArea: 'Область деятельности',
    selectedManagementLevel: 'Уровень менеджмента',
    selectedExperience: 'Опыт управления',
    selectedEmployeesNumber: 'Кол-во человек в подчинении',
    hireYearStart: 'Год трудоустройства с',
    hireYearEnd: 'Год трудоустройства по',
    professionalAchievements: 'Профессиональные достижения',

    governmentServiceInfoposition: 'Служебная должность',
    selectedGovernmentServiceKind: 'Тип службы',
    branch: 'Вид службы (род войск)',
    rank: 'Звание',
    startYearFrom: 'Год начала службы с',
    startYearTo: 'Год начала службы по',

    selectedFamilyStatus: 'Семейное положение',
    selectedChildrenStatus: 'Кол-во детей',

    language: 'Язык',
    languageLevel: 'Уровень валдения языком',

    personnelInfoextraInfo: 'Дополнительные сведения',
    recommenderName: 'Рекомендатель',
    curatorName: 'Куратор',
    selectedExperienceLevel: 'Уровень подготовленности участника',
    socialActivity: 'Социальная активность',
    memberOfSocialOrganizations: 'Членство в общественных организациях',
    socialAchivements: 'Деятельность в качестве руководителя',
    selectedGroups: 'Группа',
    wishlists: 'Подборка',
};

export const searchBlocks = {
    personalInfo: [
        'lastName',
        'firstName',
        'middleName',
        'selectedSex',
        'selectedDocument',
        'documentNumber',
        'birthPlace',
        'selectedDistricts',
        'selectedLocalities',
        'residenceStartYearStart',
        'residenceStartYearEnd',
        'phone',
        'email',
        'extraInfo',
    ],
    educationInfo: [
        'selectedEducationLevel',
        'university',
        'specialty',
        'graduationYearStart',
        'graduationYearEnd',
        'extraInfo',
    ],
    workInfo: [
        'selectedWorkArea',
        'selectedManagementLevel',
        'selectedEmployeesNumber',
        'selectedExperience',
        'hireYearStart',
        'hireYearEnd',
        'professionalAchievements',
    ],
    governmentServiceInfo: [
        'selectedGovernmentServiceKind',
        'branch',
        'position',
        'rank',
        'startYearFrom',
        'startYearTo',
    ],
    languagesInfo: ['language', 'languageLevel'],
    familyInfo: ['selectedFamilyStatus', 'selectedChildrenStatus'],
    personnelInfo: [
        'selectedDataOrigin',
        'selectedExperienceLevel',
        'recommenderName',
        'curatorName',
        'socialActivity',
        'memberOfSocialOrganizations',
        'socialAchivements',
        'extraInfo',
    ],
    systemInfo: ['selectedGroups', 'wishlists'],
};

export const altValues = {
    hireYearStart: (value) => startOf(value, 'year').format(),
    hireYearEnd: (value) => endOf(value, 'year').format(),
    startYearFrom: (value) => startOf(value, 'year').format(),
    startYearTo: (value) => endOf(value, 'year').format(),
};

export const getEvaluationLabel = (record) => {
    const getLabel = (title, value) => (value != null && value !== '' ? `${title}${value}` : null);
    const getRangeLabel = (valueFrom, valueTo) =>
        [getLabel('от ', valueFrom), getLabel('до ', valueTo)].filter((x) => x).join(' ');
    return [
        getLabel('Поставщик: ', record.provider?.name),
        getLabel('Тестирование: ', record.testName),
        getLabel('Шкала: ', record.criteriaName),
        getLabel(
            'Значение: ',
            [getLabel('от ', record.valueFrom), getLabel('до ', record.valueTo)]
                .filter((c) => c)
                .join(' '),
        ),
        getRangeLabel(
            record?.evaluationSummaryScaleValueFrom,
            record?.evaluationSummaryScaleValueTo,
        )
            ? getLabel(
                  `${record?.summaryScaleName} `,
                  getRangeLabel(
                      record?.evaluationSummaryScaleValueFrom,
                      record?.evaluationSummaryScaleValueTo,
                  ),
              )
            : getLabel('Компетенции и способности: ', record?.summaryScaleName),
    ]
        .filter((c) => c)
        .join(', ');
};
