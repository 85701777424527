import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { push as pushLocation } from 'connected-react-router';
import EmailTemplate from 'components/common/Email';
import Page from 'components/common/Page';
import { massEmailLists, editMassEmail } from 'routes';
import {
    getMassEmailById,
    saveMassEmail,
    getActiveCampaignByMassEmailId,
    getCampaignById,
    sendMassEmail,
    copyCampaign,
    getMassEmailHTMLCode,
    addRecipientsToCampaign,
    deleteRecipientsFromCampaign,
    getRecipientsByCampaignId,
} from 'api';
import Loader from 'components/common/Loader';
import { stringify } from 'query-string';
import { CAMPAIGN_STATUS } from 'constants.js';
import ModalDialog from 'components/common/ModalDialog';
import { getValidationErrors } from 'serviceErrors';
import './Email.scss';

class Email extends Component {
    _mounted = true;

    state = {
        emailCopy: {
            body: '',
            subject: '',
        },
        subject: '',
        body: '',
        recipients: [],
        campaign: null,
        loading: false,
        recipientsPaging: { pageNum: 1, pageSize: 10 },
        recipientsMeta: {},
        searchFilter: '',
        isModalOpen: false,
    };

    getEmailById = async (id) => {
        try {
            this.setState({ loading: true });
            const emailData = await getMassEmailById(id);
            const email = emailData.data;
            this.setState({
                subject: email.subject,
                body: email.message,
                emailCopy: {
                    subject: email.subject,
                    body: email.message,
                },
            });
        } catch (e) {
            this.props.pushLocation({
                pathname: massEmailLists.url,
                search: stringify({ section: 'emails', page: 1, pageSize: 10 }),
            });
            this.props.showErrorAlert('Ошибка загрузки письма.');
        } finally {
            this.setState({ loading: false });
        }
    };

    getRecipients = async (
        criteria = { paging: { ...this.state.recipientsPaging } },
        campaignId,
    ) => {
        const { campaign } = this.state;
        try {
            const res = await getRecipientsByCampaignId(campaignId || campaign.id, criteria);
            const recipients = res.data;

            this.setState({
                recipients: recipients.payload.map((x) => x.person),
                recipientsMeta: { ...recipients.meta },
                recipientsPaging: { ...criteria.paging },
                searchFilter: criteria?.filter?.fullName || '',
            });
        } catch (e) {
            this.props.showErrorAlert('Ошибка загрузки адресатов.');
        }
    };

    saveEmail = async (withSuccessMsg = true) => {
        const { subject, body } = this.state;
        const {
            match: {
                params: { id },
            },
            pushLocation,
        } = this.props;
        try {
            this.setState({ loading: true });
            const criteria = {
                ...(id && { id }),
                status: 'Active',
                subject,
                message: body,
            };
            const res = await saveMassEmail(criteria);
            withSuccessMsg && this.props.showSuccessAlert('Письмо успешно сохранено.');
            pushLocation(editMassEmail.buildUrl({ id: res.data.id }, this.props.location.search));
        } catch (e) {
            const validationErrors = getValidationErrors(e);
            if (Array.isArray(validationErrors) && validationErrors.length > 0) {
                validationErrors.map((item) => {
                    return this.props.showErrorAlert(item.message);
                });
            } else {
                this.props.showErrorAlert('Ошибка сохранения письма.');
            }
            if (!withSuccessMsg) {
                throw new Error(e);
            }
        } finally {
            this._mounted && this.setState({ loading: false, emailCopy: { subject, body } });
        }
    };

    setSubject = (e) => {
        this.setState({ subject: e.currentTarget.value });
    };

    setBody = (e) => {
        this.setState({ body: e.currentTarget.value });
    };

    setRecipients = async (newRecipientsIds = [], savePage = false, filter = '') => {
        const { campaign, recipientsMeta, recipientsPaging } = this.state;
        const { showPageLoader, hidePageLoader, showErrorAlert } = this.props;
        try {
            showPageLoader();
            let campaignId = campaign.id;

            const toAdd = newRecipientsIds.filter((x) => !recipientsMeta.ids.find((r) => r === x));
            const toDelete =
                newRecipientsIds.length !== 0
                    ? recipientsMeta.ids.filter((x) => !newRecipientsIds.find((r) => r === x))
                    : recipientsMeta.ids;

            const makeCampaignCopy = CAMPAIGN_STATUS.Sent.id === campaign.status;

            let newCampaign = null;
            if (makeCampaignCopy) {
                const campaignCopy = await copyCampaign(campaignId);
                newCampaign = campaignCopy.data;
                campaignId = newCampaign.id;
            }

            toAdd.length !== 0 && (await addRecipientsToCampaign(campaignId, toAdd));
            toDelete.length !== 0 && (await deleteRecipientsFromCampaign(campaignId, toDelete));

            if (makeCampaignCopy) {
                this.setState({ campaign: newCampaign, campaignId });
            } else {
                const maxPageNum = Math.ceil(
                    (recipientsMeta.foundCount + toAdd.length - toDelete.length) /
                        recipientsPaging.pageSize,
                );

                const newPageNum =
                    recipientsPaging.pageNum <= maxPageNum
                        ? recipientsPaging.pageNum
                        : recipientsPaging.pageNum - 1;

                await this.getRecipients({
                    paging: {
                        ...recipientsPaging,
                        pageNum: savePage ? newPageNum : 1,
                    },
                    ...(filter && { filter: { fullName: filter } }),
                });
            }
        } catch (e) {
            showErrorAlert('Ошибка добавления / удаления адресатов.');
        } finally {
            hidePageLoader();
        }
    };

    sendLetter = async () => {
        const {
            match: {
                params: { id },
            },
        } = this.props;
        const campaignId = this.state.campaign.id;
        if (!id) {
            return;
        }

        try {
            showPageLoader();
            const campaign = await getCampaignById(campaignId);
            const forceSend = CAMPAIGN_STATUS.Sent.id === campaign.data.status;
            this.isLetterChanged() && (await this.saveEmail(false));
            sendMassEmail(id, forceSend);
            this.props.showSuccessAlert('Письмо успешно отправлено.');
            this.props.pushLocation({
                pathname: massEmailLists.url,
                search: this.props.location.search,
            });
        } catch (e) {
            this.closeModal();
            showErrorAlert('Ошибка отправки.');
        } finally {
            hidePageLoader();
        }
    };

    getEmailHtmlCode = async () => {
        const { subject, body } = this.state;
        let htmlCode = { data: '' };
        try {
            htmlCode = await getMassEmailHTMLCode({ subject, body });
            return htmlCode.data;
        } catch (e) {
            this.props.showErrorAlert('Ошибка генерации письма.');
            return htmlCode.data;
        }
    };

    openSendModal = () => {
        this.setState({ isModalOpen: true });
    };

    closeModal = () => {
        this.setState({ isModalOpen: false });
    };

    fetchData = async () => {
        const { showPageLoader, hidePageLoader, showErrorAlert } = this.props;
        const { id } = this.props.match.params;
        try {
            showPageLoader();
            id && (await this.getEmailById(id));
            const campaign = id && (await getActiveCampaignByMassEmailId(id));
            id && (await this.getRecipients(undefined, campaign.data.id));
            campaign?.data?.id &&
                this.setState({ campaignId: campaign.data.id, campaign: campaign.data });
        } catch (e) {
            showErrorAlert('Ошибка загрузки письма.');
        } finally {
            hidePageLoader();
        }
    };

    isLetterChanged = () => {
        const { emailCopy, subject, body } = this.state;
        return emailCopy.subject !== subject || emailCopy.body !== body;
    };

    setRecipientsPaging = (recipientsPaging) => {
        this.setState({ recipientsPaging });
    };

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        const { id } = this.props.match.params;
        if (
            (id && prevProps.match.params?.id !== id) ||
            prevState.recipientsPaging.pageSize !== this.state.recipientsPaging.pageSize
        ) {
            this.fetchData();
        }
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    render() {
        const id = this.props.match.params.id;
        const {
            recipients,
            recipientsMeta,
            recipientsPaging,
            subject,
            body,
            loading,
            searchFilter,
            isModalOpen,
        } = this.state;

        return (
            <Page className="MassEmail">
                <EmailTemplate
                    goBack={() => this.props.pushLocation({
                        pathname: massEmailLists.url,
                        search: this.props.location.search,
                    })}
                    setSubject={this.setSubject}
                    setBody={this.setBody}
                    subject={subject}
                    body={body}
                    saveEmail={this.saveEmail}
                    hideRecipients={!id}
                    sendLetter={this.openSendModal}
                    getHtmlCode={this.getEmailHtmlCode}
                    setRecipients={this.setRecipients}
                    getRecipients={this.getRecipients}
                    recipients={recipients}
                    recipientsMeta={recipientsMeta}
                    recipientsPaging={recipientsPaging}
                    massEditRecipients={!searchFilter}
                    setRecipientsPaging={this.setRecipientsPaging}
                />
                <Loader overlay show={loading} />
                <ModalDialog
                    onClick={this.sendLetter}
                    onCloseModal={this.closeModal}
                    modalOpen={isModalOpen}
                    modalHeader={
                        'Это письмо будет отправлено всем выбранным адресатам. Продолжить?'
                    }
                    btnOktext="Отправить"
                    btnCanceltext="Отменить"
                />
            </Page>
        );
    }
}

const actions = {
    pushLocation,
    showPageLoader,
    hidePageLoader,
    showErrorAlert,
    showSuccessAlert,
};

export default connect(null, actions)(Email);
