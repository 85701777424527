export const sex = {
    Male: 'Мужской',
    Female: 'Женский',
};

export const familyStatus = {
    NotMarried: 'Не женат / Не замужем',
    Married: 'Женат / Замужем',
    Divorced: 'Разведён / Разведена',
    Widower: 'Вдовец / Вдова',
    NotRegistered: 'Брак официально не зарегистрирован',
};

export const sexFamilyStatus = {
    Male: {
        NotMarried: 'Не женат',
        Married: 'Женат',
        Divorced: 'Разведён',
        Widower: 'Вдовец',
        NotRegistered: 'Брак официально не зарегистрирован',
    },
    Female: {
        NotMarried: 'Не замужем',
        Married: 'Замужем',
        Divorced: 'Разведена',
        Widower: 'Вдова',
        NotRegistered: 'Брак официально не зарегистрирован',
    },
};

export const fileTarget = {
    PersonFile: 'PersonFile',
    PersonPhoto: 'PersonPhoto',
};

export const yesNo = {
    true: 'Да',
    false: 'Нет',
};

export const customAttributesTypes = {
    string: 'Строка',
    number: 'Число',
    date: 'Дата',
    hyperlink: 'Гиперссылка',
    select: 'Список',
    multiselect: 'Мультисписок',
    file: 'Графический элемент',
};

export const competitionLevel = {
    Federal: 'Федеральный',
    Regional: 'Региональный',
    Local: 'Местный',
};

export const reportTemplateTypes = {
    profile: 'Объективка',
    biography: 'Свод',
};

export const operationTypes = [
    { id: '=', name: 'равно' },
    { id: '!=', name: 'не равно' },
    { id: '>', name: 'больше' },
    { id: '<', name: 'меньше' },
    { id: '>=', name: 'больше или равно' },
    { id: '<=', name: 'меньше или равно' },
    { id: 'in', name: 'включает' },
    { id: 'notin', name: 'не включает' },
];

export const conditionTypes = [
    { id: 'not', name: 'НЕ' },
    { id: 'and', name: 'И' },
    { id: 'or', name: 'ИЛИ' },
];

export const geoLevel = {
    Federal: 'Федеральный',
    Regional: 'Региональный',
    Municipal: 'Муниципальный',
};
