import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Teams from './Teams';
import Wishlists from './Wishlists';
import Search from './Search';
import Selected from './Selected';
import ModalDialog from 'components/common/ModalDialog';
import Button from 'components/uikit/Button';
import MenuSwitcher from 'components/common/MenuSwitcher';

import './PersonSelector.scss';

export const menuIds = {
    teams: 'teams',
    wishlists: 'wishlists',
    search: 'search',
    selected: 'selected',
};

const shortMenu = [
    { id: menuIds.wishlists, icon: 'star', title: 'Подборки' },
    { id: menuIds.search, icon: 'search', title: 'Поиск' },
];

const allMenu = [
    { id: menuIds.teams, icon: 'users', title: 'Команды' },
    { id: menuIds.wishlists, icon: 'star', title: 'Подборки' },
    { id: menuIds.search, icon: 'search', title: 'Поиск' },
];

class PersonSelector extends Component {
    state = {
        menuId: menuIds.wishlists,
        selectedPeople: [],
        isModalOpen: false,
        selected: [],
        searchTerm: '',
    };

    setSearchTerm = (searchTerm) => this.setState({ searchTerm });

    onMenuClick = (menuId) => this.setState({ menuId });

    setSelected = (persons = [], add = true, removeFromRecipients = false) => {
        const { selected, searchTerm } = this.state;
        const { setRecipients } = this.props;

        if (add) {
            const addPart = persons.filter((x) => !selected.find((z) => z === x));
            this.setState({
                selected: [...selected, ...addPart],
            });
        } else {
            this.setState({ selected: selected.filter((x) => !persons.find((z) => z === x)) });
        }

        removeFromRecipients &&
            setRecipients(
                selected.filter((x) => !persons.find((z) => z === x)),
                true,
                searchTerm,
            );
    };

    clearSelection = () => {
        this.setState({ selected: [] }, () => this.props.setRecipients([]));
    };

    isSelectedPerson = (id) => {
        return !!this.state.selected.find((x) => x === id);
    };

    openModal = () => this.setState({ isModalOpen: true });

    closeModal = (resetSelected = false) =>
        this.setState({
            isModalOpen: false,
            menuId: this.props.withTeams ? menuIds.teams : menuIds.wishlists,
            ...(resetSelected && { selected: [...this.props.recipientsMeta.ids] }),
        });

    submitModal = () => {
        const { selected } = this.state;
        const { setRecipients } = this.props;
        setRecipients(selected);
        this.closeModal();
    };

    modalHeader = () => {
        const { withTeams } = this.props;
        const finalMenu = withTeams ? allMenu : shortMenu;
        const { menuId } = this.state;
        return (
            <MenuSwitcher
                menu={finalMenu}
                selectedMenuId={menuId}
                onMenuClick={this.onMenuClick}
                theme="dark"
            />
        );
    };

    componentDidMount() {
        const { recipientsMeta } = this.props;
        this.setState({ selected: recipientsMeta.ids });
    }

    componentDidUpdate(prevProps) {
        const { searchTerm } = this.state;
        const { recipientsMeta, setAddMenuInfo } = this.props;

        if (prevProps.recipientsMeta?.ids?.length !== recipientsMeta?.ids?.length) {
            this.setState({ selected: recipientsMeta.ids });
            searchTerm
                ? setAddMenuInfo(
                      'recipients',
                      `${
                          !!recipientsMeta.foundCount
                              ? ` (Отфильтровано: ${recipientsMeta.foundCount})`
                              : ' (Нет совпадений)'
                      }`,
                  )
                : setAddMenuInfo();
        }
    }

    componentWillUnmount() {
        const { setAddMenuInfo } = this.props;
        setAddMenuInfo();
    }

    render() {
        const { menuId, selected, isModalOpen, searchTerm } = this.state;
        const {
            withTeams,
            recipients,
            recipientsMeta,
            recipientsUpdated,
            getRecipients,
            recipientsPaging,
            massEditRecipients,
        } = this.props;

        return (
            <div className="PersonSelector">
                <Selected
                    isSelectedPerson={this.isSelectedPerson}
                    setSelected={this.setSelected}
                    clearSelection={this.clearSelection}
                    recipients={recipients}
                    recipientsMeta={recipientsMeta}
                    recipientsUpdated={recipientsUpdated}
                    getRecipients={getRecipients}
                    recipientsPaging={recipientsPaging}
                    searchTerm={searchTerm}
                    setSearchTerm={this.setSearchTerm}
                    setRecipientsPaging={this.props.setRecipientsPaging}
                />

                {massEditRecipients && (
                    <div className="PersonSelectorSection__Footer">
                        {recipients.length !== 0 && (
                            <Button closeLink onClick={this.clearSelection} size="sm">
                                Очистить
                            </Button>
                        )}
                        <Button onClick={this.openModal} size="sm">
                            Изменить
                        </Button>
                    </div>
                )}

                <ModalDialog
                    onClick={this.submitModal}
                    onCloseModal={() => this.closeModal(true)}
                    modalOpen={isModalOpen}
                    modalHeader={this.modalHeader()}
                    btnOktext="Применить"
                    btnCanceltext="Отменить"
                    sideButtons
                    size="lg"
                    fitHeight
                    isValidForm={selected?.length !== 0}
                >
                    <div className="PersonSelectorSection">
                        {/*TODO: Доработать блок с командами, когда понадобится*/}
                        {withTeams && (
                            <div
                                className={classnames('', {
                                    'PersonSelectorSection--hidden': menuId !== menuIds.teams,
                                })}
                            >
                                <Teams />
                            </div>
                        )}
                        <div
                            className={classnames('', {
                                'PersonSelectorSection--hidden': menuId !== menuIds.wishlists,
                            })}
                        >
                            <Wishlists
                                isSelectedPerson={this.isSelectedPerson}
                                setSelected={this.setSelected}
                                selected={selected}
                            />
                        </div>
                        <div
                            className={classnames('', {
                                'PersonSelectorSection--hidden': menuId !== menuIds.search,
                            })}
                        >
                            <Search
                                isSelectedPerson={this.isSelectedPerson}
                                setSelected={this.setSelected}
                                selected={selected}
                            />
                        </div>
                    </div>
                </ModalDialog>
            </div>
        );
    }
}

PersonSelector.defaultProps = {
    withTeams: false,
    visible: false,
    setRecipients: () => {},
    recipients: [],
};

PersonSelector.propTypes = {
    withTeams: PropTypes.bool,
    setRecipients: PropTypes.func,
    recipients: PropTypes.array,
    recipientsMeta: PropTypes.object,
    recipientsUpdated: PropTypes.any,
    getRecipients: PropTypes.func,
    recipientsPaging: PropTypes.object,
    massEditRecipients: PropTypes.bool,
    setAddMenuInfo: PropTypes.func,
};

export default PersonSelector;
