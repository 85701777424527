import { useCallback, useEffect, useRef, useState } from 'react';

const callEffectWithLoader = async (loaderProps, callback) => {
    loaderProps.showPageLoader();
    try {
        return await callback();
    } catch (error) {
        loaderProps.showErrorAlert(error.message);
    } finally {
        loaderProps.hidePageLoader();
    }
};

export const useRefState = initialValue => {
    const [state, setState] = useState(initialValue);
    const stateRef = useRef(state);
    useEffect(() => {
        stateRef.current = state;
    }, [state]);
    return [state, stateRef, setState];
};

export const useAsyncEffect = (effectAsyncCallback, deps) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                setData(await effectAsyncCallback());
            } catch (error) {
                setError(error);
            }
            setIsLoading(false);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [effectAsyncCallback, ...deps]);

    return { data, isLoading, error };
};

export function usePrevious(value) {
    const ref = useRef([]);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const useAsyncEffectWithLoader = (loaderProps, effectCallback, deps) => {
    const { showErrorAlert, showPageLoader, hidePageLoader } = loaderProps;

    const callback = useCallback(
        () => callEffectWithLoader(loaderProps, effectCallback),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [showErrorAlert, showPageLoader, hidePageLoader, effectCallback],
    );

    return useAsyncEffect(callback, deps);
};
