import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';
import ProfileButton from '../Buttons/Button/ProfileButton';

const FileField = props => {
    const { onFileAdd, fileId, getFileInfo, disabled, getLink = () => '#' } = props;

    const [fileInfo, setFileInfo] = useState();
    const [id, setId] = useState(null);

    useEffect(() => {
        let mounted = true;
        
        const loadInfo = async () => {
            const resp = await getFileInfo(fileId);
            mounted && setId(fileId);
            mounted && setFileInfo(resp.data);
        };

        fileId && fileId !== id && loadInfo();
        
        return () => { mounted = false; }
    }, [id, fileId, getFileInfo]);

    const renderDropZone = ({ getRootProps, getInputProps, isDragActive }) => (
        <div
            {...getRootProps()}
            className={classnames('dropzone', {
                'dropzone--isActive': isDragActive,
            })}
        >
            <input {...getInputProps()} />
            <ProfileButton noPaddings>{fileInfo ? fileInfo.name : 'добавить'}</ProfileButton>
        </div>
    );

    return disabled ? (
        <div>
            {fileInfo && fileInfo.name ? (
                <a href={fileId && getLink(fileId)}>{fileInfo.name}</a>
            ) : (
                '-'
            )}
        </div>
    ) : (
        <Dropzone disabled={disabled} onDrop={onFileAdd}>
            {renderDropZone}
        </Dropzone>
    );
};

export default FileField;
