import React from 'react';
import './Condition.scss';
import ConditionTable from './ConditionTable';
import uuid from 'uuid/v4';
import TreeView from 'components/uikit/TreeView';
import { operationTypes, conditionTypes } from 'enums';

export const Condition = props => {
    const { data = [], onChangeData, filters } = props;

    const handleAddCondition = item => {
        const newCondition = {
            id: uuid(),
            field: item.value,
            name: item.title,
            code: item.code,
            type: item.type,
            dict: item.dict,
            operation: '=',
            active: true,
        };
        onChangeData(data.concat(newCondition));
    };

    const onChange = record => {
        onChangeData(data.map(item => (item.id === record.id ? record : item)));
    };

    const getLabel = (id, arr) => (id ? arr.filter(i => i.id === id)[0].name : '?');
    const renderText =
        data &&
        data
            .filter(exp => exp.active)
            .map(
                item =>
                    `${item.name} ${getLabel(item.operation, operationTypes)} (${item.value ||
                        '?'}), ${getLabel(item.condition, conditionTypes)} `,
            );

    const items = (filters && filters.items) || [];
    return (
        <div className="Condition">
            <div className="Condition__List">
                <TreeView labelField="title" onClick={handleAddCondition} items={items} />
            </div>
            <div className="Condition__Table">
                <p className="Condition__Info">{renderText}</p>
                <ConditionTable data={data} onChange={onChange} />
            </div>
        </div>
    );
};

export default Condition;
