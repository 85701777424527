import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './ChevronButton.scss';

function ChevronButton(props) {
    const { isUp, className, color, ...tail } = props;

    const buttonClass = classNames(
        'LKChevronButton',
        { 'LKChevronButton--open': isUp, 'LKChevronButton--blue': color === 'blue' },
        className,
    );

    return <span className={buttonClass} {...tail} />;
}

ChevronButton.defaultProps = {
    color: '',
};

ChevronButton.propTypes = {
    color: PropTypes.string,
};

export default ChevronButton;
