import React, { Component } from 'react'
import { connect } from 'react-redux';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import moment from 'moment';
import { getCompetencies, updatePersonGrowthPlan, getPersonTeams } from 'api';
import CurrentCompetencies from '../../common/PersonGrowthPlan/CurrentCompetencies/CurrentCompetencies';
import TargetCreation from '../../common/PersonGrowthPlan/TargetCreation/TargetCreation';
import { ToolTypeSelectorRecommendation } from '../../common/PersonGrowthPlan/ToolTypeSelector/ToolTypeSelector';
import CompetencyPersonHelp from '../../common/PersonGrowthPlan/CompetencyPersonHelp/CompetencyPersonHelp';
import CompetencyObjectHelp from '../../common/PersonGrowthPlan/CompetencyObjectHelp/CompetencyObjectHelp';
import PersonGrowthEndDate from '../../common/PersonGrowthPlan/PersonGrowthEndDate/PersonGrowthEndDate';
import Button from "components/uikit/Button";
import * as utils from "components/PersonGrowthPlan/utils";
import { assistanceType } from './progressHelper';
import ApproversSelection from "../../common/PersonGrowthPlan/ApproversSelection";
import { serviceResultCode, getCode } from 'serviceErrors';
import { push } from 'connected-react-router';
import { personProgress } from 'routes';

class PersonPlanEdit extends Component {
    state = {
        plan: {},
        loaded: false,
        planGrowthtype: 1,
        existingPlan: true,
        approvers: [],
    };

    handleStateChange = (blockStateKey, value) => {
        this.setState(state => {
            const plan = {
                ...state.plan,
                [blockStateKey]: value,
            }
            return {
                ...state,
                plan: { ...plan },
            }
        });
    }

    checkDateChanges = () => {
        const { plan, initialEndMonth, initialEndYear } = this.state;
        return (plan.end_month === initialEndMonth && plan.end_year === initialEndYear) ? true : this.validPlanDate();
    }

    isFormValid = () => {
        const { plan } = this.state;
        const isValid = plan.competencyIds.length !== 0 &&
                        !!plan.title.trim() &&
                        this.validateAssistantFields() &&
                        (plan.resources &&  plan.resources.length !== 0) &&
                        (plan.personGrowthEvents.length === 0 || !this.validPersonGrowthEvents(plan.personGrowthEvents)) &&
                        (plan.end_year.value !== "" && plan.end_month.value !== "" && this.checkDateChanges());
        return isValid;
    }

    validPlanDate = () => utils.validPlanDate(this.state.plan);

    validPersonGrowthEvents = (data) => data.find(x => !x.type || !x.assistanceDetails);

    validateAssistantFields = () => {
        const [assistant] = this.state.plan.personGrowthAssistants;
        if (    !assistant ||
                (assistant && !assistant.fullName && !assistant.assistanceDetails && !assistant.position) ||
                (assistant && assistant.fullName && assistant.fullName.trim()
                && assistant.assistanceDetails && assistant.assistanceDetails.trim()
                && assistant.position && assistant.position.trim()) 
            ) { return true; }
        return false;
    };

    buildEndDate = () => utils.buildEndDate(this.state.plan);

    onSave = (save = false) => {
        this.callEffect(async () => {
            const statePlan = this.state.plan;
            const planToSave = {
                ...statePlan, 
                title: statePlan.title,
                personId: statePlan.personId,
                expectedResult: statePlan.expectedResult,
                target: statePlan.target,
                targetType: statePlan.targetType,
                resourceIds: statePlan.resources.map(x => x.id),
                personGrowthEvents: statePlan.personGrowthEvents,
                personGrowthAssistants: statePlan.personGrowthAssistants,
                type: "Full",
                competencyIds: statePlan.competencyIds,
                start: statePlan.start,
                end: this.buildEndDate(),
                approverIds: this.state.plan.approverIds,
            };
            save && await updatePersonGrowthPlan(planToSave);
            this.props.updateData();
        });
    };
    
    render() {
        const { plan, loaded } = this.state;

        if (!loaded) { return null; }
        
        return (   
            <div className="EditPlan">
                <div className="EditPlan__Header">Текущие компетенции</div>
                <div className="EditPlan__Section-Error">{this.state.plan.competencyIds.length === 0 && `Необходимо выбрать компетенции`}</div>
                <CurrentCompetencies
                    editMode
                    isValid={this.state.plan.competencyIds.length !== 0}
                    {...this.state}
                    competencies={this.state.plan.competencyInfo}
                    onChange={this.handleStateChange}
                />
                <div className="EditPlan__Header">Создание цели</div>
                <div className="EditPlan__Section-Error">{!this.state.plan.title.trim() && `Не указана цель`}</div>
                <TargetCreation
                    editMode
                    isValid={!!this.state.plan.title}
                    onChange={this.handleStateChange}
                    title={plan.title}
                    expectedResult={plan.expectedResult} 
                />
                <div className="EditPlan__Header">Выбор инструментов</div>
                <ToolTypeSelectorRecommendation 
                    editMode
                    onChange={this.handleStateChange}
                    competencyIds={plan.competencyIds}
                    resourceIds={plan.resourceIds}
                    resources={plan.resources}
                    filteredResources={plan.filteredResources}
                    filteredByTypeResources={plan.filteredByTypeResources}
                    filteredByCategoryResources={plan.filteredByCategoryResources}
                />
                <div className="EditPlan__Header">Кто поможет в развитии компетенции</div>
                <div className="EditPlan__Section-Error">{!this.validateAssistantFields() && `Заполните блок`}</div>
                <CompetencyPersonHelp
                    editMode
                    isValid={this.validateAssistantFields()}
                    personGrowthAssistants={plan.personGrowthAssistants}
                    onChange={this.handleStateChange}
                />
                <div className="EditPlan__Header">Что поможет в развитии компетенции</div>
                <div className="EditPlan__Section-Error">{(!this.state.plan.personGrowthEvents.length !== 0 && !!this.validPersonGrowthEvents(this.state.plan.personGrowthEvents)) && `Блок не заполнен`}</div>
                <CompetencyObjectHelp
                    editMode
                    isValid={this.state.plan.personGrowthEvents.length === 0 || this.validPersonGrowthEvents(this.state.plan.personGrowthEvents)}
                    assistanceType={assistanceType}
                    onChange={this.handleStateChange}
                    personGrowthEvents={plan.personGrowthEvents}
                />
                <div className="EditPlan__Header">Дата завершения</div>
                <div className="EditPlan__Section-Error">{!this.checkDateChanges() && `Некорректная дата`}</div>
                <PersonGrowthEndDate
                    editMode
                    onChange={this.handleStateChange}
                    end_year={plan.end_year}
                    end_month={plan.end_month}
                />
                <div className="EditPlan__Header">Согласование плана</div>
                <div className="EditPlan__Section-Error">{(this.state.approvers.length === 0 && 
                    'Резервист не состоит ни в одной команде.')}</div>
                <ApproversSelection
                    editMode
                    onChange={this.handleStateChange}
                    plan={this.state.plan}
                    approvers={this.state.approvers}
                />
                <div className="EditPlan__ButtonGroup">
                    <Button size="sm" color="default" onClick={() => this.onSave(false)}>Отмена</Button>
                    <Button size="sm" onClick={() => this.onSave(true)} disabled={!this.isFormValid()} >Сохранить план развития</Button>
                </div>        
            </div>
        );
    }

    componentDidMount() {
        const { personId, planId, getPersonGrowthPlan} = this.props;

        this.callEffect(async () => {
            const competencies = await getCompetencies(this.props.personId);
            const loadedPlan = await getPersonGrowthPlan(planId);
            const teams = (await getPersonTeams(personId)).data;
            let leaders = teams && teams.following && Array.isArray(teams.following) &&
                teams.following.map(x => x.leaderPerson);

            if (leaders && Array.isArray(leaders) && leaders.length > 0) {
                
                leaders = leaders.map(x => {
                    const approveFound = loadedPlan.data && loadedPlan.data.approves.find(i => i.approverId === x.userId);
                    return {...x, status: approveFound && approveFound.status}
                });
            }
            
            const approversCancelled = loadedPlan.data.approves.filter(x => x.status === 'Cancelled')
                .map(x => x.approverId);

            const plan = loadedPlan.data;
            const modifiedPlan = {
                id: planId,
                competencies: competencies.data,
                title: plan.title,
                type: "Full",
                target: "",
                targetType: "",
                personGrowthEvents: plan.personGrowthEvents,
                personGrowthAssistants: plan.personGrowthAssistants,
                filteredByCategoryResources: [],
                filteredByTypeResources: [],
                resources: plan.resources,
                start: plan.start,
                end_year: moment(plan.end).year(),
                end_month: moment(plan.end).month(),
                competencyIds: plan.competencyIds,
                expectedResult: plan.expectedResult,
                personId: personId,
                event_info: "",
                end: plan.end,
                approverIds: plan.approverIds.filter(x => !approversCancelled.includes(x)),
                approves: plan.approves,
                isApproved: plan.isApproved,
            };

            this.setState(state => {
                return {
                    ...state,
                    loaded: true,
                    competencyInfo: competencies.data,
                    plan: modifiedPlan,
                    initialEndYear: moment(plan.end).year(),
                    initialEndMonth: moment(plan.end).month(),
                    approvers: leaders,
                }
            })
        })
    }

    callEffect = async callback => {
        this.props.showPageLoader();
        try {
            await callback();
        } catch (error) {
            if (getCode(error) === serviceResultCode.PersonGrowthPlanApprovedUpdateError) {
                this.props.showErrorAlert('Согласованный план развития не может быть изменен.');
                this.props.push(personProgress.buildUrl({ id: this.props.personId }));
            } else {
                this.props.showErrorAlert(error.message);
            }
        } finally {
            this.props.hidePageLoader();
        }
    };    
}

const actions = { showPageLoader, hidePageLoader, showErrorAlert, push };

export default connect(null, actions)(PersonPlanEdit);