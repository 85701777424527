import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import classnames from 'classnames';
import Popover from 'components/Lk/Uikit/Popover';
import Icons from 'components/Lk/Uikit/Icons';
import './Button.scss';

function Button(props) {
    const {
        children,
        color = 'primary',
        hasGlow,
        className,
        faIcon,
        disabled,
        loading,
        link,
        closeLink,
        addLink,
        editLink,
        clearLink,
        size,
        buttonText,
        tooltip = null,
        tooltipHeader,
        tooltipPlacement,
        autoPlacement = false,
        svgIcon,
        ...tail
    } = props;

    function getColorClassName(color) {
        switch (color) {
            case 'default':
            case 'secondary':
                return 'CommonButton--secondary';
            case 'light_primary':
                return 'CommonButton--light-primary';
            case 'danger':
                if (hasGlow) {
                    return ' CommonButton--danger CommonButton--danger-glow';
                }
                return 'CommonButton--danger';
            default:
                if (hasGlow) {
                    return 'CommonButton--primary-glow';
                }
                return '';
        }
    }

    const finalClass = classnames('CommonButton', {
        className: className,
        [`${className}`]: className,
        [`CommonButton--size_${size}`]: size,
        'CommonButton--link-button-close': closeLink,
        'CommonButton--link-button-add': addLink,
        'CommonButton--link-button-edit': editLink,
        'CommonButton--link-button-clear': clearLink,
        [getColorClassName(color)]: color || hasGlow,
        'CommonButton--disabled': disabled || loading,
    });

    const iconClassName =
        children && children.length !== 0 ? ' CommonButton--icon-with-content' : '';

    const contents = loading ? (
        <>
            <div className="CommonButton__Loader">
                <FontAwesomeIcon icon="spinner" size="2x" spin />
            </div>
            {` ${buttonText || ''}`}
            {children}
        </>
    ) : (
        <>
            {faIcon && (
                <span>
                    <FontAwesomeIcon className={iconClassName} icon={faIcon} />
                </span>
            )}
            {svgIcon && (
                <span>
                    <Icons name={svgIcon.name} fill={svgIcon.fill} />
                </span>
            )}
            <span>{buttonText}</span>
            {children}
        </>
    );

    if (link) {
        return (
            <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className={finalClass}
                disabled={disabled || loading}
                {...tail}
            >
                {contents}
            </a>
        );
    }

    if (tooltip) {
        const getFinalTooltip = (tooltip, tooltipHeader) => {
            return tooltipHeader ? (
                <span>
                    <div className="CommonButton__TooltipHeader">{tooltipHeader}</div>
                    <div className="CommonButton__TooltipText">{tooltip}</div>
                </span>
            ) : (
                tooltip
            );
        };

        return (
            <Popover
                childrenClassName={'InlineFlex'}
                tooltip={tooltip ? getFinalTooltip(tooltip, tooltipHeader) : null}
                tooltipPlacement={tooltipPlacement}
                autoPlacement={autoPlacement}
            >
                <button className={finalClass} disabled={disabled || loading} {...tail}>
                    {contents}
                </button>
            </Popover>
        );
    }

    return (
        <button className={finalClass} disabled={disabled || loading} {...tail}>
            {contents}
        </button>
    );
}

export default Button;
