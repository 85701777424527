import { appName } from '../constants';
import { takeLatest, all, put } from 'redux-saga/effects';
import { showErrorAlert } from './Alert';
import RequestError from 'RequestError';

const moduleName = 'progressTeams';
const SET = `${appName}/${moduleName}/SET`;
const ADD = `${appName}/${moduleName}/ADD`;
const REMOVE = `${appName}/${moduleName}/REMOVE`;
const CLEAR = `${appName}/${moduleName}/CLEAR`;

const initialState = {
    persons: [],
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ADD:
            return {
                ...state,
                persons: [...state.persons, action.payload]
            };
        case REMOVE:
            return {
                ...state,
                persons: [...state.persons.filter(x => x.person.id !== action.payload.person.id)]
            };            
        case CLEAR:
            return initialState;
        default:
            return state;
    }
}

export const clearCart = () => ({type: CLEAR });

export const setProgressCartPersons = payload => ({ type: SET, payload });
const addProgressCartPersons = payload => ({ type: ADD, payload });
const removeProgressCartPersons = payload => ({ type: REMOVE, payload });

function* setCartPersonsSaga({ payload }) {
    try {
        payload.action === 'ADD' 
            ?  yield put(addProgressCartPersons(payload))
            :  yield put(removeProgressCartPersons(payload))
       
    } catch(error) {
        const reqError = new RequestError(error, 'Ошибка добавления / удаления резервиста');
        yield put(showErrorAlert(reqError.message));
    }
}


export function* saga() {
    yield all([takeLatest(SET, setCartPersonsSaga)]);
}
