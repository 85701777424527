import React from 'react';
import ToggleOpen from 'components/hoc/ToggleOpen';
import { trimStart } from 'utils';
import { DataCard } from "components/common/DataCard";
import EditSocialNetworksBlock from './Edit/SocialNetworksBlock';

const SocialNetworksBlock = props => {
    function getSocialNetworkUrl(value) {
        value = trimStart(value, 'http://');
        value = trimStart(value, 'https://');
        return `https://${value}`;
    }

    function renderSocialNetwork(networkInfo) {
        const { network, value } = networkInfo;
        
        const url = getSocialNetworkUrl(value);

        return (
            <a
                key={network.id} 
                href={url} 
                className={"kr_" + network.code}
            >
                {" "}
            </a>
        );
    }

    function renderView() {
        return (
            <DataCard 
                shadow
                title="Социальные сети" 
                className="kr_profile-anketa-view Person__SocialNetworkBlock"
                onChange={ props.canEditPerson ? () => props.setEditBlock('SocialNetworksBlock') : null}
            >
                <div className="kr_like-table">
                    {!!props.networks.size &&
                        <div className="kr_tr">
                            <div className="kr_td kr_item_socials">
                                {props.networks.map(renderSocialNetwork)}
                            </div>
                        </div>
                    }
                    {(!props.networks || !props.networks.size) ? props.renderEmptyBlock() : null}
                </div>
            </DataCard>
        );
    }

    function renderEdit() {
        return (
            <EditSocialNetworksBlock {...props} setEditBlock={props.setEditBlock} />
        );
    }

    return props.isEditBlock ? renderEdit() : renderView();
}

export default ToggleOpen(SocialNetworksBlock);
