import React, { Component } from 'react';
import { connect } from 'react-redux';
import Page, { PageTitle } from 'components/common/Page';
import { TabletButton } from 'components/uikit/Button';
import { fetchDictionaries } from 'ducks/Dictionary';
import { GROUPS_DICTIONARY_NAME } from 'constants.js';
import { canModerateQuotas, canModerateEvaluations, canEditInstruction, canEditReport } from 'rightsController.js';
import './ModerationWelcome.scss';
import { instructionsEdit, teamReport } from 'routes';
import { isResourceProvidersEnabled } from 'ducks/Config';

class ModerationWelcome extends Component {
    componentDidMount() {
        this.props.loadGroups();
    }

    render() {
        const { user, groups, resourceProvidersEnabled } = this.props;
        const group = groups && groups.find(g => g.id === user.groupId);
        const groupName = group ? group.name : '';

        return (
            <Page className="ModerationWelcome" theme="primary">
                <PageTitle theme="primary">Региональный координатор группы &laquo;{groupName}&raquo;</PageTitle>
                <ul className="ModerationWelcome__List">
                    {canModerateQuotas(user) && (
                        <li className="ModerationWelcome__Item">
                            <TabletButton
                                theme="link"
                                icon="cogs"
                                to="/moderation/quotas"
                                data-test="ModerationQuotas--button"
                            >
                                Модерация квот
                            </TabletButton>
                        </li>
                    )}
                    {canModerateEvaluations(user) && (
                        <li className="ModerationWelcome__Item">
                            <TabletButton
                                theme="link"
                                icon="chart-line"
                                to="/moderation/evaluations"
                                data-test="ModerationEvaluation--button"
                            >
                                Разрешение на оценку
                            </TabletButton>
                        </li>
                    )}
                    <li className="ModerationWelcome__Item">
                        <TabletButton
                            theme="link"
                            icon="tools"
                            to={{
                                pathname: "/moderation/personGrowthResources",
                                search: "?page=1"
                            }}
                            data-test="ModerationPersonGrowthResources--button"
                        >
                            Инструменты развития
                        </TabletButton>
                    </li>
                    <li className="ModerationWelcome__Item">
                        <TabletButton
                            theme="link"
                            icon="tags"
                            to="/moderation/personGrowthTags"
                            data-test="ModerationPersonGrowthTags--button"
                        >
                            Теги
                        </TabletButton>
                    </li>
                    <li className="ModerationWelcome__Item">
                        <TabletButton
                            theme="link"
                            icon="address-card"
                            to="/moderation/models"
                            data-test="ModerationModels--button"
                        >
                            Модели компетенций
                        </TabletButton>
                    </li>
                    <li className="ModerationWelcome__Item">
                        <TabletButton
                            theme="link"
                            icon="edit"
                            to="/moderation/templates"
                            data-test="ModerationTemplates--button"
                        >
                            Редактор писем
                        </TabletButton>
                    </li>
                    <li className="ModerationWelcome__Item">
                        <TabletButton
                            theme="link"
                            icon="envelope-open-text"
                            to="/moderation/templatesTypes"
                            data-test="ModerationTemplatesTypes-button"
                        >
                            Выбор шаблона письма
                        </TabletButton>
                    </li>
                    <li className="ModerationWelcome__Item">
                        <TabletButton
                            theme="link"
                            icon="tasks"
                            to="/moderation/tests"
                            data-test="ModerationTests--button"
                        >
                            Редактор тестов
                        </TabletButton>
                    </li>
                    <li className="ModerationWelcome__Item">
                        <TabletButton
                            theme="link"
                            icon="address-card"
                            to="/moderation/listManagers"
                            data-test="ModerationListManagers--button"
                        >
                            Cписок руководителей
                        </TabletButton>
                    </li>
                    {resourceProvidersEnabled && 
                        <li className="ModerationWelcome__Item">
                            <TabletButton
                                theme="link"
                                icon="chalkboard-teacher"
                                to="/moderation/resourceProviders"
                                data-test='ModerationResourceProviders--button'
                            >
                                Провайдеры
                            </TabletButton>
                        </li>}
                    {/*TODO: Реанимировать после возобновления задачи с новостями*/}
                    {/* <li className="ModerationWelcome__Item">
                        <TabletButton theme="link" icon="newspaper" to="/moderation/news" data-test='ModerationNews--button'>
                            Новости
                        </TabletButton>
                    </li> */}
                    {canEditInstruction(user) && (
                        <li className="ModerationWelcome__Item">
                            <TabletButton
                                theme="link"
                                icon="file"
                                to={instructionsEdit.url}
                                data-test="ModerationInstructions--button"
                            >
                                Редактор инструкций
                            </TabletButton>
                        </li>
                    )}
                    <li className="ModerationWelcome__Item">
                        <TabletButton
                            theme="link"
                            icon="mail-bulk"
                            to="/moderation/massEmail"
                            data-test="ModerationResourceProviders--button"
                        >
                            Письма
                        </TabletButton>
                    </li>
                    {canEditReport(user) && (
                        <li className="ModerationWelcome__Item">
                            <TabletButton theme="link" icon="tasks" to={teamReport.url} data-test='ModerationTeamReport--button'>
                                Командный отчет
                            </TabletButton>
                        </li>
                    )}
                </ul>
            </Page>
        );
    }
}


const mapStateToProps = state => ({
    user: state.auth.user,
    groups: state.dictionary.groups.dictionary,
    resourceProvidersEnabled: isResourceProvidersEnabled(state),
});

const mapDispatchToProps = dispatch => ({
    loadGroups: () => dispatch(fetchDictionaries([GROUPS_DICTIONARY_NAME])),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ModerationWelcome);
