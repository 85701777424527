import {
    ROLE_IT_ADMIN, 
    ROLE_MODERATOR, 
    ROLE_OPERATOR, 
    ROLE_SECURITY_ADMIN,
    ROLE_LEADER,
    ROLE_INSTRUCTION_EDITOR, 
    ROLE_USER, 
    ROLE_CONTENT_EDITOR,
    ROLE_SUPPORT_LINE1,
    ROLE_SUPPORT_LINE2,
    USER_INTERFACE_LEADER,
    USER_INTERFACE_MODERATOR,
    ROLE_REPORT_EDITOR
} from 'constants.js';

export const ALLOWED_EDIT_PERSON_ROLES = [ROLE_MODERATOR, ROLE_OPERATOR];
export const ALLOWED_IMPORT_ROLES = [ROLE_MODERATOR];
export const ALLOWED_USE_EXTERNAL_SEARCH = [ROLE_LEADER];
export const ALLOWED_MODERATE_QUOTAS = [ROLE_MODERATOR];
export const ALLOWED_MODERATE_ROLES = [ROLE_MODERATOR, ROLE_OPERATOR, ROLE_USER];
export const ALLOWED_MODERATE_EVALUATIONS = [ROLE_MODERATOR];
export const ALLOWED_MODERATE_PERSONGROWTHRESOURCES = [ROLE_MODERATOR];
export const ALLOWED_MODERATE_PERSONGROWTHRESOURCE = [ROLE_MODERATOR];
export const ALLOWED_MODERATE_MODELS = [ROLE_MODERATOR];
export const ALLOWED_MODERATE_TESTS = [ROLE_MODERATOR];
export const ALLOWED_MODERATE_PERSONGROWTHTAGS = [ROLE_MODERATOR];
export const ALLOWED_MODERATE_PERSONGROWTHTAG = [ROLE_MODERATOR];
export const ALLOWED_MODERATE_TEMPLATES = [ROLE_MODERATOR];
export const ALLOWED_MODERATE_NOTIFICATION_TYPES = [ROLE_MODERATOR];
export const ALLOWED_LK_ROLES = [ROLE_LEADER];
export const ALLOWED_PERSONNELS_ROLES = [ROLE_MODERATOR, ROLE_OPERATOR, ROLE_LEADER, ROLE_USER];

export const ALLOWED_MODERATOR_INTERFACE = [ROLE_MODERATOR, ROLE_OPERATOR, ROLE_USER, ROLE_CONTENT_EDITOR, ROLE_INSTRUCTION_EDITOR];

export const ALLOWED_EDIT_CONTENT = [ROLE_CONTENT_EDITOR];
export const ALLOWED_EDIT_INSTRUCTION = [ROLE_INSTRUCTION_EDITOR];
export const ALLOWED_EDIT_REPORT = [ROLE_REPORT_EDITOR];

export const canEditPerson = (user) => {
    return user.roles.filter((x) => ALLOWED_EDIT_PERSON_ROLES.includes(x.name)).length > 0;
};

export const canEditFromModeratorInterface = (user) => {
    return user.roles.filter((x) => ALLOWED_MODERATE_ROLES.includes(x.name)).length > 0;
};

export const canUsePersonnel = (user) => user.roles.filter((x) => ALLOWED_PERSONNELS_ROLES.includes(x.name)).length > 0;

export const canEditContent = (user) => user.roles.filter((x) => ALLOWED_EDIT_CONTENT.includes(x.name)).length > 0;

export const canEditInstruction = (user) => user.roles.filter((x) => ALLOWED_EDIT_INSTRUCTION.includes(x.name)).length > 0;

export const canEditReport = (user) => user.roles.filter((x) => ALLOWED_EDIT_REPORT.includes(x.name)).length > 0;

export const canModerate = (user) => isModerator(user);

export const canModerateQuotas = (user) => {
    return user.roles.filter((x) => ALLOWED_MODERATE_QUOTAS.includes(x.name)).length > 0;
};

export const canModerateEvaluations = (user) => {
    return user.roles.filter((x) => ALLOWED_MODERATE_EVALUATIONS.includes(x.name)).length > 0;
};

export const canImportPerson = (user) => {
    return user.roles.filter((x) => ALLOWED_IMPORT_ROLES.includes(x.name)).length > 0;
};

export const isITAdmin = (user) => {
    return user.roles.find(r => r.name === ROLE_IT_ADMIN) != null;
};

export const isSecurityAdmin = (user) => {
    return user.roles.find(r => r.name === ROLE_SECURITY_ADMIN) != null;
};

export const isSupportLine1 = (user) => {
    return user.roles.find(r => r.name === ROLE_SUPPORT_LINE1) != null;
};

export const isSupportLine2 = (user) => {
    return user.roles.find(r => r.name === ROLE_SUPPORT_LINE2) != null;
};

export const isAdmin = (user) => {
    return isITAdmin(user) || isSecurityAdmin(user) || isSupportLine1(user) || isSupportLine2(user);
};

export const isInstructionEditor = (user, isOnce) => hasRole(user, ROLE_INSTRUCTION_EDITOR) && (!isOnce || user.roles.length === 1);

export const isContentEditor = (user, isOnce) => hasRole(user, ROLE_CONTENT_EDITOR) && (!isOnce || user.roles.length === 1);

export const hasRole = (user, roleName) => {
    return user.roles.find(r => r.name === roleName) != null;
};

export const isLeader = (user) => {
    return user.roles.find(r => r.name === ROLE_LEADER) != null;
};

export const canUseModeratorInterface = (user) => {
    return user.roles.filter((x) => ALLOWED_MODERATOR_INTERFACE.includes(x.name)).length > 0;
};

export const canSelectInterface = (user) => {
    return isLeader(user) && canUseModeratorInterface(user);
};

export const isSelectLeaderInterface = (user) => {
    if (user.roles.length === 1)
        return true;
    
    if (canSelectInterface(user)) {
        if (user.selectedInterface === USER_INTERFACE_LEADER) {
            return true;
        }
    }
    
    return false;
};

export const isSelectModeratorInterface = (user) => {
    if (user.roles.length === 1)
        return true;

    if (canSelectInterface(user)) {
        if (user.selectedInterface === USER_INTERFACE_MODERATOR) {
            return true;
        }
    }

    return false;
};

export const isModerator = user => user.roles.find(r => r.name === ROLE_MODERATOR) != null;
export const isOperator = user => user.roles.find(r => r.name === ROLE_OPERATOR) != null;
export const isUser = user => user.roles.find(r => r.name === ROLE_USER) != null;