import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import Page from 'components/common/Page';
import StepWizard from 'components/common/StepWizard';
import ModalDialog from 'components/common/ModalDialog';

import { showErrorAlert, showWarningAlert } from 'ducks/Alert';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';

import CompetenciesSelector from './ComptenciesSelector/ComptenciesSelector';
import TargetCreation from './TargetCreation/TargetCreation';
import ResourceSelector from './ResourceSelector/ResourceSelector';
import EventDateCreation from './EventDateCreation/EventDateCreation';

import { getCompetencies, getEducationEventByIdForPerson, createEducationEvent, updateEducationEvent, getPersonGrowthResourceSchedule } from 'api';
import { person } from 'routes';

import './EducationEvent.scss';

const initialEducationEvent = {
    id: null,
    createdOn: null,
    target: '',
    expectedResult: '',
    status: null,
    educationStart: null,
    educationEnd: null,
    personId: null,
    scheduleId: null,
    resourceId: null,
    competencyIds: [],
    resource: null,
};

const EducationEvent = props => {
    const [loaded, setLoaded] = useState(false);
    const [educationEvent, setEducationEvent] = useState(initialEducationEvent);
    const [competencyInfo, setCompetencyInfo] = useState(null);
    const [wizard, setWizard] = useState(null);
    const [stepId, setStepId] = useState('');
    const [isOpenClearModal, setIsOpenClearModal] = useState(false);
    const [isSubmited, setSubmitedState] = useState(false);
    const [scheduleRecord, setScheduleRecord] = useState(null);
    
    const personId = props.match.params.id;
    const eventId  = props.match.params.eventId;
    const mode   = props.match.params.mode;
    const resourceId = educationEvent.resourceId;
    const scheduleId = educationEvent.scheduleId;
    
    useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            try {
                showPageLoader();

                const competencyData = await getCompetencies(personId);
                mounted &&
                    competencyData &&
                    competencyData.data &&
                    setCompetencyInfo(competencyData.data);

                if (mode === 'new') {
                    setLoaded(true);
                } else {
                    const educationEventData = await getEducationEventByIdForPerson(
                        personId,
                        eventId,
                    );
                    mounted &&
                        educationEventData &&
                        educationEventData.data &&
                        setEducationEvent(educationEventData.data);
                    setLoaded(true);
                }
            } catch (error) {
                showErrorAlert(error.message);
            } finally {
                hidePageLoader();
            }
        };
        fetchData();

        return () => {
            mounted = false;
        };
    }, [personId, eventId, mode]);
    
    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            try {
                showPageLoader();
                if (resourceId && scheduleId) {
                    const scheduleData = await getPersonGrowthResourceSchedule(resourceId);
                    if (mounted && scheduleData && scheduleData.data) {
                        setScheduleRecord(scheduleData.data.find(x => x.id === scheduleId));
                    }
                }
            } catch(error) {
                showErrorAlert(error.message);
            } finally {
                hidePageLoader();
            }
        };
        fetchData();

        return () => { mounted = false };
    }, [resourceId, scheduleId]);
    
    const handleScheduleRecordChange = (record) => {
       if (record) {
           setEducationEvent({...educationEvent,
               scheduleId: record.id,
               educationStart: record.subscriptionStarts,
               educationEnd: record.subscriptionEnds,
           });
           setScheduleRecord(record);
       } else {
           setEducationEvent({...educationEvent,
               scheduleId: null,
               educationStart: null,
               educationEnd: null,
           });
           setScheduleRecord(null);
       }

    };

    const renderCurrentCompetencies = () => {
        return (
            <CompetenciesSelector
                competencyInfo={competencyInfo}
                educationEvent={educationEvent}
                competencies={competencyInfo}
                onChange={handleStateChange}
                handleCompetencyModelChange={handleCompetencyModelChange}
                data={educationEvent.competencyIds}
            />
        );
    };

    const validateTargetCreation = () => !!educationEvent.target;
    const renderTargetCreation = () => {
        return (
            <TargetCreation
                onChange={handleStateChange}
                target={educationEvent.target}
                expectedResult={educationEvent.expectedResult}
                isValid={validateTargetCreation()}
                isEditMode={mode === 'edit'}
                isSubmited={isSubmited}
            />
        );
    };

    const validateResourceSelector = () => {
        return !!educationEvent.resourceId;
    };

    const renderResourceSelector = () => {
        return (
            <ResourceSelector
                competencyIds={educationEvent.competencyIds}
                resourceIds={educationEvent.resourceId}
                handleResourceChange={handleResourceChange}
                isValid={validateResourceSelector()}
            />
        );
    };

    const validateEducationStartDate = () => {

        const isEventStartsAfterSubscriptionStarts = () => {
            return moment(educationEvent.educationStart).startOf('day')
                .isSameOrAfter(moment(scheduleRecord.subscriptionStarts).startOf('day'), 'second');
        };

        if (scheduleRecord && scheduleRecord.subscriptionStarts && educationEvent.educationStart &&
            !isEventStartsAfterSubscriptionStarts() ) {
            return false;
        }
        return true;
    };

    const validateEducationEndDate = () => {

        const isEventEndsAfterEventStarts = () => {
            return moment(educationEvent.educationEnd).endOf('day')
                    .isAfter(moment(educationEvent.educationStart).startOf('day'), 'second');  
        };
        
        const isEventEndsSameOrBeforeSubscriptionEnds = () => {
            return moment(educationEvent.educationEnd).endOf('day')
                    .isSameOrBefore(moment(scheduleRecord.subscriptionEnds).endOf('day'), 'second');
        };

        const isEventEndsAfterNow = () => {
            return moment(educationEvent.educationEnd).endOf('day').isAfter(moment(),'second');
        };

        if (scheduleRecord && scheduleRecord.subscriptionEnds && educationEvent.educationStart && educationEvent.educationEnd && (
            !isEventEndsAfterEventStarts() ||
            !isEventEndsSameOrBeforeSubscriptionEnds() ||
            !isEventEndsAfterNow() )) {
            return false;
        }
        return true;
    };

    const isCourseNotFinished = () => {

        const isNowBeforeSubscriptionEnds = () => {
            return moment().isBefore(moment(scheduleRecord.subscriptionEnds).endOf('day'), 'second');
        };

        if (scheduleRecord && scheduleRecord.subscriptionEnds &&
            !isNowBeforeSubscriptionEnds() ) {
            return false;
        }
        return true;
    };

    const validateEventDatesCreation = () => {
        return !!educationEvent.educationStart && !!educationEvent.educationEnd && !!educationEvent.scheduleId &&
            validateEducationStartDate() && validateEducationEndDate() && isCourseNotFinished();
    };

    const renderEducationEventDateCreation = () => {
        return (
            <EventDateCreation
                scheduleId={educationEvent.scheduleId}
                resourceId={educationEvent.resourceId}
                educationStart={educationEvent.educationStart}
                educationEnd={educationEvent.educationEnd}
                onChange={handleStateChange}
                scheduleRecordChange={handleScheduleRecordChange}
                isValid={validateEventDatesCreation}
            />
        );
    };

    const onValid = exp => {
        setSubmitedState(true);

        if (!exp) {
            showWarningAlert('Не все поля корректно заполнены');
            return exp;
        }

        setSubmitedState(false);
        return exp;
    };

    const steps = [
        {
            id: 'competencies',
            title: 'Выбор компетенций',
            stepTitle: 'Выберите компетенции',
            description: '',
            content: renderCurrentCompetencies,
        },
        {
            id: 'title',
            title: 'Создание цели',
            stepTitle: 'Создайте цель',
            description: '',
            content: renderTargetCreation,
            isValid: validateTargetCreation,
        },
        {
            id: 'resource',
            title: 'Выбор программы',
            stepTitle: 'Выберите программу для обучения',
            description: '',
            content: renderResourceSelector,
            isValid: validateResourceSelector,
        },
        {
            id: 'date',
            title: 'Выбор даты обучения',
            stepTitle: 'Выберите даты обучения',
            description: '',
            content: renderEducationEventDateCreation,
            isValid: validateEventDatesCreation,
            nextText: "Сохранить план обучения"
        },
    ];

    const buildNewEducationEvent = () => {
        return {
            createdOn: new Date(),
            target: educationEvent.expectedResult,
            expectedResult: educationEvent.expectedResult,
            status: 'InProcess',
            educationStart: educationEvent.educationStart,
            educationEnd: educationEvent.educationEnd,
            personId: personId,
            scheduleId: educationEvent.scheduleId,
            resourceId: educationEvent.resourceId,
            competencyIds: educationEvent.competencyIds,
        };
    };

    const handleSave = () => {
        if (mode === 'new') {
            createEducationEvent(buildNewEducationEvent()).catch(() =>
                props.showErrorAlert('Во врeмя сохранения произошла ошибка', 3),
            );
        }

        if (mode === 'edit') {
            updateEducationEvent(educationEvent).catch(() =>
                props.showErrorAlert('Во врeмя сохранения произошла ошибка', 3),
            );
        }

        props.push(person.buildUrl({ id: personId }));
    };

    const handleCancel = () => setIsOpenClearModal(true);

    const handlePageChange = stepId => setStepId(stepId);

    const initialize = wizard => {
        setWizard({ ...wizard, initialized: true });
    };

    const renderModalOnCancel = () => {
        const onClearModal = () => {
            props.push(person.buildUrl({ id: props.match.params.id }));
        };

        const handleCloseModal = () => setIsOpenClearModal(false);

        return (
            <ModalDialog
                onClick={onClearModal}
                onCloseModal={handleCloseModal}
                modalOpen={isOpenClearModal}
                modalHeader="Вы действительно хотите отменить создание плана обучения?"
                btnOktext="Да"
                btnCanceltext="Нет"
                size="lg"
            ></ModalDialog>
        );
    };

    const handleStateChange = (blockStateKey, value) => {
        setEducationEvent({ ...educationEvent, [blockStateKey]: value });
    };
    
    const handleResourceChange = (resourceId) => {
        setEducationEvent({...educationEvent, resourceId, scheduleId: null, educationStart: null, educationEnd: null, resource: null});  
    };

    const handleCompetencyModelChange = value => {
        setEducationEvent({ ...educationEvent, competencyIds: [], currentCompetencyInfo: value });
    };

    return (
        <Page className="EducationEvent" w1790>
            {loaded && competencyInfo && (
                <StepWizard
                    stepId={stepId}
                    steps={steps}
                    showStepTextInHeader
                    disableTitle="Отмена"
                    disabledCancel={false}
                    onSave={handleSave}
                    onCancel={handleCancel}
                    onPreviousPage={handlePageChange}
                    onNextPage={handlePageChange}
                    wizard={wizard}
                    initialize={initialize}
                    onValid={onValid}
                />
            )}
            {renderModalOnCancel()}
        </Page>
    );
};

const actions = { showErrorAlert, showPageLoader, hidePageLoader, showWarningAlert, push };
export default connect(null, actions)(EducationEvent);
