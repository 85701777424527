import React from 'react';
import InfoField from 'components/Lk/Uikit/InfoField';
import HeaderStep from './HeaderStep';

const TitleStep = props => {
    const { changeHandler, currentPlan, submit } = props;

    const onChange = name => value => changeHandler(name, value);

    return (
        <>
            <HeaderStep
                title="2. Создание цели"
                descr="Перед формулированием цели задайте себе вопросы: Какие цели стоят передо мной в&nbsp;этом году? Чего я&nbsp;хочу добиться через 1&nbsp;год?"
            />
            <InfoField
                invalid={submit && !currentPlan.title}
                isEdit
                title="Цель развития"
                value={currentPlan.title}
                tooltip="Цель должна быть: Конкретная, достижимая, измеримая, реалистичная, значимая, определенная по времени"
                onChange={onChange('title')}
            />
            <InfoField
                isEdit
                title="Описание конечного результата"
                value={currentPlan.expectedResult}
                tooltip=" Опишите образ конечного результата, как Вы его себе представляете?
                Вопросы, которые можно себе задать:
                Что я делаю, когда цель достигнута?
                Что я теперь умею?
                Над какими проектами работаю?
                Какую должность я занимаю?"
                onChange={onChange('expectedResult')}
            />
        </>
    );
};

export default TitleStep;
