import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPersonGrowthResourceSchedule, deletePersonGrowthResourceSchedule } from 'api';
import { showErrorAlert } from 'ducks/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListTemplate from 'components/uikit/ListTemplate';
import DataGrid from 'components/common/DataGrid';
import EditScheduleRecord from './EditScheduleRecord';
import { formatPrice, formatDate } from 'utils';
import {serviceResultCode, getError} from 'serviceErrors';

import './ScheduleRecords.scss';
import Button from 'components/uikit/Button';
import ModalDialog from 'components/common/ModalDialog';

const initialState = {
    scheduleRecords: [],
    loaded: false,
    recordId: null,
    isOpenEditRecordModal: false,
    isOpenDeleteRecordModal: false,
    isDeleting: false,
    modalType: 'new',
};

class ScheduleRecords extends Component {
    state = initialState;

    componentDidMount() {
        this.performResourceScheduleFetching();
    }

    performResourceScheduleFetching = () => {
        this.callEffect(async () => {
            const scheduleResponse = await getPersonGrowthResourceSchedule(this.props.resourceId);

            if (scheduleResponse && scheduleResponse.status === 200) {
                this.setState({
                    scheduleRecords: scheduleResponse.data,
                    loaded: true,
                });
            }
        });
    };

    setStateOnCancelEditRecord = () => {
        this.setState({ isOpenEditRecordModal: false, modalType: 'new', recordId: null });
    };

    editEducation = id => () => {
        if (this.state.scheduleRecords.length) {
            this.setState({ recordId: id, modalType: 'edit', isOpenEditRecordModal: true });
        }
    };

    newEducation = () => {
        if (this.props.regime.includes('edit')) {
            this.setState({ recordId: null, modalType: 'new', isOpenEditRecordModal: true });
        }
    };

    deleteEducation = id => () => {
        if (this.state.scheduleRecords.length) {
            this.setState({ recordId: id, isOpenDeleteRecordModal: true });
        }
    };

    headBtn = {
        onClick: this.newEducation,
        value: 'Создать запись',
        addLink: true,
    };

    columns = [
        {
            Header: 'Начало обучения',
            accessor: 'subscriptionStarts',
            resizable: false,
            sortable: false,
            Cell: ({ original }) => formatDate(original.subscriptionStarts),
        },
        {
            Header: 'Окончание обучения',
            accessor: 'subscriptionEnds',
            resizable: false,
            sortable: false,
            Cell: ({ original }) => formatDate(original.subscriptionEnds),
        },
        {
            Header: 'Стоимость обучения, \u20BD',
            accessor: 'price',
            resizable: false,
            sortable: false,
            Cell: ({ original }) => formatPrice(original.price),
        },
        {
            Header: '',
            accessor: 'id',
            resizable: false,
            sortable: false,
            Cell: ({ original }) => this.renderActionButtons(original),
        },
    ];

    renderActionButtons = original => {
        return this.props.regime.includes('view') ? (
            <div className="Action-Btn__View">
                <FontAwesomeIcon icon="arrow-circle-right"> </FontAwesomeIcon>
            </div>
        ) : (
            <div class="ButtonsWrapper">
                <Button
                    size="sm"
                    onClick={this.editEducation(original.id)}
                    className="ButtonsWrapper__Button"
                >
                    Редактировать
                </Button>

                <Button
                    size="sm"
                    onClick={this.deleteEducation(original.id)}
                    className="ButtonsWrapper__Button"
                    color="danger"
                    type="button"
                >
                    Удалить
                </Button>
            </div>
        );
    };

    renderEducations() {
        return (
            <DataGrid
                data={this.state.scheduleRecords}
                columns={this.columns}
                loading={!this.state.loaded}
                showPagination={false}
                showPageSizeOptions={false}
                manual
            />
        );
    }

    renderEducationsBlock() {
        const { isDeleting, isOpenDeleteRecordModal } = this.state;
        return (
            <div className="ScheduleRecords-Container">
                {this.props.regime.includes('edit') ? (
                    <ListTemplate title={'Расписание'} headBtn={this.headBtn}>
                        {this.state.scheduleRecords.length > 0
                            ? this.renderEducations()
                            : this.state.loadResourceComplete && (
                                  <div className="No-Data">Данные не найдены</div>
                              )}
                    </ListTemplate>
                ) : (
                    <ListTemplate title={this.state.scheduleRecords.length > 0 ? 'Расписание' : ''}>
                        {this.state.scheduleRecords.length > 0
                            ? this.renderEducations()
                            : this.state.loadResourceComplete && (
                                  <div className="No-Data">Данные не найдены</div>
                              )}
                    </ListTemplate>
                )}
                {this.state.isOpenEditRecordModal && (
                    <EditScheduleRecord
                        performResourceScheduleFetching={this.performResourceScheduleFetching}
                        handleStateChange={this.handleStateChange}
                        isOpenEditRecordModal={this.state.isOpenEditRecordModal}
                        recordToEdit={this.state.scheduleRecords.find(
                            x => x.id === this.state.recordId,
                        )}
                        modalType={this.state.modalType}
                        recordId={this.state.recordId}
                        resourceId={this.props.resourceId}
                        setStateOnCancelEditRecord={this.setStateOnCancelEditRecord}
                    />
                )}
                <ModalDialog
                    onClick={this.deleteRequest}
                    onCloseModal={this.onHide}
                    modalOpen={isOpenDeleteRecordModal}
                    modalHeader="Вы уверены, что хотите удалить расписание?"
                    btnOktext="Удалить"
                    btnCanceltext="Отмена"
                    btnOkColor="danger"
                    isDangerHeader="true"
                    processing={isDeleting}
                ></ModalDialog>
            </div>
        );
    }

    deleteRequest = async () => {
        const { recordId, scheduleRecords } = this.state;
        this.setState({ isDeleting: true });
        await this.callEffect(async () => {
            await deletePersonGrowthResourceSchedule(recordId);
            this.setState({ scheduleRecords: scheduleRecords.filter(x => x.id !== recordId) });
        });

        this.onHide();
    };

    onHide = () =>
        this.setState({ isDeleting: false, recordId: null, isOpenDeleteRecordModal: false });

    render() {
        return <div>{this.renderEducationsBlock()}</div>;
    }

    handleStateChange = (stateKey, value, block = '') => {
        this.setState(state => {
            return block
                ? { ...state, [block]: { ...state[block], [stateKey]: value } }
                : { ...state, [stateKey]: value };
        });
    };

    callEffect = async callback => {
        try {
            await callback();
        } catch (error) {
            const reqError = getError(error, this.getResourceError);
            this.props.showErrorAlert(reqError.message);
        }
    };

    getResourceError = (code, payload) => {
        switch (code) {
            case serviceResultCode.NotFound:
                return `${payload}`;
            case serviceResultCode.PersonGrowthResourceScheduleIsInUse:
                return `Выбранное расписание удалить нельзя, так как оно используется`;
            default:
                return `Произошла непредвиденная ошибка`;
        }
    };
}

const actions = { showErrorAlert };
export default connect(null, actions)(ScheduleRecords);
