import React from 'react';
import classnames from 'classnames';
import './CompetenceField.scss';
import InfoField from 'components/Lk/Uikit/InfoField';
import StyledCheckBox from 'components/Lk/Uikit/StyledCheckBox';

const CompetenceFooter = (props) => {
    const { criterion, result } = props.value;

    const normalizedValue = result.normalizedValue;

    const className = classnames('LKCompetenceFooter', {
        'LKCompetenceFooter--green': normalizedValue > 0.7,
        'LKCompetenceFooter--red': normalizedValue < 0.3,
    });

    switch (criterion.valueKind.toLowerCase()) {
        case 'scale':
            return (
                <span className={className}>
                    {Math.round(result.decimalValue)} из {criterion.scaleUpperBound}
                </span>
            );
        case 'grade':
            return <span className={className}>{result.stringValue}</span>;
        default:
            return null;
    }
};

const CompetenceField = (props) => {
    const { tooltip, name, value, checked, onCheck = () => {}, disabled } = props;
    return (
        <InfoField noIcon className="CompetenceField" tooltip={tooltip}>
            <StyledCheckBox checked={checked} onChange={onCheck} disabled={disabled}>
                <div className="CompetenceField__Content">
                    <span className="CompetenceField__Text">{name}</span>
                    {value && <CompetenceFooter value={value} />}
                </div>
            </StyledCheckBox>
        </InfoField>
    );
};

export default CompetenceField;
