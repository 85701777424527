import React from 'react';
import './Field.scss';
import classnames from 'classnames';

function Field(props) {
    const { required, invalid, filled, children, size, className, noLabel, textAlign, disabled } = props;

    const finalClass = classnames('FieldItem', {
        [`FieldItem__Width-${size}`]: size,
        FieldItem__Required: required,
        FieldItem__Valid: filled,
        FieldItem__Invalid: !filled && typeof filled !== 'undefined',
        FieldItem__NoLabel: noLabel,
        FieldItem__NoEnter: invalid,
        FieldItem__Disabled: disabled,
        [`FieldItem--${textAlign}`]: textAlign,
        [`${className}`]: className,
    });
    return <div className={finalClass}>{children}</div>;
}

export default Field;
