import React, { useEffect } from 'react';
import CompetenceField from 'components/Lk/Common/CompetenceField';
import Slider from 'components/Lk/Uikit/Slider/Slider';
import CardItemBlock from '../CardItemBlock';
import EvaluationType from '../Evaluate/EvaluationType';
import HeaderStep from './HeaderStep';

const CompetenceStep = props => {
    const { competencies, currentPlan, changeHandler, changeSelectedCompetence } = props;
    const { competencyIds = [], selectedCompetence } = currentPlan;

    const competenciesDisabled = competencyIds.length >= 3;

    useEffect(() => {
        if (!selectedCompetence && competencies.length > 0) {
            changeHandler(
                'selectedCompetence', 
                {
                    activeCompetenciesGroup: competencies[0],
                    key: 0,
                }
            );
        }
    }, [competencies, selectedCompetence, changeHandler]);

    const setCheckCompetence = id => check => {
        if (check && competenciesDisabled) {
            return;
        }
        const activeCompetencies = check
            ? [...competencyIds, id]
            : competencyIds.filter(x => x !== id);
        changeHandler('competencyIds', activeCompetencies);
    };
    
    const isCheck = id => currentPlan.competencyIds && currentPlan.competencyIds.indexOf(id) >= 0;

    const slideChange = (e, array) => {
        const param = {
            activeCompetenciesGroup: array[e],
            key: e,
        };
        changeSelectedCompetence(param)
    };

    const renderCompetenciesType = obg => {
        return obg.map((item, key) => {
            return (
                <CardItemBlock grey key={key}>
                    <EvaluationType title={item.competencyModel.name} icon="evaluateType" />
                </CardItemBlock>
            );
        });
    };

    const getCriterionWithResult = (competency, testResults) => {
        if (!testResults) 
        { 
            return null; 
        }
        
        const testResult = testResults[competency.personGrowthTagId];

        if (!testResult) { return null; }

        return testResult;
    };
    
    const { activeCompetenciesGroup } = selectedCompetence || {};
    
    return (
        <>
            <HeaderStep
                title="1. Выберите компетенции"
                descr="При выборе компетенций опирайтесь на&nbsp;свои сильные стороны. Рекомендуем Вам выбирать для развития не&nbsp;более 2‑3 компетенций за&nbsp;раз."
            />
            <div>
                <Slider
                    data={renderCompetenciesType(competencies)}
                    initialSlide={selectedCompetence && selectedCompetence.key}
                    slideChange={slideChange}
                    resultIds={competencies}
                    magical={6}
                />
            </div>
            {activeCompetenciesGroup &&
                activeCompetenciesGroup.competencies.map(item => (
                    <CompetenceField
                        onCheck={setCheckCompetence(item.id)}
                        checked={isCheck(item.id)}
                        key={item.id}
                        name={item.name}
                        value={getCriterionWithResult(item, activeCompetenciesGroup.competencyResultsByTagId)}
                        tooltip={item.description}
                        disabled={!isCheck(item.id) && competenciesDisabled}
                    />
                ))}
        </>
    );
};

export default CompetenceStep;
