import React, { useEffect, useState } from 'react';
import {
    getSearchTemplates,
    removeSearchTemplate,
    addSearchTemplateComment,
    getSearchTemplate,
} from 'api';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'components/common/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcceptPage from 'components/Lk/Common/AcceptPage';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import { connect } from 'react-redux';
import SearchTemplateSorter from './SearchTemplateSorter';
import IconButton from 'components/Lk/Uikit/Buttons/IconButton';
import ModalCommentsBlock from 'components/Lk/Common/CommentsBlock/ModalCommentsBlock';
import 'components/Lk/Common/CommentsBlock/CommentsBlock.scss';

const SearchTemplateList = (props) => {
    const { loadTemplates, setTemplate, onSearch, cleanActiveTemplate, dynamicSearchTerm } = props;
    const [templates, setTemplates] = useState([]);
    const [meta, setMeta] = useState([]);
    const [newData, setNewData] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [commentsLoading, setCommentsLoading] = useState(false);
    const [criteria, setCriteria] = useState({
        searchTerm: dynamicSearchTerm,
        pageSize: 20,
        pageNum: 1,
        sorting: {},
    });
    const [isAcceptPage, setAcceptPageState] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [templateId, setTemplateId] = useState(null);
    const [saving, setSaving] = useState(false);
    const [templateComments, setTemplateComments] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        let mounted = true;
        setNewData([]);
        const loadTemplates = async () => {
            setSearchLoading(true);
            clearTimeout(timer);
            setTimer(
                setTimeout(async () => {
                    const result = await getSearchTemplates({
                        ...criteria,
                        searchTerm: dynamicSearchTerm,
                    });
                    mounted && setTemplates(result.data.payload);
                    mounted && setMeta(result.data.meta);
                    mounted && setSearchLoading(false);
                }, 200),
            );
        };

        loadTemplates();

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line
    }, [criteria, dynamicSearchTerm]);

    useEffect(() => {
        let mounted = true;
        const data = [...newData, ...templates];
        mounted && setNewData(data);

        return () => {
            mounted = false;
        };
        // пока так
        // eslint-disable-next-line
    }, [templates]);

    const handleLoadMore = () => {
        meta?.pageCount > criteria.pageNum &&
            setCriteria({ ...criteria, pageNum: criteria.pageNum + 1 });
    };

    const changeSort = (sorting) => {
        setSearchLoading(true);
        setNewData([]);
        setCriteria({ ...criteria, pageNum: 1, sorting });
    };

    const onFilterClick = (record) => async () => {
        setTemplate({ ...record });
        await loadTemplates(record.id);
        onSearch(true, false, true);
    };

    const onRemoveClick = (item) => (e) => {
        e.stopPropagation();
        setAcceptPageState(true);
        setCurrentRecord({ ...item });
    };

    const onCloseRemoveModal = () => {
        setCurrentRecord(null);
        setAcceptPageState(false);
    };

    const onRemove = async () => {
        try {
            await removeSearchTemplate(currentRecord.id);
            setNewData(newData.filter((x) => x.id !== currentRecord.id));
            onCloseRemoveModal();
            showSuccessAlert('Запрос успешно удален');
            cleanActiveTemplate();
        } catch (e) {
            showErrorAlert('Произошла непредвиденная ошибка');
        }
    };

    const fetchComments = async (templateId) => {
        const data = await getSearchTemplate(templateId);
        return data?.data?.comments;
    };

    const showComments = (templateId) => async () => {
        try {
            setCommentsLoading(true);
            const comments = await fetchComments(templateId);
            setTemplateComments(comments || []);
            setModalOpen(true);
            setTemplateId(templateId);
        } finally {
            setCommentsLoading(false);
        }
    };

    const addComment = async (comment) => {
        try {
            setSaving(true);
            await addSearchTemplateComment([templateId], comment);
            updateCommentsFlag(templateId);
            showSuccessAlert('Заметка успешно добавлена');
        } catch (e) {
            showErrorAlert('Ошибка добавления заметки');
        } finally {
            setModalOpen(false);
            setSaving(false);
        }
    };

    const updateCommentsFlag = () => {
        const updatedTemplate = templates
            .filter((x) => x.id === templateId)
            .map((t) => ({ ...t, commentsCount: t.commentsCount + 1 }));
        const restTemplates = templates.filter((x) => x.id !== templateId);
        setNewData([...updatedTemplate, ...restTemplates]);
    };

    return (
        <>
            <SearchTemplateSorter onChange={changeSort} data={criteria.sorting} />
            {!searchLoading && newData.length === 0 ? (
                <div className="NotResult">Шаблоны поиска не найдены</div>
            ) : (
                <InfiniteScroll
                    loadMore={handleLoadMore}
                    hasMore={true}
                    initialLoad={false}
                    useWindow={false}
                >
                    {newData.map((x) => (
                        <div key={x.id} className="LKSearchTemplateItem" onClick={onFilterClick(x)}>
                            <div className="LKSearchTemplateItem__Wrapper">
                                <span className="LKSearchTemplateItem__Title">
                                    {x.title} ({x.count})
                                </span>
                                <div className="LKSearchTemplateItem__Actions">
                                    <div>
                                        <IconButton
                                            type={x.commentsCount !== 0 ? 'notes' : 'blank_notes'}
                                            onClick={showComments(x.id)}
                                        />
                                    </div>
                                    <div
                                        className="LKSearchTemplateItem__Icon"
                                        onClick={onRemoveClick(x)}
                                    >
                                        <FontAwesomeIcon icon="minus-circle" color="red" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <Loader absolute={commentsLoading} show={searchLoading || commentsLoading} />
                </InfiniteScroll>
            )}

            <AcceptPage
                portal
                show={isAcceptPage}
                title="Вы действительно хотите удалить сохраненный запрос?"
                acceptAction={onRemove}
                setIsAcceptPage={onCloseRemoveModal}
            />

            <ModalCommentsBlock
                comments={templateComments}
                isOpen={isModalOpen}
                closeModal={() => setModalOpen(false)}
                onSubmit={addComment}
                fitScreenSize
                actionButtons={['Отменить', 'Сохранить']}
                processing={saving}
                commentIdKey="id"
            />
        </>
    );
};

const actions = { showErrorAlert, showSuccessAlert };

export default connect(null, actions)(SearchTemplateList);
