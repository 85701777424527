import axios from 'axios';
import moment from 'moment';
import { stringify } from 'query-string';
import { personBlocksUrls } from './constants';

axios.interceptors.request.use((config) => {
    config.headers.common['User-DateTime'] = moment().format();
    config.headers.common['X-XSRF-TOKEN'] = config.headers.common['X-XSRF-TOKEN']
        ? config.headers.common['X-XSRF-TOKEN']
        : getCookie('X-XSRF-TOKEN');
    return config;
});

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};

const buildUrl = (url, params) => `${url}?${stringify(params)}`;

export const photoSizes = {
    fullSize: 'fullSize',
    small: 'small',
    tiny: 'tiny',
};

export const livenessPing = () => {
    return axios.post('api/liveness');
};

export const getCatalog = (catalogName, params) => {
    return axios.post(`api/catalog/${catalogName}`, params);
};

export const getConfig = () => {
    return axios.get(`api/config`);
};

export const getPersonAccess = (id) => {
    return axios.get(`api/personAccess/info/${id}`);
};

export const addPersonAccess = (data) => {
    return axios.put(`api/personAccess/request`, data);
};

export const sendReviewAccessRewquestByIT = (data) => {
    return axios.put(`api/personAccess/request/itReview`, data);
};

export const sendReviewAccessRewquestBySecurity = (data) => {
    return axios.put(`api/personAccess/request/securityReview`, data);
};

export const removeAccessRequest = (id) => {
    return axios.delete(`api/personAccess/request/${id}`);
};

export const getPersonAccesses = (criteria) => {
    return axios.post(`api/personAccess/info`, criteria);
};

export const getPublicPersonAccesses = ({ criteria }) => {
    return axios.get(`api/personAccess/info/public`, { params: criteria });
};

export const getPerson = (photoSizes) => (id) => {
    return axios.get(`api/person/${id}?${stringify({ photoSizes })}`);
};

export const getPersonPhotoUri = (photoSize, personId, refetchKey = null) => {
    const tailParam = refetchKey ? `&r=${refetchKey}` : '';
    return `/api/person/${personId}/photo?photoSize=${photoSize}${tailParam}`;
};

export const getUserPhotoUri = (photoSize, userId, refetchKey = null) => {
    const tailParam = refetchKey ? `&r=${refetchKey}` : '';
    return `/api/user/${userId}/photo?photoSize=${photoSize}${tailParam}`;
};

export const putPerson = async (person) => {
    const response = await axios.put(`api/person`, person);
    if (person.photoFile) {
        await uploadPhoto(response.data.id, person.photoFile);
    }
    return response;
};

export function putPersonBlock(block) {
    let url, request;

    if (block.isArray) {
        url = `api/person/${personBlocksUrls[block.blockType]}/${block.personId}`;
        request = block.arr;
    } else {
        url = `api/person/${personBlocksUrls[block.blockType]}/${
            block.needPersonId ? block.personId : ''
        }`;
        request = block;
    }

    if (block.needPutRequest) {
        return axios.put(url, request);
    }
    return axios.post(url, request);
}

export const updateGeneralInfo = (request) => {
    return axios.post(`api/person/UpdateGeneralInfo/`, request);
};

export const getPersonIdBySnils = (snils) => {
    return axios.get(`/api/Person/snils/${snils}/`);
};

export const mergePerson = (request) => {
    return axios.put(`/api/Person/merge/`, request);
};

export const updateJobs = (personId, request) => {
    return axios.post(`api/person/updateJob/${personId}`, request);
};

export const getJobsByPersonId = (personId) => {
    return axios.get(`api/person/jobs/${personId}`);
};

export const updateLanguages = (personId, request) => {
    return axios.post(`api/person/${personId}/languages`, request);
};

export const logGoToPage = (request) => {
    return axios.post(`api/logger/goToPage`, request);
};

export const getLanguageAttachmentInfo = (personId, fileId) => {
    return axios.get(`api/person/${personId}/languages/files/${fileId}`);
};

export const getLanguageAttachmentDownloadLink = (personId, fileId) => {
    return `api/person/${personId}/languages/files/${fileId}/download`;
};

export const saveSearchTemplate = (request) => {
    return axios.post(`api/fullTextSearch/template`, request);
};

export const updateSearchTemplate = (id, request) => {
    return axios.put(`api/fullTextSearch/template/${id}`, request);
};

export const getSearchTemplates = (criteria) => {
    return axios.post(`api/fullTextSearch/templates`, criteria);
};

export const removeSearchTemplate = (id) => {
    return axios.delete(`api/fullTextSearch/template/${id}`);
};

export const getSearchTemplate = (id) => {
    return axios.patch(`api/fullTextSearch/template/${id}`);
};

export const uploadLanguageAttachment = (personId, file, onUploadProgress = () => {}) => {
    const formData = new FormData();
    formData.append('file', file);

    return axios.put(`api/person/${personId}/languages/files`, formData, { onUploadProgress });
};

export const updateSocialNetwork = (personId, request) => {
    return axios.post(`api/person/updateSocialNetwork/${personId}`, request);
};

export const updatePersonnel = (personId, request) => {
    return axios.post(`api/person/updatePersonnel/${personId}`, request);
};

export function updateAwards(personId, request) {
    return axios.post(`api/person/updateAwards/${personId}`, request);
}

export const signIn = (credentials) => {
    return axios.post(`api/auth`, credentials);
};

export const signInAdmin = (credentials) => {
    return axios.post('api/admin/auth', credentials);
};

export const signOut = () => {
    return axios.delete(`api/auth`);
};

export const getUserSettings = () => {
    return axios.get(`api/settings`);
};

export const updateUserSettings = (data) => {
    return axios.put(`api/settings`, data);
};

export const createUserSettings = (data) => {
    return axios.post(`api/settings`, data);
};

export const initiatePhoneConfirmation = (login, token) => {
    return axios.post(`api/me/phone/initiateConfirmation`, { login, token });
};

export const confirmPhoneNumber = (login, token, code) => {
    return axios.post(`api/me/phone/confirm`, { login, token, code });
};

export const resetPassword = (login, password, token) => {
    return axios.post(`api/me/password`, { login, password, token });
};

export const getCurrentUser = (skipCache = false) => {
    // do not trigger the interceptor that redirects to the sign in page if auth returns 401 error code
    // prevents double redirect to sign in page when session expires
    const config = {
        validateStatus: (status) => status < 400 || status === 401,
    };
    return axios.get(`api/auth${skipCache ? `?skipCache=${skipCache}` : ''}`, config);
};

export const getDefaultWishlist = () => {
    return axios.get('api/me/wishlists/default');
};

export const getPersonWishlists = (personId) => {
    return axios.get(`api/me/wishlists/person/${personId}`);
};

export const updatePersonWihslists = (personId, criteria) => {
    return axios.post(`api/me/wishlists/person/${personId}/multiple`, criteria);
};

export const getCommemntsWishlists = (ids) => {
    return axios.post('api/me/wishlists/comments', ids);
};

export const getWishlistById = (id) => {
    return axios.get(`api/me/wishlists/${id}`);
};

export const getWishlistPersonProgress = (criteria) => {
    return axios.post(`api/me/wishlists/fullWithProgress`, criteria);
};

export const getWishlistWithEvaluationResults = (criteria) => {
    return axios.post(`api/me/wishlists/fullWithEvaluationResults`, criteria);
};

export const getFullWishlist = (criteria) => {
    return axios.post('api/me/wishlists/full', criteria);
};

export const getFullWishlistById = (id, criteria) => {
    return axios.post(`api/me/wishlists/full/${id}`, criteria);
};

export const updateWishlist = (wishlist) => {
    return axios.put('api/me/wishlists', wishlist);
};

export const updateWishlistBatch = (wishlists) => {
    return axios.put('api/me/wishlists/multiple', wishlists);
};

export function addPersonToDefaultWishlist(personId) {
    return axios.post(`api/me/wishlists/person/${personId}`);
}

export function removePersonFromDefaultWishlist(personId) {
    return axios.delete(`api/me/wishlists/person/${personId}`);
}

export function addPersonToWishlist(id, personId) {
    return axios.post(`api/me/wishlists/${id}/person/${personId}`);
}

export function removePersonFromWishlist(id, personId) {
    return axios.delete(`api/me/wishlists/${id}/person/${personId}`);
}

export function addPersonsToWishlist(id, personIds = []) {
    return axios.post(`api/me/wishlists/${id}/person/`, { persons: personIds });
}

export function removePersonsFromWishlist(id, personIds = []) {
    return axios.delete(`api/me/wishlists/${id}/person/`, { data: { persons: personIds } });
}

export function getWishLists(criteria) {
    return axios.post(`api/me/wishlists/find`, criteria);
}

export function getWishListsWithPersonUsers() {
    return axios.get(`api/me/wishlists/withPersonUsers`);
}

export function getPersonsForWishlist(id) {
    return axios.get(`api/me/wishlists/${id}/persons`);
}

export function getWishListsByIds(criteria) {
    return axios.post('/api/me/wishlists/getMultiple', criteria);
}

export function getWishListsWithPersons() {
    return axios.get(`api/me/wishlists/withPersons`);
}

export function getWishListsWithPersonsAndEvaluations(id) {
    return axios.get(`api/me/wishlists/withPersonsAndEvaluations/${id ? id : ''}`);
}

export function getWishListsWithPersonsAndProgress(id) {
    return axios.get(`api/me/wishlists/withPersonsAndProgress/${id ? id : ''}`);
}

export function createWishList(criteria) {
    return axios.post(`/api/me/wishlists`, criteria);
}

export function deleteWishlist(id) {
    return axios.delete(`/api/me/wishlists/${id}`);
}

export const getWishlistsAllPersonIds = () => {
    return axios.get('api/me/wishlists/personIds');
};

export const updateWishlistsVisibility = (visibility, wishlists) => {
    return axios.put(`api/me/wishlists/visibility/multiple?value=${visibility}`, wishlists);
};

export const deleteWishlists = (ids) => {
    return axios.delete('/api/me/wishlists/multiple', { data: ids });
};

export const addWishlistsComment = (ids, comment) => {
    return axios.put('/api/me/wishlists/comment', { ids, comment });
};

export function mergeWishLists(name, selectedAttribute, criteria = []) {
    return axios.post(
        `/api/me/wishlists/merge?name=${name}&visibility=${selectedAttribute}`,
        criteria,
    );
}

export function cloneWishLists(criteria) {
    return axios.post(`/api/me/wishlists/clone`, criteria);
}

export const uploadPhoto = (personId, file, onUploadProgress = () => {}) => {
    const formData = new FormData();
    formData.append('file', file);

    return axios.post(`api/person/${personId}/photo`, formData, { onUploadProgress });
};

export const uploadUserPhoto = (file, onUploadProgress = () => {}) => {
    const formData = new FormData();
    formData.append('file', file);

    return axios.post(`api/user/photo`, formData, { onUploadProgress });
};
export const getSearchRequest = (searchTerm) =>
    axios.post(`api/fullTextSearch/searchRequests`, {
        searchTerm,
        paging: { pageSize: 10, pageNum: 1 },
    });

export const getPersonFileUrl = (personId, fileId) => {
    return `api/person/${personId}/files/${encodeURIComponent(fileId)}`;
};

export const uploadPersonFile = (personId, file, onUploadProgress = () => {}) => {
    const formData = new FormData();
    formData.append('file', file);

    return axios.put(`api/person/${personId}/files`, formData, { onUploadProgress });
};

export const deletePersonFile = (personId, fileId) => {
    return axios.delete(`api/person/${personId}/files/${encodeURIComponent(fileId)}`);
};

export const deleteAllPersonFiles = (personId, fileIds) => {
    return axios.delete(`api/person/${personId}/files/`, { data: fileIds });
};

export const getTempFileUrl = (fileId, refetchKey) => {
    const tailParam = refetchKey ? `?r=${refetchKey}` : '';
    return `api/tempFiles/${encodeURIComponent(fileId)}${tailParam}`;
};

export const uploadTempFile = (file, onUploadProgress = () => {}) => {
    const formData = new FormData();
    formData.append('file', file);

    return axios.put(`api/tempFiles`, formData, { onUploadProgress });
};

export const deleteTempFile = (fileId) => {
    return axios.delete(`api/tempFiles/${encodeURIComponent(fileId)}`);
};

export const generateProfileReports = (personIds) => {
    return axios.post('api/reports/profile', { personIds });
};

export const generateProfileExtendedReports = (personIds) => {
    return axios.post('api/reports/profileExtended', { personIds });
};

export const generateManagerProfileReports = (personIds) => {
    return axios.post('api/reports/managerProfile', { personIds });
};

export const generateHumanReserveFormReport = (reportDate, personIds) => {
    return axios.post('api/reports/humanReserveForm', {
        reportDate: reportDate.toISOString(true),
        personIds,
    });
};

export const generateBiographyReports = (personIds) => {
    return axios.post('api/reports/biography', { personIds });
};

export const generateFullResumeReports = (personIds) => {
    return axios.post('api/reports/fullResume', { personIds });
};

export const getReportDownloadLink = (fileId) => `api/reports/${fileId}`;

export const createUser = (user) => {
    return axios.post(`api/user`, user);
};

export const editUser = (user) => {
    return axios.put(`api/user`, user);
};

export const getUser = (id) => {
    return axios.get(`api/user/${id}`);
};

export const resetUserPassword = (userId) => {
    return axios.post(`api/user/resetPassword/${userId}`);
};

export const resetUserPasswordByEmail = (email) => {
    return axios.post(`api/auth/resetPasswordByEmail/${email}`);
};

export const resetAdminPasswordByEmail = (email) => {
    return axios.post(`api/admin/auth/resetPasswordByEmail/${email}`);
};

export const blockUserAccount = (userId, block) => {
    return axios.post(`api/user/block`, { userId: userId, block: block });
};

export const sendActivationLink = (userId) => {
    return axios.post(`api/user/sendActivationLink/${userId}`);
};

export const getGroup = (groupId) => {
    return axios.get(`api/group/${groupId}`);
};

export const createGroup = (newGroup) => {
    return axios.post(`api/group`, newGroup);
};

export const updateGroup = (group) => {
    return axios.put(`api/group`, group);
};

export const deleteGroup = (groupId) => {
    return axios.delete(`api/group/${groupId}`);
};

export const getCustomAttributes = (criteria) => {
    return axios.post(`api/customAttribute/getList`, criteria);
};

export const getCustomAttribute = (id) => {
    return axios.get(`api/customAttribute/get/${id}`);
};

export const addCustomAttribute = (attribute) => {
    return axios.put(`api/customAttribute/create`, attribute);
};

export const editCustomAttribute = (attribute) => {
    return axios.post(`api/customAttribute/update`, attribute);
};

export const updateAttributes = (personId, request) => {
    return axios.post(`api/person/updateCustomAttributes/${personId}`, request);
};

export const deleteCustomAttribute = (attributeId) => {
    return axios.delete(`api/customAttribute/delete/${attributeId}`);
};

export function fullTextSearchPersons(criteria = { searchTerm: '' }) {
    return axios.post(`api/fullTextSearch/persons`, criteria);
}

export function fullTextSearchUsers(criteria = { searchTerm: '', filterRoles: [] }) {
    return axios.post(`api/fullTextSearch/users`, criteria);
}

export function fullTextSearchSelect(criteria) {
    return axios.post(`api/fullTextSearch/select`, criteria);
}

export function searchAssignUsers(criteria) {
    return axios.post(`api/fullTextSearch/selectUsers`, criteria);
}

export const fullTextSearchSelectWithProgress = (criteria) => {
    return axios.post(`api/fullTextSearch/selectWithProgress`, criteria);
};

export function fullTextSearchAmount(criteria) {
    return axios.post(`api/fullTextSearch/amount`, criteria);
}

export function fullTextSearchAttributes() {
    return axios.get(`api/fullTextSearch/attributes`);
}

export function fullTextSearchWithEvaluations(criteria) {
    return axios.post(`/api/fullTextSearch/selectWithEvaluations`, criteria);
}

export function saveSearchRequest(searchRequest) {
    return axios.post('api/search-requests', searchRequest);
}

export function searchPersons(criteria = {}) {
    return axios.post(`api/search/persons`, criteria);
}

export const searchPersonsWithProgress = (criteria = {}) => {
    return axios.post(`api/search/personsWithProgress`, criteria);
};

export function searchUsers(criteria = {}) {
    return axios.post(`api/search/users`, criteria);
}

export function searchGroups(criteria = {}) {
    return axios.post(`api/search/groups`, criteria);
}

export function searchUserActions(criteria = {}) {
    return axios.post(`api/search/userActions`, criteria);
}

export function searchPersonGrowthTags(criteria = {}) {
    return axios.post(`api/search/personGrowthTags`, criteria);
}

export function searchCompetencyModels(criteria = {}) {
    return axios.post(`api/search/competencyModels`, criteria);
}

export function searchLeaders(criteria = {}) {
    return axios.post(`api/search/leaders`, criteria);
}

export function searchIssues(criteria = {}) {
    return axios.post(`api/search/issues`, criteria);
}

export function createIssue(issue) {
    return axios.post(`api/issues`, issue);
}

export function updateIssue(issue) {
    return axios.put(`api/issues`, issue);
}

export function updateIssueMultiple(issue) {
    return axios.put(`api/issues/multiple`, issue);
}

export function getIssue(id) {
    return axios.get(`api/issues/${id}`);
}

export function getEditIssue(id) {
    return axios.get(`/api/issues/assigned/${id}`);
}

export function deleteIssue(id) {
    return axios.delete(`api/issues/${id}`);
}

export function getIssueActive() {
    return axios.get(`api/issues/active`);
}

export function getIssueAssigned(parentId) {
    return axios.get(`api/issues/assigned/${parentId}`);
}

export function getIssuesAssigned(params) {
    return axios.get(`api/issues/assigned`, { params });
}
export function getIssueDrafts(pageNum = 1, pageSize = 5) {
    return axios.get(`api/issues/drafts?pageNum=${pageNum}&pageSize=${pageSize}`);
}

export function getIssueActiveAmount() {
    return axios.get(`api/issues/activeAmount`);
}

export function createIssueMultiple(criteria) {
    return axios.post(`api/issues/multiple`, criteria);
}

export const getIssueFileUrl = (fileId) => {
    return `api/issues/files/${fileId}`;
};

export function createIssueDraftMultiple(criteria) {
    return axios.post(`api/issues/multiple/drafts`, criteria);
}

export function updateIssueStatus(id, status, comment) {
    return axios.patch(`api/issues/updateStatus`, { id, status, comment });
}

export const updateDueDate = (id, dueDate, comment) => {
    return axios.patch(`api/issues/updateDueDate`, { id, dueDate, comment });
};

export function searchNews(criteria = {}) {
    return axios.post(`api/search/news`, criteria);
}

export function fullTextSearchNewsTags(criteria = { searchTerm: '', groupId: 1 }) {
    return axios.post(`api/fullTextSearch/newsTags`, criteria);
}

export const uploadNewsPicture = (newsId, file, onUploadProgress = () => {}) => {
    const formData = new FormData();
    formData.append('file', file);

    return axios.put(`api/news/${newsId}/files`, formData, { onUploadProgress });
};

export const getNewsPictureUri = (newsId, pictureId, refetchKey = null) => {
    const tailParam = refetchKey ? `?r=${refetchKey}` : '';
    return `/api/news/${newsId}/files/${pictureId}${tailParam}`;
};

export function createNews(news) {
    return axios.post(`api/news`, news);
}

export function updateNews(news) {
    return axios.put(`api/news`, news);
}

export function getNews(id) {
    return axios.get(`api/news/${id}`);
}

export function updateNewsStatus(id, status) {
    return axios.patch(`api/news/updateStatus`, { id, status });
}

export function getCategories() {
    return axios.get(`api/news/categories`);
}

export function getTags() {
    return axios.get(`api/news/tags`);
}

export function getNewsTags(newsId) {
    return axios.get(`api/news/${newsId}/tags`);
}

export function getPublicPersonAccess(criteria = {}) {
    return axios.post(`api/personAccess/info/public`, criteria);
}

export function getManagers(criteria = {}) {
    return axios.post(`api/personAccess/info/managers`, criteria);
}

export function getUserAction(userActionId) {
    return axios.get(`api/userAction/${userActionId}`);
}

export function getUserActionForSupportLine(userActionId) {
    return axios.get(`api/userAction/${userActionId}/support`);
}

export function heartbeat(info) {
    return axios.post(`api/UserSession/heartbeat`, info);
}

export function getUserSession(userId) {
    return axios.get(`api/UserSession/${userId}`);
}

export function dropUserSession(userId) {
    return axios.post(`api/userSession/${userId}/drop`);
}

export function dropDeviceSession(userId, deviceSessionId) {
    return axios.post(`api/userSession/${userId}/${deviceSessionId}/drop`);
}

export function getDictionary(dictionaryName) {
    return axios.get(`api/dictionary/${dictionaryName}`);
}

export function getGroupsList(groupId) {
    return axios.get(`api/group/availableParents?groupId=${groupId}`);
}

export function getAllowedGroups(groupId) {
    return axios.get(`api/group/availableGroups?groupId=${groupId}`);
}

export function getWidgetSettings() {
    return axios.get('api/widget/settings');
}

export function getDashboardInfo() {
    return axios.get('api/dashboard/info');
}

export function getDashboardPersonsWithoutResultAmount() {
    return axios.get('api/dashboard/personsWithoutResult/amount');
}

export function getDashboardTeamGrowth(personId) {
    return axios.get(`/api/dashboard/teamGrowth/${personId}`);
}

export function getDashboardTeamGrowthAmount(personId) {
    return axios.get(`/api/dashboard/teamGrowth/${personId}/amount`);
}

export function getDashboardBestInGroup() {
    return axios.get('api/dashboard/bestInGroup');
}

export function getDashboardPersonsWithoutResultSelect(criteria) {
    return axios.post('api/dashboard/personsWithoutResult/select', criteria);
}

export function getDashboardEvaluationRegionRating() {
    return axios.get('api/dashboard/regionRating');
}

export function getPersonProfilesInfo(clientDate, months) {
    return axios.post('api/dashboard/personProfilesInfo', {
        clientDate: clientDate.toISOString(true),
        months: months,
    });
}

export const getAttributeAttachmentInfo = (personId, attributeId, fileId) => {
    return axios.get(`api/person/${personId}/attributes/${attributeId}/files/${fileId}`);
};

export const getAttributeAttachmentDownloadLink = (personId, attributeId, fileId) => {
    return `api/person/${personId}/attributes/${attributeId}/files/${fileId}/download`;
};

export const uploadAttributeAttachment = (personId, file, onUploadProgress = () => {}) => {
    const formData = new FormData();
    formData.append('file', file);

    return axios.put(`api/person/${personId}/files`, formData, { onUploadProgress });
};

export const getImportTemplateLink = () => {
    return '/api/import/template';
};

export const uploadImportFile = (file) => {
    const formData = new FormData();
    formData.append('file', file);

    return axios.put(`api/import`, formData);
};

export const uploadReportTemplate = (report) => {
    return axios.put(`api/reports/creator/me/templates`, report);
};

export const getReportCreatorTemplates = () => {
    return axios.get(`/api/reports/creator/me/templates`);
};

export const getReportTemplate = (id) => {
    return axios.get(`/api/reports/${id}`);
};

export const deleteReportTemplate = (reportTemplateId) => {
    return axios.delete(`api/reports/creator/me/templates/${reportTemplateId}`);
};

export const importSave = (importId) => {
    return axios.post(`api/import/save/${importId}`);
};

export const importCancel = (importId) => {
    return axios.post(`api/import/cancel/${importId}`);
};

export const getImportUserSession = () => {
    return axios.get('api/import/session');
};

export const saveImportUserSession = (session) => {
    return axios.put('api/import/session', session);
};

export const deleteImportUserSession = () => {
    return axios.delete('api/import/session');
};

export const getReportTemplates = () => {
    return axios.get('/api/reports/templates');
};

export const getReportById = (id, personIds) => {
    return axios.post(`/api/reports/${id}`, { personIds });
};

export const getCreatorReportTemplates = () => {
    return axios.get('/api/reports/creator/bases');
};

export const getPersonGrowthPlan = (id) => {
    return axios.get(`/api/personGrowthPlan/${id}`);
};

export const createPersonGrowthPlan = (plan) => {
    return axios.post(`/api/personGrowthPlan`, plan);
};

export const getPersonGrowthPlanSession = (personId, sessionId) =>
    axios.get(`/api/personGrowthPlan/${personId}/session/${sessionId}`);

export const setPersonGrowthPlanSession = (session) =>
    axios.post(`/api/personGrowthPlan/session`, session);

export const clearPersonGrowthPlanSession = (personId, sessionId) => {
    return axios.delete(`/api/personGrowthPlan/${personId}/session/${sessionId}`);
};

export const deletePersonGrowthPlan = (personId) => {
    return axios.delete(`/api/personGrowthPlan/${personId}`);
};

export const getCompetencies = (personId) => {
    return axios.get('/api/competencyModel', { params: { personId } });
};

export const getCompetencyById = (competencyId) => {
    return axios.get(`/api/competency/${competencyId}`);
};

export const getPersonGrowthTags = () => {
    return axios.get(`/api/personGrowthTags`);
};

export const getPersonGrowthCompetencies = () => {
    return axios.get(`/api/CompetenciesGroups/competencies`);
};

export const getPersonGrowthTagById = (id) => {
    return axios.get(`/api/personGrowthTags/${id}`);
};

export const createPersonGrowthTag = async (personGrowthTag) => {
    return await axios.post(`api/personGrowthTags`, personGrowthTag);
};

export const updatePersonGrowthTag = async (personGrowthTag) => {
    return await axios.put(`api/personGrowthTags/`, personGrowthTag);
};

export const deletePersonGrowthTag = (id) => {
    return axios.delete(`/api/personGrowthTags/${id}`);
};

export const getTestsForPersonGrowthTag = (tagId, criteria) => {
    return axios.post(`api/search/personGrowthTags/${tagId}/evaluationTests`, criteria);
};

export const getResourcesForPersonGrowthTag = (tagId, criteria) => {
    return axios.post(`api/search/personGrowthTags/${tagId}/personGrowthResources`, criteria);
};

export const searchPersonGrowthResources = (criteria = {}) => {
    return axios.post(`/api/personGrowthResource/search`, criteria);
};

export const extendedSearchPersonGrowthResources = (criteria = {}, source) => {
    return axios.post(`/api/personGrowthResource/extendedSearch`, criteria, {
        cancelToken: source.token,
    });
};

export const getResourceCounter = (criteria = {}, source) => {
    return axios
        .post(`/api/personGrowthResource/count`, criteria, {
            cancelToken: source.token,
        })
        .catch(() => {});
};

export const getPersonGrowthResourcePhotoUri = (resourceId, refetchKey = null) => {
    const tailParam = refetchKey ? `?r=${refetchKey}` : '';
    return `/api/personGrowthResource/${resourceId}/file/download${tailParam}`;
};

export const getPersonGrowthResourceById = (id) => {
    return axios.get(`/api/personGrowthResource/${id}`);
};

export const uploadResourcePhoto = (resourceId, file, onUploadProgress = () => {}) => {
    const formData = new FormData();
    formData.append('file', file);

    return axios.put(`api/personGrowthResource/${resourceId}/sberbankSchoolPreview`, formData, {
        onUploadProgress,
    });
};

export const createPersonGrowthResource = async (personGrowthResource) => {
    return await axios.post(`api/personGrowthResource`, personGrowthResource);
};

export const deletePersonGrowthResource = (id) => {
    return axios.delete(`/api/personGrowthResource/${id}`);
};

export const updatePersonGrowthResource = async (personGrowthResource) => {
    return await axios.put(
        `api/personGrowthResource/${personGrowthResource.id}`,
        personGrowthResource,
    );
};

export const getPersonGrowthResourceSchedule = async (resourceId) => {
    return await axios.get(`api/personGrowthResource/${resourceId}/schedule`);
};

export const deletePersonGrowthResourceSchedule = async (recordId) => {
    return await axios.delete(`api/personGrowthResource/schedule/${recordId}`);
};

export const updatePersonGrowthResourceSchedule = async (record) => {
    return await axios.put(
        `api/personGrowthResource/${record.personGrowthResourceId}/schedule/${record.id}`,
        record,
    );
};

export const createPersonGrowthResourceSchedule = async (record) => {
    return await axios.post(
        `api/personGrowthResource/${record.personGrowthResourceId}/schedule/${record.id}`,
        record,
    );
};

export const getQuotasInfo = (currentMonth = true) => {
    return currentMonth
        ? axios.get('/api/evaluation/quotaInfo')
        : axios.get(
              `/api/evaluation/quotaInfo?startDate=${moment()
                  .subtract(3, 'years')
                  .format('YYYY-MM-DD')}&endDate=${moment().add(3, 'years').format('YYYY-MM-DD')}`,
          );
};

export const getQuotaById = (quotaId = '') => {
    return axios.get(`/api/evaluation/quotas/${quotaId}`);
};

export const saveQuota = (quota = {}) => {
    return axios.put('api/evaluation/quotas', quota);
};

export const getTemplatesInfo = () => {
    return axios.get('/api/emailTemplates/');
};

export const searchEmailTemplates = (criteria = {}) => {
    return axios.post('/api/emailTemplates/search', criteria);
};

export const getTemplatesInfoByGroupId = (groupId) => {
    return axios.get(`/api/emailTemplates/groups/${groupId}`);
};

export const getReportSettings = () => {
    return axios.get(`api/teamReport/settings`);
};

export const generateTeamReport = (teamId, download = false) => {
    return axios.post(`api/teamReport/${teamId}/print`, {}, { params: { download } });
};

export const printTeamReport = (teamId) => `api/teamReport/${teamId}/print`;

export const saveReportSettings = (data) => {
    return axios.put(`api/teamReport/settings`, data);
};

export const getTemplateById = (templateId = '') => {
    return axios.get(`/api/emailTemplates/${templateId}`);
};

export const saveTemplate = (template = {}) => {
    return axios.put('api/emailTemplates', template);
};

export const makeTemplatesDefault = (templateIds) => {
    return axios.put(`api/emailTemplates/makeDefault`, templateIds);
};

export const addCorrections = (quotaId, corrections) => {
    return axios.put(`api/evaluation/quotas/${quotaId}/corrections`, corrections);
};

const sorter = (data, key, asc = true) => {
    return data.sort((a, b) => {
        return asc ? (a[key] > b[key] ? 1 : -1) : a[key] > b[key] ? -1 : 1;
    });
};

export const getAnalytics = async (personIds = []) => {
    const industiesData = await axios.post(' api/charts/industries', personIds);
    const choroplethData = await axios.post(' api/charts/choropleth', personIds);
    const data = {
        // TODO: График - кол-во человек в подчинении. Появится после согласования графика с заказчиком. Пока скрыт.
        employees: [
            { year: 2010, amount: 1 },
            { year: 2011, amount: 2 },
            { year: 2013, amount: 3 },
            { year: 2016, amount: 4 },
            { year: 2017, amount: 2 },
            { year: 2018, amount: 3 },
            { year: 2019, amount: 4 },
        ],
        industries: sorter(industiesData.data, 'percent', false),
        regions: choroplethData.data,
    };
    return data;
};

export const getEvaluationRequests = (yearMonth, pageNum = 1, pageSize = 7) => {
    return axios.get('/api/evaluation/requests', {
        params: {
            yearMonth: yearMonth,
            pageNum: pageNum,
            pageSize: pageSize,
        },
    });
};

export const getEvaluationRequestsByPersonId = (personId, pageNum = 1, pageSize = 1000) => {
    return axios.get(`/api/evaluation/requests/byPersonId/${personId}`, {
        params: {
            pageNum: pageNum,
            pageSize: pageSize,
        },
    });
};

// TODO: Хранить в настройках пользователя конфигурацию "краткой" карточки резервиста
// пока используется только "showPhotoBlock: false" в карточке на странице резервиста в блоке "Оценка"
export const getPersonCardConfig = () => {
    return {
        data: {
            showPhotoBlock: false,
            firstSection: ['fullName', 'position'], // For example
            secondeSection: ['district', 'region'], // For example
        },
    };
};

export const getEvaluationSummaryScales = () => {
    return axios.get(`/api/evaluation/scale/summary/list`);
};

export const getEvaluationTestList = () => {
    return axios.get(`/api/evaluationTest/list`);
};

export const searchEvaluationTestList = (criteria = {}) => {
    return axios.post('api/search/evaluationTests', criteria);
};

export const searchEvaluationTestFullList = (criteria = {}) => {
    return axios.post('api/search/evaluationTestsFull', criteria);
};

export const getTestProvidersNotDeleted = () => {
    return axios.get(`api/evaluationTestProviders`);
};

export const getTestProvidersActive = () => {
    return axios.get(`api/evaluationTestProviders/active`);
};

export const getEvaluationRequestsPermissions = () => {
    return axios.get(`/api/evaluation/requests/permissions`);
};

export const createEvaluationRequest = (request = {}) => {
    return axios.put(`/api/evaluation/requests`, request);
};

export const reviewEvaluationRequest = (requestId, decision = 'Approve', rejectionComment = '') => {
    return axios.put(`/api/evaluation/requests/review`, {
        evaluationRequestId: requestId,
        decision: decision,
        rejectionComment: rejectionComment,
    });
};

export const confirmEvaluationRequest = (evaluationRequestId, messageToPerson) => {
    return axios.put(`/api/evaluation/requests/confirmation`, {
        evaluationRequestId: evaluationRequestId,
        messageToPerson: messageToPerson,
    });
};

export const resendConfirmEvaluationRequest = (evaluationRequestId, messageToPerson) => {
    return axios.put(`/api/evaluation/requests/confirmation/retry`, {
        evaluationRequestId: evaluationRequestId,
        messageToPerson: messageToPerson,
    });
};

export const getQuotaBalance = (quotabalanceDates = null) => {
    let params = quotabalanceDates
        ? `?startYear=${quotabalanceDates.startYearMonth}&endYear=${quotabalanceDates.endYearMonth}`
        : '';
    return axios.get(`/api/evaluation/quotaBalance${params}`);
};

export const evaluationTestResultsByPersonId = (personId = 0) => {
    return axios.get(`/api/evaluationTest/results/${personId}`);
};

export const getEvaluationTestResultFileLink = (resultId) => {
    return `api/evaluationTest/results/byId/${resultId}/file/download`;
};

export const getCountResultFile = (personId) => {
    return axios.get(`api/evaluationtest/results/${personId}/countResultFiles`);
};

export const getCombinedResultsFile = (personId) => {
    return buildUrl(`api/evaluationtest/results/${personId}/combined`, {
        clientDate: moment().format(),
    });
};

export const getPersonGrowthPlans = (personId) => {
    return axios.get(`api/personGrowthPlan/list/${personId}`);
};

export const getPersonAlreadyHavePersonGrowthPlan = (personId) => {
    return axios.get(`api/personGrowthPlan/alreadyHave/${personId}`);
};

export const savePersonGrowthPlan = (plan) => {
    return axios.post(`api/personGrowthPlan`, plan);
};

export const updatePersonGrowthPlan = (plan) => {
    return axios.put(`api/personGrowthPlan/${plan.id}`, plan);
};

export const updateCachePersonGrowthPlan = (plan) => {
    return axios.put(`api/personGrowthPlan/cache/${plan.id}`, plan);
};

export const updatePersonGrowthPlanApprove = (approve) => {
    return axios.put(`api/personGrowthPlan/${approve.personGrowthPlanId}/approves`, approve);
};

export const createPersonGrowthPlanApproveComment = (planId, approveId, comment, issueId) => {
    return axios.post(`api/personGrowthPlan/${planId}/approves/${approveId}/comments`, {
        comment,
        issueId,
    });
};

export const getPersonGrowthReportPdfDownloadLink = (planId) => {
    return buildUrl(`api/personGrowthPlan/${planId}/download/pdf`, {
        clientDate: moment().format(),
    });
};

export const getCompetenceModelById = (modelId) => {
    return axios.get(`/api/competencyModel/${modelId}`);
};

export const createCompetenceModel = (model) => {
    return axios.post(`/api/competencyModel/`, model);
};

export const updateCompetenceModel = (id, model) => {
    return axios.put(`/api/competencyModel/${id}`, model);
};

export const addEvaluationTestResults = (testResults, file, action = 'update') => {
    const formData = new FormData();
    formData.append('data', JSON.stringify(testResults));
    file && formData.append('file', file, file.name);

    if (action === 'update') {
        return axios.put(`/api/evaluationTest/results`, formData);
    } else {
        return axios.post(`/api/evaluationTest/results`, formData);
    }
};

export const getEvaluationTestById = (id = 0) => {
    return axios.get(`/api/evaluationTest/${id}`);
};

export const deleteEvaluationTestById = (id = 0) => {
    return axios.delete(`/api/evaluationTest/${id}`);
};

export const getExternalEvaluationTestById = (id = 0) => {
    return axios.get(`/api/externalEvaluationTests/${id}`);
};

export const getExternalEvaluationTests = () => {
    return axios.get(`/api/externalEvaluationTests`);
};

export const getExternalEvaluationTestsNotUsed = () => {
    return axios.get(`/api/externalEvaluationTests/notUsed`);
};

export const saveEvaluationTest = (test, type) => {
    return type === 'edit'
        ? axios.put(`/api/evaluationTest`, test)
        : axios.post(`/api/evaluationTest`, test);
};

export const getPersonTeams = (personId) => {
    return axios.get(`api/teams/byPersonId/${personId}`);
};

export const getTeamPersonSummary = (personId) => {
    return axios.get(`api/teams/person/${personId}`);
};

export const putTeam = (team, isLeader) => {
    return axios.put(`api/teams${isLeader ? '/onLeader' : ''}`, team);
};

export const deleteTeam = (id, isLeader) => {
    return axios.delete(`api/teams/${id}${isLeader ? '/onLeader' : ''}`);
};
export const getTeamPersonEvaluationTestsCriterion = (personId) => {
    return axios.get(`api/teams/evaluationTestsCriterion/${personId}`);
};

export const getIsEvaluationTestUsed = (id) => {
    return axios.get(`/api/evaluationTest/${id}/status`);
};

export const getPersonGrowthResourceProviders = () => {
    return axios.get(`api/resourceProviders`);
};

export const searchResourceProviders = (criteria = {}) => {
    return axios.post('api/search/resourceProviders', criteria);
};

export const getResourceProviderById = (id) => {
    return axios.get(`api/resourceProviders/${id}`);
};

export const createResourceProvider = (provider) => {
    return axios.post(`api/resourceProviders/`, provider);
};

export const updateResourceProvider = (provider) => {
    return axios.put(`api/resourceProviders/`, provider);
};

export const deleteResourceProviderById = (id) => {
    return axios.delete(`api/resourceProviders/${id}`);
};

export const getAdvicesForTag = (tadId) => {
    return axios.get(`api/advices/tags/${tadId}`);
};

export const getAdviceFileLink = (adviceId, fileId) => {
    return `api/advices/${adviceId}/files/${fileId}`;
};

export const getEmailColors = () => {
    return axios.get(`/api/emailTemplates/colors`);
};

export const getEducationEventsForPerson = (personId) => {
    return axios.get(`api/person/${personId}/educationEvents`);
};

export const getEducationEventByIdForPerson = (personId, eventId) => {
    return axios.get(`api/person/${personId}/educationEvents/${eventId}`);
};

export const createEducationEvent = (educationEvent) => {
    return axios.post(`api/person/${educationEvent.personId}/educationEvents`, educationEvent);
};

export const updateEducationEvent = (educationEvent) => {
    return axios.put(`api/person/${educationEvent.personId}/educationEvents`, educationEvent);
};

export const sendPersons = (type, testName, personsIds, dueDate) => {
    return axios.post(`api/leader/sendPersons`, {
        type: type,
        testName: testName,
        persons: personsIds,
        dueDate,
    });
};

export const getPersonComments = (personId, criteria = { pageNum: 1, pageSize: 1000 }) => {
    return axios.get(`api/person/${personId}/comments`, {
        params: criteria,
    });
};

export const getPersonComment = (personId) => {
    return axios.get(`api/person/${personId}/comment`);
};

export const getIsExistCommentsForPerson = (personId) => {
    return axios.get(`api/person/${personId}/comments/check`);
};

export const deleteComment = (commentId) => {
    return axios.delete(`api/person/comment/${commentId}`);
};

export const checkCommentsExist = (personIds = []) => {
    return axios.post(`api/Person/comments/check`, personIds);
};

export const setPersonsComments = (
    personIds = [],
    forceUpdate = true,
    text = '',
    visibleToAll = true,
) => {
    const comments = {
        personIds,
        forceUpdate,
        comment: { text, visibleToAll },
    };

    return axios.put(`api/Person/comments`, comments);
};

export const addSearchTemplateComment = (ids, text) => {
    return axios.post(`api/fullTextSearch/comments`, { ids, text });
};

export const uploadContentFiles = (formData, onUploadProgress = () => {}) => {
    return axios.post(`api/content/files`, formData, { onUploadProgress });
};

export const updateContent = (content) => {
    return axios.put(`api/content`, content);
};

export const saveContent = (content) => {
    return axios.post(`api/content`, content);
};

export const printPdf = (id) => {
    return axios.get(`/api/content/${id}/print`, { responseType: 'blob' });
};
export const printInstructionRoute = (id) =>
    buildUrl(`/api/content/${id}/print`, { clientDate: moment().format() });

export const getContentById = (id, isHtml = false) => {
    return axios.get(`api/content/${id}`, { params: { isHtml } });
};

export const getContentByIdFromEditor = (id, isHtml = false) => {
    return axios.get(`api/content/${id}/all`, { params: { isHtml } });
};

export const getHtmContentById = (id) => {
    return axios.get(`api/content/${id}/html`);
};

export const getHtmContent = (summary) => {
    return axios.post(`api/content/html`, { summary });
};

export const getContentCategories = (parentId = null, publishOnly = true) => {
    return parentId
        ? axios.get(`api/content/category/${parentId}`, { params: { publishOnly } })
        : axios.get(`/api/content/category`, { params: { publishOnly } });
};

export const getCategoriesWithContents = (data) =>
    axios.post('api/content/category/withContents', data);

export const searchContentCategories = (criteria) => {
    return axios.post('api/content/category/search', criteria || {});
};

export const createNewCategory = (category) => {
    return axios.post('api/content/category', category);
};

export const updateCategory = (category) => {
    return axios.put('api/content/category', category);
};

export const deleteCategory = (categoryId) => {
    return axios.delete(`api/content/category?id=${categoryId}`);
};

export const getLastModifiedContents = (number = 10) => {
    return axios.get(`api/content/lastModified?num=${number}`);
};

export const deleteContent = (id) => {
    return axios.delete(`api/content/${id}`);
};

export const changeStatus = (id, status) => {
    return axios.put(`api/content/${id}/status/${status}`);
};

export const copyContent = (id) => {
    return axios.post(`api/content/${id}/copy`);
};

export const getAvailableCategories = () => {
    return axios.get(`api/content/category/allowed`);
};

export const getTreeByCategory = (id, onlyPublish = true) =>
    axios.get(`/api/content/category/ancestors`, { params: { parentId: id, onlyPublish } });

export const updateTreePositions = (list) => {
    return axios.post(`/api/content/category/positions`, list);
};

export const getRoles = () => {
    return axios.get('api/roles');
};

export const changeInterface = (payload) => {
    return axios.post('api/user/change/interface', payload);
};

export const getPersonAgeRange = () => axios.get('api/Person/minMaxAgeInfo');

export const makeRequestCreator = (callback) => {
    let call;
    return function (criteria) {
        if (call) {
            call.cancel();
        }
        call = axios.CancelToken.source();
        return callback(criteria, call);
    };
};

export const searchTopContentTitles = (searchTerm) => {
    return searchTerm && axios.get(`/api/fullTextSearch/contentTitles?searchTerm=${searchTerm}`);
};

export const searchContent = (criteria = {}) => {
    return axios.post(`api/fullTextSearch/contents`, criteria);
};

export const getMassEmails = (criteria) => {
    return axios.post(`api/letter/find`, criteria);
};

export const saveMassEmail = (criteria) => {
    return axios.put(`/api/letter`, criteria);
};

export const getMassEmailById = (id) => {
    return axios.get(`/api/letter/${id}`);
};

export const deleteMassEmailById = (id) => {
    return axios.delete(`/api/letter?id=${id}`);
};

export const getMassEmailHTMLCode = (data) => {
    return axios.post(`/api/letter/html`, data);
};

export const getAdminUserInfo = (userId) => {
    return axios.get(`/api/User/${userId}/card`);
};

export const getAdminUserActions = (userId, criteria) => {
    return axios.post(`/api/User/${userId}/actions`, criteria);
};

export const getAdminUserLeaders = (userId, criteria) => {
    return axios.post(`/api/User/${userId}/leaders`, criteria);
};

export const getAdminUserExperts = (userId, criteria) => {
    return axios.post(`/api/User/${userId}/experts`, criteria);
};

export const getAdminUserIssues = (userId, criteria) => {
    return axios.post(`/api/User/${userId}/issues`, criteria);
};

export const createMassEmailCampaign = (letterId) => {
    return axios.put(`/api/campaign/`, { letterId });
};

export const getActiveCampaignByMassEmailId = (id) => {
    return axios.get(`/api/campaign/letter/${id}`);
};

export const addRecipientsToCampaign = (campaignId, recipients = []) => {
    return axios.post(`/api/campaign/${campaignId}/address`, recipients);
};

export const deleteRecipientsFromCampaign = (campaignId, recipients = []) => {
    return axios.delete(`/api/campaign/${campaignId}/address`, { data: recipients });
};

export const getRecipientsByCampaignId = (campaignId, criteria) => {
    return axios.post(`/api/campaign/${campaignId}/address/find`, criteria);
};

export const getMassEmailCampaigns = (criteria) => {
    return axios.post(`/api/campaign/find`, criteria);
};

export const getSentMassEmailCampaigns = (criteria) => {
    return axios.post(`/api/campaign/findSent`, criteria);
};

export const sendMassEmail = (letterId, force = false) => {
    return axios.put(`/api/letter/${letterId}/send?force=${force}`);
};

export const getCampaignById = (campaignId) => {
    return axios.get(`/api/campaign/${campaignId}`);
};

export const copyCampaign = (campaignId) => {
    return axios.get(`/api/campaign/${campaignId}/copy`);
};

export const getCampaignHtml = (campaignId) => {
    return axios.get(`/api/campaign/${campaignId}/html`);
};
export const getAdminGroupExperts = (groupId, criteria) => {
    return axios.post(`/api/Group/${groupId}/experts`, criteria);
};

export const getAdminGroupCompetencyModels = (groupId, criteria) => {
    return axios.post(`/api/Group/${groupId}/competencyModels`, criteria);
};

export const getAdminGroupQuotas = (groupId, criteria) => {
    return axios.post(`/api/Group/${groupId}/quotas`, criteria);
};

export const getAdminUserEvaluationRequests = (userId, criteria) => {
    return axios.post(`/api/User/${userId}/evaluationRequests`, criteria);
};

export const getAdminUserEvaluationRequestsMade = (userId, criteria) => {
    return axios.post(`/api/User/${userId}/evaluationRequestsMade`, criteria);
};

export const getAdminUserAccessRequests = (userId, criteria) => {
    return axios.post(`/api/User/${userId}/accessRequests`, criteria);
};

export const getAdminUserAccessRequestsMade = (userId, criteria) => {
    return axios.post(`/api/User/${userId}/accessRequestsMade`, criteria);
};

export const logUIError = (error) => {
    return axios.post(`/api/logger/clientError`, error);
};

export const getSmsEmailGateMessageById = (id) => {
    return axios.get(`/api/messageHistory/${id}`);
};

export const getSmsEmailGateMessages = (criteria) => {
    return axios.post(`/api/messageHistory`, criteria);
};

export const getSmsEmailGateMessagesByUserId = (userId, criteria) => {
    return axios.post(`/api/messageHistory/user/${userId}`, criteria);
};

export const getCompetenceIndicatorsTree = () => {
    return axios.get('/api/CompetenciesGroups');
};

export const canCreateModel = () => {
    return axios.get('/api/competencyModel/group');
};

export const checkPerson = (data = []) => {
    return axios.post('/api/Person/Check', data)
};

export const personsFileUpload = (file) => {
    return axios.post('/api/Person/CheckByFile', file)
};

export const setPersonAccess = (persons = [], restore = false) => {
    if (restore) {
        return axios.post('/api/Person/Restore', persons)
    } else {
        return axios.post('/api/Person/Delete', persons)
    }
};