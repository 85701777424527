import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import './Logo.scss';

function Logo(props) {
    const {
        title,
        afterTitle,
        className,
        darken,
        size,
        transparent,
        push,
        svgLogo,
        location,
        auth,
        noUrl = false,
    } = props;
    const renderHome = () => !noUrl && push(!auth.user ? location.pathname : '/');

    const finalClass =
        'kr_logo v2__Logo' +
        (className ? ' ' + className : '') +
        (darken ? ' v2__Logo_Darken' : '') +
        (size ? ` v2__Logo_${size}` : '');

    const afterTitleClass =
        'v2__Logo__AfterTitle' + (transparent ? ' v2__Logo__AfterTitle_Transparent' : '');

    const lastLetterOpacity = transparent ? 0.7 : 1;

    const showLogo = (size) => {
        return size === 'lg' ? (
            <svg
                width="137pt"
                height="39pt"
                viewBox="0 0 137 39"
                xmlns="http://www.w3.org/2000/svg"
                className="v2__Logo--shadow"
            >
                <path
                    fill="#FFFFFF"
                    d="M 30.316406 32.730469 L 23.234375 32.730469 L 23.234375 18.546875 L 12.203125 32.730469 L 5.929688 32.730469 L 5.929688 6.875 L 13.015625 6.875 L 13.015625 20.542969 L 23.636719 6.875 L 30.316406 6.875 Z M 30.316406 32.730469 "
                />
                <path
                    fill="#FFFFFF"
                    d="M 34.003906 19.804688 C 34.003906 12.269531 39.792969 6.359375 47.613281 6.359375 C 52.890625 6.359375 56.171875 8.648438 58.460938 11.824219 L 53.222656 15.890625 C 51.746094 14.042969 50.011719 12.898438 47.542969 12.898438 C 43.925781 12.898438 41.378906 15.960938 41.378906 19.804688 C 41.378906 23.683594 43.925781 26.710938 47.542969 26.710938 C 50.234375 26.710938 51.820312 25.457031 53.371094 23.570312 L 58.757812 27.414062 C 56.320312 30.773438 53.039062 33.25 47.320312 33.25 C 39.941406 33.25 34.003906 27.597656 34.003906 19.804688 Z M 34.003906 19.804688 "
                />
                <path
                    fill="#FFFFFF"
                    d="M 61.816406 6.875 L 68.972656 6.875 L 68.972656 17.367188 L 77.863281 6.875 L 86.347656 6.875 L 76.574219 17.996094 L 86.679688 32.730469 L 78.085938 32.730469 L 71.59375 23.128906 L 68.972656 26.046875 L 68.972656 32.730469 L 61.816406 32.730469 Z M 61.816406 6.875 "
                />
                <path
                    fill="#FFFFFF"
                    d="M 88.746094 6.875 L 99.8125 6.875 C 106.339844 6.875 110.546875 10.238281 110.546875 16.035156 C 110.546875 22.132812 105.863281 25.34375 99.441406 25.34375 L 95.902344 25.34375 L 95.902344 32.730469 L 88.746094 32.730469 Z M 99.296875 19.730469 C 101.839844 19.730469 103.390625 18.402344 103.390625 16.296875 C 103.390625 14.078125 101.839844 12.898438 99.257812 12.898438 L 95.902344 12.898438 L 95.902344 19.730469 Z M 99.296875 19.730469 "
                />
                <path
                    fill="#FFFFFF"
                    fillOpacity={lastLetterOpacity}
                    d="M 111.613281 26.933594 C 111.613281 22.796875 114.75 20.652344 119.4375 20.652344 C 121.28125 20.652344 123.089844 21.023438 124.230469 21.429688 L 124.230469 21.097656 C 124.230469 19.027344 122.941406 17.847656 120.246094 17.847656 C 118.144531 17.847656 116.523438 18.253906 114.75 18.953125 L 113.3125 14.003906 C 115.5625 13.082031 117.960938 12.453125 121.390625 12.453125 C 124.96875 12.453125 127.402344 13.304688 128.992188 14.890625 C 130.503906 16.367188 131.128906 18.4375 131.128906 21.246094 L 131.128906 32.730469 L 124.195312 32.730469 L 124.195312 30.664062 C 122.828125 32.179688 120.949219 33.175781 118.328125 33.175781 C 114.53125 33.175781 111.613281 30.996094 111.613281 26.933594 Z M 124.304688 25.492188 L 124.304688 24.570312 C 123.605469 24.273438 122.609375 24.050781 121.574219 24.050781 C 119.546875 24.050781 118.367188 25.050781 118.367188 26.601562 C 118.367188 27.96875 119.398438 28.78125 120.835938 28.78125 C 122.867188 28.78125 124.304688 27.488281 124.304688 25.492188 Z M 124.304688 25.492188 "
                />
            </svg>
        ) : (
            <svg width="87pt" height="25pt" viewBox="0 0 87 25" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill="#011B71"
                    d="M 19.25 20.980469 L 14.753906 20.980469 L 14.753906 11.890625 L 7.75 20.980469 L 3.765625 20.980469 L 3.765625 4.40625 L 8.265625 4.40625 L 8.265625 13.167969 L 15.011719 4.40625 L 19.25 4.40625 Z M 19.25 20.980469 "
                />
                <path
                    fill="#011B71"
                    d="M 21.59375 12.695312 C 21.59375 7.863281 25.269531 4.078125 30.238281 4.078125 C 33.585938 4.078125 35.671875 5.542969 37.125 7.582031 L 33.796875 10.183594 C 32.859375 9 31.761719 8.265625 30.191406 8.265625 C 27.894531 8.265625 26.277344 10.230469 26.277344 12.695312 C 26.277344 15.179688 27.894531 17.121094 30.191406 17.121094 C 31.902344 17.121094 32.90625 16.316406 33.890625 15.109375 L 37.3125 17.574219 C 35.765625 19.726562 33.679688 21.3125 30.050781 21.3125 C 25.363281 21.3125 21.59375 17.691406 21.59375 12.695312 Z M 21.59375 12.695312 "
                />
                <path
                    fill="#011B71"
                    d="M 39.253906 4.40625 L 43.800781 4.40625 L 43.800781 11.132812 L 49.445312 4.40625 L 54.835938 4.40625 L 48.625 11.535156 L 55.046875 20.980469 L 49.585938 20.980469 L 45.464844 14.824219 L 43.800781 16.695312 L 43.800781 20.980469 L 39.253906 20.980469 Z M 39.253906 4.40625 "
                />
                <path
                    fill="#011B71"
                    d="M 56.355469 4.40625 L 63.382812 4.40625 C 67.53125 4.40625 70.203125 6.5625 70.203125 10.28125 C 70.203125 14.1875 67.226562 16.246094 63.148438 16.246094 L 60.902344 16.246094 L 60.902344 20.980469 L 56.355469 20.980469 Z M 63.054688 12.648438 C 64.671875 12.648438 65.65625 11.796875 65.65625 10.445312 C 65.65625 9.023438 64.671875 8.265625 63.03125 8.265625 L 60.902344 8.265625 L 60.902344 12.648438 Z M 63.054688 12.648438 "
                />
                <path
                    fill="#0A84FF"
                    d="M 70.878906 17.265625 C 70.878906 14.613281 72.871094 13.238281 75.847656 13.238281 C 77.015625 13.238281 78.164062 13.476562 78.890625 13.738281 L 78.890625 13.523438 C 78.890625 12.199219 78.070312 11.441406 76.363281 11.441406 C 75.027344 11.441406 73.996094 11.699219 72.871094 12.152344 L 71.957031 8.976562 C 73.386719 8.386719 74.910156 7.984375 77.085938 7.984375 C 79.359375 7.984375 80.90625 8.527344 81.914062 9.546875 C 82.875 10.492188 83.273438 11.820312 83.273438 13.617188 L 83.273438 20.980469 L 78.867188 20.980469 L 78.867188 19.65625 C 78 20.625 76.808594 21.265625 75.144531 21.265625 C 72.730469 21.265625 70.878906 19.871094 70.878906 17.265625 Z M 78.9375 16.339844 L 78.9375 15.75 C 78.492188 15.558594 77.859375 15.417969 77.203125 15.417969 C 75.917969 15.417969 75.167969 16.058594 75.167969 17.050781 C 75.167969 17.929688 75.824219 18.449219 76.738281 18.449219 C 78.023438 18.449219 78.9375 17.621094 78.9375 16.339844 Z M 78.9375 16.339844 "
                />
            </svg>
        );
    };

    return (
        <div className={finalClass} onClick={renderHome}>
            {svgLogo ? (
                showLogo(size)
            ) : (
                <>
                    <span className="v2__Logo__Title">{title}</span>
                    <span className={afterTitleClass}>{afterTitle}</span>
                </>
            )}
        </div>
    );
}

const props = (state) => ({auth: state.auth});
const actions = { push };

export default withRouter(connect(props, actions)(Logo));
