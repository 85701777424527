import { appName } from '../constants';
import { put, all, takeLatest, select, call } from 'redux-saga/effects';
import { show as showAcceptModal } from './AcceptModal';
import {
    uploadImportFile as uploadImportFileApi,
    importSave as importSaveApi,
    importCancel as importCancelApi,
    saveImportUserSession as saveSessionApi,
    getImportUserSession as getSessionApi,
    deleteImportUserSession as deleteSessionApi
} from "api";
import { serviceResultCode, getError } from 'serviceErrors';
import { showErrorAlert, showWarningAlert } from './Alert';
import { isNullOrWhitespace } from 'utils';

const moduleName = 'import';
export const INIT_WIZARD_START = `${appName}/${moduleName}/INIT_WIZARD_START`;
export const INIT_WIZARD_FINISH = `${appName}/${moduleName}/INIT_WIZARD_FINISH`;
export const STEP_SET_START = `${appName}/${moduleName}/STEP_SET_START`;
export const STEP_SET_FINISH = `${appName}/${moduleName}/STEP_SET_FINISH`;

export const COMPLETE_STEP_START = `${appName}/${moduleName}/COMPLETE_STEP_START`;
export const COMPLETE_STEP_FINISH = `${appName}/${moduleName}/COMPLETE_STEP_FINISH`;
export const UPLOAD_FILE = `${appName}/${moduleName}/UPLOAD_FILE`;
export const UPLOAD_FILE_START = `${appName}/${moduleName}/UPLOAD_FILE_START`;
export const UPLOAD_FILE_SUCCESS = `${appName}/${moduleName}/UPLOAD_FILE_SUCCESS`;
export const UPLOAD_FILE_FAILED = `${appName}/${moduleName}/UPLOAD_FILE_FAILED`;
export const CLEAR_IMPORT = `${appName}/${moduleName}/CLEAR_IMPORT`;
export const NEW_IMPORT = `${appName}/${moduleName}/NEW_IMPORT`;
export const CANCEL_IMPORT = `${appName}/${moduleName}/CANCEL_IMPORT`;
export const CANCEL_IMPORT_SUCCESS = `${appName}/${moduleName}/CANCEL_IMPORT_SUCCESS`;
export const SAVE_IMPORT_START = `${appName}/${moduleName}/SAVE_IMPORT_START`;
export const SAVE_IMPORT_FINISH = `${appName}/${moduleName}/SAVE_IMPORT_FINISH`;

export const STEP_NEXT = `${appName}/${moduleName}/STEP_NEXT`;
export const STEP_PREVIOUS = `${appName}/${moduleName}/STEP_PREVIOUS`;

const initialState = {
    initialized: false,
    complete: false,
    activeStep: null,
    totalSteps: 0,
    menuItems: [],
    steps: [],
    upload: false,
    fileId: null,
    fileErrors: null,
    data: {}
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case INIT_WIZARD_FINISH:
            return {
                ...payload,
                initialized: true,
            }
        case STEP_SET_FINISH:
            return {
                ...state,
                activeStep: payload
            }
        case COMPLETE_STEP_FINISH:
            return {
                ...state,
                data: {
                    ...state.data,
                    [payload.id]: payload.data
                }
            }
        case UPLOAD_FILE_START:
            return {
                ...state,
                upload: true
            };
        case UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                fileId: payload,
                fileErrors: null,
                upload: false
            };
        case UPLOAD_FILE_FAILED:
            return {
                ...state,
                fileId: null,
                fileErrors: payload,
                upload: false
            };
        case CLEAR_IMPORT:
            return {
                ...initialState
            };
        case SAVE_IMPORT_FINISH:
            return {
                ...state,
                activeStep: payload,
                fileId: null,
                complete: true
            };
        default:
            return state;
    }
}

export function initialize(payload) {
    return {
        type: INIT_WIZARD_START,
        payload: payload
    }
}

export function completeStep(id, data) {
    return {
        type: COMPLETE_STEP_START,
        payload: {id, data}
    }

}

export function newImport() {
    return {
        type: NEW_IMPORT
    };
}

export function setStep(page) {
    return {
        type:STEP_SET_START,
        payload: page
    }
}

function setStepComplete(page) {
    return {
        type: STEP_SET_FINISH,
        payload: page
    }
}

export function saveImport(fileId, nextStep) {
    return {
        type: SAVE_IMPORT_START,
        payload: { fileId, nextStep }
    };
}

function saveImportComplete(page) {
    return {
        type: SAVE_IMPORT_FINISH,
        payload: page
    };
}

export function cancelImport(fileId) {
    return {
        type: CANCEL_IMPORT,
        payload: { fileId }
    };
}

function clearImport() {
    return {
        type: CLEAR_IMPORT
    };
}

const saveState = async (state) => {
    if (state.complete) { return; }

    try {
        await saveSessionApi(state);
        return true;
    }
    catch (error) {
        return false;
    }
}

const clearState = async () => {
    try {
        await deleteSessionApi();
        return true;
    }
    catch (error) {
        return false;
    }
}

const getState = async (fallbackState) => {
    try {
        const response = await getSessionApi();
        if (response && response.data) {
            return {
                ok: {
                    payload: response.data
                }
            };
        }

        return {
            ok: {
                payload: fallbackState
            }
        };
    }
    catch (error) {
        return {
            error: true
        };
    }
}

const getStateErrorText = "Произошла ошибка при загрузке сессии импорта. Пожалуйста, попробуйте повторить операцию позднее.";
const saveStateErrorText = "Произошла ошибка при сохранении сессии импорта. Пожалуйста, попробуйте повторить операцию позднее.";
const saveStateWarningText = "Произошла ошибка при сохранении сессии импорта.";
const clearStateWarningText = "Произошла ошибка при очистке сессии импорта.";

function initComplete(payload) {
    return {
        type: INIT_WIZARD_FINISH,
        payload:payload
    };
}

function completeStepFinish(payload) {
    return {
        type: COMPLETE_STEP_START,
        payload: payload
    }
}


const storageInit = function* (action) {
    const result = yield call(getState, action.payload);
    if (result.error) {
        yield put(showErrorAlert(getStateErrorText));
        return;
    }

    yield put(initComplete(result.ok.payload));
}

const storageSetStep = function* (action) {
    const state = {
        ...yield select(state => state.import),
        activeStep: action.payload
    };

    const ok = yield call(saveState, state);
    if (!ok) {
        yield put(showErrorAlert(saveStateErrorText));
        return;
    }

    yield put(setStepComplete(action.payload));
}

const storageCompleteStep = function* (action) {
    const state = yield select(state => state.import);
    const ok = yield call(saveState, state);
    if (!ok) {
        yield put(showErrorAlert(saveStateErrorText));
        return;
    }

    yield put(completeStepFinish(action.payload));
}

const storageNewImport = function* () {
    const ok = yield call(clearState);
    if (!ok) {
        yield put(showWarningAlert(clearStateWarningText));
    }

    yield put(clearImport());
}

const storageSaveImport = function* (action) {
    const ok = yield call(clearState);
    if (!ok) {
        yield put(showWarningAlert(clearStateWarningText));
    }

    try {
        yield call(importSaveApi, action.payload.fileId);
        clearState();
        yield put(saveImportComplete(action.payload.nextStep));
    }
    catch (error) {
        const reqError = getError(error, getImportError);
        yield put(showErrorAlert(reqError.message));
    }
}

export const uploadImportFile = (file) => {
    return {
        type: UPLOAD_FILE,
        payload: file
    }
}

const uploadImportFileStart = () => {
    return {
        type: UPLOAD_FILE_START
    }
}

const uploadImportFileSuccess = (guid) => {
    return {
        type: UPLOAD_FILE_SUCCESS,
        payload: guid
    }
}

const uploadImportFileFailed = (errors) => {
    return {
        type: UPLOAD_FILE_FAILED,
        payload: errors
    }
}

export const showAcceptForCancelImport = function* (action) {

    const title = `Отмена импорта`;
    const question = `Вы действительно хотите отменить импорт?`;
    const actions = [
        { title: "Да", value: action.payload.fileId, color: "primary" },
        { title: "Нет", value: null, color: "secondary" },
    ];

    yield put(showAcceptModal(title, question, actions, CANCEL_IMPORT_SUCCESS));
};

const cancelImportSaga = function* (action) {

    if (isNullOrWhitespace(action.payload.value)) {
        const ok = yield call(clearState);
        if (!ok) {
            yield put(showWarningAlert(clearStateWarningText));
        } else {
            yield put(clearImport());
        }

        return;
    }

    try {
        yield call(importCancelApi, action.payload.value);
        const ok = yield call(clearState);
        if (!ok) {
            yield put(showWarningAlert(clearStateWarningText));
        } else {
            yield put(clearImport());
        }
    }
    catch (error) {
        const reqError = getError(error, getImportError);
        yield put(showErrorAlert(reqError.message));
    }
}

const getImportError = (code) => {
    switch (code) {
        case serviceResultCode.ProfileImportTooLarge:
            return `Файл должен быть меньше 100 Мб`;
        case serviceResultCode.ProfileImportWrongType:
            return `Неверный формат файла`;
        case serviceResultCode.ProfileImportErrorSave:
            return `Произошла ошибка при сохранении`;
        case serviceResultCode.ProfileImportEmptyFile:
            return `Отсутствуют данные для загрузки`;
        case serviceResultCode.ProfileImportCancelNotFound:
            return `Импорт не найден`;
        case serviceResultCode.ProfileImportCancelDeleteError:
            return `Произошла ошибка при удалении данных`;
        case serviceResultCode.ProfileImportUnsupportedVersion:
            return `Версия загруженного шаблона импорта не поддерживается`;
        default:
            return "Произошла непредвиденная ошибка"
    }
}

const uploadImportFileSaga = function* (action) {
    yield put(uploadImportFileStart());
    try {
        const request = yield call(uploadImportFileApi, action.payload);
        yield put(uploadImportFileSuccess(request.data));
        const state = yield select(state => state.import);

        const ok = yield call(saveState, state);
        if (!ok) {
            yield put(showWarningAlert(saveStateWarningText));
        }
    }
    catch (error) {
        if (error && error.response && error.response.data) {

            yield put(uploadImportFileFailed(error.response.data));

            if (error.response.data.code
                && error.response.data.code !== serviceResultCode.ProfileImportErrors) {
                const reqError = getError(error, getImportError);

                yield put(showErrorAlert(reqError.message));
            }
        }
    }
}

export const allActions = {
    initialize, setStep, completeStep, newImport, saveImport, cancelImport, uploadImportFile
}

export const saga = function* () {
    yield all([
        takeLatest(CANCEL_IMPORT, showAcceptForCancelImport),
        takeLatest(INIT_WIZARD_START, storageInit),
        takeLatest(STEP_SET_START, storageSetStep),
        takeLatest(COMPLETE_STEP_START, storageCompleteStep),
        takeLatest(NEW_IMPORT, storageNewImport),
        takeLatest(CANCEL_IMPORT_SUCCESS, cancelImportSaga),
        takeLatest(SAVE_IMPORT_START, storageSaveImport),
        takeLatest(UPLOAD_FILE, uploadImportFileSaga),
    ]);
}