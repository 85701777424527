import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import Popover from 'components/Lk/Uikit/Popover';
import Button from 'components/uikit/Button';

const TreeEditMenu = (props) => {
    const {
        selectedItem,
        itemsInCategory,
        extItems,
        moveElem = undefined,
        createItem = undefined,
        createCategory = undefined,
        refreshTree = undefined,
        deleteItem = undefined,
        disabled = false,
    } = props;

    const menuItems = [
        {
            id: 'moveUp',
            icon: 'arrow-circle-up',
            checkProp: 'canMoveUp',
            callback: () => moveElem(selectedItem.parentId, selectedItem.position),
            tooltip: 'Переместить вверх',
        },
        {
            id: 'moveDown',
            icon: 'arrow-circle-down',
            checkProp: 'canMoveDown',
            callback: () => moveElem(selectedItem.parentId, selectedItem.position, false),
            tooltip: 'Переместить вниз',
        },
        {
            id: 'newCategory',
            icon: 'folder-plus',
            canExecuteUnselected: true,
            checkProp: 'canAddCategory',
            callback: createCategory,
            tooltip: 'Создать новую категорию',
        },
        {
            id: 'newArticle',
            icon: 'file-alt',
            canExecuteUnselected: false,
            checkProp: 'canAddArticle',
            callback: createItem,
            tooltip: 'Создать новую инструкцию',
        },
        {
            id: 'refresh',
            icon: 'sync',
            canExecute: true,
            callback: refreshTree,
            tooltip: 'Обновить',
        },
        {
            id: 'delete',
            icon: 'trash',
            checkProp: 'canDelete',
            callback: deleteItem,
            tooltip: 'Удалить',
        },
    ];

    const canExecute = (x) => {
        if (x.id === 'moveDown' && selectedItem && selectedItem.isArticle) {
            return (
                (selectedItem && selectedItem.position < itemsInCategory[selectedItem.parentId] - 1) ||
                (!selectedItem && x.canExecuteUnselected) ||
                x.canExecute
            );
        } else {
            return (
                (selectedItem && selectedItem[x.checkProp]) ||
                (!selectedItem && x.canExecuteUnselected) ||
                x.canExecute
            );
        }
    };

    return (
        <div className="TreeEditMenu">
            {menuItems.map((x) => (
                <Popover
                    key={`popover${x.id}`}
                    tooltip={x.tooltip || null}
                    className="TreeEditMenu__IconTooltip"
                    autoPlacement={false}
                >
                    <div
                        className={classnames('TreeEditMenu__Item', {
                            'TreeEditMenu__Item--disabled': !canExecute(x),
                        })}
                        key={x.id}
                        onClick={canExecute(x) ? (!disabled ? x.callback : undefined) : undefined}
                    >
                        <FontAwesomeIcon icon={x.icon} color="#fff" />
                    </div>
                </Popover>
            ))}

            {extItems && (
                <div>
                    {extItems.map((x) => (
                        <Button
                            key={x.key}
                            onClick={x.onClick}
                            className="TreeEditMenu__Item TreeEditMenu__Item--extItem"
                        >
                            <span className="TreeEditMenu__ItemIcon">
                                <Popover
                                    key={`popover${x.key}`}
                                    tooltip={x.title}
                                    className="TreeEditMenu__IconTooltip--ext"
                                    autoPlacement={false}
                                >
                                    <FontAwesomeIcon icon={x.icon} size="lg" />
                                </Popover>
                            </span>
                            <span className="TreeEditMenu__ItemTitle">{x.title}</span>
                        </Button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default TreeEditMenu;
