import { getUserAction, getUserActionForSupportLine, searchUserActions } from 'api';
import { GROUPS_DICTIONARY_NAME, ROLES_DICTIONARY_NAME, USERS_DICTIONARY_NAME } from 'constants.js';
import { fetchDictionaries } from 'ducks/Dictionary';
import RequestError from 'RequestError';

export async function search(criteria) {
    try {
        const response = await searchUserActions(criteria);
        const { payload, meta } = response.data;

        return {
            userActions: payload,
            foundCount: meta.foundCount,
            pageCount: meta.pageCount,
        };
    } catch (error) {
        const errorMessage =
            'При загрузке действий пользователей произошла ошибка';

        throw new RequestError(error, errorMessage);
    }
}

export async function fetch(userActionId, isSupport) {
    try {
        const response = isSupport 
            ? await getUserActionForSupportLine(userActionId) 
            : await getUserAction(userActionId);
        
        return response.data;
    } catch (error) {
        const errorMessage =
            'При загрузке действия пользователя произошла ошибка';

        throw new RequestError(error, errorMessage);
    }
}

export function loadDictionaries() {
    return fetchDictionaries([USERS_DICTIONARY_NAME, ROLES_DICTIONARY_NAME, GROUPS_DICTIONARY_NAME]);
}

export function loadSupprotDictionaries() {
    return fetchDictionaries([ROLES_DICTIONARY_NAME, GROUPS_DICTIONARY_NAME]);
}
