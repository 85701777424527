import store from 'store';
import axios from 'axios';
import { signIn as signInRoute } from './routes';
import { signOutUnauthorized } from 'ducks/Auth';

axios.interceptors.response.use(response => response, error => {
    if (error.response && error.response.status === 401 && !window.location.href.includes(signInRoute.url)) {
        store.dispatch(signOutUnauthorized());
    }
    return Promise.reject(error);
});