import React from 'react';
import { lkDetailsProfile } from 'routes';
import { getUserFullName, getUserPositionAndCompany } from 'utils';
import { wordForm } from 'utils';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { USER_ROLES } from 'constants.js';
import { MENU_PROFILE } from '../Profile/constants';
import PersonCard from 'components/Lk/Common/PersonCard';

const SearchResultCard = props => {
    const {
        searchResult,
        onCheckClick = () => {},
        push,
        activePerson,
        onClickMenu = () => {},
        onClickCommentBlock = () => {},
        withMenu = true,
        withCheckbox = true,
        withComments,
        searchString,
        searchLoading,
    } = props;
    const getAgeStr = num => {
        if (num === 0) {
            return null;
        }

        return `${num} ${wordForm(num, ['год', 'года', 'лет'])}`;
    };

    const renderRole = role => {
        return USER_ROLES.find(x => x.code === role)?.name;
    };

    const clickMenuHandler = id => e => {
        e.stopPropagation();
        onClickMenu(id);
    };
    
    const renderSearchResults = () => {
        return searchResult && searchResult.length !== 0
            ? searchResult.map(x => {
                  return (
                      <PersonCard key={x.id} onClick={handleCardClick(x.id)}>
                          <PersonCard.CardAvatar
                              id={x.id}
                              isCheckable={withCheckbox}
                              isChecked={
                                  withCheckbox && activePerson && activePerson.indexOf(x.id) >= 0
                              }
                              onClick={handleCheckClick(x.id)}
                          />
                          <PersonCard.CardInfo isSearchCard>
                                <PersonCard.CardTitle title={getUserFullName(x)} />
                                <PersonCard.CardInfoText text={getUserPositionAndCompany(x)} />
                                <PersonCard.CardInfoText text={getAgeStr(+x.age)} isSubText />
                                <PersonCard.CardRoles roles={x.roles.map(renderRole)} />
                          </PersonCard.CardInfo>
                          {withComments && x.hasComment && <PersonCard.CardComments onClick={() => onClickCommentBlock(x.id)} />}
                          {withMenu && onClickMenu && <PersonCard.CardMenu onClick={clickMenuHandler(x.id)} />}
                      </PersonCard>
                  );
              })
            : !searchLoading && <div className="NotResult">Ничего не найдено</div>;
    };

    const handleCardClick = personId => () => {
        push({
            pathname: lkDetailsProfile.buildUrl({ id: personId }),
            search: queryString.stringify({ q: searchString, profileMenuId: MENU_PROFILE }),
        });
    };

    const handleCheckClick = personId => (e, check) => {
        e.stopPropagation();
        onCheckClick(personId, check);
    };

    return <div className="LKSearchCard__Results">{renderSearchResults()}</div>;
};

export default connect(
    null,
    { push },
)(SearchResultCard);
