import React from 'react';
import classnames from 'classnames';
import './EditWishLists.scss';

const Item = props => {
    const { name, id, isSelected, onClick } = props;
    return (
        <div
            className={classnames('LKEditWishListsItem', {
                'LKEditWishListsItem--selected': isSelected,
            })}
            onClick={onClick(id)}
        >
            {name}
        </div>
    );
};

export default Item;
