import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { isPathMatch, sidebarRoutes as routes } from 'routes';
import {
    isITAdmin,
    isSecurityAdmin,
    isModerator,
    canEditInstruction,
    isOperator,
    isUser,
    isSupportLine1,
    isSupportLine2,
} from 'rightsController';
import './CommonMenu.scss';

function CommonMenu(props) {
    const {
        path,
        mobileMenuIsOpen,
        onToggleMobileMenu,
        canEditPerson,
        user,
        canImportPerson,
    } = props;

    const isSupport = isSupportLine1(user) || isSupportLine2(user);

    const renderAdminMenu = () => {
        return (
            <div id="navigation" className={mobileMenuIsOpen ? 'open' : ''}>
                <div className="kr_menu admin_menu">
                    <ul>
                        <li
                            className={
                                'kr_item_admin ' +
                                (isPathMatch(path, routes.users.url) ||
                                isPathMatch('/', routes.users.url)
                                    ? 'kr_active'
                                    : '')
                            }
                        >
                            <Link to={routes.users.url} onClick={onToggleMobileMenu}>
                                {routes.users.title}
                            </Link>
                        </li>
                        <li
                            className={
                                'kr_item_admin ' +
                                (isPathMatch(path, routes.groups.url) ? 'kr_active' : '')
                            }
                        >
                            <Link to={routes.groups.url} onClick={onToggleMobileMenu}>
                                {routes.groups.title}
                            </Link>
                        </li>
                        {isSupport && (
                            <>
                                {isSupportLine2(user) && (
                                    <li
                                        className={
                                            'kr_item_admin ' +
                                            (isPathMatch(path, routes.adminPersons.url)
                                                ? 'kr_active'
                                                : '')
                                        }
                                    >
                                        <Link
                                            to={routes.adminPersons.url}
                                            onClick={onToggleMobileMenu}
                                        >
                                            {routes.adminPersons.title}
                                        </Link>
                                    </li>
                                )}

                                <li
                                    className={
                                        'kr_item_admin ' +
                                        (isPathMatch(path, routes.msgGate.url)
                                            ? 'kr_active'
                                            : '')
                                    }
                                >
                                    <Link to={routes.msgGate.url} onClick={onToggleMobileMenu}>
                                        {routes.msgGate.title}
                                    </Link>
                                </li>
                            </>
                        )}
                        {!isSupport && (
                            <>
                                <li
                                    className={
                                        'kr_item_admin ' +
                                        (isPathMatch(path, routes.access.url) ? 'kr_active' : '')
                                    }
                                >
                                    <Link to={routes.access.url} onClick={onToggleMobileMenu}>
                                        {routes.access.title}
                                    </Link>
                                </li>
                                <li
                                    className={
                                        'kr_item_admin ' +
                                        (isPathMatch(path, routes.usersActions.url)
                                            ? 'kr_active'
                                            : '')
                                    }
                                >
                                    <Link to={routes.usersActions.url} onClick={onToggleMobileMenu}>
                                        {routes.usersActions.title}
                                    </Link>
                                </li>
                                <li
                                    className={
                                        'kr_item_admin ' +
                                        (isPathMatch(path, routes.attributes.url)
                                            ? 'kr_active'
                                            : '')
                                    }
                                >
                                    <Link to={routes.attributes.url} onClick={onToggleMobileMenu}>
                                        {routes.attributes.title}
                                    </Link>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            </div>
        );
    };

    const renderModeratorMenu = () => {
        return (
            <div id="navigation" className={mobileMenuIsOpen ? 'open' : ''}>
                <div className="kr_menu">
                    <ul>
                        {canEditPerson && (
                            <li
                                className={
                                    'kr_item1 ' +
                                    (isPathMatch(path, routes.personNew.url) ? 'kr_active' : '')
                                }
                            >
                                <Link to={routes.personNew.url} onClick={onToggleMobileMenu}>
                                    <svg
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="444.9695653169651"
                                        height="531.8181895072913"
                                        viewBox="0 0 444.9695653169651 531.8181895072913"
                                    >
                                        <g transform="matrix(1,0,0,1,-43.90921734151749,0.09090524635439579)">
                                            <g>
                                                <g>
                                                    <g>
                                                        <circle
                                                            cx="262.294"
                                                            cy="149.655"
                                                            r="149.655"
                                                        />
                                                        <path d="M338.097,427.767c0-51.082,41.41-92.49,92.49-92.49c1.323,0,2.635,0.045,3.943,0.1     c-21.247-41.729-45.704-75.837-45.704-75.837s-57.806,62.998-138.735,57.806c-80.929-5.191-115.612-57.806-115.612-57.806     S7.304,375.151,53.549,467.641s300.593,57.807,300.593,57.807s19.505-1.461,43-11.451     C362.599,500.588,338.097,467.05,338.097,427.767z" />
                                                        <path d="M476.832,416.206h-34.684v-34.685c0-6.384-5.177-11.562-11.563-11.562c-6.384,0-11.56,5.177-11.56,11.562v34.685h-34.684     c-6.386,0-11.562,5.176-11.562,11.562s5.176,11.562,11.562,11.562h34.684v34.684c0,6.386,5.176,11.563,11.56,11.563     c6.386,0,11.563-5.177,11.563-11.563V439.33h34.684c6.386,0,11.562-5.176,11.562-11.562S483.218,416.206,476.832,416.206z" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    {routes.personNew.title}
                                </Link>
                            </li>
                        )}

                        <li
                            className={
                                'kr_item2 ' +
                                (isPathMatch(path, routes.search.url) ||
                                isPathMatch(path, routes.fullTextSearch.url)
                                    ? 'kr_active'
                                    : '')
                            }
                        >
                            <Link to={routes.search.url} onClick={onToggleMobileMenu}>
                                <svg
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="250.31199999999998"
                                    height="250.31199999999998"
                                    viewBox="0 0 250.31199999999998 250.31199999999998"
                                >
                                    <g transform="matrix(1,0,0,1,-0.1560000000000059,-0.1560000000000059)">
                                        <g>
                                            <path d="M244.186,214.604l-54.379-54.378c-0.289-0.289-0.628-0.491-0.93-0.76   c10.7-16.231,16.945-35.66,16.945-56.554C205.822,46.075,159.747,0,102.911,0S0,46.075,0,102.911   c0,56.835,46.074,102.911,102.91,102.911c20.895,0,40.323-6.245,56.554-16.945c0.269,0.301,0.47,0.64,0.759,0.929l54.38,54.38   c8.169,8.168,21.413,8.168,29.583,0C252.354,236.017,252.354,222.773,244.186,214.604z M102.911,170.146   c-37.134,0-67.236-30.102-67.236-67.235c0-37.134,30.103-67.236,67.236-67.236c37.132,0,67.235,30.103,67.235,67.236   C170.146,140.044,140.043,170.146,102.911,170.146z" />
                                        </g>
                                    </g>
                                </svg>
                                {routes.search.title}
                            </Link>
                        </li>
                        <li
                            className={
                                'kr_item3 ' +
                                (isPathMatch(path, routes.favorites.url) ? 'kr_active' : '')
                            }
                        >
                            <Link to={routes.favorites.url} onClick={onToggleMobileMenu}>
                                <FontAwesomeIcon icon={faStar} />
                                {routes.favorites.title}
                            </Link>
                        </li>
                        <li
                            className={
                                'kr_item5 ' +
                                (isPathMatch(path, routes.reports.url) ? 'kr_active' : '')
                            }
                        >
                            <Link to={routes.reports.url} onClick={onToggleMobileMenu}>
                                <svg
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="408"
                                    height="510"
                                    viewBox="0 0 408 510"
                                >
                                    <g transform="matrix(1,0,0,1,-51,0)">
                                        <g>
                                            <g>
                                                <path d="M102,0C73.95,0,51,22.95,51,51v408c0,28.05,22.95,51,51,51h306c28.05,0,51-22.95,51-51V153L306,0H102z M280.5,178.5V38.25    L420.75,178.5H280.5z" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                {routes.reports.title}
                            </Link>
                        </li>
                        {canImportPerson && (
                            <li
                                className={
                                    'kr_item6 ' +
                                    (isPathMatch(path, routes.importRoute.url) ? 'kr_active' : '')
                                }
                            >
                                <Link to={routes.importRoute.url} onClick={onToggleMobileMenu}>
                                    <svg
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="47.2950016783271"
                                        height="52.75599999999999"
                                        viewBox="0 0 47.2950016783271 52.75599999999999"
                                    >
                                        <g transform="matrix(1,0,0,1,-2.3785008391635483,-0.3780000000000001)">
                                            <g>
                                                <g>
                                                    <g>
                                                        <path d="M26.106,29.059l10.523-10.523c1.047-1.024,0.813-1.746-0.649-1.746h-5.873L30.108,4c0-2.209-1.79-4-4-4l0,0     c-2.209,0-4,1.791-4,4l-0.001,12.789h-5.876c-1.462-0.001-1.693,0.723-0.646,1.747L26.106,29.059z" />
                                                        <path d="M49.027,25.77h-6.049c-0.554,0-1,0.447-1,1v17.939H10.78V26.77c0-0.553-0.447-1-1-1H3.731c-0.553,0-1,0.447-1,1v20.464     c0,3.045,2.479,5.522,5.524,5.522h36.248c3.046,0,5.523-2.479,5.523-5.522V26.77C50.027,26.217,49.581,25.77,49.027,25.77z" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    {routes.importRoute.title}
                                </Link>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        );
    };

    const renderInstructionsEditorMenu = () => {
        return (
            <div id="navigation" className={mobileMenuIsOpen ? 'open' : ''}>
                <div className="kr_menu kr_menu--flex">
                    <ul className="NavigationMenu">
                        <li
                            className={classnames('kr_item1 NavigationMenu__Link', {
                                kr_active: isPathMatch(path, routes.instructionsEdit.url),
                            })}
                        >
                            <Link to={routes.instructionsEdit.url} onClick={onToggleMobileMenu}>
                                <svg
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="408"
                                    height="510"
                                    viewBox="0 0 408 510"
                                >
                                    <g transform="matrix(1,0,0,1,-51,0)">
                                        <g>
                                            <g>
                                                <path d="M102,0C73.95,0,51,22.95,51,51v408c0,28.05,22.95,51,51,51h306c28.05,0,51-22.95,51-51V153L306,0H102z M280.5,178.5V38.25    L420.75,178.5H280.5z" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                {routes.instructionsEdit.title}
                            </Link>
                        </li>
                        <li />
                        <li />
                        <li />
                        <li />
                        <li />
                    </ul>
                </div>
            </div>
        );
    };

    const renderEmptyMenu = () => {
        return (
            <div id="navigation" className={mobileMenuIsOpen ? 'open' : ''}>
                <div className="kr_menu kr_menu--flex">
                    <ul className="NavigationMenu"></ul>
                </div>
            </div>
        );
    };

    if (isITAdmin(props.user) || isSecurityAdmin(props.user) || isSupport) {
        return renderAdminMenu();
    }

    if (isModerator(props.user) || isOperator(props.user) || isUser(props.user)) {
        return renderModeratorMenu();
    }

    if (canEditInstruction(props.user)) {
        return renderInstructionsEditorMenu();
    }

    return renderEmptyMenu();
}

CommonMenu.propTypes = {
    className: PropTypes.string,
    path: PropTypes.string,
};

CommonMenu.defaultProps = {
    className: '',
};

function mapStateToProps(state) {
    return {
        path: state.router.location.pathname,
        user: state.auth.user,
    };
}

export default connect(mapStateToProps)(CommonMenu);
