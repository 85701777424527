import format from "date-fns/format";
import ruLocale from 'date-fns/locale/ru';

export const formatDate = (date) => { 
    return date && format(new Date(date), "PP", { locale: ruLocale })
};

export function numberAsPercentages(value, numbersAfterComma = 1) {
    if (typeof value !== "number") {
        return 0;
    }
    
    const factor = Math.pow(10, numbersAfterComma);
    return Math.round(value * factor) / factor + "%";
}
