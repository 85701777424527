import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { getEvaluationTestResultFileLink } from 'api';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import TestChart from './TestChart';
import ExpandedBlock from 'components/Lk/Uikit/ExpandedBlock';
import InfoField from 'components/Lk/Uikit/InfoField';
import Popover from 'components/Lk/Uikit/Popover';
import Icons from 'components/Lk/Uikit/Icons/Icons';
import { isDevice } from 'utils';
import { EVALUATIONTEST_STATUS } from 'constants.js';

export const Tests = (props) => {
    const { testsResults, testProviders, personId, competenciesModels, actual } = props;
    const renderTests = (actual = true) => {
        const testList =
            testsResults &&
            testsResults.length > 0 &&
            testsResults.filter((x) => x.resultType === (actual ? EVALUATIONTEST_STATUS.Relevant : EVALUATIONTEST_STATUS.Archived));

        if (testList && testList.length === 0 && !actual) {
            return;
        }

        return testList && testList.length !== 0 ? (
            <div className="Tests-Block">
                <div className="Tests-Block__List">{testList.map(renderActualTest)}</div>
            </div>
        ) : (
            <div className="LKProfileEvaluate__NoData">Нет данных</div>
        );
    };

    const stopPropagation = (e) => e.stopPropagation();

    const makeHeader = ({ title, description }) => {
        return description && !isDevice.SM() ? (
            <>
                {title}&nbsp;
                <Popover tooltip={description} autoPlacement={false}>
                    <Icons name="help-alt" fill={'#007AFF'} />
                </Popover>
            </>
        ) : (
            title
        );
    };

    const renderActualTest = (test) => {
        const testPassed = true; //TODO TEST FAILED ??????
        const showFileLink = test.result && test.result.resultFileId;
        const link = test.result && getEvaluationTestResultFileLink(test.result.id);
        const testProvider = testProviders.find((x) => x.id === test.testProviderId);

        return (
            <div className="LKLine" key={test.result.id}>
                <ExpandedBlock
                    fitted
                    direction="vertical"
                    header={makeHeader({ title: test.title ,description: test.description })}
                    subHeader={
                        <div className="TestBlockFooter">
                            <svg
                                className="TestBlockFooter__Status"
                                width="8"
                                height="8"
                                viewBox="0 0 8 8"
                            >
                                <circle cx="4" cy="4" r="3.5" fill="#52C41A" stroke="white" />
                            </svg>

                            {testPassed ? 'Пройден' : 'Сбой в тестировании'}
                            {showFileLink && (
                                <a
                                    onClick={stopPropagation}
                                    className="FileDownloadLink"
                                    href={link}
                                >
                                    <FontAwesomeIcon icon={faFileDownload} size="lg" />
                                </a>
                            )}
                        </div>
                    }
                >
                    <div className="LKSub-Line">
                        <TestChart
                            personId={personId}
                            test={test}
                            chartType={test.visualizationKind}
                            config={{withDescr: isDevice.SM()}}
                            testProviders={testProviders}
                            testsResults={testsResults}
                            competenciesModels={competenciesModels}
                        />
                    </div>

                    <InfoField noIcon title="Оценка получена">
                        {moment(test.result.completedOn).format('DD.MM.YYYY')}
                    </InfoField>

                    <InfoField noIcon title="Проведено">
                        {!!testProvider && testProvider.name}
                    </InfoField>
                </ExpandedBlock>
            </div>
        );
    };

    return <div>{renderTests(actual)}</div>;
};

export default Tests;
