import React from 'react';
import { localizeTimezone, getUserShortName } from 'utils';
import { converter } from 'libs/showdown';
import './CommentsBlock.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Comment = props => {
    const { comment, onWrap } = props;
    return (
        <div className="LKComment">
            <div className="LKComment__Wrapper">
                <div
                    className="LKComment__Text"
                    dangerouslySetInnerHTML={{
                        __html: converter.makeHtml(comment.text),
                    }}
                />
                {onWrap && (
                    <FontAwesomeIcon
                        icon={'chevron-right'}
                        className="LKComment__WrapperIcon"
                        fill="#A6B2C4"
                        color="#A6B2C4"
                        onClick={() => onWrap({ ...comment })}
                    />
                )}
            </div>

            <div className="LKComment__Info">
                <div>{getUserShortName(comment.user)}</div>
                <div>{localizeTimezone(comment.createdDate)}</div>
            </div>
        </div>
    );
};

export default Comment;
