import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import { GROUPS_DICTIONARY_NAME, ROLES_DICTIONARY_NAME } from 'constants.js';
import {
    searchContentCategories,
    updateCategory,
    getDictionary,
    getLastModifiedContents,
    updateContent,
    getTreeByCategory,
} from 'api';
import { Switch } from 'react-router';
import { push, replace } from 'connected-react-router';
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import { ALLOWED_EDIT_INSTRUCTION } from 'rightsController';
import { instructionsEdit, instructionsEditItem, instructionsEditCategory } from 'routes';

import Tree from 'components/common/Tree';
import ContentEditor from 'components/uikit/ContentEditor';
import CategoryEditor from './CategoryEditor';
import RecentPosts from './RecentPosts';
import ModalDialog from 'components/common/ModalDialog';
import './InstructionsEdit.scss';

const INSTRUCTIONS_EDITOR = { id: 7, name: 'Редактор инструкций пользователя' };
const CENTRE_GROUP_ID = 1;

class InstructionsEdit extends Component {
    state = {
        lastEditedView: true,
        selectedItem: null,
        treeLoading: true,
        recentPostsLoading: false,
        nodeLoading: false,
        treeData: [],
        recentPosts: [],
        modalOpen: false,
        modalHeader: '',
        modalWithoutButtons: false,
        modalCallBack: undefined,
        modalBody: '',
        roles: [],
        groups: [],
        modalProcessing: false,
        parentRoleIds: null,
        parentGroupIds: null,
        newItem: null,
        itemFullAccess: true,
        prevItemId: null,
        loadedItem: null,
    };

    setTreeData = (treeData) => {
        this.setState({ treeData });
    };

    setSelectedItem = (selectedItem) => {
        this.setState({ selectedItem });
    };

    setLoadedItem = (loadedItem) => {
        this.setState({ loadedItem });
    };

    setItemFullAccess = (itemFullAccess) => {
        this.setState({ itemFullAccess });
    };

    setCategories = (categories, category) => {
        const expandCategory = (item) => {
            return {
                ...item,
                expanded: !!item.children && item.children.length > 0,
                children: item.children ? item.children.map(expandCategory) : [],
            };
        };

        const [cat] = category;

        categories =
            !!categories && categories.length > 0
                ? categories.map((x) => (x.id === cat?.id ? expandCategory(cat) : x))
                : [];

        return categories;
    };

    fetchRecentPosts = async () => {
        const { currentUser } = this.props;
        try {
            const filter = currentUser.groupId === CENTRE_GROUP_ID ? null : currentUser.groupId;
            this.setState({ recentPostsLoading: true });
            const lastPostsData = await getLastModifiedContents();
            const recentPosts = filter
                ? lastPostsData.data.filter((x) => x.author.groupId === filter)
                : lastPostsData.data;
            this.setState({ recentPosts });
        } catch (e) {
            this.props.showErrorAlert('Ошибка загрузки инструкций');
        } finally {
            this.setState({ recentPostsLoading: false });
        }
    };

    fetchBranch = async (
        selectedParentId = this.state.selectedItem?.parentId,
        callBack = undefined,
    ) => {
        try {
            const {
                currentUser: { groupId },
            } = this.props;
            this.setState({ treeLoading: true });

            callBack && (await callBack());

            let { treeData } = this.state;
            if (selectedParentId && treeData.length !== 0) {
                const treeByCategory = await getTreeByCategory(selectedParentId, false);
                treeData = this.setCategories(treeData, treeByCategory.data);
            } else {
                const res = await searchContentCategories(
                    groupId === CENTRE_GROUP_ID ? '' : { groupIds: [groupId] },
                );
                treeData = res.data;
            }
            this.setState({ treeData });
        } catch (e) {
            this.props.showErrorAlert('Ошибка загрузки инструкций');
        } finally {
            this.setState({ treeLoading: false });
        }
    };

    removeItemFromParent = (treeData, item, newParentId) => {
        const data = treeData || [...this.state.treeData];
        let newData = data.map((x) => {
            if (x?.children) {
                this.removeItemFromParent(x.children, item, newParentId);
            }

            const deleteIndex = data.findIndex(
                (x) => x?.id === item.id && x?.parentId !== newParentId,
            );
            deleteIndex !== -1 && data.splice(deleteIndex, 1);
            return { ...x };
        });

        newData = newData.map((x) => {
            return {
                ...(x.children && x.children.length === 0
                    ? { ...x, hasChildren: false, expanded: false }
                    : { ...x }),
            };
        });

        this.setState({ treeData: newData });
    };

    refreshTreeFromRecentPosts = (deletedItem) => {
        const getParentId = (items) => {
            if (!items || items.length < 1) return null;

            for (const item of items) {
                if (item.id === deletedItem.categoryId) {
                    return item.id;
                }
                const parentId = getParentId(item.children);
                if (parentId) return parentId;
            }
            return null;
        };

        const parentId = getParentId(this.state.treeData);
        this.fetchRecentPosts();
        this.fetchBranch(parentId);
    };

    refreshRoot = async (callback = undefined) => {
        try {
            if (callback) {
                this.setState({ treeLoading: true });
                await callback();
            }
            !callback && this.onItemClick(null);
            await this.fetchBranch();
        } finally {
            this.setState({ treeLoading: false });
        }
    };

    refreshTree = async () => {
        await this.fetchBranch();
        await this.fetchRecentPosts();
        await this.props.push(instructionsEdit.buildUrl());
        this.selectItem(null);
    };

    fetchData = async () => {
        await this.fetchBranch();
    };

    saveCallback = async () => {
        await this.fetchBranch(null);
    };

    copyCallback = (selectedItem) => {
        this.onItemClick(selectedItem);
        this.fetchData();
    };

    deleteCallback = async (parentId) => {
        await this.props.push(instructionsEdit.buildUrl());
        this.selectItem(null);
        await this.fetchRecentPosts();
        await this.fetchBranch(parentId);
    };

    backToListCallback = async () => {
        this.selectItem(null);
        await this.fetchRecentPosts();
        await this.props.push(instructionsEdit.buildUrl());
    };

    onItemClick = (selectedItem, isNew = false) => {
        if (!selectedItem) return;

        let route;
        if (selectedItem) {
            if (isNew) {
                this.setState({ newItem: { parentId: selectedItem.parentId } });
                selectedItem.isArticle
                    ? (route = instructionsEditItem.buildUrl({ id: selectedItem.id }))
                    : (route = instructionsEditCategory.buildUrl({ id: 'new' }));
            } else {
                this.setState({ newItem: null });
                selectedItem?.isArticle
                    ? (route = instructionsEditItem.buildUrl({ id: selectedItem.id }))
                    : (route = instructionsEditCategory.buildUrl({ id: selectedItem.id }));
            }
        }

        if (this.props.pathname === route) {
            this.props.replace(route);
        } else {
            this.props.push(route);
        }
    };

    selectItem = (selectedItem) => {
        this.setParentRolesGroups(this.state.treeData, selectedItem);

        if (!selectedItem) {
            this.setState({
                selectedItem: null,
            });
            return;
        }

        if (selectedItem.id === 'new' || (selectedItem.id === null && !selectedItem.isArticle)) {
            this.setState({
                newItem: { ...selectedItem, parentId: selectedItem.categoryId },
            });
        } else {
            this.setState({
                newItem: null,
                selectedItem: {
                    ...selectedItem,
                    parentId: selectedItem.categoryId || selectedItem.parentId,
                },
            });
        }
    };

    setModal = (
        modalHeader,
        modalCallBack,
        modalOpen = true,
        modalWithoutButtons = false,
        modalBody = '',
    ) => {
        this.setState({
            modalHeader,
            modalCallBack,
            modalOpen,
            modalWithoutButtons,
            modalBody,
        });
    };

    setModalProcessing = (modalProcessing) => this.setState({ modalProcessing });

    onCloseModal = () => {
        this.setState({
            modalHeader: '',
            modalCallBack: () => {},
            modalOpen: false,
        });
    };

    updateTreeNodeStatus = (id, status, treeClone) => {
        for (const node of treeClone) {
            if (node.children) {
                this.updateTreeNodeStatus(id, status, node.children);
            } else {
                if (node.id === id) {
                    node.status = status;
                }
            }
        }

        this.setState({ treeData: treeClone });
    };

    setParentRolesGroups = (treeData, selectedItem) => {
        if (treeData && selectedItem?.parentId) {
            treeData.map((item) => {
                if (item.id === selectedItem.parentId) {
                    this.setState({
                        parentRoleIds: item.roleIds,
                        parentGroupIds: item.groupIds,
                    });
                }
                if (item.children) {
                    return this.setParentRolesGroups(item.children, selectedItem);
                }
                return true;
            });
        } else {
            this.setState({
                parentRoleIds: null,
                parentGroupIds: null,
            });
        }
    };

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        const roles = await getDictionary(ROLES_DICTIONARY_NAME);
        const groups = await getDictionary(GROUPS_DICTIONARY_NAME);
        this.fetchData();
        this.fetchRecentPosts();
        this.setState({ roles: roles.data, groups: groups.data });
    };

    render() {
        const {
            selectedItem,
            loadedItem,
            treeLoading,
            treeData,
            recentPostsLoading,
            recentPosts,
            modalOpen,
            modalHeader,
            modalCallBack,
            groups,
            roles,
            modalProcessing,
            parentGroupIds,
            parentRoleIds,
            newItem,
            modalWithoutButtons,
            modalBody,
            itemFullAccess,
        } = this.state;

        return (
            <>
                <div className="ContentEditorPage">
                    <div className="ContentTree">
                        <div className="ContentTree__Header">Редактор инструкций</div>
                        <Tree
                            loading={treeLoading}
                            data={treeData}
                            editMode
                            onItemClick={this.onItemClick}
                            selectItem={this.selectItem}
                            refreshTree={this.refreshTree}
                            refreshBranchData={this.fetchBranch}
                            setModal={this.setModal}
                            setModalProcessing={this.setModalProcessing}
                            updateCategory={updateCategory}
                            updateContent={updateContent}
                            selectedItem={selectedItem}
                            selectedId={selectedItem?.id}
                            loadedItem={loadedItem}
                            deleteCallback={this.deleteCallback}
                            centerGroupId={CENTRE_GROUP_ID}
                            itemFullAccess={itemFullAccess}
                            refreshRoot={this.refreshRoot}
                            removeItemFromParent={this.removeItemFromParent}
                        />
                    </div>
                    <Switch>
                        <ProtectedRoute
                            exact
                            path={instructionsEdit.url}
                            render={(props) => (
                                <RecentPosts
                                    {...props}
                                    loading={recentPostsLoading}
                                    data={recentPosts}
                                    onItemClick={this.onItemClick}
                                    refreshTree={this.refreshTree}
                                    setModal={this.setModal}
                                    setModalProcessing={this.setModalProcessing}
                                />
                            )}
                            allowedRoles={ALLOWED_EDIT_INSTRUCTION}
                        />
                        <ProtectedRoute
                            path={instructionsEditItem.url}
                            render={() => (
                                <div className="ContentItem">
                                    <ContentEditor
                                        mode="edit"
                                        setLoadedItem={this.setLoadedItem}
                                        parentId={newItem?.parentId || selectedItem?.parentId}
                                        setModal={this.setModal}
                                        setModalProcessing={this.setModalProcessing}
                                        updateTreeNodeStatus={this.updateTreeNodeStatus}
                                        treeData={treeData}
                                        treeLoading={treeLoading}
                                        deleteCallback={this.deleteCallback}
                                        cancelCallback={this.backToListCallback}
                                        saveCallback={this.saveCallback}
                                        copyCallback={this.copyCallback}
                                        setParentRolesGroups={this.setParentRolesGroups}
                                        groups={
                                            parentGroupIds
                                                ? groups.filter((x) =>
                                                      parentGroupIds.includes(x.id),
                                                  )
                                                : groups
                                        }
                                        roles={
                                            parentRoleIds
                                                ? roles.filter((x) => parentRoleIds.includes(x.id))
                                                : roles
                                        }
                                        editorRole={INSTRUCTIONS_EDITOR}
                                        createCallback={this.refreshTreeFromRecentPosts}
                                        onItemClick={this.onItemClick}
                                        centerGroupId={CENTRE_GROUP_ID}
                                        setItemFullAccess={this.setItemFullAccess}
                                        itemFullAccess={itemFullAccess}
                                    />
                                </div>
                            )}
                            allowedRoles={ALLOWED_EDIT_INSTRUCTION}
                        />
                        <ProtectedRoute
                            path={instructionsEditCategory.url}
                            render={() => (
                                <div className="ContentItem">
                                    <CategoryEditor
                                        setLoadedItem={this.setLoadedItem}
                                        refreshData={this.fetchBranch}
                                        groups={
                                            parentGroupIds
                                                ? groups.filter((x) =>
                                                      parentGroupIds.includes(x.id),
                                                  )
                                                : groups
                                        }
                                        roles={
                                            parentRoleIds
                                                ? roles.filter((x) => parentRoleIds.includes(x.id))
                                                : roles
                                        }
                                        onItemClick={this.onItemClick}
                                        editorRole={INSTRUCTIONS_EDITOR}
                                        saveCallback={this.saveCallback}
                                        setModal={this.setModal}
                                        centerGroupId={CENTRE_GROUP_ID}
                                        setItemFullAccess={this.setItemFullAccess}
                                        itemFullAccess={itemFullAccess}
                                        treeData={treeData}
                                        treeLoading={treeLoading}
                                    />
                                </div>
                            )}
                            allowedRoles={ALLOWED_EDIT_INSTRUCTION}
                        />
                    </Switch>
                </div>
                <ModalDialog
                    onCloseModal={this.onCloseModal}
                    modalOpen={modalOpen}
                    modalHeader={modalHeader}
                    onClick={modalCallBack}
                    processing={modalProcessing}
                    withoutButtons={modalWithoutButtons}
                >
                    {modalBody}
                </ModalDialog>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    currentUser: state.auth.user,
    pathname: state.router.location.pathname,
});
const actions = { showErrorAlert, showSuccessAlert, push, replace };

export default connect(mapStateToProps, actions)(InstructionsEdit);
