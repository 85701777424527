import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { showSuccessAlert, showErrorAlert, showWarningAlert } from 'ducks/Alert';
import { serviceResultCode, getError } from 'serviceErrors';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { getPersonGrowthTagById, createPersonGrowthTag, updatePersonGrowthTag } from 'api';

import { personGrowthTagsRoute } from 'routes';

import Button from 'components/uikit/Button';
import Label from 'components/uikit/Label';
import Input from 'components/uikit/Input';
import Field from 'components/uikit/Field';
import Select from 'components/uikit/Select';

import Page from 'components/common/Page';
import Form from 'components/common/Form';

import PersonGrowthTagDeps from './PersonGrowthTagDeps/PersonGrowthTagDeps';
import { tagTypes } from 'constants.js';
import './PersonGrowthTag.scss';

const initialTag = {
    id: null,
    name: '',
    isInternal: null,
    groupId: null,
    isLoaded: false,
};

const PersonGrowthTag = (props) => {
    const {
        showPageLoader,
        hidePageLoader,
        showSuccessAlert,
        showErrorAlert,
        showWarningAlert,
        push,
    } = props;
    const { regime, id } = props.match.params;

    const [tag, setTag] = useState(initialTag);
    const [selectedType, setSelectedType] = useState(null);
    const [saving, setSaveState] = useState(false);

    const isEditMode = regime === 'edit' || regime === 'new';

    useEffect(() => {
        let mount = true;

        if (regime === 'new') {
            setTag((tag) => ({ ...tag, id, isLoaded: true }));
            return () => {
                mount = false;
            };
        } else {
            const fetchData = async () => {
                try {
                    showPageLoader();

                    const tagData = await getPersonGrowthTagById(id);

                    if (mount && tagData && tagData.data) {
                        const tag = tagData.data;
                        setTag({ ...tag, isLoaded: true });
                        setSelectedType(
                            fillSelect([tagTypes.find((x) => x.type === tag.isInternal)]),
                        );
                    }
                } catch (error) {
                    showErrorAlert(error.message);
                } finally {
                    hidePageLoader();
                }
            };
            fetchData();
        }

        return () => {
            mount = false;
        };
    }, [showPageLoader, showErrorAlert, hidePageLoader, regime, id]);

    const fillSelect = (dictionary) => {
        if (dictionary) {
            return dictionary.map((x) => {
                return { value: x.id, label: x.name };
            });
        } else {
            return [];
        }
    };

    const returnToMain = (withoutQueryParams = false) => {
        if (withoutQueryParams === false) {
            push({
                pathname: personGrowthTagsRoute.url,
            });
        } else {
            push({
                pathname: personGrowthTagsRoute.url,
                search: props.location.search,
            });
        }
    };

    const isTagValid = () => {
        if (!tag.name) {
            showWarningAlert('Не указано название тега инструмента развития');
            return;
        }

        if (tag.isInternal === null || tag.isInternal === undefined) {
            showWarningAlert('Не указан тип тега инструмента развития');
            return;
        }

        return true;
    };

    const handleSaveTag = async () => {
        if (!isTagValid()) {
            return;
        }
        setSaveState(true);

        const getTagError = (code, payload) => {
            switch (code) {
                case serviceResultCode.NotFound:
                    return `${payload}`;
                case serviceResultCode.Forbidden:
                    return `Только региональный координатор группы Центр может ${
                        regime === 'new' ? 'создавать новый тег' : 'обновлять теги'
                    }`;
                case serviceResultCode.PersonGrowthTagError:
                    return `${payload}`;
                case serviceResultCode.PersonGrowthTagAttachedToResource:
                    return `${payload}`;
                case serviceResultCode.PersonGrowthTagIdAlreadyExists:
                    return `${payload}`;
                case serviceResultCode.PersonGrowthTagNameRequired:
                    return `Не указано название тега инструмента развития`;
                case serviceResultCode.PersonGrowthTagIsInternalPropRequired:
                    return `Не указано является ли тег инструмента развития внутренним`;
                default:
                    return `Произошла непредвиденная ошибка`;
            }
        };

        try {
            let response = null;
            if (regime === 'edit') {
                response = await updatePersonGrowthTag(tag);
            }

            if (regime === 'new') {
                response = await createPersonGrowthTag(tag);
            }

            if (response && response.status === 200) {
                showSuccessAlert('Тег инструмента развития успешно сохранен');
                returnToMain(regime !== 'new');
            }
        } catch (error) {
            const reqError = getError(error, getTagError, true);
            showErrorAlert(reqError.message);
        } finally {
            setSaveState(false);
            hidePageLoader();
        }
    };

    const handleClearTag = (e) => {
        e.preventDefault();
        setTag(initialTag);
        returnToMain();
    };

    const handleTagTypeChange = (item) => {
        setTag({ ...tag, isInternal: tagTypes.find((x) => x.id === item.value).type });
        setSelectedType(item);
    };

    const getTagTypeFriendlyName = (isInternal) => {
        const tagType = tagTypes.find((x) => x.type === isInternal);
        return !!tagType && tagType.name;
    };

    return (
        <Grid fluid>
            {tag.isLoaded && (
                <>
                    <Page>
                        <Form autocomplete={false} onSubmit={handleSaveTag}>
                            <Col className="PersonGrowthTag">
                                <div className="TagLine__Header">Тег инструмента развития</div>
                                <div className="TagLine__Divider">
                                    <hr></hr>
                                </div>
                                <Row className="TagLine">
                                    <div className="TagLine__Input">
                                        {!isEditMode ? (
                                            <Field>
                                                <Label>Название</Label>
                                                <Input
                                                    value={tag.name}
                                                    name="name"
                                                    maxLength="100"
                                                    disabled={!isEditMode}
                                                />
                                            </Field>
                                        ) : (
                                            <Field filled={tag.name} required>
                                                <Label>Название</Label>
                                                <Input
                                                    value={tag.name}
                                                    maxLength="200"
                                                    onChange={(e) =>
                                                        setTag({ ...tag, name: e.target.value })
                                                    }
                                                />
                                            </Field>
                                        )}
                                    </div>
                                </Row>
                                <Row className="TagLine">
                                    <div className="TagLine__Input">
                                        {!isEditMode ? (
                                            <Field>
                                                <Label>Тип тега</Label>
                                                <Input
                                                    value={getTagTypeFriendlyName(tag.isInternal)}
                                                    maxLength="100"
                                                    disabled={!isEditMode}
                                                />
                                            </Field>
                                        ) : (
                                            <Field filled={tag.isInternal !== null} required>
                                                <Label>Тип тега</Label>
                                                <Select
                                                    value={selectedType}
                                                    inputId="selectedPersonGrowthTags"
                                                    placeholder={<div>Выберите тип</div>}
                                                    options={fillSelect(tagTypes)}
                                                    onChange={(item) => handleTagTypeChange(item)}
                                                />
                                            </Field>
                                        )}
                                    </div>
                                </Row>
                                {isEditMode && (
                                    <Row className="TagButtonsLine">
                                        <div className="TagButtonsLine__Btn">
                                            <Button
                                                loading={saving}
                                                type="button"
                                                onClick={handleSaveTag}
                                            >
                                                Сохранить
                                            </Button>
                                            {regime === 'new' && (
                                                <Button
                                                    type="button"
                                                    onClick={handleClearTag}
                                                    closeLink
                                                >
                                                    Отменить
                                                </Button>
                                            )}
                                        </div>
                                    </Row>
                                )}
                                {!isEditMode && (
                                    <Row className="TagButtonsLine">
                                        <div className="TagButtonsLine__Btn">
                                            <Button type="button" onClick={returnToMain}>
                                                Вернуться
                                            </Button>
                                        </div>
                                    </Row>
                                )}
                            </Col>
                        </Form>
                    </Page>
                    {(regime === 'edit' || regime === 'view') && <PersonGrowthTagDeps tagId={id} />}
                </>
            )}
        </Grid>
    );
};

const actions = {
    showPageLoader,
    hidePageLoader,
    showSuccessAlert,
    showErrorAlert,
    showWarningAlert,
    push,
};

export default connect(null, actions)(PersonGrowthTag);
