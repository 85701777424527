import React, { useState, useEffect, useCallback } from 'react';
import './ContextMenu.scss';
import Icons from 'components/Lk/Uikit/Icons';
import classnames from 'classnames';

const ContextMenu = props => {
    const { isOpen = false, items = [], onClose, onClick, title } = props;

    const [menuItems, setMenu] = useState(items || []);

    const baseClass = 'LKContextMenu';

    useEffect(() => {
        if (items && items.length > 0) {
            setMenu(items);
        }
    }, [items]);

    const onBlankClick = useCallback(
        e => {
            if (e.composedPath().indexOf(document.querySelector(`.${baseClass}`)) === -1) {
                e.stopPropagation();
                onClose();
                window.removeEventListener('click', onBlankClick, true);
            }
        },
        [onClose],
    );

    useEffect(() => {
        if (isOpen) {
            window.addEventListener('click', onBlankClick, true);
        } else {
            window.removeEventListener('click', onBlankClick, true);
        }
    }, [isOpen, onBlankClick]);

    useEffect(
        () => () => {
            window.removeEventListener('click', onBlankClick, true);
        },
        [onBlankClick],
    );

    const handleMenuClick = menuId => e => {
        e.preventDefault();
        const item = menuItems.find(i => i.id === menuId);
        if (item.children) {
            setMenu(item.children);
            return;
        }
        onClose();
        onClick({ ...item });
    };
    //TODO доработать позиционирование.
    return (
        <div className="ContextWrapper">
            {isOpen && (
                <div className={classnames('LKContextMenu', baseClass)}>
                    {title && <div className="LKContextMenu__MenuTitle">{title}</div>}
                    {menuItems.map(x => (
                        <div
                            key={x.id}
                            className={classnames("LKContextMenu__MenuItem", {"LKContextMenu__MenuItem--disabled": x.disabled})}
                            onClick={!x.disabled ? handleMenuClick(x.id) : undefined}
                        >
                            {x.icon && (
                                <div className="LKContextMenu__Icon">
                                    <Icons className name={x.icon} fill={x.disabled ? '#b3b3b3' : '#051945'} />
                                </div>
                            )}
                            <div>{x.name}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ContextMenu;
