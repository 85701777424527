import React from 'react';
import ReactTooltip from 'react-tooltip';

import './ContactIcons.scss';
import { faEnvelope, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ContactIcons = (props) => {
    return (
        <div className="ContactIcons__Container">
            {props.showMail && props.email && <div className="ContactIcons__Item ContactIcons__Item--mail">
                <a href={props.email ? `mailto:${props.email}` : `mailto:`}>
                    <FontAwesomeIcon 
                    size={props.iconSize || "lg"} 
                    color={props.iconColor || "#368ef1"} 
                    icon={faEnvelope}
                    data-tip data-for={`emailIcon_${props.tooltipId}`} />
                    <span className="ContactIcons__Title">
                    <ReactTooltip id={`emailIcon_${props.tooltipId}`} effect={'solid'} border={true} type={'light'}>
                        <p className="tooltip">
                            {props.email}
                        </p>
                    </ReactTooltip>
                    </span>
                </a>
            </div>}
            {props.showPhone && props.number && <div className="ContactIcons__Item ContactIcons__Item--phone">
                <a href={`tel:${props.number}`}>
                <FontAwesomeIcon
                    size={props.iconSize || "lg"}
                    color={props.iconColor || "#368ef1"}
                    icon={faPhoneAlt}
                    data-tip data-for={`phoneIcon_${props.tooltipId}`} />
                <span className="ContactIcons__Title">
                    <ReactTooltip id={`phoneIcon_${props.tooltipId}`} effect={'solid'} border={true} type={'light'}>
                        <p className="tooltip">
                            {props.number}
                        </p>
                    </ReactTooltip>
                </span>
                </a>
            </div>}
        </div>
    )
}

export default ContactIcons