import React, { useState, useEffect } from 'react';
import LazyLoad from 'react-lazy-load';
import classnames from 'classnames';
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { showErrorAlert, showWarningAlert } from 'ducks/Alert';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { getResourceCounter, extendedSearchPersonGrowthResources, makeRequestCreator } from 'api';
import { resourceTypes } from 'constants.js';
import CheckCard from 'components/common/CheckCard';
import Label from 'components/uikit/Label';
import Pagination from "components/common/Pagination";

import './ResourceSelector.scss';

const getPersonGrowthResourcesOnce = makeRequestCreator(extendedSearchPersonGrowthResources);
const getPersonResourceCounterOnce = makeRequestCreator(getResourceCounter);

const ResourceSelector = (props) => {
    const [paging, setPaging] = useState({
        pageNum: 1,
        pageSize: 10,
    });
    const [totalCount, setTotalCount] = useState(0);
    const [pageCount, setPageCount] = useState(1);
    
    const [resources, setResources] = useState([]);
    const [selectedResources, setSelectedResources] = useState((props.resourceIds && [props.resourceIds]) || []);
    const [selectedType, setSelectedType] = useState(null);
    const [resourceCounter, setResourceCounter] = useState({});
    
    useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            try {
                props.showPageLoader();

                const requestCriteria = {
                    filter:
                        {
                            types: selectedType ? [selectedType] : ["DistanceEducation", "FullTimeEducation"],
                            competencyIds: []
                        },
                    paging: paging
                };
                
                const response = await getPersonGrowthResourcesOnce(requestCriteria);
                mounted && setResources([...response.data.payload]);
                mounted && setTotalCount(response.data.meta.foundCount);
                mounted && setPageCount(response.data.meta.pageCount);
            } catch(error) {
                props.showErrorAlert(error.message);
            } finally {
                props.hidePageLoader();
            }
        };
        fetchData();

        return () => { mounted = false };
    }, [paging, selectedType, props]);

    useEffect(() => {
        const loadResourceCounter = async () => {
            const requestCriteria = {
                filter:
                    {
                        competencyIds: []
                    }
            };
            const response = await getPersonResourceCounterOnce(requestCriteria);
            if (response) {
                setResourceCounter({...response.data});
            }
        };

        loadResourceCounter();

    }, []);
    
    const handlePaginate = (pageNum) => {
        setPaging({ ...paging, pageNum });
    };
    
    const filterByType = (e) => {
        const type  = e.currentTarget.dataset.type;
        setSelectedType(type);
        clearSelected();
    };
    
    const clearSelected = () => {
        setSelectedResources([]);
        props.handleResourceChange(null);
    };
    
    const renderTypesSection = () => {
        return (
            <div className="ResourceSelectorTypes">
                {resourceTypes.filter(x => x.type === "DistanceEducation" || x.type === "FullTimeEducation").map((resource) => {
                    return (
                        <div key={resource.type} className={classnames("ResourceSelectorTypes__Item", {"ResourceSelectorTypes__Item--active": selectedType === resource.type})}>
                            <div className="ResourceSelectorType">
                                <input 
                                    className="ResourceSelectorType__Input"
                                    name="type"
                                    type="radio"
                                    id={resource.id}
                                />
                                <label
                                    data-type={resource.type}
                                    htmlFor={resource.id}
                                    className="ResourceSelectorType__Label"
                                    onClick={filterByType}
                                >
                                    {resource.name}<div className="ResourceSelectorType__Badge">{resourceCounter[resource.type]}</div>
                                </label>
                            </div>
                        </div>
                    )
                })}
            </div>
        );
    };
    
    const handleToolSelect = item => selected => {
        if ((selectedResources.length === 0) || (selected === false)) {
            const changedResources = selected
                ? [...selectedResources.map(x => x), item.id]
                : selectedResources.filter(x => x !== item.id);
            setSelectedResources(changedResources);
            props.handleResourceChange(...changedResources);
        }
    };

    const changePageAmount = (pageSize) => {
        setPaging({ ...paging, pageSize });
    };
    
    const renderResultsSection = () => {
        return (
            <div>
                <div className="ResourceSelectorCards">
                    {resources && resources.length &&
                    resources.map((item) => (
                        <div className="ResourceSelectorCards__Item" key={item.id}>
                            <div className="ResourceSelectorCard">
                                <LazyLoad offsetVertical={300}>
                                    <CheckCard
                                        disabled={!selectedResources.some(x => x === item.id) && selectedResources && selectedResources.length === 1}
                                        className="ResourceSelectorCheckCard"
                                        onChange={handleToolSelect(item)}
                                        checked={(selectedResources && selectedResources.find(x => x === item.id)) || false}
                                        imgUrl={`api/personGrowthResource/${item.id}/file/download`}
                                    />
                                </LazyLoad>
                                <div className="ResourceSelectorCheckCard__Title">
                                    <Label className="ResourceSelectorCheckCard__Label">
                                        <a target="_blank" rel="noopener noreferrer" href={item.link}>{item.name}</a>
                                    </Label>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                </div>
                <div className="ResourceSelectorCards__Paging">
                    <Pagination
                        currentPageNum={paging.pageNum}
                        pageCount={pageCount}
                        totalCount={totalCount}
                        onPaginate={handlePaginate}
                        changePageAmount={changePageAmount}
                        pageSize={paging.pageSize}
                    />
                </div>
            </div>
        );
    };
    
    return (
         <div className="ResourceSelector">
             {renderTypesSection()}
             {!!resources.length
                 ? renderResultsSection()
                 : <div className="ResourceSelectorNoData">Нет данных</div>}
          </div>
    );
};

const actions = {showErrorAlert, showPageLoader, hidePageLoader, showWarningAlert, push};
export default connect(null, actions)(ResourceSelector);