import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { selectPageAmountOptions } from 'constants.js';
import './Pagination.scss';

function Pagination(props) {
    const { currentPageNum, pageCount, totalCount, /* shownTotal, */ onPaginate, changePageAmount, pageSize = 10 } = props;
    const [pageAmount, setPageAmount] = useState({ value: pageSize, label: pageSize });

    const customSelectStyles = {
        container: (base) => ({
            ...base,
            width: 100,
        })
    };

    function handlePaginate(pageNum) {
        return (e) => {
            e.preventDefault();
            onPaginate(pageNum);
        };
    }

    function renderEllipsis(key) {
        return (
            <li key={key} className="Pagination__Button_Ellipsis">
                <button disabled>...</button>
            </li>
        );
    }

    function renderPageButton(pageNum) {
        const isActive = pageNum === currentPageNum;
        return (
            <li key={pageNum} className={isActive ? 'Pagination__Button_Active' : ''}>
                <button onClick={handlePaginate(pageNum)}>{pageNum}</button>
            </li>
        );
    }

    function renderItems() {
        // maxPageButtons может быть только нечетным числом >= 7
        const maxPageButtons = 6;

        // 5 = 2 боковые кнопки, 2 "..." кнопки, 1 центральная кнопка
        const midOneSideButtons = (maxPageButtons - 4) / 2;

        const leftDistanceToCenterButton = ((maxPageButtons / 2) >> 0) + 1;
        const rightDistanceToCenterButton = pageCount + 1 - leftDistanceToCenterButton;

        const firstPage = [renderPageButton(1)];
        const lastPage = pageCount > 1 ? [renderPageButton(pageCount)] : [];

        const hasLeftEllipsis =
            pageCount > maxPageButtons && currentPageNum >= leftDistanceToCenterButton;
        const leftEllipsis = hasLeftEllipsis ? [renderEllipsis('l')] : [];

        const hasRightEllipsis =
            pageCount > maxPageButtons && currentPageNum <= rightDistanceToCenterButton;
        const rightEllipsis = hasRightEllipsis ? [renderEllipsis('r')] : [];

        const hasMidSection = pageCount >= 3;
        let midSection = [];
        if (hasMidSection) {
            let rightOverflow = currentPageNum - leftDistanceToCenterButton;
            rightOverflow = rightOverflow < 0 ? -rightOverflow : 0;

            let leftOverflow = rightDistanceToCenterButton - currentPageNum;
            leftOverflow = leftOverflow < 0 ? -leftOverflow : 0;

            const midLeftBoundary = 2;
            const midLeftmostPageNum = Math.max(
                midLeftBoundary,
                -leftOverflow + (currentPageNum - midOneSideButtons),
            );

            const midRightBoundary = pageCount - 1;
            const midRightmostPageNum = Math.min(
                midRightBoundary,
                rightOverflow + (currentPageNum + midOneSideButtons),
            );

            for (let pageNum = midLeftmostPageNum; pageNum <= midRightmostPageNum; ++pageNum) {
                midSection.push(renderPageButton(pageNum));
            }
        }

        return [...firstPage, ...leftEllipsis, ...midSection, ...rightEllipsis, ...lastPage];
    }

    const isFirstPage = currentPageNum === 1;
    const isLastPage = currentPageNum === pageCount;

    const onAmountChange = (item) => {
        setPageAmount(item);
        changePageAmount(item.value);
    };

    return (
        <div className="Pagination Pagination_Right">
            <div className="Pagination__Text Pagination_Left">
                <div className="Pagination__TextBlock">Показывать по </div>
                <div className="Pagination__TextBlock">
                    <Select
                        value={pageAmount}
                        options={selectPageAmountOptions}
                        onChange={onAmountChange}
                        styles={customSelectStyles}
                        menuPlacement="top"
                        isSearchable={false}
                    />
                </div>
                <div className="Pagination__TextBlock">
                    на странице (всего: {totalCount})
                </div>
            </div>
            <ul className="Pagination__Buttons Pagination_Right">
                <li className="Pagination__Button_Arrow Pagination__Button_Prev">
                    <button onClick={handlePaginate(currentPageNum - 1)} disabled={isFirstPage} />
                </li>
                {renderItems(pageCount)}
                <li className="Pagination__Button_Arrow Pagination__Button_Next">
                    <button onClick={handlePaginate(currentPageNum + 1)} disabled={isLastPage} />
                </li>
            </ul>
        </div>
    );
}

Pagination.defaultProps = {
    changePageAmount: () => {},
};

Pagination.propTypes = {
    currentPageNum: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    //shownTotal: PropTypes.number.isRequired,
    onPaginate: PropTypes.func.isRequired,
    changePageAmount: PropTypes.func,
};

export default Pagination;
