import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { ACTIVE_LIST_CARD, EVALUATION_TYPE } from 'components/Lk/Basic/constants';
import { lkDetailsEvaluateTeamResults } from 'routes';
import EvaluationCardItemBlock from './EvaluationCardItemBlock';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';
import EvaluationType from './EvaluationType/EvaluationType';
import { fetchEvaluation, setSelectes } from 'ducks/Evaluation';
import './EvaluateResultSelect.scss';

const EvaluateResultSelect = (props) => {

    const { push, activeCard, setActiveCard } = props;

    const nextCard = (url) => {
        push(url);
    };

    setActiveCard(ACTIVE_LIST_CARD);
    const isMobileActive = activeCard === ACTIVE_LIST_CARD;

    const handleBlockClick = key => id => () => {
        const param = { id, key }
        props.setSelectes(param);
        nextCard(lkDetailsEvaluateTeamResults.url)
    }

    const renderEvaluationType = obg => {
       
        return obg.map((item, key) => (
            <EvaluationCardItemBlock grey key={item.id}>
                <EvaluationType {...item} tooltip
                    onClick={handleBlockClick(key)}
                    icon="evaluateType"
                />
            </EvaluationCardItemBlock>)
        )
    }

    return (
        <div className={classnames('ListCard', { 'ListCard--isMobileActive': isMobileActive })}>
            <MainCardMenu />
            <div className="EvaluateResultSelect">
                <div className="EvaluateResultSelect__Item">
                    <h3 className="LKLayout__Title">Результат по типу оценки</h3>
                    {renderEvaluationType(EVALUATION_TYPE)}
                </div>
            </div>
        </div>
    );
};

EvaluateResultSelect.propTypes = {
    activeCard: PropTypes.string.isRequired,
    setActiveCard: PropTypes.func.isRequired,
};

const mapStateToProps = state => (
    {
        personId: state.auth.user.personId,
        evaluation: state.evaluation.list,
        selectedEvaluation: state.evaluation.selected,
    }
);

const actions = { push, fetchEvaluation, setSelectes };

export default connect(mapStateToProps, actions)(EvaluateResultSelect);