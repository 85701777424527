import React, { useState } from 'react';

const Switcher = props => {
    const { onClick, checked = false, titleBefore = '', titleAfter = '', disabled } = props;
    
    const [isChecked, setIsChecked] = useState(checked);
    const onCheckHandle = () => {
        setIsChecked(!isChecked);
    }

    return (
        <div className='apple-switch-wrapper'>
            <div className="apple-switch-title apple-switch-title--before">{titleBefore}</div>
            <input className="apple-switch" type="checkbox" checked={checked} onChange={onCheckHandle} onClick={onClick} disabled={disabled}/>
            <div className="apple-switch-title apple-switch-title--after">{titleAfter}</div>
        </div>
    );
};

export default Switcher;