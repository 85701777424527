import { appName, USERS_DICTIONARY_NAME, ROLES_DICTIONARY_NAME, GROUPS_DICTIONARY_NAME } from 'constants.js';
import { takeEvery, put, call, select } from 'redux-saga/effects';
import { getDictionary } from 'api';
import RequestError from '../RequestError';
import { showErrorAlert } from './Alert';

const moduleName = 'dictionary';
const FETCH_REQUEST = `${appName}/${moduleName}/FETCH_REQUEST`;
const FETCH_START = `${appName}/${moduleName}/FETCH_START`;
const FETCH_END = `${appName}/${moduleName}/FETCH_END`;
const REFETCH_REQUEST = `${appName}/${moduleName}/REFETCH_REQUEST`;

export function fetchDictionaries(dictionaryNames) {
    return {
        type: FETCH_REQUEST,
        payload: { dictionaryNames },
    };
}

function fetchStart(dictionaryName) {
    return {
        type: FETCH_START,
        payload: { dictionaryName },
    };
}

function fetchSuccess(dictionaryName, dictionary) {
    return {
        type: FETCH_END,
        payload: { dictionaryName, dictionary },
    };
}

function fetchFailed(dictionaryName, error) {
    return {
        type: FETCH_END,
        payload: { dictionaryName, error },
        error: true,
    };
}

export function refetchDictionary(dictionaryName) {
    return {
        type: REFETCH_REQUEST,
        payload: { dictionaryName },
    };
}

const initialDictionaryState = {
    loading: false,
    dictionary: null,
};

const initialState = {
    [USERS_DICTIONARY_NAME]: initialDictionaryState,
    [ROLES_DICTIONARY_NAME]: initialDictionaryState,
    [GROUPS_DICTIONARY_NAME]: initialDictionaryState,
};

export function reducer(state, action) {
    switch (action.type) {
        case FETCH_START: {
            const { dictionaryName } = action.payload;

            return {
                ...state,
                [dictionaryName]: {
                    ...state[dictionaryName],
                    loading: true,
                },
            };
        }

        case FETCH_END:
            if (!action.error) {
                const { dictionaryName, dictionary } = action.payload;

                return {
                    ...state,
                    [dictionaryName]: {
                        loading: false,
                        dictionary,
                    },
                };
            } else {
                const { dictionaryName } = action.payload;

                return {
                    ...state,
                    [dictionaryName]: {
                        loading: false,
                        dictionary: null,
                    },
                };
            }

        default:
            return state || initialState;
    }
}

function* fetchDictionarySaga(dictionaryName, force = false) {
    const state = yield select();
    const dictionaryAlreadyExist =
        !force &&
        (state.dictionary[dictionaryName].loading || state.dictionary[dictionaryName].dictionary);

    if (dictionaryAlreadyExist) {
        return;
    }

    yield put(fetchStart(dictionaryName));

    try {
        const response = yield call(getDictionary, dictionaryName);
        const dictionary = response.data;

        yield put(fetchSuccess(dictionaryName, dictionary));
    } catch (error) {
        const errorMessage = `При загрузке словаря ${dictionaryName} произошла ошибка`;
        const requestError = new RequestError(error, errorMessage);

        yield put(fetchFailed(dictionaryName, requestError));
        yield put(showErrorAlert(requestError.message));
    }
}

function* fetchDictionariesSaga(action) {
    const { dictionaryNames } = action.payload;

    if (Array.isArray(dictionaryNames)) {
        for (const dictionaryName of dictionaryNames) {
            yield call(fetchDictionarySaga, dictionaryName);
        }
    } else {
        yield call(fetchDictionarySaga, dictionaryNames);
    }
}

function* refetchDictionarySaga(action) {
    const { dictionaryName } = action.payload;
    yield call(fetchDictionarySaga, dictionaryName, true);
}

export function* saga() {
    yield takeEvery(FETCH_REQUEST, fetchDictionariesSaga);
    yield takeEvery(REFETCH_REQUEST, refetchDictionarySaga);
}

export function selectDictionaries(state, dictionaryNames) {
    if (Array.isArray(dictionaryNames)) {
        return dictionaryNames.reduce((dicts, dictName) => {
            dicts[dictName] = selectDictionaries(state, dictName);
            return dicts;
        }, {});
    } else {
        const dictState = state[dictionaryNames];
    
        return dictState.dictionary;
    }
}

export function isDictionariesLoaded(state, dictionaryNames) {
    if (Array.isArray(dictionaryNames)) {
        return dictionaryNames.every(dictName => isDictionariesLoaded(state, dictName));
    } else {
        const dictState = state[dictionaryNames];
        
        return !!dictState.dictionary;
    }
}