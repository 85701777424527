import React, { Component } from 'react';
import moment from 'moment';
import Button from 'components/uikit/Button';
import Page from 'components/common/Page';
import StepWizard from 'components/common/StepWizard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { allActions } from 'ducks/PersonGrowthPlan';
import { push, goBack } from 'connected-react-router';
import { personProgress } from 'routes';
import { connect } from 'react-redux';
import {
    getCompetencies,
    createPersonGrowthPlan,
    getPersonGrowthPlanSession,
    setPersonGrowthPlanSession,
    updatePersonGrowthPlan,
    updateCachePersonGrowthPlan,
    getPersonGrowthPlan,
} from 'api';
import { serviceResultCode, getError, getCode } from 'serviceErrors';
import { showErrorAlert, showWarningAlert } from 'ducks/Alert';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import './PersonGrowthPlan.scss';
import * as utils from './utils';
import uuid from 'uuid/v4';

import CurrentCompetencies, {
    CurrentCompetenciesTitle,
} from '../common/PersonGrowthPlan/CurrentCompetencies/CurrentCompetencies';
import TargetCreation, {
    TargetCreationTitle,
} from '../common/PersonGrowthPlan/TargetCreation/TargetCreation';
import {
    ToolTypeSelectorRecommendation,
    ToolTypeSelectorHelpTitle,
} from '../common/PersonGrowthPlan/ToolTypeSelector/ToolTypeSelector';
import CompetencyPersonHelp, {
    CompetencyPersonHelpTitle,
} from '../common/PersonGrowthPlan/CompetencyPersonHelp/CompetencyPersonHelp';
import CompetencyObjectHelp, {
    CompetencyObjectHelpTitle,
} from '../common/PersonGrowthPlan/CompetencyObjectHelp/CompetencyObjectHelp';
import PersonGrowthEndDate, {
    PersonGrowthEndTitle,
} from '../common/PersonGrowthPlan/PersonGrowthEndDate/PersonGrowthEndDate';
import ApproversSelection, {
    ApproversSelectionTitle,
} from '../common/PersonGrowthPlan/ApproversSelection/ApproversSelection';
import { getPersonTeams } from 'api';
import { PERSONGROWTHPLAN_TYPE, PERSONGROWTHPLAN_STATUS } from 'constants.js';
import debounce from 'debounce-promise';

const pages = {
    CURR_COMPETENCIES: 'current',
    TARGET_CREATION: 'target',
    TOOLS_SELECTION: 'tools',
    PERSON_HELP: 'person',
    OBJECT_HELP: 'object',
    END_DATE: 'date',
    APPROVERS: 'approvers',
    RESULT: 'result',
};

const initialPlan = {
    title: '',
    assistanceDetails: '',
    target: '',
    targetType: '',
    personGrowthEvents: [],
    filteredByCategoryResources: [],
    filteredByTypeResources: [],
    resources: [],
    start: new Date(),
    end_year: null,
    end_month: null,
    competencyIds: [],
    result: '',
    personId: null,
    event_info: '',
    approverIds: [],
    approves: [],
    isApproved: '',
    personGrowthAssistants: [],
};

class PersonGrowthPlan extends Component {
    state = {
        loaded: false,
        planGrowthtype: 1,
        isSubmited: false,
        isOpenClearModal: false,
        stepId: null,
        wizard: null,
        plan: { ...initialPlan },
        approverIds: [],
    };

    handleComplete = async () => {
        const endDate = this.buildEndDate();
        if (!endDate) {
            this.props.showWarningAlert('Некорректная дата');
        }

        const isSession = this.getIsSession();

        const plan = this.buildPlan(endDate);
        try {
            this.props.showPageLoader();
            isSession ? await createPersonGrowthPlan({ ...plan, status: PERSONGROWTHPLAN_STATUS.Process }) : await updatePersonGrowthPlan(plan);
            this.props.push(personProgress.buildUrl({ id: this.getPersonId() }));
        } catch (e) {
            if (getCode(e) === serviceResultCode.PersonGrowthPlanApprovedUpdateError) {
                this.props.showErrorAlert('Согласованный план развития не может быть изменен.');
                this.props.push(personProgress.buildUrl({ id: this.getPersonId() }));
            } else {
                const reqError = getError(e, this.getResourceError);
                this.props.showErrorAlert(reqError.message);
            }
        } finally {
            this.props.hidePageLoader();
        }
    };
    onHandleComplete = debounce(this.handleComplete, 750);

    getResourceError = (code, payload) => {
        switch (code) {
            case serviceResultCode.NotFound:
                return `${payload}`;
            case serviceResultCode.PersonGrowthResourceError:
                return `${payload}`;
            case serviceResultCode.PersonGrowthResourceNameRequired:
                return `Не указано название инструмента развития`;
            case serviceResultCode.PersonGrowthResourceTypeRequired:
                return `Не указан тип инструмента развития`;
            case serviceResultCode.PersonGrowthResourceLinkRequired:
                return `Не указана ссылка на инструмент развития`;
            case serviceResultCode.PersonGrowthPlanEndRequired:
                return `Не указана дата завершения ИПР`;
            case serviceResultCode.PersonGrowthPlanEndMoreOrEqualStart:
                return `Дата завершения должна быть больше чем текущая дата`;
            default:
                return `Произошла непредвиденная ошибка`;
        }
    };

    handleSaveAndReturn = async () => {
        try {
            this.props.showPageLoader();
            if (this.state.stepId !== pages.APPROVERS) {
                const result = await this.handleSaveDraft();
                result && this.props.push(personProgress.buildUrl({ id: this.getPersonId() }));
            }
        } finally {
            this.props.hidePageLoader();
        }
    };

    handleSaveDraft = async (manual = true) => {
        const isSession = this.getIsSession();
        try {
            const endDate = this.buildEndDate();
            const plan = this.buildPlan(endDate);
            const session = { plan, stepId: this.state.stepId };
            isSession
                ? await setPersonGrowthPlanSession(session)
                : manual
                ? await updatePersonGrowthPlan(plan)
                : await updateCachePersonGrowthPlan(plan);
            this.state.plan.id === undefined &&
                this.setState({
                    plan: { ...this.state.plan },
                });
            return true;
        } catch (e) {
            if (getCode(e) === serviceResultCode.PersonGrowthPlanApprovedUpdateError) {
                showErrorAlert('Согласованный план развития не может быть изменен.');
                this.props.push(personProgress.buildUrl({ id: this.props.match.params.id }));
            } else {
                isSession
                    ? this.props.showErrorAlert('Во врeмя сохранения сессии произошла ошибка')
                    : this.props.showErrorAlert(
                    'Во врeмя сохранения сессии произошла ошибка. Проверьте корректность заполнения полей',
                    );
            }
            return false;
        }
    };

    componentDidMount() {
        const that = this;

        this.callEffect(async () => {
            const personId = that.getPersonId();
            const planId = that.getPlanId();
            const isSession = that.getIsSession();
            let session;

            if (planId) {
                if (!isSession) {
                    const personGrowthPlan = await getPersonGrowthPlan(planId);
                    if (personGrowthPlan && personGrowthPlan.data && personGrowthPlan.data.isApproved) {
                        this.props.showErrorAlert("Согласованный план развития не может быть изменен.");
                        return this.props.goBack();
                    }
                    session = { plan: personGrowthPlan.data, stepId: 'current' };
                } else {
                    session = (await getPersonGrowthPlanSession(personId, planId)).data;
                }
            }
            const competencyInfo = (await getCompetencies(personId)).data;
            const teams = (await getPersonTeams(personId)).data;
            const plan = session ? session.plan : this.state.plan;

            let leaders =
                teams &&
                teams.following &&
                Array.isArray(teams.following) &&
                teams.following.map((x) => x.leaderPerson);

            that.setState({
                competencyInfo,
                loaded: true,
                plan: {
                    ...plan,
                    ...this.splitEndDate(plan),
                    id: planId ? planId : uuid(),
                    competencyIds: plan.competencies ? plan.competencies.map((c) => c.id) : [],
                    resources: plan.resources ? plan.resources : [],
                },
                stepId: session ? session.stepId : null,
                approvers: leaders,
                planSessionId: plan.id || null,
            });
        });
    }

    saveDraft = debounce(this.handleSaveDraft, 500);
    componentDidUpdate(prevProps, prevState) {
        const { plan, stepId } = this.state;
        if (
            (JSON.stringify(prevState.plan) !== JSON.stringify(plan) ||
                prevState.stepId !== stepId) &&
            prevState.plan.id === plan.id &&
            !(plan.competencyIds.length === 0)
        ) {
            if (this.state.stepId === pages.APPROVERS || this.state.stepId === pages.END_DATE ||
               (prevState.stepId === pages.APPROVERS && this.state.stepId === pages.OBJECT_HELP)) {
                    return;
            }
            this.saveDraft(prevState.stepId !== stepId);
        }
    }

    isNewPlan = () => !this.props.id;

    getPersonId = () => this.props.match.params.id;
    getPlanId = () => this.props.match.params.planId;
    getIsSession = () => this.props.match.params.isSession !== 'false';

    buildEndDate = () => utils.buildEndDate(this.state.plan);

    splitEndDate = (plan) => {
        if (!plan.end) {
            return null;
        }

        const endDate = moment(plan.end);
        return {
            end_year: endDate.year(),
            end_month: endDate.month(),
        };
    };

    buildPlan = (endDate) => {
        const plan = this.state.plan;
        const competencyIds = plan.competencyIds;

        return {
            id: this.state.plan.id,
            personGrowthAssistants: plan.personGrowthAssistants,
            title: plan.title,
            personId: this.getPersonId(),
            expectedResult: plan.expectedResult,
            target: plan.target,
            targetType: plan.targetType,
            resourceIds: plan.resources.map((x) => x.id) || [],
            personGrowthEvents: plan.personGrowthEvents,
            type: PERSONGROWTHPLAN_TYPE.Full,
            competencyIds: competencyIds || [],
            start: plan.start,
            end: endDate,
            approverIds: plan.approverIds,
            status: plan.status,
        };
    };

    handleStateChange = (blockStateKey, value) => {
        this.setState((state) => ({ plan: { ...state.plan, [blockStateKey]: value } }));
    };

    handleCancel = () =>
        this.setState({
            plan: {
                ...initialPlan,
                personId: this.getPersonId(),
            },
        });

    onClearModal = () => {
        this.callEffect(async () => {
            this.props.push(personProgress.buildUrl({ id: this.props.match.params.id }));
        });
    };

    initialize = (wizard) => {
        this.setState({
            wizard: {
                ...wizard,
                initialized: true,
            },
        });
    };

    onCancel = () => {
        this.callEffect(async () => {
            this.props.push(personProgress.buildUrl({ id: this.props.match.params.id }));
        });
    };

    handlePageChange = (stepId) => this.setState({ stepId });

    validateCurrentCompetencies = () => this.state.plan.competencyIds.length !== 0;

    validateTargetCreation = () => !!this.state.plan.title;

    validPersonGrowthEvents = (data) => {
        const isNotValid = data.find((x) => {
            return !x.type || !x.assistanceDetails;
        });
        return isNotValid;
    };

    validPlanDate = () => utils.validPlanDate(this.state.plan);

    validateToolTypeSelectorRecommendation = () => this.state.plan.resources.length !== 0;

    validateCompetencyPersonHelp = () => {
        const { personGrowthAssistants } = this.state.plan;
        if (
            !personGrowthAssistants ||
            (Array.isArray(personGrowthAssistants) && personGrowthAssistants.length === 0) ||
            (personGrowthAssistants &&
                personGrowthAssistants[0] &&
                !personGrowthAssistants[0].fullName &&
                !personGrowthAssistants[0].assistanceDetails &&
                !personGrowthAssistants[0].position) ||
            (personGrowthAssistants &&
                personGrowthAssistants[0] &&
                personGrowthAssistants[0].fullName &&
                personGrowthAssistants[0].assistanceDetails &&
                personGrowthAssistants[0].position)
        ) {
            return true;
        }

        return false;
    };

    validateCompetencyObjectHelp = () => {
        const { personGrowthEvents } = this.state.plan;
        if (
            !personGrowthEvents ||
            personGrowthEvents.length === 0 ||
            !this.validPersonGrowthEvents(personGrowthEvents)
        ) {
            return true;
        }
        return false;
    };

    validatePersonGrowthEndDate = () => {
        return (
            this.state.plan.end_year &&
            (this.state.plan.end_month || this.state.plan.end_month === 0) &&
            this.validPlanDate()
        );
    };

    renderCurrentCompetencies = () => {
        return (
            <CurrentCompetencies
                editMode={false}
                {...this.state}
                competencies={this.state.competencyInfo}
                onChange={this.handleStateChange}
                data={this.state.plan.competencyIds}
            />
        );
    };

    onValid = (exp) => {
        this.setState({ isSubmited: true });

        if (!exp) {
            this.props.showWarningAlert('Не все поля корректно заполнены');
            return exp;
        }

        this.setState({ isSubmited: false });
        return exp;
    };

    renderTargetCreation = () => {
        return (
            <TargetCreation
                editMode={false}
                onChange={this.handleStateChange}
                title={this.state.plan.title}
                expectedResult={this.state.plan.expectedResult}
                isValid={this.validateTargetCreation()}
                isSubmited={this.state.isSubmited}
            />
        );
    };

    renderToolTypeSelectorRecommendation = () => {
        return (
            <ToolTypeSelectorRecommendation
                editMode={false}
                competencyIds={this.state.plan.competencyIds}
                resources={this.state.plan.resources}
                onChange={this.handleStateChange}
                filteredResources={this.state.plan.filteredResources}
                filteredByTypeResources={this.state.plan.filteredByTypeResources}
                filteredByCategoryResources={this.state.plan.filteredByCategoryResources}
            />
        );
    };

    renderCompetencyPersonHelp = () => {
        return (
            <CompetencyPersonHelp
                editMode={false}
                personGrowthAssistants={this.state.plan.personGrowthAssistants}
                onChange={this.handleStateChange}
            />
        );
    };

    renderCompetencyObjectHelp = () => (
        <CompetencyObjectHelp
            editMode={false}
            personGrowthEvents={this.state.plan.personGrowthEvents}
            onChange={this.handleStateChange}
        />
    );

    renderPersonGrowthEndDate = () => {
        return (
            <PersonGrowthEndDate
                editMode={false}
                onChange={this.handleStateChange}
                end_year={this.state.plan.end_year}
                end_month={this.state.plan.end_month}
            />
        );
    };

    renderPersonGrowthPlanApprovers = () => {
        return (
            <ApproversSelection
                editMode={false}
                onChange={this.handleStateChange}
                plan={this.state.plan}
                approvers={this.state.approvers}
            />
        );
    };

    renderLeftMenuPrefix = () => {
        if ([pages.APPROVERS, pages.RESULT].indexOf(this.state.stepId) >= 0) {
            return null;
        }
        return (
            <Button className="EvolutionPlan__SaveAndReturn" onClick={this.handleSaveAndReturn}>
                <FontAwesomeIcon icon="save" />
                Сохранить и вернуться
            </Button>
        );
    };

    render() {
        const steps = [
            {
                id: pages.CURR_COMPETENCIES,
                title: 'Выбор компетенций',
                stepTitle: 'Выберите компетенции для развития',
                description: '',
                titleAddition: <CurrentCompetenciesTitle />,
                content: this.renderCurrentCompetencies,
                isValid: this.validateCurrentCompetencies,
            },
            {
                id: pages.TARGET_CREATION,
                title: 'Создание цели',
                stepTitle: 'Создайте цель',
                description: '',
                titleAddition: <TargetCreationTitle />,
                content: this.renderTargetCreation,
                isValid: this.validateTargetCreation,
            },
            {
                id: pages.TOOLS_SELECTION,
                title: 'Выбор инструментов',
                stepTitle: 'Выберите инструменты для развития',
                description: '',
                titleAddition: <ToolTypeSelectorHelpTitle />,
                content: this.renderToolTypeSelectorRecommendation,
                isValid: this.validateToolTypeSelectorRecommendation,
            },
            {
                id: pages.PERSON_HELP,
                title: 'Кто поможет в развитии компетенции',
                stepTitle: 'Кто поможет в развитии компетенции',
                description:
                    'Шаги 4, 5 и 6 являются не обязательными и заполняются по вашему усмотрению.',
                titleAddition: <CompetencyPersonHelpTitle />,
                content: this.renderCompetencyPersonHelp,
                isValid: this.validateCompetencyPersonHelp,
            },
            {
                id: pages.OBJECT_HELP,
                title: 'Что поможет в развитии компетенции',
                stepTitle: 'Что поможет в развитии компетенции',
                description:
                    'Шаги 4, 5 и 6 являются не обязательными и заполняются по вашему усмотрению.',
                titleAddition: <CompetencyObjectHelpTitle />,
                content: this.renderCompetencyObjectHelp,
                isValid: this.validateCompetencyObjectHelp,
            },
            {
                id: pages.APPROVERS,
                title: 'Согласование плана',
                stepTitle: 'Согласование плана',
                description:
                    'Шаги 4, 5 и 6 являются не обязательными и заполняются по вашему усмотрению.',
                titleAddition: <ApproversSelectionTitle />,
                content: this.renderPersonGrowthPlanApprovers,
            },
            {
                id: pages.END_DATE,
                title: 'Дата завершения',
                stepTitle: 'Выберите дату завершения',
                description: '',
                titleAddition: <PersonGrowthEndTitle />,
                content: this.renderPersonGrowthEndDate,
                isValid: this.validatePersonGrowthEndDate,
                nextText: 'Сохранить план развития',
            },
        ];

        return (
            <div className="EvolutionPlan--wrapper">
                <Page className="EvolutionPlan" w1790>
                    <StepWizard
                        stepId={this.state.stepId}
                        steps={steps}
                        showStepTextInHeader
                        disableTitle="Отмена"
                        disableCancel={false}
                        leftMenuPrefix={this.renderLeftMenuPrefix()}
                        onSave={this.onHandleComplete}
                        onCancel={this.onCancel}
                        onPreviousPage={this.handlePageChange}
                        onNextPage={this.handlePageChange}
                        wizard={this.state.wizard}
                        initialize={this.initialize}
                        onValid={this.onValid}
                    />
                </Page>
            </div>
        );
    }

    callEffect = async (callback) => {
        this.props.showPageLoader();
        try {
            await callback();
        } catch (error) {
            this.props.showErrorAlert(error.message);
        } finally {
            this.props.hidePageLoader();
        }
    };
}

const mapStateToProps = (state) => ({ plan: state.plan });

const actions = {
    ...allActions,
    showErrorAlert,
    showWarningAlert,
    showPageLoader,
    hidePageLoader,
    push,
    goBack,
};

export default connect(mapStateToProps, actions)(PersonGrowthPlan);
