import React from 'react';
import { connect } from 'react-redux';
import ToggleOpen from 'components/hoc/ToggleOpen';
import ValidBlock from 'components/hoc/ValidBlock';
import { showErrorAlert, showWarningAlert } from 'ducks/Alert';
import { requireValidator } from 'libs/validators';
import { push } from "connected-react-router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircle } from "@fortawesome/free-solid-svg-icons";

import { DataCard } from "components/common/DataCard";
import Field from "components/uikit/Field";
import InputTextArea from "components/uikit/InputTextArea";
import Row from "components/uikit/Row";
import { getPersonComment } from 'api.js';
import { default as LocalLoader } from 'components/common/Loader';
import Button from "components/uikit/Button";
import ModalDialog from 'components/common/ModalDialog';
import LoadingUrlBlocker from 'components/Lk/Common/LoadingUrlBlocker';
import UnloadBlocker from 'components/Lk/Common/UnloadBlocker/UnloadBlocker';

import './PersonCommentBlock.scss';

class PersonCommentBlock extends React.Component {
    state = {
        loading: true,
        openConfirmationModal: false,
        isUrlBlocker: false,
        forceClose: false,
        onOkModalAction: null,
        copyComment: { ...this.props.comment }
    };

    mounted = true;
    
    componentDidMount() {
        this.fetchComment()
    }

    componentDidUpdate(prevProps) {
        const { visibleToAll, text, id } = this.props;
        const { copyComment } = this.state;

        if (
            (prevProps.id === id) &&
            (prevProps.visibleToAll !== visibleToAll || prevProps.text !== text)  
        ) {
            const commentChanged = copyComment.text !== text || copyComment.visibleToAll !== visibleToAll;
            this.props.setUnsavedBlock({state: commentChanged, ref: this.props.blockRef});
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchComment = async () => {
        this.setState({loading: true});
        const result = await getPersonComment(this.props.personId);

        if (result.data) {
            this.props.handleStateChange(result.data);
            this.mounted && this.setState({loading: false, copyComment: {...result.data}});
        }
        else {
            this.setState({loading: false});
        }
    };
    
    renderIcon = (condition) => {
        return (
            <FontAwesomeIcon
                icon={condition ? faCheckCircle : faCircle}
                color={condition ? '#4bc161' : '#b9b9b9'} 
                size="lg"
            />);
    };

    onCancel = async () => {
        await this.props.setUnsavedBlock(false);
        this.props.handleClose();
    };
    
    getModalWindow = url => {
        this.setState({
            openConfirmationModal: true,
            isUrlBlocker: true,
            onOkModalAction: () => {
                this.setState({ openConfirmationModal: false, forceClose: true }, () => {
                    this.props.push(url);
                })} });
    };
    
    renderModalHeader = () => {
        return this.state.isUrlBlocker ?
            <div className="PersonCommentBlock__ModalHeader">
                Внесенные изменения будут удалены
            </div>
            : <div className="PersonCommentBlock__ModalHeader">
            Вы&nbsp;выбрали настройку видимости, при которой 
            <br/><b>заметка будет доступна для всех пользователей</b><br/> 
            системы, имеющих доступ к&nbsp;данной анкете резервиста
        </div>
    };
    

    onCloseModal = () => {
        this.setState({ openConfirmationModal: false });
    };

    onOkModal = e => {
        this.state.onOkModalAction && this.state.onOkModalAction(e);
    };

    openModal = () => {
        this.setState({
                openConfirmationModal: true, 
                onOkModalAction: (e) => {   
                    this.saveComment(e);
                    this.onCloseModal();
                }
            });
    };
    
    onSubmitButton = visibleToAll => e => {
        e.persist();
        if (visibleToAll) {
            this.openModal();
            return;
        }
        this.saveComment(e);
    };

    saveComment = async (e) => {
        try {
            await this.props.setUnsavedBlock(false);
            this.props.onSubmit(e);
            
        } catch(e) {
            this.props.showErrorAlert('Ошибка сохранения комментария');
        }
    };
    
    render() {
        const {
            isInputInvalid,
            visibleToAll,
            text
        } = this.props;

        
        const { loading, copyComment, forceClose } = this.state;

        const isChanges = copyComment.text !== text || copyComment.visibleToAll !== visibleToAll;

        return (
            <UnloadBlocker isSaving={isChanges}>
                <DataCard
                    shadow
                    title="Заметки"
                    className="kr_profile-anketa"
                    isOpen={this.props.hasValidationErrors}
                >
                    {loading ? <LocalLoader /> :
                        <div className="EditPerson__Form PersonCommentBlock__Form">
                            <LoadingUrlBlocker
                                isLoading={!forceClose && isChanges}
                                onLoad={this.getModalWindow}
                                message={false}
                            />
                            <Row>
                                <Field size="70">
                                    <div>
                                        <div className='PersonCommentBlock__VisibleHeader'>
                                            Настройка видимости создаваемой заметки
                                        </div>
                                        <div onClick={() => this.props.handleStateChange({ visibleToAll: true })}>
                                            <div className='PersonCommentBlock__VisibleHeader--attribute'>
                                                {this.renderIcon(visibleToAll)}
                                                <div className='PersonCommentBlock__VisibleHeader--text'>
                                                    <div>Общая</div>
                                                    <div className='PersonCommentBlock__VisibleHeader--description'>Доступна всем</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div onClick={() => this.props.handleStateChange({ visibleToAll: false })}>
                                            <div className='PersonCommentBlock__VisibleHeader--attribute'>
                                                {this.renderIcon(!visibleToAll)}
                                                <div className='PersonCommentBlock__VisibleHeader--text'>
                                                    <div>Персональная</div>
                                                    <div className='PersonCommentBlock__VisibleHeader--description'>Доступна только Вам</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Field>
                            </Row>
                            <Row>
                                <Field size="100"
                                       invalid={isInputInvalid('text', [
                                           requireValidator('Введите заметку'),
                                       ])}>
                                    <InputTextArea
                                        id="text"
                                        maxLength={250}
                                        className="PersonCommentBlock__TextBox"
                                        placeholder="Введите текст заметки..."
                                        name="text"
                                        value={text || ''}
                                        clearButton={text?.trim()}
                                        onClearClick={() => this.props.handleStateChange({ text: '' })}
                                        onChange={e =>  this.props.handleStateChange({ text: e.target.value })}
                                    />
                                    <span className="PersonCommentBlock__HelpMessage">
                                Можно ввести не более 250 символов. { text ? 'Вы ввели ' + text.length : ''}
                            </span>
                                </Field>
                            </Row>
                            <Row>
                                <div className="PersonCommentBlock__ButtonContainer">
                                    <Button type="button" onClick={e => this.onCancel(e)} color="default">
                                        Отмена
                                    </Button>
                                    {" "}
                                    <Button
                                        onClick={this.onSubmitButton(visibleToAll)}
                                        loading={this.props.loading}
                                        disabled={!text || !isChanges }
                                    >
                                        Сохранить
                                    </Button>
                                </div>
                            </Row>
                        </div>}
                    <ModalDialog
                        onClick={e => this.onOkModal(e)}
                        onCloseModal={this.onCloseModal}
                        modalOpen={this.state.openConfirmationModal}
                        modalHeader={this.renderModalHeader()}
                        btnOktext={"Подтвердить"}
                        btnCanceltext={this.state.isUrlBlocker ? "Отменить" : "Вернуться"}
                        sideButtons
                    />
                </DataCard>
            </UnloadBlocker>
        );
    }
}

const actions = { showWarningAlert, showErrorAlert, push };

export default connect(null, actions)(ToggleOpen(ValidBlock(PersonCommentBlock)));
