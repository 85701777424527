import React, { useState, useEffect } from 'react';
import { push } from 'connected-react-router';
import { lkNewIssue } from 'routes';
import { connect } from 'react-redux';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import { sendPersons } from 'api';
import Modal from 'react-responsive-modal';
import AcceptPage from '../AcceptPage';
import { serviceResultCode, getError } from 'serviceErrors';
import EvaluatePersonWizard from './EvaluatePersonWizard';
import ModalSummaryCard from './ModalSummaryCard';
import { changeBasket, basketActive } from 'ducks/Baskets';

const ModalPersonSummaryCard = props => {
    const {
        isOpen,
        onClose,
        person,
        showErrorAlert,
        showSuccessAlert,
        profileRoute,
        profileUrl,
        linkParams,
        buttons,
        isUser,
        push,
        changeBasket,
        basketActive,
        storeBasket,
    } = props;

    const [pageType, setPageType] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const defaultAcceptPageParams = {
        title: '',
        acceptAction: () => {},
    };

    const [acceptPage, setAcceptPage] = useState(defaultAcceptPageParams);
    const [isAcceptPage, setIsAcceptPage] = useState(false);

    const onEvaluatePerson = () => {
        setPageType('evaluate');
    };

    useEffect(() => {
        setIsModalOpen(isOpen);
    }, [isOpen]);

    const onCloseModal = () => {
        onClose();
        setPageType('');
    };

    let modal = { borderRadius: '12px', padding: '24px', margin: '30px auto' };
    const overlay = { height: '100%' };
    let style = { modal: modal, overlay: overlay };

    const onAcceptSend = () => {
        setPageType('accept');
        setIsAcceptPage(true);
        setAcceptPage({
            title: `Направить задачу по созданию ИПР?`,
            info:
                'Задача о создании индивидуального плана развития будет направлена резервисту и эксперту по управлению талантами, который закреплён за Вами и вашими командами',
            acceptAction: onPersonSend('PersonGrowth'),
        });
    };

    const onPersonSend = (type, id) => async () => {
        try {
            await sendPersons(type, id, [person.id]);
            showSuccessAlert('Запрос успешно отправлен');
            onCloseModal();
        } catch (error) {
            const reqError = getError(error, getSendError());
            showErrorAlert(reqError.message);
        }
    };

    const getSendError = () => (code, payload) => {
        switch (code) {
            case serviceResultCode.PersonAlreadyHavePersonGrowthPlan:
                return `У выбранного резервиста уже есть ИПР`;
            case serviceResultCode.CuratorNotFound:
                return payload ? payload : "Специалист по развитию не найден";
            default:
                return 'Произошла непредвиденная ошибка';
        }
    };

    const onSetIssue = () => {
        const result = {
            ...storeBasket,
            persons: [
                ...storeBasket.persons,
                {
                    person: person,
                    type: 'Team',
                },
            ],
            [person.id]: person.userId,
            userId: [...storeBasket.userId, person.userId],
        };
        push(lkNewIssue.url);
        changeBasket('newIssue', result);
        basketActive('newIssue', true);
    };

    const renderAcceptPage = () => {
        return (
            <AcceptPage
                portal
                show={isAcceptPage}
                title={acceptPage.title}
                info={acceptPage.info}
                acceptAction={acceptPage.acceptAction}
                setIsAcceptPage={setIsAcceptPage}
                setPageType={setPageType}
            />
        );
    };

    const renderSummaryPage = type => {
        const body = document.querySelector('#root');
        return (
            person && (
                <Modal open={isModalOpen} onClose={onCloseModal} center styles={style} container={body}>
                    {type ? (
                        <EvaluatePersonWizard
                            person={person}
                            setPageType={setPageType}
                            setIsAcceptPage={setIsAcceptPage}
                            setAcceptPage={setAcceptPage}
                            onPersonSend={onPersonSend}
                        />
                    ) : (
                        <ModalSummaryCard
                            person={person}
                            buttons={buttons}
                            profileRoute={profileRoute}
                            profileUrl={profileUrl}
                            onSetIssue={onSetIssue}
                            linkParams={linkParams}
                            onAcceptSend={onAcceptSend}
                            onEvaluatePerson={onEvaluatePerson}
                            onClose={onClose}
                            isUser={isUser}
                        />
                    )}
                </Modal>
            )
        );
    };

    const renderPage = pageType => {
        switch (pageType) {
            case 'accept':
                return renderAcceptPage();
            case 'evaluate':
                return renderSummaryPage('evaluate');
            default:
                return renderSummaryPage();
        }
    };

    return <>{renderPage(pageType)}</>;
};

const actions = { showErrorAlert, showSuccessAlert, push, changeBasket, basketActive };

const mapStateToProps = store => ({
    storeBasket: store.baskets.newIssue.basket,
});


export default connect(
    mapStateToProps,
    actions,
)(ModalPersonSummaryCard);
