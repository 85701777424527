import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function Toggle(props) {
    const { options, value, className, compare, onChange, isDisabled = false } = props;

    const finalClass = classnames('kr_like-checkbox-items', isDisabled ? 'muted' : '', className);

    return (
        <div className={finalClass}>
            {options.map((item, index, arr) => {
                let className = null;
                if (index === 0) {
                    className = 'kr_left-border';
                } else if (index === arr.length - 1) {
                    className = 'kr_right-border';
                }

                return (
                    <React.Fragment key={item.htmlId}>
                        <input
                            type="radio"
                            id={item.htmlId}
                            checked={compare(value, item.value)}
                            onChange={() => onChange(item.value, item)}
                            disabled={isDisabled}
                        />
                        <label htmlFor={item.htmlId} className={className}>
                            {item.title}
                        </label>
                    </React.Fragment>
                );
            })}
        </div>
    );
}

Toggle.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.any,
    className: PropTypes.string,
    compare: PropTypes.func,
    onChange: PropTypes.func.isRequired,
};

Toggle.defaultProps = {
    compare: (actualValue, optionValue) => actualValue === optionValue,
};

export default Toggle;
