import React from 'react';
import './InstructionView.scss';
import Instruction from './Instruction';
import CategoryList from './CategoryList';
import {
    getContentById,
    getContentCategories,
    getTreeByCategory,
    searchTopContentTitles,
    searchContent,
    getContentByIdFromEditor,
} from 'api';
import IconButton from 'components/Lk/Uikit/Buttons/IconButton';
import classnames from 'classnames';
import { push } from 'connected-react-router';
import { showErrorAlert } from 'ducks/Alert';
import { connect } from 'react-redux';
import { instructionsView as instructionViewRoute } from 'routes';
import InstructionList from '../InstructionList';
import SearchRequestInput from 'components/Lk/Common/SearchRequestInput';
import EmailBlockTemplate from 'components/Lk/Common/EmailBlock';
import queryString from 'query-string';
import { instructionsView } from 'routes';
import NoContentSearchResults from './NoContentSearchResults';
import { isDevice, wordForm } from 'utils';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { helpEmail } from 'constants.js';
import InstructionNotFound from './InstructionNotFound';

const EmailBlock = () => {
    return (
        <EmailBlockTemplate
            preText="Если у Вас остались вопросы, напишите на"
            email={helpEmail}
        />
    );
};

class InstructionView extends React.Component {
    state = {
        data: null,
        categories: [],
        isLoadingContent: false,
        isLoadingList: false,
        activeMenu: false,
        categoryId: null,
        localCategories: [],
        localCategoryTitle: null,
        selectedId: null,
        searchTerm: '',
        searchCriteria: { paging: { pageNum: 1, pageSize: 1000 } },
        searchQuery: '',
        isSearching: false,
        instructionMenu: null,
    };

    instructionRef = React.createRef();

    changeSearchTerm = (value) => {
        this.setState({ searchTerm: value });
    };

    loadInstruction = async () => {
        try {
            this.props.showPageLoader();
            const { isEditor } = this.props;
            const loadApi = isEditor ? getContentByIdFromEditor : getContentById;
            const result = await loadApi(this.props.match?.params?.id, true);
            this.setState({
                data: result?.data || null,
                localCategories: [],
                instructionMenu: result?.data?.headersMenu || null,
            });
            return result;
        } catch (e) {
            return null;
        } finally {
            this.props.hidePageLoader();
        }
    };

    loadCategories = async (id, setLoader = false) => {
        const loadingType = id ? 'isLoadingContent' : 'isLoadingList';
        setLoader && this.setState({ [loadingType]: true });
        const result = await this.callEffect(() => getContentCategories(id));
        setLoader && this.setState({ [loadingType]: false });
        return result;
    };

    callEffect = async (callback) => {
        try {
            this.props.showPageLoader();
            return await callback();
        } catch (e) {
            this.props.showErrorAlert('Произошла ошибка');
        } finally {
            this.props.hidePageLoader();
        }
    };

    onShowMenu = () => {
        this.setState(({ activeMenu }) => ({ activeMenu: !activeMenu }));
    };

    onTreeClick = async (item) => {
        if (item.isArticle) {
            this.props.push(instructionViewRoute.buildUrl({ id: item.id }));
        } else {
            this.props.push({
                pathname: instructionViewRoute.buildUrl({ id: null }),
                search: `?section=${item.id}`,
            });
            const result = await this.loadCategories(item.id);

            this.setState({
                localCategories: result.data,
                data: null,
                localCategoryTitle: item.title,
                searchQuery: '',
                searchTerm: '',
                selectedId: item.id,
            });
        }
        this.setState({ activeMenu: false });
    };

    setCategories = (categories, category) => {
        const expandCategory = (item) => {
            return {
                ...item,
                expanded: !!item.children && item.children.length > 0,
                children: item.children ? item.children.map(expandCategory) : [],
            };
        };

        const [cat] = category;

        this.setState({
            categories:
                !!categories && categories.length > 0
                    ? categories.map((x) =>
                          x.id === cat?.id
                              ? expandCategory(cat)
                              : { ...x, expanded: false, children: [] },
                      )
                    : [],
        });
    };

    submitSearchContent = async (_, item) => {
        const searchQuery = item?.title || this.state?.searchTerm || '';
        if (!searchQuery) {
            return;
        }
        this.setState({ searchQuery });
        this.searchPush(searchQuery);
    };

    onSearchKeyPress = async (e) => {
        if (e.key === 'Enter') {
            if (this.state.searchTerm) {
                this.setState({ searchQuery: this.state.searchTerm });
                this.searchPush(this.state.searchTerm);
            }
        }
    };

    searchPush = (searchQuery) => {
        const urlParams = {
            pathname: instructionsView.buildUrl({ id: null }),
            search: queryString.stringify({ q: searchQuery }),
        };
        this.props.push(urlParams);
    };

    componentDidMount = async () => {
        try {
            this.setState({ isLoadingList: true });

            const searchParams = queryString.parse(this.props.location.search);
            const { data: categories } = await this.loadCategories(null, false);
            if (this.props.match?.params?.id) {
                const res = await this.loadInstruction();
                if (!res) {
                    return this.setState({ categories });
                }
                const result = await this.callEffect(() => getTreeByCategory(res.data.categoryId));
                result?.data && this.setCategories(categories, result.data);
                this.setState({ selectedId: this.props.match?.params?.id });
            } else if (searchParams?.section || searchParams?.q) {
                const result = searchParams?.section
                    ? await this.loadCategories(searchParams?.section, false)
                    : [];
                this.setState({
                    localCategories: result?.data || [],
                    categories,
                    searchQuery: searchParams?.q || '',
                    selectedId: searchParams?.section,
                    localCategoryTitle: categories.find((x) => x.id === searchParams?.section)
                        ?.title,
                });
            } else {
                this.setState({ categories });
            }

            const searchQuery = searchParams.q || '';
            if (searchQuery) {
                this.setState({ searchTerm: searchQuery, searchQuery });
            }
        } finally {
            this.setState({ isLoadingList: false });
        }
    };

    updateStateFromUrl = () => {
        const searchQuery = queryString.parse(this.props.location.search)?.q || '';
        const selectedId = queryString.parse(this.props.location.search)?.section || '';
        this.setState({
            data: null,
            activeMenu: false,
            localCategoryTitle: this.state.localCategoryTitle,
            searchQuery,
            selectedId,
        });
    };

    componentDidUpdate = async (prevProps, prevState) => {
        const { searchQuery, searchCriteria, selectedId, categories } = this.state;
        const { location } = this.props;
        const instructionId = this.props.match?.params?.id;

        if (prevProps.location.pathname !== location.pathname && instructionId) {
            this.setState({
                searchTerm: '',
                activeMenu: false,
                searchQuery: '',
                selectedId: instructionId,
            });

            const res = await this.loadInstruction();
            this.instructionRef.current.scrollTo(0, 0);
            const prevId = prevProps.match?.params?.id;
            if (!prevId && !categories.find((x) => x.id === res.data.categoryId)?.expanded) {
                const result = await this.callEffect(() => getTreeByCategory(res.data.categoryId));
                result?.data && this.setCategories(categories, result.data);
            }
        }

        if (prevProps.location.search !== this.props.location.search) {
            const searchParams = queryString.parse(this.props.location.search);
            if (searchParams?.q) {
                const result = searchParams?.section
                    ? await this.loadCategories(searchParams?.section, false)
                    : [];
                this.setState({
                    localCategories: result?.data || [],
                    categories,
                    searchQuery: searchParams?.q || '',
                    selectedId: searchParams?.section,
                    localCategoryTitle: categories.find((x) => x.id === searchParams?.section)
                        ?.title,
                });
            }
        }

        if (
            prevState.searchQuery !== searchQuery ||
            (prevState.selectedId !== selectedId && !instructionId)
        ) {
            if (prevState.searchQuery !== searchQuery && searchQuery) {
                this.setState({ searchTerm: searchQuery, isSearching: true });
                if (searchQuery) {
                    const result = await this.callEffect(() =>
                        searchContent({ searchTerm: searchQuery, ...searchCriteria }),
                    );
                    result?.data?.payload &&
                        this.setState({
                            localCategories: result.data.payload.map((x) => ({
                                ...x,
                                isArticle: true,
                            })),
                        });
                }
                this.setState({ isSearching: false, searchTerm: '' });
            }

            const section = queryString.parse(this.props.location.search)?.section || '';
            if (
                prevState.selectedId !== selectedId &&
                selectedId !== section &&
                selectedId &&
                !instructionId
            ) {
                const result = await this.loadCategories(selectedId, false);
                this.setState({
                    localCategories: result?.data || [],
                    categories,
                    localCategoryTitle: categories.find((x) => x.id === selectedId)?.title,
                });
            }
        }

        if (prevProps.location.search !== this.props.location.search && !instructionId) {
            this.updateStateFromUrl();
        }
    };

    render() {
        const {
            isLoadingList,
            isLoadingContent,
            localCategories,
            data,
            localCategoryTitle,
            categories,
            activeMenu,
            selectedId,
            searchTerm,
            searchQuery,
            isSearching,
            instructionMenu,
        } = this.state;

        const instructionId = this.props.match?.params?.id;

        return (
            <div
                style={{
                    display: 'flex',
                    flex: '1 1 100%',
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    bottom: '0',
                }}
            >
                <div className="InstructionView">
                    <div className="InstructionView__ShowMenu">
                        {isDevice.SM() && (
                            <div className="InstructionView__Search">
                                <SearchRequestInput
                                    className="LKSearchCardInput__Search LKSearchCardInput__Search--no-preIcon  LKSearchCardInput__Search--no-borders"
                                    value={searchTerm}
                                    onChange={(e) => this.changeSearchTerm(e.currentTarget.value)}
                                    onKeyPress={this.onSearchKeyPress}
                                    onSelect={this.submitSearchContent}
                                    postIcon="search"
                                    postIconClick={this.submitSearchContent}
                                    postIconColor="#051945"
                                    searchCallback={searchTopContentTitles}
                                    placeholder="Напишите вопрос или проблему"
                                    withPayload={false}
                                />
                            </div>
                        )}
                        <IconButton
                            type={activeMenu ? 'iconClose' : 'burger'}
                            onClick={this.onShowMenu}
                        />
                    </div>
                    <div className="InstructionView__Wrapper">
                        <div className="InstructionViewListWrapper">
                            <div
                                className={classnames('InstructionView__List', {
                                    'InstructionView__List--active': activeMenu,
                                })}
                            >
                                <CategoryList
                                    treeData={categories.filter((x) => x.hasChildren)}
                                    onTreeClick={this.onTreeClick}
                                    selectedId={selectedId}
                                    loading={isLoadingList}
                                />
                            </div>
                        </div>

                        <div className="InstructionView__Content" ref={this.instructionRef}>
                            {window.innerWidth > 576 && (
                                <div className="InstructionView__Search">
                                    <SearchRequestInput
                                        className="LKSearchCardInput__Search LKSearchCardInput__Search--no-preIcon LKSearchCardInput__Search--post-icon"
                                        value={searchTerm}
                                        onChange={(e) =>
                                            this.changeSearchTerm(e.currentTarget.value)
                                        }
                                        onKeyPress={this.onSearchKeyPress}
                                        onSelect={this.submitSearchContent}
                                        postIcon="search"
                                        postIconClick={this.submitSearchContent}
                                        postIconColor="#051945"
                                        searchCallback={searchTopContentTitles}
                                        placeholder="Напишите вопрос или проблему"
                                        withPayload={false}
                                    />
                                </div>
                            )}
                            {data ? (
                                <div className="InstructionView__Instruction">
                                    <Instruction item={data} instructionMenu={instructionMenu} />
                                    {isDevice.SM() && (
                                        <div className="InstructionView__Email">
                                            <EmailBlock />
                                        </div>
                                    )}
                                </div>
                            ) : localCategories?.length !== 0 ? (
                                <>
                                    <InstructionList
                                        blockStyle
                                        items={localCategories}
                                        title={
                                            searchQuery ? (
                                                <div className="InstructionView__SearchHeader">
                                                    По&nbsp;запросу &laquo;{searchQuery}&raquo;
                                                    найдено&nbsp;&mdash; {localCategories?.length}
                                                    &nbsp;
                                                    {wordForm(localCategories?.length, [
                                                        ' совпадение',
                                                        ' совпадения',
                                                        ' совпадений ',
                                                    ])}
                                                </div>
                                            ) : (
                                                localCategoryTitle
                                            )
                                        }
                                    />
                                </>
                            ) : (
                                !isLoadingContent &&
                                !isLoadingList &&
                                !isSearching &&
                                instructionId && <InstructionNotFound />
                            )}

                            {!isLoadingContent && !isLoadingList && !isSearching && (
                                <>
                                    {localCategories.length === 0 &&
                                        data == null &&
                                        searchQuery && (
                                            <NoContentSearchResults searchQuery={searchQuery} />
                                        )}
                                </>
                            )}
                        </div>
                    </div>
                    {!isDevice.SM() && (
                        <div className="InstructionView__EmailWrapper">
                            <div className="InstructionView__Email">
                                <EmailBlock />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const actions = {
    push,
    showErrorAlert,
    showPageLoader,
    hidePageLoader,
};

export default connect(null, actions)(InstructionView);
