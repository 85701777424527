import React from 'react';
import classnames from 'classnames';
import './Tracking.scss';
import { ACTIVE_LIST_CARD } from 'components/Lk/Basic/constants';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';
import EvaluationCardItemBlock from '../Evaluate/EvaluationCardItemBlock';
import { IssueTrackItem } from '../Issues/IssueItem';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Loader from 'components/common/Loader';
import InfiniteScroll from 'react-infinite-scroller';
import { setUpdateTracking, getTracking, getTrackingMore } from 'ducks/Issues';
import useMountedEffect from 'components/Lk/Hooks/useMountedEffect';

const IssueList = ({issues}) => {
    return issues.map(item => (
        <EvaluationCardItemBlock grey key={item.id}>
            <IssueTrackItem {...item} dataTest={`IssueItem ${item.id}`} />
        </EvaluationCardItemBlock>
    ));
}

const Tracking = props => {
    const { activeCard, setActiveCard, hasMore, update, criteria, loading, issues } = props;

    const isMobileActive = activeCard === ACTIVE_LIST_CARD;
    setActiveCard(ACTIVE_LIST_CARD);

    const handleLoadMore = () => {
        if (hasMore && !loading) {
            props.getTrackingMore({ ...criteria, pageNum: criteria.pageNum + 1 });
        }
    };

    useMountedEffect(() => {
        props.getTracking({ pageSize: 10, pageNum: 1 });

        if (update) {
            props.setUpdateTracking(false);
        }
    }, [update]);

    return (
        <div
            className={classnames('ListCard ', {
                'ListCard--isMobileActive': isMobileActive,
            })}
        >
            <MainCardMenu />
            <div className="AdviceTags">
                <h3 className="LKLayout__Title">Контроль исполнения:</h3>
                {!loading && issues.length === 0 ? (
                    <div className="NotResult">Назначенных задач нет</div>
                ) : (
                    <InfiniteScroll
                        pageStart={1}
                        loadMore={handleLoadMore}
                        hasMore={hasMore}
                        initialLoad={false}
                        useWindow={false}
                    >
                        <IssueList issues={issues} />
                        <Loader show={loading} />
                    </InfiniteScroll>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = ({ issues }) => ({
    update: issues.updateTracking,
    hasMore: issues.tracking.hasMore,
    criteria: issues.tracking.criteria,
    loading: issues.tracking.loading,
    issues: issues.tracking.issues,
});

export default connect(
    mapStateToProps,
    { push, setUpdateTracking, getTracking, getTrackingMore },
)(Tracking);
