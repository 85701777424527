import React, {useState, useEffect} from 'react';
import {push} from "connected-react-router";
import {connect} from "react-redux";
import {Col, Grid, Row} from "react-flexbox-grid";

import {getResourceProviderById, createResourceProvider, updateResourceProvider } from 'api';
import {resourceProvidersRoute} from 'routes';
import { phoneMask } from 'constants.js';

import { showErrorAlert, showSuccessAlert, showWarningAlert } from "ducks/Alert";
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';

import Page from "components/common/Page";
import Button from 'components/uikit/Button';
import Label from "components/uikit/Label";
import Input from "components/uikit/Input";
import Field from "components/uikit/Field";
import InputMask from "components/uikit/InputMask";

import './ResourceProvider.scss';


const blankProvider = {
    id: null,
    title: "",
    agentName: "",
    phone: "",
    email: "",
    loaded: false,
};

const ResourceProvider = (props) => {

    const [provider, setProvider] = useState(blankProvider);
    const [saving, setSavingState] = useState(false)

    const { id, regime } = props.match.params;
    const isEditMode = regime.includes('new') || regime.includes('edit');
    
    useEffect(() => {

        if (regime === "new") {
            setProvider({...blankProvider, loaded: true, id});
        } else {
            const fetchData = async () => {
                try {
                    showPageLoader();

                    const providerData = await getResourceProviderById(id);

                    if (providerData && providerData.status === 200 && providerData.data) {
                        setProvider({...providerData.data, loaded: true});
                    }

                } catch (error) {
                    showErrorAlert(error.message);
                } finally {
                    hidePageLoader();
                }
            };
            fetchData();
        }
        
    }, [id, regime]);

    const renderTitleInput = () => {
        return (
            <Field
                filled={provider.title}
                required={isEditMode}
                invalid={provider.title.length > 64}
            >
                <Label>Название</Label>
                <Input
                    value={provider.title || ""}
                    maxLength="64"
                    onChange={e => setProvider({...provider, title: e.target.value})}
                    disabled={!isEditMode}
                />
            </Field>
        );
    };

    const renderAgentNameInput = () => {
        return (
            <Field
                filled={provider.agentName}
            >
                <Label>Контактное лицо</Label>
                <Input
                    value={provider.agentName || ""}
                    maxLength="100"
                    onChange={e => setProvider({...provider, agentName: e.target.value})}
                    disabled={!isEditMode}
                />
            </Field>
        );
    };

    const renderPhoneInput = () => {
        return (
            <Field
                filled={provider.phone}
            >
                <Label>Телефон</Label>
                <InputMask
                    mask={phoneMask} maskChar="_"
                    value={provider.phone || ""}
                    onChange={e => setProvider({...provider, phone: e.target.value})}
                    placeholder=""
                    disabled={!isEditMode}
                />
            </Field>
        );
    };

    const renderEmailInput = () => {
        return (
            <Field
                filled={provider.email}
            >
                <Label>Email</Label>
                <Input
                    value={provider.email || ""}
                    maxLength="100"
                    onChange={e => setProvider({...provider, email: e.target.value})}
                    disabled={!isEditMode}
                />
            </Field>
        );
    };

    const isEmailInValid = (value) => {
        const regEx = new RegExp(/([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]+/);
        if (value.match(regEx)) {
            return false
        }

        return true;
    }
    
    const renderButtons = () => {
        
        const returnToMain = () => {
            props.push(resourceProvidersRoute.buildUrl({}, props.location.search));
        };

        const callEffect = async callback => {
            try {
                showPageLoader();
                await callback();
            } catch (error) {
                props.showErrorAlert(error.message);
            } finally {
                hidePageLoader();
            }
        };
        
        const onSaveData = async () => {

            if (!provider.title) {
                props.showWarningAlert('Не указано наименование провайдера');
                return;
            }

            if (provider.phone && provider.phone.split('_').join('').length < 18) {
                props.showWarningAlert('Указан некорректный номер телефона');
                return;
            }

            if (provider.email && isEmailInValid(provider.email)) {
                props.showWarningAlert("Указан неверный формат Email");
                return;
            }
            setSavingState(true)
            await callEffect(async () => {
                let response;
                
                if (regime === "new") {
                    response = await createResourceProvider(provider);
                } else if (regime === "edit")
                    response = await updateResourceProvider(provider);

                if (response && response.status === 200) {
                    props.showSuccessAlert('Данные успешно сохранены');
                    returnToMain();
                }
            });
            setSavingState(false)
        };

        if (isEditMode) {
            return (
                <Row end="xs" className="ProviderButtonsLine">
                    <div className="ProviderButtonsLine__Btn">
                        <Button loading={saving}  type="button" onClick={onSaveData}>
                            Сохранить
                        </Button>
                    </div>
                </Row>
            )
        } else {
            return (
                <Row end="xs" className="ProviderButtonsLine">
                    <div className="ProviderButtonsLine__Btn">
                        <Button type="button" onClick={returnToMain}>
                            Вернуться
                        </Button>
                    </div>
                </Row>
            );
        }
    };

    return (
        <Grid fluid>
            { (provider.loaded) &&
            <Page>
                <Col xs={12}>
                    <div className="Resource">
                        <div className="ResourceLine__Header">Провайдер обучения</div>
                        <div className="ResourceLine__Divider">
                            <hr />
                        </div>
                            <Row>
                                <Col xs={12}>
                                    {renderTitleInput()}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    {renderAgentNameInput()}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    {renderPhoneInput()}
                                </Col>
                                <Col xs={6}>
                                    {renderEmailInput()}
                                </Col>
                            </Row>
                        {renderButtons()}
                    </div>
                </Col>
            </Page>}
        </Grid>
    );
};

const actions = { showPageLoader, hidePageLoader, showErrorAlert, showWarningAlert, showSuccessAlert, push };
export default connect(null, actions)(ResourceProvider);