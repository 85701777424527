import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hidePageLoader, showPageLoader } from 'ducks/PageLoader';
import { fetchDictionaries } from 'ducks/Dictionary';
import { showErrorAlert, showSuccessAlert, showWarningAlert } from 'ducks/Alert';
import { getError, serviceResultCode, getValidationErrors } from 'serviceErrors';
import { Col, Row } from 'react-flexbox-grid';

import { getUserShortName, formatDate, deepClone } from 'utils.js';
import { isNullOrWhitespace } from 'utils';

import { DataCard } from 'components/common/DataCard';
import TextEditor from 'components/common/TextEditor';

import Input from 'components/uikit/Input';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import { DictionarySelect } from 'components/uikit/Select';

import { push as pushLocation } from 'connected-react-router';
import { withRouter } from 'react-router';
import { InstructionsViewFromEdit } from 'routes';
import {
    uploadContentFiles,
    updateContent,
    saveContent,
    getContentByIdFromEditor,
    deleteContent,
    changeStatus,
    copyContent,
} from 'api';

import { CONTENT_STATUS, CONTENT_STATUS_ENUM } from 'constants.js';

import './ContentEditor.scss';
import ContentEditorToolbar from './components/ContentEditorToolbar';

class ContentEditor extends Component {
    state = {
        form: {
            id: null,
            status: CONTENT_STATUS_ENUM.Draft,
            title: '',
            contentFiles: [],
            summary: '',
            sortOrder: 0,
            author: {},
            categoryId: null,
            roles: [],
            groups: [],
        },
        prevForm: { title: '', summary: '' },
        categories: [],
        mode: 'edit',
        isBusy: false,
    };

    async componentDidMount() {
        const {
            parentId,
            mode = 'edit',
            centerGroupId,
            currentUser: { groupId },
            treeData,
        } = this.props;

        const { id } = this.props.match.params;

        this.setState({ categories: treeData.map((x) => ({ id: x.id, name: x.title })) });
        if (id !== 'new') {
            this.fetchContentAndCategories();
        }

        parentId &&
            (await this.setState({ form: { ...this.state.form, categoryId: parentId }, mode }));

        if (groupId !== centerGroupId && id === 'new') {
            await this.selectAllGroups();
        }
        if (id === 'new') {
            await this.init();
        }
        window.scrollTo(0, 0);
    }

    async componentDidUpdate(prevProps) {
        const {
            parentId,
            mode = 'edit',
            centerGroupId,
            currentUser: { groupId },
            treeData,
            treeLoading,
        } = this.props;

        const { id } = this.props.match.params;

        if (!treeLoading) {
            if (
                prevProps?.match?.params?.id !== id ||
                prevProps.parentId !== parentId ||
                prevProps?.treeLoading !== treeLoading
            ) {
                this.setState({ categories: treeData.map((x) => ({ id: x.id, name: x.title })) });
                if (id !== 'new') this.fetchContentAndCategories();
                parentId &&
                    (await this.setState({
                        form: { ...this.state.form, categoryId: parentId },
                        mode,
                    }));
                window.scrollTo(0, 0);
                if (groupId !== centerGroupId && id === 'new') {
                    await this.selectAllGroups();
                }

                if (id === 'new') {
                    await this.init();
                }
            }
        }
    }

    init() {
        const { form } = this.state;
        const { roles, groups } = this.props;
        const role = roles.length === 1 ? roles.map((x) => x.id) : form.roles;
        const group = groups.length === 1 ? groups.map((x) => x.id) : form.groups;

        this.setState({ form: { ...form, roles: role, groups: group } });
    }

    setItemAccess = () => {
        const { form } = this.state;
        const { currentUser, centerGroupId } = this.props;
        const canEdit =
            currentUser.groupId === form.author.groupId ||
            currentUser.groupId === centerGroupId ||
            !form.author?.groupId;
        this.props.setItemFullAccess(canEdit);
    };

    fetchContentAndCategories = () => {
        this.callEffect(async () => {
            const resultContent = await getContentByIdFromEditor(this.props.match.params.id);
            const groups = resultContent.data.groups.map((x) => x.id);
            const roles = resultContent.data.roles.map((x) => x.id);

            await this.setState({
                form: {
                    ...resultContent.data,
                    groups: groups,
                    roles: roles,
                },
                prevForm: { ...resultContent.data },
            });
            this.setItemAccess();
            this.props.setLoadedItem({
                ...resultContent.data,
                isArticle: true,
                parentId: resultContent.data.categoryId,
            });
        });
    };

    setStateFrom(form) {
        const groups = form.groups.map((x) => x.id);
        const roles = form.roles.map((x) => x.id);

        this.setState({
            form: {
                ...form,
                groups: groups,
                roles: roles,
            },
            prevForm: { ...form },
        });
    }

    getValidateErrors = () => {
        const errors = [];

        if (!this.state.form.title) {
            errors.push('Не указано название');
        }

        if (!this.state.form.title) {
            errors.push('Не указано название');
        }
        return errors;
    };

    onChange = (item) => {
        this.setState((state) => ({
            form: {
                ...state.form,
                ...item,
            },
            dirtyForm: true,
        }));
    };

    onChangeCategory = (item) => {
        const { treeData } = this.props;
        this.props.setParentRolesGroups(treeData, { parentId: item });
        this.setState((state) => ({
            form: {
                ...state.form,
                categoryId: item,
            },
        }));
    };

    onChangeRoles = (item) => {
        const { editorRole } = this.props;
        const selectedRoles = item
            ? item.find((x) => x === editorRole.id)
                ? item
                : [...item, editorRole.id]
            : [editorRole.id];

        this.setState((state) => ({
            form: {
                ...state.form,
                roles: selectedRoles,
            },
        }));
    };

    onChangeGroups = (item) => {
        this.setState((state) => ({
            form: {
                ...state.form,
                groups: item,
            },
        }));
    };

    onSaveContent = () => {
        const { saveCallback, createCallback, onItemClick } = this.props;

        const errors = this.getValidateErrors();

        if (errors.length > 0) {
            this.props.showWarningAlert('Не все поля корректно заполнены.');
            return;
        }

        const { form } = this.state;

        const formToSend = {
            ...form,
            title: form.title.trim(),
            summary: this.addHttps(form.summary),
        };

        this.callEffect(async () => {
            const result = formToSend.id
                ? await updateContent(formToSend)
                : await saveContent(formToSend);
            showSuccessAlert('Сохранение прошло успешно');
            if (formToSend.id) {
                saveCallback && saveCallback();
            } else {
                createCallback && (await createCallback(result.data));
                onItemClick({ ...result.data, isArticle: true, parentId: result.data?.categoryId });
            }

            this.setStateFrom(result.data);
        });
    };

    onDelete = () => {
        const { setModal } = this.props;
        const modalHeader = `Удалить инструкцию "${this.state.form.title}"?`;
        setModal ? setModal(modalHeader, () => this.deleteItem()) : this.deleteItem();
    };

    deleteItem = () => {
        const { deleteCallback, setModal, setModalProcessing } = this.props;

        try {
            this.callEffect(async () => {
                setModalProcessing && setModalProcessing(true);
                const result = await deleteContent(this.state.form.id);
                if (result) {
                    deleteCallback && deleteCallback(this.state.form?.categoryId);
                    this.props.showSuccessAlert('Инструкция успешно удалена');
                }
            });
        } finally {
            setModalProcessing && setModalProcessing(false);
            setModal && setModal('', undefined, false);
        }
    };

    onChangeStatus = (status) => {
        const { saveCallback } = this.props;
        if (status === CONTENT_STATUS_ENUM.Published && !this.state.form.summary) {
            this.props.showErrorAlert('Для публикации инструкции необходимо добавить описание');
            return;
        }
        if (!!this.state.form.id) {
            this.callEffect(async () => {
                const result = await changeStatus(this.state.form.id, status);

                if (result) {
                    this.setStateFrom(result.data);
                    let tree = deepClone(this.props.treeData);
                    this.props.updateTreeNodeStatus(this.state.form.id, status, tree);
                    saveCallback && saveCallback();
                }
            });
        } else {
            this.onSaveContent();
        }
        this.setState({ openModal: false });
    };

    addHttps(value) {
        const checkUrlsReg = /\[(.*?)\]\((http(s?):\/\/)(.*?)\)/g;
        const replaceToString = '[$1](https://$4)';
        return value && value.trim().replace(checkUrlsReg, replaceToString);
    }

    onUpdateFiles = async (files) => {
        return await this.callEffect(async () => {
            const result = await uploadContentFiles(files);

            const contentFiles = this.state.form.contentFiles
                ? this.state.form.contentFiles.concat(result.data)
                : result.data;

            this.setState((state) => ({
                form: {
                    ...state.form,
                    contentFiles,
                },
            }));

            return result;
        });
    };

    onEditContentMode = () => {
        this.setState({ mode: 'edit' });
    };

    onViewContentMode = () => {
        if (this.state.form.id) {
            window.open(InstructionsViewFromEdit.buildUrl({ id: this.state.form.id }));
        } else {
            this.setState({ mode: 'view' });
        }
    };

    onCancel = () => {
        const { cancelCallback } = this.props;
        cancelCallback && cancelCallback();
    };

    onCopy = async () => {
        const { copyCallback } = this.props;
        if (!!this.state.form.id) {
            this.callEffect(async () => {
                const result = await copyContent(this.state.form.id);
                if (result.data) {
                    this.setStateFrom(result.data);
                    showSuccessAlert('Инструкция успешно скопирована');
                    copyCallback &&
                        copyCallback({
                            ...result.data,
                            isArticle: true,
                            parentId: result.data.categoryId,
                        });
                }
            });
        }
    };

    onChangePreview = (fieldName, value) => {
        this.setState((state) => ({
            form: {
                ...state.form,
                [fieldName]: this.addHttps(state.form[fieldName]),
            },
            [fieldName + 'Preview']: value,
        }));
    };

    callEffect = async (callback) => {
        this.setState({ isBusy: true });
        this.props.showPageLoader();
        try {
            return await callback();
        } catch (error) {
            const validationErrors = getValidationErrors(error);
            if (Array.isArray(validationErrors) && validationErrors.length > 0) {
                validationErrors.map((item) => {
                    return this.props.showErrorAlert(item.message);
                });
            } else {
                const reqError = getError(error, this.getContentEditorError);
                this.props.showErrorAlert(reqError.message);
            }
        } finally {
            this.props.hidePageLoader();
            this.setState({ isBusy: false });
        }
    };

    getContentEditorError = (code) => {
        switch (code) {
            case serviceResultCode.ValidationErrors:
                return 'Произошла ошибка при сохранении';
            default:
                return `Произошла непредвиденная ошибка`;
        }
    };

    onExecute = (type) => {
        switch (type) {
            case 'cancel':
                this.onCancel();
                return;
            case 'delete':
                this.onDelete();
                return;
            case 'save':
                this.onSaveContent();
                return;
            case 'copy':
                this.onCopy();
                return;
            case 'viewContentMode':
                this.onViewContentMode();
                return;
            case 'publish':
                this.onChangeStatus(CONTENT_STATUS_ENUM.Published);
                return;
            case 'draft':
                this.onChangeStatus(CONTENT_STATUS_ENUM.Draft);
                return;
            default:
                return;
        }
    };

    renderContentInfo = (form) => {
        return (
            <Row className="ContentEditor__ShortTechInfo">
                <Col md={6}>
                    <Field>
                        <Label>Автор последнего изменения</Label>
                        <Input
                            className="ContentEditor__Input"
                            value={
                                (form.modifier && getUserShortName(form.modifier)) ||
                                getUserShortName(form.author) ||
                                ''
                            }
                            disabled={true}
                        />
                    </Field>
                </Col>
                <Col md={3}>
                    <Field>
                        <Label>Дата изменения</Label>
                        <Input
                            className="ContentEditor__Input"
                            maxLength="200"
                            value={formatDate(form.modified) || formatDate(form.created) || ''}
                            disabled={true}
                        />
                    </Field>
                </Col>
                <Col md={3}>
                    <Field>
                        <Label>Версия</Label>
                        <Input
                            className="ContentEditor__Input"
                            value={form.version || ''}
                            disabled={true}
                        />
                    </Field>
                </Col>
            </Row>
        );
    };

    selectAllGroups = () => {
        this.onChangeGroups(this.props.groups.map((x) => x.id));
    };

    render() {
        const { form, categories, mode, prevForm } = this.state;
        const { id } = this.props.match.params;
        const viewMode = mode === 'view';
        const selectedStatus = CONTENT_STATUS.find((x) => x.value === form.status);

        const customSelectStyles = {
            control: (base) => ({
                ...base,
                minHeight: 55,
                backgroundColor: '#f9f9ff',
                borderColor: '#d2d5ea',
                maxHeight: 200,
            }),
            multiValueLabel: (base, state) => {
                return state.data.isFixed ? { ...base, paddingRight: '5px' } : base;
            },
            multiValueRemove: (base, state) => {
                return state.data.isFixed ? { ...base, display: 'none' } : base;
            },
        };

        const canEdit = this.props.itemFullAccess;

        return (
            <div>
                <div className="ContentEditor">
                    {canEdit ? (
                        form.id || (!form.id && id === 'new') ? (
                            <DataCard className="ContentEditor__Content">
                                <Row>
                                    <Col md={12}>
                                        <ContentEditorToolbar
                                            viewMode={viewMode}
                                            summaryPreview={this.state.summaryPreview}
                                            form={form}
                                            prevForm={prevForm}
                                            onExecute={this.onExecute}
                                            isPublished={
                                                form.status === CONTENT_STATUS_ENUM.Published
                                            }
                                        ></ContentEditorToolbar>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md={12} xs>
                                        <Field filled={!isNullOrWhitespace(form.title)} required>
                                            <Label>Название</Label>
                                            <Input
                                                className="ContentEditor__Input"
                                                maxLength="200"
                                                value={form.title || ''}
                                                disabled={viewMode}
                                                onChange={(e) =>
                                                    this.onChange({ title: e.target.value })
                                                }
                                            />
                                        </Field>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Field required filled={!isNullOrWhitespace(form.categoryId)}>
                                            <Label>Категория</Label>
                                            <DictionarySelect
                                                inputId="selectedCategoryId"
                                                placeholder={<div>Выберите категорию</div>}
                                                options={categories}
                                                value={form.categoryId}
                                                onChange={(category) =>
                                                    this.onChangeCategory(category)
                                                }
                                                styles={customSelectStyles}
                                                isDisabled={viewMode}
                                                isClearable
                                            />
                                        </Field>
                                    </Col>
                                    <Col md={4}>
                                        <Field>
                                            <Label>Статус</Label>
                                            <Input
                                                disabled={true}
                                                value={selectedStatus.label || ''}
                                            />
                                        </Field>
                                    </Col>
                                    <Col md={4}>
                                        <Field>
                                            <Label>Дата публикации</Label>
                                            <Input
                                                className="ContentEditor__Input"
                                                value={formatDate(form.published) || ''}
                                                disabled={true}
                                            />
                                        </Field>
                                    </Col>
                                </Row>
                                {form.author && form.author.id && this.renderContentInfo(form)}
                                {(!viewMode || form.summary) && (
                                    <Row>
                                        <Col md={12}>
                                            <Field className="ContentEditor__Description">
                                                <Label>Описание</Label>
                                                <TextEditor
                                                    value={form.summary}
                                                    showToolbar={!viewMode}
                                                    preview={viewMode || this.state.summaryPreview}
                                                    minHeight="auto"
                                                    extendedToolbar={true}
                                                    onChangePreview={(value) =>
                                                        this.onChangePreview('summary', value)
                                                    }
                                                    onChange={(e) =>
                                                        this.onChange({ summary: e.target.value })
                                                    }
                                                    onPasteFiles={this.onUpdateFiles}
                                                    renderDropZone={true}
                                                    prefixFileApi="api/content/files"
                                                    backRender={true}
                                                />
                                            </Field>
                                        </Col>
                                    </Row>
                                )}
                            </DataCard>
                        ) : (
                            <div className="ErrorBox">
                                <h1>
                                    {this.state.isBusy || this.props.treeLoading
                                        ? ''
                                        : 'Запрашиваемая инструкция не найдена'}
                                </h1>
                            </div>
                        )
                    ) : (
                        <div className="ErrorBox">
                            <h1>Вы не можете редактировать данную инструкцию</h1>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const props = (state) => ({ currentUser: state.auth.user });
const actions = {
    showPageLoader,
    hidePageLoader,
    showErrorAlert,
    showSuccessAlert,
    showWarningAlert,
    pushLocation,
    fetchDictionaries,
};

export default withRouter(connect(props, actions)(ContentEditor));
