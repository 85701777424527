import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Input.scss';

const Input = (props) => {
    const {
        type,
        postIconButton,
        postIconButtonClick,
        postIconButtonMouseDown,
        postIconButtonMouseUp,
        ...rest
    } = props;

    switch (type) {
        case 'textarea':
            return <TextareaAutosize {...rest} />;
        case 'checkbox':
            return <input type={type} {...rest} />;
        default:
            return (
                <div className="InputWrapper">
                    <input type={type} {...rest} />
                    {postIconButton && (
                        <div
                            className="InputPostIconButton"
                            onMouseDown={postIconButtonMouseDown}
                            onMouseUp={postIconButtonMouseUp}
                            onClick={postIconButtonClick}
                        >
                            <FontAwesomeIcon icon={postIconButton} size="lg" fixedWidth />
                        </div>
                    )}
                </div>
            );
    }
};

Input.propTypes = {
    type: PropTypes.string,
    postIconButton: PropTypes.string,
    postIconButtonClick: PropTypes.func,
    postIconButtonMouseDown: PropTypes.func,
    postIconButtonMouseUp: PropTypes.func,
};

export default Input;
