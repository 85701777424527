import PropTypes from 'prop-types';
import React from "react";
import { connect } from 'react-redux';
import ModalDialog from 'components/common/ModalDialog'
import { accept, hide } from 'ducks/AcceptModal';
import './AcceptModal.scss';

function AcceptModal(props) {
    const { title, question, actions, accept, hide } = props;

    return (
        <ModalDialog
            modalOpen
            modalHeader={title}
            btnOktext="Да"
            btnOkColor="danger"
            btnCanceltext="Нет" 
            actions={actions}
            accept={accept}
            onCloseModal={hide}
            >
                {question}
            </ModalDialog>
    );
}

AcceptModal.propTypes = {
    title: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    actions: PropTypes.array.isRequired,
    accept: PropTypes.func.isRequired,
};


function AcceptModalManager(props) {
    const { item, accept, hide } = props;

    return item ? <AcceptModal {...item} accept={accept} hide={hide} /> : null;
}

AcceptModalManager.propTypes = {
    item: PropTypes.object,
};


const mapStateToProps = state => ({ item: state.acceptModal.item });
const actions = { accept, hide };

export default connect(mapStateToProps, actions)(AcceptModalManager);
