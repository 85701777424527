import React, { useState } from 'react';
import { connect } from 'react-redux';
import InfoField from 'components/Lk/Uikit/InfoField';
import ProfileButton from 'components/Lk/Uikit/Buttons/Button/ProfileButton';
import classnames from 'classnames';
import NoData from './NoData';
import { MENU_PROFILE_EDUCATION } from './constants';

const EducationInfo = props => {
    const { person, isWrap, setWrap, onChange, isEdit, educationalLevels, studyForms, setVisibleWrapSection } = props;

    const {
        educationInfo: { educations },
    } = person;

    const [currentId, setCurrentId] = useState(null);

    const [currentEducation = {}] = educations.filter(item => item.id === currentId);

    const selectEducation = id => () => {
        setCurrentId(id);
        setWrap(true);
        setVisibleWrapSection(MENU_PROFILE_EDUCATION);
    };

    const onAdd = () => {
        const id = -new Date().getTime();
        const newEducation = { id };
        onChange(
            { ...person, educationInfo: { educations: [...educations, newEducation] } },
            'EducationBlock',
        );
    };

    const onRemove = id => e => {
        e.stopPropagation();
        onChange(
            { ...person, educationInfo: { educations: educations.filter(a => a.id !== id) } },
            'EducationBlock',
        );
    };

    const onChangeState = name => value => {
        const newEducation = { ...currentEducation, [name]: value };

        const newEducations = educations.map(item =>
            item.id === currentEducation.id ? newEducation : item,
        );
        onChange({ ...person, educationInfo: { educations: newEducations } }, 'EducationBlock');
    };

    const renderField = education => {
        const { university, specialty, faculty, educationLevel, studyForm, graduationYear, id, extraInfo } = education;
        return (
            <InfoField
                icon={
                    isEdit && {
                        icon: 'minus-circle',
                        size: 'xs',
                        color: 'red',
                        onClick: onRemove(id),
                    }
                }
                noIcon={!isEdit}
                key={id}
                onWrap={selectEducation(id)}
                dataTest="EductionInfo_SelectItem--button"
            >
                {university && <div>{university}</div>}
                {specialty && 
                <div>
                    <span className="InfoField__Title">Специальность - </span>
                    <span className="PersonalInfo__SmallValue">{specialty}</span>
                </div>}
                {faculty && 
                <div>
                    <span className="InfoField__Title">Факультет - </span>
                    <span className="PersonalInfo__SmallValue">{faculty}</span>
                </div>}
                {educationLevel && educationLevel.name &&
                <div>
                    <span className="InfoField__Title">Уровень образования - </span>
                    <span className="PersonalInfo__SmallValue">{educationLevel.name}</span>
                </div>}
                {studyForm && studyForm.name &&
                <div>
                    <span className="InfoField__Title">Форма обучения - </span>
                    <span className="PersonalInfo__SmallValue">{studyForm.name}</span>
                </div>}
                {!!graduationYear && 
                <div> 
                    <span className="InfoField__Title">Окончил - </span> 
                    <span className="PersonalInfo__SmallValue">{graduationYear}</span>
                </div>}
                {extraInfo && 
                <div> 
                    <span className="InfoField__Title">Повышение квалификации и другая информация о дополнительном образовании - </span> 
                <span className="PersonalInfo__SmallValue">{extraInfo}</span></div>}                
                {!university && !specialty && !faculty && !educationLevel && !studyForm && !!!graduationYear && !extraInfo && '–'}
            </InfoField>
        );
    };

    return (
        <div className={classnames("PersonalInfo", {'PersonalInfo--bottom-margin': isEdit})}>
            {isWrap && currentId !== false ? (
                <>
                    <InfoField
                        title="ВУЗ"
                        isEdit={isEdit}
                        value={currentEducation.university || ''}
                        onChange={onChangeState('university')}
                        inputProps={{
                            dataTest: "EductionInfo_EditUniversity--input"
                        }}
                        maxLength={250}
                        dataTest="EductionInfo_University--text"
                        noIcon
                    />
                    <InfoField
                        title="Специальность"
                        isEdit={isEdit}
                        value={currentEducation.specialty || ''}
                        onChange={onChangeState('specialty')}
                        inputProps={{
                            dataTest: "EductionInfo_EditSpecialty--input"
                        }}
                        maxLength={250}
                        dataTest="EductionInfo_Specialty--text"
                        noIcon
                    />
                    <InfoField
                        title="Факультет"
                        isEdit={isEdit}
                        value={currentEducation.faculty || ''}
                        onChange={onChangeState('faculty')}
                        inputProps={{
                            dataTest: "EductionInfo_EditFaculty--input"
                        }}
                        maxLength={250}
                        dataTest="EductionInfo_Faculty--text"
                        noIcon
                    />
                    <InfoField
                        value={
                            currentEducation.educationLevel && currentEducation.educationLevel.id
                        }
                        title="Уровень образования"
                        required
                        isEdit={isEdit}
                        inputProps={{
                            type: 'select',
                            items: educationalLevels,
                            onChange: onChangeState('educationLevel'),
                        }}
                        dataTest="EductionInfo_EducationLevel--text"
                        noIcon
                    >
                        {currentEducation.educationLevel && currentEducation.educationLevel.name}
                    </InfoField>
                    <InfoField
                        value={currentEducation.studyForm && currentEducation.studyForm.id}
                        title="Форма обучения"
                        isEdit={isEdit}
                        inputProps={{
                            type: 'select',
                            items: studyForms,
                            onChange: onChangeState('studyForm'),
                        }}
                        dataTest="EductionInfo_StudyForm--text"
                        noIcon
                    >
                        {currentEducation.studyForm && currentEducation.studyForm.name}
                    </InfoField>
                    <InfoField
                        title="Год окончания"
                        isEdit={isEdit}
                        type="number"
                        value={currentEducation.graduationYear || ''}
                        onChange={onChangeState('graduationYear')}
                        inputProps={{
                            dataTest: "EductionInfo_EditGraduationYear--input"
                        }}
                        maxLength={4}
                        dataTest="EductionInfo_GraduationYear--text"
                        noIcon

                    />
                    <InfoField
                        title="Повышение квалификации и другая информация о дополнительном образовании"
                        isEdit={isEdit}
                        value={currentEducation.extraInfo || ''}
                        onChange={onChangeState('extraInfo')}
                        inputProps={{
                            dataTest: "EductionInfo_EditExtraInfo--input"
                        }}
                        dataTest="EductionInfo_ExtraInfo--text"
                        maxLength={4000}
                        noIcon
                    />
                </>
            ) : (
                <>
                    {(educations.size && educations.size !== 0) || (educations.length && educations.length !== 0)
                        ? educations.map(renderField)
                        : (!isEdit && <NoData text='Нет данных' data-test="EducationInfo_NoData--test" />)
                    }
                    {isEdit && (
                        <InfoField noIcon>
                            <ProfileButton onClick={onAdd} dataTest="EducationInfo_AddEducation--button" >добавить</ProfileButton>
                        </InfoField>
                    )}
                </>
            )}
        </div>
    );
};
const mapStateToProps = state => ({
    educationalLevels: state.catalogs.educationalLevels.data,
    studyForms: state.catalogs.studyForms.data,
});

export default connect(mapStateToProps)(EducationInfo);
