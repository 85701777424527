import React, { useEffect, useState, useCallback } from 'react';
import { getHtmContent } from 'api';
import { converter } from 'libs/showdown';
import Loader from 'components/common/Loader';
import classnames from 'classnames';

const PreviewContent = props => {
    const { value, backRender, disabled = false } = props;
    const [html, setHtml] = useState('');
    const [loading, setLoading] = useState(false);

    const loadHtml = useCallback(async () => {
        setLoading(true);
        if (backRender) {
            const result = await getHtmContent(value);
            setHtml(result.data);
        } else {
            setHtml(await converter.makeHtml(value));
        }
        setLoading(false);
    }, [value, backRender]);
    useEffect(() => {
        loadHtml();
    }, [loadHtml]);

    return loading ? (
        <div className="TextEditor__Preview Clear">
            <Loader />
        </div>
    ) : (
        <div
            className={classnames("InstructionCommon TextEditor__Preview Clear", {"TextEditor__Disabled": disabled})}
            dangerouslySetInnerHTML={{
                __html: html,
            }}
        ></div>
    );
};

export default PreviewContent;
