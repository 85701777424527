import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import {
    attributes,
    attributeNew,
    attributeEdit,
    group,
    groupEdit,
    groupNew,
    groups,
    user,
    userEdit,
    userNew,
    users,
    usersActions,
    access,
    adminPersons,
    msgGate,
} from 'routes';
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import EditGroup from './EditGroup';
import EditUser from './EditUser';
import Group from './Group';
import Groups from './Groups';
import User from './User/';
import Users from './Users';
import Attributes from './Attributes/Attributes';
import EditAttribute from './Attributes/EditAttribute';
import NotFound from '../NotFound';
import UserActions from './UserActions';
import SmsEmailGate from './SmsEmailGate';
import {
    ROLE_IT_ADMIN,
    ROLE_SECURITY_ADMIN,
    ROLE_SUPPORT_LINE1,
    ROLE_SUPPORT_LINE2,
} from 'constants.js';
import Access from './Access';
import Persons from './Persons';

export class AdminRoutes extends Component {
    renderEditUser = ({ match }) => <EditUser id={+match.params.id} />;
    renderUser = ({ match, location }) => <User id={+match.params.id} location={location} />;
    renderEditGroup = ({ match }) => <EditGroup id={+match.params.id} />;
    renderGroup = ({ match, location }) => <Group id={+match.params.id} location={location} />;
    renderNotFound = () => <NotFound />;
    renderEditAttribute = ({ match }) => <EditAttribute id={+match.params.id} />;

    render() {
        return (
            <Switch>
                <ProtectedRoute
                    exact
                    path={userNew.url}
                    component={EditUser}
                    allowedRoles={[ROLE_IT_ADMIN]}
                />
                <ProtectedRoute
                    path={userEdit.url}
                    component={this.renderEditUser}
                    allowedRoles={[
                        ROLE_IT_ADMIN,
                        ROLE_SECURITY_ADMIN,
                        ROLE_SUPPORT_LINE1,
                        ROLE_SUPPORT_LINE2,
                    ]}
                />
                <ProtectedRoute
                    path={user.url}
                    component={this.renderUser}
                    allowedRoles={[
                        ROLE_IT_ADMIN,
                        ROLE_SECURITY_ADMIN,
                        ROLE_SUPPORT_LINE1,
                        ROLE_SUPPORT_LINE2,
                    ]}
                />
                <ProtectedRoute
                    path={users.url}
                    component={Users}
                    allowedRoles={[
                        ROLE_IT_ADMIN,
                        ROLE_SECURITY_ADMIN,
                        ROLE_SUPPORT_LINE1,
                        ROLE_SUPPORT_LINE2,
                    ]}
                />
                <ProtectedRoute
                    path={usersActions.url}
                    component={UserActions}
                    allowedRoles={[ROLE_IT_ADMIN, ROLE_SECURITY_ADMIN]}
                />
                <ProtectedRoute
                    path={groupNew.url}
                    component={EditGroup}
                    allowedRoles={[ROLE_IT_ADMIN]}
                />
                <ProtectedRoute
                    path={groupEdit.url}
                    component={this.renderEditGroup}
                    allowedRoles={[ROLE_IT_ADMIN, ROLE_SECURITY_ADMIN]}
                />
                <ProtectedRoute
                    path={group.url}
                    component={this.renderGroup}
                    allowedRoles={[
                        ROLE_IT_ADMIN,
                        ROLE_SECURITY_ADMIN,
                        ROLE_SUPPORT_LINE1,
                        ROLE_SUPPORT_LINE2,
                    ]}
                />
                <ProtectedRoute
                    path={groups.url}
                    component={Groups}
                    allowedRoles={[
                        ROLE_IT_ADMIN,
                        ROLE_SECURITY_ADMIN,
                        ROLE_SUPPORT_LINE1,
                        ROLE_SUPPORT_LINE2,
                    ]}
                />
                <ProtectedRoute
                    path={attributes.url}
                    component={Attributes}
                    allowedRoles={[ROLE_IT_ADMIN, ROLE_SECURITY_ADMIN]}
                />
                <ProtectedRoute
                    path={attributeNew.url}
                    component={EditAttribute}
                    allowedRoles={[ROLE_IT_ADMIN]}
                />
                <ProtectedRoute
                    path={attributeEdit.url}
                    component={this.renderEditAttribute}
                    allowedRoles={[ROLE_IT_ADMIN, ROLE_SECURITY_ADMIN]}
                />
                <ProtectedRoute
                    path={access.url}
                    component={Access}
                    allowedRoles={[ROLE_IT_ADMIN, ROLE_SECURITY_ADMIN]}
                />

                <ProtectedRoute
                    path={adminPersons.url}
                    component={Persons}
                    allowedRoles={[ROLE_SUPPORT_LINE2]}
                />
                
                <ProtectedRoute
                    path={msgGate.url}
                    component={SmsEmailGate}
                    allowedRoles={[ROLE_SUPPORT_LINE1, ROLE_SUPPORT_LINE2]}
                />
                <Route component={this.renderNotFound} />
            </Switch>
        );
    }
}

export default AdminRoutes;
