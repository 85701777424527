import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Row, Col } from 'react-flexbox-grid';
import RoundButton from 'components/uikit/Button/RoundButton';
import classnames from 'classnames';

const CardHeader = (props) => {
    const {header, canEdit, onEdit, onCopy, copyHint, canDelete, onDelete} = props;
    const hasActionButtons = true;

    return (
        <div className={classnames("Card__Header", {"Card__Header--empty": !header})}>
            <Row middle="xs">
                <Col xs={10}>
                    {header}
                </Col>
                <Col xs={2}>
                    <Row end="xs">
                        {hasActionButtons 
                            && <div className="ActionButtons">
                                {onCopy && 
                                    <>
                                        <div data-tip data-for='btnCopy' style={{display: "inline-block"}}>
                                            <RoundButton id='btnCopy' type="copy" onClick={onCopy} />
                                        </div>
                                        {copyHint && 
                                        <ReactTooltip 
                                            id='btnCopy'
                                            effect={'solid'} 
                                            border={true} 
                                            type={'light'} 
                                            data-offset="{'top': 0, 'left': 0}"
                                        >
                                            <p className="tooltip">{copyHint}</p>
                                        </ReactTooltip>
                                        }
                                    </>
                                }
                                {canEdit && <RoundButton type="edit" onClick={onEdit} />}
                                {canDelete && <RoundButton type="close" onClick={onDelete}/>}
                            </div>
                        }
                    </Row>
                </Col>
            </Row>
        </div>        
    );
};

CardHeader.propTypes = {
    header: PropTypes.string,
    canEdit: PropTypes.bool,
    onEdit: PropTypes.func,
    onCopy: PropTypes.func,
    canMinimize: PropTypes.bool,
    copyHint: PropTypes.string,
};

export default CardHeader;