import React, { memo } from 'react';
import DatePicker from 'components/uikit/DatePicker';
import Input from 'components/uikit/Input';
import moment from 'moment';
import DictionaryField from './DictionaryField';

// TODO: сделать нормальную фабрику полей
export const Field = props => {
    const { type, onChange, ...tail } = props;
    const onInputChange = e => onChange(e.target.value);

    switch (type) {
        case 'Date':
            const onDateChange = val => onChange(moment(val).format('YYYY-MM-DD'));
            return <DatePicker {...tail} inPortal={true} onChange={onDateChange} />;
        case 'Number':
            return <Input {...tail} onChange={onInputChange} type="number" />;
        case 'Select':
            return <DictionaryField {...tail} onChange={onChange} />;
        default:
            return <Input {...tail} onChange={onInputChange} />;
    }
};

export default memo(Field);
