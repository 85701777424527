import React, { useEffect, useState, useMemo } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { MAX_AGE, MIN_AGE, AGE_FILTER_ID } from '../constants';
import { setSearchFilter } from '../utils';
import Field from 'components/Lk/Uikit/Field';
import Select from 'components/Lk/Uikit/Select/CustomSelect';
import SearchGroupCondition from '../SearchGroupCondition';
import DatePicker from 'libs/react-datepicker';
import ActiveFilters from '../ActiveFilters';
import uuid from 'uuid/v4';
import { connect } from 'react-redux';
import { queryOperation } from 'components/Search/QueryOperation';
import SuccessButton from '../SuccessButton';
import { FIELDS_TYPE } from '../SearchFilters/constants';
import FilterField from './FilterField';
import StyledCheckBox from 'components/Lk/Uikit/StyledCheckBox';
import { getPersonAgeRange } from 'api';

const Fields = [
    { value: 'age', label: 'Возраст (полных лет)', isDefault: true, withHeader: true },
    { value: 'date', label: 'Дата рождения' },
    {
        type: FIELDS_TYPE.input,
        label: 'Фамилия',
        column: 'LastName',
        value: 'lastName',
        table: 'Persons',
        isExtra: true,
        query: queryOperation.Contain,
        isDefault: true,
    },
    {
        type: FIELDS_TYPE.input,
        label: 'Имя',
        value: 'firstName',
        column: 'FirstName',
        table: 'Persons',
        isExtra: true,
        query: queryOperation.Contain,
        isDefault: true,
    },
    {
        type: FIELDS_TYPE.input,
        label: 'Отчество',
        value: 'MiddleName',
        column: 'MiddleName',
        table: 'Persons',
        isExtra: true,
        query: queryOperation.Contain,
        isDefault: true,
    },
    { value: 'language', label: 'Язык', isDefault: true },
    {
        type: FIELDS_TYPE.select,
        label: 'Пол',
        value: 'sex',
        column: 'Sex',
        catalog: 'sexCatalog',
        table: 'Persons',
        isExtra: true,
    },
    {
        type: FIELDS_TYPE.select,
        label: 'Документ',
        column: 'IdentityDocumentName',
        value: 'documentType',
        catalog: 'identityDocuments',
        table: 'Persons',
        isExtra: true,
        isValueName: true,
    },
    {
        type: FIELDS_TYPE.input,
        label: 'Номер документа',
        column: 'IdentityDocumentNumber',
        value: 'documentNumber',
        table: 'Persons',
        isExtra: true,
        query: queryOperation.Like,
    },
    {
        type: FIELDS_TYPE.input,
        label: 'Название документа',
        column: 'IdentityDocumentCustomName',
        value: 'documentName',
        table: 'Persons',
        isExtra: true,
        query: queryOperation.Contain,
    },
    {
        type: FIELDS_TYPE.input,
        label: 'Телефон',
        column: 'Phone',
        value: 'phone',
        table: 'Persons',
        isExtra: true,
        query: queryOperation.Like,
    },
    {
        type: FIELDS_TYPE.input,
        label: 'E-mail',
        column: 'Email',
        value: 'email',
        table: 'Persons',
        isExtra: true,
        query: queryOperation.Like,
    },
    {
        type: FIELDS_TYPE.input,
        label: 'Биографическая справка',
        column: 'ExtraInfo',
        value: 'personExtraInfo',
        table: 'Persons',
        isExtra: true,
        query: queryOperation.Contain,
    },
    {
        type: FIELDS_TYPE.multiSelect,
        label: 'Семейный статус',
        column: 'FamilyStatus',
        value: 'familyStatus',
        catalog: 'familyStatuses',
        table: 'Persons',
        isExtra: true,
        query: queryOperation.InArray,
    },
    {
        type: FIELDS_TYPE.multiSelect,
        label: 'Кол-во детей',
        column: 'ChildrenStatusId',
        value: 'childrenStatus',
        catalog: 'childrenStatus',
        table: 'Persons',
        isExtra: true,
        query: queryOperation.InArray,
    },
    {
        type: FIELDS_TYPE.input,
        label: 'Рекомендатель',
        column: 'RecommenderName',
        value: 'recommenderName',
        table: 'PersonPersonnels',
        isExtra: true,
        query: queryOperation.Contain,
    },
    {
        type: FIELDS_TYPE.input,
        label: 'Куратор',
        column: 'CuratorName',
        value: 'curatorName',
        table: 'PersonPersonnels',
        isExtra: true,
        query: queryOperation.Contain,
    },
    {
        type: FIELDS_TYPE.input,
        label: 'Доп. сведения',
        column: 'ExtraInfo',
        value: 'extraInfo',
        table: 'PersonPersonnels',
        isExtra: true,
        query: queryOperation.Contain,
    },
];

const PersonFilters = props => {
    const {
        selectStyles,
        setFiltersState,
        updateFiltersWithCondition,
        getGroupCondition,
        mainFilter,
        activeFilter,
        onSuccess,
        filterId,
        catalogs,
        defaultFilters,
        setDefaultFilters,
        isFocused,
    } = props;

    const [state, setState] = useState({});

    const [visibleSection, setVisibleSection] = useState(null);
    const [groupCondition, setGroupCondition] = useState(getGroupCondition(filterId));
    const [excludeFromSearch, setExcludeFromSearch] = useState(false);
    const [ageRange, setAgeRange] = useState(null);

    const ageOptions = useMemo(() => {
        let options = [];
        if (ageRange) {
            const ageFrom = ageRange.minAge < 14 ? 14 : ageRange.minAge;
            const ageTo = ageRange.maxAge > 100 ? 100 : ageRange.maxAge;
            for (let i = ageFrom; i <= ageTo; i++) {
                options.push({ value: i, label: i });
            }
        }
        return options;
    }, [ageRange]);

    const { startBD, endtBD, ageFrom, ageTo, languageId, languageLevelId } = state;

    const handleCriteriaChange = e => {
        if (e?.value === "date") {
                !startBD && onChange({startBD: new Date(1975, 0, 1)});
                !endtBD && onChange({endtBD: new Date(1990, 0, 1)});
        }

        setVisibleSection(e);
    };

    const clearFields = () => {
        setState({});
        setExcludeFromSearch(false);
    };

    const onSubmit = async isClose => {
        await addDefaultFilters();
        await AddFilter();
        clearFields();
        setVisibleSection(null);
        isClose && onSuccess();
    };

    const addDefaultFilters = () => {
        return defaultFilters.map(x => AddFilter(x));
    };

    const onChange = state => {
        setState(x => ({ ...x, ...state }));
    };

    const onChangeField = name => value => {
        onChange({ [name]: value });
    };

    useEffect(() => {
        const fetchAgeRange = async () => {
            const ageRangeData = await getPersonAgeRange();
            setAgeRange(ageRangeData.data);
        };
        fetchAgeRange();
    }, []);

    useEffect(() => {
        setDefaultFilters(Fields.filter(x => x.isDefault));
    }, [setDefaultFilters]);

    useEffect(() => {
        if (activeFilter == null) {
            return;
        }
        const [activeFilters] = mainFilter.filter(x => x.filterId === AGE_FILTER_ID);
        const { childs: filters = [] } = activeFilters || {};

        const currentFilters = filters.filter(x => x.group === activeFilter.group);
        const sectionValue = activeFilter.section || activeFilter.filterId;
        const section = Fields.find(x => x.value === sectionValue);
        !section?.isDefault && setVisibleSection(section);

        switch (sectionValue) {
            case 'date':
                currentFilters.forEach(x => {
                    x.operation === queryOperation.LessOrEqual
                        ? onChange({ endtBD: new Date(x.value) })
                        : onChange({ startBD: new Date(x.value) });
                    setExcludeFromSearch(x.excludeFromSearch);
                });
                break;
            case 'age':
                currentFilters.forEach(x => {
                    x.operation !== queryOperation.LessOrEqual
                        ? onChange({ ageFrom: x.value })
                        : onChange({ ageTo: x.value });
                    setExcludeFromSearch(x.excludeFromSearch);
                });
                break;
            case 'language':
                const [lang] = currentFilters;
                const language = lang.childs.find(x => x.field === 'LanguageId');

                const level = lang.childs.find(x => x.field === 'LanguageLevelId');
                const languageId = catalogs['languages'].find(x => x.id === language?.value);
                const languageLevelId = catalogs['languageLevels'].find(x => x.id === level?.value);
                onChange({ languageId, languageLevelId });
                setExcludeFromSearch(lang.excludeFromSearch);
                break;
            default:
                if (section.type === FIELDS_TYPE.select) {
                    const val = catalogs[section.catalog].find(x =>
                        section.isValueName
                            ? x.name === activeFilter.value
                            : x.id === activeFilter.value,
                    );
                    onChange({ [section.value]: val });
                } else if (section.type === FIELDS_TYPE.multiSelect) {
                    const val = catalogs[section.catalog].filter(x =>
                        section.isValueName
                            ? activeFilter.values.indexOf(x.name) >= 0
                            : activeFilter.values.indexOf(x.id) >= 0,
                    );
                    onChange({ [section.value]: val });
                } else {
                    onChange({ [section.value]: activeFilter.value });
                }
                setExcludeFromSearch(activeFilter.excludeFromSearch);
                break;
        }
    }, [activeFilter, mainFilter, catalogs]);

    const AddFilter = (defaultFilter = false) => {
        let res = null;
        const group = uuid();
        if (defaultFilter?.value === 'age' && (ageFrom || ageTo)) {
            res = [
                checkAge(ageFrom)
                    ? setSearchFilter(
                          'Persons',
                          'Age',
                          queryOperation.GreaterOrEqual,
                          ageFrom,
                          `Возраст больше ${ageFrom}`,
                          groupCondition,
                          filterId,
                          defaultFilter?.value,
                          excludeFromSearch,
                          null,
                          group,
                          FIELDS_TYPE.number,
                          ageFrom,
                          defaultFilter?.isDefault,
                      )
                    : undefined,
                checkAge(ageTo)
                    ? setSearchFilter(
                          'Persons',
                          'Age',
                          queryOperation.LessOrEqual,
                          ageTo,
                          `Возраст меньше ${ageTo}`,
                          groupCondition,
                          filterId,
                          defaultFilter?.value,
                          excludeFromSearch,
                          null,
                          group,
                          FIELDS_TYPE.number,
                          ageTo,
                          defaultFilter?.isDefault,
                      )
                    : undefined,
            ];
        } else if (visibleSection?.value === 'date' && (startBD || endtBD) && !defaultFilter) {
            res = [
                startBD &&
                    setSearchFilter(
                        'Persons',
                        'BirthDate',
                        queryOperation.GreaterOrEqual,
                        moment(startBD).toISOString(),
                        `Дата рождения больше ${moment(startBD).format('DD.MM.YYYY')}`,
                        groupCondition,
                        filterId,
                        visibleSection?.value,
                        excludeFromSearch,
                        null,
                        group,
                        FIELDS_TYPE.date,
                        `${moment(startBD).format('DD.MM.YYYY')}`,
                    ),
                endtBD &&
                    setSearchFilter(
                        'Persons',
                        'BirthDate',
                        queryOperation.LessOrEqual,
                        moment(endtBD).toISOString(),
                        `Дата рождения меньше ${moment(endtBD).format('DD.MM.YYYY')}`,
                        groupCondition,
                        filterId,
                        visibleSection?.value,
                        excludeFromSearch,
                        null,
                        group,
                        FIELDS_TYPE.date,
                        `${moment(endtBD).format('DD.MM.YYYY')}`,
                    ),
            ];
        } else if (defaultFilter?.value === 'language' && (languageId || languageLevelId)) {
            const groupLang = uuid();
            const parentId = uuid();
            res = [
                {
                    id: parentId,
                    joinOperation: groupCondition,
                    filterId: filterId,
                    childs: [
                        languageId
                            ? {
                                  ...setSearchFilter(
                                      'PersonLanguages',
                                      'LanguageId',
                                      queryOperation.Equal,
                                      languageId.id,
                                      `Язык: ${languageId.name}`,
                                      'AND',
                                      filterId,
                                      defaultFilter?.value,
                                      false,
                                      null,
                                      groupLang,
                                      FIELDS_TYPE.number,
                                      languageId.name,
                                      defaultFilter?.isDefault,
                                  ),
                              }
                            : undefined,
                        languageLevelId
                            ? {
                                  ...setSearchFilter(
                                      'PersonLanguages',
                                      'LanguageLevelId',
                                      queryOperation.Equal,
                                      languageLevelId.id,
                                      `Уровень владения: ${languageLevelId.name}`,
                                      'AND',
                                      filterId,
                                      defaultFilter?.value,
                                      false,
                                      null,
                                      groupLang,
                                      FIELDS_TYPE.number,
                                      languageLevelId.name,
                                      defaultFilter?.isDefault,
                                  ),
                              }
                            : undefined,
                    ].filter(x => x),
                    group: groupLang,
                    operation: queryOperation.Exists,
                    parent: true,
                    excludeFromSearch,
                    table: 'PersonLanguages',
                    label: `Язык: ${languageId?.name || ''} ${languageLevelId?.name || ''}`,
                    section: 'language',
                },
            ];
        } else if (
            (visibleSection && state[visibleSection.value]) ||
            (defaultFilter && state[(defaultFilter?.value)])
        ) {
            const { label, column, table, query = queryOperation.Equal, type, isArrayOfValues } = defaultFilter
                ? defaultFilter
                : visibleSection;
            let value = '',
                name = '',
                section = '';

            if (defaultFilter && !!state[(defaultFilter?.value)]) {
                if (type === FIELDS_TYPE.multiSelect) {
                    value = state[(defaultFilter?.value)].map(x => x.id);
                    name = state[(defaultFilter?.value)].map(x => x.name).join(', ');
                } else {
                    value =
                        type !== FIELDS_TYPE.select
                            ? state[(defaultFilter?.value)]
                            : state[(defaultFilter?.value)].id;
                    name =
                        type !== FIELDS_TYPE.select
                            ? state[(defaultFilter?.value)]
                            : state[(defaultFilter?.value)]?.name;
                }
                section = defaultFilter?.value;
            } else if (!defaultFilter && state[visibleSection.value]) {
                if (type === FIELDS_TYPE.multiSelect) {
                    value = state[visibleSection.value].map(x =>
                        visibleSection.isValueName ? x.name : x.id,
                    );
                    name = state[visibleSection.value].map(x => x.name).join(', ');
                } else {
                    value =
                        type !== FIELDS_TYPE.select
                            ? state[visibleSection.value]
                            : visibleSection.isValueName
                            ? state[visibleSection.value].name
                            : state[visibleSection.value].id;
                    name =
                        type !== FIELDS_TYPE.select
                            ? state[visibleSection.value]
                            : state[visibleSection.value].name;
                }
                section = visibleSection?.value;
            }

            res = value &&
                name && [
                    setSearchFilter(
                        table,
                        column,
                        query,
                        value,
                        `${label}: ${name}`,
                        groupCondition,
                        filterId,
                        section,
                        excludeFromSearch,
                        null,
                        group,
                        type,
                        null,
                        defaultFilter?.isDefault,
                        isArrayOfValues,
                    ),
                ];
        }
        return setFiltersState(res, filterId);
    };

    const handleGroupConditionChange = e => {
        const condition = e.currentTarget.checked ? 'OR' : 'AND';
        updateFiltersWithCondition(filterId, condition);
        setGroupCondition(condition);
    };
    const checkAge = value => value && value >= MIN_AGE && value <= MAX_AGE;

    const renderDefaultFilters = (activeFilter = null) => {
        const finalFilters = activeFilter
            ? defaultFilters.filter(x => x.value === activeFilter.section)
            : defaultFilters;
        return finalFilters.map(x => (
            <React.Fragment key={x.label}>
                {x.withHeader && (
                    <div className="LKSearchCardVisibleFIlterLine__GroupHeader">{x.label}</div>
                )}
                {renderFilters(x)}
            </React.Fragment>
        ));
    };

    const renderFilters = (defaultFilter = null) => {
        if (!visibleSection && !defaultFilter) {
            return null;
        }

        const prefix = (ageFrom = true) => ({
            alignItems: 'center',
            display: 'flex',

            ':before': {
                content: ageFrom ? '"от"' : '"до"',
                display: 'block',
                marginRight: 8,
            },
        });

        const isSelected = (selected = true) => ({color: !selected ? 'lightgray' : 'hsl(0,0%,20%)'});

        const styles = {
            singleValue: (styles, { data }) => ({ ...styles, ...prefix(data.ageFrom), ...isSelected(data.selected)}),
        };
        const { value, type, catalog, label } = defaultFilter || visibleSection;
        switch (value) {
            case 'age':
                return (
                    <>
                        <Field
                            className="LKFilterField"
                            error="от 14 до 100"
                            size={50}
                            invalid={ageFrom && !checkAge(+ageFrom)}
                        >
                            <Select
                                styles={styles}
                                options={ageOptions}
                                onChange={e => onChange({ ageFrom: e?.value })}
                                maxMenuHeight={380}
                                isSearchable={window.innerWidth > 768}
                                isClearable={ageFrom}
                                value={
                                    ageFrom
                                        ? {
                                              value: ageFrom,
                                              label: ageFrom,
                                          }
                                        : {...ageOptions[0], selected: false}
                                }
                            />
                        </Field>
                        <Field
                            className="LKFilterField"
                            error="от 14 до 100"
                            size={50}
                            invalid={ageTo && !checkAge(+ageTo)}
                        >
                            <Select
                                styles={styles}
                                options={ageOptions}
                                onChange={e => onChange({ ageTo: e?.value })}
                                maxMenuHeight={380}
                                isSearchable={window.innerWidth > 768}
                                isClearable={ageTo}
                                value={
                                    ageTo
                                        ? {
                                              value: ageTo,
                                              label: ageTo,
                                              ageFrom: false,
                                          }
                                        : {...ageOptions[ageOptions.length -1], ageFrom: false, selected: false}
                                }
                            />
                        </Field>
                    </>
                );
            case 'date':
                return (
                    <>
                        <Field size={50} className="LKFilterField">
                            <DatePicker
                                placeholderText="от"
                                selected={startBD}
                                onChange={date => onChange({ startBD: date })}
                                locale="ru"
                                dateFormat="dd.MM.yyyy"
                                opperPlacement="top-end"
                                popperModifiers={{
                                    flip: {
                                        enabled: false,
                                    },
                                    preventOverflow: {
                                        enabled: false,
                                        escapeWithReference: false,
                                    },
                                }}
                            />
                        </Field>
                        <Field size={50} className="LKFilterField">
                            <DatePicker
                                placeholderText="до"
                                selected={endtBD}
                                onChange={date => onChange({ endtBD: date })}
                                locale="ru"
                                dateFormat="dd.MM.yyyy"
                                popperPlacement="bottom-end"
                                popperModifiers={{
                                    flip: {
                                        enabled: false,
                                    },
                                    preventOverflow: {
                                        enabled: false,
                                        escapeWithReference: false,
                                    },
                                }}
                            />
                        </Field>
                    </>
                );

            case 'language':
                return (
                    <>
                        <FilterField
                            onChange={val => onChange({ languageId: val })}
                            type={FIELDS_TYPE.select}
                            value={languageId || null}
                            items={catalogs['languages']}
                            placeholder={'Язык'}
                        />
                        <FilterField
                            onChange={val => onChange({ languageLevelId: val })}
                            type={FIELDS_TYPE.select}
                            value={languageLevelId || null}
                            items={catalogs['languageLevels']}
                            placeholder={'Уровень владения языком'}
                        />
                    </>
                );
            default:
                return (
                    <FilterField
                        onChange={onChangeField(value)}
                        type={type}
                        value={state[value] || null}
                        items={catalogs[catalog]}
                        placeholder={defaultFilter ? label : ''}
                        closeMenuOnSelect={type !== FIELDS_TYPE.multiSelect}
                    />
                );
        }
    };

    const isDefaultFilter = activeFilter => {
        return defaultFilters.find(x => x.isDefault && x.value === activeFilter.section);
    };

    const isDefaultFilterActive = () => {
        return defaultFilters.filter(x => state[x.value]).length !== 0;
    };

    const buttonEnabled =
        ((ageFrom || ageTo) && (!ageFrom || checkAge(ageFrom)) && (!ageTo || checkAge(ageTo))) ||
        (startBD || endtBD) ||
        (languageId || languageLevelId) ||
        (visibleSection && state[visibleSection.value]) ||
        isDefaultFilterActive();

    return (
        <>
            <div className="LKSearchCardVisibleFIlterLine">
                <SearchGroupCondition
                    checked={groupCondition === 'OR'}
                    onClick={e => handleGroupConditionChange(e)}
                />
            </div>

            <ActiveFilters filterId={filterId} />

            {(!activeFilter || (activeFilter && isDefaultFilter(activeFilter))) && (
                <div className={classnames('LKSearchCardVisibleFIlterLine')}>
                    {renderDefaultFilters(activeFilter)}
                </div>
            )}

            {(!activeFilter || (activeFilter && !isDefaultFilter(activeFilter))) && (
                <>
                    <div className="LKSearchCardVisibleFIlterLine">
                        <Field>
                            <Select
                                styles={selectStyles}
                                placeholder="Выберите дополнительный критерий"
                                options={Fields.filter(
                                    x =>
                                        (!x.isExtra || x.isExtra === props.extraFields) &&
                                        !x.isDefault,
                                )}
                                onChange={handleCriteriaChange}
                                value={visibleSection}
                                isClearable
                            />
                        </Field>
                    </div>
                    <div className={classnames('LKSearchCardVisibleFIlterLine')}>
                        {renderFilters()}
                    </div>
                </>
            )}

            <div
                className={classnames('LKSearchCardVisibleFIlterLine', {
                    'LKSearchCardVisibleFIlterLine--hidden': !buttonEnabled,
                })}
            >
                <Field>
                    <StyledCheckBox
                        onChange={setExcludeFromSearch}
                        checked={excludeFromSearch}
                        title="Исключить из поиска"
                        style={{display: 'inline-block'}}
                    />
                </Field>
            </div>

            <SuccessButton
                onSubmit={onSubmit}
                buttonEnabled={buttonEnabled}
                activeFilter={activeFilter}
                visibleSection={visibleSection?.value}
                defaultFilters={defaultFilters}
                isFocused={isFocused}
            />
        </>
    );
};

const mapStateToProps = state => {
    return {
        mainFilter: state.filterSearch.mainFilter,
        activeFilter: state.filterSearch.activeFilter,
        extraFields: state.filterSearch.extraFields,
        catalogs: {
            identityDocuments: state.catalogs.identityDocuments.data,
            sexCatalog: state.catalogs.sexCatalog.data,
            familyStatuses: state.catalogs.familyStatuses.data,
            childrenStatus: state.catalogs.childrenStatus.data,
            languages: state.catalogs.languages.data,
            languageLevels: state.catalogs.languageLevels.data,
        },
    };
};

export default connect(mapStateToProps)(PersonFilters);
