import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import uuid from 'uuid/v4';
import ReactTooltip from 'react-tooltip';

import { putTeam as putTeamApi } from 'api';
import PersonCard from 'components/common/PersonCard';
import BlockButton from '../Edit/BlockButton';
import Team from './Team';
import './PersonTeams.scss';
import { setTeams, fetchTeams } from 'ducks/Teams';
import { showErrorAlert } from 'ducks/Alert';

const PersonTeams = props => {
    const {
        person,
        hidePerson,
        title = 'Мои команды',
        followingTitle = 'Я в командах',
        loadedPerson,
        teams,
        setTeams,
        fetchTeams,
        user,
        showErrorAlert,
    } = props;

    const canEditTeam = person?.personnelInfo?.curator?.id === user?.id

    useEffect(() => {
        if (loadedPerson !== person.personalInfo.id) {
            fetchTeams(person.personalInfo.id);
        }
    }, [fetchTeams, teams, loadedPerson, person.personalInfo.id]);

    const addTeam = useCallback(async () => {
        const newTeam = {
            id: uuid(),
            name: 'Новая команда',
            leaderPersonId: person.personalInfo.id,
            followerPersonIds: [],
        };

        try {
            const response = await putTeamApi(newTeam);
            setTeams({
                ...teams,
                leading: [...teams.leading, response.data],
            });
        } catch (error) {
            const errorMessage =
                error.response?.data?.payload || 'Произошла ошибка при сохранении команды';
            showErrorAlert(errorMessage);
        }
    }, [person.personalInfo.id, teams, setTeams, showErrorAlert]);

    const updateTeam = useCallback(
        changedTeam => {
            setTeams({
                ...teams,
                leading: teams.leading.map(team =>
                    team.id === changedTeam.id ? changedTeam : team,
                ),
                following: teams.following.map(team =>
                    team.id === changedTeam.id ? changedTeam : team,
                ),
            });
        },
        [teams, setTeams],
    );

    const removeTeam = useCallback(
        teamId => {
            setTeams({
                ...teams,
                leading: teams.leading.filter(team => team.id !== teamId),
                following: teams.following.filter(team => team.id !== teamId),
            });
        },
        [teams, setTeams],
    );

    const renderTeam = team => {
        const isCurrentUserIsTeamLeader = () => {
            return team.leaderPerson.id === person.personalInfo.id;
        };

        return (
            <div key={team.id} className="TeamsList__Line">
                <Team
                    team={team}
                    onTeamChanged={updateTeam}
                    onTeamDeleted={removeTeam}
                    isPersonLeader={isCurrentUserIsTeamLeader()}
                    isLeaderPage={props.isLeaderPage}
                    canEditTeam={canEditTeam}
                />
            </div>
        );
    };

    const personTeamHint = () => {
        return (
            <span className="personHint__Title">
                <div className="PersonTeams__Tooltip" data-tip data-for="personTeamHint">
                    ?
                </div>
                <ReactTooltip id="personTeamHint" effect={'solid'} border={true} type={'light'}>
                    <p className="tooltip">{props.hintText}</p>
                </ReactTooltip>
            </span>
        );
    };

    const renderTeams = () => {
        return (
            <div>
                <div className="PersonTeams">
                    <div className="PersonTeamsHeader">
                        <div className="PersonTeamsHeader__Text">
                            {title}
                            {!!props.showHint ? personTeamHint() : null}
                        </div>
                    </div>
                    <div className="PersonTeamsBody">
                        <div className="TeamsList">
                            {teams && !teams.leading.length <= 0 ? (
                                teams.leading.map(renderTeam)
                            ) : (
                                <div className="TeamsList__Line">
                                    <div className="TeamsList__Text">У вас нет команды</div>
                                </div>
                            )}
                        </div>
                        {canEditTeam && (
                            <div className="PersonTeamsBody__AddButtonContainer">
                                <div className="PersonTeamsBody__AddButton">
                                    <BlockButton type="add" onClick={addTeam}>
                                        Добавить команду
                                    </BlockButton>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="PersonTeams">
                    <div className="PersonTeamsHeader">
                        <div className="PersonTeamsHeader__Text">{followingTitle}</div>
                    </div>
                    <div className="PersonTeamsBody">
                        <div className="TeamsList">
                            {teams && !teams.following.length <= 0 ? (
                                teams.following.map(renderTeam)
                            ) : (
                                <div className="TeamsList__Line">
                                    <div className="TeamsList__Text">
                                        Вы не состоите ни в одной команде
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            {!!!hidePerson ? <PersonCard {...person} /> : null}
            {renderTeams()}
        </div>
    );
};

const mapStateToProps = ({ teams: { following, leading, personId } }) => ({
    loadedPerson: personId,
    teams: { following, leading },
});

const actions = { setTeams, fetchTeams, showErrorAlert };

export default connect(mapStateToProps, actions)(PersonTeams);
