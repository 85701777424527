import React from 'react';
import { connect } from 'react-redux';
import './UserProfile.scss';
import Page from 'components/common/Page/Page';
import PhotoBlock from 'components/common/PhotoBlock/PhotoBlock';
import { savePhoto } from 'ducks/User';
import { fetchUser } from 'ducks/Auth';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import Label from 'components/uikit/Label';
import Field from 'components/uikit/Field';
import Button from "components/uikit/Button";
import Row from 'components/uikit/Row';
import RoundButton from 'components/uikit/Button/RoundButton';
import { DataCard } from 'components/common/DataCard';
import { getUserPhotoUri, photoSizes, getJobsByPersonId, updateJobs } from 'api';
import Form from 'components/common/Form';
import Input from 'components/uikit/Input';
import { selectDictionaries, fetchDictionaries, isDictionariesLoaded } from 'ducks/Dictionary';
import { GROUPS_DICTIONARY_NAME } from 'constants.js';
import { DictionarySelect } from "components/uikit/Select";

class UserProfile extends React.Component {
    _isMounted = true;
    
    state = { 
        editPhoto: false,
        jobs: [],
        actualJob: { company: '', position: '' },
        copyActualJob: {},
        isEdit: false,
        loading: false
    };
    
    handleTogglePhotoBlockMode = () => this.setState({ editPhoto: !this.state.editPhoto });

    handleChangeCurrentWork = (value) => this.setState({ actualJob: { ...this.state.actualJob, company: value } });

    handleChangeCurrentPosition = (value) => this.setState({ actualJob: { ...this.state.actualJob, position: value } });
    
    onToggleEditMode = () => {
        this.setState({ isEdit: !this.state.isEdit, copyActualJob: this.state.actualJob });
    };
    
    onCancel = () => {
        this.setState({ isEdit: false, actualJob: this.state.copyActualJob });
    };
    
    handlePhotoOpened = photoFile => {
        if (!photoFile) {
            return;
        }

        this.props.savePhoto(photoFile);
    };

    fetchPersonJobs = async () => {
        const user = this.props.user;
        if (user.personId) {
            const result = await getJobsByPersonId(user.personId);
            
            if (result && result.status === 200 && result.data && result.data.length > 0) {
                const jobs = [...result.data];
                this._isMounted && this.setState({jobs: jobs, actualJob: jobs.find(j => j.isActual) })
            }
            else {
                this.props.showErrorAlert("При получении текущей работы пользователя произошла ошибка");
            }
        }
    };
    
    onSubmit = async () => {
        this.setState({ loading: true });
        
        const { jobs, actualJob } = this.state;
        const user = this.props.user;
        
        jobs.splice(jobs.findIndex(x => x.id === actualJob.id), 1);
        jobs.push(actualJob);
        
        const result = await updateJobs(user.personId, jobs);
        if (result && result.status === 200) {
            this.props.showSuccessAlert("Сохранение прошло успешно");
        }
        else {
            this.props.showErrorAlert("При обновлении профиля произошла ошибка");
        }
        
        this.setState({ loading: false, isEdit: false, copyActualJob: {} });
    };
    
    renderJobBlock = () => {
        const { actualJob, isEdit } = this.state;
        const user = this.props.user;

        return ( user.personId && 
            <Row>
                <Field size="50" disabled={!isEdit}>
                    <Label htmlFor="currentWork">Место работы</Label>
                    {!isEdit && <RoundButton type="edit" position="absolute" onClick={() => this.onToggleEditMode()} style={{top: 0, right: 5}}/>}
                    <Input
                        value={actualJob.company}
                        onChange={e => this.handleChangeCurrentWork(e.target.value)}
                    />
                </Field>
                <Field size="50" disabled={!isEdit}>
                    <Label htmlFor="currentPosition">Должность</Label>
                    {!isEdit &&
                    <span className="UserProfile--icon">
                        <RoundButton type="edit" position="absolute" onClick={() => this.onToggleEditMode()} style={{top: 0, right: 5}}/> 
                    </span>}
                    <Input
                        value={actualJob.position}
                        onChange={e => this.handleChangeCurrentPosition(e.target.value)}
                    />
                </Field>
            </Row>
        );
    };


    renderPhotoBlock = () => {
        const user = this.props.user;

        const refetchKey = this.props.refetchKey;
        const userId = user.id;
        const photoUri = getUserPhotoUri(photoSizes.small, userId, refetchKey);
        const photoLink = getUserPhotoUri(photoSizes.fullSize, userId, refetchKey);

        return (
            <PhotoBlock
                id="photo-block"
                className="UserPhoto"
                photoUri={photoUri}
                photoLink={photoLink}
                loading={this.state.updatingPhoto}
                onPhotoOpened={this.handlePhotoOpened}
                onToggleMode={this.handleTogglePhotoBlockMode}
                readOnly={!this.state.editPhoto}
                canEdit={true}
            />
        );
    };


    componentDidMount = async () => {
        this._isMounted = true;
        const { groups, isGroupsLoading, fetchUser } = this.props;
        if (!groups && !isGroupsLoading) {
            this.props.fetchDictionaries([GROUPS_DICTIONARY_NAME]);
        }
        fetchUser(true);
        await this.fetchPersonJobs();
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    customSelectStyles = {
        control: base=> ({
           ...base,
            borderColor: '#f1f1f1',
        }),
        multiValueLabel: base => ({
            ...base,
            paddingRight: '6px',
            color: 'rgba(60, 60, 67, 0.6)'
        }),
        multiValueRemove: base => ({
            ...base,
            display: "none"
        }),
        indicatorsContainer: base => ({
            ...base,
            display: "none"
        })
    };

    render() {
        const { user, groups } = this.props;
        
        if (!groups) { 
            return null;
        }
        
        const { isEdit, loading } = this.state;
        const { lastName, phone, email, middleName, firstName, roles } = user;
        
        return (
            <div>
                <Page w1790 className="UserProfile">
                    <div className="Page__MenuColumn">{this.renderPhotoBlock()}</div>
                    <Form className="Page__ContentColumn">
                        <div>
                            <DataCard hasOpen={false} shadow title="Профиль" className="kr_profile-anketa">
                                <div className="EditPerson__Form">
                                    <Row>
                                        <Field size="33" disabled>
                                            <Label htmlFor="lastName">Фамилия</Label>
                                            <Input
                                                value={lastName}
                                                disabled
                                            />
                                        </Field>
                                        <Field size="33" disabled>
                                            <Label htmlFor="firstName">Имя</Label>
                                            <Input
                                                value={firstName}
                                                disabled
                                            />
                                        </Field >
                                        <Field size="33" disabled>
                                            <Label htmlFor="middleName">Отчество</Label>
                                            <Input
                                                value={middleName}
                                                disabled
                                            />
                                        </Field>
                                    </Row>

                                    <Row>
                                        <Field size="50" disabled>
                                            <Label htmlFor="email">E-mail</Label>
                                            <Input
                                                value={email || ''}
                                                disabled
                                            />
                                        </Field>
                                        <Field size="50" disabled>
                                            <Label htmlFor="phone">Телефон</Label>
                                            <Input
                                                value={phone || ''}
                                                disabled
                                            />
                                        </Field>
                                    </Row>

                                    <Row>
                                        <Field size="100" disabled>
                                            <Label>Роли</Label>
                                            <DictionarySelect
                                                options={roles ? roles.map(x => ({id: x.id, name: x.title })) : []}
                                                value={roles.map(x => x.id)}
                                                isMulti
                                                styles={this.customSelectStyles}
                                                isDisabled={true}
                                            />
                                        </Field>
                                    </Row>
                                    
                                    <Row>
                                        <Field size="100" disabled>
                                            <Label>Группа</Label>
                                            <DictionarySelect
                                                options={groups ? groups : []}
                                                value={user.groupId}
                                                styles={this.customSelectStyles}
                                                isDisabled={true}
                                            />
                                        </Field>
                                    </Row>
                                    
                                    {this.renderJobBlock()}
                                    {isEdit && (
                                        <div className="UserProfile__ButtonContainer">
                                            <Button 
                                                type="button" 
                                                disabled={loading} 
                                                onClick={this.onCancel} 
                                                color="default">
                                                Отмена
                                            </Button>
                                            <Button
                                                type="submit"
                                                onClick={this.onSubmit}
                                                loading={loading}
                                            >
                                                Сохранить
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </DataCard>
                        </div>
                    </Form>
                </Page>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        refetchKey: state.user.refetchKey,
        groups: selectDictionaries(state.dictionary, GROUPS_DICTIONARY_NAME),
        isGroupsLoading: state.dictionary[GROUPS_DICTIONARY_NAME].loading,
    };
};

const actions = {
    showErrorAlert,
    showSuccessAlert,
    savePhoto,
    fetchDictionaries,
    isDictionariesLoaded,
    fetchUser
};

export default connect(
    mapStateToProps,
    actions,
)(UserProfile);
