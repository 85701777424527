import React from 'react';
import classnames from 'classnames';
import './ButtonsGroup.scss';

const ButtonsGroup = props => {
    const { buttons = [], position = 'start'} = props;
    return (
        <div className={classnames(
            'ButtonsGroup',
            {
                'ButtonsGroup--left': position === 'start',
                'ButtonsGroup--center': position === 'center',
                'ButtonsGroup--right': position === 'end',
            }
        )}>
            {buttons.map((x, index) => (
                <div key={index} className='ButtonsGroup__Button'>
                    {x}
                </div>
            ))}
        </div>
    );
};

export default ButtonsGroup;