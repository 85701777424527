import React from 'react';
import { connect } from 'react-redux';
import { showWarningAlert, showErrorAlert } from 'ducks/Alert';
import ToggleOpen from 'components/hoc/ToggleOpen';
import ValidBlock from 'components/hoc/ValidBlock'
import Select from 'components/uikit/Select';
import Field from 'components/uikit/Field';
import Row from 'components/uikit/Row';
import Label from 'components/uikit/Label';
import Input from 'components/uikit/Input';
import Form from "components/common/Form";
import { DataCard } from "components/common/DataCard";
import BlockButton from "./BlockButton";
import ItemButton from "./ItemButton";
import uuid from "uuid/v4";
import AttributeFileUploadButton from "./AttributeFileUploadButton";
import "./AttributesBlock.scss";
import DatePicker from 'components/uikit/DatePicker';
import moment from 'moment';

const AttributesBlock = props => {
    const {
        handleStateChange,
        isInputFilled,
    } = props;

    function handleChange(key, item) {
        const index = (props.attributes.findIndex(x => x.attribute.local_id === key))
        const attributes = props.attributes.set(index, { ...props.attributes.get(index), ...item });
        handleStateChange({ attributes });
    }

    function handleAttributeChange(local_id, item) {
        const newItem = { ...item, attributeValue: null, attribute: { ...item.attribute, local_id: local_id } }
        handleChange(local_id, newItem);
    }

    function validator(key, prop, errorMessage, attributeId = 0) {
        return () => {
            const index = (props.attributes.findIndex(x => x.attribute.local_id === key))
            const attribute = props.attributes.get(index);
            const isValid = attribute[prop] && typeof (attribute[prop]) === 'object'
                ? attribute[prop].length !== 0
                : attribute[prop] && attribute[prop] !== '';
            return attributeId && isValid ? null : errorMessage;
        }
    }

    const attributeDateValidator = (key, prop, errorMessage) => {
        return () => {
            const index = (props.attributes.findIndex(x => x.attribute.local_id === key))
            const attribute = props.attributes.get(index);
            const dateFrom = moment().set({ 'year': 1000, 'month': 1, 'day': 1 });
            const dateTo = moment().set({ 'year': 9999, 'month': 1, 'day': 1 });
            return !(moment(attribute[prop]).isSameOrAfter(dateFrom) && moment(attribute[prop]).isSameOrBefore(dateTo)) ? errorMessage : null;
        }
    }

    function addAttribute() {
        const attributes = props.attributes.concat({ attribute: { local_id: uuid() } });
        handleStateChange({ attributes });
    }

    function removeAttribute(local_id) {
        const attributes = props.attributes.filter(x => x.attribute.local_id !== local_id);
        handleStateChange({ attributes });
    }

    const getId = (name, key) => {
        return `${name}${key}`;
    };

    const getAttributeInputType = (inputType = '') => {
        switch (inputType.toLowerCase()) {
            case 'number':
            case 'long':
            case 'decimal':
                return 'number'
            case 'date':
                return 'date'
            case 'select':
                return 'select'
            case 'multiselect':
                return 'multiselect'
            case 'file':
                return 'file'
            default:
                return 'text'
        }
    }

    const getAttributeValueByType = (item, attributeType = 'text') => {
        switch (getAttributeInputType(attributeType)) {
            case 'multiselect':
                return item.attribute.payload.length
            case 'file':
                return item.attachmentId
            default:
                return item.attributeValue
        }
    }

    const renderSelect = (item, attributeErrorKey, attributeType) => {
        const { attribute } = item;
        const key = attribute.local_id;
        return (
            <Field
                required
                filled={isInputFilled(getAttributeValueByType(item, attributeType))}
                invalid={props.isInputInvalid('attributeValue', [validator(key, 'attributeValue', 'Выберите элемент списка', attribute.id)], attributeErrorKey)}
                size="50"
                className="AttributesInputWrapper"
            >
                <Label>Значение</Label>
                <Select
                    className="AttributesInput"
                    id={getId('attributeValue', key)}
                    value={item.attributeValue}
                    options={attribute.payload.map(x => ({ id: x.id, name: x.value }))}
                    catalog
                    isMulti={attributeType === 'multiselect'}
                    onChange={item => handleChange(key, { attributeValue: item })}
                />
                <ItemButton
                    className="AttributesBlockLineControls__RemoveButton"
                    type="remove"
                    onClick={() => removeAttribute(key)}
                />
            </Field>
        )
    }

    const renderInput = (item, attributeErrorKey, attributeType) => {
        const { attribute } = item;
        const key = attribute.local_id;
        return (
            <Field
                required
                filled={isInputFilled(item.attributeValue)}
                invalid={props.isInputInvalid('attributeValue', [validator(key, 'attributeValue', 'Введите значение', attribute.id)], attributeErrorKey)}
                size="50"
                className="AttributesInputWrapper"
            >
                <Label>Значение</Label>
                <Input
                    className="AttributesInput"
                    id={getId('attributeValue', key)}
                    type={attributeType}
                    value={item.attributeValue || ''}
                    maxLength={250}
                    onChange={e => handleChange(key, { attributeValue: e.target.value })}
                />
                <ItemButton
                    className="AttributesBlockLineControls__RemoveButton"
                    type="remove"
                    onClick={() => removeAttribute(key)}
                />
            </Field>
        )
    }

    const renderDate = (item, attributeErrorKey, attributeType) => {
        const { attribute } = item;
        item.attributeValue = new Date(item.attributeValue);
        const key = attribute.local_id;
        return (
            <Field
                required
                filled={isInputFilled(item.attributeValue)}
                invalid={props.isInputInvalid('attributeValue', [attributeDateValidator(key, 'attributeValue', 'Введите корректную дату')])}
                size="50"
                className="AttributesInputWrapper"
            >
                <Label>Значение</Label>
                <div className="AttributesDateInput">
                    <DatePicker
                        id={getId('attributeValue', key)}
                        type={attributeType}
                        selected={item.attributeValue}
                        onChange={value => handleChange(key, { attributeValue: value })}
                    />
                </div>
                <ItemButton
                    className="AttributesBlockLineControls__RemoveButton"
                    type="remove"
                    onClick={() => removeAttribute(key)}
                />
            </Field>
        )
    }

    const renderFile = (item) => {
        const { attribute } = item;
        const key = attribute.local_id;
        const personId = props.personId;
        return (
            <Field className="kr_not_labeled AttributesBlockLineControlsRow">
                <div className="AttributesBlockLineControls AttributesBlockLineControls__FileUpload">
                    <AttributeFileUploadButton
                        className="AttributesUploadButton"
                        personId={personId}
                        fileId={item.attachmentId}
                        attributeId={item.id}
                        onFileIdChange={item => handleChange(key, { attachmentId: item.id, attributeValue: item.id })}
                    />
                    <ItemButton
                        className="AttributesBlockLineControls__RemoveButton"
                        type="remove"
                        onClick={() => removeAttribute(key)}
                    />
                </div>
                <small>* Размер файла не должен превышать 100 Мб</small>
            </Field>
        );
    }

    const renderAttribute = (item, attributeErrorKey, attributeType) => {
        switch (attributeType) {
            case 'select':
            case 'multiselect':
                return renderSelect(item, attributeErrorKey, attributeType);
            case 'file':
                return renderFile(item);
            case 'date':
                return renderDate(item, attributeErrorKey, attributeType);
            default:
                return renderInput(item, attributeErrorKey, attributeType);
        }
    }

    const renderAttributes = (item) => {
        const { attribute } = item;
        const attributeErrorKey = `attribute_${attribute.local_id}`;
        const attributeType = getAttributeInputType(attribute.type);
        return (
            <div className='AttributesWrapper' key={attribute.local_id}>
                <Field
                    required
                    filled={isInputFilled(attribute.type)}
                    invalid={props.isInputInvalid('attributeValue', [validator(attribute.local_id, 'attributeValue', 'Выберите дополнительное поле', item.attribute.id)], attributeErrorKey)}

                    className="AttributesInputWrapper"
                >
                    <Label>Дополнительное поле</Label>
                    <Select
                        className="AttributesSelect"
                        id={getId('attribute', attribute.local_id)}
                        value={attribute}
                        options={props.catalogs.attributes.data}
                        catalog
                        onChange={attribute => handleAttributeChange(item.attribute.local_id, { attribute: attribute })}
                    />
                </Field>
                {renderAttribute(item, attributeErrorKey, attributeType)}
            </div>
        )
    }

    return (
        <DataCard
            shadow
            title='Дополнительные поля'
            className="kr_profile-anketa"
            isOpen={props.hasValidationErrors}
        >
            <Form autoComplete={false} className="AttributesForm">
                <Row>
                    {props.attributes.map(renderAttributes)}
                    <BlockButton className="AttributesAddButton" type="add" onClick={addAttribute}>Добавить</BlockButton>
                </Row>
                <Row>
                    {props.isEditBlock ? props.renderEditBlock() : null}
                </Row>
            </Form>
        </DataCard>
    );

}

export default connect(null, { showWarningAlert, showErrorAlert })(ToggleOpen(ValidBlock(AttributesBlock)));