import React, { Component } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import classnames from 'classnames';
import { 
    ACTIVE_DETAIL_CARD, 
    EVALUATION_TYPE,
    COMPETENCIES_TEST_ID, 
    MANAGEMENT_POTENTIAL_TESTS_ID,
    BEHAVIOR_EVAL_TYPE_ID,
    CAREER_DESTRUCTORS_EVAL_TYPE_ID,
} from 'components/Lk/Basic/constants';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';
import { evaluateResultCardMenuItems } from 'components/Lk/Basic/MainCard/constants';
import { getWishlistWithEvaluationResults, fullTextSearchWithEvaluations, getCompetencies } from 'api';
import { showErrorAlert } from 'ducks/Alert';
import { fetchEvaluation, setSelectes, setEvaluationTestsCriterion } from 'ducks/Evaluation';
import Slider from 'components/Lk/Uikit/Slider/Slider';
import Loader from 'components/common/Loader';
import RenderPerson from './RenderPerson';
import ModalSummaryCard from 'components/Lk/Common/ModalPersonSummaryCard';
import { lkDetailsEvaluateTeamProfile } from 'routes';
import Input from 'components/Lk/Uikit/Input';
import Button from 'components/Lk/Uikit/Buttons/Button';
import EvaluationCardItemBlock from './EvaluationCardItemBlock';
import MenuFilter from 'components/Lk/Uikit/Filter/MenuFilter';
import {
    ALL_EVALUATIONS_MENU_ID,
    HAS_EVALUATIONS_MENU_ID,
    HAS_NO_EVALUATIONS_MENU_ID,
    hasEvaluationsMenuOptions,
} from './constants';

import './EvaluationFavorite.scss';
import './EvaluationSearch.scss';
import NoDataBlock from 'components/Lk/Common/NoDataBlock';
import EvaluteScaleInfo from './EvaluteScaleInfo';
import EvaluationType from "./EvaluationType";

class EvaluationResult extends Component {

    state = {
        resultIds: [],
        evaluation: [],
        testedPersons: [],
        resultCriteria: [],
        criteriaSelectedId: {},
        person: null,
        hasMore: true,
        paging: { pageNum: 1, pageSize: 10 },
        pageCount: 0,
        searchLoading: false,
        searchIsLoaded: false,
        favoritesLoading: false,
        favoritesIsLoaded: false,
        isOpenModalSummary: false,
        searchTerm: '',
        resultSearchTerm: '',
        selectedFilter: ALL_EVALUATIONS_MENU_ID,
        competencyModels: [],
    };

    slideChange = (e, array) => {
        const param = {
            id: array[e],
            key: e,
        };
        this.props.setSelectes(param);
    };

    renderEvaluationType = (obg, filter) => {
        const resultFiltered = obg.filter(item =>  filter ? item.forEvaluation : true );
        const resultWithTooltipDescriptions = resultFiltered.map(x =>
            ({...x, description : !!x.extraDescription
                    ? <span>{x.description}<br/><br/>{x.extraDescription}</span>
                    : x.description}));
        return resultWithTooltipDescriptions.map((item, key) => (
                <EvaluationCardItemBlock grey key={key}>
                    <EvaluationType {...item} icon="evaluateType" tooltip/>
                </EvaluationCardItemBlock>
            )
        )
    };

    slideChangeCriteria = (e) => {
        const actualCriteriaIds = this.state.resultCriteria.map(x => x.id);
        const param = {
            id: actualCriteriaIds[e],
            key: e,
        };
        this.setState({ criteriaSelectedId: param });
    };

    onSelectPerson = (id, item) => () => {
        this.setState({ person: item });
        this.handleSummaryCardOpen();
    };

    closeSummaryCard = () => this.setState({ isOpenModalSummary: false });
    handleSummaryCardOpen = () => this.setState({ isOpenModalSummary: true });

    getCriteria = evaluationsList => {
        let res = {};

        if (!!!this.props.selectedTest.id || this.props.selectedTest.id === COMPETENCIES_TEST_ID) {

            if (this.state.competencyModels.length > 0 && this.props.evaluation.list.length > 0) {
                const competencyTests = this.props.evaluation.list
                    .filter(x => x.kind === 'Competency')
                    .map(x => ({ testId: x.id, title: x.title, competencyModelId: x.competencyModelId }));

                const competencyModelIds = competencyTests
                    .map(i => i.competencyModelId)
                    .filter((item, index, self) => index === self.findIndex(x => x === item));

                const competencyTestsCriteria = this.props.testedPersons
                    .filter(x => competencyTests.map(x => x.testId).includes(x.testId))
                    .map((x, index) => {

                        const competency = this.state.competencyModels
                            .map(i => i.competencies)
                            .flat()
                            .filter(i => competencyModelIds.includes(i.competencyModelId))
                            .find(i => i.personGrowthTagId === x.personGrowthTagId);

                        return {
                            id: x.personGrowthTagId,
                            title: (competency && competency.name) || x.personGrowthTagName,
                            key: index,
                            description: competency && competency.description,
                        }
                    })
                    .filter(x => !!x.id)
                    .filter((item, index, self) => index === self.findIndex(x => x.id === item.id));

                res.resultCriteria = competencyTestsCriteria;

                if (competencyTestsCriteria)
                res.criteriaSelectedId = { id: competencyTestsCriteria.length > 0 && competencyTestsCriteria[0].id, key: 0 };
            }
        
        } else if (this.props.selectedTest.id === MANAGEMENT_POTENTIAL_TESTS_ID) {
            if (this.props.evaluation.isLoaded) {
                const potentialTestIds = this.props.evaluation.list
                    .filter(x => x.kind === 'Potential')
                    .map(x => x.id);

                const potentialTestsCriteria = this.state.testedPersons
                    .map(x => x.evaluationTestResults)
                    .flat()
                    .filter(x => potentialTestIds.includes(x.testId))
                    .map((x, index) => ({
                        id: x.personGrowthTagId,
                        title: x.personGrowthTagName,
                        key: index,
                        description: x.description,
                    }))
                    .filter(x => !!x.id)
                    .filter((item, index, self) => index === self.findIndex(x => x.id === item.id));

                res.resultCriteria = potentialTestsCriteria;

                if (!!potentialTestsCriteria[0] && !!potentialTestsCriteria[0].id) {
                    res.criteriaSelectedId = { id: potentialTestsCriteria[0].id, key: 0 };
                } else {
                    res.criteriaSelectedId = {};
                }
            }
        } else {
            const currentEvaluation = evaluationsList.find(
                x => x.id === this.props.selectedTest.id,
            );
            if (currentEvaluation) {
                const result = currentEvaluation.criteriaOrder.map(
                    item => currentEvaluation.criteria[item],
                );
                const param = {
                    id: result[0].id,
                    key: 0,
                };

                res.resultCriteria = result;
                res.criteriaSelectedId = param;
            } else {
                const param = {
                    id: null,
                    key: 0,
                };
                res.resultCriteria = [];
                res.criteriaSelectedId = param;
            }
        }

        return res;
    };

    makeFilterCriteria = (testId, criteriaId) => {
        switch (testId) {
            case COMPETENCIES_TEST_ID:
            case MANAGEMENT_POTENTIAL_TESTS_ID:
                const personGrowthTagId = criteriaId;
                const criteriaIds = !!this.props.evaluation.list
                    ? this.props.evaluation.list
                          .map(x => Object.values(x.criteria))
                          .flat()
                          .filter(x => x.personGrowthTagId === personGrowthTagId)
                          .map(x => ({ testId, criteriaId: x.id }))
                    : [];
                return this.state.selectedFilter === HAS_NO_EVALUATIONS_MENU_ID
                    ? criteriaId !== null && criteriaIds.length > 0
                        ? [{ testId, criteriaIds }]
                        : [{ testId, criteriaId }]
                    : [{ testId, criteriaId }];
            default:
                return [{ testId, criteriaId }];
        }
    };

    handleLoad = (fetchType, clearData = false) => {
        try {
            const { paging, searchTerm } = this.state;
            if (fetchType === 'search' && searchTerm === '') {
                return false;
            }
            const newPageNum = clearData ? 2 : paging.pageNum + 1;

            if (fetchType === 'search') {
                const searchCriteria = {
                    filter: {
                        fields: [
                            {
                                category: 'Persons',
                                field: 'FullName',
                                operation: 'contains',
                                value: searchTerm,
                            },
                        ],
                    },
                    paging: { pageNum: clearData ? 1 : paging.pageNum, pageSize: paging.pageSize },
                };

                if (!this.state.searchLoading) {
                    this.setState({ searchIsLoaded: false, searchLoading: true });
                    fullTextSearchWithEvaluations(searchCriteria).then(res => {
                        this.setState({
                            pageCount: res.data.meta.pageCount,
                            resultSearchTerm: searchTerm,
                            searchIsLoaded: true,
                            searchLoading: false,
                        });
                        this.setSearchResults(clearData, newPageNum, res, fetchType);
                    });
                }
            } else {
                const testId = this.props.selectedTest.id || COMPETENCIES_TEST_ID;
                const criteriaId =
                    !!this.state.criteriaSelectedId && !!this.state.criteriaSelectedId.id
                        ? this.state.criteriaSelectedId.id
                        : null;

                const getTestKind = testId => {
                    switch (testId) {
                        case COMPETENCIES_TEST_ID:
                            return 'Competency';
                        case MANAGEMENT_POTENTIAL_TESTS_ID:
                            return 'Potential';
                        default:
                            return '';
                    }
                };

                const filter = {
                    evaluationFilter: {
                        testKind: getTestKind(testId),
                        criteria: this.makeFilterCriteria(testId, criteriaId),
                        filterKind: this.state.selectedFilter,
                    },
                };

                if (!this.state.favoritesLoading) {
                    this.setState({ favoritesIsLoaded: false, favoritesLoading: true });

                    getWishlistWithEvaluationResults({
                        filter: filter,
                        paging: { pageNum: clearData ? 1 : newPageNum, pageSize: paging.pageSize },
                    }).then(res => {
                        this.setState({ favoritesIsLoaded: true, favoritesLoading: false });
                        this.setSearchResults(clearData, newPageNum, res);
                    });
                }
            }
        } catch (error) {
            showErrorAlert(error.message);
        }
    };

    chooseValueType = testId => {
        switch (testId) {
            case BEHAVIOR_EVAL_TYPE_ID:
            case CAREER_DESTRUCTORS_EVAL_TYPE_ID:
                return 'decimalValue';
            default:
                return 'normalizedValue';
        }
    };

    fillByEvaluationTestType = (testId, evaluationTestResults) => {
        switch (testId) {
            case COMPETENCIES_TEST_ID:
                const competencyTestsIds = this.props.evaluation.list
                    .filter(x => x.kind === 'Competency')
                    .map(x => x.id);
                return evaluationTestResults
                    .filter(x => competencyTestsIds.includes(x.testId))
                    .map(x => ({ ...x, testId: COMPETENCIES_TEST_ID }))
                    .map(x => ({ ...x, criterionId: x.personGrowthTagId }));
            case MANAGEMENT_POTENTIAL_TESTS_ID:
                const potentialTestsIds = this.props.evaluation.list
                    .filter(x => x.kind === 'Potential')
                    .map(x => x.id);
                return evaluationTestResults
                    .filter(x => potentialTestsIds.includes(x.testId))
                    .map(x => ({ ...x, testId: MANAGEMENT_POTENTIAL_TESTS_ID }))
                    .map(x => ({ ...x, criterionId: x.personGrowthTagId }));
            default:
                return evaluationTestResults;
        }
    };

    setSearchResults = (clearData, newPageNum, res, fetchType) => {
        const { paging, pageCount } = this.state;
        this.setState({ testedPersons: clearData ? [] : this.state.testedPersons });
        const prevSearchResults = clearData ? [] : this.state.testedPersons;

        const testId = this.props.selectedTest.id || COMPETENCIES_TEST_ID;

        const persons =
            fetchType === 'search'
                ? res.data.payload.map(x => ({
                      ...x,
                      percent: this.fillByEvaluationTestType(
                          testId,
                          x.evaluationTestResults.map(x => 
                              ({...x, show: this.chooseValueType(x.testId)}))
                      ),
                  }))
                : res.data.persons.payload.map(x => ({
                      ...x,
                      percent: this.fillByEvaluationTestType(
                          testId,
                          x.evaluationTestResults.map(x => 
                              ({...x, show: this.chooseValueType(x.testId)}))
                      ),
                  }));

        this.setState({
            testedPersons: [...prevSearchResults, ...persons],
            paging: { pageNum: newPageNum, pageSize: paging.pageSize },
            hasMore: pageCount > newPageNum,
        });

        if (!!!this.state.criteriaSelectedId.id) {
            this.updateCriteria(this.props.evaluation);
        }
    };

    getFavorites = async () => {
        this.setState({
            favoritesLoading: true,
            favoritesIsLoaded: false,
            resultSearchTerm: '',
            testedPersons: [],
        });

        const testId = this.props.selectedTest.id || COMPETENCIES_TEST_ID;
        const criteriaId =
            !!this.state.criteriaSelectedId && !!this.state.criteriaSelectedId.id
                ? this.state.criteriaSelectedId.id
                : null;

        const getTestKind = testId => {
            switch (testId) {
                case COMPETENCIES_TEST_ID:
                    return 'Competency';
                case MANAGEMENT_POTENTIAL_TESTS_ID:
                    return 'Potential';
                default:
                    return '';
            }
        };

        const criteria = {
            filter: {
                evaluationFilter: {
                    testKind: getTestKind(testId),
                    criteria: this.makeFilterCriteria(testId, criteriaId),
                    filterKind: this.state.selectedFilter,
                },
            },
            paging: { pageNum: 1, pageSize: 10 },
        };
        const res = await getWishlistWithEvaluationResults(criteria);
        const pageCount = res.data.persons.meta.pageCount;

        const persons = res.data.persons.payload.map(x => ({
            ...x,
            percent: this.fillByEvaluationTestType(
                testId,
                x.evaluationTestResults.map(x => ({...x, show: this.chooseValueType(x.testId)}))
            ),
        }));

        this.setState({ favoritesLoading: false, favoritesIsLoaded: true });
        return {
            pageCount: pageCount,
            persons: persons,
        };
    };

    searchPersons = async () => {
        return {
            pageCount: 0,
            persons: [],
        };
    };

    updateCriteria = evaluation => {
        
        if (this.state.competencyModels.length === 0) {
            return;
        }
        
        const evaluationsList = evaluation.list.map(item => {
            const findItem = EVALUATION_TYPE.find(x => x.id === item.id);
            if (findItem === undefined) {
                return item;
            }
            return {
                ...item,
                title: findItem.title,
                description: findItem.description,
            };
        });

        const resultIds = EVALUATION_TYPE.map(x => x.id);

        const res = this.getCriteria(evaluationsList);

        this.setState({
            resultIds: resultIds,
            evaluation: evaluationsList,
            resultCriteria: res.resultCriteria,
            criteriaSelectedId: res.criteriaSelectedId,
        });
    };

    componentDidMount = async () => {
            this.setState({ resultSearchTerm: '' });

            const {
                personId,
                setEvaluationTestsCriterion,
                fetchEvaluation,
                fetchType,
            } = this.props;

            if (!this.props.evaluation.isLoaded && !this.props.evaluation.loading) {
                fetchEvaluation();
            }

            if (
                !this.props.evaluation.criteriasIsLoaded &&
                !this.props.evaluation.criteriasLoading
            ) {
                setEvaluationTestsCriterion(personId);
            }

            if (!!!this.props.selectedTest.id) {
                this.props.setSelectes({ id: COMPETENCIES_TEST_ID, key: 0 });
            }

            this.fetchCompetencies();

            this.updateCriteria(this.props.evaluation);

            const fetchResult =
                fetchType === 'search' ? await this.searchPersons() : await this.getFavorites();

            this.setState({
                testedPersons: fetchResult.persons,
                pageCount: fetchResult.pageCount,
            });

            if (!!!this.state.criteriaSelectedId.id) {
                this.updateCriteria(this.props.evaluation);
                this.setState({ searchIsLoaded: false });
            }
    };
    
    fetchCompetencies = async () => {
        let mount = true;
        try {
            const { personId } = this.props; 
            const competencyModelsData = await getCompetencies(personId);
            
            if (competencyModelsData && competencyModelsData.status === 200 && competencyModelsData.data) {
                const competencyModels = competencyModelsData.data;
                mount && this.setState({competencyModels});
            }
        } catch(error) {
            showErrorAlert(error.message);
        }
        return () => { mount = false;}
    };

    updateData = () => {
        const testedPersons =
            this.state.testedPersons.length > 0 && !!this.props.selectedTest.id
                ? this.state.testedPersons.map(x => ({
                      ...x,
                      percent: this.fillByEvaluationTestType(
                          this.props.selectedTest.id,
                          x.evaluationTestResults.map(x => ({...x, show: this.chooseValueType(x.testId)}))
                      ),
                  }))
                : [];

        this.setState({ testedPersons });
    };

    componentDidUpdate = async (prevProps, prevState) => {
        if (
            prevProps.fetchType !== this.props.fetchType ||
            prevState.selectedFilter !== this.state.selectedFilter
        ) {
            if (this.props.fetchType === 'search') {
                if (
                    prevProps.fetchType !== this.props.fetchType ||
                    prevProps.selectedTest.id !== this.props.selectedTest.id
                ) {
                    this.setState({ testedPersons: [], searchIsLoaded: false, searchTerm: '' });
                }
            } else {
                const res = await this.getFavorites();
                this.setState({
                    testedPersons: res.persons,
                    pageCount: res.pageCount,
                    resultSearchTerm: '',
                    paging: { pageNum: 1, pageSize: 10 },
                    hasMore: res.pageCount > 1,
                });
            }
        }

        if (prevProps.selectedTest.id !== this.props.selectedTest.id) {
            this.updateCriteria(this.props.evaluation);
            this.updateData();
            this.setState({ searchIsLoaded: false, selectedFilter: ALL_EVALUATIONS_MENU_ID });
        }
    };

    handleFilterChange = value => {
        this.setState({ selectedFilter: value });
    };

    renderRangeFilterMenu = () => {
        return (
            <MenuFilter
                options={hasEvaluationsMenuOptions}
                value={this.state.selectedFilter}
                onChange={this.handleFilterChange}
            />
        );
    };

    onChangeSearchTerm = e => {
        this.setState({ searchTerm: e.target.value });
    };

    onSearchFieldKeyPress = e => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleLoad('search', true);
        }
    };

    render() {
        const {
            criteriaSelectedId,
            testedPersons,
            resultIds,
            resultCriteria,
            hasMore,
            person,
            isOpenModalSummary,
            searchLoading,
            searchIsLoaded,
            favoritesLoading,
            searchTerm,
            resultSearchTerm,
            selectedFilter,
            competencyModels,
        } = this.state;

        const { activeCard, setActiveCard, selectedTest, fetchType } = this.props;

        const noDataText = fetchType === 'favorites' ? 'Нет данных' : '';

        setActiveCard(ACTIVE_DETAIL_CARD);
        const isMobileActive = activeCard === ACTIVE_DETAIL_CARD;

        const personClick = {
            onChange: this.onSelectPerson,
            onClick: this.onSelectPerson,
            onImageClick: this.onSelectPerson,
        };

        const tests = {
            testId: this.props.selectedTest.id || COMPETENCIES_TEST_ID,
            criteriaId: !!criteriaSelectedId ? criteriaSelectedId.id : {},
        };

        const getFilteredTestedPersons = selectedFilter => {
            switch (selectedFilter) {
                case HAS_EVALUATIONS_MENU_ID:
                    return testedPersons.filter(person =>
                        person.percent.find(
                            x =>
                                x.testId === tests.testId &&
                                x.criterionId === tests.criteriaId &&
                                !!x.normalizedValue,
                        ),
                    );
                case HAS_NO_EVALUATIONS_MENU_ID:
                    return testedPersons.filter(
                        person =>
                            !person.percent.find(
                                x =>
                                    x.testId === tests.testId && x.criterionId === tests.criteriaId,
                            ),
                    );
                case ALL_EVALUATIONS_MENU_ID:
                default:
                    return testedPersons;
            }
        };

        const testedPersonsData = getFilteredTestedPersons(selectedFilter);

        return (
            <>
                <div
                    className={classnames('DetailsCard', {
                        'DetailsCard--isMobileActive': isMobileActive,
                    })}
                >
                    <MainCardMenu main menuItems={evaluateResultCardMenuItems} />
                    {fetchType === 'search' && (
                        <div className="LKProgressSearchLine">
                            <Input
                                value={searchTerm}
                                onChange={this.onChangeSearchTerm}
                                onKeyPress={this.onSearchFieldKeyPress}
                            />
                            <Button onClick={() => this.handleLoad('search', true)} />
                        </div>
                    )}

                    <div className="EvaluationFavorite">
                        <InfiniteScroll
                            loadMore={() => this.handleLoad(fetchType)}
                            hasMore={hasMore}
                            initialLoad={false}
                            useWindow={false}
                        >
                            {!!resultIds && Array.isArray(resultIds) && resultIds.length > 0 && (
                                <Slider
                                    data={this.renderEvaluationType(EVALUATION_TYPE)}
                                    initialSlide={selectedTest.key}
                                    slideChange={this.slideChange}
                                    resultIds={resultIds}
                                />
                            )}
                            {resultCriteria &&
                                Array.isArray(resultCriteria) &&
                                resultCriteria.length > 0 && competencyModels.length > 0 && (
                                    <Slider
                                        data={this.renderEvaluationType(resultCriteria, false)}
                                        initialSlide={criteriaSelectedId.key}
                                        slideChange={this.slideChangeCriteria}
                                        resultIds={resultCriteria.map(x => x.id)}
                                    />
                                )}
                            <EvaluteScaleInfo
                                test={this.state.evaluation.find(x => x.id === selectedTest.id)}
                                testId={selectedTest.id}
                            />
                            <div className="LKEvaluationFavoriteFilters">
                                {fetchType === 'favorites' &&
                                    !!this.state.favoritesIsLoaded &&
                                    this.renderRangeFilterMenu()}
                            </div>
                            {fetchType === 'search' &&
                                searchIsLoaded &&
                                testedPersonsData.length === 0 && (
                                    <EvaluationCardItemBlock shadow>
                                        <div className="EvaluationPerson__Content-NotFound">
                                            По вашему запросу <strong>{resultSearchTerm}</strong>{' '}
                                            ничего не найдено
                                        </div>
                                    </EvaluationCardItemBlock>
                                )}
                            {testedPersons.length > 0 && competencyModels.length > 0 ? (
                                <RenderPerson
                                    tests={tests}
                                    data={testedPersonsData}
                                    basket={{ persons: [] }}
                                    {...personClick}
                                    checkbox={false}
                                    noDataText={noDataText}
                                />
                            ) : (
                                fetchType === 'favorites' && (
                                    <NoDataBlock text='Никто не добавлен в "Избранное"' />
                                )
                            )}
                            {(favoritesLoading || searchLoading) && <Loader />}
                        </InfiniteScroll>
                    </div>
                </div>
                {person && (
                    <ModalSummaryCard
                        isOpen={isOpenModalSummary}
                        onClose={this.closeSummaryCard}
                        person={person}
                        profileRoute={lkDetailsEvaluateTeamProfile}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        personId: state.auth.user.personId,
        favorites: state.wishlists,
        evaluation: state.evaluation,
        selectedTest: state.evaluation.selected,
        testedPersons: state.evaluation.testedPersons,
    };
};

const actions = { fetchEvaluation, setEvaluationTestsCriterion, setSelectes, showErrorAlert };

export default connect(
    mapStateToProps,
    actions,
)(EvaluationResult);
