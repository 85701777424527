import React from 'react';
import { connect } from 'react-redux';
import { fetchCatalog } from 'ducks/Catalog';
import { queryOperation } from 'components/Search/QueryOperation';
import FilterTemplate from './FilterTemplate';
import { FIELDS_TYPE } from '../SearchFilters/constants';

const Fields = [
    {
        type: FIELDS_TYPE.input,
        label: 'Социальная активность',
        query: queryOperation.Contain,
        value: 'SocialActivity',
        column: 'SocialActivity',
        table: 'PersonPersonnels',
        isDefault: true,
    },
    {
        type: FIELDS_TYPE.input,
        label: 'Членство в общественных организациях',
        query: queryOperation.Contain,
        value: 'MemberOfSocialOrganizations',
        column: 'MemberOfSocialOrganizations',
        table: 'PersonPersonnels',
        isDefault: true,
    },
];
class ManagementFilter extends React.Component {
    render() {
        return <FilterTemplate {...this.props} fields={Fields} />;
    }
}

const mapStateToProps = state => {
    return {
        catalogs: state.catalogs,
        activeFilter: state.filterSearch.activeFilter,
    };
};

const actions = { fetchCatalog };

export default connect(
    mapStateToProps,
    actions,
)(ManagementFilter);
