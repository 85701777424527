import React from 'react';
import Menu from './Menu';
import Logo from './Logo';

import './Header.scss';

const Header = (props) => {
    const { user, refetchKey } = props;
    return (
        <div className="LayoutHeader">
            <Logo />
            <Menu user={ user } refetchKey={refetchKey} />
        </div>
    );
};

export default Header;