import React from 'react';

import { getPersonPhotoUri, photoSizes } from 'api';
import Image from 'components/common/Image';
import { person as personRoute } from 'routes';
import { Link } from 'react-router-dom';
import './PersonShortInfo.scss';

const PersonShortInfo = (props) => {
    const { person } = props;
    const fullName = `${person.lastName} ${person.firstName} ${person.middleName}`;

    const handleImageClick =
        () => window.open(personRoute.buildUrl({ id: person.id }), '_blank noopener noreferrer');

    return (
        <div className='PersonInfo'>
            <div className='PersonInfo__Photo'>
                <Image
                    alt='Фотография'
                    photoUri={getPersonPhotoUri(photoSizes.small, person.id)}
                    showLoader={false}
                    loading={false}
                    onClick={handleImageClick}
                    onLoad={() => { }}
                />
            </div>
            <div className='PersonInfo__Text PersonInfoDetails'>
                <Link to={`/person/${person.id}`} target="_blank">
                    <div className='PersonInfoDetails__Name'>{fullName}</div>
                </Link>
                <div className='PersonInfoDetails__Job'>
                    <div>{person.currentJobTitle}</div>
                    <div>{person.currentJobCompanyName}</div>
                </div>
            </div>
        </div>
    );
}

export default PersonShortInfo;
