import React from 'react';
import classnames from 'classnames';
import './InfoField.scss';
import DatePicker from 'components/Lk/Uikit/DatePicker';
import { Select } from 'components/Lk/Uikit/Select/Select';
import TextareaAutosize from 'react-autosize-textarea';
import UserSearchSelect from 'components/uikit/UserSearchSelect';
import InputNumber from '../InputNumber';
import InputMask from 'components/uikit/InputMask';

export default React.forwardRef((props, ref) => {
    const {onChange, type="text", value, items, inpref, double, dataTest, maskchar, maxRows, ...tail} = props;

    const onChangeValue = e => {
        const value = e.target.value;

        onChange(value);
    };

    switch (type) {
        case 'text':
            return (
                <input
                    {...tail}
                    ref={ref}
                    onChange={onChangeValue}
                    value={value || ''}
                    type={type}
                    className={classnames('InputField', props.className)}
                    data-test={dataTest}
                />
            );
        case 'number':
            return (
                <InputNumber
                    ref={inpref}
                    onChange={onChange}
                    value={value}
                    className={classnames('InputField', props.className)}
                    double={double}
                    dataTest={dataTest}
                    {...tail}
                />
            );
        case 'textarea':
            return (
                <TextareaAutosize
                    ref={ref}
                    onChange={onChangeValue}
                    value={value}
                    type={type}
                    className={classnames('InputField', props.className)}
                    data-test={dataTest}
                    maxRows={maxRows}
                    {...tail}
                />
            );
        case 'date':
            return (
                <DatePicker
                    inPortal
                    selected={value ? new Date(value) : null}
                    onChange={onChange}
                    dataTest={dataTest}
                    {...tail}
                />
            );
        case 'select':
            return (
                <Select
                    items={items}
                    value={value}
                    onChange={onChange}
                    placeholder="Выбрать..."
                    dataTest={dataTest}
                    noOptionsMessage={() => 'Нет данных'}
                    {...tail}
                />
            );
        case 'userSearchSelect':
            return (
                <UserSearchSelect
                    value={value}
                    onChange={onChange}
                    placeholder="Выбрать..."
                    dataTest={dataTest}
                    {...tail}                    
                />
            );
        case 'inputMask':
            return (
                <InputMask 
                    value={value}
                    onChange={onChange}
                    maskChar={maskchar ||null}
                    {...tail}
                />                
            );            
        default:
            return null;
    }
});
