import React from "react";
import { connect } from 'react-redux';
import Dropdown from "components/common/Dropdown";

import Button from 'components/uikit/Button';
import "./HeadingMenu.scss";
import { faHeading } from "@fortawesome/free-solid-svg-icons";

function ToggleButton(props) {
    const { children, onToggle, tooltip, tooltipHeader, tooltipPlacement } = props;
    return (
        <React.Fragment>
            <Button
                onClick={onToggle}
                size="md"
                faIcon={faHeading}
                data-test="HeadingMenu_Main-button"
                tooltip={tooltip}
                tooltipHeader={tooltipHeader}
                tooltipPlacement={tooltipPlacement}
            />
            <div onClick={onToggle}>
                {children}
            </div>
        </React.Fragment>
    );
}

function HeadingMenu({ onExecute, tooltip, tooltipHeader, tooltipPlacement }) {
    return (
        <Dropdown
            ToggleButtonComponent={ToggleButton}
            defaultOpen={false}
            className="HeadingButton"
            tooltip={tooltip}
            tooltipHeader={tooltipHeader}
            tooltipPlacement={tooltipPlacement}
        >
            <ul className="HeadingButton__Options">
                <li>
                    <Button
                        onClick={() => onExecute(1)}
                        size="md"
                        data-test="HeadingMenu_Levels_First--button"
                    > 
                        1-го уровня
                    </Button>
                </li>
                <li>
                    <Button
                        onClick={() => onExecute(2)}
                        size="md"
                        data-test="HeadingMenu_Levels_Second--button"
                    >
                        2-го уровня
                    </Button>
                </li>
                <li>
                    <Button
                        onClick={() => onExecute(3)}
                        size="md"
                        data-test="HeadingMenu_Levels_Third--button"
                    >
                        3-го уровня
                    </Button>
                </li>
                <li>
                    <Button
                        onClick={() => onExecute(4)}
                        size="md"
                        data-test="HeadingMenu_Levels_Fourth--button"
                    >
                        4-го уровня
                    </Button>
                </li>
                <li>
                    <Button
                        onClick={() => onExecute(5)}
                        size="md"
                        data-test="HeadingMenu_Levels_Fifth--button"
                    >
                        5-го уровня
                    </Button>
                </li>
                <li>
                    <Button
                        onClick={() => onExecute(6)}
                        size="md"
                        data-test="HeadingMenu_Levels_Sixth--button"
                    >
                        6-го уровня
                    </Button>
                </li>
            </ul>
    </Dropdown>)
}

const mapStateToProps = (state) => ({
    user: state.auth.user
});

export default connect(mapStateToProps)(HeadingMenu);
