import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalDialog from 'components/common/ModalDialog';
import Condition from '../Condition';

export const ConditionModal = props => {
    const { data, onClose, filters, onSave, isOpen } = props;

    const [currendDate, setDate] = useState(data);

    const onChange = data => setDate(data);
    const onClick = () => {
        onSave(currendDate);
    };

    return (
        <ModalDialog
            onCloseModal={onClose}
            onClick={onClick}
            modalOpen={isOpen}
            btnOktext="Сохранить"
            modalHeader="Конструктор поиска"
            size="xxl"
            btnCanceltext="Отмена"
        >
            <Condition filters={filters} data={currendDate || []} onChangeData={onChange} />
        </ModalDialog>
    );
};

ConditionModal.propTypes = {
    isOpen: PropTypes.bool,
    accessRequestId: PropTypes.string,
    onClose: PropTypes.func,
};

export default ConditionModal;
