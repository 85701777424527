import React from 'react';
import Select from 'components/uikit/Select';
import './CompetencyObjectHelp.scss';
import uuid from 'uuid/v4';
import InputText from 'components/uikit/InputText';
import Label from 'components/uikit/Label';
import Button from 'components/uikit/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Popover from 'components/Lk/Uikit/Popover';

export const CompetencyObjectHelpTitle = () => {
    const tooltip = `Выберите один или несколько инструментов, которые помогут резервисту достигнуть
                    цели. Описание инструмента появится после того, как вы на него нажмете.`;
    return (
        <div className="competencyObjectHelp__container__title">
            <Popover tooltip={tooltip}>
                <div
                    className="tooltip-link"
                    data-tip
                    data-for="socialActivityHint"
                    style={{ fontSize: 24 + 'px' }}
                >
                    ?
                </div>
            </Popover>
        </div>
    );
};

const helpObjectsTypes = [
    {
        value: 1,
        label: 'Гемба',
        type: 'Gemba',
        tip:
            'Изучение ситуации или процесса непосредственно на месте, где реализуется продукция или предоставляется услуга.',
    },
    {
        value: 2,
        label: 'Стажировка',
        type: 'Internship',
        tip:
            'Приобретение новых профессиональных навыков и знакомство с обязанностями на рабочем месте.',
    },
    {
        value: 3,
        label: 'Проект',
        type: 'Project',
        tip:
            'Последовательность взаимосвязанных действий, которые направлены на решение конкретной ситуации и ограничены по времени. Реализация проекта позволяет приобрести новый профессиональный опыт.',
    },
    {
        value: 4,
        label: 'Задача',
        type: 'Task',
        tip:
            'Кратковременное действие в рамках проекта или операционной деятельности. Решение задачи позволяет приобрести новый профессиональный опыт.',
    },
    {
        value: 5,
        label: 'Shadowing',
        type: 'Shadowing',
        tip:
            'Обучение на рабочем месте на опыте руководителя или более опытного сотрудника. Во время обучения резервист наблюдает за процессом работы руководителя, выезжает вместе с ним на переговоры, самостоятельно выполняет задания и задает вопросы.',
    },
];

const tips = [
    {
        value: 1,
        label:
            'Изучение ситуации или процесса непосредственно на месте, где реализуется продукция или предоставляется услуга.',
    },
    {
        value: 2,
        label:
            'Приобретение новых профессиональных навыков и знакомство с обязанностями на рабочем месте.',
    },
    {
        value: 3,
        label:
            'Последовательность взаимосвязанных действий, которые направлены на решение конкретной ситуации и ограничены по времени. Реализация проекта позволяет приобрести новый профессиональный опыт.',
    },
    {
        value: 4,
        label:
            'Кратковременное действие в рамках проекта или операционной деятельности. Решение задачи позволяет приобрести новый профессиональный опыт.',
    },
    {
        value: 5,
        label:
            'Обучение на рабочем месте на опыте руководителя или более опытного сотрудника. Во время обучения резервист наблюдает за процессом работы руководителя, выезжает вместе с ним на переговоры, самостоятельно выполняет задания и задает вопросы.',
    },
];

const CompetencyObjectHelp = ({ personGrowthEvents = [], editMode, assistanceType, onChange }) => {
    const isEventContainsId = (evt, id) => evt.id === id || evt.local_id === id;

    const handleSelectOptionAdd = () =>
        onChange('personGrowthEvents', [...personGrowthEvents, { local_id: uuid() }]);

    const handleSelectOptionRemove = (eventId) =>
        onChange(
            'personGrowthEvents',
            personGrowthEvents.filter((evt) => !isEventContainsId(evt, eventId)),
        );

    const handleTypeChange = (eventId, value) =>
        onChange(
            'personGrowthEvents',
            personGrowthEvents.map((evt) =>
                isEventContainsId(evt, eventId) ? { ...evt, type: value } : evt,
            ),
        );

    const handleAssistanceDetailsChange = (eventId, value) =>
        onChange(
            'personGrowthEvents',
            personGrowthEvents.map((evt) =>
                isEventContainsId(evt, eventId)
                    ? { ...evt, assistanceDetails: value, toggle: new Date() }
                    : evt,
            ),
        );

    const getTipByEventType = (type) => {
        let tipObj = tips.find((x) => x.value === type);
        let tip = '';

        if (tipObj) {
            tip = tipObj.label;
        } else {
            // TODO
            // not found in first dictionary? OK! We'll find it in another one :P
            tipObj = helpObjectsTypes.find((x) => x.type === type);
            tip = tipObj && tipObj.tip;
        }

        return tip;
    };

    return (
        <div className="competencyObjectHelp">
            <div className="competencyObjectHelp__container">
                {personGrowthEvents &&
                    personGrowthEvents.map((evt) => {
                        const typeValue = !editMode
                            ? helpObjectsTypes.find((x) => x.type === evt.type)
                            : helpObjectsTypes.find((x) => x.label === assistanceType[evt.type]);

                        const eventId = evt.id || evt.local_id;

                        return (
                            <div
                                key={eventId}
                                className="competencyObjectHelp__container-horizontal"
                            >
                                <div className="competencyObjectHelp__container__row-horizontal">
                                    <div className="competencyObjectHelp__container__row__item">
                                        <Select
                                            value={typeValue}
                                            inputId={eventId}
                                            placeholder={<div>Выберите тип</div>}
                                            options={helpObjectsTypes}
                                            onChange={(e) => handleTypeChange(eventId, e.type)}
                                            styles=""
                                            isDisabled={false}
                                        />
                                    </div>
                                    <div className="competencyObjectHelp__container__row__item competencyObjectHelp__container__row__item__button">
                                        <FontAwesomeIcon
                                            className="competencyObjectHelp__delete"
                                            size="2x"
                                            icon={faTimesCircle}
                                            onClick={() => handleSelectOptionRemove(eventId)}
                                        />
                                    </div>
                                </div>
                                <div className="competencyObjectHelp__Small-Tip">
                                    {getTipByEventType(evt.type)}
                                </div>
                                <div className="competencyObjectHelp__container">
                                    <div className="competencyObjectHelp__container__item">
                                        <Label className="competencyObjectHelp__label">
                                            Чем поможет?
                                        </Label>
                                        <div>
                                            <InputText
                                                className="competencyObjectHelp__textArea"
                                                style={{ resize: 'none', minHeight: 180 + 'px' }}
                                                maxLength={4000}
                                                id="additionalObjectInfo"
                                                value={evt.assistanceDetails}
                                                onChange={(e) =>
                                                    handleAssistanceDetailsChange(
                                                        eventId,
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                <div className="competencyObjectHelp__container__row">
                    <div className="competencyObjectHelp__container__row__item__button">
                        <Button type="add" addLink onClick={() => handleSelectOptionAdd()}>
                            Добавить
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompetencyObjectHelp;
