import React from 'react';
import Input from 'components/Lk/Uikit/Input';
import Autocomplete from 'react-autocomplete';
import './SearchRequestInput.scss';
import classnames from 'classnames';

class SearchRequestInput extends React.Component {
    state = { items: [] };
    timer = null;
    _mount=true;

    componentWillUnmount(){
        this._mount = false;
    }

    renderItem = (item, isHighlighted) => {
        return (
            <div
                key={item.id}
                className={classnames('LKSearchRequest__ItemWrapper', {
                    'LKSearchRequest__ItemWrapper--active': isHighlighted,
                })}
            >
                <div className={classnames('LKSearchRequest__Item')}>
                    <span>{item.title || 'Запрос'}</span>
                </div>
            </div>
        );
    };

    loadRequestTerm = async () => {
        const { searchCallback = () => {}, withPayload = true } = this.props;
        const res = await searchCallback(this.props.value);
        this._mount  && this.setState({ items: withPayload ? res?.data?.payload || [] : res?.data || [] });
    };

    async componentDidUpdate(prevProps) {
        const { value } = this.props;
        if (value !== prevProps.value) {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.loadRequestTerm();
            }, 500);
        }
    }
    renderInput = props => (
        <Input
            {...props}
            placeholder={this.props.placeholder}
            preIcon={this.props.preIcon}
            postIcon={this.props.postIcon}
            postIconColor={this.props.postIconColor}
            postIconClick={this.props.postIconClick}
            className={this.props.className}
            onKeyPress={this.props.onKeyPress}
            onFocus={this.loadRequestTerm}
        />
    );
    renderMenu = items => {
        return items?.length > 0 ? <div className="LKSearchRequest__Menu" children={items} /> : <div />;
    };

    render() {

        return (
            <div className="LKSearchRequest">
                <Autocomplete
                    renderItem={this.renderItem}
                    renderInput={this.renderInput}
                    renderMenu={this.renderMenu}
                    getItemValue={item => item?.id || 'value'}
                    items={this.state.items}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    wrapperStyle={{}}
                    onSelect={this.props.onSelect}
                />
            </div>
        );
    }
}

export default SearchRequestInput;
