import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { fetchTeams, setTeams, clearTeams } from 'ducks/Teams';
import { showErrorAlert, showWarningAlert, showSuccessAlert } from 'ducks/Alert';
import { putTeam, deleteTeam as deleteTeamApi } from 'api'
import { serviceResultCode } from 'serviceErrors';
import uuid from "uuid/v4";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DataCard } from 'components/Lk/Uikit/DataCard';
import CommonSearchCard from 'components/Lk/Common/CommonSearchCard';
import TeamsEditMenu from './TeamsEditMenu';
import { teamsEditMenuItems, DELETE_MENU_ID, TEAM_NAME_LIMIT } from './constants';
import TeamEditMenu from './TeamEditMenu';
import TeamAcceptMenu from './TeamAcceptMenu';
import AcceptPage from 'components/Lk/Common/AcceptPage';
import InfoField from 'components/Lk/Uikit/InfoField';
import Loader from "components/common/Loader";
import './Teams.scss';
import { getUserShortName, isNullOrWhitespace } from 'utils';
import { lkTeamsProfile } from 'routes'
import StickyFooter from 'components/Lk/Common/StickyFooter';
import CardItemExpert from 'components/Lk/Common/CardItemPerson/CardItemExpert';
import classnames from 'classnames';
import WishList from 'components/Lk/Shared/GroupList/WishList';
import PersonCard from 'components/Lk/Common/PersonCard';
import ModalSummaryCard from 'components/Lk/Common/ModalPersonSummaryCard';

const LeaderTeams = props => {
    const { setTeams, fetchTeams, clearTeams, teams, setActiveCard, userId, showErrorAlert, showWarningAlert, showSuccessAlert, activeCard, fromProfile } = props;
    setActiveCard(activeCard);

    useEffect(() => {
        return () => clearTeams();
    }, [clearTeams]);

    useEffect(() => {
        fetchTeams(userId);
    },[fetchTeams, userId]);

    const [teamTitles, setTeamTitles] = useState([]);

    useEffect(() => {
        setTeamTitles(teams.leading.map(x => ({ teamId: x.id, title: x.name })));
    }, [teams.leading]);

    const [teamIsChanging, setTeamIsChanging] = useState(false);

    const handleTeamSave = async (changedTeam, action) => {
        try {
            setTeamIsChanging(true);
            const requestParams = {
                id: changedTeam.id,
                concurrencyId: changedTeam.concurrencyId,
                name: changedTeam.name,
                leaderPersonId: changedTeam.leaderPerson.id,
                followerPersonIds: changedTeam.followerPersons.map(x => x.id)
            };

            const res = await putTeam(requestParams, true);
            switch (action) {
                case 'new':
                    await setTeams({
                        ...teams,
                        leading: [...teams.leading, res.data],
                    });
                    showSuccessAlert('Команда успешно добавлена');
                    break;
                case 'list':
                    break;
                default:
                    await setTeams({
                        ...teams,
                        leading: teams.leading.map(team =>
                            team.id === res.data.id ? res.data : team,
                        ),
                    });
                    showSuccessAlert('Команда успешно обновлена');
                    break;
            }
            return res;
        } catch (error) {
            const errorCode = error.response && error.response.data && error.response.data.code;
            switch (errorCode) {
                case serviceResultCode.ConcurrencyError:
                    showWarningAlert('Команда резервиста была изменена другим пользователем. Пожалуйста, обновите страницу и внесите изменения повторно');
                    break;
                case serviceResultCode.InvalidTeamName:
                    showErrorAlert('Имя команды не может быть пустым или превышать 250 символов');
                    break;
                case serviceResultCode.InvalidTeam:
                    showErrorAlert('Вы уже являетесь членом этой команды как ее руководитель');
                    break;
                default:
                    showErrorAlert('Произошла непредвиденная ошибка при сохранении команды');
            }
        } finally {
            setTeamIsChanging(false);
        }
    };

    const onDragEnd = result => {
        const { source, destination } = result;
        if (!destination || source.index === destination.index || !teamMenuAction) { return; }
        const selectedTeam = { ...editTeam };
        const copyTeamPersons = [...selectedTeam.followerPersons];
        const movedField = copyTeamPersons[source.index];
        copyTeamPersons.splice(source.index, 1);
        copyTeamPersons.splice(destination.index, 0, movedField);
        const updatedTeam = { ...selectedTeam, followerPersons: [...copyTeamPersons] }
        setEditTeam(updatedTeam);
    };

    const handlePersonDelete = (personId) => {
        const copyTeamPersons = [...editTeam.followerPersons.filter(x => x.id !== personId)];
        const updatedTeam = { ...editTeam, followerPersons: [...copyTeamPersons] }
        setEditTeam(updatedTeam);
    };

    const handleActionClick = personId => (e) => {
        e.preventDefault();
        switch (teamMenuAction.action) {
            case 'delete':
                handlePersonDelete(personId);
                break;
            default:
                return;
        }
    };

    const [editTeam, setEditTeam] = useState({});
    const [teamMenuAction, setTeamMenuAction] = useState();

    const [actionSelected, setActionSelected] = useState(false);
    const getActionFromMenu = () => {
        return teamsEditMenuItems.find(x => x.id === actionSelected).action;
    };

    const [newTeamName, setNewTeamName] = useState('');
    const [teamsChanging, setTeamsChanging] = useState(false);

    const onChangeTeams = async () => {
        setTeamsChanging(true);
        switch (actionSelected) {
            case 'addTeam':
                await onAddTeam(newTeamName);
                break;
            case 'renameTeam':
                await onRenameTeam();
                break;
            case 'deleteTeam':
                approveTeamsDelete();
                break;
            default:
                break;
        }
        setTeamsChanging(false);
    };

    const renderMyTeams = () => {
        let myTeams = [];
        if (teamMenuAction) {
            myTeams = teams.leading.filter(x => x.id === teamMenuAction.teamId);
        } else {
            myTeams = teams.leading.filter(x => !teamsToDelete.find(t => t.id === x.id));
        }

        return myTeams.map(x => renderTeam(x, { myTeam: true, action: actionSelected && getActionFromMenu(), menu: !actionSelected && renderTeamMenu(x) }));
    };
    
    const handleNewTeamNameChange = e => {
        e !== null && e !== undefined && setNewTeamName(e.slice(0, TEAM_NAME_LIMIT));
    };

    const renderTeamsBlock = (my = false) => {
        return (
            <>
                <div className='LKMainTeamsCategory'>
                    <div className='LKMainTeamsCategory__Name'>
                        {my 
                            ? teamMenuAction ? `Редактирование команды` : `Мои команды (${teams.leading.length})` 
                            : !actionSelected && !teamMenuAction && `Я в командах (${teams.following.length})`}
                    </div>
                    {my && renderTeamsEditMenu()}
                </div>
                <div className='LKMainTeams__Team'>
                    {actionSelected === 'addTeam' && my &&
                        <div className='LKMainTeamsNewTeam'>
                            <InfoField noIcon isEdit autoFocus title='Название команды' value={newTeamName} size='lg' onChange={handleNewTeamNameChange}  />
                        </div>
                    }
                    {
                        my
                            ? renderMyTeams()
                            : !actionSelected && !teamMenuAction && teams.following.map(x => renderTeam(x, { myTeam: false }))
                    }
                </div>
            </>
        );
    };

    const clearTeamsActions = (resetTitles = false) => {
        setActionSelected(false)
        setNewTeamName('');
        setTeamsToDelete([]);
        resetTitles && setTeamTitles(teams.leading.map(x => ({ teamId: x.id, title: x.name })));
    };

    const [teamsEditMenu, setTeamsEditMenu] = useState(false);
    const handleTeamsChange = () => {
        setTeamMenuAction(false);
        setTeamsEditMenu(!teamsEditMenu);
    };

    const teamsOpenMenuRef = useRef();
    const teamsMenuRef = useRef();

    const handleClickOutside = e => {
        if (teamsMenuRef.current.contains(e.target) || teamsOpenMenuRef.current.contains(e.target)) {
            return;
        }
        setTeamsEditMenu(false);
        setActionSelected(false);
    };

    useEffect(() => {
        if (teamsEditMenu) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [teamsEditMenu]);

    const renderTeamsEditMenu = () => {
        return (
            !teamMenuAction && 
            <>
                <div ref={teamsOpenMenuRef}
                     className={`LKMainTeamsIcon LKMainTeamsIcon__Services 
                        ${teamsEditMenu && ' LKMainTeamsIcon__Services--active'}`}
                    onClick={handleTeamsChange}
                />
                <div ref={teamsMenuRef}>
                    <TeamsEditMenu
                        isOpen={teamsEditMenu}
                        handleTeamsChange={handleTeamsChange}
                        setActionSelected={setActionSelected}
                        onlyAddMenuItem={teams.leading.length === 0}
                    />
                </div>
            </>
        );
    };

    const renderTeamMenu = team => {
        const hasPersons = team.followerPersons && team.followerPersons.length !== 0;
        return (
            <TeamEditMenu
                teamId={team.id}
                handleMenuClick={handleTeamMenuClick}
                hasPersons={hasPersons}
            />
        );
    };

    const [isSearching, setIsSearching] = useState(false);
    const handleTeamMenuClick = action => {
        setTeamMenuAction(action);
        setEditTeam(teams.leading.find(x => x.id === action.teamId));
        action.action === 'add' && setIsSearching(true);
    };

    const defaultAcceptPageParams = {
        title: '',
        acceptAction: () => { }
    };
    const [acceptPage, setAcceptPage] = useState(defaultAcceptPageParams);
    const [isAcceptPage, setIsAcceptPage] = useState(false);

    const handleTeamsMenuAction = team => () => {
        switch (actionSelected) {
            case DELETE_MENU_ID:
                onDeleteTeam(team);
                break;
            default:
                break;
        }
    };

    const teamNameIsValid = (name) => {
        if (isNullOrWhitespace(name)) {
            showWarningAlert(`Не заполнено поле "Название команды"`);
            return false;
        }

        if (name && name.length > 250) {
            showWarningAlert(`Значения поля "Название команды" превышает 250 символов`);
            return false;
        }

        return true;
    };

    const onAddTeam = async teamName => {
        const newTeam = {
            id: uuid(),
            name: teamName,
            leaderPerson: { id: userId },
            followerPersons: [],
        };

        if (!teamNameIsValid(teamName)) {
            return;
        }

        await handleTeamSave(newTeam, 'new');
        
        clearTeamsActions();
    };

    const onRenameTeam = async () => {
        if (teamTitles && teamTitles.length !== 0) {
            for (const x of teamTitles) {
                if (!teamNameIsValid(x.title)) {
                    return;
                }
            }

            const ids = [];
            let res = {};
            for (const t of teamTitles) {
                const selectedTeam = teams.leading.find(x => x.id === t.teamId);
                const changedTeam = selectedTeam && { ...selectedTeam, name: t.title };
                res = changedTeam && await handleTeamSave(changedTeam, 'list');
                res && res.data && ids.push({ id: t.teamId, concurrencyId: res.data.concurrencyId, name: t.title });
            }
            
            if (res && res.data) {
                await setTeams(
                    {
                        ...teams,
                        leading: [...teams.leading.map(x => (
                                {
                                    ...x,
                                    name: ids.find(i => i.id === x.id)?.name,
                                    concurrencyId: ids.find(i => i.id === x.id)?.concurrencyId,
                                }
                            )
                        )
                        ],
                    }
                );
                showSuccessAlert('Команда(ы) успешно переименована(ы)');
                clearTeamsActions();
            }
        }
    };

    const [teamsToDelete, setTeamsToDelete] = useState([]);
    const onDeleteTeam = team => {
        !teamsToDelete.find(x => x.id === team.id) && setTeamsToDelete([...teamsToDelete, team]);
    };

    const approveTeamsDelete = () => {
        teamsToDelete.length !== 0 && setIsAcceptPage(true);
        setAcceptPage(
            {
                title: `Вы действительно хотите удалить ${teamsToDelete.length === 1 ? 'команду' : 'команды'}:  ${teamsToDelete.map(x => (` "${x.name}"`))}?`,
                acceptAction: deleteTeams,
            }
        )
    };

    const deleteTeams = async () => {
        try {
            for (const team of teamsToDelete) {
                await deleteTeamApi(team.id, true);
            }

            const newLeadingTeams = teams.leading.filter(x => {
                return !teamsToDelete.find(t => t.id === x.id) && { ...x };
            });

            await setTeams({
                ...teams,
                leading: newLeadingTeams,
            });

            clearTeamsActions();
            showSuccessAlert(`${teamsToDelete.length > 1 ? 'Команды успешно удалены' : 'Команда успешно удалена'} `);
        } catch (error) {
            showErrorAlert('Произошла непредвиденная ошибка при удалении команды');
        }
    }

    const handleTeamNameChange = (teamId, teamName) => {
        const newTeamTitles = teamTitles.map(x => {
            return x.teamId === teamId
                ? { ...x, title: teamName ? teamName.slice(0, TEAM_NAME_LIMIT) : teamName }
                : { ...x }
        })
        setTeamTitles(newTeamTitles)
    };

    const renderTeam = (team, { myTeam, action, menu }) => {
        const withDND = teamMenuAction && team.id === teamMenuAction.teamId && teamMenuAction.action === 'move' && myTeam;
        const isEditTeam = teamMenuAction && team.id === teamMenuAction.teamId;
        const personsInTeam = myTeam
            ? team.followerPersons.length
            : team.followerPersons.length + 1;
        const teamTitle = team.name ? `${team.name} (${personsInTeam})` : `Команда (${personsInTeam})`;

        const dataCardProps = {
            key: team.id,
            title: teamTitle,
            titleModifire: "accordion",
            action: action === 'delete' && action,
            handleAction: handleTeamsMenuAction(team),
            className:"LKDataCard--Line"
        };

        if (action) { dataCardProps.isOpen = false }
        return (
            action === 'edit'
                ?
                <InfoField
                    noIcon key={team.id}
                    isEdit
                    title='Название команды'
                    value={teamTitles.find(x => x.teamId === team.id).title}
                    onChange={e => handleTeamNameChange(team.id, e)}
                />
                :
                !team.isSelectedForDelete &&
                <DataCard {...dataCardProps}>
                    {!teamMenuAction && menu}
                    {renderTeamPersons(isEditTeam ? editTeam : team, withDND, myTeam)}
                </DataCard>
        );
    };

    const renderTeamPersons = (team, withDND = false, myTeam = false) => {
        if (team.followerPersons && team.followerPersons.length !== 0) {
            return withDND
                ?
                (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId='TeamId'>
                            {(provided) => (
                                <div className='LKDataCard__Content' {...provided.droppableProps} ref={provided.innerRef}>
                                    {editTeam.followerPersons.map((x, index) => (
                                        renderTeamPerson(x, team.id, withDND, index)
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                )
                :
                [
                    !myTeam && renderTeamPerson(team.leaderPerson, team.id, false, -1),
                    team.followerPersons.map((x, index) => (
                        renderTeamPerson(x, team.id, withDND, index)
                    ))
                ];
        } else {
            return <div className='LKMainTeams__NoTeamData LKMainTeams__NoTeamData--noBottomBorder'>В этой команде пока никого нет</div>
        }
    };

    const getActionIcon = teamId => {
        if (teamMenuAction && teamId === teamMenuAction.teamId && teamMenuAction.action !== 'add') {
            return teamMenuAction.action;
        }
        return '';
    };

    const [modalSummary, setModalSummary] = useState(false);
    const openSummaryCard = person => () => setModalSummary(person);
    const closeSummaryCard = () => setModalSummary(false);

    const renderTeamPerson = (person, teamId, withDND = false, index) => {
        const actionIcon = getActionIcon(teamId);

        const isUser = !!person.userId;
        
        return withDND
            ?
            <Draggable draggableId={person.id} index={index} key={person.id}>
                {(provided) => (
                    <div ref={provided.innerRef}  {...provided.draggableProps} {...provided.dragHandleProps}>
                        <PersonCard draggable>
                            <PersonCard.CardAvatar id={isUser ? person.userId : person.id} isUser={isUser}/>
                            <PersonCard.CardInfo>
                                <PersonCard.CardTitle title={getUserShortName(person)}/>
                                <PersonCard.CardInfoText text={person.currentJobTitle} />
                            </PersonCard.CardInfo>
                            <PersonCard.CardAction icon={actionIcon} onClick={handleActionClick(person.id)} />
                        </PersonCard>
                    </div>
                )}
            </Draggable>
            :
            (
                <React.Fragment key={person.id}>
                    {index === -1 && <div className='LKMainTeamPersonPrefix'>Руководитель команды</div>}
                    {index === 0 && <div className='LKMainTeamPersonPrefix'>Состав команды</div>}
                    <PersonCard onClick={!actionIcon ? openSummaryCard(person): undefined}>
                        <PersonCard.CardAvatar id={isUser ? person.userId : person.id} isUser={isUser}/>
                        <PersonCard.CardInfo>
                            <PersonCard.CardTitle title={getUserShortName(person)}/>
                            <PersonCard.CardInfoText text={person.currentJobTitle} />
                        </PersonCard.CardInfo>
                        {actionIcon && <PersonCard.CardAction icon={actionIcon} onClick={handleActionClick(person.id)} />}
                    </PersonCard>
                </React.Fragment>
            );
    };

    const handleSelected = cart => {
        const copyTeamPersons = [...editTeam.followerPersons];
        const result = [];
        const map = new Map();
        for (const item of [...copyTeamPersons, ...cart]) {
            if (!map.has(item.id)) {
                map.set(item.id, true);
                result.push({
                    ...item
                });
            }
        }
        const updatedTeam = { ...editTeam, followerPersons: result }
        setEditTeam(updatedTeam);
        setIsSearching(false);
    };

    const handleCancelSelect = () => {
        setTeamMenuAction();
        setIsSearching(false);
    };

    const renderSearch = () => {
        return <CommonSearchCard 
                    setActiveCard={setActiveCard} 
                    activeCard={activeCard} 
                    handleSelected={handleSelected} 
                    handleCancelSelect={handleCancelSelect}
                    editTeam={editTeam}
                    fromProfile={fromProfile}
                />;
    };

    const renderTeamsBottomMenu = () => {
        return actionSelected && (
            <div className='LKMainTeamsBottomAction'>
                <div className='LKMainTeamsBottomAction__ActionButton LKMainTeamsBottomAction__ActionButton--red' onClick={() => clearTeamsActions(true)}>
                    Отменить
                </div>
                {renderAcceptTeamsMenuButton()}
            </div>
        )
    };

    const renderAcceptTeamsMenuButton = () => {
        return (
            <>
                |
                <div onClick={!teamsChanging ? onChangeTeams : () => { }}
                    className={classnames('LKMainTeamsBottomAction__ActionButton LKMainTeamsBottomAction__ActionButton--blue', {
                        'LKMainTeamsBottomAction__ActionButton--disabled': teamsChanging || (actionSelected === 'deleteTeam' && teamsToDelete.length === 0)
                    })}>
                    Сохранить
                </div>
            </>
        );
    };

    const renderTeamAcceptMenu = () => {
        return teamMenuAction
            ?
            <TeamAcceptMenu
                allTeams={teams}
                editTeam={editTeam}
                setEditTeam={setEditTeam}
                handleAcceptClick={handleTeamSave}
                show={teamMenuAction}
                setTeamMenuAction={setTeamMenuAction}
            />
            : null;
    };

    return (
            <>
                <div className={`LKMainTeams__Wrapper ${isSearching ? 'LKMainTeams__Wrapper--hidden' : ''} ${fromProfile ? 'LKMainTeams__Wrapper--fromProfile' : ''}`}>
                    <div>
                        <CardItemExpert initAllInfo useShortInfo/>
                    </div>
                    {teams.loading || teamsChanging || !teams.isLoaded
                        ? <Loader />
                        :
                        <>
                            {teamIsChanging ? <Loader /> : null}
                            <div className={teamIsChanging ? 'LKMainTeams--hidden' : 'LKMainTeams'}>
                                {renderTeamsBlock(true)}
                                {teams.following.length !== 0 && renderTeamsBlock()}
                                <div className={classnames('LKMainTeamsCategory', {'LKMainTeamsCategory--hidden':  actionSelected || teamMenuAction})}>
                                    <div className='LKMainTeamsCategory__Name'>
                                        Подборки
                                    </div>
                                </div>
                                <div className={classnames('LKMainTeams__Team', {'LKMainTeams__Team--hidden': actionSelected || teamMenuAction})}>
                                    <WishList {...props} existScrollWrapper={true} personProfileUrl={lkTeamsProfile}/>
                                </div>
                            </div>

                        </>
                    }
                </div>
                { (!isSearching && (teamMenuAction || actionSelected)) && <StickyFooter>
                    {actionSelected && renderTeamsBottomMenu()}
                    {teamMenuAction && renderTeamAcceptMenu()}
                </StickyFooter> }
                {isSearching && renderSearch()}
                <AcceptPage 
                    show={isAcceptPage} 
                    title={acceptPage.title} 
                    acceptAction={acceptPage.acceptAction} 
                    setIsAcceptPage={setIsAcceptPage} 
                />
                <ModalSummaryCard
                    isOpen={!!modalSummary}
                    onClose={closeSummaryCard}
                    person={modalSummary}
                    profileRoute={lkTeamsProfile}
                />
            </>
    );
};

const mapStateToProps = state => {
    return {
        teams: state.teams,
        userId: state.auth.user.personId,
    }
};

const actions = { fetchTeams, setTeams, clearTeams, showErrorAlert, showWarningAlert, showSuccessAlert };

export default connect(mapStateToProps, actions)(LeaderTeams);