import React, { useState } from 'react';
import List from './List';

export const Item = props => {
    const { item, onClick, labelField } = props;
    const { fields } = item;
    const hasChildren = fields && fields.length > 0;

    const [isOpen, setOpenState] = useState(false);

    const handleToggle = e => {
        e.stopPropagation();
        setOpenState(!isOpen);
    };

    const handleClick = () => {
        onClick(item);
    };

    return hasChildren ? (
        <>
            <li onClick={handleToggle}>
                <span>{isOpen ? `-` : `+`}</span>
                <span>{item[labelField]}</span>
            </li>
            {isOpen && <List labelField={labelField} items={fields} onClick={onClick} />}
        </>
    ) : (
        <>
            <li onClick={handleClick}>
                <span>{item[labelField]}</span>
            </li>
        </>
    );
};

export default Item;
