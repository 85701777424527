import Button from "components/uikit/Button";
import Input from "components/uikit/Input";
import PropTypes from "prop-types";
import classnames from "classnames";
import React from 'react';
import "./SearchLine.scss";

function SearchLine(props) {
    const { 
        value, 
        className,
        size,
        onChangeValue, 
        onSubmit, 
    } = props;

    function handleSubmit(e) {
        e.preventDefault();

        onSubmit();
    }

    function handleChangeValue(e) {
        onChangeValue(e.target.value)
    }

    function handleSearchFieldKeyPress(e) {
        if (e.key === 'Enter') {
            e.preventDefault();

            onSubmit();
        }
    }

    const finalClass = classnames("SearchLine", className, {
        "SearchLine_Large": size === "lg",
    });

    return (
        <div className={finalClass}>
            <form onSubmit={handleSubmit}>
                <div className="kr_input_wrap">
                    <Input 
                        name="Name" 
                        type="search" 
                        aria-label="Поиск" 
                        value={value} 
                        onChange={handleChangeValue} 
                        onKeyPress={handleSearchFieldKeyPress} 
                    />
                    <Button>
                        {size === "lg" ? "Найти" : ""}
                    </Button>
                </div>
            </form>
        </div>
    );
}

SearchLine.propTypes = {
    value: PropTypes.string.isRequired,
    className: PropTypes.string,
    size: PropTypes.oneOf(["lg", "sm"]),
    onChangeValue: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

SearchLine.defaultProps = {
    className: "",
    size: "sm",
};

export default SearchLine;
