import React from 'react';
import Popover from 'components/Lk/Uikit/Popover';
import InputText from 'components/uikit/InputText';
import Input from 'components/uikit/Input';
import Label from 'components/uikit/Label';
import Field from 'components/uikit/Field';
import './TargetCreation.scss';
import Row from 'components/uikit/Row';

const tooltipTitle = `Перед формулированием цели задайте резервисту вопросы: Какие цели стоят
                        перед ним в этом году? Чего он хочет добиться через 1 год?`;

const tooltipTarget = `Опишите образ конечного результата, как резервист его себе
                        представляет? Вопросы, которые можно задать: Что я делаю,
                        когда цель достигнута? Что я умею нового? Над какими
                        проектами работаю? Какую должность занимаю?`;

export const TargetCreationTitle = () => {
    return (
        <div>
            <div className="targetCreation__container__title">
                <Popover tooltip={tooltipTitle}>
                    <div
                        className="tooltip-link"
                        data-tip
                        data-for="socialActivityHint"
                        style={{ fontSize: 24 + 'px' }}
                    >
                        ?
                    </div>
                </Popover>
            </div>
        </div>
    );
};

const TargetCreation = props => {
    return (
        <div className="targetCreation">
            <div className="targetCreation__container">
                <div className="targetCreation__container__row">
                    <div className="targetCreation__container__row__item">
                        <Field
                            required
                            noLabel
                            invalid={props.isSubmited && !props.isValid}
                            filled={props.isValid}
                        >
                            <Input
                                id="targetInfo"
                                maxLength={4000}
                                value={props.title}
                                onChange={e => {
                                    props.onChange('title', e.target.value);
                                }}
                                rows={1}
                            />
                        </Field>
                    </div>
                </div>
            </div>
            <div className="targetCreation__container">
                <div className="targetCreation__container__row">
                    <div className="targetCreation__container__row__item">
                        <Label htmlFor="targetKind" className="targetCreation__label">
                            Цель должна быть:
                        </Label>
                        <div className="targetCreation__additionalInfo">
                            Конкретная, достижимая, измеримая, реалистичная, значимая, определенная
                            по времени
                        </div>
                    </div>
                </div>
            </div>
            <div className="resultDescription">
                <div className="resultDescription__title">
                    <span className="resultDescription__title-text">
                        Описание конечного результата:
                    </span>
                    <div className="resultDescription__tooltip-container">
                        {!props.editMode && (
                            <>
                                <Popover tooltip={tooltipTarget}>
                                    <div
                                        className="tooltip-link"
                                        data-tip
                                        data-for="resultDescriptionHint"
                                    >
                                        ?
                                    </div>
                                </Popover>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Row>
                <Field size="full">
                    <div className="targetCreation__container">
                        <div className="targetCreation__container__row">
                            <InputText
                                maxLength={4000}
                                value={props.expectedResult}
                                onChange={e => props.onChange('expectedResult', e.target.value)}
                                rows={6}
                            />
                        </div>
                    </div>
                </Field>
            </Row>
        </div>
    );
};

export default TargetCreation;
