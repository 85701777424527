import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/Lk/Common/Avatar';
import classnames from 'classnames';
import ModalSummaryCard from 'components/Lk/Common/ModalPersonSummaryCard';
import IconButton from 'components/Lk/Uikit/Buttons/IconButton';
import ChevronButton from 'components/Lk/Uikit/Buttons/ChevronButton';
import './CardItemExpertData.scss';

const CardItemExpertData = props => {
    const {
        clickable,
        curator = {},
        profileRoute,
        profileUrl,
        isUser,
        data,
        position,
        useShortInfo,
        initAllInfo,
    } = props;

    const [isOpenModalSummary, setIsOpenModalSummary] = useState(false);
    const [isAllInfo, setAllInfo] = useState(initAllInfo);

    const closeSummaryCard = () => setIsOpenModalSummary(false);

    const isOnClick = () => {};

    const handleCardClick = e => {
        if (useShortInfo) {
            handleToggle(e);
        }
    };

    const handleSummaryCardOpen = e => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpenModalSummary(true);
    };

    const sendEmail = useCallback(() => {
        window.location = `mailto:${curator.email}`;
    }, [curator]);

    const callPhone = useCallback(() => {
        window.location = `tel:${curator.phone}`;
    }, [curator]);

    const buttons = useMemo(
        () =>
            curator && [
                { id: 2, type: 'phone', value: curator.phone, onClick: callPhone },
                { id: 1, type: 'mail', value: curator.email, onClick: sendEmail },
                { id: 3, type: 'etc' },
            ],

        [curator, sendEmail, callPhone],
    );

    const handleToggle = e => {
        e.preventDefault();
        e.stopPropagation();
        setAllInfo(!isAllInfo);
    };

    const ImgClassName = classnames('CardItemExpertData__Img', {
        'CardItemExpertData__Img--small': !isAllInfo,
    });

    return (
        <>
            <div
                className={classnames('CardItemExpertData', {
                    'CardItemExpertData--clickable': clickable,
                    'CardItemExpertData--all-info': isAllInfo,
                })}
                data-person-id={curator.id}
            >
                <div
                    className={classnames('CardItemExpertData__Info', {
                        'CardItemExpertData__Info--paddingBottom': isAllInfo,
                    })}
                    onClick={clickable ? handleCardClick : null}
                >
                    <div className={ImgClassName}>
                        <Avatar person={curator} isUser={isUser} size={isAllInfo ? 'sm' : 'xs'} />
                    </div>
                    <div className="CardItemExpertData__Content" onClick={isOnClick}>
                        {data && isAllInfo && (
                            <div className="CardItemExpertData__Content--Name">{data}</div>
                        )}
                        <div className="CardItemExpertData__Content--ShortInfo">
                            <div className="CardItemExpertData__Content--Position">{position}</div>
                            {useShortInfo && (
                                <ChevronButton
                                    isUp={isAllInfo}
                                    onClick={handleToggle}
                                    data-test={`Expert_Chevron--button`}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {isAllInfo && (
                    <div className="CardItemExpertData__Buttons">
                        <span
                            className="CardItemExpertData__Contact"
                            onClick={handleSummaryCardOpen}
                        >
                            Связаться
                        </span>
                        <IconButton type="etc" />
                    </div>
                )}
            </div>
            <ModalSummaryCard
                isOpen={isOpenModalSummary}
                onClose={closeSummaryCard}
                person={curator}
                profileRoute={profileRoute}
                profileUrl={profileUrl}
                isUser={isUser}
                buttons={buttons}
            />
        </>
    );
};

CardItemExpertData.propTypes = {
    checkbox: PropTypes.bool,
    children: PropTypes.any,
    onClick: PropTypes.func,
};

export default CardItemExpertData;
