import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Constructor.scss';
import ConstructorItem from './ConstructorItem';
import { fetchCategories } from 'ducks/ExternalSearch';
import ConditionModal from './ConditionModal';

const Constructor = props => {
    const { categories, fetchCategories } = props;
    const [activeFilters, changeFilters] = useState({});
    const [editItem, setEditItem] = useState(null);

    useEffect(() => {
        if (categories.length <= 0) {
            fetchCategories();
        }
    }, [fetchCategories, categories]);

    const showModal = item => {
        setEditItem(item);
    };
    const hideModal = () => {
        setEditItem(null);
    };

    const onSave = items => {
        changeFilters({ ...activeFilters, [editItem.id]: items });

        hideModal();
    };

    const renderFilters = () => {
        return categories.map(category => (
            <div key={category.id} className="SearchConstructor__Item">
                <ConstructorItem
                    data={category}
                    onShowModal={showModal}
                    count={
                        activeFilters[category.id] &&
                        JSON.stringify(activeFilters[category.id]).length
                    }
                />
            </div>
        ));
    };

    return (
        <div className="SearchConstructor">
            <div className="SearchConstructor__List">{renderFilters()}</div>

            {editItem && (
                <ConditionModal
                    isOpen={!!editItem}
                    onSave={onSave}
                    onClose={hideModal}
                    data={activeFilters[editItem.id]}
                    filters={editItem}
                />
            )}
        </div>
    );
};

Constructor.propTypes = {
    searchCategories: PropTypes.array,
};

const mapStateToProps = ({ externalSearch }) => ({
    categories: externalSearch.categories,
});

export default connect(mapStateToProps, { fetchCategories })(Constructor);
