import React, { useState, useEffect } from 'react';
import StickyBottomAcceptMenu from 'components/Lk/Uikit/Buttons/StickyBottomAcceptMenu';
import './TeamAcceptMenu.scss';

const TeamAcceptMenu = props => {
    const { editTeam, setEditTeam, handleAcceptClick, show = false, setTeamMenuAction } = props;
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(show);
    }, [show]);

    const onAcceptClick = () => {
        handleAcceptClick(editTeam);

        setEditTeam({});
        setTeamMenuAction();
    };

    const onCancelClick = () => {
        setIsOpen(false);
        setEditTeam({});
        setTeamMenuAction();
    };

    const MenuSetup = {
        separator: ' | ',
        btns: [
            {
                title: 'Отменить',
                onClick: onCancelClick,
                type: 'Cancel',
            },
            {
                title: 'Применить',
                onClick: onAcceptClick,
                type: 'Accept',
            },
        ]
    }

    return ( isOpen && 
        <StickyBottomAcceptMenu {...MenuSetup} />
    );
};

export default TeamAcceptMenu;