import { push } from 'connected-react-router';
import memoize from 'fast-memoize';
import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { withRouter } from 'react-router-dom';
import AdminRoutes from 'components/Admin/AdminRoutes';
import AccountWizard from 'components/Auth/AccountWizard';
import SignIn from 'components/Auth/SignIn';
import Analytics from 'components/Analytics';
import AcceptModal from 'components/common/AcceptModal';
import AdaptiveProvider from 'components/common/AdaptiveProvider';
import Favorites from 'components/Favorites';
import FullTextSearch from 'components/FullTextSearch';
import Home from 'components/Home';
import Users from 'components/Admin/Users';
import Import from 'components/Import';
import Instructions, {
    InstructionsEdit as InstructionsEditComponent,
} from 'components/Instructions';
import Layout from 'components/Layout';
import NotFound from 'components/NotFound';
import PersonRoutes from 'components/Person/PersonRoutes';
import PersonGrowthResourcesRoutes from 'components/Moderation/PersonGrowthResources/PersonGrowthResourcesRoutes';
import PersonGrowthResourceRoutes from 'components/Moderation/PersonGrowthResource/PersonGrowthResourceRoutes';
import ModerationWelcome from 'pages/manager/moderation/ModerationWelcome';
import QuotasRoutes from 'components/Moderation/QuotasRoutes';
import TemplatesRoutes from 'components/Moderation/Templates/TemplatesRoutes';
import EvaluationsRoutes from 'components/Moderation/EvaluationsRoutes';
import CompetenceModelsRoutes from 'components/Moderation/CompetenceModelsRoutes';
import PrivateRoute from 'components/hoc/PrivateRoute';
import Reports from 'components/Reports';
import Restricted from 'components/Restricted';
import Search from 'components/Search';
import Forbidden from 'components/Forbidden';
import EvolutionPlan from 'components/PersonGrowthPlan';
import { fetchConfig, isResourceProvidersEnabled } from 'ducks/Config';
import { goToPage } from 'ducks/Logger';
import {
    isLeader,
    isInstructionEditor,
    isContentEditor,
    canEditInstruction,
    isSelectModeratorInterface,
    isSelectLeaderInterface,
    canEditReport,
    isModerator,
    isOperator,
    isAdmin,
} from 'rightsController';
import UnderConstruction from 'pages/common/UnderConstruction';
import PersonGrowthTagsRoutes from 'components/Moderation/PersonGrowthTags/PersonGrowthTagsRoutes';
import PersonGrowthTagRoutes from 'components/Moderation/PersonGrowthTag/PersonGrowthTagRoutes';
import ResourceProviders from 'components/Moderation/ResourceProviders/ResourceProviders';
import ResourceProvider from 'components/Moderation/ResourceProvider/ResourceProvider';
import TemplatesTypesRoutes from 'components/Moderation/TemplatesTypes/TemplatesTypesRoutes';
import Issues from 'pages/manager/moderation/Issues/Issues';
import IssueEdit from 'pages/manager/moderation/Issues/IssueEdit';
import News from 'pages/manager/moderation/News/News';
import NewsEdit from 'pages/manager/moderation/News/NewsEdit';
import Profile from 'pages/leader/Profile/Profile';
import Team from 'pages/leader/Team/Team';
import EmailLists from 'pages/manager/moderation/MassEmail/Lists';
import Email from 'pages/manager/moderation/MassEmail/Email';
import Campaign from 'pages/manager/moderation/MassEmail/Email/Campaign';

import {
    accountWizard,
    analytics,
    profile,
    favorites,
    fullTextSearch,
    home,
    users,
    importRoute,
    instructionsRoute,
    forbidden,
    notFound,
    reports,
    restricted,
    search,
    signIn,
    evolutionPlan,
    moderationWelcomeRoute,
    quotasRoute,
    adminSignIn,
    evaluationsModerationRoute,
    personGrowthResourcesRoute,
    competenceModelsRoute,
    personGrowthResourceRoute,
    underconstructionRoute,
    testsRoute,
    personGrowthTagsRoute,
    templatesRoute,
    personGrowthTagRoute,
    profileTeams,
    listManagers,
    masterSearch,
    resourceProvidersRoute,
    resourceProviderRoute,
    issuesRoute,
    issuesEditRoute,
    templatesTypesRoute,
    newsRoute,
    newsEditRoute,
    instructionsEdit,
    instructionsView,
    instructionsOldRoute,
    profileRoute,
    evolutionEditPlan,
    interfaceSelectorRoute,
    massEmailLists,
    newMassEmail,
    editMassEmail,
    InstructionsViewFromEdit,
    teamReport,
    massEmailCampaign,
} from 'routes';
import TestsRoutes from 'components/Moderation/Tests/TestsRoutes';

import { Home as LeaderHome } from 'pages/leader/Home';
import { Layout as LeaderLayout } from 'pages/leader/Layout';
import { leaderMenuItems } from 'constants.js';
import MasterSearchRoutes from 'components/MasterSearch/MasterSearchRoutes';
import ListManagers from 'pages/manager/moderation/ListManagers';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { signOut, initHeartBeat, setIdle } from 'ducks/Auth';

import { Layout as LKLayout } from 'pages/lk/Layout';

import { LastLocationProvider } from 'react-router-last-location';
import InstructionView from './Instructions/View/InstructionView';
import InstructionsOld from './Instructions/InstructionsOld';
import UserProfile from './UserProfile';
import InterfaceSelector from './InterfaceSelector';
import TeamReport from './Moderation/TeamReport';

class App extends Component {
    componentDidMount() {
        this.props.initHeartBeat();
        this.loadConfig();
        this.props.goToPage({}, this.props.location);
    }

    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(this.props.location) !== JSON.stringify(prevProps.location) &&
            this.props.isAuthenticated
        ) {
            this.props.goToPage(prevProps.location, this.props.location);
        }
        this.loadConfig();
    }

    loadConfig = () => {
        if (!this.props.config && !this.props.loading && this.props.isAuthenticated) {
            this.props.fetchConfig();
        }
    };

    renderComponent = (route, Component, withLayout = true, isLeader = false, props) => {
        if (withLayout) {
            return !isLeader ? (
                <Layout>{Component && <Component {...route} {...props} />}</Layout>
            ) : (
                <LeaderLayout items={leaderMenuItems}>
                    {Component && <Component {...route} {...props} />}
                </LeaderLayout>
            );
        }

        return <Component route={route} {...props} />;
    };

    renderSuperAdminRoutes = () => {
        return (
            <Switch>
                <PrivateRoute
                    path={home.url}
                    render={(route) => this.renderComponent(route, Users)}
                    exact
                    redirectUrl={users.url}
                />
                <PrivateRoute
                    path="/admin"
                    render={(route) => this.renderComponent(route, AdminRoutes)}
                />
                <PrivateRoute
                    path={profileRoute.url}
                    render={(route) => this.renderComponent(route, UserProfile)}
                />
                <PrivateRoute
                    path={instructionsOldRoute.url}
                    render={(route) => this.renderComponent(route, InstructionsOld)}
                />
                <PrivateRoute render={(route) => this.renderComponent(route, NotFound)} />
            </Switch>
        );
    };

    renderLeaderRoutes = () => {
        return (
            <Switch>
                <PrivateRoute
                    path={home.url}
                    render={(route) => this.renderComponent(route, LeaderHome, true, true)}
                    exact
                />
                <PrivateRoute
                    path={masterSearch.url}
                    render={(route) => this.renderComponent(route, MasterSearchRoutes, true, true)}
                />
                <PrivateRoute
                    path={analytics.url}
                    render={(route) => this.renderComponent(route, Analytics, true, true)}
                />
                <PrivateRoute
                    path={profileTeams.url}
                    render={(route) => this.renderComponent(route, Team, true, true)}
                />
                <PrivateRoute
                    path={profile.url}
                    render={(route) => this.renderComponent(route, Profile, true, true)}
                />
                <PrivateRoute
                    path={instructionsOldRoute.url}
                    render={(route) => this.renderComponent(route, InstructionsOld)}
                />
                <PrivateRoute
                    path="/person"
                    render={(route) => this.renderComponent(route, PersonRoutes, true, true)}
                />
                <PrivateRoute
                    path={underconstructionRoute.url}
                    exact
                    render={(route) => this.renderComponent(route, UnderConstruction, true, true)}
                />
                <PrivateRoute
                    render={(route) => this.renderComponent(route, NotFound, true, true)}
                />
            </Switch>
        );
    };

    renderContentEditorRoutes = () => {
        return (
            <Switch>
                <PrivateRoute
                    path={home.url}
                    render={(route) => this.renderComponent(route, Home, testsRoute)}
                    exact
                />
                {canEditInstruction(this.props.auth.user) && (
                    <PrivateRoute
                        path={instructionsEdit.url}
                        render={(route) =>
                            this.renderComponent(route, InstructionsEditComponent, true)
                        }
                    />
                )}
                <PrivateRoute
                    path={instructionsOldRoute.url}
                    render={(route) => this.renderComponent(route, InstructionsOld)}
                />
                <PrivateRoute
                    path={issuesRoute.url}
                    exact
                    render={(route) => this.renderComponent(route, Issues)}
                />
                <PrivateRoute
                    path={issuesEditRoute.url}
                    render={(route) => this.renderComponent(route, IssueEdit)}
                />
                <PrivateRoute
                    path={profileRoute.url}
                    render={(route) => this.renderComponent(route, UserProfile)}
                />
                <PrivateRoute
                    path={underconstructionRoute.url}
                    exact
                    render={(route) => this.renderComponent(route, UnderConstruction, true)}
                />
                <PrivateRoute render={(route) => this.renderComponent(route, NotFound, true)} />
            </Switch>
        );
    };

    renderDisabledUserRoutes = () => {
        return (
            <Switch>
                <PrivateRoute
                    path={home.url}
                    render={(route) => this.renderComponent(route, Forbidden)}
                    exact
                />
            </Switch>
        );
    };

    renderModerationRoutes = () => {
        const { resourceProvidersEnabled } = this.props;
        return (
            <Switch>
                <PrivateRoute
                    exact
                    path={moderationWelcomeRoute.url}
                    render={(route) => this.renderComponent(route, ModerationWelcome)}
                />
                <PrivateRoute
                    path={templatesRoute.url}
                    render={(route) => this.renderComponent(route, TemplatesRoutes)}
                />
                <PrivateRoute
                    path={templatesTypesRoute.url}
                    render={(route) => this.renderComponent(route, TemplatesTypesRoutes)}
                />
                <PrivateRoute
                    path={quotasRoute.url}
                    render={(route) => this.renderComponent(route, QuotasRoutes)}
                />
                <PrivateRoute
                    path={evaluationsModerationRoute.url}
                    render={(route) => this.renderComponent(route, EvaluationsRoutes)}
                />
                <PrivateRoute
                    path={personGrowthResourcesRoute.url}
                    render={(route) => this.renderComponent(route, PersonGrowthResourcesRoutes)}
                />
                <PrivateRoute
                    path={personGrowthResourceRoute.url}
                    render={(route) => this.renderComponent(route, PersonGrowthResourceRoutes)}
                />
                {resourceProvidersEnabled && (
                    <PrivateRoute
                        exact
                        path={resourceProvidersRoute.url}
                        render={(route) => this.renderComponent(route, ResourceProviders)}
                    />
                )}
                {resourceProvidersEnabled && (
                    <PrivateRoute
                        path={resourceProviderRoute.url}
                        render={(route) => this.renderComponent(route, ResourceProvider)}
                    />
                )}
                <PrivateRoute
                    path={competenceModelsRoute.url}
                    render={(route) => this.renderComponent(route, CompetenceModelsRoutes)}
                />
                <PrivateRoute
                    path={testsRoute.url}
                    render={(route) => this.renderComponent(route, TestsRoutes)}
                />
                {canEditReport(this.props.auth.user) && (
                    <PrivateRoute
                        path={teamReport.url}
                        render={(route) => this.renderComponent(route, TeamReport)}
                    />
                )}

                <PrivateRoute
                    path={evolutionPlan.url}
                    render={(route) => this.renderComponent(route, EvolutionPlan)}
                />
                <PrivateRoute
                    path={evolutionEditPlan.url}
                    render={(route) => this.renderComponent(route, EvolutionPlan)}
                />
                <PrivateRoute
                    path={personGrowthTagsRoute.url}
                    render={(route) => this.renderComponent(route, PersonGrowthTagsRoutes)}
                />
                <PrivateRoute
                    path={personGrowthTagRoute.url}
                    render={(route) => this.renderComponent(route, PersonGrowthTagRoutes)}
                />
                <PrivateRoute
                    path={listManagers.url}
                    render={(route) => this.renderComponent(route, ListManagers)}
                />
                <PrivateRoute
                    path={newsRoute.url}
                    exact
                    render={(route) => this.renderComponent(route, News)}
                />
                <PrivateRoute
                    path={newsEditRoute.url}
                    render={(route) => this.renderComponent(route, NewsEdit)}
                />
                <PrivateRoute
                    path={massEmailLists.url}
                    exact
                    render={(route) => this.renderComponent(route, EmailLists)}
                />
                <PrivateRoute
                    path={[newMassEmail.url, editMassEmail.url]}
                    exact
                    render={(route) => this.renderComponent(route, Email)}
                />
                <PrivateRoute
                    path={massEmailCampaign.url}
                    exact
                    render={(route) => this.renderComponent(route, Campaign)}
                />
                <PrivateRoute render={(route) => this.renderComponent(route, NotFound)} />
            </Switch>
        );
    };

    renderOperatorRoutes = () => {
        return (
            <Switch>
                <PrivateRoute
                    path={evolutionPlan.url}
                    render={(route) => this.renderComponent(route, EvolutionPlan)}
                />
                <PrivateRoute
                    path={evolutionEditPlan.url}
                    render={(route) => this.renderComponent(route, EvolutionPlan)}
                />
            </Switch>
        );
    };

    renderOrdinaryRoutes = () => {
        const { auth } = this.props;
        return (
            <Switch>
                <PrivateRoute
                    path={home.url}
                    render={(route) => this.renderComponent(route, Home)}
                    exact
                />
                <PrivateRoute
                    path="/person"
                    render={(route) => this.renderComponent(route, PersonRoutes)}
                />
                <PrivateRoute
                    path={search.url}
                    render={(route) => this.renderComponent(route, Search)}
                />
                <PrivateRoute
                    path={profileRoute.url}
                    render={(route) => this.renderComponent(route, UserProfile)}
                />
                <PrivateRoute
                    path={favorites.url}
                    render={(route) => this.renderComponent(route, Favorites)}
                />
                <PrivateRoute
                    path={fullTextSearch.url}
                    render={(route) => this.renderComponent(route, FullTextSearch)}
                />
                <PrivateRoute
                    path={analytics.url}
                    render={(route) => this.renderComponent(route, Analytics)}
                />
                <PrivateRoute
                    path={reports.url}
                    render={(route) => this.renderComponent(route, Reports)}
                />
                <PrivateRoute
                    path={importRoute.url}
                    render={(route) => this.renderComponent(route, Import)}
                />
                <PrivateRoute
                    path={instructionsOldRoute.url}
                    render={(route) => this.renderComponent(route, InstructionsOld)}
                />
                {canEditInstruction(this.props.auth.user) && (
                    <PrivateRoute
                        path={instructionsEdit.url}
                        render={(route) =>
                            this.renderComponent(route, InstructionsEditComponent, true)
                        }
                    />
                )}
                <PrivateRoute
                    path={issuesRoute.url}
                    exact
                    render={(route) => this.renderComponent(route, Issues)}
                />
                <PrivateRoute
                    path={issuesEditRoute.url}
                    render={(route) => this.renderComponent(route, IssueEdit)}
                />
                <PrivateRoute
                    path={search.url}
                    render={(route) => this.renderComponent(route, Search)}
                />
                <PrivateRoute
                    path={notFound.url}
                    render={(route) => this.renderComponent(route, NotFound)}
                />
                {isModerator(auth.user) && this.renderModerationRoutes()};
                {isOperator(auth.user) && this.renderOperatorRoutes()};
                <PrivateRoute
                    path={forbidden.url}
                    render={(route) => this.renderComponent(route, Forbidden)}
                />
                <PrivateRoute
                    path={underconstructionRoute.url}
                    exact
                    render={(route) => this.renderComponent(route, UnderConstruction)}
                />
                <PrivateRoute render={(route) => this.renderComponent(route, NotFound)} />
            </Switch>
        );
    };

    renderLKRoutes = () => {
        return (
            <Switch>
                <PrivateRoute path={home.url} render={(props) => <LKLayout {...props} />} />
            </Switch>
        );
    };

    renderIdleTimer = memoize(() => {
        const TIMEOUT_INTERVAL = 60 * 1000;

        const handleOnIdle = () => {
            if (!this.props.isIdle) {
                this.props.setIdle(true);
            }
        };

        const handleOnAction = () => {
            if (this.props.isIdle) {
                this.props.setIdle(false);
            }
        };

        return (
            <IdleTimer
                element={document}
                onIdle={handleOnIdle}
                onAction={handleOnAction}
                timeout={TIMEOUT_INTERVAL}
            />
        );
    });

    render() {
        return (
            <AdaptiveProvider>
                {this.props.config && this.renderIdleTimer(this.props.config)}
                <LastLocationProvider>
                    <Switch>
                        <Route
                            path={signIn.url}
                            render={(route) => this.renderComponent(route, SignIn, false)}
                        />
                        <Route
                            path={adminSignIn.url}
                            render={(route) => this.renderComponent(route, SignIn, false)}
                        />
                        <Route
                            path={accountWizard.url}
                            render={(route) => this.renderComponent(route, AccountWizard, false)}
                        />
                        <Route
                            path={interfaceSelectorRoute.url}
                            render={(route) =>
                                this.renderComponent(route, InterfaceSelector, false)
                            }
                        />
                        <PrivateRoute
                            path={restricted.url}
                            render={(route) => this.renderComponent(route, Restricted)}
                        />
                        <PrivateRoute
                            path={[InstructionsViewFromEdit.url]}
                            render={(route) =>
                                this.renderComponent(
                                    route,
                                    InstructionView,
                                    true,
                                    isLeader(this.props.auth.user) &&
                                        isSelectLeaderInterface(this.props.auth.user),
                                    { isEditor: isInstructionEditor(this.props.auth.user) },
                                )
                            }
                        />
                        <PrivateRoute
                            path={instructionsView.url}
                            render={(route) =>
                                this.renderComponent(
                                    route,
                                    InstructionView,
                                    true,
                                    isLeader(this.props.auth.user) &&
                                        isSelectLeaderInterface(this.props.auth.user),
                                )
                            }
                        />
                        <PrivateRoute
                            path={instructionsRoute.url}
                            exact
                            render={(route) =>
                                this.renderComponent(
                                    route,
                                    Instructions,
                                    true,
                                    isLeader(this.props.auth.user) &&
                                        isSelectLeaderInterface(this.props.auth.user),
                                )
                            }
                        />
                        <PrivateRoute
                            render={() => {
                                if (
                                    isLeader(this.props.auth.user) &&
                                    isSelectLeaderInterface(this.props.auth.user)
                                ) {
                                    return this.renderLKRoutes();
                                } else if (isAdmin(this.props.auth.user)) {
                                    return this.renderSuperAdminRoutes();
                                } else if (this.props.auth.user.isDisabled) {
                                    return this.renderDisabledUserRoutes();
                                } else if (
                                    (isContentEditor(this.props.auth.user, true) ||
                                        isInstructionEditor(this.props.auth.user, true)) &&
                                    isSelectModeratorInterface(this.props.auth.user)
                                ) {
                                    return this.renderContentEditorRoutes();
                                } else {
                                    return this.renderOrdinaryRoutes();
                                }
                            }}
                        />

                        <PrivateRoute
                            path={notFound.url}
                            render={(route) => this.renderComponent(route, NotFound)}
                        />
                    </Switch>
                </LastLocationProvider>
                <ToastContainer />
                <AcceptModal />
                <div id="fixed-window" />
            </AdaptiveProvider>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                auth: state.auth,
                isAuthenticated: !!state.auth.user,
                isIdle: state.auth.isIdle,
                config: state.config.loadComplete && state.config.data,
                loading: state.config.loading,
                resourceProvidersEnabled: isResourceProvidersEnabled(state),
            };
        },
        {
            goToPage,
            fetchConfig,
            initHeartBeat,
            signOut,
            setIdle,
            push,
        },
    )(App),
);
