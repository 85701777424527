import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { lkWidgetsBestInGroupProfile } from 'routes';
import { ACTIVE_LIST_CARD } from 'components/Lk/Basic/constants';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';
import Loader from "components/common/Loader";
import classnames from 'classnames';
import { getDashboardBestInGroup } from 'api';
import { getUserFullName } from 'utils';
import ModalSummaryCard from 'components/Lk/Common/ModalPersonSummaryCard';
import CardItemBlock from 'components/Lk/Common/CardItemBlock';
import CardItemPerson from 'components/Lk/Common/CardItemPerson';
import CardItemPersonData from 'components/Lk/Common/CardItemPerson/CardItemPersonData';
import Icons from 'components/Lk/Uikit/Icons/Icons';
import './BestInGroup.scss';
import Popover from 'components/Lk/Uikit/Popover';

const initialState = {
    data: [],
    meta: {
        pageCount: 0
    },
    isLoading: false,
};

class BestInGroup extends Component {
    _isMounted = false;
    
    state = initialState;

    fetchSearch = async () => {

        this._isMounted && this.setState({
            isLoading: true,
        });

        const result = await getDashboardBestInGroup();

        this._isMounted && this.setState({
            data: [...result.data.payload],
            meta: { ...result.data.meta },
            isLoading: false,
        });
    }

    componentDidMount() {
        this._isMounted = true;
        this.fetchSearch();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    handleCardClick = personId => () => {
        this.setState({
            summaryPersonId: personId,
            isSummaryCardOpen: true,
        });
    };

    closeSummaryCard = () => {
        this.setState({ isSummaryCardOpen: false });
    };

    renderSearchResults = () => {
        return (
            this.state.data.map(x => {
                return (
                    <CardItemBlock key={x.id}>
                        <CardItemPerson
                            person={x}
                            clickable
                            onCardClick={this.handleCardClick(x.id)}
                        >
                            <CardItemPersonData
                                normalText
                                data={getUserFullName(x)}
                                text={<span>Средний балл<b> {Math.round(x.evaluationRating)}%</b></span>}
                            />
                        </CardItemPerson>
                    </CardItemBlock>
                );
            })
        );
    };

    render() {
        const { activeCard, setActiveCard } = this.props;
        const { isLoading, data, summaryPersonId, isSummaryCardOpen } = this.state;

        const isMobileActive = activeCard === ACTIVE_LIST_CARD;
        setActiveCard(ACTIVE_LIST_CARD);
        
        return (
            <>
                <div className={classnames('ListCard LKSearchCard', { 'ListCard--isMobileActive': isMobileActive, 'LKSearchCard--isMobileActive': isMobileActive })}>
                    <MainCardMenu defaultMenuId="favorite" />
                    <div className="LKSearchCard__List">
                        <div className="LKBestInGroup">
                            <div className="LKLayout__Title">
                                <span>
                                    Лучшие резервисты в моем регионе:
                                    <Popover 
                                        childrenClassName='InlineFlex' 
                                        tooltip='Рейтинг сформирован на основании среднего балла по тестам способностей: вербальной, числовой и логической секций'
                                    >
                                        <span className="EvaluationRegionRating__Info">
                                            <Icons name="help-alt" fill={'#007AFF'} />
                                        </span>
                                    </Popover>
                                </span>
                            </div>
                            {this.renderSearchResults()}
                            {isLoading && <Loader />}
                        </div>
                    </div>
                </div>
                {summaryPersonId &&
                    <ModalSummaryCard
                        isOpen={isSummaryCardOpen}
                        onClose={this.closeSummaryCard}
                        person={data.find(x => x.id === summaryPersonId)}
                        profileRoute={lkWidgetsBestInGroupProfile}
                    />
                }
            </>
        );
    }
}

BestInGroup.propTypes = {
    activeCard: PropTypes.string.isRequired,
    setActiveCard: PropTypes.func.isRequired,
};

const actions = { push };

export default connect(null, actions)(BestInGroup);