import React, { useState, useMemo, useEffect } from 'react';
import './LinkMenu.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectMenu from '../SelectMenu';
import calssnames from 'classnames';

const LinkMenu = props => {
    const { activeId, items, onChange, isWrap, setWrap, 
            withBackGround = true, disabled = false, defaultOpen = false,
            noOptionText = 'Выбрать раздел или фото', onOpenMenu
        } = props;
    const [isOpen, setOpenState] = useState(defaultOpen);

    useEffect(() => {
        onOpenMenu(defaultOpen);
        // нужно только при первой загрузке проверить
        // eslint-disable-next-line
    }, []);

    const onChoose = id => {
        onChange(id);
        setOpenState(false);
    };

    const onClickMenu = () => {
        isWrap && setWrap(false);
        !isWrap && setOpenState(!isOpen);
        !isWrap && onOpenMenu && onOpenMenu(!isOpen);
    };

    const activeRecord = useMemo(() => items.filter(({ id }) => id === activeId)[0] || {}, [
        activeId,
        items,
    ]);

    return (
        <>
            <div className={calssnames("LinkMenuWrapper", {'LinkMenuWrapper--disabled': disabled, 'LinkMenuWrapper--open' : isOpen})}>
                <div className="ActiveMenu" onClick={disabled ? () => {} : onClickMenu}>
                    {isWrap && (
                        <FontAwesomeIcon className="ActiveMenu__Icon" icon={'chevron-left'} />
                    )}
                    <span className="ActiveMenu__Text">{activeRecord.name || noOptionText}</span>
                    {!isWrap && (
                        <FontAwesomeIcon
                            className="ActiveMenu__Icon ActiveMenu__Icon--left"
                            icon={isOpen ? 'chevron-up' : 'chevron-down'}
                        />
                    )}
                    {isOpen && (
                        <div>
                        <SelectMenu
                            className="LinkMenuWrapper__Menu"
                            onChange={onChoose}
                            items={items}
                            activeId={activeId}
                        />
                        </div>
                    )}
                </div>
            </div>
            {isOpen && withBackGround && (
                <div className="LinkMenuWrapper__BackgroundOnSelect"></div>
            )}
        </>
    );
};

export default LinkMenu;
