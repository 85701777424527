import { appName, PERESELECTION_EVALUATE_BASKET_NAME, EVALUATE_BASKET_NAME, NEW_ISSUE_BASKET_NAME, PERESELECTION_NEW_ISSUE_BASKET_NAME } from 'constants.js';
import { all,takeEvery, put, call, select } from 'redux-saga/effects';
import RequestError from '../RequestError';
import { showErrorAlert } from './Alert';

const moduleName = 'baskets';
const FETCH_REQUEST = `${appName}/${moduleName}/FETCH_REQUEST`;
const CHANGE_REQUEST = `${appName}/${moduleName}/CHANGE_REQUEST`;
const REMOVE_ITEM = `${appName}/${moduleName}/REMOVE_ITEM`;
const CLEAR_REQUEST = `${appName}/${moduleName}/CLEAR_REQUEST`;
const FETCH_START = `${appName}/${moduleName}/FETCH_START`;
const FETCH_END = `${appName}/${moduleName}/FETCH_END`;
const FETCH_ACTIVE = `${appName}/${moduleName}/FETCH_ACTIVE`;

export function fetchBaskets(basketsNames) {
    return {
        type: FETCH_REQUEST,
        payload: { basketsNames },
    };
}
export function changeBasket(basketName, basket) {
    return {
        type: CHANGE_REQUEST,
        payload: { basketName, basket },
    };
}

export function clearBasket(basketName) {
    return {
        type: CLEAR_REQUEST,
        payload: { basketName },
    };
}

export function removeItem(basketName, itemId) {
    return {
        type: REMOVE_ITEM,
        payload: { basketName, itemId },
    };
}
export function basketActive(name, status) {
    return {
        type: FETCH_ACTIVE,
        payload: { name, status },
    };
}

function fetchStart(basketName) {
    return {
        type: FETCH_START,
        payload: { basketName },
    };
}

function fetchSuccess(basketName, basket) {
    return {
        type: FETCH_END,
        payload: { basketName, basket },
    };
}

function fetchFailed(basketName, error) {
    return {
        type: FETCH_END,
        payload: { basketName, error },
        error: true,
    };
}

export const initialBasketState = {
    loading: false,
    active: false,
    basket: {
        persons: [],
        userId: [],
        selectPerson: {},
    },
};
const initialState = {
    [PERESELECTION_EVALUATE_BASKET_NAME]: initialBasketState,
    [EVALUATE_BASKET_NAME]: initialBasketState,
    [PERESELECTION_NEW_ISSUE_BASKET_NAME]: initialBasketState,
    [NEW_ISSUE_BASKET_NAME]: initialBasketState,
};

export function reducer(state, action) {
    switch (action.type) {
        case FETCH_START: {
            const { basketName } = action.payload;

            return {
                ...state,
                [basketName]: {
                    ...state[basketName],
                    loading: true,
                },
            };
        }

        case CLEAR_REQUEST: {
            const { basketName } = action.payload;
                return {
                    ...state,
                    [basketName]: initialBasketState
                };
            }

        case REMOVE_ITEM:
            const { basketName, itemId } = action.payload;
            const copyBasket = { ...state[basketName].basket };
            delete copyBasket[itemId];
            return {
                ...state,
                [basketName]: {
                    basket: { 
                        ...copyBasket,
                        persons: [...state[basketName].basket.persons.filter(x => x.person.id !== itemId)]
                    },
                },
            };

        case FETCH_ACTIVE:
            const { name, status} = action.payload;
            return {
                ...state,
                [name]: {
                    ...state[name],
                    active: status,
                },
            };
         
        case FETCH_END:
            if (!action.error) {
                const { basketName, basket } = action.payload;

                return {
                    ...state,
                    [basketName]: {
                        ...state[basketName],
                        loading: false,
                        basket,
                    },
                };
            } else {
                const { basketName } = action.payload;

                return {
                    ...state,
                    [basketName]: initialBasketState
                };
            }

        default:
            return state || initialState;
    }
}

function* fetchBasketsSaga(basketName) {
    
    const state = yield select();    
    const basketAlreadyExist = state.basket[basketName].loading || state.basket[basketName].basket;

    if (basketAlreadyExist) {
        return;
    }

    yield put(fetchStart(basketName));

    try {
        const response = [];// yield call(getBasket, basketName); пока нет вызова, коментирую
        const basket = response.data;

        yield put(fetchSuccess(basketName, basket));
    } catch (error) {
        const errorMessage = `При загрузке словаря ${basketName} произошла ошибка`;
        const requestError = new RequestError(error, errorMessage);

        yield put(fetchFailed(basketName, requestError));
        yield put(showErrorAlert(requestError.message));
    }
}

function* fetchBasketSaga(action) {
    const { basketsNames } = action.payload;

    if (Array.isArray(basketsNames)) {
        for (const basketName of basketsNames) {
            yield call(fetchBasketsSaga, basketName);
        }
    } else {
        yield call(fetchBasketsSaga, basketsNames);
    }
}

function* changeBasketSaga(action) {
    const { basketName, basket } = action.payload;

    yield put(fetchStart(basketName));

    try {
        yield put(fetchSuccess(basketName, basket));
    } catch (error) {
        const errorMessage = `При загрузке словаря ${basketName} произошла ошибка`;
        const requestError = new RequestError(error, errorMessage);

        yield put(fetchFailed(basketName, requestError));
        yield put(showErrorAlert(requestError.message));
    }
}

export function* saga() {
    yield all([
        takeEvery(FETCH_REQUEST, fetchBasketSaga),
        takeEvery(CHANGE_REQUEST, changeBasketSaga),
    ]);
}

export function selectBaskets(state, basketsNames) {
    if (Array.isArray(basketsNames)) {
        return basketsNames.reduce((baskets, basketName) => {
            baskets[basketName] = selectBaskets(state, basketName);
            return baskets;
        }, {});
    } else {
        const basketState = state[basketsNames];
    
        return basketState.basket;
    }
}

export function isBasketsLoaded(state, basketsNames) {
    if (Array.isArray(basketsNames)) {
        return basketsNames.every(basketName => isBasketsLoaded(state, basketName));
    } else {
        const basketState = state[basketsNames];
        
        return !!basketState.basket;
    }
}