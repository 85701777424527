import React from 'react';
import InfoField from 'components/Lk/Uikit/InfoField';
import { connect } from 'react-redux';
import moment from 'moment';

class RolesAccess extends React.Component {
    renderRole = (item) => {
        return (
            <InfoField value={item?.id} title="Роль" isEdit={false} key={item?.id} noIcon>
                <div>{item.title}</div>
                {item.created && (
                    <div className="LkSystemInfoDate">
                        {' '}
                        с {moment(item.created).format('DD MMMM Y')}
                    </div>
                )}
            </InfoField>
        );
    };

    render() {
        const { data } = this.props;
        return data?.length > 0 ? data.map(this.renderRole) : null;
    }
}

const mapStateToProps = state => ({
    access: state.access,
});

export default connect(mapStateToProps)(RolesAccess);
