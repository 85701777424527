import React from 'react';
import { connect } from 'react-redux';
import EventMenu from 'components/Lk/Common/EventMenu';
import { WISHLIST_FILTER_ID } from '../constants';
import { setSearchFilter } from '../utils';
import { queryOperation } from 'components/Search/QueryOperation';
import './ManageWishlists.scss';
import { FIELDS_TYPE } from '../SearchFilters/constants';

const ManageWishlistsMenu = props => {
    const {
        setSelectedWishlists,
        selectedWishlists,
        list,
        onSearch,
        setMainFilter,
        wishLists,
        setMode,
    } = props;

    const menuFuncs = {
        onWishlistsShow: wishListsIds => async () => {
            await setMainFilter([]);
            await setMainFilter(
                [
                    setSearchFilter(
                        'Wishlist',
                        'WishlistId',
                        queryOperation.InArray,
                        wishListsIds,
                        `Подборка: ${wishLists
                            .filter(w => wishListsIds.indexOf(w.id) >= 0)
                            .map(x => x.isDefault ? 'Моё избранное' : x.name)
                            .join('; ')}`,
                        'AND',
                        WISHLIST_FILTER_ID,
                        'WishlistId',
                        false,
                        null,
                        null,
                        FIELDS_TYPE.multiSelect,
                    ),
                ],

                WISHLIST_FILTER_ID,
                true,
                { join: 'WishlistEntries' },
            );
            onSearch(true, false, true);
        },
        onWishlistsEdit: () => setMode('edit'),
    };

    return (
        <EventMenu
            menuType="wishlists"
            menuFuncs={menuFuncs}
            data={list && list.wishLists}
            onCheck={setSelectedWishlists}
            activeSelection={selectedWishlists}
            allIdsList={list ? list.meta.ids : []}
        />
    );
};

const mapStateToProps = state => ({ list: state.wishlists.allWishLists });

export default connect(mapStateToProps)(ManageWishlistsMenu);
