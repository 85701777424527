import React, { Component } from 'react';

export default (Comp, wrapperElementId, scrollIntoOptions = {
    block: 'center',
    inline: 'center'
}) => class WithKeyboardHandler extends Component {
    state = {
        isFocused: false,
        focusElementsHTML: [],
        focusClassNamesHTML: [],
        height: window.innerHeight,
        isResized: false,
        isBlurred: false,
        isFocusChanged: false
    };
    
    _isFocused = false;
    _isMounted = false;

    onFocusChange = e => {
        if (e.type === 'blur') {
            setTimeout(() => {
                this._isMounted && this.state.isBlurred && this.setState({ isFocused: false, isResized: false, height: window.innerHeight });
            }, 250);
            this.setState({ isBlurred: true });
        } else {
            setTimeout(() => e.target.scrollIntoView(scrollIntoOptions), 250);
            this.setState({ isBlurred: false, isFocused: true, isResized: false, height: window.innerHeight });
        }
        
        this.setState( { isFocusChanged: true } );
        
        setTimeout(() => {
            this._isMounted && this.setState( { isFocusChanged: false } );
        }, 300)
    };
    
    onResizeChange = () => {
        if (this.state.isFocusChanged) { 
            this.setState( { height: window.innerHeight } );
            return;
        }
        
        if (this.state.isFocused && !this.state.isResized) {
            if (this.state.height < window.innerHeight) {
                this.setState({ isFocused: false, isResized: true, height: window.innerHeight });
                return;
            }
        }
        if (this.state.isResized) {
            if (this.state.height > window.innerHeight) {
                this.setState({ isFocused: true, isResized: false, height: window.innerHeight });
                return;
            }
        }
        this.setState({ height: window.innerHeight });
    };

    setListeners = (add = true) => {
        const focusElements = this.getFocusElements();
        const activeElement = document.activeElement;
        const focusElementsHTML = [];
        const focusClassNamesHTML = [];
        for (const el of focusElements) {
            if (activeElement === el) {
                this.setState({isFocused: true}); 
            }
            if (add) {
                el.addEventListener('focus', this.onFocusChange);
                el.addEventListener('blur', this.onFocusChange);
            } else {
                el.removeEventListener('focus', this.onFocusChange);
                el.removeEventListener('blur', this.onFocusChange);
            }
            focusElementsHTML.push(el.outerHTML);
            focusClassNamesHTML.push(el.className);
        }
        this.setState({ focusElementsHTML, focusClassNamesHTML })
    };

    getFocusElements = () => {
        const el = document.getElementById(wrapperElementId);
        return el ? [...el.querySelectorAll("input[type='text'], textarea, select")] : [];
    };

    handleVisibilityChange = () => {
        if (!document.hidden) {
            setTimeout(() => {
                if ((window.screen.availHeight - window.innerHeight) < 250) {
                    this._isMounted && this.setState({ isFocused: false, isResized: true, height: window.innerHeight })
                }
            }, 100)
        }
    };
    
    componentDidMount() {
        this._isMounted = true;
        window.addEventListener('resize', this.onResizeChange);
        document.addEventListener("visibilitychange", this.handleVisibilityChange, false);
        this.setListeners();
    }

    componentDidUpdate(prevProps, prevState) {
        const currentElemsArray = this.getFocusElements().map(x => x.className);

        if (JSON.stringify(prevState.focusClassNamesHTML) !== JSON.stringify(currentElemsArray)) {
            this.setListeners();
        }

    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.onResizeChange);
        document.removeEventListener("visibilitychange", this.handleVisibilityChange, false);
        this.setListeners(false);
    }

    render() {
        return (
            <Comp {...this.props} isFocused={this.state.isFocused} />
        );
    }
};

