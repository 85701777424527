import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import uuid from 'uuid/v4';
import {
    getEvaluationTestById,
    searchCompetencyModels,
    getPersonGrowthTags,
    saveEvaluationTest,
    getExternalEvaluationTests,
    getExternalEvaluationTestsNotUsed,
    getTestProvidersNotDeleted,
} from 'api';
import { testsRoute } from 'routes';
import { showErrorAlert, showSuccessAlert, showWarningAlert } from 'ducks/Alert';
import { serviceResultCode, getError } from 'serviceErrors';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
    chartTypes,
    testStatuses,
    testCriteriaType,
    testGroups,
    yearMask,
    INT_32_MAX_VALUE,
} from 'constants.js';
import PageCardWrapper from 'components/common/PageCardWrapper';
import { Card, CardHeader, CardFooter } from 'components/common/Card';
import BlankTest from 'components/Moderation/Tests/BlankTest';
import { CardLine } from 'components/common/Card';
import Button from 'components/uikit/Button';
import Label from 'components/uikit/Label';
import Input from 'components/uikit/Input';
import Select from 'components/uikit/Select';
import Field from 'components/uikit/Field';
import InputText from 'components/uikit/InputText';
import InputMask from 'components/uikit/InputMask';
import ReactTooltip from 'react-tooltip';
import Media from 'react-media';
import { mediaScreenSizes } from 'constants.js';

import './Test.scss';

const Test = (props) => {
    const {
        id,
        type,
        showPageLoader,
        showSuccessAlert,
        hidePageLoader,
        showErrorAlert,
        showWarningAlert,
    } = props;

    const [models, setModels] = useState([]);
    const [allTags, setAllTags] = useState([]);
    const [test, setTest] = useState(type === 'edit' ? {} : BlankTest);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [externalTests, setExternalTests] = useState([]);
    const [externalTestsNotUsed, setExternalTestsNotUsed] = useState([]);
    const [testProviders, setTestProviders] = useState([]);

    useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            try {
                showPageLoader();

                const modelsData = await searchCompetencyModels();
                mounted &&
                    setModels(
                        modelsData.data &&
                            modelsData.data.payload &&
                            modelsData.data.payload.map((x) => ({ code: x.id, name: x.name })),
                    );

                const tagsData = await getPersonGrowthTags();
                mounted && setAllTags(tagsData.data);

                const externalTestsData = await getExternalEvaluationTests();
                mounted &&
                    setExternalTests(
                        externalTestsData.data &&
                            externalTestsData.data.map((x) => ({
                                code: x.id,
                                name: x.title,
                            })),
                    );

                const externalTestsNotUsedData = await getExternalEvaluationTestsNotUsed();
                mounted &&
                    setExternalTestsNotUsed(
                        externalTestsNotUsedData.data &&
                            externalTestsNotUsedData.data.map((x) => ({
                                code: x.id,
                                name: x.title,
                            })),
                    );

                const testProvidersData = await getTestProvidersNotDeleted();
                mounted &&
                    setTestProviders(
                        !!testProvidersData.data &&
                            !!testProvidersData.data &&
                            testProvidersData.data.map((x) => ({
                                ...x,
                                name: x.title,
                                value: x.id,
                            })),
                    );

                let testData = {};
                if (type === 'edit') {
                    const res = await getEvaluationTestById(id);
                    testData = res.data;
                } else {
                    const testId = uuid();
                    const criteriaId = uuid();
                    testData = {
                        ...BlankTest,
                        id: testId,
                        criteria: [
                            { ...BlankTest.criteria[0], id: criteriaId, evaluationTestId: testId },
                        ],
                        criteriaOrder: [criteriaId],
                    };
                }

                let criterias = getCriterias(testData);
                criterias = criterias.map((x) => ({
                    ...x,
                    tags: getTagsForCriteria(x.id, criterias, tagsData.data),
                }));
                mounted && setTest({ ...testData, criteria: criterias });
            } catch (error) {
                showErrorAlert(error.message);
            } finally {
                hidePageLoader();
            }
        };

        fetchData();

        return () => {
            mounted = false;
        };
    }, [showPageLoader, hidePageLoader, showErrorAlert, id, type]);

    const [screenMatch, setScreenMatch] = useState({});
    const [screenSize, setScreenSize] = useState('unknown');

    const isNarrowScreen = () =>
        screenSize &&
        ['xxsmall', 'xsmall', 'small', 'medium', 'large', 'xlarge'].some(
            (x) => x === screenSize[0],
        );

    const isSuperNarrowScreen = () =>
        screenSize && ['xxsmall', 'xsmall', 'small', 'medium'].some((x) => x === screenSize[0]);

    useEffect(() => {
        const size = Object.entries(screenMatch).filter((x) => x[1])[0];
        screenMatch && setScreenSize(size);
    }, [screenMatch]);

    const getTagsForCriteria = (criteriaId, criterias, tags) => {
        return tags
            .filter(
                (tag) =>
                    !criterias.find(
                        (cr) => cr.personGrowthTagId === tag.id && cr.id !== criteriaId,
                    ),
            )
            .map((x) => ({ value: x.id, label: x.name, isInternal: x.isInternal }));
    };

    const getCriterias = (test) => {
        const orderedCriterias = Object.entries(test.criteria)
            .map((x) => x[1])
            .sort((a, b) => (a.position > b.position ? 1 : b.position > a.position ? -1 : 0));

        return orderedCriterias;
    };

    const returnToList = (type) => {
        props.push({
            pathname: testsRoute.url,
            search: type === 'new' ? '' : props.location.search,
        });
    };

    const onSubmit = () => {
        const isValid = isTestValid();
        setIsSubmitted(true);
        !isValid ? showWarningAlert('Не все поля корректно заполнены') : saveTest(type);
    };

    const saveTest = async () => {
        try {
            showPageLoader();
            await saveEvaluationTest(test, type);
            showSuccessAlert('Тест успешно сохранен');
            returnToList(type);
        } catch (error) {
            const reqError = getError(error, getSaveTestError);
            showErrorAlert(reqError.message);
        } finally {
            hidePageLoader();
        }
    };

    const getSaveTestError = (code, payload) => {
        switch (code) {
            case serviceResultCode.NotFound:
                return `${payload}`;
            case serviceResultCode.EvaluationTestIsUsedUnableUpdate:
                return `Тест оценки развития уже использован. Критерии теста не могут быть удалены или добавлены.`;
            case serviceResultCode.EvaluationTestIsUsedUnableDelete:
                return `Тест оценки развития уже использован и не может быть удален.`;
            case serviceResultCode.ValidationErrors:
                return extractValidationError(payload);
            default:
                return `Произошла непредвиденная ошибка`;
        }
    };

    const extractValidationError = (errorPayload) => {
        if (!errorPayload) {
            return '';
        }

        const errors = Object.values(errorPayload);
        let errorMessages = [];
        if (errors) {
            errors.map((x) => x.map((i) => errorMessages.push(i.message)));
        }

        return errorMessages.length > 0 && errorMessages.join(', ');
    };

    const isValidForYearsInValid = (validForYears) => {
        const num = parseInt(validForYears);
        return !!num && num > 0 && num <= INT_32_MAX_VALUE;
    };

    const isCriteriaWithExtraDescription = (title) => {
        return !!title && !!title.match('[-––]');
    };

    const isTestValid = () => {
        const isCriteriaInvalid = test.criteria.some((x) => {
            return (
                !x.evaluationTestId ||
                (x.valueKind === 'Scale'
                    ? (!x.scaleLowerBound && x.scaleLowerBound !== 0) ||
                      +x.scaleLowerBound < 0 ||
                      (!x.scaleUpperBound && x.scaleUpperBound !== 0) ||
                      +x.scaleUpperBound < 0 ||
                      +x.scaleLowerBound > +x.scaleUpperBound ||
                      (+x.scaleLowerBound === 0 && +x.scaleUpperBound === 0) ||
                      !x.description ||
                      !x.description.trim() ||
                      (isCriteriaWithExtraDescription(x.title) && !x.extraDescription) ||
                      (isCriteriaWithExtraDescription(x.title) &&
                          x.extraDescription &&
                          !x.extraDescription.trim())
                    : !x.gradesInAscendingOrder.trim()) ||
                !x.personGrowthTagId ||
                !x.title ||
                !x.title.trim() ||
                !x.valueKind ||
                !x.valueKind.trim() ||
                !x.description ||
                !x.description.trim() ||
                (isCriteriaWithExtraDescription(x.title) && !x.extraDescription) ||
                (isCriteriaWithExtraDescription(x.title) &&
                    x.extraDescription &&
                    !x.extraDescription.trim())
            );
        });

        const isCompetencyModelIdValid =
            test.kind === 'Competency' ? !!test.competencyModelId : true;

        const isExternalTestIdValid = test.status === 'External' ? !!test.externalTestId : true;

        const isTestDescriptionValid = test.description
            ? typeof test.description === 'string' && test.description.length < 5000
            : true;

        return (
            test.title &&
            test.title.trim() &&
            test.status &&
            test.visualizationKind &&
            test.testProviderId &&
            test.kind &&
            !isCriteriaInvalid &&
            isCompetencyModelIdValid &&
            isExternalTestIdValid &&
            isValidForYearsInValid(test.validForYears) &&
            isTestDescriptionValid
        );
    };

    const getNewCriteria = () => {
        const criteriaId = uuid();
        return {
            evaluationTestId: test.id,
            gradesInAscendingOrder: '',
            id: criteriaId,
            personGrowthTagId: '',
            position: test.criteria.length + 1,
            scaleLowerBound: 0,
            scaleStep: 1,
            scaleUpperBound: 10,
            tags: getTagsForCriteria(criteriaId, test.criteria, allTags),
            title: '',
            valueKind: 'Scale',
            description: '',
            extraDescription: '',
        };
    };

    const addNewCriteria = () => {
        const blankCriteria = getNewCriteria();
        const newCriteriaArray = [...test.criteria, blankCriteria];
        const criteriaOrder = newCriteriaArray.map((x) => x.id);
        setTest({ ...test, criteria: newCriteriaArray, criteriaOrder: criteriaOrder });
    };

    const handleTestTitleChange = (e) => setTest({ ...test, title: e.target.value });
    const handleTestStatusChange = (e) => setTest({ ...test, status: e.code });
    const handleTestModelChange = (e) => setTest({ ...test, competencyModelId: e.code });
    const handleTestChartTypeChange = (e) => setTest({ ...test, visualizationKind: e.code });
    const handleTestDescriptionChange = (e) => setTest({ ...test, description: e.target.value });
    const handleExternalTestChange = (e) => setTest({ ...test, externalTestId: e.code });
    const handleTestProviderChange = (e) =>
        setTest({ ...test, testProviderId: testProviders.find((x) => x.code === e.code).id });
    const handleTestGroupChange = (e) =>
        setTest({
            ...test,
            kind: e.code,
            criteria: test.criteria.map((x) => ({ ...x, personGrowthTagId: '' })),
        });
    const handleTestValidForYearsChange = (e) =>
        setTest({ ...test, validForYears: e.target.value });

    const renderPageCardWRapperFooter = () => {
        return (
            <>
                <div>
                    {!test.isUsed && (
                        <Button size="m" addLink onClick={addNewCriteria}>
                            Добавить критерий
                        </Button>
                    )}
                </div>
                <div>
                    <Button size="m" closeLink onClick={returnToList}>
                        Отменить
                    </Button>
                    <Button size="m" onClick={onSubmit}>
                        Сохранить
                    </Button>
                </div>
            </>
        );
    };

    const renderTestCard = () => {
        return (
            test && (
                <Row>
                    <Col xs={12}>
                        <Card>
                            <CardLine lineBlock={renderTestBlock()} />
                        </Card>
                    </Col>
                </Row>
            )
        );
    };

    const renderTestBlock = () => {
        return (
            <>
                <Row>
                    <Col xs={7}>
                        <Field
                            required
                            filled={test.title}
                            invalid={
                                (isSubmitted && !test.title.trim()) ||
                                (test.title && test.title.length > 250)
                            }
                        >
                            <Row between="xs" middle="xs" className="TestToolTipLink__Row">
                                <Col>
                                    <Label>Название</Label>
                                </Col>
                                <Col>
                                    <div data-tip data-for="titleToolTip" className="tooltip-link">
                                        ?
                                    </div>
                                </Col>
                            </Row>
                            <Input value={test.title || ''} onChange={handleTestTitleChange} />
                            <ReactTooltip
                                id="titleToolTip"
                                place={'top'}
                                type={'light'}
                                effect={'solid'}
                                border={true}
                                data-offset="{'top': 0, 'left': 0}"
                            >
                                <p className="tooltip">
                                    Введите название теста, которое будет отображаться в системе
                                </p>
                            </ReactTooltip>
                        </Field>
                    </Col>
                    <Col xs={5}>
                        <Field required filled={test.status} invalid={isSubmitted && !test.status}>
                            <Row between="xs" middle="xs" className="TestToolTipLink__Row">
                                <Col>
                                    <Label>Статус</Label>
                                </Col>
                                <Col>
                                    <div data-tip data-for="testStatus" className="tooltip-link">
                                        ?
                                    </div>
                                </Col>
                            </Row>
                            <Select
                                options={testStatuses}
                                catalog
                                value={testStatuses.filter((x) => x.code === test.status) || {}}
                                onChange={handleTestStatusChange}
                            />
                            <ReactTooltip
                                id="testStatus"
                                place={'top'}
                                type={'light'}
                                effect={'solid'}
                                border={true}
                                data-offset="{'top': 0, 'left': 0}"
                            >
                                <p className="tooltip">
                                    Введите статус теста "тестирование в ИСКРе"/"тестирование вне
                                    системы"
                                </p>
                            </ReactTooltip>
                        </Field>
                    </Col>
                </Row>
                <Row>
                    <Col xs={7}>
                        <Field required filled={test.kind} invalid={isSubmitted && !test.kind}>
                            <Row between="xs" middle="xs" className="TestToolTipLink__Row">
                                <Col>
                                    <Label>Тип оценки</Label>
                                </Col>
                                <Col>
                                    <div data-tip data-for="testType" className="tooltip-link">
                                        ?
                                    </div>
                                </Col>
                            </Row>
                            <Select
                                value={testGroups.filter((x) => x.code === test.kind) || {}}
                                onChange={handleTestGroupChange}
                                options={testGroups}
                                catalog
                            />
                            <ReactTooltip
                                id="testType"
                                place={'top'}
                                type={'light'}
                                effect={'solid'}
                                border={true}
                                data-offset="{'top': 0, 'left': 0}"
                            >
                                <p className="tooltip">
                                    Выберите тип оценки, к которой относится тест
                                </p>
                            </ReactTooltip>
                        </Field>
                    </Col>
                    <Col xs={5}>
                        <Field
                            required
                            filled={test.visualizationKind}
                            invalid={isSubmitted && !test.visualizationKind}
                        >
                            <Row between="xs" middle="xs" className="TestToolTipLink__Row">
                                <Col>
                                    <Label>Тип визуализации</Label>
                                </Col>
                                <Col>
                                    <div
                                        data-tip
                                        data-for="visualizationKind"
                                        className="tooltip-link"
                                    >
                                        ?
                                    </div>
                                </Col>
                            </Row>
                            <Select
                                options={chartTypes.filter((x) =>
                                    test.kind === 'Abilities' ? x : x.code !== 'Gradient',
                                )}
                                catalog
                                value={
                                    chartTypes.filter((x) => x.code === test.visualizationKind) ||
                                    {}
                                }
                                onChange={handleTestChartTypeChange}
                            />
                            <ReactTooltip
                                id="visualizationKind"
                                place={'top'}
                                type={'light'}
                                effect={'solid'}
                                border={true}
                                data-offset="{'top': 0, 'left': 0}"
                            >
                                <p className="tooltip">
                                    Выберите тип визуализации результатов теста
                                </p>
                            </ReactTooltip>
                        </Field>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Field
                            filled={test.description}
                            invalid={
                                test.description &&
                                typeof test.description === 'string' &&
                                test.description.length >= 5000
                            }
                        >
                            <Row between="xs" middle="xs" className="TestToolTipLink__Row">
                                <Col>
                                    <Label>Описание</Label>
                                </Col>
                                <Col>
                                    <div data-tip data-for="description" className="tooltip-link">
                                        ?
                                    </div>
                                </Col>
                            </Row>
                            <InputText
                                value={test.description || ''}
                                onChange={handleTestDescriptionChange}
                            />
                        </Field>
                        <ReactTooltip
                            id="description"
                            place={'top'}
                            type={'light'}
                            effect={'solid'}
                            border={true}
                            data-offset="{'top': 0, 'left': 0}"
                        >
                            <p className="tooltip">Введите описание теста, что он измеряет</p>
                        </ReactTooltip>
                    </Col>
                </Row>
                <Row>
                    <Col xs={7}>
                        <Field
                            required
                            filled={test.testProviderId}
                            invalid={isSubmitted && !test.testProviderId}
                        >
                            <Row between="xs" middle="xs" className="TestToolTipLink__Row">
                                <Col>
                                    <Label>Кто проводит</Label>
                                </Col>
                                <Col>
                                    <div data-tip data-for="testProvider" className="tooltip-link">
                                        ?
                                    </div>
                                </Col>
                            </Row>
                            <Select
                                options={testProviders}
                                catalog
                                value={
                                    testProviders.filter((x) => x.id === test.testProviderId) || {}
                                }
                                onChange={handleTestProviderChange}
                            />
                            <ReactTooltip
                                id="testProvider"
                                place={'top'}
                                type={'light'}
                                effect={'solid'}
                                border={true}
                                data-offset="{'top': 0, 'left': 0}"
                            >
                                <p className="tooltip">Укажите поставщика (провайдера) теста</p>
                            </ReactTooltip>
                        </Field>
                    </Col>
                    <Col xs={5}>
                        <Field
                            required
                            filled={
                                test.validForYears && isValidForYearsInValid(test.validForYears)
                            }
                            invalid={isSubmitted && !isValidForYearsInValid(test.validForYears)}
                        >
                            <Row between="xs" middle="xs" className="TestToolTipLink__Row">
                                <Col>
                                    <Label>Срок актуальности</Label>
                                </Col>
                                <Col>
                                    <div data-tip data-for="validForYears" className="tooltip-link">
                                        ?
                                    </div>
                                </Col>
                            </Row>
                            <Input
                                type="number"
                                min={1}
                                value={test.validForYears || ''}
                                onChange={handleTestValidForYearsChange}
                                readOnly={false}
                            />
                            <ReactTooltip
                                id="validForYears"
                                place={'top'}
                                type={'light'}
                                effect={'solid'}
                                border={true}
                                data-offset="{'top': 0, 'left': 0}"
                            >
                                <p className="tooltip">Укажите количество лет</p>
                            </ReactTooltip>
                        </Field>
                    </Col>
                </Row>
                {test.kind === 'Competency' && (
                    <Row>
                        <Col xs={12}>
                            <Field
                                required
                                filled={test.competencyModelId}
                                invalid={isSubmitted && !test.competencyModelId}
                            >
                                <Row between="xs" middle="xs" className="TestToolTipLink__Row">
                                    <Col>
                                        <Label>Модель компетенций</Label>
                                    </Col>
                                    <Col>
                                        <div
                                            data-tip
                                            data-for="competencyModelId"
                                            className="tooltip-link"
                                        >
                                            ?
                                        </div>
                                    </Col>
                                </Row>
                                <Select
                                    options={models}
                                    catalog
                                    value={
                                        models.filter((x) => x.code === test.competencyModelId) ||
                                        {}
                                    }
                                    onChange={handleTestModelChange}
                                />
                                <ReactTooltip
                                    id="competencyModelId"
                                    place={'top'}
                                    type={'light'}
                                    effect={'solid'}
                                    border={true}
                                    data-offset="{'top': 0, 'left': 0}"
                                >
                                    <p className="tooltip">
                                        Выберите модель компетенций, к которой относится тест
                                    </p>
                                </ReactTooltip>
                            </Field>
                        </Col>
                    </Row>
                )}
                {test.status === 'External' && (
                    <Row>
                        <Col xs={12}>
                            <Field
                                required
                                filled={test.externalTestId}
                                invalid={isSubmitted && !test.externalTestId}
                            >
                                <Row between="xs" middle="xs" className="TestToolTipLink__Row">
                                    <Col>
                                        <Label>Внешний тест</Label>
                                    </Col>
                                    <Col>
                                        <div
                                            data-tip
                                            data-for="externalTest"
                                            className="tooltip-link"
                                        >
                                            ?
                                        </div>
                                    </Col>
                                </Row>
                                <Select
                                    options={externalTestsNotUsed}
                                    catalog
                                    value={
                                        externalTests.filter(
                                            (x) => x.code === test.externalTestId,
                                        ) || {}
                                    }
                                    onChange={handleExternalTestChange}
                                />
                                <ReactTooltip
                                    id="externalTest"
                                    place={'top'}
                                    type={'light'}
                                    effect={'solid'}
                                    border={true}
                                    data-offset="{'top': 0, 'left': 0}"
                                >
                                    <p className="tooltip">
                                        Выберите внешний тест из списка, соответствующий вашему
                                        тесту
                                    </p>
                                </ReactTooltip>
                            </Field>
                        </Col>
                    </Row>
                )}
            </>
        );
    };

    const renderCriterias = (draggingOverWith) => {
        return (
            <>
                {test.criteria &&
                    test.criteria.map((x, index) => (
                        <Draggable draggableId={x.id} index={index} key={x.id}>
                            {(provided, snapshot) => (
                                <div
                                    key={x.id}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                >
                                    <Row key={x.id}>
                                        <Col xs={12}>
                                            {renderCriteriaCard(
                                                x,
                                                snapshot.isDragging && !draggingOverWith,
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Draggable>
                    ))}
            </>
        );
    };

    const makeNewCriteriaArray = (e, key, id = null) => {
        const criteriaId = !id ? e.target.getAttribute('criteriaid') : id;
        let newCriteriaArray = [...test.criteria];
        const criteriaIndex = newCriteriaArray.findIndex((x) => x.id === criteriaId);
        const criteria = newCriteriaArray.find((x) => x.id === criteriaId);
        criteriaIndex !== -1 &&
            newCriteriaArray.splice(criteriaIndex, 1, {
                ...criteria,
                [key]: !id ? e.target.value : e.code,
            });
        return newCriteriaArray;
    };

    const handleCriteriaTitleChange = (e) => {
        const newCriteriaArray = makeNewCriteriaArray(e, 'title');
        setTest({ ...test, criteria: newCriteriaArray });
    };

    const handleCriteriaTypeChange = (e, criteriaId) => {
        const newCriteriaArray = makeNewCriteriaArray(e, 'valueKind', criteriaId);
        clearScaleGrades(criteriaId, newCriteriaArray);
    };

    const handleCriteriaDescriptionChange = (e) => {
        const newCriteriaArray = makeNewCriteriaArray(e, 'description');
        setTest({ ...test, criteria: newCriteriaArray });
    };

    const handleCriteriaExtraDescriptionChange = (e) => {
        const newCriteriaArray = makeNewCriteriaArray(e, 'extraDescription');
        setTest({ ...test, criteria: newCriteriaArray });
    };

    const clearScaleGrades = (criteriaId, criteria, code) => {
        const cleanCriteria = criteria.map((x) => {
            if (x.id === criteriaId) {
                if (code === 'Scale') {
                    delete x.gradesInAscendingOrder;
                } else {
                    delete x.scaleLowerBound;
                    delete x.scaleUpperBound;
                }
                return x;
            } else {
                return x;
            }
        });
        setTest({ ...test, criteria: cleanCriteria });
    };

    const handleCriteriaTagChange = (e, criteriaId) => {
        const newCriteria = test.criteria.map((x) => ({
            ...x,
            personGrowthTagId: x.id === criteriaId ? e.value : x.personGrowthTagId,
        }));
        modifyTags(newCriteria);
    };

    const modifyTags = (criteria) => {
        const newCriteria = criteria.map((x) => ({
            ...x,
            tags: getTagsForCriteria(x.id, criteria, allTags),
        }));
        setTest({ ...test, criteria: newCriteria });
    };

    const handleCriteriaMinAmountChange = (e) => {
        const newCriteriaArray = makeNewCriteriaArray(e, 'scaleLowerBound');
        setTest({ ...test, criteria: newCriteriaArray });
    };

    const handleCriteriaMaxAmountChange = (e) => {
        const newCriteriaArray = makeNewCriteriaArray(e, 'scaleUpperBound');
        setTest({ ...test, criteria: newCriteriaArray });
    };

    const handleCriteriaGradesChange = (e) => {
        const newCriteriaArray = makeNewCriteriaArray(e, 'gradesInAscendingOrder');
        setTest({ ...test, criteria: newCriteriaArray });
    };

    const removeCriteria = (e) => {
        const criteriaId = e.target.getAttribute('criteriaid');
        let newCriteriaArray = [...test.criteria];
        const criteriaIndex = newCriteriaArray.findIndex((x) => x.id === criteriaId);
        criteriaIndex !== -1 && newCriteriaArray.splice(criteriaIndex, 1);
        const orderedNewCriteriaArray = newCriteriaArray.map((x, index) => ({
            ...x,
            position: index + 1,
        }));
        const criteriaOrder = orderedNewCriteriaArray.map((x) => x.id);
        setTest({ ...test, criteria: orderedNewCriteriaArray, criteriaOrder: criteriaOrder });
    };

    const renderCriteriaCard = (criteria, fade) => {
        const { id } = criteria;
        const cardBlock = (
            <>
                <Row>
                    <Col xs={8}>
                        <Field
                            required
                            filled={criteria.title}
                            invalid={
                                (isSubmitted && !criteria.title.trim()) ||
                                (criteria.title && criteria.title.length > 250)
                            }
                        >
                            <Row between="xs" middle="xs" className="TestToolTipLink__Row">
                                <Col>
                                    <Label>Название</Label>
                                </Col>
                                <Col>
                                    <div data-tip data-for="criteriaTitle" className="tooltip-link">
                                        ?
                                    </div>
                                </Col>
                            </Row>
                            <Input
                                value={criteria.title || ''}
                                onChange={handleCriteriaTitleChange}
                                criteriaid={id}
                            />
                            <ReactTooltip
                                id="criteriaTitle"
                                place={'top'}
                                type={'light'}
                                effect={'solid'}
                                border={true}
                                data-offset="{'top': 0, 'left': 0}"
                            >
                                <p className="tooltip">
                                    Укажите название шкалы, которая измеряет тест
                                </p>
                            </ReactTooltip>
                        </Field>
                    </Col>
                    <Col xs={4}>
                        <Field
                            required
                            filled={criteria.valueKind}
                            invalid={isSubmitted && !criteria.valueKind}
                        >
                            <Row between="xs" middle="xs" className="TestToolTipLink__Row">
                                <Media queries={mediaScreenSizes}>
                                    {(matches) => (
                                        <>
                                            {!isNarrowScreen() ? (
                                                <Col>
                                                    <Label>Единица измерения</Label>
                                                </Col>
                                            ) : screenSize[0] === 'xlarge' ||
                                              screenSize[0] === 'large' ? (
                                                <Col>
                                                    <Label>Ед. измерения</Label>
                                                </Col>
                                            ) : (
                                                <Col>
                                                    <Label>Ед. измер.</Label>
                                                </Col>
                                            )}
                                            <div>{setScreenMatch(matches)}</div>
                                        </>
                                    )}
                                </Media>
                                <Col>
                                    <div data-tip data-for="valueKind" className="tooltip-link">
                                        ?
                                    </div>
                                </Col>
                            </Row>
                            <Select
                                options={testCriteriaType}
                                value={testCriteriaType.find((x) => x.code === criteria.valueKind)}
                                catalog
                                onChange={(e) => handleCriteriaTypeChange(e, id)}
                                isDisabled={test.isUsed}
                            />
                            <ReactTooltip
                                id="valueKind"
                                place={'top'}
                                type={'light'}
                                effect={'solid'}
                                border={true}
                                data-offset="{'top': 0, 'left': 0}"
                            >
                                <p className="tooltip">Выберите тип шкалы</p>
                            </ReactTooltip>
                        </Field>
                    </Col>
                </Row>
                <Row>
                    <Col xs={8}>
                        <Field
                            required
                            filled={criteria.personGrowthTagId}
                            invalid={isSubmitted && !criteria.personGrowthTagId}
                        >
                            <Row between="xs" middle="xs" className="TestToolTipLink__Row">
                                <Col>
                                    <Label>Шкала</Label>
                                </Col>
                                <Col>
                                    <div data-tip data-for="criteriaTag" className="tooltip-link">
                                        ?
                                    </div>
                                </Col>
                            </Row>
                            <Select
                                value={
                                    criteria.tags.find(
                                        (x) => x.value === criteria.personGrowthTagId,
                                    ) || ''
                                }
                                options={
                                    test.kind === 'Competency'
                                        ? criteria.tags.filter((x) => !x.isInternal)
                                        : criteria.tags.filter((x) => x.isInternal)
                                }
                                onChange={(e) => handleCriteriaTagChange(e, id)}
                            />
                            <ReactTooltip
                                id="criteriaTag"
                                place={'top'}
                                type={'light'}
                                effect={'solid'}
                                border={true}
                                data-offset="{'top': 0, 'left': 0}"
                            >
                                <p className="tooltip">Выберите шкалу для теста</p>
                            </ReactTooltip>
                        </Field>
                    </Col>
                    {criteria.valueKind === 'Scale' ? (
                        <>
                            <Col xs={2}>
                                <Field
                                    required
                                    filled={
                                        (criteria.scaleLowerBound ||
                                            criteria.scaleLowerBound === 0) &&
                                        criteria.scaleLowerBound > -1
                                    }
                                    invalid={
                                        isSubmitted &&
                                        ((!criteria.scaleLowerBound &&
                                            criteria.scaleLowerBound !== 0) ||
                                            criteria.scaleLowerBound < 0 ||
                                            +criteria.scaleLowerBound > +criteria.scaleUpperBound ||
                                            (+criteria.scaleLowerBound === 0 &&
                                                +criteria.scaleUpperBound === 0))
                                    }
                                >
                                    <Row between="xs" middle="xs" className="TestToolTipLink__Row">
                                        <Col>
                                            <Label>Мин.</Label>
                                        </Col>
                                        <Col>
                                            <div
                                                data-tip
                                                data-for="scaleLowerBound"
                                                className="tooltip-link"
                                            >
                                                ?
                                            </div>
                                        </Col>
                                    </Row>
                                    <InputMask
                                        mask={yearMask}
                                        maskChar={null}
                                        criteriaid={id}
                                        value={criteria.scaleLowerBound}
                                        onChange={handleCriteriaMinAmountChange}
                                        disabled={test.isUsed}
                                        style={test.isUsed ? { background: '#f1f1f1' } : {}}
                                    />
                                    <ReactTooltip
                                        id="scaleLowerBound"
                                        place={'top'}
                                        type={'light'}
                                        effect={'solid'}
                                        border={true}
                                        data-offset="{'top': 0, 'left': 0}"
                                    >
                                        <p className="tooltip">
                                            Укажите интервал значений шкалы: минимально возможное
                                        </p>
                                    </ReactTooltip>
                                </Field>
                            </Col>
                            <Col xs={2}>
                                <Field
                                    required
                                    filled={
                                        (criteria.scaleUpperBound ||
                                            criteria.scaleUpperBound === 0) &&
                                        criteria.scaleUpperBound > -1
                                    }
                                    invalid={
                                        isSubmitted &&
                                        ((!criteria.scaleUpperBound &&
                                            criteria.scaleUpperBound !== 0) ||
                                            criteria.scaleUpperBound < 0 ||
                                            +criteria.scaleLowerBound > +criteria.scaleUpperBound ||
                                            (+criteria.scaleLowerBound === 0 &&
                                                +criteria.scaleUpperBound === 0))
                                    }
                                >
                                    <Row between="xs" middle="xs" className="TestToolTipLink__Row">
                                        <Media queries={mediaScreenSizes}>
                                            {(matches) => (
                                                <>
                                                    {setScreenMatch(matches)}
                                                    {!isSuperNarrowScreen() ? (
                                                        <Col>
                                                            <Label>Макс.</Label>
                                                        </Col>
                                                    ) : (
                                                        <Col>
                                                            <Label>Мкс.</Label>
                                                        </Col>
                                                    )}
                                                </>
                                            )}
                                        </Media>
                                        <Col>
                                            <div
                                                data-tip
                                                data-for="scaleUpperBound"
                                                className="tooltip-link"
                                            >
                                                ?
                                            </div>
                                        </Col>
                                    </Row>
                                    <InputMask
                                        mask={yearMask}
                                        maskChar={null}
                                        criteriaid={id}
                                        value={criteria.scaleUpperBound}
                                        onChange={handleCriteriaMaxAmountChange}
                                        disabled={test.isUsed}
                                        style={test.isUsed ? { background: '#f1f1f1' } : {}}
                                    />
                                    <ReactTooltip
                                        id="scaleUpperBound"
                                        place={'top'}
                                        type={'light'}
                                        effect={'solid'}
                                        border={true}
                                        data-offset="{'top': 0, 'left': 0}"
                                    >
                                        <p className="tooltip">
                                            Укажите интервал значений шкалы: максимально возможное
                                        </p>
                                    </ReactTooltip>
                                </Field>
                            </Col>
                        </>
                    ) : (
                        <Col xs={4}>
                            <Field required filled={criteria.gradesInAscendingOrder}>
                                <Row between="xs" middle="xs" className="TestToolTipLink__Row">
                                    <Col>
                                        <Label>Значения</Label>
                                    </Col>
                                    <Col>
                                        <div
                                            data-tip
                                            data-for="gradesInAscendingOrder"
                                            className="tooltip-link"
                                        >
                                            ?
                                        </div>
                                    </Col>
                                </Row>
                                <Input
                                    value={criteria.gradesInAscendingOrder}
                                    onChange={handleCriteriaGradesChange}
                                    criteriaid={id}
                                    disabled={test.isUsed}
                                />
                                <ReactTooltip
                                    id="gradesInAscendingOrder"
                                    place={'top'}
                                    type={'light'}
                                    effect={'solid'}
                                    border={true}
                                    data-offset="{'top': 0, 'left': 0}"
                                >
                                    <p className="tooltip">
                                        Укажите значения шкалы в нисходящем порядке
                                    </p>
                                </ReactTooltip>
                            </Field>
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col xs={12}>
                        <Field
                            required
                            filled={!!criteria.description && criteria.description.trim()}
                            invalid={
                                isSubmitted &&
                                (!criteria.description ||
                                    (criteria.description && !criteria.description.trim()) ||
                                    (criteria.description && criteria.description.length > 2500))
                            }
                        >
                            <Row between="xs" middle="xs" className="TestToolTipLink__Row">
                                <Col>
                                    <Label>
                                        {!isCriteriaWithExtraDescription(criteria.title)
                                            ? 'Описание критерия'
                                            : 'Описание левого полюса шкалы'}
                                    </Label>
                                </Col>
                                <Col>
                                    <div
                                        data-tip
                                        data-for="criteriaDescription"
                                        className="tooltip-link"
                                    >
                                        ?
                                    </div>
                                </Col>
                            </Row>
                            <InputText
                                value={criteria.description || ''}
                                onChange={handleCriteriaDescriptionChange}
                                criteriaid={id}
                            />
                            <ReactTooltip
                                id="criteriaDescription"
                                place={'top'}
                                type={'light'}
                                effect={'solid'}
                                border={true}
                                data-offset="{'top': 0, 'left': 0}"
                            >
                                <p className="tooltip">Укажите описание критерия</p>
                            </ReactTooltip>
                        </Field>
                    </Col>
                </Row>
                {isCriteriaWithExtraDescription(criteria.title) && (
                    <Row>
                        <Col xs={12}>
                            <Field
                                required
                                filled={
                                    !!criteria.extraDescription && criteria.extraDescription.trim()
                                }
                                invalid={
                                    isSubmitted &&
                                    (!criteria.extraDescription ||
                                        (criteria.extraDescription &&
                                            !criteria.extraDescription.trim()) ||
                                        (criteria.extraDescription &&
                                            criteria.extraDescription.length > 2500))
                                }
                            >
                                <Row between="xs" middle="xs" className="TestToolTipLink__Row">
                                    <Col>
                                        <Label>Описание правого полюса шкалы</Label>
                                    </Col>
                                    <Col>
                                        <div
                                            data-tip
                                            data-for="criteriaExtraDescription"
                                            className="tooltip-link"
                                        >
                                            ?
                                        </div>
                                    </Col>
                                </Row>
                                <InputText
                                    value={criteria.extraDescription || ''}
                                    onChange={handleCriteriaExtraDescriptionChange}
                                    criteriaid={id}
                                />
                                <ReactTooltip
                                    id="criteriaExtraDescription"
                                    place={'top'}
                                    type={'light'}
                                    effect={'solid'}
                                    border={true}
                                    data-offset="{'top': 0, 'left': 0}"
                                >
                                    <p className="tooltip">
                                        Укажите описание дополнительного критерия
                                    </p>
                                </ReactTooltip>
                            </Field>
                        </Col>
                    </Row>
                )}
            </>
        );
        return (
            <Card isDraggable fade={fade}>
                <CardLine lineBlock={cardBlock} />
                <CardFooter
                    hasButtons
                    actionButtons={
                        test.criteria.length > 1 &&
                        !test.isUsed && (
                            <Button
                                className="TestRemoveButton"
                                criteriaid={id}
                                closeLink
                                onClick={removeCriteria}
                                size="sm"
                            >
                                Удалить
                            </Button>
                        )
                    }
                ></CardFooter>
            </Card>
        );
    };

    const onDragEnd = (result) => {
        const { source, destination } = result;

        if (!source || !destination || source.droppableId !== destination.droppableId) {
            return;
        }
        if (source.index === destination.index) {
            return;
        }

        const selectedFields = [...test.criteria];
        const movedField = selectedFields[source.index];

        selectedFields.splice(source.index, 1);
        selectedFields.splice(destination.index, 0, movedField);

        const newCriteria = selectedFields.map((x, index) => ({ ...x, position: index + 1 }));
        const criteriaOrder = newCriteria.map((x) => x.id);
        setTest({ ...test, criteria: newCriteria, criteriaOrder: criteriaOrder });
    };

    return (
        <Grid fluid>
            <Row className="TestCard">
                <Col xs={0} xl={2} />
                <Col xs={12} xl={8}>
                    <PageCardWrapper
                        header={type === 'edit' ? `Редактирование теста` : `Новый тест`}
                        footer={renderPageCardWRapperFooter()}
                        size="sm"
                    >
                        {renderTestCard()}
                        <Card>
                            <CardHeader header="Критерии теста" />
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="list">
                                    {(provided, snapshot) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef}>
                                            {renderCriterias(snapshot.draggingOverWith)}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Card>
                    </PageCardWrapper>
                </Col>
                <Col xs={0} xl={2} />
            </Row>
        </Grid>
    );
};

const actions = {
    showErrorAlert,
    showSuccessAlert,
    showPageLoader,
    hidePageLoader,
    showWarningAlert,
    push,
};
export default connect(null, actions)(Test);
