import React from 'react';
import { DataCard } from 'components/Lk/Uikit/DataCard';
import RenderPerson from 'components/Lk/Shared/Evaluate/RenderPerson';
import classnames from 'classnames';
import StyledCheckBox from 'components/Lk/Uikit/StyledCheckBox';
import { CHECKBOX_TYPES } from 'components/Lk/Uikit/StyledCheckBox/constants';
import './WishList.scss';

const WishList = props => {
    const {
        tempBasket,
        personClick,
        items,
        dataTest,
        withCheckboxes,
        onCheckAll,
        selectedIds = [],
        loadPersons,
        isPersonsLoading,
    } = props;

    const onCheckWishList = (persons, wishlistId) => (check, e) => {
        e.stopPropagation();
        onCheckAll(persons, check, wishlistId);
    };

    const renderWishList = wishList => {
        if (!withCheckboxes) {
            return (
                <DataCard
                    key={wishList.id}
                    id={wishList.id}
                    title={wishList.name}
                    className="LKDataCard--Line"
                    titleModifire="accordion"
                    isCount
                    dataTest={`${dataTest}_DataCard`}
                >
                    {wishList.persons?.length > 0 ? (
                        <RenderPerson
                            noDataText="В этой подборке еще никого нет"
                            data={wishList.persons}
                            basket={tempBasket}
                            {...personClick}
                            userId
                            isUser={true}
                        />
                    ) : (
                        <div className="LKMainTeams__NoTeamData">
                            В этой подборке пока никого нет
                        </div>
                    )}
                </DataCard>
            );
        }

        const personsInWishList = wishList.personUsers.length;

        const personIds = wishList.personUsers?.filter(x => x.userId).map(x => x.userId) || [];
        const iconsStartFrom = +(
            personIds.some(x => selectedIds.indexOf(x) >= 0) && personIds.length !== 0
        );
        const checkboxes = CHECKBOX_TYPES.TRIPLE.slice(iconsStartFrom);

        const personsInWishListRegistered = personIds.length;
        const personsInWishListChecked = personIds.filter(x => selectedIds.indexOf(x) >= 0).length;

        const wishListPersons = wishList.personUsers;

        const getTitle =() =>
        {
            return wishList.name || wishList.isDefault ? (
                onCheckAll ? (
                    <div>
                        {wishList.isDefault ? `Моё избранное` : wishList.name} ({personsInWishList}/
                        <span
                            className={classnames(
                                'LKWishListTitle__PersonsInWishListRegistered',
                                {
                                    'LKWishListTitle__PersonsInWishListRegistered--grey':
                                        personsInWishListRegistered === 0,
                                },
                            )}
                        >
                            {personsInWishListRegistered}
                        </span>
                        )
                    </div>
                ) : (
                    <div>
                        {wishList.name} ({personsInWishList})
                    </div>
                )
            ) : (
                `Подборка (${personsInWishList})`
            )
        };
        
        const wishListCheckBox = (
            <>
            {onCheckAll && (
                <StyledCheckBox
                    checkboxes={checkboxes}
                    onChange={personsInWishListRegistered > 0 && onCheckWishList(wishListPersons, wishList.id)}
                    checked={personIds.every(x => selectedIds.indexOf(x) >= 0) && personIds.length !== 0}
                    disabled={Object.keys(isPersonsLoading).some(wishlistId => isPersonsLoading[wishlistId])
                    || personsInWishListRegistered === 0}
                />
            )}
            </>
        );

        const wishListTitle = (
            <div className="LkWishListTitleWrapper">
                <div className="LKWishListTitle">
                   {getTitle()}

                </div>
                {onCheckAll && (
                    <div className="LKWishListTitle__PersonsInWishListChecked">
                        {personsInWishListChecked}
                    </div>
                )}
            </div>
        );

        const wishListSubTitle = (
            <div className="LKWishListSubTitle">
                <div className="LKWishListSubTitle__Data">
                    <div className="LKWishListSubTitle__AllAndRegisteredPersonsCol">
                        <div className="LKWishListSubTitle__AllPersonsCol">
                            Всего: {personsInWishList}
                        </div>
                        <div>Подключено: {personsInWishListRegistered}</div>
                    </div>
                    <div>Выбрано: {personsInWishListChecked}</div>
                </div>
            </div>
        );

        return (
            <DataCard
                key={wishList.id}
                id={wishList.id}
                title={wishListTitle}
                titleCheckbox={wishListCheckBox}
                titleClassName={classnames('LKDataCard__Title', {
                    'LKDataCard__Title--wide': withCheckboxes,
                })}
                chevronClassName={classnames({ LKDataCard__ChevronButton: withCheckboxes })}
                titleModifire="accordionWide"
                defaultIsOpen={false}
                unwrap={(!wishList.persons || (wishList.persons && wishList.persons.length === 0)) && loadPersons(wishList.id, true)}
                loading={wishList.id in isPersonsLoading &&  isPersonsLoading[wishList.id]}
                disabled={Object.keys(isPersonsLoading).some(wishlistId => isPersonsLoading[wishlistId])}
                dataTest={`${dataTest}_DataCard`}
            >
                {wishList.persons?.length > 0 ? (
                    <>
                        {wishListSubTitle}
                        <RenderPerson
                            key={wishList.id}
                            noDataText="В этой подборке еще никого нет"
                            data={wishList.persons}
                            basket={tempBasket}
                            {...personClick}
                            userId
                            littleMargin
                            isUser={true}
                        />
                    </>
                ) : (
                    <div className="LKMainTeams__NoTeamData">В этой подборке пока никого нет</div>
                )}
            </DataCard>
        );
    };

    return (
        <div className={withCheckboxes ? 'LKMainWishLists__WishList' : ''}>
            {items.map(item => renderWishList(item))}
        </div>
    );
};

export default WishList;
