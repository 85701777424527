import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { lkDetailsEvaluateCart } from 'routes';
import classnames from 'classnames';
import { ACTIVE_DETAIL_CARD, EVALUATION_TYPE } from 'components/Lk/Basic/constants';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';
import { evaluateCardMenuItems } from 'components/Lk/Basic/MainCard/constants';
import RoundedButton from 'components/Lk/Uikit/Buttons/RoundedButton';
import { fetchTeams } from 'ducks/Teams';
import { changeBasket } from 'ducks/Baskets';
import { acceptChoice, renderEvaluationType } from './Utils'
import { fetchEvaluation, setSelectes } from 'ducks/Evaluation';
import Slider from 'components/Lk/Uikit/Slider/Slider';
import StickyFooter from 'components/Lk/Common/StickyFooter';
import TeamList from 'components/Lk/Shared/GroupList/TeamList.js';

import './EvaluationTeam.scss';

const EvaluationTeam = (props) => {
    const { activeCard, setActiveCard, push } = props;
    
    const [preselectionBasket, setPreselectionBasket] = useState(props.preselectionBasket);
    const [resultIds, setResultIds] = useState([]);

    const refItem = React.createRef();
    const useRefItem = useRef(true);

    setActiveCard(ACTIVE_DETAIL_CARD);
    const isMobileActive = activeCard === ACTIVE_DETAIL_CARD;

    const isInCart = personId => {
        return (
            preselectionBasket &&
            preselectionBasket.persons &&
            preselectionBasket.persons.find(x => +x.person.id === +personId)
        );
    };

    const getSelectedIds = () => {
        return (
            preselectionBasket &&
            preselectionBasket.persons &&
            preselectionBasket.persons.map(x => x.person.id)
        );
    };

    useEffect(() => {
        setResultIds(EVALUATION_TYPE.map(item => { return item.id }));
    }, []);

    useEffect(() => {
        setPreselectionBasket(props.preselectionBasket);
    }, [props.preselectionBasket]);

    useEffect(() => {
        useRefItem.current = refItem.current.offsetWidth;
    }, [refItem]);

    const onSelectperson = (id, item) => e => {
        if (e)
        {
            e.stopPropagation();
            e.preventDefault();
        }
        const isOwn = preselectionBasket.persons && preselectionBasket.persons.some(x => x.person.id === id);
        let result = {};
        if (!isOwn) {
            result = {
                ...preselectionBasket,
                persons: [
                    ...preselectionBasket.persons,
                    {
                        person: item,
                        type: 'team'
                    }
                ],
                [id]: true
            }
        } else {
            delete preselectionBasket[id];
            result = {
                ...preselectionBasket,
                persons: preselectionBasket.persons.filter(item => item.person.id !== id),
            };
        }
        props.changeBasket("preselectionEvaluate", result);
    }

    const onCheckAll = (items, isCheck) => {
        const personIds = items.map(x => x.id);
        let result = {
                ...preselectionBasket,
                persons: isCheck
                    ? preselectionBasket.persons
                        .filter(x => personIds.indexOf(x.person.id) === -1)
                        .concat(items.filter(x => x.id).map(x => ({person: x, type: 'team'})))
                    : preselectionBasket.persons
                        .filter(x => personIds.indexOf(x.person.id) === -1)
        };
        props.changeBasket("preselectionEvaluate", result);
    }
    
    const renderTeams = () => {
        return (
            <TeamList 
                onPersonSelect={onSelectperson}
                isInCart={isInCart}
                selectedIds={getSelectedIds()} 
                withCheckbox
                onCheckAll={onCheckAll}
                type="evaluate"
                {...props}
            >
            </TeamList>);
    };

    const slideChange = (e, array) => {
        const param = {
            id: array[e],
            key: e
        }
        props.setSelectes(param);
    }

    const personsSelected = () => {
        return preselectionBasket.persons && preselectionBasket.persons.length > 0;
    }

    const onSubmit = () => {
        acceptChoice(props.changeBasket, preselectionBasket);
        push(lkDetailsEvaluateCart.url);
    }

    return (
        <>
            <div className={classnames('DetailsCard', { 'DetailsCard--isMobileActive': isMobileActive })}>
                <MainCardMenu main menuItems={evaluateCardMenuItems} />
                <div className="EvaluationTeam">
                    <div className='EvaluationTeam__Wrapper'>
                        <div className="EvaluationSearch__Item" ref={refItem}>
                            <div>
                                {EVALUATION_TYPE && <Slider
                                    data={renderEvaluationType(EVALUATION_TYPE)}
                                    initialSlide={props.selectedTest.key}
                                    slideChange={slideChange}
                                    resultIds={resultIds}
                                />}
                            </div>
                            {renderTeams()}
                        </div>
                    </div>
                </div>
                {preselectionBasket.persons.length > 0 && (
                    <StickyFooter>
                        {personsSelected() && <RoundedButton onClick={onSubmit}>Выбрать ({preselectionBasket.persons && preselectionBasket.persons.length})</RoundedButton>}
                    </StickyFooter>
                )}
            </div>
        </>
    );
};

EvaluationTeam.propTypes = {
    activeCard: PropTypes.string.isRequired,
    setActiveCard: PropTypes.func.isRequired,
};

const mapStateToProps = state => (
    {
        personId: state.auth.user.personId,
        teams: state.teams,
        selectedTest: state.evaluation.selected,
        preselectionBasket: state.baskets.preselectionEvaluate.basket,
        basket: state.baskets.evaluate.basket,
    }
);
const actions = { fetchTeams, fetchEvaluation, changeBasket, setSelectes, push };

export default connect(mapStateToProps, actions)(EvaluationTeam);