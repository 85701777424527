import React from 'react';
import WishList from 'components/Lk/Shared/GroupList/WishList';
import Filters from './Filters';

const FavoriteProgressCard = props => {
    const { selectedSorting, setSelectedSorting, selectedFilter, setSelectedFilter, onPersonSelect, isInCart, filterGroups, sortGroups } = props;

    return (
        <div className='LKMainProgressWrapper'>
            <Filters 
                selectedSorting={selectedSorting}
                setSelectedSorting={setSelectedSorting}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
            />
            <div className="LKScrollWrapper">
                <WishList 
                    wishListType='progress'
                    onPersonSelect={onPersonSelect}
                    withCheckboxes
                    isInCart={isInCart}
                    {...props} 
                    filters={[
                        {func: filterGroups, params: [selectedFilter]}, 
                        {func: sortGroups, params: [selectedSorting]}
                    ]} 
                />
            </div>
        </div>
    );
};


export default FavoriteProgressCard;