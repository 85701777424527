import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-flexbox-grid';
import IconButton from 'components/Lk/Uikit/Buttons/IconButton';
import classnames from 'classnames';
import { personStatus } from './Persons';

const PersonsTable = (props) => {
    const { persons, togglePerson, onDeleteClick } = props;

    return (
        <div className="AdminPersonsTable">
            {persons.map((x) => {
                return (
                    <Row
                        key={`${x.id}_${x.lastName}`}
                        className={classnames('AdminPersonsTable__Row', {
                            'AdminPersonsTable__Row--error': !x.found,
                        })}
                        onClick={() => x.isActionAllowed && togglePerson(x.id)}
                    >
                        <Col xs={2}>
                            <div
                                className={classnames('AdminPersonsTable__Check', {
                                    'AdminPersonsTable__Check--hidden': !x.isActionAllowed,
                                })}
                            >
                                <input
                                    disabled={!x.found || !x.isActionAllowed}
                                    type="checkbox"
                                    checked={x.checked && x.isActionAllowed}
                                    onChange={() => x.isActionAllowed && togglePerson(x.id)}
                                />
                                {x.id}
                            </div>
                        </Col>
                        <Col style={{ overflow: 'hidden' }} xs={6}>
                            {x.lastName}
                        </Col>
                        {
                            <Col xs={3}>
                                <Row end="xs">
                                    <div style={{ paddingRight: '20px' }}>
                                        {!x.found
                                            ? 'Анкета не найдена'
                                            : x.status === personStatus.Active
                                            ? 'Активна'
                                            : 'Удалена'}
                                    </div>
                                </Row>
                            </Col>
                        }
                        <Col xs={1}>
                            <Row center="xs">
                                <IconButton
                                    className="AdminPersonsTable__Icon"
                                    type="iconCloseFilled"
                                    fill="#e54848"
                                    size="sm"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onDeleteClick(x);
                                    }}
                                />
                            </Row>
                        </Col>
                    </Row>
                );
            })}
        </div>
    );
};

PersonsTable.propTypes = {
    persons: PropTypes.array.isRequired,
    togglePerson: PropTypes.func.isRequired,
};

export default PersonsTable;
