import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import debounce from 'debounce-promise';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AsyncSelect } from 'components/uikit/Select';
import { showErrorAlert } from 'ducks/Alert';
import { searchForUsers } from 'ducks/Search';
import { ROLE_MODERATOR_ID, ROLE_OPERATOR_ID, ROLE_USER_ID, ROLE_LEADER_ID } from 'constants.js';
import './UserSearchSelect.scss';

const search = (onError) => async (inputValue) => {
    const criteria = {
        searchTerm: inputValue,
        filterRoles: [
            ROLE_MODERATOR_ID,
            ROLE_OPERATOR_ID,
            ROLE_USER_ID,
            ROLE_LEADER_ID
        ],
        paging: {
            pageNum: 1,
            pageSize: 10
        }
    };

    const result = await searchForUsers(criteria);
    if (result.ok) {
        const users = result.ok.payload.data.payload;
        const options = users.map((user) => ({
            value: user,
            label: `${user.fullName}`,
        }));
        return options;
    }

    onError(result.error.message);
    return [];
};

const renderOptionLabel = ({ value: user }, { context }) => {
    const fullName = user && user.fullName;

    const isSelected = context === 'value';
    const containerClassName = classnames(
        'UserSearchOptionContainer',
        { 'UserSearchOptionContainer--Selected': isSelected }
    );

    return (
        <div className={containerClassName}>
            <div className='UserSearchOption'>
                <span className='UserSearchOption__Name'>{fullName}</span>
            </div>
        </div>
    );
}

export const UserSearchSelect = (props) => {
    const { onChange, onError, className, placeholderText, dataTest, ...selectProps } = props;
    delete selectProps.style;

    const [showPlaceholder, setPlaceholder] = useState(true);
    // eslint-disable-next-line
    const loadOptions = useCallback(
        debounce(search(onError), 500),
        [onError]
    );

    return (
        <div className={className}>
            <div className="UserSearchSelect">
            <FontAwesomeIcon className='UserSearchSelect__FixedIcon' icon={faSearch} size="lg" />
            <AsyncSelect
                placeholder={showPlaceholder && <span className='UserSearchSelectPlaceholder'>{placeholderText}</span>}
                cacheOptions={false}
                defaultOptions={false}

                isClearable={true}
                loadOptions={loadOptions}
                formatOptionLabel={renderOptionLabel}
                onFocus={() => setPlaceholder(false)}
                onBlur={() => setPlaceholder(true)}
                onChange={onChange}
                data-test={dataTest}
                {...selectProps}
            /></div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    onError: bindActionCreators(showErrorAlert, dispatch),
});

export default connect(null, mapDispatchToProps)(UserSearchSelect);
