import React, { Component } from 'react';
import InputMask from '../InputMask';
import { phoneMaskWithoutRegion, dialCode } from 'constants.js';
import './PhoneInput.scss';
import Select from '../Select';
import { isNullOrWhitespace } from 'utils';

class PhoneInput extends Component {
    regionCode = this.props.value
        ? '+' + this.props.value.replace(/[^0-9]/g, '').slice(0, -10)
        : null;
    phone = this.props.value ? this.props.value.slice(this.regionCode.length) : '';

    currentDialCode = dialCode.find((d) => d.id === this.regionCode);

    state = {
        regionValue:
            this.regionCode && this.currentDialCode
                ? this.currentDialCode
                : { name: '+7 (Россия)', id: '+7' },
        phoneValue: this.phone,
        regionCodeFromProps: this.props.value ? this.props.value.replace(/[^0-9]/g, '') : null,
    };

    handlePhoneChange = (phone) => {
        if (isNullOrWhitespace(phone)) {
            this.props.onChange('');
            this.setState({ phoneValue: '' });
            return;
        }

        let region = this.state.regionValue;
        if (region && region.id && this.props.onChange) {
            let fullPhone = region.id.concat(' ', phone.trim());
            this.props.onChange(fullPhone);
        }

        this.setState({ phoneValue: phone });
    };

    componentDidUpdate(prevProps) {
        const { value } = this.props;

        if (!value && prevProps.value) {
            this.handlePhoneChange('');
        }
    }

    handleRegionChange = (region) => {
        let phoneValue = this.state.phoneValue ? this.state.phoneValue.trim() : '';
        let regionValue = region.id;

        if (regionValue && this.props.onChange) {
            let fullPhone = regionValue.concat(' ', phoneValue);
            this.props.onChange(fullPhone);
        }

        this.setState({ regionValue: region });
    };

    render() {
        return (
            <div className="PhoneInput__Container">
                <Select
                    className="RegionSelect"
                    id="phoneRegion"
                    value={this.state.regionValue}
                    onChange={(e) => this.handleRegionChange(e)}
                    options={dialCode}
                    catalog
                    isDisabled={this.props.disabled}
                />
                <InputMask
                    mask={phoneMaskWithoutRegion}
                    maskChar="_"
                    value={this.state.phoneValue}
                    onChange={(e) => this.handlePhoneChange(e.target.value)}
                    placeholder=""
                    disabled={this.props.disabled}
                />
            </div>
        );
    }
}

export default PhoneInput;
