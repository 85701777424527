import React from 'react';
import Input from 'components/Lk/Uikit/Input';
import Button from 'components/Lk/Uikit/Buttons/Button';
import './SearchLine.scss';

const SearchLine = props => {
    const {
        searchTerm,
        onChange,
        onKeyPress,
        onSearch,
        inputDataTest,
        searchButtonDatatTest,
    } = props;
    return (
        <div className="LKSearchLine">
            <Input
                value={searchTerm}
                onChange={onChange}
                onKeyPress={onKeyPress}
                data-test={inputDataTest}
            />
            <Button onClick={e => onSearch(e, true)} data-test={searchButtonDatatTest} />
        </div>
    );
};

export default SearchLine;
