import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router';
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import { ALLOWED_LK_ROLES } from 'rightsController';

import {
    lkDetailsEvaluateTeam, lkDetailsEvaluatePersonResults, lkDetailsProfile,
    lkDetailsProfilePersonsWithoutResults, lkDetailsEvaluatefavorites, lkDetailsEvaluateSearch,
    lkDetailsEvaluateTeamResults, lkDetailsEvaluateFavoritesResults, lkDetailsEvaluateSearchResults,
    lkWidgetsTeamGrowthProfile, lkTeamsProfile, lkDetailsEvaluateTeamProfile,
    lkWidgetsBestInGroupProfile, lkDetailsAdvices, lkEvaluateCartProfile, lkProgressCartProfile,
    lkProgressPersonProfile, lkProgressFavotiteProfile, lkProgressTeamProfile, lkNewIssueSelectImplementer, lkIssueDone, lkIssueDeferred,
    lkDetailsEditWishLists, lkIssueEdit,  lkIssueDraftSelectImplementer, lkIssueTrackingSelect, lkIssueEditSelectImplementer, 
    lkIssueDraft, lkIssueTrackingProfile, lkDetailsEvaluateCart, lkPersonPlanComment, lkIssueTrackingEdit, lkDetailsEditWishList,

} from 'routes';
import EvaluationTeam from 'components/Lk/Shared/Evaluate/EvaluationTeam';
import EvaluationResultTeam from 'components/Lk/Shared/Evaluate/EvaluationResultTeam';
import EvaluationFavorite from 'components/Lk/Shared/Evaluate/EvaluationFavorite';
import EvaluationSearch from 'components/Lk/Shared/Evaluate/EvaluationSearch';
import EvaluationPersonResults from 'components/Lk/Shared/Evaluate/EvaluationPersonResults';
import ProfileCard from 'components/Lk/Shared/Profile/ProfileCard';
import SelectImplementer from 'components/Lk/Shared/Tasks/SelectImplementer';
import Advices from 'components/Lk/Shared/Advices/Advices';
import EvaluationResult from 'components/Lk/Shared/Evaluate/EvaluationResult';
import EditWishLists from 'components/Lk/Shared/WishLists/EditWishLists';
import IssueItemPageDetail from 'components/Lk/Shared/Issues/IssueItemPageDetail';
import AssignedIssue from 'components/Lk/Shared/Tracking/AssignedIssue';
import EvaluationWishListResult from 'components/Lk/Shared/Evaluate/EvaluationWishListResult';
import PersonCart from 'components/Lk/Common/PersonCart';
import { evaluateCardMenuItems } from 'components/Lk/Basic/MainCard/constants';
import { ACTIVE_DETAIL_CARD } from 'components/Lk/Basic/constants';
import PersonPlanCommentPage from 'components/Lk/Shared/Issues/PersonPlanCommentPage';
import NewIssue from 'components/Lk/Shared/Tasks/NewIssue';
import WishlistCard from 'components/Lk/Shared/Search/ManageWishlists/WishlistCard';

const DetailsCard = (props) => {
    const { activeCard, setActiveCard } = props;
    return (
        <Switch>
            <ProtectedRoute
                path={lkDetailsEvaluateTeam.url}
                render={(props) => <EvaluationTeam {...props} activeCard={activeCard} setActiveCard={setActiveCard} withSelect/>}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={lkDetailsEvaluateTeamResults.url}
                render={(props) => <EvaluationResultTeam {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={lkDetailsEvaluateFavoritesResults.url}
                render={(props) => <EvaluationWishListResult {...props} fetchType='favorites' activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={lkDetailsEvaluateSearchResults.url}
                render={(props) => <EvaluationResult {...props} fetchType='search' activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={lkDetailsEvaluatefavorites.url}
                render={(props) => <EvaluationFavorite {...props} activeCard={activeCard} setActiveCard={setActiveCard} withSelect />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={lkDetailsEvaluateSearch.url}
                render={(props) => <EvaluationSearch {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={lkDetailsEvaluatePersonResults.url}
                render={(props) => <EvaluationPersonResults {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={[lkDetailsProfile.url,lkIssueTrackingProfile.url]}
                render={(props) => <ProfileCard {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={lkDetailsProfilePersonsWithoutResults.url}
                render={(props) => <ProfileCard {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={lkWidgetsTeamGrowthProfile.url}
                render={(props) => <ProfileCard {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={lkTeamsProfile.url} 
                render={(props) => <ProfileCard {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES} 
            />
            <ProtectedRoute
                path={lkNewIssueSelectImplementer.url} 
                render={(props) => <SelectImplementer {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES} 
            />
            <ProtectedRoute
                path={lkDetailsEvaluateTeamProfile.url}
                render={(props) => <ProfileCard {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={lkWidgetsBestInGroupProfile.url}
                render={(props) => <ProfileCard {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={lkDetailsAdvices.url}
                render={(props) => <Advices {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={lkEvaluateCartProfile.url}
                render={(props) => <ProfileCard {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={lkProgressCartProfile.url}
                render={(props) => <ProfileCard {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            
            <ProtectedRoute
                path={lkIssueTrackingEdit.url}
                render={(props) => <NewIssue {...props} returnUrl={lkIssueTrackingSelect} isEdit activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            
            <ProtectedRoute
                path={lkIssueTrackingSelect.url}
                render={(props) => <AssignedIssue {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={lkProgressTeamProfile.url}
                render={(props) => <ProfileCard {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={lkProgressFavotiteProfile.url}
                render={(props) => <ProfileCard {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={lkProgressPersonProfile.url}
                render={(props) => <ProfileCard {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute
                path={lkDetailsEditWishLists.url}
                render={(props) => <EditWishLists {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />
            <ProtectedRoute 
                path={[lkIssueDone.url,lkIssueDeferred.url]}
                render={(props) => <IssueItemPageDetail {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />

            <ProtectedRoute
                path={lkPersonPlanComment.url}
                render={(props) => <PersonPlanCommentPage {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />

            <ProtectedRoute
                path={lkNewIssueSelectImplementer.url} 
                render={(props) => <SelectImplementer {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES} 
            />
             <ProtectedRoute
                path={lkIssueEditSelectImplementer.url} 
                render={(props) => <SelectImplementer {...props}  activeCard={activeCard} setActiveCard={setActiveCard} returnUrl={lkIssueEdit} />}
                allowedRoles={ALLOWED_LK_ROLES} 
            />
            <ProtectedRoute
                path={lkIssueDraftSelectImplementer.url} 
                render={(props) => <SelectImplementer {...props} activeCard={activeCard} setActiveCard={setActiveCard} returnUrl={lkIssueDraft} />}
                allowedRoles={ALLOWED_LK_ROLES} 
            />
            <ProtectedRoute
                path={lkDetailsEvaluateCart.url}
                render={(props) => 
                <PersonCart 
                    {...props} 
                    activeCard={ACTIVE_DETAIL_CARD} 
                    setActiveCard={setActiveCard} 
                    menuItems = {evaluateCardMenuItems}
                    type = 'evaluate'
                    profileRoute={lkEvaluateCartProfile}
                />}
                allowedRoles={ALLOWED_LK_ROLES} 
            />
            <ProtectedRoute
                path={lkDetailsEditWishList.url}
                render={(props) => <WishlistCard {...props} activeCard={activeCard} setActiveCard={setActiveCard} />}
                allowedRoles={ALLOWED_LK_ROLES}
            />

        </Switch>
    );
};

DetailsCard.propTypes = {
    activeCard: PropTypes.string.isRequired,
    setActiveCard: PropTypes.func.isRequired,
};

export default DetailsCard;