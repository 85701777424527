import React from "react";

import classNames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

import "./ChevronButton.scss";

function ChevronButton(props) {

    const {
        isUp,
        className,
        disabled = false,
        ...tail
    } = props;

    const buttonClass = classNames("ChevronButton", { "ChevronButton--open": isUp, "ChevronButton--disabled": disabled }, className);

    return (
        <button type="button" className={buttonClass} {...tail}>
            <FontAwesomeIcon icon={faChevronUp} />
        </button>
    );
}

export default ChevronButton;