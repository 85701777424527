export const MapRegions = [
    {regionName: "Adygey", regionCode: "01"},
    {regionName: "Altay", regionCode: "04"},
    {regionName: "Gorno-Altay", regionCode: "22"},
    {regionName: "Amur", regionCode: "28"},
    {regionName: "Arkhangel'sk", regionCode: "29"},
    {regionName: "Astrakhan'", regionCode: "30"},
    {regionName: "Bashkortostan", regionCode: "02"},
    {regionName: "Belgorod", regionCode: "31"},
    {regionName: "Bryansk", regionCode: "32"},
    {regionName: "Buryat", regionCode: "03"},
    {regionName: "Vladimir", regionCode: "33"},
    {regionName: "Volgograd", regionCode: "34"},
    {regionName: "Vologda", regionCode: "35"},
    {regionName: "Voronezh", regionCode: "36"},
    {regionName: "Dagestan", regionCode: "05"},
    {regionName: "Yevrey", regionCode: "79"},
    {regionName: "Zabaykal'ye", regionCode: "75"},
    {regionName: "Ivanovo", regionCode: "37"},
    {regionName: "Ingush", regionCode: "06"},
    {regionName: "Irkutsk", regionCode: "38"},
    {regionName: "Kabardin-Balkar", regionCode: "07"},
    {regionName: "Kaliningrad", regionCode: "39"},
    {regionName: "Kalmyk", regionCode: "08"},
    {regionName: "Kaluga", regionCode: "40"},
    {regionName: "Kamchatka", regionCode: "41"},
    {regionName: "Karachay-Cherkess", regionCode: "09"},
    {regionName: "Karelia", regionCode: "10"},
    {regionName: "Kemerovo", regionCode: "42"},
    {regionName: "Kirov", regionCode: "43"},
    {regionName: "Komi", regionCode: "11"},
    {regionName: "Kostroma", regionCode: "44"},
    {regionName: "Krasnodar", regionCode: "23"},
    {regionName: "Krasnoyarsk", regionCode: "24"},
    {regionName: "Crimea", regionCode: "91"},
    {regionName: "Kurgan", regionCode: "45"},
    {regionName: "Kursk", regionCode: "46"},
    {regionName: "Leningrad", regionCode: "47"},
    {regionName: "Lipetsk", regionCode: "48"},
    {regionName: "Magadan", regionCode: "49"},
    {regionName: "Mariy-El", regionCode: "12"},
    {regionName: "Mordovia", regionCode: "13"},
    {regionName: "Moscow City", regionCode: "77"},
    {regionName: "Moskva", regionCode: "50"},
    {regionName: "Murmansk", regionCode: "51"},
    {regionName: "Nenets", regionCode: "83"},
    {regionName: "Nizhegorod", regionCode: "52"},
    {regionName: "Novgorod", regionCode: "53"},
    {regionName: "Novosibirsk", regionCode: "54"},
    {regionName: "Omsk", regionCode: "55"},
    {regionName: "Orenburg", regionCode: "56"},
    {regionName: "Orel", regionCode: "57"},
    {regionName: "Penza", regionCode: "58"},
    {regionName: "Perm'", regionCode: "59"},
    {regionName: "Primor'ye", regionCode: "25"},
    {regionName: "Pskov", regionCode: "60"},
    {regionName: "Rostov", regionCode: "61"},
    {regionName: "Ryazan'", regionCode: "62"},
    {regionName: "Samara", regionCode: "63"},
    {regionName: "Saint Petersburg City", regionCode: "78"},
    {regionName: "Saratov", regionCode: "64"},
    {regionName: "Sakha", regionCode: "14"},
    {regionName: "Sakhalin", regionCode: "65"},
    {regionName: "Sverdlovsk", regionCode: "66"},
    {regionName: "Sevastolol'", regionCode: "92"},
    {regionName: "North Ossetia", regionCode: "15"},
    {regionName: "Smolensk", regionCode: "67"},
    {regionName: "Stavropol'", regionCode: "26"},
    {regionName: "Tambov", regionCode: "68"},
    {regionName: "Tatarstan", regionCode: "16"},
    {regionName: "Tver'", regionCode: "69"},
    {regionName: "Tomsk", regionCode: "70"},
    {regionName: "Tula", regionCode: "71"},
    {regionName: "Tuva", regionCode: "17"},
    {regionName: "Tyumen'", regionCode: "72"},
    {regionName: "Udmurt", regionCode: "18"},
    {regionName: "Ul'yanovsk", regionCode: "73"},
    {regionName: "Khabarovsk", regionCode: "27"},
    {regionName: "Khakass", regionCode: "19"},
    {regionName: "Khanty-Mansiy", regionCode: "86"},
    {regionName: "Chelyabinsk", regionCode: "74"},
    {regionName: "Chechnya", regionCode: "20"},
    {regionName: "Chuvash", regionCode: "21"},
    {regionName: "Chukot", regionCode: "87"},
    {regionName: "Yamal-Nenets", regionCode: "89"},
    {regionName: "Yaroslavl'", regionCode: "76"},    
];

export const colors = [
    "#edf9fc", "#e2f6fc", "#cbeef9", "#a1dbed", "#70d3f1", "#2dc2e1", "#00a5c8", "#09a6ac", "#15937a", "#008176", 
    "#007081", "#026897", "#024f7f", "#053d72", "#093284", "#092084", "#360dab", "#1f046f", "#2e2547", "#1a1528",
];