import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import {
    deleteCategory,
    deleteContent,
    updateTreePositions,
    searchContentCategories,
    getContentCategories,
} from 'api';
import TreeEditMenu from './TreeEditMenu';
import TreeNode from './TreeNode';
import Loader from 'components/common/Loader';
import './Tree.scss';
import { push } from 'connected-react-router';
import { instructionsRoute } from 'routes';

const initialState = {
    data: [],
    processing: false,
    itemsInSelectedCategory: {},
};

class Tree extends Component {
    state = initialState;

    componentDidMount() {
        const { data } = this.props;
        this.setState({ data });
    }

    async componentDidUpdate(prevProps, prevState) {
        const { data, selectedItem, itemFullAccess, loadedItem } = this.props;

        if (JSON.stringify(prevProps.data) !== JSON.stringify(data)) {
            this.setState({ data });
            selectedItem && this.onItemSelect(selectedItem);
        }

        if (loadedItem && prevProps.loadedItem?.id !== loadedItem?.id) {
            const updatedSelectedItem = this.updatedSelectedItem(loadedItem);
            this.props.selectItem(updatedSelectedItem);
        }

        if (selectedItem && JSON.stringify(prevState.data) !== JSON.stringify(this.state.data)) {
            const cat = this.state.data.find((x) => x?.id === selectedItem.parentId);
            if (cat && !cat.expanded && !cat.children) {
                await this.fetchChildren(cat.id);
            }
        }

        if (prevProps.itemFullAccess !== itemFullAccess) {
            this.onItemSelect(selectedItem);
        }
    }

    fetchChildren = async (parentId, removeChildren = false) => {
        const {
            editMode,
            centerGroupId,
            currentUser: { groupId },
        } = this.props;
        try {
            const children = !removeChildren
                ? editMode
                    ? await searchContentCategories({
                          parentId,
                          ...(groupId === centerGroupId ? {} : { groupIds: [groupId] }),
                      })
                    : await getContentCategories(parentId)
                : [];
            const childrenData = !removeChildren ? children.data : children;
            this.updateAllTreeNodes(parentId, childrenData);
        } catch (e) {
            this.props.showErrorAlert('Ошибка получения данных');
        }
    };

    updateAllTreeNodes = (parentId, children) => {
        const newData = this.updateInternalTreeDataWithChildren(
            parentId,
            children,
            this.state.data,
        );
        const newItemsInSelectedCategory = this.state.itemsInSelectedCategory
        newItemsInSelectedCategory[parentId] = children.length
        this.setState({ data: newData, itemsInSelectedCategory: newItemsInSelectedCategory });
    };

    updateInternalTreeDataWithChildren = (parentId, children, initialData = this.state.data) => {
        const newData = initialData.map((item) => {
            if (item.id === parentId) {
                item.expanded = children.length !== 0;
                item.children = children.map(x => ({...x, canMoveDown: x.position < children.length - 1}));
            }

            if (item?.children && item?.children?.length !== 0) {
                this.updateInternalTreeDataWithChildren(parentId, children, item.children);
            }
            return item;
        });
        return newData;
    };

    onItemSelect = (item) => {
        if (this.props.editMode) {
            const updatedSelectedItem = item && this.updatedSelectedItem(item);
            this.props.selectItem(updatedSelectedItem);
            this.props.onItemClick(updatedSelectedItem);
        } else {
            this.props.onItemClick(item);
        }
    };

    onDeleteItem = () => {
        const { selectedItem } = this.props;
        const { setModal } = this.props;
        if (!selectedItem) {
            return;
        }

        const modalHeader = `Удалить ${selectedItem.isArticle ? 'инструкцию ' : 'категорию'} "${
            selectedItem.title
        }"?`;
        setModal(modalHeader, () => this.deleteItem(selectedItem));
    };

    deleteItem = async (item) => {
        const {
            setModal,
            setModalProcessing,
            showErrorAlert,
            showSuccessAlert,
            deleteCallback,
        } = this.props;
        const { selectedItem } = this.props;

        try {
            setModalProcessing(true);

            if (item.isArticle) {
                await deleteContent(item.id);
            } else {
                await deleteCategory(item.id);
                this.removeItem(item)
            }

            await deleteCallback(item?.parentId);

            setModal('', undefined, false);
            showSuccessAlert(
                `${selectedItem.isArticle ? 'Инструкция' : 'Категория'}  успешно удалена`,
            );
        } catch (e) {
            showErrorAlert('Ошибка удаления');
        } finally {
            setModalProcessing(false);
        }
    };

    removeItem = (item, initialData = this.state.data) => {
        const { position } = item
        let sectionArray = initialData.map(x => x.position < position ? x : {...x, position: x.position - 1});
        sectionArray.splice(position, 1);
        this.updateItemsToRewrite(sectionArray);
    };

    moveElem = (parentId, position, moveUp = true, initialData = this.state.data) => {
        const { selectedItem } = this.props;
        let sectionArray = [];
        if (parentId === null) {
            sectionArray = initialData;
            const element = sectionArray[position];
            sectionArray.splice(position, 1);
            sectionArray.splice(moveUp ? position - 1 : position + 1, 0, element);
            const updatedSectionArray = sectionArray.map((x, index) => ({ ...x, position: index }));
            this.setState({ data: updatedSectionArray });
            this.onItemSelect({ ...selectedItem, position: moveUp ? position - 1 : position + 1 });
            this.updateItemsToRewrite(updatedSectionArray);
        } else {
            initialData.map((item) => {
                if (item.id === parentId) {
                    sectionArray = item.children;
                    const element = sectionArray[position];
                    sectionArray.splice(position, 1);
                    sectionArray.splice(moveUp ? position - 1 : position + 1, 0, element);
                    const updatedSectionArray = sectionArray.map((x, index) => ({
                        ...x,
                        position: index,
                    }));
                    item.children = updatedSectionArray;
                    this.onItemSelect({
                        ...selectedItem,
                        position: moveUp ? position - 1 : position + 1,
                    });
                    this.updateItemsToRewrite(updatedSectionArray);
                }

                if (item?.children && item?.children?.length !== 0) {
                    this.moveElem(parentId, position, moveUp, item.children);
                }
                return item;
            });
        }
    };

    updateItemsToRewrite = (array) => {
        try {
            this.saveTree(array);
        } catch (e) {
            this.props.showErrorAlert('Ошибка сохранения дерева категорий');
        }
    };

    saveTree = async (itemsToRewrite) => {
        const { showErrorAlert } = this.props;
        try {
            this.setState({ processing: true });
            await updateTreePositions(itemsToRewrite);
        } catch (e) {
            showErrorAlert('Ошибка сохранения дерева категорий');
        } finally {
            this.setState({ processing: false });
        }
    };

    updatedSelectedItem = (item) => {
        const { data, itemFullAccess } = this.props;

        const updatedItem = {
            ...item,
            canAddCategory: true,
            canAddArticle: !item.isArticle,
            canDelete: (item.isArticle || (!item.isArticle && !item.hasChildren)) && itemFullAccess,
            canMoveUp: item.position !== 0,
            canMoveDown: item.position < data.length - 1,
            canSaveTree: true,
        };
        return updatedItem;
    };

    createItem = () => {
        const { selectedItem } = this.props;
        this.props.onItemClick({ id: 'new', isArticle: true, parentId: selectedItem?.id }, true);
    };

    createCategory = () => {
        this.props.onItemClick(
            {
                id: null,
                title: '',
                isArticle: false,
                parentId: null,
            },
            true,
        );
    };

    render() {
        const { data, processing } = this.state;
        const {
            loading,
            editMode = false,
            selectedItem,
            centerGroupId,
            push,
            refreshBranchData,
            setModal,
        } = this.props;
        const extItems = [
            {
                key: 'lokAt',
                title: 'Переход в режим просмотра под ролями',
                icon: 'eye',
                onClick: () => {
                    push(instructionsRoute.buildUrl(), { lookAs: true });
                },
            },
        ];

        return (
            <>
                {
                    <div className="Tree">
                        {editMode && (
                            <TreeEditMenu
                                selectedItem={selectedItem}
                                itemsInCategory= {this.state.itemsInSelectedCategory}
                                refreshTree={this.props.refreshTree}
                                deleteItem={this.onDeleteItem}
                                moveElem={this.moveElem}
                                createItem={this.createItem}
                                createCategory={this.createCategory}
                                saveTree={this.saveTree}
                                extItems={extItems}
                                disabled={processing}
                            />
                        )}
                        {loading ? (
                            <Loader />
                        ) : data.length === 0 ? (
                            <div className="NoDataTree">Нет данных</div>
                        ) : (
                            data.map((x, index) => (
                                <TreeNode
                                    key={x.id}
                                    item={{ ...x, itemsInCategory: data.length, position: index }}
                                    onItemSelect={this.onItemSelect}
                                    selectedItem={selectedItem}
                                    updateTreeData={this.updateAllTreeNodes}
                                    fullSearch={editMode}
                                    selectedId={this.props.selectedId}
                                    active={x.status === 'Published' || !x.isArticle}
                                    centerGroupId={centerGroupId}
                                    lastNode={data.length - 1 === index}
                                    refreshBranchData={refreshBranchData}
                                    refreshRoot={this.props.refreshRoot}
                                    treeData={data}
                                    removeItemFromParent={this.props.removeItemFromParent}
                                    setModal={setModal}
                                    editMode={editMode}
                                />
                            ))
                        )}
                    </div>
                }
                {this.state.processing && <Loader absolute />}
            </>
        );
    }
}

const mapStateToProps = (state) => ({ currentUser: state.auth.user });
const actions = { showErrorAlert, showSuccessAlert, push };
export default connect(mapStateToProps, actions)(Tree);
