import { faExternalLinkAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import debounce from 'debounce-promise';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { photoSizes, getPersonPhotoUri } from 'api';
import Image from 'components/common/Image';
import Field from 'components/uikit/Field';
import { AsyncSelect } from 'components/uikit/Select';
import { showErrorAlert } from 'ducks/Alert';
import { searchForPersons } from 'ducks/Search';
import { person as personRoute } from 'routes';
import './PersonSearchSelect.scss';

const search = (onError, filter) => async (inputValue) => {
    const criteria = {
        searchTerm: inputValue,
        paging: {
            pageNum: 1,
            pageSize: 10
        },
        filter
    };

    const result = await searchForPersons(criteria);
    if (result.ok)
    {
        const persons = result.ok.payload.data.payload;
        const options = persons.map((person) => ({
            value: person,
            label: `${person.lastName} ${person.firstName} ${person.middleName}`,
        }));
        return options;
    }

    onError(result.error.message);
    return [];
};

const renderOptionLabel = ({ value: person }, { context }) => {
    const fullName = `${person.lastName} ${person.firstName} ${person.middleName}`;

    const isSelected = context === 'value';
    const containerClassName = classnames(
        'PersonSearchOptionContainer',
        { 'PersonSearchOptionContainer--Selected': isSelected }
    );

    const handleImageClick =
        isSelected
            ? () => window.open(personRoute.buildUrl({ id: person.id }), '_blank noopener noreferrer')
            : () => {};

    return (
        <div className={containerClassName}>
            <div className='PersonSearchOption'>
                <div className='PersonSearchOptionImage'>
                    <Image
                        className='PersonSearchOptionImage__Img'
                        alt='Фотография'
                        photoUri={getPersonPhotoUri(photoSizes.tiny, person.id)}
                        showLoader={false}
                        loading={false}
                        onClick={handleImageClick}
                        onLoad={() => {}}
                    />
                </div>
                <span className='PersonSearchOption__Name'>{fullName}</span>
                {isSelected &&
                    <a
                        className='PersonSearchOption__Link'
                        href={personRoute.buildUrl({ id: person.id })}
                        target="_blank noopener noreferrer"
                    >
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </a>
                }
            </div>
        </div>
    );
};

export const PersonSearchSelect = (props) => {
    const { onChange, onError, className, style,filter, ...selectProps } = props;
    const [showPlaceholder, setPlaceholder] = useState(true);
    const [isSearchInputValid, setIsSearchInputValid] = useState(false);
    // eslint-disable-next-line
    const loadOptions = useCallback(
        debounce(search(onError, filter), 500),
        [onError,filter]
    );

    const handleInputChange = (input) => {
        input && input.length > 2 ? setIsSearchInputValid(true) : setIsSearchInputValid(false);
    };

    return (
        <Field className={className} style={style}>
            <FontAwesomeIcon className='PersonSearchSelect__Icon' icon={faSearch} />
            <AsyncSelect
                placeholder={showPlaceholder && <span className='PersonSearchSelectPlaceholder'>Найти члена команды</span>}
                cacheOptions={false}
                defaultOptions={false}
                isClearable={true}
                loadOptions={isSearchInputValid ? loadOptions : null}
                formatOptionLabel={renderOptionLabel}
                onFocus={()=> setPlaceholder(false)}
                onBlur={()=> setPlaceholder(true)}
                onChange={onChange}
                onInputChange={handleInputChange}
                {...selectProps}
            />
        </Field>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onError: bindActionCreators(showErrorAlert, dispatch),
});

export default connect(null, mapDispatchToProps)(PersonSearchSelect);
