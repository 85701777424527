import { DataCard } from 'components/common/DataCard';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import Row from 'components/uikit/Row';
import Form from 'components/common/Form';
import { DictionarySelect } from 'components/uikit/Select';
import { showErrorAlert, showWarningAlert } from 'ducks/Alert';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ToggleOpen from 'components/hoc/ToggleOpen';
import ValidBlock from 'components/hoc/ValidBlock';
import { requireValidator } from 'libs/validators';
import UserSearchSelect from 'components/uikit/UserSearchSelect';

class SystemInfoBlock extends PureComponent {
    componentDidMount() {
        const { selectedCurator, curatorId, handleStateChange, selectedCuratorName } = this.props;
        if (selectedCurator === undefined && curatorId) {
            handleStateChange({
                selectedCurator: {
                    value: { id: curatorId, fullName: selectedCuratorName },
                    label: selectedCuratorName,
                },
            });
        }
    }

    render() {
        const {
            selectedGroup,
            selectedPersonGroupIds,
            groups,
            isInputInvalid,
            isInputFilled,
            isEditBlock,
            renderEditBlock,
            selectedCurator,
            handleStateChange,
        } = this.props;

        const selectedGroups = selectedPersonGroupIds
            ? [...selectedPersonGroupIds, selectedGroup]
            : selectedGroup
            ? [selectedGroup]
            : null;

        const coloredGroups = groups.map((x) => {
            const className = x.isScope
                ? 'EditPerson__GroupsList--isScope'
                : 'EditPerson__GroupsList';
            return { ...x, className: className };
        });

        const groupIds = groups.map((x) => x.id);

        const isPersonFromAnotherGroup = selectedGroups
            ? selectedGroups.some((g) => groupIds.indexOf(g) < 0)
            : false;

        const canEditGroups = !selectedGroup || groupIds.some((x) => x === selectedGroup);

        const colourStyles = {
            multiValue: (styles, { data }) => {
                return {
                    ...styles,
                    backgroundColor: data.isScope ? '#9dc7f7' : 'hsl(0,0%,90%)',
                };
            },
            option: (styles, { data }) => {
                return {
                    ...styles,
                    color: data.isScope ? '#007aff' : 'black',
                };
            },
            menu: (provided) => ({
                ...provided,
                'z-index': 2,
            }),
        };

        return (
            <DataCard
                shadow
                title="Системная информация"
                className="kr_profile-anketa"
                isOpen={this.props.hasValidationErrors}
            >
                <Form autoComplete={false} className="EditPerson__Form">
                    <Row>
                        <Field
                            required
                            filled={isInputFilled(selectedGroup)}
                            invalid={isInputInvalid('selectedGroup', [
                                requireValidator('Выберите группу не с типом Транзитная'),
                            ])}
                            size="50"
                        >
                            <Label htmlFor="selectedGroup">Группы</Label>
                            <DictionarySelect
                                inputId="selectedGroup"
                                value={selectedGroups}
                                onChange={(e) =>
                                    this.handleGroupChange(isPersonFromAnotherGroup, e)
                                }
                                styles={colourStyles}
                                options={coloredGroups}
                                isMulti
                                isDisabled={!canEditGroups}
                            />
                        </Field>
                    </Row>
                    <Row>
                        <Field filled={isInputFilled(selectedCurator, false)} size="50">
                            <Label htmlFor="сurator">Эксперт по управлению талантами</Label>
                            <UserSearchSelect
                                id="curator"
                                onChange={(newSelectedCurator) =>
                                    handleStateChange({ selectedCurator: newSelectedCurator })
                                }
                                value={selectedCurator}
                            />
                        </Field>
                    </Row>
                    {isEditBlock ? <Row>{renderEditBlock()}</Row> : null}
                </Form>
            </DataCard>
        );
    }

    handleGroupChange = (isPersonFromAnotherGroup, selected) => {
        const { groups, selectedGroup, handleStateChange } = this.props;

        if (!selected) {
            handleStateChange({
                selectedGroup: isPersonFromAnotherGroup ? selectedGroup : null,
                selectedPersonGroupIds: null,
            });
            return;
        }

        let selectedCommonGroups = groups
            .filter((x) => !x.isScope && selected.includes(x.id))
            .map((x) => x.id);

        if (selectedCommonGroups.length > 1) {
            selectedCommonGroups = selectedCommonGroups.filter((x) => x !== selectedGroup);
        } else if (isPersonFromAnotherGroup && selectedCommonGroups.length < 1) {
            selectedCommonGroups = [selectedGroup];
        }
        const selectedPersonGroupIds = groups
            .filter((x) => x.isScope && selected.includes(x.id))
            .map((x) => x.id);

        handleStateChange({
            selectedGroup: selectedCommonGroups.length === 1 ? selectedCommonGroups[0] : null,
            selectedPersonGroupIds,
        });
    };
}

const actions = { showWarningAlert, showErrorAlert };

export default connect(null, actions)(ToggleOpen(ValidBlock(SystemInfoBlock)));
