import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import { getValidationErrors, getError } from 'serviceErrors';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { DataCard } from 'components/common/DataCard';
import Input from 'components/uikit/Input';
import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import Button from 'components/uikit/Button';
import DatePicker from 'components/uikit/DatePicker';
import Page from 'components/common/Page';
import UserSearchSelect from 'components/uikit/UserSearchSelect';
import ControlGroup from 'components/uikit/ControlGroup';
import TextEditor from 'components/common/TextEditor';
import { issuesRoute, issuesEditRoute } from 'routes';
import { push as pushLocation } from 'connected-react-router';
import { createIssue, updateIssue, updateIssueStatus, updateDueDate, getIssue } from 'api';
import { getUserFullName, endOf, isNullOrWhitespace, formatDate, startOf } from 'utils';
import { ISSUE_STATUS, ISSUE_STATUS_ENUM, ISSUE_TYPE_ENUM } from 'constants.js';
import IssueFile from 'components/Lk/Shared/Tasks/IssueFile';
import LoadingUrlBlocker from 'components/Lk/Common/LoadingUrlBlocker';
import { stringify } from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalDialog from 'components/common/ModalDialog';
import moment from 'libs/moment';
import './IssueEdit.scss';

class IssueEdit extends Component {
    _mounted = true;

    state = {
        form: {
            id: '',
            title: '',
            description: '',
            dueDate: null,
            type: ISSUE_TYPE_ENUM.Default,
            status: ISSUE_STATUS_ENUM.New,
            assigneeId: null,
        },
        processingModal: false,
        isSaving: false,
        dirtyForm: false,
        preview: false,
        editMode: false,
        selectedUser: null,
        userFromSearch: null,
        statusChangeState: {
            isOpen: false,
            newStatus: '',
            header: '',
            comment: '',
            date: null,
            buttons: ['Да', 'Нет'],
        },
    };

    componentDidMount() {
        if (this.props.match.params.id !== 'new') {
            this.fetchIssue();
        }
    }

    componentDidUpdate() {
        const { form } = this.state;
        const viewMode = this.props.match.params.mode === 'view';
        const isOwner = this.props.currentUser.id === form.authorId;
        !viewMode && !isOwner && form.status !== ISSUE_STATUS_ENUM.New && this.onCancel();

        if (this.props.match.params.id !== 'new' && this.props.match.params.id !== form.id) {
            this.fetchIssue();
        }
    }

    componentWillUnmount() {
        this._mounted = false;
    }

    fetchIssue = () => {
        this.callEffect(async () => {
            const result = await getIssue(this.props.match.params.id);
            const dueDate = result.data.dueDate
                ? new Date(result.data.dueDate)
                : result.data.dueDate;

            const user = {
                value: { id: result.data.id, fullName: result.data.assigneeName },
                label: result.data.assigneeName,
            };

            this._mounted &&
                this.setState({
                    form: {
                        ...result.data,
                        dueDate,
                    },
                    selectedUser: { ...user },
                    userFromSearch: { ...user },
                    statusChangeState: {
                        ...this.state.statusChangeState,
                        date: this.getDefferDate(dueDate),
                    },
                });
        });
    };

    onChange = item => {
        this.setState(state => ({
            form: {
                ...state.form,
                ...item,
            },
            dirtyForm: true,
        }));
    };

    onStartSelectUser = () => {
        this.setState({
            editMode: true,
        });
    };

    onSelectUser = user => {
        this.setState({
            userFromSearch: { ...user },
        });
    };

    onAssignUser = () => {
        if (!this.state.userFromSearch.value) {
            return;
        }

        this.setState(state => ({
            form: {
                ...state.form,
                assigneeId: state.userFromSearch.value?.id,
            },
            editMode: false,
            selectedUser: { ...state.userFromSearch },
        }));
    };

    onCloseSelectUser = () => {
        this.setState(state => ({
            editMode: false,
            userFromSearch: { ...state.selectedUser },
        }));
    };

    setRequest = criteria => {
        const formData = new FormData();
        const { issueFiles: files = [], ...issue } = criteria;
        formData.append('data', JSON.stringify(issue));
        if (files?.length > 0) {
            files.filter(x => x.isNew).forEach(x => formData.append('files', x.file));
            files
                .filter(x => x.deleted)
                .map(x => x.id)
                .forEach(x => formData.append('removeIds', x));
        }
        return formData;
    };

    onSaveIssue = async () => {
        this.setState({ isSaving: true });
        await this.callEffect(async () => {
            await this.applyAssignee();

            const requestForm = {
                ...this.state.form,
                dueDate:
                    this.state.form &&
                    this.state.form.dueDate &&
                    endOf(this.state.form.dueDate, 'day').format(),
            };

            const result = this.state.form.id
                ? await updateIssue(this.setRequest(requestForm))
                : await createIssue(this.setRequest(requestForm));

            const issueFiles = result?.data?.issueFiles || [];
            
            this.setState({ isSaving: false, form: {...this.state.form, issueFiles }});
            this.props.pushLocation({
                pathname: issuesEditRoute.buildUrl({
                    id: result.data.id,
                    mode: 'view',
                }),
                state: { ...this.props.location?.state },
            });
            showSuccessAlert('Сохранение прошло успешно');
        });
    };

    applyAssignee = () => this.state.editMode && this.onAssignUser();

    onEditIssueMode = () => {
        this.props.pushLocation({
            pathname: issuesEditRoute.buildUrl({ id: this.props.match.params.id }),
            state: { ...this.props.location?.state },
        });
    };

    onCancel = () => {
        const statusesFilter = this.props.location?.state?.status;
        const searchParam =
            statusesFilter && (Array.isArray(statusesFilter) ? statusesFilter : [statusesFilter]);
        const url = stringify({...this.props.location?.state, status: searchParam });
        this.props.pushLocation({ pathname: issuesRoute.url, search: url });
    };

    onChangePreview = value => {
        this.setState({
            preview: value,
        });
    };

    callEffect = async callback => {
        this.props.showPageLoader();
        try {
            return await callback();
        } catch (error) {
            const validationErrors = getValidationErrors(error);
            if (validationErrors.length > 0) {
                validationErrors.map(item => {
                    return this.props.showErrorAlert(item.message);
                });
            } else {
                const reqError = getError(error, this.getIssueError());
                this.props.showErrorAlert(reqError.message);
            }
        } finally {
            this.props.hidePageLoader();
        }
    };

    getIssueError = () => code => {
        switch (code) {
            default:
                return 'Произошла непредвиденная ошибка';
        }
    };

    static getDerivedStateFromProps(props, state) {
        if (!state.form.assigneeId) {
            const currentUser = {
                value: {
                    id: props.currentUser.id,
                    fullName: getUserFullName(props.currentUser),
                },
                label: getUserFullName(props.currentUser),
            };

            return {
                ...state,
                form: {
                    ...state.form,
                    assigneeId: props.currentUser.id,
                },
                selectedUser: { ...currentUser },
                userFromSearch: { ...currentUser },
            };
        }

        return null;
    }

    isIssueValid = () => {
        const { form, userFromSearch, selectedUser } = this.state;

        return (
            !isNullOrWhitespace(form.title) &&
            selectedUser &&
            selectedUser.label &&
            userFromSearch.value
        );
    };

    closeModal = () => {
        this.setState({
            statusChangeState: {
                ...this.state.statusChangeState,
                isOpen: false,
                comment: '',
                newStatus: '',
                date: this.getDefferDate(this.state.form.dueDate),
            },
        });
    };

    openStatusChangeModal = status => {
        const header = {
            [ISSUE_STATUS_ENUM.New]: 'Отложить задачу',
            [ISSUE_STATUS_ENUM.Completed]: 'Выполнить задачу',
            [ISSUE_STATUS_ENUM.Deleted]: 'Удалить задачу?',
        };

        const buttons = {
            [ISSUE_STATUS_ENUM.New]: ['Отложить', 'Отменить'],
            [ISSUE_STATUS_ENUM.Completed]: ['Выполнить', 'Отменить'],
            [ISSUE_STATUS_ENUM.Deleted]: ['Да', 'Нет'],
        };

        this.setState({
            statusChangeState: {
                ...this.state.statusChangeState,
                isOpen: true,
                newStatus: status,
                header: header[status],
                buttons: buttons[status],
            },
        });
    };

    changeStatusState = (field, value) => {
        this.setState({
            statusChangeState: {
                ...this.state.statusChangeState,
                [field]: value,
            },
        });
    };


    changeStatus = async () => {
        const {
            form: { id },
            statusChangeState,
        } = this.state;

        try {
            this.setState({ processingModal: true });
            statusChangeState.newStatus === ISSUE_STATUS_ENUM.New
                ? await updateDueDate(id, statusChangeState.date, statusChangeState.comment)
                : await updateIssueStatus(
                      id,
                      statusChangeState.newStatus,
                      statusChangeState.comment,
                  );
            this.closeModal();
            this.onCancel();
            showSuccessAlert(
                statusChangeState.newStatus === ISSUE_STATUS_ENUM.Deleted
                    ? 'Задача успешно удалена'
                    : 'Статус задачи изменен',
            );
        } catch (e) {
            showErrorAlert('Ошибка обновления задачи');
        } finally {
            this._mounted && this.setState({ processingModal: false });
        }
    };

    getDefferDate = dueDate => {
        return moment(dueDate).isSameOrAfter(new Date())
            ? new Date(moment(dueDate).add(1, 'days'))
            : new Date(moment(new Date()).add(1, 'days'));
    };

    render() {
        const { form, userFromSearch, isSaving, statusChangeState, processingModal } = this.state;
        const viewMode = this.props.match.params.mode === 'view';
        const isOwner = this.props.currentUser.id === form.authorId;
        const isAssignee = this.props.currentUser.id === form.assigneeId;

        const editButtons = this.state.editMode
            ? [
                  <Button
                      disabled={!userFromSearch.value}
                      size="sm"
                      faIcon="check"
                      onClick={this.onAssignUser}
                  />,
                  <Button size="sm" faIcon="times" onClick={this.onCloseSelectUser} />,
              ]
            : [<Button size="sm" faIcon="pencil-alt" onClick={this.onStartSelectUser} />];

        return (
            <Grid fluid>
                <Page className="IssueEdit">
                    <DataCard className="IssueEdit__Content">
                        <Row>
                            <Col md={6}>
                                <div className="IssueCloseButton" onClick={this.onCancel}>
                                    <FontAwesomeIcon size="lg" icon="arrow-left" /> К списку задач
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="IssueStatus">
                                    {ISSUE_STATUS.find(x => x.value === form.status)?.label}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Field
                                    filled={!isNullOrWhitespace(form.title) && !viewMode}
                                    required={!viewMode}
                                >
                                    <Label>Заголовок</Label>
                                    <Input
                                        className="IssueEdit__Input"
                                        value={form.title}
                                        maxLength="128"
                                        disabled={viewMode}
                                        onChange={e => this.onChange({ title: e.target.value })}
                                    />
                                </Field>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Field>
                                    {viewMode && <Label>Описание</Label>}
                                    <TextEditor
                                        disabled={viewMode}
                                        value={form.description}
                                        showToolbar={!viewMode}
                                        maxLength={1000}
                                        preview={viewMode || this.state.preview}
                                        minHeight={viewMode ? 'auto' : 300}
                                        onChangePreview={this.onChangePreview}
                                        onChange={e =>
                                            this.onChange({ description: e.target.value })
                                        }
                                    />
                                </Field>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} xl={5}>
                                <Field>
                                    <Label>Автор</Label>
                                    <div className="IssueEdit__FakeInput">{form.authorName}</div>
                                </Field>
                            </Col>
                            <Col md={12} xl={5}>
                                <ControlGroup buttons={!viewMode && editButtons}>
                                    <Label>Ответственный</Label>
                                    {!this.state.editMode || viewMode ? (
                                        <div
                                            className={
                                                viewMode
                                                    ? 'IssueEdit__FakeInput'
                                                    : 'ControlGroup__FakeInput'
                                            }
                                        >
                                            {this.state.selectedUser &&
                                                this.state.selectedUser.label}
                                        </div>
                                    ) : (
                                        <UserSearchSelect
                                            onChange={this.onSelectUser}
                                            value={this.state.userFromSearch}
                                        />
                                    )}
                                </ControlGroup>
                            </Col>
                            <Col md={12} xl={2}>
                                <Field>
                                    <Label>Выполнить до</Label>
                                    {viewMode ? (
                                        <Input disabled value={formatDate(form.dueDate) || ''} />
                                    ) : (
                                        <DatePicker
                                            className="EventDateCreation__DatePicker"
                                            selected={form.dueDate && new Date(form.dueDate)}
                                            minDate={new Date(startOf(new Date(), 'day').format())}
                                            onChange={value =>
                                                this.onChange({
                                                    dueDate: value
                                                        ? endOf(value, 'day').format()
                                                        : null,
                                                })
                                            }
                                        />
                                    )}
                                </Field>
                            </Col>
                        </Row>

                        {(form.issueFiles?.length > 0 || !viewMode) && (
                            <Row top="xs">
                                <Col md={12}>
                                    <Field className="IssueFiles">
                                        <Label>Файлы</Label>
                                        <IssueFile
                                            files={form?.issueFiles || []}
                                            isEdit={!viewMode}
                                            onChange={issueFiles => this.onChange({ issueFiles })}
                                            issueId={form.id}
                                        />
                                    </Field>
                                </Col>
                            </Row>
                        )}

                        {viewMode ? (
                            <Row>
                                <Col md={6} className="IssueEdit__ActionButtons--left">
                                    {isOwner && form.status === ISSUE_STATUS_ENUM.New && (
                                        <>
                                            <Button
                                                size="sm"
                                                color="danger"
                                                onClick={() =>
                                                    this.openStatusChangeModal(
                                                        ISSUE_STATUS_ENUM.Deleted,
                                                    )
                                                }
                                            >
                                                Удалить
                                            </Button>
                                            <Button
                                                size="sm"
                                                color="light_primary"
                                                onClick={this.onEditIssueMode}
                                            >
                                                Редактировать
                                            </Button>
                                        </>
                                    )}
                                </Col>
                                <Col md={6} className="IssueEdit__ActionButtons--right">
                                    {form.id && isAssignee && form.status === ISSUE_STATUS_ENUM.New && (
                                        <>
                                            {!isOwner && 
                                                <Button
                                                    size="sm"
                                                    color="danger"
                                                    onClick={() =>
                                                        this.openStatusChangeModal(
                                                            ISSUE_STATUS_ENUM.New,
                                                        )
                                                    }
                                                >
                                                    Отложить
                                                </Button>
                                            }
                                            <Button
                                                size="sm"
                                                onClick={() =>
                                                    this.openStatusChangeModal(
                                                        ISSUE_STATUS_ENUM.Completed,
                                                    )
                                                }
                                            >
                                                Выполнить
                                            </Button>
                                        </>
                                    )}
                                </Col>
                            </Row>
                        ) : (
                            <Row className="IssueEdit__ActionButtons--right" end="md">
                                <Col md={12} xl={4}>
                                    <Button size="sm" color="danger" onClick={this.onCancel}>
                                        Отменить
                                    </Button>
                                    <Button
                                        size="sm"
                                        disabled={!this.isIssueValid()}
                                        onClick={this.onSaveIssue}
                                    >
                                        Сохранить
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </DataCard>
                </Page>
                <LoadingUrlBlocker
                    isLoading={isSaving}
                    message="Выполняется сохранение. Пожалуйста, подождите."
                />
                <ModalDialog
                    onClick={this.changeStatus}
                    onCloseModal={this.closeModal}
                    modalOpen={statusChangeState.isOpen}
                    modalHeader={statusChangeState.header}
                    btnOktext={statusChangeState.buttons[0]}
                    btnCanceltext={statusChangeState.buttons[1]}
                    sideButtons
                    processing={processingModal}
                    btnOkColor={
                        statusChangeState.newStatus === ISSUE_STATUS_ENUM.New ||
                        statusChangeState.newStatus === ISSUE_STATUS_ENUM.Deleted
                            ? 'danger'
                            : ''
                    }
                >
                    <div className="IssueStatusChange">
                        <div>
                            {statusChangeState.newStatus !== ISSUE_STATUS_ENUM.Deleted && (
                                <Field>
                                    <Label>Комментарий</Label>
                                    <Input
                                        type="textarea"
                                        className="IssueEdit__TextArea"
                                        value={statusChangeState.comment || ''}
                                        onChange={e =>
                                            this.changeStatusState('comment', e.currentTarget.value)
                                        }
                                    />
                                </Field>
                            )}
                        </div>
                        {statusChangeState.newStatus === ISSUE_STATUS_ENUM.New && (
                            <Field>
                                <Label>Срок выполнения задачи</Label>
                                <DatePicker
                                    isClearable={false}
                                    minDate={new Date(statusChangeState.date)}
                                    selected={new Date(statusChangeState.date)}
                                    onChange={value => this.changeStatusState('date', value)}
                                />
                            </Field>
                        )}
                    </div>
                </ModalDialog>
            </Grid>
        );
    }
}

const props = state => {
    return {
        currentUser: state.auth.user,
    };
};

const actions = {
    showPageLoader,
    hidePageLoader,
    showErrorAlert,
    showSuccessAlert,
    pushLocation,
};

export default connect(
    props,
    actions,
)(IssueEdit);
