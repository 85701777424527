import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import ModalPopupHeader from './ModalPopupHeader.js';
import ModalPopupContent from './ModalPopupContent.js';
import ModalPopupFooter from './ModalPopupFooter.js';
import Loader from 'components/common/Loader';
import './ModalPopup.scss';

class ModalPopup extends Component {
    static Header = ModalPopupHeader;
    static Content = ModalPopupContent;
    static Footer = ModalPopupFooter;

    state = {
        contentMarginTop: 0,
    };

    setContentMarginTop = (contentMarginTop) => {
        this.setState({ contentMarginTop });
    };

    setAutoFocus = (autofocusId) => {
        document.getElementById(autofocusId).focus();
    };

    componentDidMount() {
        const { autofocusId = null, fitScreenSize = false } = this.props;

        if (fitScreenSize) {
            const header = document.querySelector('.LKModalPopup__Header--fitScreenSize');
            header && this.setContentMarginTop(header.offsetHeight + 10 + 'px');
            const content = document.querySelector('.LKModalPopup__Content--fitScreenSize');
            const footer = document.querySelector('.LKModalPopup__Footer--fitScreenSize');
            if (content && footer && header) {
                content.style.maxHeight = `calc(100vh - ${
                    header.offsetHeight + 57 + footer.offsetHeight
                }px)`;
            }
        }

        autofocusId && this.setAutoFocus(autofocusId);
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            autofocusId = null,
            fitScreenSize = false,
            noChildren,
            isModalOpen,
            children,
            headerInput,
        } = this.props;

        if (
            prevProps.fitScreenSize !== fitScreenSize ||
            prevProps.noChildren !== noChildren ||
            prevProps.isModalOpen !== isModalOpen ||
            prevProps.children !== children ||
            prevProps.headerInput !== headerInput ||
            prevState.contentMarginTop !== this.state.contentMarginTop
        ) {
            if (fitScreenSize) {
                const header = document.querySelector('.LKModalPopup__Header--fitScreenSize');
                header && this.setContentMarginTop(header.offsetHeight + 10 + 'px');
                const content = document.querySelector('.LKModalPopup__Content--fitScreenSize');
                const footer = document.querySelector('.LKModalPopup__Footer--fitScreenSize');
                if (content && footer && header) {
                    content.style.maxHeight = `calc(100vh - ${
                        header.offsetHeight + 57 + footer.offsetHeight
                    }px)`;
                }
            }
        }

        if (prevProps.autofocusId !== autofocusId) {
            autofocusId && this.setAutoFocus(autofocusId);
        }
    }

    onCancel = (fromConfirm = false) => {
        this.props.onModalClose(fromConfirm);
    };

    onConfirm = async (e) => {
        const { enabled = true, confirm = () => {}, onModalClose } = this.props;
        if (enabled) {
            await confirm(e);
            onModalClose(true);
        }
    };

    render() {
        const {
            isModalOpen,
            onModalClose,
            showCloseIcon = false,
            processing = false,

            isHide = false,
            width,
            hideOverlay = false,
            container,

            withFooter = true,
            unstickFooter = false,
            ...tail
        } = this.props;

        const { fitScreenSize } = this.props;
        const { contentMarginTop } = this.state;

        const modal = {
            borderRadius: '12px',
            minWidth: '270px',
            ...(fitScreenSize && {
                padding: '0',
                minWidth: window.screen.availWidth < 577 ? '100%' : '600px',
                maxWidth: window.screen.availWidth < 577 ? '100%' : '800px',
                overflowY: 'hidden',
                height: !unstickFooter ? '100%' : 'auto',
                margin: '0',
            }),
            opacity: isHide ? '0' : 1,
            width: width || 'auto',
        };

        const closeIcon = { ...(fitScreenSize && { zIndex: '11' }) };

        const overlay = {
            background: hideOverlay && 'none',
            justifyContent: 'center',
        };

        const modalStyle = { modal, closeIcon, overlay };

        return (
            <Modal
                open={isModalOpen}
                onClose={() => onModalClose(false)}
                center
                styles={modalStyle}
                classNames={modalStyle}
                focusTrapped={false}
                showCloseIcon={showCloseIcon}
                closeOnOverlayClick={!processing}
                container={container}
            >
                <ModalPopupHeader {...tail} />
                <ModalPopupContent contentMarginTop={contentMarginTop} {...tail} />
                <Loader show={processing} absolute />
                <ModalPopupFooter
                    show={withFooter}
                    onConfirm={this.onConfirm}
                    onCancel={this.onCancel}
                    unstickFooter={unstickFooter}
                    {...tail}
                />
            </Modal>
        );
    }
}

export default ModalPopup;
