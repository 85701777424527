import React from 'react';
import { appName } from '../constants';
import { put, delay, takeEvery } from 'redux-saga/effects'
import { toast } from 'react-toastify';

const moduleName = 'alert';
export const SHOW_ALERT = `${appName}/${moduleName}/SHOW_ALERT`;
export const CLOSE_ALERT = `${appName}/${moduleName}/CLOSE_ALERT`;
export const CLOSE_BY_TIMEOUT = `${appName}/${moduleName}/CLOSE_BY_TIMEOUT`;

const initialState = {
    visible: false,
    closeTimeout: 0,
    color: 'info',
    message: '',
};

const formatMessage = (message) => {
    if (!Array.isArray(message)) {
        return message;
    }

    if (message.length === 0) {
        return "Произошла непредвиденная ошибка";
    }

    if (message.length === 1) {
        return message[0];
    }

    return message.reduce((acc, cur) => (<>{acc}{cur}<br /><br /></>), "");
}

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    const  { color='info', message, closeTimeout } = payload|| {}
    
    switch (type) {
        case SHOW_ALERT:
            return {
                ...state,
                visible: true,
                color,
                message,
                closeTimeout,
            }

        case CLOSE_ALERT:
            return initialState;

        default:
            return state;
    }
}

export const showAlert = (type, message, closeTimeoutInSeconds, position = toast.POSITION.BOTTOM_LEFT) => {
    // TODO:
    // пока оставим вызов алертов через редакс (возможно потом понадобится)
    toast[type](formatMessage(message), {position: position, autoClose: closeTimeoutInSeconds * 1000, hideProgressBar: true});
    return {
        type: SHOW_ALERT,
        payload: {
            type,
            message,
            closeTimeout: closeTimeoutInSeconds * 1000,
        },
    }
}

export const showSuccessAlert = (message, closeTimeoutInSeconds = 5) => {
    return showAlert('success', message, closeTimeoutInSeconds);
}

export const showWarningAlert = (message, closeTimeoutInSeconds = 5) => {
    return showAlert('warn', message, closeTimeoutInSeconds);
}

export const showInfoAlert = (message, closeTimeoutInSeconds = 5) => {
    return showAlert('info', message, closeTimeoutInSeconds);
}

export const showErrorAlert = (message, closeTimeoutInSeconds = 5) => {
    return showAlert('error', message, closeTimeoutInSeconds);
}

export const closeAlert = () => {
    return {
        type: CLOSE_ALERT,
    }
}

export const closeByTimeout = timeout => {
    return {
        type: CLOSE_BY_TIMEOUT,
        payload: { timeout }
    }
}

export const closeByTimeoutSaga = function* (action) {
    if (action.payload.closeTimeout > 0) {
        yield delay(action.payload.closeTimeout);
        yield put(closeAlert());
    }
}



export const watchCloseByTimeoutSaga = function* () {
    yield takeEvery (SHOW_ALERT, closeByTimeoutSaga);
}

export const allActions = {
    showAlert, showSuccessAlert, showWarningAlert, showInfoAlert, showErrorAlert, closeAlert, closeByTimeout
}

export const saga = function* () {
    yield watchCloseByTimeoutSaga();
}