import React from 'react';
import Popover from 'components/Lk/Uikit/Popover';
import { Select } from 'components/uikit/Select';
import CheckCard from '../../CheckCard';

import './CurrentCompetencies.scss';

const getCriterionWithResult = (competency, testResults) => {
    const testResult = testResults[competency.personGrowthTagId];

    if (!testResult) {
        return null;
    }

    return testResult;
};

const getCompetencyScore = criterionWithResult => {
    const { criterion, result } = criterionWithResult;

    const normalizedValue = result.normalizedValue;

    let className = '';
    if (normalizedValue < 0.3) {
        className = 'CompetencyScore--bad';
    } else if (normalizedValue < 0.7) {
        className = 'CompetencyScore--average';
    } else {
        className = 'CompetencyScore--good';
    }

    switch (criterion.valueKind.toLowerCase()) {
        case 'scale':
            return {
                text: `${Math.round(result.decimalValue)} из ${criterion.scaleUpperBound}`,
                className,
                normalizedValue,
            };
        case 'grade':
            return {
                text: result.stringValue,
                className,
                normalizedValue,
            };
        default:
            return null;
    }
};

const tooltip = `При выборе компетенций опирайтесь на сильные стороны резервиста. Рекомендуем
                вам выбирать не более 2-3 компетенций за раз.`;

export const CurrentCompetenciesTitle = () => {
    return (
        <div>
            <div className="currentCompetencies__container__title">
                <Popover tooltip={tooltip}>
                    <div
                        className="tooltip-link"
                        data-tip
                        data-for="socialActivityHint"
                        style={{ fontSize: 24 + 'px' }}
                    >
                        ?
                    </div>
                </Popover>
            </div>
        </div>
    );
};

class CurrentCompetencies extends React.Component {
    getCurrentCompetencyInfo = () => {
        const competencyModelId = 
            this.props.plan && 
            this.props.plan.competencies && 
            Array.isArray(this.props.plan.competencies) && 
            this.props.plan.competencies.length > 0 
            && this.props.plan.competencies[0].competencyModelId;
        const currentCompetencyInfo = 
            this.props.competencyInfo && 
            Array.isArray(this.props.competencyInfo) && 
            this.props.competencyInfo.length > 0 && 
            this.props.competencyInfo.find(x => x.competencies.some(x => x.competencyModelId === competencyModelId));
        
        return this.props.plan.currentCompetencyInfo || currentCompetencyInfo || this.props.competencyInfo[0];
    };

    isCompetencyModelChanged = plan => {
        if (!plan) {
            return false;
        }
        const currentCompetencyIds = plan.currentCompetencyInfo?.competencies.map(x => x.id) || [];
        return !!plan.currentCompetencyInfo && !plan.competencyIds.every(x => currentCompetencyIds.includes(x));
    };

    handleChange = (item, selected) => {
        const { onChange, plan } = this.props;

        selected
            ? onChange('competencyIds',
            this.isCompetencyModelChanged(plan)
                ? [item.id]
                : [...plan.competencyIds.map(x => x), item.id])
            : onChange('competencyIds', plan.competencyIds.filter(x => x !== item.id));
    };

    handleCompetencyModelChange = value => {
        if (this.getCurrentCompetencyInfo() === value) {
            return;
        }

        this.props.onChange('currentCompetencyInfo', value);
    };

    render() {
        const { competencyInfo } = this.props;

        const renderCompetencies = () => {
            if (!!competencyInfo) {
                return competencyInfo.length === 1
                    ? this.renderSingleModel(competencyInfo[0])
                    : this.renderMultiModel(competencyInfo);
            }

            return () => null;
        };

        const noAvailableCompetencies = competencyInfo && competencyInfo.length === 0;

        return (
            <div className="currentCompetencies">
                {noAvailableCompetencies && (
                    <div className="currentCompetencies__nothingAvaliable">
                        Нет доступных компетенций
                    </div>
                )}
                {competencyInfo && !noAvailableCompetencies ? (
                    renderCompetencies()
                ) : (
                    <div className="currentCompetencies__container" />
                )}
            </div>
        );
    }

    renderSingleModel = competencySingleInfo => {
        const { competencyModel, competencies, competencyResultsByTagId } = competencySingleInfo;

        return (
            <div>
                <div className="currentCompetencies__modelTitle">
                    <span className="currentCompetencies__modelTitle__text">
                        {competencyModel.name}
                    </span>
                </div>
                <div className="currentCompetencies__container">
                    <div className="currentCompetencies__container__row">
                        {this.renderCompetencyList(competencies, competencyResultsByTagId)}
                    </div>
                </div>
            </div>
        );
    };

    renderMultiModel = competencyInfo => {
        const currentCompetencyInfo = this.getCurrentCompetencyInfo();
        const { competencies, competencyResultsByTagId } = currentCompetencyInfo;

        return (
            <div>
                <div className="currentCompetencies__modelTitle">
                    <span className="currentCompetencies__modelTitle__text">
                        <Select
                            value={currentCompetencyInfo}
                            onChange={this.handleCompetencyModelChange}
                            options={competencyInfo}
                            getOptionValue={c => c.competencyModel.id}
                            getOptionLabel={c => c.competencyModel.name}
                        />
                    </span>
                </div>
                <div className="currentCompetencies__container">
                    <div className="currentCompetencies__container__row">
                        {this.renderCompetencyList(competencies, competencyResultsByTagId)}
                    </div>
                </div>
            </div>
        );
    };

    renderCompetencyList = (competencies, testResults) => {
        const { plan } = this.props;
        return (
            <div className="currentCompetencies__container__row">
                {competencies.map(item => {
                    const testResult = getCriterionWithResult(item, testResults);
                    const score = testResult && getCompetencyScore(testResult);
                    const isChecked = plan.competencyIds.includes(item.id);
                    return (
                        <div className="currentCompetencies__container__row__item" key={item.id}>
                            <CheckCard
                                disabled={
                                    !isChecked &&
                                    plan.competencyIds &&
                                    plan.competencyIds.length === 3 &&
                                    !this.isCompetencyModelChanged(plan)
                                }
                                title={item.name}
                                value={item.id}
                                checked={plan.competencyIds.find(x => x === item.id) || false}
                                className="toolTypeSelector__checkCard"
                                onChange={e => {
                                    this.handleChange(item, e);
                                }}
                            />
                            <div className="CompetencyCardRight">
                                {score && (
                                    <div
                                        className={`${score.className} CompetencyCardRight__Score`}
                                    >
                                        {score.text}
                                    </div>
                                )}
                                <Popover tooltip={item.description}>
                                    <div
                                        className="tooltip-link"
                                        data-tip
                                        data-for={item.id}
                                        style={{ fontSize: '12px', background: '#e1e1e1' }}
                                    >
                                        ?
                                    </div>
                                </Popover>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };
}

export default CurrentCompetencies;
