import React, { useState, useEffect } from 'react';
import StickyFooter from 'components/Lk/Common/StickyFooter';
import './FooterAcceptMenu.scss';

const FooterAcceptMenu = props => {
    const { handleAcceptClick, show = false, okText = 'Применить', cancelText = 'Отменить', onCancel, alwaysOpen = false } = props;
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(show);
    }, [show]);

    const onAcceptClick = () => {
        handleAcceptClick();
        !alwaysOpen && setIsOpen(false);
    };

    const onCancelClick = () => {
        onCancel 
        ? onCancel()
        : setIsOpen(false);
    };

    return ( isOpen && 
        <StickyFooter>
            <div className='LKFooterAcceptMenu'>
                <div className='LKFooterAcceptMenu__Cancel' onClick={onCancelClick}>{cancelText}</div> |
                <div className='LKFooterAcceptMenu__Accept' onClick={onAcceptClick}>{okText}</div>
            </div>
        </StickyFooter>
    );
};

export default FooterAcceptMenu;