import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { personFullSelector } from 'ducks/Person';
import { profile, profileEvaluate, profileProgress } from 'routes';
import TopMenu from 'components/common/TopMenu';

class ProfileMenu extends Component {
    
    static getDerivedStateFromProps(props, state) {
        if (state.loadComplete && props.editBlock && !state.isBlockChanged) {
            return null;
        }

        const { person } = props;

        let loadComplete = person.loadComplete;
        if (state.loadComplete == null) {
            loadComplete = loadComplete && person.isActual;
        }

        if (!loadComplete) {
            return { loadComplete };
        }

        return ProfileMenu.getFreeStateFromPropsAndState(props, state);
    }

    static getFreeStateFromPropsAndState(props, state) {
        const { person } = props;

        const { personalInfo = {} } = person;

        return {
            mainMenuId: state.mainMenuId || (props.personProfileBlock || "profile"),
            editBlock: state.editBlock || "",            
            isBlockChanged: false,
            updatingPhoto: person.updatingPhoto,
            refetchKey: person.refetchKey,
            loadComplete: true,
            person: {
                personalInfo: {
                    id: personalInfo.id,                    
                },                
            },
        };
    }

    state = {
        person: {},
        loadComplete: null,
        editBlock: '',
        isBlockChanged: false,
        evaluationMenuItems: [
            { anchor: 'evaluation', text: 'Результаты оценки', visible: true },
            { anchor: 'newTests', text: 'Назначено на оценку', visible: false },
            { anchor: 'rejectedTests', text: 'Отклоненные', visible: false },
            { anchor: 'actualTests', text: 'Актуальные', visible: false },
            { anchor: 'archiveTests', text: 'Архив', visible: false },
        ],
    };

    constructor(props) {
        super(props);

        this.cardRefs = {
            personal: React.createRef(),
            education: React.createRef(),
            workExperience: React.createRef(),
            languages: React.createRef(),
            socialNetworks: React.createRef(),
            family: React.createRef(),
            files: React.createRef(),
            personnel: React.createRef(),
            attributes: React.createRef(),
        };

        this.evaluationRefs = {
            evaluation: React.createRef(),
            newTests: React.createRef(),
            rejectedTests: React.createRef(),
            actualTests: React.createRef(),
            archiveTests: React.createRef(),
        }
    }

    onMainMenuItemChange = (item) => {
        window.scroll(0, 0);
        this.setState(state => {
            return {
                ...state,
                mainMenuId: item.id,
            }
        }, () => this.props.push(item.route.buildUrl({ id: this.props.id, profileBlock: item.id }))
        )
    };

    renderMainMenu = () => {
        let mainMenuItems = [
            {
                id: "profile",
                title: "Профиль",
                svgIcon: "Profile",                
                route: profile
            },
            {
                id: "evaluate",
                title: "Оценка",
                svgIcon: "Evaluate",
                route: profileEvaluate
            },
            {
                id: "progress",
                title: "Развитие",
                svgIcon: "Progress",
                route: profileProgress
            }
        ];

        return (
            <div className="ProfileMenu__Container">                
                <TopMenu
                    items={mainMenuItems}
                    selected={this.state.mainMenuId}
                    onChangePage={this.onMainMenuItemChange}
                />
            </div>
        )
    };

    render() {
        if (!this.state.loadComplete) {
            return null;
        }

        const config = this.props.config;
        const teamsEnabled = config && config.data && config.data.features.teams;

        return (
            <div>
                {this.renderMainMenu(teamsEnabled)}

            </div>
        );
    }
}

ProfileMenu.propTypes = {
    id: PropTypes.number.isRequired,
    person: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const config = state.config;

    const person = personFullSelector(state, ownProps.id);   

    const loading = !!state.personBlock.saving
        || !!state.person.loading;

    return {
        config,
        person,
        user: state.auth.user,
        loading: loading,
        editBlock: loading
        
            ? state.personBlock.editBlock || state.personBlock.lastEditBlock
            : state.personBlock.editBlock
    };
};

const actions = { push };

export default connect(mapStateToProps, actions)(ProfileMenu);
