import shallowEqualExternal from 'shallowequal';
import moment from 'libs/moment';
import { SHORT_TEXT } from 'constants.js';

export const mapEnumToCatalog = (enumObj, isNormalizedForReactSelect = false) => {
    return Object.keys(enumObj).map(k => ({
        id: k,
        name: enumObj[k],
        ...(isNormalizedForReactSelect ? { value: k } : {}),
    }));
};

export const trimTrailingSlashes = str => {
    return str && str.replace(/\/+$/, '');
};

export const deepClone = obj => {
    return JSON.parse(JSON.stringify(obj));
};

export const diffDate = (start, end, type = 'days') =>
    start && end ? moment(start).diff(moment(end), type) : null;

export const random = (min, max) => {
    const rand = min + Math.random() * (max + 1 - min);
    return Math.floor(rand);
};

export const importToArray = module => {
    const keys = Object.getOwnPropertyNames(module);
    return keys.filter(k => !k.startsWith('__')).map(key => module[key]);
};

export const shallowEqual = (first, second) => {
    return shallowEqualExternal(first, second);
};

export const indexArray = (array, keyField = 'id') => {
    return array.reduce((obj, item) => {
        obj[item[keyField]] = item;
        return obj;
    }, {});
};

export const getFileExtension = fileName => {
    if (fileName.includes('.')) {
        return fileName.split('.').pop();
    }
};

export const setItem = (setter, comparator, currentItem, items) =>
    items.map((item, index) => {
        if (comparator(item, currentItem, index)) {
            return setter(item);
        }
        return item;
    });

export const getFileSize = sizeInBytes => {
    let size = sizeInBytes / 1024;
    if (size < 1000) {
        return `${+size.toFixed(2)} Кб`;
    }

    size = size / 1024;
    if (size < 1000) {
        return `${+size.toFixed(2)} Мб`;
    }

    size = size / 1024;
    return `${+size.toFixed(2)} Гб`;
};

export const sleep = timeoutInMs => {
    return new Promise(resolve => setTimeout(resolve, timeoutInMs));
};

export const trim = (str, strToTrim) => {
    return trimEnd(trimStart(str, strToTrim), strToTrim);
};

export const trimStart = (str, strToTrim) => {
    if (!str) {
        return str;
    }

    if (strToTrim == null) strToTrim = ' ';

    let result = str;
    while (result.startsWith(strToTrim)) {
        result = result.slice(strToTrim.length);
    }

    return result;
};

export const trimEnd = (str, strToTrim) => {
    if (!str) {
        return str;
    }

    if (strToTrim == null) strToTrim = ' ';

    let result = str;
    while (result.endsWith(strToTrim)) {
        result = result.slice(0, result.length - strToTrim.length);
    }

    return result;
};

//https://stackoverflow.com/questions/4060004/calculate-age-given-the-birth-date-in-the-format-yyyymmdd#answer-7091965
export const getAge = date => {
    const birthDate = new Date(date)
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};

//https://gist.github.com/paulvales/113b08bdf3d4fc3beb2a5e0045d9729d
export const declineAge = age => {
    let txt;
    let count = age % 100;
    if (count >= 5 && count <= 20) {
        txt = 'лет';
    } else {
        count = count % 10;
        if (count === 1) {
            txt = 'год';
        } else if (count >= 2 && count <= 4) {
            txt = 'года';
        } else {
            txt = 'лет';
        }
    }
    return age + ' ' + txt;
};

export const getFullBirthDate = birthDate => {
    if (!birthDate) {
        return null;
    }

    return `${birthDate.toLocaleDateString()} (${declineAge(getAge(birthDate))})`;
};

export function fileToBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
    });
}

export class Validator {
    constructor() {
        this.test = this.test.bind(this);
        this.isValid = this.isValid.bind(this);

        this.running = false;
        this.errors = {};
        this.keys = [];
    }

    setState(running) {
        this.running = running;
    }

    test(key, value, validators) {
        let invalid = false;

        for (const validate of validators) {
            const error = validate(value);

            if (error) {
                this.keys.push(key);
                this.errors[key] = error;
            } else {
                const index = this.keys.indexOf(key);
                this.keys.splice(index, 1);
                delete this.errors[key];
            }

            invalid |= !!error;
        }

        return this.running ? !invalid : true;
    }

    isValid() {
        return this.keys.length === 0;
    }

    getErrorMessage(key) {
        return this.running ? this.errors[key] : null;
    }
}

export const bracket = (before, after) => async getPromise => {
    before();
    try {
        const result = await getPromise();
        return { ok: result };
    } catch (error) {
        return { error };
    } finally {
        after();
    }
};

export const localizeTimezone = (date, format = 'LLL') => {
    return moment
        .parseZone(date)
        .local()
        .format(format);
};

export const formatDate = (date, format = 'L') => date && moment(date).format(format);

//////////////////////////////////////////////////////////////////////////////////////////////
// https://misha.blog/javascript/numbers-and-words.html
// Example to use: wordForm($count, [' комментарий', ' комментария', ' комментариев']);
export const wordForm = (amount, word) => {
    let cases = [2, 0, 1, 1, 1, 2];
    return word[
        amount % 100 > 4 && amount % 100 < 20 ? 2 : cases[amount % 10 < 5 ? amount % 10 : 5]
    ];
};

export const getUserFullName = user => {
    return `${user.lastName} ${user.firstName} ${user.middleName}`;
};

export const getUserPositionAndCompany = user => {
    const values = [];

    if (!isNullOrWhitespace(user.currentPosition)) {
        values.push(user.currentPosition);
    }

    if (!isNullOrWhitespace(user.currentCompany)) {
        values.push(user.currentCompany);
    }

    if (values.length > 0) {
        return values.join(', ');
    }

    return "";
};

export const getUserShortName = user => {
    return user && `${user.lastName} ${user.firstName && user.firstName[0] + '.'} ${user.middleName &&
        user.middleName[0] + '.'}`;
};

export const getUserSymbols = user => {
    const nameList = [];

    if (user && user.firstName && user.firstName[0]) {
        nameList.push(user.firstName[0].toUpperCase());
    }

    if (user && user.lastName && user.lastName[0]) {
        nameList.push(user.lastName[0].toUpperCase());
    }

    return nameList.join('');
};

export function isNull(input) {
    return typeof input === 'undefined' || input == null || Number.isNaN(input);
}

export function isNullOrWhitespace(input) {
    if (isNull(input)) {
        return true;
    }
    return input.replace(/\s/g, '').length < 1;
}

export const trimObjectStrings = obj => {
    let newObj = {};
    for (const arr of Object.entries(obj)) {
        const key = arr[0];
        const val = arr[1];
        typeof obj[key] === 'string' ? (newObj[key] = val.trim()) : (newObj[key] = val);
    }
    return newObj;
};

export const capitalizeFirstLetter = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const isSameOrAfter = (date1, date2, type = 'month') =>
    moment(date1).isSameOrAfter(date2, type);

export const endOf = (startDate, type = 'month') =>
    startDate ? moment(startDate).endOf(type) : null;

export const startOf = (startDate, type = 'month') =>
    startDate ? moment(startDate).startOf(type) : null;

export const getMonths = () => {
    return [
        { value: 0, label: 'Январь' },
        { value: 1, label: 'Февраль' },
        { value: 2, label: 'Март' },
        { value: 3, label: 'Апрель' },
        { value: 4, label: 'Май' },
        { value: 5, label: 'Июнь' },
        { value: 6, label: 'Июль' },
        { value: 7, label: 'Август' },
        { value: 8, label: 'Сентябрь' },
        { value: 9, label: 'Октябрь' },
        { value: 10, label: 'Ноябрь' },
        { value: 11, label: 'Декабрь' },
    ];
};

export const getYears = (startYear, yearsCount) => {
    const years = [];

    for (var iterator = 0; iterator < yearsCount; iterator++) {
        const presentYear = startYear + iterator;

        years.push({
            value: presentYear,
            label: presentYear.toString(),
        });
    }

    return years;
};

export const formatPrice = value => {
    if (typeof value === 'number') {
        if (value % 1 !== 0) {
            return parseFloat(value).toLocaleString('ru');
        }

        if (value % 1 === 0) {
            return parseInt(value).toLocaleString('ru');
        }
    }

    return value;
};

export const getStateFromTextArea = textArea => ({
    selection: {
        start: textArea.selectionStart,
        end: textArea.selectionEnd,
    },
    text: textArea.value,
    selectedText: textArea.value.slice(textArea.selectionStart, textArea.selectionEnd),
});

export const getSurroundingWord = (text, position) => {
    if (!text) throw Error("Argument 'text' should be truthy");

    const isWordDelimiter = c => c === ' ' || c.charCodeAt(0) === 10;

    let start = 0;
    let end = text.length;

    for (let i = position; i - 1 > -1; i--) {
        if (isWordDelimiter(text[i - 1])) {
            start = i;
            break;
        }
    }

    for (let i = position; i < text.length; i++) {
        if (isWordDelimiter(text[i])) {
            end = i;
            break;
        }
    }

    return { start, end };
};

export const selectWord = ({ text, selection }) => {
    if (text && text.length && selection.start === selection.end) {
        return getSurroundingWord(text, selection.start);
    }
    return selection;
};

export const getShortText = value =>
    value &&
    (SHORT_TEXT.find(x => x.value.toLowerCase() === value.toLowerCase()) || { short: value }).short;

export const isMobileWidth = () => window.innerWidth <= 1366;

export const getRolesOptions = (selectedRoles, appRoles) => {
    if (!selectedRoles || !Array.isArray(selectedRoles) || !appRoles || !Array.isArray(appRoles)) {
        return
    }
    
    const selectedRolesWithAllProps = appRoles.filter(x => selectedRoles.map(i => i.id).includes(x.id));

    const result = {};
    for (const { roleGroup, ...tail } of selectedRolesWithAllProps) {
        if(!result[roleGroup]) {
            result[roleGroup] = [];
        }
        result[roleGroup].push({ ...tail });
    }

    let newRoles = appRoles;
    for (const selectedRole of selectedRolesWithAllProps) {

        if (!selectedRole.isMergeOwn) {
            newRoles = newRoles.filter(x => x.roleGroup !== selectedRole.roleGroup);
            newRoles.push(selectedRole);
        }

        if (!selectedRole.isMergeExt) {
            newRoles = newRoles.filter(x => x.roleGroup === selectedRole.roleGroup);
        } else {
            newRoles = newRoles.filter(x => x.isMergeExt);
        }
    }

    return newRoles
        .map(x => ({id: x.id, name: x.title}))
        .sort((a, b) => a.name.localeCompare(b.name));
};

// https://github.com/stiang/remove-markdown/blob/master/index.js
export const resetMarkdown = text => {
    let output = text || '';
    try {
        output = output.replace(/^(-\s*?|\*\s*?|_\s*?){3,}\s*$/gm, '');
        // eslint-disable-next-line
        output = output.replace(/^([\s\t]*)([\*\-\+]|\d+\.)\s+/gm, '$1');
        output = output
            // Header
            .replace(/\n={2,}/g, '\n')
            // Fenced codeblocks
            .replace(/~{3}.*\n/g, '')
            // Strikethrough
            .replace(/~~/g, '')
            // Fenced codeblocks
            .replace(/`{3}.*\n/g, '')
            // Remove HTML tags
            .replace(/<[^>]*>/g, '')
            // Remove setext-style headers
            // eslint-disable-next-line
            .replace(/^[=\-]{2,}\s*$/g, '')
            // Remove footnotes?
            // eslint-disable-next-line
            .replace(/\[\^.+?\](\: .*?$)?/g, '')
            .replace(/\s{0,2}\[.*?\]: .*?$/g, '')
            // Remove images
            // eslint-disable-next-line
            .replace(/\!\[(.*?)\][\[\(].*?[\]\)]/g, '')
            // Remove target links
            // eslint-disable-next-line
            .replace(/\[(.*?)\][\[\(].*?[\]\)][\[\(].*?[\]\)]/g, '$1')
            // Remove inline links
            // eslint-disable-next-line
            .replace(/\[(.*?)\][\[\(].*?[\]\)]/g, '$1')
            // Remove blockquotes
            .replace(/^\s{0,3}>\s?/g, '')
            // Remove reference-style links?
            .replace(/^\s{1,2}\[(.*?)\]: (\S+)( ".*?")?\s*$/g, '')
            // Remove atx-style headers
            .replace(/#{1,6}\s+| {0,}(\n)?\s{0,}#{0,} \s{0,}$/gm, '$1')
            // Remove emphasis (repeat the line to remove double emphasis)
            // eslint-disable-next-line
            .replace(/([\*_]{1,3})(\S.*?\S{0,1})\1/g, '$2')
            // eslint-disable-next-line
            .replace(/([\*_]{1,3})(\S.*?\S{0,1})\1/g, '$2')
            // Remove code blocks
            .replace(/(`{3,})(.*?)\1/gm, '$2')
            // Remove inline code
            .replace(/`(.+?)`/g, '$1');
    } catch(e) {
        console.error(e);
        return text;
    } 
    return output;
};

export const getSelectedText = (elemId) => {
    let text = "";
    const activeEl = document.activeElement;
    const activeElTagName = activeEl ? activeEl.tagName.toLowerCase() : null;
    if (
      ((activeElTagName === "textarea") || (activeElTagName === "input")) &&
      (typeof activeEl.selectionStart === "number") && activeEl.id === elemId
    ) {
        text = activeEl.value.slice(activeEl.selectionStart, activeEl.selectionEnd);
    } 
    return text;
};

export const isDevice = {
    XS: () => window.innerWidth <= 320,
    SM: () => window.innerWidth > 320 && window.innerWidth <= 576,
    MD: () => window.innerWidth > 576 && window.innerWidth <= 768,
    LG: () => window.innerWidth > 768 && window.innerWidth <= 992,
    XL: () => window.innerWidth > 992 && window.innerWidth <= 1200,
    XXL: () => window.innerWidth > 1200,
};