import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { MAIN_PAGE_URL } from 'components/Lk/Basic/constants';
import classnames from 'classnames';

const GoBackButton = props => {
    const { push, className="", backUrl, color = 'white', action = null } = props;

    return (
        <div
            className={classnames("LKGoBackButton", className )}
            onClick={() => (action ? action() : push(backUrl || MAIN_PAGE_URL))}
        >
            <div
                className={classnames('LKGoBackButton__Icon', {
                    [`LKGoBackButton__Icon--${color}`]: true,
                })}
            ></div>
            {props.children && <div className="LKGoBackButton__Title">{props.children}</div>}
        </div>
    );
};

const actions = { push };

export default connect(
    null,
    actions,
)(GoBackButton);
