import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { competenceModelsRoute, competenceModelEditRoute, competenceModelNewRoute, competenceModelCopyRoute } from 'routes';
import ModelList from 'pages/manager/moderation/competencies';
import Model from 'pages/manager/moderation/competencies/Model';
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import { ALLOWED_MODERATE_MODELS } from 'rightsController';
import NotFound from 'pages/common/NotFound';

class CompetenceModelsRoutes extends Component {

    renderNewModel = (props) => {
        const { state } = props.location;
        return <Model {...props} type={"new"} modelNames={state.modelNames} />;
    };

    renderEditModel = ({match, location}) => {
        const { params } = match;
        const { state } = location;
        return <Model type={"edit"} id={params.id} location={location} isEdit={state && state.isEdit} modelNames={state && state.modelNames} />;
    };

    renderCopyModel = ({match, location}) => {
        const { params } = match;
        const { state } = location;
        return <Model type={"copy"} id={params.id} location={location} modelNames={state.modelNames} isEdit={state && state.isEdit} />;
    };

    render() {
        return (
            <Switch>
                <ProtectedRoute exact path={competenceModelsRoute.url} component={ModelList} allowedRoles={ALLOWED_MODERATE_MODELS} />    
                <ProtectedRoute exact path={competenceModelEditRoute.url} component={this.renderEditModel} allowedRoles={ALLOWED_MODERATE_MODELS} /> 
                <ProtectedRoute exact path={competenceModelCopyRoute.url} component={this.renderCopyModel} allowedRoles={ALLOWED_MODERATE_MODELS} /> 
                <ProtectedRoute exact path={competenceModelNewRoute.url} component={this.renderNewModel} allowedRoles={ALLOWED_MODERATE_MODELS} />   
                <Route component={NotFound} />         
            </Switch>
        );
    }
}

export default CompetenceModelsRoutes;