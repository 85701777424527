import { faUpload } from "@fortawesome/free-solid-svg-icons";
import React, { Component } from "react";
import { uploadPersonFile } from "ducks/Person";
import Button from "../Button";
import "./MultiFileUploadButton.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteAllPersonFiles } from 'api';

const initialState = {
    files: [],
    localFilesIds: [],
    count: 0,
    loaded: false,
    loading: false,
    deleting: false,
    error: "",
}

class MultiFileUploadButton extends Component {
    inputRef = React.createRef();
    state = initialState;

    handleClick = () => {
        const { current } = this.inputRef;
        if (!current) { return; }

        current.click();
    }

    handleFileChange = async (e) => {
        const files = e.target.files;
        
        if (files.length === 0) { return; }
        
        this.setState({
            files: files,
            loading: true,
        });

        await this.uploadMultiFiles(files);
        this.setState({ 
            loading: false,
            loaded: true,
        });
    }
    
    uploadMultiFiles = async (files) => {
        try {
            for (const file of Array.from(files)) {
                const data = await this.uploadFile(file);
                this.setState(state => {
                    return {
                        ...state,
                        localFilesIds: [...state.localFilesIds, data.data.id],
                        count: state.count + 1,
                    }
                })                
            }
        } catch(error) {
            deleteAllPersonFiles(this.props.personId);
            this.setState({ 
                error: "Ошибка загрузки файлов",
                loaded: true,
            })
        }
    }
    
    uploadFile = uploadPersonFile(this.props.personId);

    removeError = async () => {
        this.setState(initialState);
    }

    removeFiles = async (personId) => {
        this.setState(state => {
            return {
                ...state,
                deleting: true
            }
        });
        await deleteAllPersonFiles(personId, this.state.localFilesIds);
        this.setState(initialState);
    }

    renderButton = () => {
        const { files, loading, count, loaded } = this.state;
        return (
            !loaded 
            ? loading ? this.renderLoading()
            :
            <>
                <Button
                    className="MultiFileUploadButton__Button"
                    type="button"
                    buttonText={files.length ? `${count} из ${files.length}`: "Загрузить файлы"}
                    faIcon={faUpload}
                    loading={loading}
                    onClick={this.handleClick}
                >
                </Button>
                <input
                    ref={this.inputRef}
                    className="MultiFileUploadButton__Input"
                    type="file"
                    multiple
                    tabIndex="-1"
                    onChange={this.handleFileChange}
                />
            </>
            :
            <>
                <div className="MultiFileUploadButton__Success">{`Загружено файлов: ${count}`}</div>
                
                {this.state.deleting 
                    ? 
                    <div className="MultiFileUploadButton__Updating">
                        <span><FontAwesomeIcon icon="circle-notch" size="lg" spin /></span>
                    </div>
                    : 
                    <div className="MultiFileUploadButton__Remove" onClick={() => this.removeFiles(this.props.personId)}/>}
            </>  
        );
    }

    renderLoading = () => {
        const { files, count } = this.state;
        return(
            count === 0 
            ? 
            <div className="MultiFileUploadButton__Updating">
                <span>{`Загружается...`}</span><span><FontAwesomeIcon icon="circle-notch" size="lg" spin /></span>
            </div>
            :
            <div className="MultiFileUploadButton__Updating">
                <span>{`Загружается ${count} из ${files.length}`}</span><span><FontAwesomeIcon icon="circle-notch" size="lg" spin /></span>
            </div>
        )
    }

    renderError = () => {
        const { error } = this.state;
        return (
            <>
                <div className="MultiFileUploadButton__Text  MultiFileUploadButton__Remove--error">{error}</div>
                {this.state.deleting 
                    ? 
                    <div className="MultiFileUploadButton__Updating--error">
                        <FontAwesomeIcon icon="circle-notch" size="lg" spin />
                    </div>
                    : 
                    <div className="MultiFileUploadButton__Remove" onClick={this.removeError}/>}
            </>            
        );
    }

    render = () => {
        const { error } = this.state;
        return (
            <div className="MultiFileUploadButton">
                {!error ? this.renderButton() : this.renderError()}
            </div>
        );
    }
}

export default MultiFileUploadButton;