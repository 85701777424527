import React from 'react';
import Select, { components } from 'react-select';
import Option from '../Option';
import classnames from 'classnames';
import './Select.scss';

export default props => {
    const { className = '', ...tail } = props;

    return (
        <Select
            {...tail}
            className={classnames('Select', className)}
            components={{
                Option: optionProps => <Option {...optionProps} dataTest={props.dataTest} />,
            }}
        />
    );
};
export { components };
