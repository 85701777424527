import { Route, Switch } from 'react-router';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchTestsResultsByPersonId, isPersonEducationEnabled } from 'ducks/Person';
import { showWarningAlert, showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import { loadAttributes } from 'ducks/Attributes';
import { GOVT_SERVICE_KINDS } from 'ducks/Catalog';
import {
    allPersonCatalogs,
    educationBlockCatalogs,
    familyStatusBlockCatalogs,
    languagesBlockCatalogs,
    personalInfoBlockCatalogs,
    personnelInfoBlockCatalogs,
    personFullSelector,
    socialNetworksBlockCatalogs,
    workInfoBlockCatalogs,
    togglePersonInWishlist,
    getPersonFileUrl,
    saveCardRefs,
} from 'ducks/Person';
import ModalDialog from 'components/common/ModalDialog';
import { saveBlock, setEditBlock } from 'ducks/PersonBlock';
import AttributesBlock from './AttributesBlock';
import EducationBlock from './EducationBlock';
import FamilyStatusBlock from './FamilyStatusBlock';
import FilesBlock from './FilesBlock';
import LanguagesBlock from './LanguagesBlock';
import PersonalInfoBlock from './PersonalInfoBlock';
import PersonnelInfoBlock from './PersonnelInfoBlock';
import SocialNetworksBlock from './SocialNetworksBlock';
import WorkBlock from './WorkBlock';
import SystemInfoBlock from './SystemInfoBlock';
import PersonCommentsBlock from './PersonCommentsBlock';

import { canEditPerson } from '../../rightsController';
import uuid from 'uuid/v4';
import NotFound from 'pages/common/NotFound';
import {
    profile,
    profileEvaluate,
    profileTeams,
    person,
    personEvaluate,
    personProgress,
    personTeams,
    personEducationEvents,
} from 'routes';
import PersonEvaluate from './Evaluate';
import PersonProgress from './Progress';
import PersonTeams from './Teams';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { isNullOrWhitespace } from 'utils';
import EducationEvents from 'components/Person/EducationEvents';

import './PersonDataCard.scss';
import AccessBlock from './AccessBlock';
import { isAccessEnabled } from 'ducks/Teams';
import { BIRTH_DATE_DISPLAY_MODES, ACCESS_STATUS } from 'constants.js';

class PersonDataCard extends Component {
    static getDerivedStateFromProps(props, state) {
        if (state.loadComplete && props.editBlock && !state.isBlockChanged) {
            return null;
        }

        const { person } = props;

        let loadComplete = person.loadComplete;
        if (state.loadComplete == null) {
            loadComplete = loadComplete && person.isActual;
        }

        if (!loadComplete) {
            return { loadComplete };
        }

        return PersonDataCard.getFreeStateFromPropsAndState(props, state);
    }

    static getFreeStateFromPropsAndState(props, state) {
        const { person } = props;

        const {
            personalInfo = {},
            educationInfo = {},
            workInfo = {},
            languagesInfo = {},
            socialNetworksInfo = {},
            filesInfo = {},
            filesDirectoryId,
            attributesInfo = {},
            personnelInfo = {},
            governmentServiceInfo = {},
            systemInfo = {},
        } = person;

        return {
            mainMenuId: state.mainMenuId || props.personProfileBlock || 'profile',
            editBlock: state.editBlock || '',
            isBlockChanged: false,
            updatingPhoto: person.updatingPhoto,
            refetchKey: person.refetchKey,
            loadComplete: true,
            person: {
                personalInfo: {
                    id: personalInfo.id,
                    snils: personalInfo.snils,
                    lastName: personalInfo.lastName || '',
                    firstName: personalInfo.firstName || '',
                    middleName: personalInfo.middleName || '',
                    selectedSex: personalInfo.sex,
                    birthDate: personalInfo.birthDate,
                    birthDateDisplayMode:
                        personalInfo.birthDateDisplayMode || BIRTH_DATE_DISPLAY_MODES.FullDate,
                    birthPlace: personalInfo.birthPlace || '',
                    email: personalInfo.email || '',
                    phone: personalInfo.phone || '',
                    selectedDistrict: personalInfo.federalDistrict,
                    selectedRegion: personalInfo.region,
                    selectedLocality: personalInfo.locality,
                    selectedDocument: personalInfo.document,
                    sex: personalInfo.sex,
                    documentNumber: personalInfo.documentNumber || '',
                    documentName: personalInfo.documentName || '',
                    isRussianCitizen: personalInfo.isRussianCitizen,
                    foreignRegion: personalInfo.foreignRegion,
                    foreignLocality: personalInfo.foreignLocality,
                    residenceStartYear: personalInfo.residenceStartYear,
                    selectedNationality: personalInfo.nationality,
                    readyMoveToRussia: personalInfo.readyMoveToRussia || false,
                    region: personalInfo.region,
                    locality: personalInfo.locality,
                    extraInfo: personalInfo.extraInfo,
                    pastResidences: personalInfo.pastResidences,
                    isFavorite: personalInfo.isFavorite,
                },
                educationInfo: {
                    educations: List(educationInfo.educations),
                },
                workInfo: {
                    workPlaces: List(workInfo.workPlaces),
                },
                governmentServiceInfo: {
                    governmentServices: List(governmentServiceInfo.governmentServices),
                },
                languagesInfo: {
                    knownLanguages: List(languagesInfo.knownLanguages),
                },
                socialNetworksInfo: {
                    networks: List(socialNetworksInfo.networks),
                },
                foreignerInfo: {
                    selectedNationality: personalInfo.nationality,
                    readyMoveToRussia: personalInfo.readyMoveToRussia || false,
                },
                familyInfo: {
                    selectedFamilyStatus: personalInfo.familyStatus,
                    selectedChildrenStatus: personalInfo.childrenStatus,
                },
                filesInfo: {
                    files: List(filesInfo.files),
                },
                personnelInfo: {
                    id: personnelInfo.id,
                    selectedDataOrigin: personnelInfo.dataOrigin,
                    recommenderName: personnelInfo.recommenderName,
                    curatorName: personnelInfo.curatorName,
                    curatorId: personnelInfo.curatorId,
                    selectedCuratorName: personnelInfo.selectedCuratorName,
                    selectedGeoLevel: personnelInfo.selectedGeoLevel,
                    selectedManagerLevel: personnelInfo.selectedManagerLevel,
                    selectedExperienceLevel: personnelInfo.experienceLevel,
                    socialActivity: personnelInfo.socialActivity || '',
                    memberOfSocialOrganizations: personnelInfo.memberOfSocialOrganizations || '',
                    socialAchivements: personnelInfo.socialAchivements || '',
                    extraInfo: personnelInfo.extraInfo,
                    awards: personnelInfo.awards,
                    inclusionDate: personnelInfo.inclusionDate,
                    inclusionReason: personnelInfo.inclusionReason,
                    totalManagementExperience: personnelInfo.totalManagementExperience,
                    totalRegionSpentTime: personnelInfo.totalRegionSpentTime,
                    reserveLevel: personnelInfo.reserveLevel,
                    governmentWorkPositions: personnelInfo.governmentWorkPositions,
                },
                attributesInfo: {
                    attributes: List(attributesInfo.attributes).map((attribute) => ({
                        ...attribute,
                        attributeValue:
                            attribute.attribute.type.toLowerCase() === 'select' ||
                            attribute.attribute.type.toLowerCase() === 'multiselect'
                                ? JSON.parse(attribute.attributeValue)
                                : attribute.attributeValue,
                        attribute: {
                            ...attribute.attribute,
                            local_id: uuid(),
                            payload:
                                attribute.attribute.payload &&
                                JSON.parse(attribute.attribute.payload),
                        },
                    })),
                },
                filesDirectoryId,
                systemInfo: {
                    groups: systemInfo.groups,
                    selectedGroup: systemInfo.groupId,
                    selectedPersonGroupIds: personalInfo.personGroupIds,
                    user: systemInfo.user,
                    curatorId: personnelInfo.curatorId,
                    selectedCuratorName: personnelInfo.selectedCuratorName,
                    selectedCurator: {
                        label: personnelInfo.selectedCuratorName,
                        value: {
                            ...personnelInfo.curator,
                            fullName: personnelInfo.selectedCuratorName,
                        },
                    },
                },
                comment: {
                    text: '',
                    visibleToAll: false,
                    personId: personalInfo.id,
                },
            },
        };
    }

    state = {
        id: this.props.id,
        person: {},
        loadComplete: null,
        editBlock: '',
        isBlockChanged: false,
        draggedBlock: '',
        testsResults: [],
        unsavedBlock: false,
        unsavedBlockNotify: false,
        resolvedPersonalInfo: {},
    };

    constructor(props) {
        super(props);

        this.cardRefs = {
            personal: React.createRef(),
            comments: React.createRef(),
            education: React.createRef(),
            workExperience: React.createRef(),
            languages: React.createRef(),
            family: React.createRef(),
            socialNetworks: React.createRef(),
            files: React.createRef(),
            personnel: React.createRef(),
            attributes: React.createRef(),
            systemInfo: React.createRef(),
        };

        this.blockRefMap = {
            PersonalInfoBlock: 'personal',
            PersonCommentsBlock: 'comments',
            EducationBlock: 'education',
            WorkBlock: 'workExperience',
            LanguagesBlock: 'languages',
            FamilyStatusBlock: 'family',
            FilesBlock: 'files',
            SocialNetworksBlock: 'socialNetworks',
            PersonnelInfoBlock: 'personnel',
            AttributesBlock: 'attributes',
            SystemInfoBlock: 'systemInfo',
        };

        this.evaluationRefs = {
            evaluation: React.createRef(),
            newTests: React.createRef(),
            rejectedTests: React.createRef(),
            actualTests: React.createRef(),
            archiveTests: React.createRef(),
        };
    }

    componentDidMount() {
        this.props.setEditBlock('');
        this.updateData();
    }

    updateData = () => {
        this.callEffect(async () => {
            const criteria = { paging: { pageNum: 1, pageSize: 50 } };
            await this.props.loadAttributes(criteria);
            await this.props.saveCardRefs(this.cardRefs, this.evaluationRefs);
        });
    };

    updateResults = (personId) => {
        this.callEffect(async () => {
            await this.props.fetchTestsResultsByPersonId(personId);
        });
    };

    getCatalogs = (names) => {
        return names.reduce((obj, name) => {
            obj[name] = this.props.catalogs[name];
            return obj;
        }, {});
    };

    getCurrentWorkPlace = (workPlaces = List()) => {
        if (workPlaces.size === 0) return List();
        if (workPlaces.size === 1) return workPlaces.get(0);

        const currentWorkPlace = workPlaces.filter((x) => {
            return x.isCurrentWorkPlace;
        });
        return currentWorkPlace.get(0) || workPlaces.get(0);
    };

    resumeUnsavedBlock = (blockName) => () => {
        const { draggedBlock } = this.state;
        this.setState({ unsavedBlockNotify: false, unsavedBlock: false }, () =>
            this.setEditBlock(blockName),
        );
        setTimeout(() => {
            this.scrollToBlock(draggedBlock || blockName);
        }, 1000);
    };

    setEditBlock = (blockName) => {
        if (
            this.blockedBySnils() && 
            blockName !== 'PersonalInfoBlock' &&
            blockName !== ''
        ) {
            this.props.setEditBlock('PersonalInfoBlock');
            showWarningAlert('Необходимо заполнить поле СНИЛС');
            this.scrollToBlock(this.props.editBlock);
            return;
        }

        const { unsavedBlock } = this.state;
        if (unsavedBlock?.state) {
            this.scrollToBlock(this.props.editBlock);
            this.setState({
                unsavedBlockNotify: true,
                unsavedBlock: { ...unsavedBlock, nextBlock: blockName },
            });
        } else {
            this.props.setEditBlock(blockName);
        }
    };

    closeUnsavedNotify = () => {
        this.setState({ unsavedBlockNotify: false });
        this.setDraggedBlock('');
    };

    renderEmptyBlock = () => {
        return <div className="Person__NotFound">Нет данных</div>;
    };

    handleStateChange = (blockStateKey, blockState) => {
        this.setState((state) => {
            const person = {
                ...state.person,
                [blockStateKey]: { ...state.person[blockStateKey], ...blockState },
            };
            return {
                ...state,
                person: { ...person },
            };
        });
    };

    toggleFavoriteFlag = () => this.props.togglePersonInWishlist(this.state.person.personalInfo.id);

    setUnsavedBlock = (block) => {
        this.setState({ unsavedBlock: block });
    };

    scrollToBlock = (blockName) => {
        blockName &&
            window.scrollTo(0, this.cardRefs[this.blockRefMap[blockName]].current.offsetTop - 100);
    };

    setDraggedBlock = (draggedBlock) => this.setState({ draggedBlock });

    componentDidUpdate(prevProps, prevState) {
        const { draggedBlock } = this.state;
        const { editBlock } = this.props;

        if (prevProps.editBlock !== editBlock) {
            editBlock && this.scrollToBlock(editBlock);

            const isBlockChanged = editBlock && this.editBlock !== editBlock;
            this.editBlock = editBlock;
            if (isBlockChanged && editBlock) {
                this.setState({ isBlockChanged: isBlockChanged });
            }
        }

        if (prevState.draggedBlock !== draggedBlock) {
            this.scrollToBlock(draggedBlock || editBlock);
        }

        const { testsResults } = this.props.person;

        if (prevProps.person.testsResults !== testsResults) {
            this.setState((state) => {
                return { ...state, testsResults };
            });
        }
    }

    blockedBySnils = () =>
        !this.props.person.personalInfo.snils && !!this.props.person.personalInfo.isRussianCitizen;

    render() {
        if (!this.state.loadComplete) {
            return null;
        }

        const { personEducationEnabled } = this.props;

        return (
            <>
                <Switch>
                    <Route path={[person.url, profile.url]} exact component={this.renderProfile} />
                    <Route
                        path={[personEvaluate.url, profileEvaluate.url]}
                        exact
                        render={(props) => (
                            <PersonEvaluate
                                emailIsValid={
                                    !isNullOrWhitespace(this.state.person.personalInfo.email)
                                }
                                testsResults={this.state.testsResults}
                                updateResults={this.updateResults}
                                {...props}
                                {...this.props}
                                evaluationRefs={this.evaluationRefs}
                            />
                        )}
                    />
                    <Route
                        path={personProgress.url}
                        exact
                        render={() => <PersonProgress {...this.props} />}
                    />
                    {personEducationEnabled && (
                        <Route
                            path={[personEducationEvents.url]}
                            exact
                            render={() => <EducationEvents person={this.props.person} />}
                        />
                    )}

                    <Route
                        path={[personTeams.url, profileTeams.url]}
                        exact
                        render={() => (
                            <PersonTeams
                                person={this.props.person}
                                user={this.props.user}
                                hidePerson={this.props.hidePerson}
                            />
                        )}
                    />

                    <Route component={NotFound} />
                </Switch>
            </>
        );
    }

    canDragWorkPlaces = () => {
        return (
            !this.blockedBySnils() &&
            (this.state.person.workInfo.workPlaces.size > 1 ||
                this.state.person.governmentServiceInfo.governmentServices.size > 1)
        );
    };

    isPersonAccessExist = () => {
        const { access } = this.props;
        const { user } = this.state.person.systemInfo;
        const { lastAccessRequest } = access.data;

        const isRejected =
            [ACCESS_STATUS.REJECTED_BY_IT, ACCESS_STATUS.REJECTED_BY_SECURITY].indexOf(
                lastAccessRequest && lastAccessRequest.status,
            ) !== -1;

        return !isRejected && user && Array.isArray(user.roles) && user.roles.length > 0;
    };

    renderModalHeader = () => {
        return <div className="PersonDataCard__ModalHeader">Внесенные изменения будут удалены</div>;
    };

    renderProfile = () => {
        const currentWorkPlace = this.getCurrentWorkPlace(this.state.person.workInfo.workPlaces);
        const user = this.props.user;
        const { draggedBlock } = this.state;
        const { accessEnabled, access } = this.props;

        return (
            <>
                <div id="personal" ref={this.cardRefs.personal}>
                    <PersonalInfoBlock
                        person={this.state.person}
                        {...this.state.person.personalInfo}
                        {...this.state.person.familyInfo}
                        {...this.state.person.systemInfo}
                        handleStateChange={this.handleStateChange.bind(null, 'personalInfo')}
                        setEditBlock={this.setEditBlock}
                        isEditBlock={this.props.editBlock === 'PersonalInfoBlock'}
                        {...this.getCatalogs(personalInfoBlockCatalogs)}
                        {...this.getCatalogs(familyStatusBlockCatalogs)}
                        saveBlock={this.props.saveBlock}
                        blockType="PersonalInfoBlock"
                        id={this.state.person.personalInfo.id}
                        personId={this.state.person.personalInfo.id}
                        needPersonId={false}
                        currentWorkPlace={currentWorkPlace}
                        canEditPerson={canEditPerson(user)}
                        toggleFavoriteFlag={this.toggleFavoriteFlag}
                        blockRef={this.cardRefs.personal}
                    />
                </div>

                <div id="personal" ref={this.cardRefs.comments}>
                    <PersonCommentsBlock
                        {...this.state.person.comment}
                        comment={this.state.person.comment}
                        setEditBlock={this.setEditBlock}
                        handleStateChange={this.handleStateChange.bind(null, 'comment')}
                        isEditBlock={this.props.editBlock === 'PersonCommentsBlock'}
                        {...this.getCatalogs(educationBlockCatalogs)}
                        saveBlock={this.props.saveBlock}
                        blockType="PersonCommentsBlock"
                        defaultOpen={false}
                        personId={this.state.person.personalInfo.id}
                        renderEmptyBlock={this.renderEmptyBlock}
                        currentUser={user}
                        canEditPerson={canEditPerson(user)}
                        setUnsavedBlock={this.setUnsavedBlock}
                        blockRef={this.cardRefs.comments}
                    />
                </div>

                {accessEnabled && (
                    <div>
                        <AccessBlock
                            personId={this.state.person.personalInfo.id}
                            user={this.state.person.systemInfo.user}
                            isDisabled={this.blockedBySnils()}
                        />
                    </div>
                )}

                <div id="education" ref={this.cardRefs.education}>
                    <EducationBlock
                        {...this.state.person.educationInfo}
                        setEditBlock={this.setEditBlock}
                        handleStateChange={this.handleStateChange.bind(null, 'educationInfo')}
                        isEditBlock={this.props.editBlock === 'EducationBlock'}
                        {...this.getCatalogs(educationBlockCatalogs)}
                        saveBlock={this.props.saveBlock}
                        blockType="EducationBlock"
                        id={this.state.person.educationInfo.id}
                        personId={this.state.person.personalInfo.id}
                        renderEmptyBlock={this.renderEmptyBlock}
                        isArray={true}
                        needPersonId
                        canEditPerson={canEditPerson(user)}
                        blockRef={this.cardRefs.education}
                    />
                </div>
                <div id="workExperience" ref={this.cardRefs.workExperience}>
                    <WorkBlock
                        {...this.state.person.governmentServiceInfo}
                        governmentServiceKindCatalog={this.props.catalogs[GOVT_SERVICE_KINDS]}
                        handleStateChange={this.handleStateChange}
                        {...this.state.person.workInfo}
                        currentWorkPlace={currentWorkPlace}
                        setEditBlock={this.setEditBlock}
                        isEditBlock={this.props.editBlock === 'WorkBlock'}
                        renderEmptyBlock={this.renderEmptyBlock}
                        catalogs={this.getCatalogs(workInfoBlockCatalogs)}
                        saveBlock={this.props.saveBlock}
                        blockType="WorkBlock"
                        isArray={true}
                        personId={this.state.person.personalInfo.id}
                        canEditPerson={canEditPerson(user)}
                        canDrag={this.canDragWorkPlaces()}
                        scrollToBlock={this.scrollToBlock}
                        editBlock={this.props.editBlock}
                        blockRef={this.cardRefs.workExperience}
                        setDraggedBlock={this.setDraggedBlock}
                        draggedBlock={draggedBlock}
                    />
                </div>
                <div id="languages" ref={this.cardRefs.languages}>
                    <LanguagesBlock
                        {...this.state.person.languagesInfo}
                        setEditBlock={this.setEditBlock}
                        handleStateChange={this.handleStateChange.bind(null, 'languagesInfo')}
                        isEditBlock={this.props.editBlock === 'LanguagesBlock'}
                        {...this.getCatalogs(languagesBlockCatalogs)}
                        renderEmptyBlock={this.renderEmptyBlock}
                        saveBlock={this.props.saveBlock}
                        blockType="LanguagesBlock"
                        isArray={true}
                        personId={this.state.person.personalInfo.id}
                        canEditPerson={canEditPerson(user)}
                        blockRef={this.cardRefs.languages}
                    />
                </div>
                <div id="family" ref={this.cardRefs.family}>
                    <FamilyStatusBlock
                        {...this.state.person.familyInfo}
                        {...this.state.person.systemInfo}
                        {...this.state.person.personalInfo}
                        setEditBlock={this.setEditBlock}
                        handleStateChange={this.handleStateChange.bind(null, 'familyInfo')}
                        isEditBlock={this.props.editBlock === 'FamilyStatusBlock'}
                        {...this.getCatalogs(personalInfoBlockCatalogs)}
                        {...this.getCatalogs(familyStatusBlockCatalogs)}
                        renderEmptyBlock={this.renderEmptyBlock}
                        saveBlock={this.props.saveBlock}
                        blockType="FamilyStatusBlock"
                        blockId={this.state.person.educationInfo.id}
                        personId={this.state.person.personalInfo.id}
                        needPersonId={false}
                        canEditPerson={canEditPerson(user)}
                        blockRef={this.cardRefs.family}
                    />
                </div>
                <div id="socialNetworks" ref={this.cardRefs.socialNetworks}>
                    <SocialNetworksBlock
                        {...this.state.person.socialNetworksInfo}
                        setEditBlock={this.setEditBlock}
                        handleStateChange={this.handleStateChange.bind(null, 'socialNetworksInfo')}
                        isEditBlock={this.props.editBlock === 'SocialNetworksBlock'}
                        {...this.getCatalogs(socialNetworksBlockCatalogs)}
                        renderEmptyBlock={this.renderEmptyBlock}
                        saveBlock={this.props.saveBlock}
                        blockType="SocialNetworksBlock"
                        isArray={true}
                        personId={this.state.person.personalInfo.id}
                        canEditPerson={canEditPerson(user)}
                        blockRef={this.cardRefs.socialNetworks}
                    />
                </div>
                <div id="files" ref={this.cardRefs.files}>
                    <FilesBlock
                        {...this.state.person.filesInfo}
                        personId={this.state.person.personalInfo.id}
                        getFileUrl={getPersonFileUrl(this.state.person.personalInfo.id)}
                        setEditBlock={this.setEditBlock}
                        handleStateChange={this.handleStateChange.bind(null, 'filesInfo')}
                        isEditBlock={this.props.editBlock === 'FilesBlock'}
                        saveBlock={this.props.saveBlock}
                        blockType="FilesBlock"
                        renderEmptyBlock={this.renderEmptyBlock}
                        isArray={true}
                        canEditPerson={canEditPerson(user)}
                        blockRef={this.cardRefs.files}
                    />
                </div>
                <div id="personnel" ref={this.cardRefs.personnel}>
                    <PersonnelInfoBlock
                        {...this.state.person.personnelInfo}
                        {...this.getCatalogs(personnelInfoBlockCatalogs)}
                        setEditBlock={this.setEditBlock}
                        handleStateChange={this.handleStateChange.bind(null, 'personnelInfo')}
                        isEditBlock={this.props.editBlock === 'PersonnelInfoBlock'}
                        renderEmptyBlock={this.renderEmptyBlock}
                        saveBlock={this.props.saveBlock}
                        blockType="PersonnelInfoBlock"
                        personId={this.state.person.personalInfo.id}
                        needPersonId={true}
                        canEditPerson={canEditPerson(user)}
                        blockRef={this.cardRefs.personnel}
                    />
                </div>
                {this.props?.catalogs?.attributes?.data.length !== 0 && (
                    <div id="attributes" ref={this.cardRefs.attributes}>
                        <AttributesBlock
                            {...this.state.person.attributesInfo}
                            catalogs={this.props.catalogs}
                            setEditBlock={this.setEditBlock}
                            handleStateChange={this.handleStateChange.bind(null, 'attributesInfo')}
                            isEditBlock={this.props.editBlock === 'AttributesBlock'}
                            renderEmptyBlock={this.renderEmptyBlock}
                            saveBlock={this.props.saveBlock}
                            blockType="AttributesBlock"
                            isArray={true}
                            personId={this.state.person.personalInfo.id}
                            canEditPerson={canEditPerson(user)}
                            blockRef={this.cardRefs.attributes}
                        />
                    </div>
                )}
                <div id="systemInfo" ref={this.cardRefs.systemInfo}>
                    <SystemInfoBlock
                        {...this.state.person.familyInfo}
                        {...this.state.person.systemInfo}
                        {...this.state.person.personalInfo}
                        personnelInfo={this.state.person.personnelInfo}
                        personId={this.state.person.personalInfo.id}
                        setEditBlock={this.setEditBlock}
                        handleStateChange={this.handleStateChange.bind(null, 'systemInfo')}
                        isEditBlock={this.props.editBlock === 'SystemInfoBlock'}
                        {...this.getCatalogs(personalInfoBlockCatalogs)}
                        {...this.getCatalogs(familyStatusBlockCatalogs)}
                        saveBlock={this.props.saveBlock}
                        blockType="SystemInfoBlock"
                        needPersonId={false}
                        canEditPerson={canEditPerson(user)}
                        access={access}
                        accessEnabled={accessEnabled}
                        isPersonAccessExist={this.isPersonAccessExist()}
                        blockRef={this.cardRefs.systemInfo}
                    />
                </div>

                <ModalDialog
                    onClick={this.resumeUnsavedBlock(this.state.unsavedBlock.nextBlock)}
                    onCloseModal={this.closeUnsavedNotify}
                    modalOpen={this.state.unsavedBlockNotify}
                    modalHeader={this.renderModalHeader()}
                    btnOktext={'Подтвердить'}
                    btnCanceltext={'Отменить'}
                    sideButtons
                />
            </>
        );
    };

    callEffect = async (callback) => {
        this.props.showPageLoader();
        try {
            await callback();
        } catch (error) {
            this.props.showErrorAlert(error.message);
        } finally {
            this.props.hidePageLoader();
        }
    };
}

PersonDataCard.propTypes = {
    id: PropTypes.number.isRequired,
    person: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    const config = state.config;
    const access = state.access;

    const person = personFullSelector(state, ownProps.id);
    let catalogs = allPersonCatalogs.reduce((obj, name) => {
        obj[name] = state.catalogs[name];
        return obj;
    }, {});

    const attributesData = state.attributes.data.payload.map((x) => {
        return { id: x.id, code: x.type, type: x.type, name: x.name, payload: x.payload };
    });
    const attributes = { data: attributesData };

    catalogs = { ...catalogs, attributes };

    const loading = !!state.personBlock.saving || !!state.person.loading;

    const accessEnabled = isAccessEnabled(state);
    const personEducationEnabled = isPersonEducationEnabled(state);

    return {
        config,
        person,
        mergeData: state.person.mergeData,
        catalogs,
        user: state.auth.user,
        loading: loading,
        accessEnabled,
        personEducationEnabled,
        access,
        editBlock: loading
            ? state.personBlock.editBlock || state.personBlock.lastEditBlock
            : state.personBlock.editBlock,
    };
};

const actions = {
    fetchTestsResultsByPersonId,
    loadAttributes,
    showWarningAlert,
    showErrorAlert,
    showSuccessAlert,
    saveBlock,
    saveCardRefs,
    togglePersonInWishlist,
    setEditBlock,
    showPageLoader,
    hidePageLoader,
};

export default connect(mapStateToProps, actions)(PersonDataCard);
