import React from 'react';
import classnames from 'classnames';

const DataCardContent = props => {
    const { isOpen, children } = props;
    return (
        <div
            className={classnames('DataCard__Content', {
                'DataCard__Content--hidden': !isOpen,
            })}
        >
            {children}
        </div>
    );
};

export default DataCardContent;
