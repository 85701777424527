import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { ACTIVE_DETAIL_CARD, 
    EVALUATION_TYPE,
    COMPETENCIES_TEST_ID,
    MANAGEMENT_POTENTIAL_TESTS_ID,
    BEHAVIOR_EVAL_TYPE_ID,
    CAREER_DESTRUCTORS_EVAL_TYPE_ID,
} from 'components/Lk/Basic/constants';
import EvaluationCardItemBlock from './EvaluationCardItemBlock';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';
import { DataCard } from 'components/Lk/Uikit/DataCard';
import EvaluationType from './EvaluationType/EvaluationType';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { showErrorAlert } from 'ducks/Alert';
import { fetchTeams } from 'ducks/Teams';
import { changeBasket } from 'ducks/Baskets';
import { fetchEvaluation, setSelectes, setEvaluationTestsCriterion } from 'ducks/Evaluation';
import Slider from 'components/Lk/Uikit/Slider/Slider';
import RenderPerson from './RenderPerson';
import Loader from "components/common/Loader";
import { lkDetailsEvaluateTeamProfile } from 'routes';
import ModalSummaryCard from 'components/Lk/Common/ModalPersonSummaryCard';
import MenuFilter from 'components/Lk/Uikit/Filter/MenuFilter';
import SorterButton from 'components/Lk/Uikit/Buttons/SorterButton';
import { evaluateResultCardMenuItems } from 'components/Lk/Basic/MainCard/constants';
import { 
    hasEvaluationsMenuOptions, 
    sortingOptions, 
    ALL_EVALUATIONS_MENU_ID, 
    HAS_EVALUATIONS_MENU_ID, 
    HAS_NO_EVALUATIONS_MENU_ID  
} from "./constants.js";
import NoDataBlock from 'components/Lk/Common/NoDataBlock';
import './EvaluationResultTeam.scss';
import EvaluteScaleInfo from './EvaluteScaleInfo';
import { getCompetencies } from 'api';
import { deepClone } from 'utils';

const EvaluationResultTeam = (props) => {

    const { activeCard, setActiveCard, personId, fetchTeams, fetchEvaluation, setEvaluationTestsCriterion, setSelectes } = props;

    const [teams, setTeams] = useState([]);
    const [evaluation, setEvaluation] = useState([]);
    const [resultIds, setResultIds] = useState([]);
    const [criteriaSelectedId, setCriteriaSelectedId] = useState({});
    const [resultCriteria, setResultCriteria] = useState([]);
    const [selectedSorting, setSelectedSorting] = useState(sortingOptions[0]);
    const [selectedFilter, setSelectedFilter] = useState(ALL_EVALUATIONS_MENU_ID);
    const [competencyModels, setCompetencyModels] = useState([]);
    const [criteriasLoading, setCriteriasLoading] = useState(false);
    const [teamsLoading, setTeamsLoading] = useState(false);

    const refItem = React.createRef();
    const useRefItem = useRef(true);

    setActiveCard(ACTIVE_DETAIL_CARD);
    const isMobileActive = activeCard === ACTIVE_DETAIL_CARD;

    useEffect(() => {
        const fetchData = async () => {
            try {
                showPageLoader();
                if (!props.evaluation.isLoaded && !props.evaluation.loading ) {
                    fetchEvaluation();
                }

                if (!criteriasLoading && !props.evaluation.criteriasIsLoaded && !props.evaluation.criteriasLoading) {
                    setEvaluationTestsCriterion(personId);
                    setCriteriasLoading(true);
                }
                if (props.evaluation.criteriasIsLoaded) {
                    setCriteriasLoading(false);
                }
                
                if (!teamsLoading && !props.teams.isLoaded && !props.teams.loading) {
                    fetchTeams(personId);
                    setTeamsLoading(true);
                }
                if (props.teams.isLoaded) {
                    setTeamsLoading(false);
                }
                
            } catch (error) {
                showErrorAlert(error.message);
            } finally {
                hidePageLoader();
            }
        };

        fetchData();
    }, [fetchEvaluation, fetchTeams, setEvaluationTestsCriterion, personId, props.evaluation, 
        props.teams, props.testedPersons, criteriasLoading, teamsLoading]);

    useEffect(() => {
        let mount = true;

        const fetchCompetencyModels = async () => {
            try {
                showPageLoader();

                const competencyModels = await getCompetencies(personId);

                mount && competencyModels && competencyModels.status === 200 && competencyModels.data &&
                    setCompetencyModels(competencyModels.data);

            } catch (error) {
                showErrorAlert(error.message);
            } finally {
                hidePageLoader();
            }
        };
        fetchCompetencyModels();

        return () => { mount = false }
    }, [personId]);

    const getTeams = useCallback(() => {

        const getTeamPeople = team => {
            const persons = [];

            team.leaderPerson.id !== personId && persons.push(team.leaderPerson);
            for (const p of team.followerPersons) {
                p.id !== personId && persons.push(p);
            }

            const chooseValueType = testId => {
                switch (testId) {
                    case BEHAVIOR_EVAL_TYPE_ID:
                    case CAREER_DESTRUCTORS_EVAL_TYPE_ID:
                        return 'decimalValue';
                    default:
                        return 'normalizedValue';
                }
            };
            
            const selectedTestId = !!props.selectedTest.id && props.selectedTest.id;
            
            const fillByEvaluationTestType = (percentItem) => {
                switch (selectedTestId) {
                case COMPETENCIES_TEST_ID:
                    const competencyTestsIds = props.evaluation.list.filter(x => x.kind === 'Competency').map(x => x.id);
                    return percentItem
                            .filter(x => competencyTestsIds.includes(x.testId))
                            .map(x => ({...x, testId: COMPETENCIES_TEST_ID}))
                            .map(x => ({...x, criterionId: x.personGrowthTagId }));
                case MANAGEMENT_POTENTIAL_TESTS_ID:
                    const potentialTestsIds = props.evaluation.list.filter(x => x.kind === 'Potential').map(x => x.id);
                    return percentItem
                        .filter(x => potentialTestsIds.includes(x.testId))
                        .map(x => ({...x, testId: MANAGEMENT_POTENTIAL_TESTS_ID}))
                        .map(x => ({...x, criterionId: x.personGrowthTagId }));
                default:
                    return percentItem;
                }
            };
            
            return persons.map(person => {
                const percentItem = props.testedPersons
                    ? props.testedPersons
                        .filter(x => x.personId === person.id)
                        .map(x => ({...x, show: chooseValueType(x.testId) }))
                    : [];
                
                return {
                    ...person,
                    percent: fillByEvaluationTestType(percentItem)
                }
            });
        };

        let readyTeams = [];

        if (props.teams && props.teams.leading && props.teams.leading.length !== 0) {
            for (const x of props.teams.leading) {
                const persons = getTeamPeople(x);
                readyTeams = [...readyTeams, { teamId: x.id, teamName: x.name, persons: persons }];
            }
        }
        
        return readyTeams;

    }, [props.teams, props.testedPersons, personId, props.selectedTest, props.evaluation.list]);

    useEffect(() => {
        const testTeams = getTeams();
        if (testTeams.length > 0) {
            setTeams(testTeams);
        }
    }, [getTeams, props.teams, props.testedPersons ]);

    useEffect(() => {
        const result = props.evaluation.list
            .map(item => {
                const findItem = EVALUATION_TYPE.find(x => x.id === item.id);
                if (findItem === undefined) { return item }
                return {
                    ...item,
                    title: findItem.title,
                    description: findItem.description,
                }
            });
        const resultIds = EVALUATION_TYPE.map(x => x.id);

        setEvaluation(result);
        setResultIds(resultIds);
        
    }, [props.evaluation]);

    useEffect(() => {
        useRefItem.current = refItem.current.offsetWidth;
    }, [refItem]);

    useEffect(() => {

        if (!!!props.selectedTest.id) {
            setSelectes({id: COMPETENCIES_TEST_ID, key: 0});
        }
        
        if (props.selectedTest.id === COMPETENCIES_TEST_ID) {
            
            if (competencyModels.length > 0 && props.evaluation.list.length > 0) {
                const competencyTests = props.evaluation.list
                    .filter(x => x.kind === 'Competency')
                    .map(x => ({ testId: x.id, title: x.title, competencyModelId: x.competencyModelId }));

                const competencyModelIds = competencyTests.map(i => i.competencyModelId)
                    .filter((item, index, self) => index === self.findIndex(x => x === item));
                
                const competencyTestsCriteria = props.testedPersons
                    .filter(x => competencyTests.map(x => x.testId).includes(x.testId))
                    .map((x, index) => {

                        const competency = competencyModels
                            .map(i => i.competencies)
                            .flat()
                            .filter(i => competencyModelIds.includes(i.competencyModelId))
                            .find(i => i.personGrowthTagId === x.personGrowthTagId);

                        return {
                            id: x.personGrowthTagId,
                            title: (competency && competency.name) || x.personGrowthTagName,
                            key: index,
                            description: competency && competency.description,
                        }
                    })
                    .filter(x => !!x.id)
                    .filter((item, index, self) => index === self.findIndex(x => x.id === item.id));

                setResultCriteria(competencyTestsCriteria);
                setCriteriaSelectedId({ id: competencyTestsCriteria.length > 0 && competencyTestsCriteria[0].id, key: 0 });
            }
        
        } else if (props.selectedTest.id === MANAGEMENT_POTENTIAL_TESTS_ID) {

            if (props.evaluation.isLoaded) {

                const potentialTestIds = props.evaluation.list
                    .filter(x => x.kind === 'Potential')
                    .map(x => x.id);

                const potentialTestsCriteria = props.testedPersons
                    .filter(x => potentialTestIds.includes(x.testId))
                    .map((x, index) => 
                        ({
                            id: x.personGrowthTagId, 
                            title: x.personGrowthTagName, 
                            key: index,
                            description: x.description,
                        }))
                    .filter(x => !!x.id)
                    .filter((item, index, self) => index === self.findIndex(x => x.id === item.id));

                setResultCriteria(potentialTestsCriteria);
                
                if (!!potentialTestsCriteria[0] && !!potentialTestsCriteria[0].id) {
                    setCriteriaSelectedId({ id: potentialTestsCriteria[0].id, key: 0 });
                }
            }
        } else {
            const currentEvaluation = evaluation.find(x => x.id === props.selectedTest.id);
            if (currentEvaluation) {
                const result = currentEvaluation.criteriaOrder.map(item => (currentEvaluation.criteria[item]));

                const param = {
                    id: result[0].id,
                    key: 0
                };

                setResultCriteria(result);
                setCriteriaSelectedId(param);
            } else {

                const param = {
                    id: null,
                    key: 0
                };

                setResultCriteria([]);
                setCriteriaSelectedId(param);
            }
        }

    }, [evaluation, props.selectedTest, props.testedPersons, props.evaluation, setSelectes, competencyModels]);

    const [person, setPerson] = useState({});
    const onSelectedPerson = (id, item) => () => {
        setPerson(item);
        handleSummaryCardOpen();
    };

    const personClick = {
        onChange: onSelectedPerson,
        onClick: onSelectedPerson,
        onImageClick: onSelectedPerson,
    };

    const tests = {
        testId: props.selectedTest.id,
        criteriaId: !!criteriaSelectedId ? criteriaSelectedId.id : {}
    };
    
    const renderTeam = (data) => {
        
        const compareByPositionDesc = ( a, b ) => {
            const a_res = a.percent.find(res => res.testId === tests.testId && res.criterionId === tests.criteriaId);
            const b_res = b.percent.find(res => res.testId === tests.testId && res.criterionId === tests.criteriaId);
            const a_value = (a_res && a_res.normalizedValue.toFixed(2));
            const b_value = (b_res && b_res.normalizedValue.toFixed(2));
            return (a_value || 0) === (b_value || 0)
                ? 0
                : (a_value || 0) > (b_value || 0) ? -1 : 1;
        };

        const compareByPositionAsc = ( a, b ) => {
            const a_res = a.percent.find(res => res.testId === tests.testId && res.criterionId === tests.criteriaId);
            const b_res = b.percent.find(res => res.testId === tests.testId && res.criterionId === tests.criteriaId);
            const a_value = (a_res && a_res.normalizedValue.toFixed(2));
            const b_value = (b_res && b_res.normalizedValue.toFixed(2));
            return (a_value || 0) === (b_value || 0)
                ? 0
                : b_value === undefined 
                    ? -1 
                    : a_value === undefined || (a_value || 0) > (b_value || 0) ? 1 : -1;
        };

        const sortReadyTeams = (readyTeams, selectedSorting) => {
            switch(selectedSorting && selectedSorting.type){
                case "desc":
                    return deepClone(readyTeams).map(x => 
                        ({...x, persons: x.persons.sort((a, b) => compareByPositionDesc(a, b))}));
                case "asc":
                    return deepClone(readyTeams).map(x => 
                        ({...x, persons: x.persons.sort((a, b) => compareByPositionAsc(a, b))}));
                default:
                    return readyTeams;
            }
        };

        const filterTeams = (readyTeams, selectedFilter) => {
            switch(selectedFilter){
                case ALL_EVALUATIONS_MENU_ID:
                    return readyTeams;
                case HAS_EVALUATIONS_MENU_ID:
                    return readyTeams.map(team =>
                        ({...team, persons: team.persons.filter(person =>
                                !!person.percent.find(res => res.testId === tests.testId &&
                                    res.criterionId === tests.criteriaId) )} ));
                case HAS_NO_EVALUATIONS_MENU_ID:
                    return readyTeams.map(team =>
                        ({...team, persons: team.persons.filter(person =>
                                !!!person.percent.find(res => res.testId === tests.testId &&
                                    res.criterionId === tests.criteriaId) )} ));
                default:
                    return readyTeams;
            }
        };

        const dataFiltered = data && filterTeams(data, selectedFilter);
        const dataSorted = dataFiltered && sortReadyTeams(dataFiltered, selectedSorting);
        return data.length > 0
            ?
                dataSorted.map(item => (
                    <DataCard
                        key={item.teamId}
                        id={item.iteamId}
                        title={item.teamName}
                        className="LKDataCard--Line"
                        titleModifire="accordion"
                        isCount
                    >{item.persons.length !== 0 
                        ?
                          <RenderPerson 
                            tests={tests} 
                            data={item.persons} 
                            basket={{ persons: [] }} 
                            {...personClick}
                            checkbox={false}
                          />
                        :
                          <NoDataBlock text='В этой команде пока никого нет' />
                    }</DataCard>
            ))
            : <NoDataBlock text='Нет ни одной команды' />
    };

    const renderEvaluationType = (obg) => {
        const obgWithTooltipDescriptions = obg.map(x =>
            ({...x, description :
                !!x.extraDescription && !!x.description
                    ?
                    <span>
                    {x.description.split(/(?:\r\n|\r|\n)/g).map(i => (<span key={i}>{i}<br/></span>))}
                        <br/><br/>
                        {x.extraDescription.split(/(?:\r\n|\r|\n)/g).map(i => (<span key={i}>{i}<br/></span>))}
                </span>
                    : !!x.description
                    ? x.description.split(/(?:\r\n|\r|\n)/g).map(i => (<span key={i}>{i}<br/></span>))
                    : x.description}
            ));
        
        return obgWithTooltipDescriptions.map((item, key) => {
            return (
                    <EvaluationCardItemBlock grey key={key}>
                        <EvaluationType
                            {...item}
                            icon="evaluateType"
                            tooltip
                        />
                    </EvaluationCardItemBlock>
            )
        })
    };

    const slideChange = (e, array) => {
        const param = {
            id: array[e],
            key: e
        };

        props.setSelectes(param);
    };
    
    const slideChangeCriteria = (e, array) => {
        const param = {
            id: array[e],
            key: e
        };

        setCriteriaSelectedId(param);
    };

    const handleSortingChange = (value) => {
        if (selectedFilter !== HAS_NO_EVALUATIONS_MENU_ID) {
            setSelectedSorting(value);
        }
    };

    const renderSortFilter = () => {
        return (
            <SorterButton
                options={sortingOptions}
                value={selectedSorting}
                onChange={handleSortingChange}
            />
        )
    };

    const handleFilterChange = (value) => {
        if (value !== ALL_EVALUATIONS_MENU_ID && selectedSorting.type !== undefined) {
            setSelectedFilter(value);
            setSelectedSorting(sortingOptions[0]);
        } else {
            setSelectedFilter(value);
        }
    };

    const renderRangeFilterMenu = () => {
        return (
            <MenuFilter
                options={hasEvaluationsMenuOptions}
                value={selectedFilter}
                onChange={handleFilterChange}
            />
        )
    };

    const [isOpenModalSummary, setIsOpenModalSummary] = useState(false);
    const closeSummaryCard = () => setIsOpenModalSummary(false);

    const handleSummaryCardOpen = () => {
        setIsOpenModalSummary(true);
    };

    return (
        <>
            <div className={classnames('DetailsCard', { 'DetailsCard--isMobileActive': isMobileActive })}>
                <MainCardMenu main menuItems={evaluateResultCardMenuItems} />
                <div className="EvaluationResultTeam">
                    <div className="EvaluationSearch__Item" ref={refItem}>
                        <div>
                            {!!resultIds && Array.isArray(resultIds) && resultIds.length > 0 &&
                                <Slider
                                    data={renderEvaluationType(EVALUATION_TYPE)}
                                    initialSlide={props.selectedTest.key}
                                    slideChange={slideChange}
                                    resultIds={resultIds}
                                />}
                            {resultCriteria && Array.isArray(resultCriteria) && resultCriteria.length > 0 &&
                                <Slider
                                    data={renderEvaluationType(resultCriteria, false)}
                                    initialSlide={criteriaSelectedId.key}
                                    slideChange={slideChangeCriteria}
                                    resultIds={resultCriteria.map(x => x.id)}
                                />}
                        </div>
                        <EvaluteScaleInfo test={evaluation.find(x => x.id === props.selectedTest.id)} testId={props.selectedTest.id}/>
                        <div className='LKEvaluationResultTeamFilters'>
                            { renderRangeFilterMenu() }
                            { renderSortFilter() }                            
                        </div>
                        {props.teams.isLoaded && props.evaluation.isLoaded && props.evaluation.criteriasIsLoaded
                            ? renderTeam(teams) 
                            : <Loader />
                        }
                    </div>
                </div>
            </div>
            {person && <ModalSummaryCard
                isOpen={isOpenModalSummary}
                onClose={closeSummaryCard}
                person={person}
                profileRoute={lkDetailsEvaluateTeamProfile}
            />}
        </>
    );
};

EvaluationResultTeam.propTypes = {
    activeCard: PropTypes.string.isRequired,
    setActiveCard: PropTypes.func.isRequired,
};

const mapStateToProps = state => (
    {
        personId: state.auth.user.personId,
        teams: state.teams,
        evaluation: state.evaluation,
        selectedTest: state.evaluation.selected,
        testedPersons: state.evaluation.testedPersons,
        preselectionBasket: state.baskets.preselectionEvaluate.basket,
        basket: state.baskets.evaluate.basket,
    }
);
const actions = { fetchTeams, fetchEvaluation, setEvaluationTestsCriterion, changeBasket, setSelectes };

export default connect(mapStateToProps, actions)(React.memo(EvaluationResultTeam));