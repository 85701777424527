import { takeLatest, put, call, all } from 'redux-saga/effects';
import { getTestProvidersActive } from 'api';
import RequestError from 'RequestError';
import { appName, API_STATUS_OK } from '../constants';
import { showErrorAlert } from './Alert';
import { withPageLoader } from './PageLoader';

const moduleName = 'testProviders';
const FETCH_REQUEST = `${appName}/${moduleName}/FETCH_REQUEST`;
const FETCH_START = `${appName}/${moduleName}/FETCH_START`;
const FETCH_SUCCESS = `${appName}/${moduleName}/FETCH_SUCCESS`;
const FETCH_FAILED = `${appName}/${moduleName}/FETCH_FAILED`;

const initialState = {
    loading: false,
    loadComplete: false,
    data: {
        payload: [],
    },
    error: '',
};

export default function reducer(state = initialState, action) {
    const { type, payload, error } = action;
    switch (type) {
        case FETCH_START:
            return {
                ...state,
                loading: true,
            };
        case FETCH_FAILED:
            return {
                ...state,
                loading: false,
                loadComplete: true,
                error: error.message,
            };
        case FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                loadComplete: true,
                data: { payload },
            };
        default:
            return state;
    }
}

export const fetchTestProviders = () => {
    return {
        type: FETCH_REQUEST,
    }
};

const fetchStart = () => {
    return {
        type: FETCH_START,
    }
};

export const fetchSuccess = (data) => {
    return {
        type: FETCH_SUCCESS,
        payload: data,
    }
};

const fetchFailed = (error) => {
    return {
        type: FETCH_FAILED,
        error,
    }
};

const fetchTestProvidersSaga = function* () {
    yield put(fetchStart());
    try {
        const response = yield call(withPageLoader, () => getTestProvidersActive());
        if (response.status === API_STATUS_OK) {
            yield put(fetchSuccess(response.data));
        }
    } catch (error) {
        const reqError = new RequestError(error, 'При загрузке поставщиков тестирования произошла ошибка');
        yield all([
            put(fetchFailed(reqError)),
            put(showErrorAlert(reqError.message))
        ]);
    }
};

export const saga = function* () {
    yield all([
        takeLatest(FETCH_REQUEST, fetchTestProvidersSaga),
    ]);
};
