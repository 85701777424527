import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import GoBackButton from 'components/Lk/Uikit/Buttons/GoBackButton';
import { ACTIVE_DETAIL_CARD } from 'components/Lk/Basic/constants';
import { lkDetailsEvaluateTeam } from 'routes';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';
import { evaluateCardMenuItems } from 'components/Lk/Basic/MainCard/constants';

import './EvaluationPersonResults.scss';

const EvaluationPersonResults = (props) => {
    const { activeCard, setActiveCard } = props;
    const backUrl = lkDetailsEvaluateTeam.url;

    setActiveCard(ACTIVE_DETAIL_CARD);

    const isMobileActive = activeCard === ACTIVE_DETAIL_CARD;
    return (
        <div className={classnames('DetailsCard', {'DetailsCard--isMobileActive': isMobileActive })}>
            <MainCardMenu main menuItems={evaluateCardMenuItems} />

            {isMobileActive && <GoBackButton backUrl={backUrl} color='black' />}

            <div className="EvaluationPersonResults">
                <div className="EvaluationPersonResults__Content">
                    <h3 className="LKLayout__Title">Оценка</h3>
                </div>
            </div>
        </div>
    );
};

EvaluationPersonResults.propTypes = {
    activeCard: PropTypes.string.isRequired,
    setActiveCard: PropTypes.func.isRequired,
};

export default EvaluationPersonResults;