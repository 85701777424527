import { EVALUATION_TYPE_CAREER } from "constants.js";

export const ACTIVE_MAIN_CARD = 'main';
export const ACTIVE_LIST_CARD = 'list';
export const ACTIVE_DETAIL_CARD = 'detail';

export const MAIN_PAGE_URL = '/services';

export const PERSONGROWTHTAGS = [
    {
        id: '39633b1c-7633-453e-9817-40b7e8fadb24',
        position: 1,
        title: "Масштабность мышления",
        description: "Способность видеть и анализировать проблемы не только с позиции занимаемой должности, но и в более широком организационном и социальном контексте, умение сформировать целостное понимание ситуации, принимать продуманные комплексные решения, основанные на анализе объективных данных. Поиск креативных способов решения, анализ их плюсов, минусов и рисков. Готовность изменить решение при наличии новых аргументов."
    },
    {
        id: '2a6c000c-b0ee-4e67-b0e5-0af3b0e4379d',
        position: 2,
        title: "Нацеленность на результат",
        description: "Способность к преодолению сопротивления внешней среды при выполнении профессиональной и управленческой деятельности, умение ставить цель и добиваться результата, доводить задачи до конца, повторно возвращается к реализации процесса в случае его неудачного внедрения. Проявление самомотивации, амбициозности и инициативы, принятие на себя ответственности за свои решения, поступки и их последствия."
    },
    {
        id: 'a35b450e-2e70-404f-aecf-28573de12056',
        position: 3,
        title: "Готовность к саморазвитию",
        description: "Ориентация на непрерывное профессиональное и личностное совершенствование, готовность и способность эффективно действовать в условиях изменений, быстро воспринимать и осваивать новую информацию, принимать решения в ограниченное время с ограниченным доступом к необходимой информации. Готовность к взаимодействию с новыми людьми, а также к применению новых приемов и методов управления, скромность и зрелость."
    },
    {
        id: '405fce46-c90f-42c1-aa4e-bd534d4ad1ed',
        position: 4,
        title: "Умение работать в команде",
        description: "Ориентация на командное взаимодействие, готовность к внутреннему принятию поставленных организационных целей и задач, готовность к организации совместной деятельности по решению управленческих вопросов. Навыки бесконфликтного общения и взаимодействия с людьми с различными личностно – профессиональными особенностями, готовность к диалогу и сотрудничеству, обсуждению и принятию компромиссных решений для достижения общего результата. Налаживание горизонтального взаимодействия, выстраивание партнерских отношений с коллегами, готовность быть и лидером, и членом команды."
    },
    {
        id: 'c1a5eeb3-0026-4f0a-bbec-04ffd1b095c9',
        position: 5,
        title: "Социальное взаимодействие",
        description: "Готовность к конструктивному межличностному и социальному взаимодействию, владение умениями и навыками эффективных социальных коммуникаций. Проявление активности в социальных контактах, нацеленность на совместную деятельность и коллективную работу, способность учитывать мнения подчиненных и руководителей по тому или иному вопросу."
    },
    {
        id: '69664c9b-e64b-4923-bd41-e49f3a6ebc79',
        position: 6,
        title: "Организация работы",
        description: "Способность к планированию, организации, контролю и координации деятельности больших организационных структур, готовность к принятию самостоятельных управленческих решений, реализации менеджерских и административных функций. Умение заметить отклонения в поставленных задачах и внести коррективы. Точность в оценке необходимых ресурсов, их оптимальное планирование и использование."
    },
    {
        id: '394e0eb2-368b-4301-8efa-b44f0399cbdc',
        position: 7,
        title: "Компетентности самоуправления",
        description: "Проявление стрессоустойчивости, выдержки, самообладания и уравновешенности, объективная и рациональная оценка личных результатов, а также использование собственных личностно – профессиональных ресурсов. Эффективная самоорганизация."
    },
    {
        id: '6afba4e8-2b01-4a36-b0c5-d0c40cb27e97',
        position: 8,
        title: "Стратегическое лидерство",
        description: "Способность формулировать стратегические цели организации, видеть стратегические перспективы деятельности и развития организационно - управленческих структур. Развитие и поддержка энергетического и психологического потенциала сотрудников, заряжание их своими идеями."
    },
    {
        id: '3293e6bc-4eb4-4717-b198-50e7291a2859',
        position: 9,
        title: "Экспертная компетентность",
        description: "Способность к глубокому разностороннему и системному анализу профессиональных и управленческих проблем на основе имеющегося профессионального опыта и знаний."
    },
    {
        id: 'be0c3c37-558b-4c0e-9716-697e53eed9c8',
        position: 10,
        title: "Социальная ответственность",
        description: "Повышенное внимание к внешнему миру, людям, социальным и политическим изменениям, наличие ведущих социально направленных ценностей. Принятие социальной ответственности, ориентация на интересы граждан и общества, на решение социально-значимых задач. Активность в конструктивном решении существующих проблем. Проактивное определение потребности клиентов/граждан/общества, построение долгосрочных доверительных отношений с клиентами/гражданами/обществом, предвосхищение их ожиданий."
    },
    {
        id: '7672157f-ce4b-4328-97e6-68d01ef0b77f',
        position: 11,
        title: "Коммуникация и влияние",
        description: "Умение выстраивать контакт, четкость и аргументированность в высказывании своих мыслей, умение слышать и учитывать позиции других, влиять и убеждать."
    },
    {
        id: '2cac4b1a-012e-49a1-9625-22ae0ce09bb0',
        position: 12,
        title: "Инновационность",
        description: "Способность предлагать новые, нестандартные идеи для совершенствования имеющихся и выработки радикально новых продуктов, услуг и процессов, готовность к экспериментам, знание современных технологий. Конструктивное отношение к изменениям, умение их внедрять и управлять ими, использование аналитики данных при принятии решений и планировании изменений."
    },
    {
        id: '8797930f-d186-43d8-844b-99f2bfe204ec',
        position: 13,
        title: "Лидерство",
        description: "Умение найти союзников, мотивировать людей и координировать их работу по достижению цели. Готовность принимать на себя ответственность за результаты работы других. Наличие харизмы."
    }
];

export const COMPETENCIES_TEST_ID = '00000000-0000-0000-0000-000000000000';
export const MANAGEMENT_POTENTIAL_TESTS_ID = '00000000-0000-0000-0000-000000000001';

export const BEHAVIOR_EVAL_TYPE_ID = "fc80e8d8-91fe-79b9-36cf-2dfddae3ba00";
export const CAREER_DESTRUCTORS_EVAL_TYPE_ID  = "5b93a28f-b977-ebcd-ab75-91f6f2b7f60c";

export const EVALUATION_TYPE = [
    {
        id: COMPETENCIES_TEST_ID,
        forEvaluation: true,
        title: "Компетенции",
        description: "Поведенческие проявления резервиста в рамках модели компетенций региона",
        legend:'compitencies'
    },
    {
        id: BEHAVIOR_EVAL_TYPE_ID,
        forEvaluation: true,
        title: "Поведенческий тип",
        description: "Профиль резервиста по 5 качествам: открытость к новому, следование правилам, экстраверсия, командность, эмоциональная стабильность"
    },
    {
        id: EVALUATION_TYPE_CAREER,
        forEvaluation: true,
        title: "Карьерная мотивация",
        description: "Аспекты работы, которые сильнее всего влияют на мотивацию резервиста",
        legend:false
    },
    {
        id: CAREER_DESTRUCTORS_EVAL_TYPE_ID,
        forEvaluation: true,
        title: "Карьерные деструкторы",
        description: "Потенциальные факторы риска для продуктивной работы и построения карьеры"
    },
    {
        id: "f258fc8a-cd06-269a-a043-131e84314278",
        forEvaluation: true,
        title: "Способности: вербальная секция",
        description: "Умение анализировать разные типы информации в условиях ограниченного времени. Вербальная секция - анализ словесной информации"
    },
    {
        id: "b18a4270-b6a1-96f6-6c21-cfb303d5f13d",
        forEvaluation: true,
        title: "Способности: логическая секция",
        description: "Умение анализировать разные типы информации в условиях ограниченного времени. Логическая секция - анализ абстрактной информации и выявление закономерностей"
    },
    {
        id: "d40247cc-823b-c4e7-c067-b90b05df2b21",
        forEvaluation: true,
        title: "Способности: числовая секция",
        description: "Умение анализировать разные типы информации в условиях ограниченного времени. Числовая секция - интерпретация таблиц и графиков, выводы на основании числовой информации"
    },
    {
        id: MANAGEMENT_POTENTIAL_TESTS_ID,
        forEvaluation: true,
        title: "Управленческий потенциал",
        description: "Качества, определяющие эффективность в новой деятельности — в ее освоении и использовании необходимых навыков и знаний",
        legend:'managerials'
    },
    {
        id: "00000000-0000-0000-0000-000000000002",
        forEvaluation: false,
        title: "Ассессмент",
        description: "Комплексная оценка навыков, основанная на наблюдении за поведением резервистов при выполнении ими специальных заданий",
        legend:false
    },
];