import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import './Instructions.scss';
import { getCategoriesWithContents, searchTopContentTitles } from 'api';
import { withRouter } from 'react-router-dom';
import { instructionsEdit } from 'routes.js';
import Loader from 'components/common/Loader';
import Button from 'components/uikit/Button';
import { Select } from 'components/uikit/Select';
import { fetchDictionaries } from 'ducks/Dictionary';
import {
    GROUPS_DICTIONARY_NAME,
    ROLES_DICTIONARY_NAME,
    GROUP_ROOT_ID,
    ROLE_INSTRUCTION_EDITOR_ID,
    helpEmail,
} from 'constants.js';
import { canEditInstruction } from 'rightsController';
import InstructionList from './InstructionList';
import classnames from 'classnames';
import SearchRequestInput from 'components/Lk/Common/SearchRequestInput';
import EmailBlock from 'components/Lk/Common/EmailBlock';
import { instructionsView } from 'routes';
import queryString from 'query-string';
import { isDevice } from 'utils';

const Instructions = (props) => {
    const { fetchDictionaries } = props;
    const [categories, setCategories] = useState([]);
    const [roles, setRoles] = useState([]);
    const [groups, setGroups] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [filter, changeFilter] = useState({
        role: ROLE_INSTRUCTION_EDITOR_ID,
        group: GROUP_ROOT_ID,
    });

    const [mounted, setMounted] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');

    const isModerateLook = useMemo(
        () => canEditInstruction(props.user) && props.location.state?.lookAs,
        [props.location.state, props.user],
    );

    const loadCategories = useCallback(async () => {
        const { role, group } = filter;
        const criteria = {
            roleIds: role ? [role] : [],
            groupIds: props.user.groupId === GROUP_ROOT_ID && group ? [group] : [],
        };
        const result = await getCategoriesWithContents(isModerateLook ? criteria : {});
        setCategories(result.data);
    }, [filter, isModerateLook, props.user.groupId]);

    useEffect(() => {
        const load = async () => {
            setLoading(true);
            await loadCategories();
            setLoading(false);
        };
        load();
        return () => setMounted(false);
    }, [loadCategories]);

    useEffect(() => {
        fetchDictionaries([GROUPS_DICTIONARY_NAME, ROLES_DICTIONARY_NAME]);
    }, [fetchDictionaries]);

    useEffect(() => {
        setRoles(props.roles?.map((x) => ({ ...x, label: x.name, value: x.id })));
    }, [props.roles]);

    useEffect(() => {
        setGroups(
            props.groups
                ?.filter((x) => !x.isScope)
                .map((x) => ({ ...x, label: x.name, value: x.id })),
        );
    }, [props.groups]);

    const onChange = (name) => ({ value }) => {
        changeFilter((x) => ({ ...x, [name]: value }));
    };

    function renderInstruction(item) {
        return (
            <div
                key={item.id}
                className={classnames('CategoryBlock', {
                    'CategoryBlock--wrapped': isDevice.SM(),
                })}
            >
                <InstructionList items={item.contents} title={item.title} />
            </div>
        );
    }

    const backToEdit = () => {
        props.history.push(instructionsEdit.buildUrl());
    };

    const getTitle = () => {
        const {
            user,
            location: { state },
        } = props;

        if (!canEditInstruction(user) || !state?.lookAs) {
            return 'Помощь';
        }

        return (
            <div className="InstructionHeader">
                <div className="InstructionHeader__Selects">
                    <div className="InstructionHeader__Field">
                        <span className="InstructionHeader__Label">Выберите группу</span>
                        <Select
                            onChange={onChange('group')}
                            options={groups}
                            value={groups?.find((x) => x.id === filter.group)}
                            placeholder="Выберите группу"
                        />
                    </div>
                    <div className="InstructionHeader__Field InstructionHeader__Field--w500">
                        <span className="InstructionHeader__Label">Выберите роль</span>
                        <Select
                            onChange={onChange('role')}
                            options={roles}
                            value={roles?.find((x) => x.id === filter.role)}
                            placeholder="Выберите роль"
                        />
                    </div>
                    <div className="InstructionHeader__Field">
                        <span className="InstructionHeader__Label">&nbsp;</span>
                        <Button className="InstructionHeader__Exit" onClick={backToEdit} size="md">
                            Выйти из режима просмотра
                        </Button>
                    </div>
                </div>
            </div>
        );
    };

    const onSearch = (searchQuery) => {
        const urlParams = {
            pathname: instructionsView.buildUrl({ id: null }),
            search: queryString.stringify({
                q: searchQuery,
            }),
        };
        searchQuery && props.push(urlParams);
    };

    const searchSubmit = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onSearch(searchTerm);
        }
    };

    const submitSearchContent = (_, item) => {
        if (item?.title) {
            onSearch(item?.title);
        } else {
            onSearch(searchTerm);
        }
    };

    return (
        <>
            <div className="InstrctionWrapper">
                <div className="InstructionCategoriesWrapper">
                    <div className="InstructionCategories">
                        {!props.location.state?.lookAs && (
                            <div className="InstrctionWrapper__Search">
                                <SearchRequestInput
                                    className={classnames(
                                        'LKSearchCardInput__Search LKSearchCardInput__Search--no-preIcon LKSearchCardInput__Search--post-icon',
                                        {
                                            'LKSearchCardInput__Search--no-borders': isDevice.SM(),
                                        },
                                    )}
                                    value={searchTerm}
                                    onChange={
                                        mounted
                                            ? (e) => setSearchTerm(e.currentTarget.value)
                                            : undefined
                                    }
                                    onKeyPress={searchSubmit}
                                    onSelect={submitSearchContent}
                                    postIcon="search"
                                    postIconClick={submitSearchContent}
                                    postIconColor="#051945"
                                    searchCallback={searchTopContentTitles}
                                    placeholder="Напишите вопрос или проблему"
                                    withPayload={false}
                                />
                            </div>
                        )}
                        <div className="InstrctionWrapper__Header">{getTitle()}</div>
                        {categories.length !== 0
                            ? categories.map(renderInstruction)
                            : !isLoading && (
                                  <div className="InstructionCategories__NoData">Нет данных </div>
                              )}
                    </div>
                </div>
                <div className="InstrctionWrapper__Email">
                    <EmailBlock
                        preText="Если у Вас остались вопросы, напишите на"
                        email={helpEmail}
                    />
                </div>
            </div>
            {<Loader absolute show={isLoading} />}
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    roles: state.dictionary.roles.dictionary,
    groups: state.dictionary.groups.dictionary,
});

const actions = { fetchDictionaries, push };

export default withRouter(connect(mapStateToProps, actions)(Instructions));
