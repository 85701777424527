import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Redirect } from 'react-router-dom';
import { Switch } from 'react-router';
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import { ALLOWED_LK_ROLES } from 'rightsController';
import { lkMainServices, lkMainWidgets, lkMainTeams, lkMainContent } from 'routes';
import Services from './Services';
import Favorites from './Favorites';
import TeamsPage from './TeamsPage';
import ContentPage from './ContentPage';
import { ACTIVE_MAIN_CARD } from 'components/Lk/Basic/constants';
import './MainCard.scss'

const MainCard = props => {
    const { activeCard, setActiveCard, personId } = props;
    const isMobileActive = activeCard === ACTIVE_MAIN_CARD

    const redirectToServices = () => {
        return <Redirect to='/services' />
    };

    return (
        <div className={classnames('LKMainCard', {'LKMainCard--isMobileActive': isMobileActive })}>
            <Switch>
                <ProtectedRoute exact path={'/'} render={() => redirectToServices()} allowedRoles={ALLOWED_LK_ROLES} />
                <ProtectedRoute 
                    path={lkMainServices.url} 
                    render={(props) => <Services 
                        {...props} 
                        activeCard={activeCard} 
                        setActiveCard={setActiveCard} 
                        />} 
                    allowedRoles={ALLOWED_LK_ROLES}  
                /> 
                <ProtectedRoute 
                    path={lkMainWidgets.url} 
                    render={(props) => <Favorites 
                        {...props} 
                        activeCard={activeCard} 
                        setActiveCard={setActiveCard} 
                        />} 
                    allowedRoles={ALLOWED_LK_ROLES}  
                />
                <ProtectedRoute 
                    path={lkMainTeams.url} 
                    render={(props) => <TeamsPage 
                        {...props} 
                        personId={personId}
                        activeCard={activeCard} 
                        setActiveCard={setActiveCard} 
                        />} 
                    allowedRoles={ALLOWED_LK_ROLES}  
                />
                <ProtectedRoute 
                    path={lkMainContent.url} 
                    render={(props) => <ContentPage 
                        {...props} 
                        personId={personId}
                        activeCard={activeCard} 
                        setActiveCard={setActiveCard} 
                        />} 
                    allowedRoles={ALLOWED_LK_ROLES}  
                />                
            </Switch>
        </div>
    );
};

MainCard.propTypes = {
    activeCard: PropTypes.string.isRequired,
    setActiveCard: PropTypes.func.isRequired,
};

const state = state => {
    return {
        personId: state.auth.user.personId,
    }
};

export default connect(state)(MainCard);