import React from 'react';

export const statuses = {
    NEW: { value: 'New', label: 'В процессе отправления' },
    ERROR: { value: 'Error', label: 'Ошибка отправления' },
    SENT: { value: 'Sent', label: 'Успешно отправлено' },
};

export const types = {
    SMS: { value: 'SMS', label: 'SMS' },
    EMAIL: { value: 'email', label: 'Email' },
};

export const eventTypes = {
    Created: 'Создано',
    SendingStarted: 'Отправляется',
    Sent: 'Отправлено',
    Opened: 'Прочитано',
    Error: 'Ошибка',
};

export const generateOptions = (obj) => {
    let options = [];
    for (const [key, value] of Object.entries(obj)) {
        options.push({ ...value, key });
    }
    return options;
};

export const icons = (fill) => ({
    mail: (
        <svg
            width="16"
            height="13"
            viewBox="0 0 16 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.3316 12.3221H13.9033C15.1809 12.3221 15.9162 11.5868 15.9162 10.1529V2.20258C15.9162 0.768751 15.1809 0.0334473 13.7287 0.0334473H2.15698C0.879404 0.0334473 0.144104 0.759565 0.144104 2.20258V10.1529C0.144104 11.596 0.879404 12.3221 2.3316 12.3221ZM7.17535 6.60514L1.63308 1.15478C1.81689 1.05368 2.03748 1.00772 2.30402 1.00772H13.7562C14.032 1.00772 14.2617 1.06287 14.4456 1.17316L8.93087 6.60514C8.61838 6.91764 8.33345 7.04632 8.04851 7.04632C7.77279 7.04632 7.47867 6.91764 7.17535 6.60514ZM1.11837 10.1438V2.20258C1.11837 2.11985 1.11837 2.04632 1.12755 1.98198L5.43822 6.20073L1.13676 10.4195C1.12755 10.3368 1.11837 10.2449 1.11837 10.1438ZM14.9419 2.20258V10.1529C14.9419 10.2357 14.9327 10.3184 14.9235 10.3919L10.6588 6.20073L14.9327 2.01875C14.9419 2.0739 14.9419 2.13824 14.9419 2.20258ZM2.30402 11.3478C2.05587 11.3478 1.84447 11.3018 1.67903 11.2191L6.10918 6.86249L6.6055 7.34963C7.09264 7.83676 7.57058 8.02978 8.04851 8.02978C8.52646 8.02978 9.00439 7.83676 9.49153 7.34963L9.98785 6.86249L14.4088 11.2007C14.2342 11.3018 14.0136 11.3478 13.7562 11.3478H2.30402Z"
                fill={fill}
            />
        </svg>
    ),
    mailOpen: (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 512 512">
            <path
                fill={fill}
                d="M494.586 164.516c-4.697-3.883-111.723-89.95-135.251-108.657C337.231 38.191 299.437 0 256 0c-43.205 0-80.636 37.717-103.335 55.859-24.463 19.45-131.07 105.195-135.15 108.549A48.004 48.004 0 0 0 0 201.485V464c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V201.509a48 48 0 0 0-17.414-36.993zM464 458a6 6 0 0 1-6 6H54a6 6 0 0 1-6-6V204.347c0-1.813.816-3.526 2.226-4.665 15.87-12.814 108.793-87.554 132.364-106.293C200.755 78.88 232.398 48 256 48c23.693 0 55.857 31.369 73.41 45.389 23.573 18.741 116.503 93.493 132.366 106.316a5.99 5.99 0 0 1 2.224 4.663V458zm-31.991-187.704c4.249 5.159 3.465 12.795-1.745 16.981-28.975 23.283-59.274 47.597-70.929 56.863C336.636 362.283 299.205 400 256 400c-43.452 0-81.287-38.237-103.335-55.86-11.279-8.967-41.744-33.413-70.927-56.865-5.21-4.187-5.993-11.822-1.745-16.981l15.258-18.528c4.178-5.073 11.657-5.843 16.779-1.726 28.618 23.001 58.566 47.035 70.56 56.571C200.143 320.631 232.307 352 256 352c23.602 0 55.246-30.88 73.41-45.389 11.994-9.535 41.944-33.57 70.563-56.568 5.122-4.116 12.601-3.346 16.778 1.727l15.258 18.526z"
            />
        </svg>
    ),

    sms: (
        <svg
            viewBox="0 0 512 512"
            width="16"
            height="16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M144 208c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm112 0c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm112 0c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zM256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26S14.4 480 24 480c61.5 0 110-25.7 139.1-46.3C192 442.8 223.2 448 256 448c141.4 0 256-93.1 256-208S397.4 32 256 32zm0 368c-26.7 0-53.1-4.1-78.4-12.1l-22.7-7.2-19.5 13.8c-14.3 10.1-33.9 21.4-57.5 29 7.3-12.1 14.4-25.7 19.9-40.2l10.6-28.1-20.6-21.8C69.7 314.1 48 282.2 48 240c0-88.2 93.3-160 208-160s208 71.8 208 160-93.3 160-208 160z"
                fill={fill}
            ></path>
        </svg>
    ),

    ok: (
        <svg
            viewBox="0 0 512 512"
            width="16"
            height="16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill={fill}
                d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
            ></path>
        </svg>
    ),
    error: (
        <svg
            viewBox="0 0 512 512"
            width="16"
            height="16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill={fill}
                d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
            ></path>
        </svg>
    ),
    process: (
        <svg
            viewBox="0 0 512 512"
            width="16"
            height="16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill={fill}
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"
            ></path>
        </svg>
    ),
});
