import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import { setMergeData, personalInfoBlockCatalogs, personnelInfoBlockCatalogs } from 'ducks/Person';
import {
    FEDERAL_DISTRICTS,
    REGIONS,
    LOCALITIES,
    DATA_ORIGINS,
    EXPERIENCE_LEVELS,
} from 'ducks/Catalog';
import './PersonMerge.scss';
import { OptionButton } from 'components/uikit/OptionButton/OptionButton';
import moment from 'libs/moment';
import { mergePerson } from 'api';
import { EMPLOYEE_LEVEL_DICT } from 'constants.js';
import { sex, familyStatus, geoLevel } from 'enums';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';
import { serviceResultCode } from 'serviceErrors';

const blockFields = {
    personalInfo: [
        'lastName',
        'firstName',
        'middleName',
        'sex',
        'birthDate',
        'birthPlace',
        'email',
        'phone',
        'documentNumber',
        'familyStatus',
        'extraInfo',
    ],
    personnelInfo: [
        'dataOriginId',
        'recommenderName',
        'curatorName',
        'reserveLevel',
        'inclusionDate',
        'inclusionReason',
        'managerLevel',
        'experienceLevelId',
        'socialAchivements',
        'socialActivity',
        'governmentWorkPositions',
        'geoLevel',
        'memberOfSocialOrganizations',
        'extraInfo',
    ],
};

const fieldTitles = {
    lastName: 'Фамилия',
    firstName: 'Имя',
    middleName: 'Отчество',
    sex: 'Пол',
    familyStatus: 'Семейное положение',
    birthDate: 'Дата рождения',
    birthPlace: 'Место рождения',
    email: 'Email',
    phone: 'Телефон',
    federalDistrictId: 'Федеральный округ',
    regionId: 'Регион',
    locality: 'Город',
    documentNumber: 'Номер документа',
    extraInfo: 'Дополнительно',

    socialActivity: 'Социальная активность',
    memberOfSocialOrganizations: 'Членство в общественных организациях',
    socialAchivements: 'Деятельность в качестве руководителя',
    dataOriginId: 'Источник данных',
    experienceLevelId: 'Уровень подготовленности участника',
    curatorName: 'Куратор',
    recommenderName: 'Рекомендатель',
    geoLevel: 'Географический уровень',
    managerLevel: 'Уровень менеджмента',
    inclusionDate: 'Дата включения в резерв',
    inclusionReason: 'Основание включения',
    reserveLevel: 'Уровень резерва',
    governmentWorkPositions: 'Работа на выборных должностях',
};

const restBlockTitles = [
    { block: 'educationInfo', text: 'Образование' },
    { block: 'workInfo', text: 'Опыт работы' },
    { block: 'languagesInfo', text: 'Иностранные языки' },
    { block: 'familyInfo', text: 'Семейное положение' },
    { block: 'socialNetworksInfo', text: 'Социальные сети' },
    { block: 'filesInfo', text: 'Файлы' },
    { block: 'attributesInfo', text: 'Дополнительные поля' },
    { block: 'systemInfoInfo', text: 'Системная информация' },
];

const PersonMerge = (props) => {
    const { person, mergeData, catalogs } = props;
    const [conflicts, setConflicts] = useState([]);
    const [localityConflict, setLocalityConflict] = useState({});
    const [resolvedPerson, setResolvedPerson] = useState({ ...mergeData, snils: person.snils, isRussianCitizen: true });
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        let conflictsArray = [];

        blockFields.personalInfo.forEach((field) => {
            if (person?.[field] !== mergeData?.[field]) {
                conflictsArray.push({
                    block: 'personalInfo',
                    field,
                    values: [mergeData?.[field], person?.[field]],
                });
            }
        });

        if (person.regionId !== mergeData.regionId || person.locality !== mergeData.locality) {
            setLocalityConflict({
                selectedId: 0,
                values: [
                    {
                        federalDistrictId: mergeData.federalDistrictId,
                        regionId: mergeData.regionId,
                        locality: mergeData.locality,
                        id: 0,
                    },
                    {
                        federalDistrictId: person.federalDistrictId,
                        regionId: person.regionId,
                        locality: person.locality,
                        id: 1,
                    },
                ],
            });
        }

        blockFields.personnelInfo.forEach((field) => {
            if (person.personnelInfo?.[field] !== mergeData.personnelInfo?.[field]) {
                conflictsArray.push({
                    block: 'personnelInfo',
                    field,
                    values: [mergeData.personnelInfo?.[field], person.personnelInfo?.[field]],
                });
            }
        });

        setConflicts(conflictsArray);
    }, [mergeData, person]);

    const getLocalityDisplayValue = (value) => {
        const federalDistrict =
            catalogs[FEDERAL_DISTRICTS]?.data.find((x) => x.id === value.federalDistrictId)?.name ||
            '';
        const region = catalogs[REGIONS]?.data.find((x) => x.id === value.regionId)?.name || '';
        const locality =
            catalogs[LOCALITIES]?.data.find((x) => x.id === value.locality)?.name || '';

        const final =
            federalDistrict + (region ? `, ${region}` : '') + (locality ? `, ${locality}` : '');

        return final || 'н/д';
    };

    const getDisplayValue = (field, value) => {
        if (!value) {
            return 'н/д';
        }

        let displayValue = '';
        switch (field) {
            case 'birthDate':
            case 'inclusionDate':
                displayValue = moment(value).format('DD.MM.YYYY');
                break;
            case 'dataOriginId':
                displayValue = catalogs[DATA_ORIGINS]?.data?.find((x) => x.id === value).name;
                break;
            case 'experienceLevelId':
                displayValue = catalogs[EXPERIENCE_LEVELS]?.data?.find((x) => x.id === value).name;
                break;
            case 'managerLevel':
                displayValue = EMPLOYEE_LEVEL_DICT.find((x) => x.value === value).label;
                break;
            case 'sex':
                displayValue = sex[value];
                break;
            case 'familyStatus':
                displayValue = familyStatus[value];
                break;
            case 'geoLevel':
                displayValue = geoLevel[value];
                break;

            default:
                displayValue = value;
        }

        return displayValue;
    };

    const resolveLocality = (value) => {
        const { federalDistrictId, regionId, locality, id } = value;
        setResolvedPerson((prev) => {
            return { ...prev, federalDistrictId, regionId, locality };
        });
        setLocalityConflict((prev) => {
            return { ...prev, selectedId: id };
        });
    };

    const resolveField = (block = 'personalInfo', field, value) => {
        setResolvedPerson((prev) => {
            if (block === 'personalInfo') {
                return { ...prev, [field]: value };
            }

            return { ...prev, [block]: { ...prev[block], [field]: value } };
        });
    };

    const createPayload = () => {
        return { data: { ...resolvedPerson }, deleteId: person.id };
    };

    const updatePerson = async () => {
        setBusy(true);
        try {
            props.showPageLoader();
            await mergePerson(createPayload());
            showSuccessAlert('Анкеты объединены успешно');
            props.push(`/Person/${props.mergeData.id}`);
        } catch (error) {
            if (error.response?.data?.code === serviceResultCode.PersonPhoneIsInvalid) {
                showErrorAlert('Выбран некорректный номер телефона');
            } else {
                showErrorAlert('Ошибка объединения анкет');
            }
        } finally {
            setBusy(false);
            props.hidePageLoader()
        }
    };

    const handleSubmit = () => {
        updatePerson();
    };

    const handleCancel = () => {
        props.push(`/Person/${props.mergeData.id}`);
    };

    return (
        <div className="PersonMerge__Container">
            <h1 className="PersonMerge__Title">
                Выберите корректный вариант для заполнения поля для объединения
            </h1>
            <p>Прочие поля будут объединены автоматически</p>

            <div className="PersonMerge__BlockTitleBox">
                <h1 className="PersonMerge__BlockTitle">Личные данные</h1>
            </div>
            <div className="PersonMerge__Row">
                <div className="PersonMerge__OptionHeader">Редактируемая анкета</div>
                <div className="PersonMerge__OptionHeader">Найденная по совпадению СНИЛС</div>
            </div>
            {conflicts.map((x, index) =>
                x.block === 'personalInfo' ? (
                    <div className="PersonMerge__Row" key={index}>
                        {x.values.map((value, index) => (
                            <OptionButton
                                key={index}
                                label={fieldTitles[x.field]}
                                value={getDisplayValue(x.field, value)}
                                onClick={() => resolveField(x.block, x.field, value)}
                                selected={value === resolvedPerson[x.field]}
                            />
                        ))}
                    </div>
                ) : null,
            )}

            {localityConflict?.values && (
                <div className="PersonMerge__Row">
                    {localityConflict.values.map((value, index) => (
                        <OptionButton
                            key={index}
                            label={'Проживание (федеральный округ, регион, город)'}
                            value={getLocalityDisplayValue(value)}
                            onClick={() => resolveLocality(value)}
                            selected={value.id === localityConflict.selectedId}
                        />
                    ))}
                </div>
            )}

            <div className="PersonMerge__BlockTitleBox">
                <h1 className="PersonMerge__BlockTitle">Дополнительная информация</h1>
            </div>
            {conflicts.map((x, index) =>
                x.block === 'personnelInfo' ? (
                    <div className="PersonMerge__Row" key={index}>
                        {x.values.map((value, index) => (
                            <OptionButton
                                key={index}
                                label={fieldTitles[x.field]}
                                value={getDisplayValue(x.field, value)}
                                onClick={() => resolveField(x.block, x.field, value)}
                                selected={value === resolvedPerson.personnelInfo?.[x.field]}
                            />
                        ))}
                    </div>
                ) : null,
            )}

            {restBlockTitles.map((x, index) => (
                <div key={index}>
                    <div className="PersonMerge__BlockTitleBox">
                        <h1 className="PersonMerge__BlockTitle">{x.text}</h1>
                    </div>
                    <div className="PersonMerge__Info">
                        Информацию необходимо отредактировать вручную после слияния анкет
                    </div>
                </div>
            ))}
            <div className="PersonMerge__Row">
                <button
                    className="PersonMerge__Button PersonMerge__Button__Cancel"
                    onClick={handleCancel}
                    disabled={busy}
                >
                    Отменить
                </button>
                <button
                    className="PersonMerge__Button PersonMerge__Button__Submit"
                    onClick={handleSubmit}
                    disabled={busy}
                >
                    Объединить
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    const catalogsEnum = [...personalInfoBlockCatalogs, ...personnelInfoBlockCatalogs];
    let catalogs = catalogsEnum.reduce((obj, name) => {
        obj[name] = state.catalogs[name];
        return obj;
    }, {});

    return {
        person: state.person.data,
        mergeData: state.person.mergeData,
        catalogs,
    };
};

const actions = {
    setMergeData,
    showErrorAlert,
    showSuccessAlert,
    push,
    showPageLoader,
    hidePageLoader,
};

export default connect(mapStateToProps, actions)(PersonMerge);
