import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAllWishlists } from 'ducks/Wishlists';
import Loader from 'components/common/Loader';
import DataCard from 'components/common/DataCard/DataCard';
import Pagination from 'components/common/Pagination';
import PersonCard from './PersonCard';
import { getPersonsForWishlist } from 'api';
import { showErrorAlert } from 'ducks/Alert';
import Field from 'components/uikit/Field';
import Input from 'components/uikit/Input';

class Wishlists extends Component {
    state = {
        paging: { pageNum: 1, pageSize: 10 },
        loadingWishlist: false,
        loadedWishlists: [],
        searchTerm: '',
    };

    timer = null;

    changePage = (pageNum) => {
        this.setState({ paging: { ...this.state.paging, pageNum } });
    };

    getWishlist = async (id) => {
        const { loadedWishlists } = this.state;
        const foundWishlist = loadedWishlists.find((x) => x.id === id);
        if (foundWishlist) {
            return foundWishlist;
        }

        try {
            this.setState({ loadingWishlist: id });
            const wishlist = await getPersonsForWishlist(id);
            this.setState({
                loadedWishlists: [...loadedWishlists, { id, persons: wishlist.data }],
            });
            return { id, persons: wishlist.data };
        } catch (e) {
            this.props.showErrorAlert('Ошибка получения подборки');
        } finally {
            this.setState({ loadingWishlist: false });
        }
    };

    isSelectedWishlist = (id) => {
        const {
            list: { wishLists },
            selected,
        } = this.props;

        const wishlist = wishLists.find((x) => x.id === id);
        const selectedCount = wishlist.personIds.filter((x) => selected.find((z) => z === x))
            .length;
        if (selectedCount === 0) {
            return false;
        }
        return selectedCount === wishlist.entriesCount ? 'all' : 'part';
    };

    checkWishlist = async (id, checked) => {
        const {
            list: { wishLists },
            setSelected,
        } = this.props;
        const wishlist = wishLists.find((x) => x.id === id);
        setSelected(wishlist?.personIds || [], checked);
    };

    changeSearchterm = (e) => {
        const searchTerm = e.target.value;
        this.setState({ searchTerm });
    };

    changePageAmount = (pageSize) => {
        this.setState({ paging: { pageNum: 1, pageSize } });
    };

    componentDidMount() {
        const { paging } = this.state;
        this.props.getAllWishlists({ paging, IncludeIds: true });
    }

    componentDidUpdate(prevProps, prevState) {
        const { paging, searchTerm } = this.state;
        if (
            prevState.paging.pageNum !== paging.pageNum ||
            prevState.paging.pageSize !== paging.pageSize
        ) {
            this.props.getAllWishlists({ paging, IncludeIds: true });
        }

        if (prevState.searchTerm !== searchTerm) {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.props.getAllWishlists({ paging, IncludeIds: true, searchTerm });
            }, 500);
        }
    }

    render() {
        const { paging, loadingWishlist, loadedWishlists, searchTerm } = this.state;
        const { list, loadComplete, loading, setSelected } = this.props;

        return (
            <>
                {(!loadComplete || loading || loadingWishlist) && <Loader overlay />}
                <div className="PersonSelectorSection__Header">
                    <Field>
                        <Input
                            value={searchTerm}
                            onChange={this.changeSearchterm}
                            postIconButton={searchTerm && 'times-circle'}
                            postIconButtonClick={() => this.setState({ searchTerm: '' })}
                        />
                    </Field>
                </div>
                <div className="PersonSelectorSection__Body">
                    {list?.wishLists.map((x) => {
                        const checkStatus = this.isSelectedWishlist(x.id);
                        return (
                            <div key={x.id}>
                                <DataCard
                                    className="PersonSelectorSection__Item"
                                    title={`${x.name} (${x.entriesCount})`}
                                    defaultIsOpen={false}
                                    openCallback={
                                        x.entriesCount !== 0
                                            ? () => this.getWishlist(x.id)
                                            : undefined
                                    }
                                    toggleDisabled={x.entriesCount === 0}
                                    withCheck
                                    checked={checkStatus === 'all'}
                                    partlyChecked={checkStatus === 'part'}
                                    onCheck={() => this.checkWishlist(x.id, checkStatus !== 'all')}
                                >
                                    {loadedWishlists.filter((w) => w.id === x.id).length !== 0 &&
                                        loadedWishlists
                                            .find((w) => w.id === x.id)
                                            .persons.map((z) => {
                                                const isPersonChecked = this.props.isSelectedPerson(
                                                    z.id,
                                                );
                                                return (
                                                    <PersonCard
                                                        key={z.id}
                                                        person={z}
                                                        checkable
                                                        checked={isPersonChecked}
                                                        onCheck={() =>
                                                            setSelected([z.id], !isPersonChecked)
                                                        }
                                                    />
                                                );
                                            })}
                                </DataCard>
                            </div>
                        );
                    })}
                </div>
                {list?.meta && (
                    <div className="PersonSelectorSection__Pagination">
                        <Pagination
                            currentPageNum={paging.pageNum}
                            pageCount={list.meta.pageCount}
                            totalCount={list.meta.foundCount}
                            shownTotal={list.wishLists.length}
                            onPaginate={this.changePage}
                            changePageAmount={this.changePageAmount}
                            pageSize={paging.pageSize}
                        />
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        list: state.wishlists.allWishLists,
        loading: state.wishlists.loading,
        loadComplete: state.wishlists.loadComplete,
    };
};

const actions = {
    getAllWishlists,
    showErrorAlert,
};

Wishlists.propTypes = {
    isSelectedPerson: PropTypes.func,
    filterOptions: PropTypes.array,
    setSelected: PropTypes.func,
    selected: PropTypes.array,
};

export default connect(mapStateToProps, actions)(Wishlists);
