import { all, call, put, takeLatest } from 'redux-saga/effects';
import { appName, GROUPS_DICTIONARY_NAME } from '../constants';
import RequestError from '../RequestError';
import { showSuccessAlert, showErrorAlert } from './Alert';
import { searchGroups, deleteGroup as deleteGroupApi, getAllowedGroups } from "../api";
import { serviceResultCode, getError } from "serviceErrors";
import { withPageLoader } from './PageLoader';
import { refetchDictionary } from 'ducks/Dictionary';

const moduleName = 'groups';
export  const FETCH_REQUEST_ALLOWED = `${appName}/${moduleName}/FETCH_REQUEST_ALLOWED`;
export const FETCH_REQUEST = `${appName}/${moduleName}/FETCH_REQUEST`;
export const FETCH_START = `${appName}/${moduleName}/FETCH_START`;
export const FETCH_SUCCESS = `${appName}/${moduleName}/FETCH_SUCCESS`;
export const FETCH_FAILED = `${appName}/${moduleName}/FETCH_FAILED`;
export const FETCH_START_ALLOWED = `${appName}/${moduleName}/FETCH_START_ALLOWED`;
export const FETCH_SUCCESS_ALLOWED = `${appName}/${moduleName}/FETCH_SUCCESS_ALLOWED`;
export const FETCH_FAILED_ALLOWED = `${appName}/${moduleName}/FETCH_FAILED_ALLOWED`;
export const DELETE_GROUP = `${appName}/${moduleName}/DELETE_GROUP`;
export const SEND_DELETE_GROUP = `${appName}/${moduleName}/SEND_DELETE_GROUP`;
export const NEED_UPDATE = `${appName}/${moduleName}/NEED_UPDATE`;
export const CHANGE_CRITERIA = `${appName}/${moduleName}/CHANGE_CRITERIA`;

const initialState = {
    loading: false,
    loadComplete: false,
    loadTime: new Date(0),
    needUpdate: false,
    data: {
        payload: [],
        meta: {
            foundCount: 0,
            pageCount: 0,
        },
    },
    error: ''
};

export default function reducer(state = initialState, action) {
    const { type, payload, error } = action;

    switch (type) {
        case FETCH_START:
            return {
                ...state,
                loading: true,
                needUpdate: false,
            };
        case FETCH_FAILED:
            return {
                ...state,
                loadComplete: true,
                loadTime: payload.loadTime,
                error: error.message,
                needUpdate: false,
            };
        case FETCH_SUCCESS:
            return {
                ...state,
                loadComplete: true,
                loadTime: payload.loadTime,
                data: payload.data,
                needUpdate: false,
            };
        case FETCH_START_ALLOWED:
            return {
                ...state,
                loadingAllowed: true,
                needUpdateAllowed: false,
            };
        case FETCH_FAILED_ALLOWED:
            return {
                ...state,
                loadCompleteAllowed: true,
                loadTimeAllowed: payload.loadTime,
                errorAllowed: error.message,
                needUpdateAllowed: false,
            };
        case FETCH_SUCCESS_ALLOWED:
            return {
                ...state,
                loadCompleteAllowed: true,
                loadTimeAllowed: payload.loadTime,
                dataAllowed: payload.data,
                needUpdateAllowed: false,
            };
        case NEED_UPDATE:
            return {
                ...state,
                needUpdate: true,
                needUpdateAllowed: true,
            };
        default:
            return state;
    }
}

export const fetchGroups = (criteria) => {
    return {
        type: FETCH_REQUEST,
        payload: { criteria },
    }
};

export const fetchStart = () => {
    return {
        type: FETCH_START,
    }
};

export const fetchSuccess = (data, loadTime) => {
    return {
        type: FETCH_SUCCESS,
        payload: { data, loadTime },
    }
};

export const fetchFailed = (error, loadTime) => {
    return {
        type: FETCH_FAILED,
        payload: { loadTime },
        error,
    }
};

export const changeCriteria = (key, value) => {
    return {
        type: CHANGE_CRITERIA,
        payload: { key, value },
    };
};

export const deleteGroup = (group) => {
    return {
        type: DELETE_GROUP,
        payload: { group },
    }
};

export const fetchAllowedGroups = (groupId) => {
    return {
        type: FETCH_REQUEST_ALLOWED,
        payload: { groupId },
    }
};

export const fetchStartAllowed = () => {
    return {
        type: FETCH_START_ALLOWED,
    }
};

export const fetchSuccessAllowed = (data, loadTime) => {
    return {
        type: FETCH_SUCCESS_ALLOWED,
        payload: { data, loadTime },
    }
};

export const fetchFailedAllowed = (error, loadTime) => {
    return {
        type: FETCH_FAILED_ALLOWED,
        payload: { loadTime },
        error,
    }
};

export const needUpdateGroups = () => {
    return {
        type: NEED_UPDATE
    }
};

export const fetchAllowedGroupsSaga = function* (action) {
    yield put(fetchStartAllowed());

    const { groupId } = action.payload;
    try {
        const response = yield call(getAllowedGroups, groupId);
        const groups = response.data;
        yield put(fetchSuccessAllowed(groups, new Date()));
    } catch (error) {
        const reqError = new RequestError(error, 'При загрузке доступных групп пользователей произошла ошибка');
        yield all([
            put(fetchFailedAllowed(reqError, new Date())),
            put(showErrorAlert(reqError.message))
        ]);
    }
};

export const fetchGroupsSaga = function* (action) {
    yield put(fetchStart());

    const { criteria } = action.payload;
    try {
        const response = yield call(withPageLoader, () => searchGroups(criteria));
        const groups = response.data;
        yield put(fetchSuccess(groups, new Date()));
    } catch (error) {
        const reqError = new RequestError(error, 'При загрузке групп пользователей произошла ошибка');
        yield all([
            put(fetchFailed(reqError, new Date())),
            put(showErrorAlert(reqError.message))
        ]);
    }
};

export const sendDeleteGroup = function* (action) {
    const { group } = action.payload;

    if (!group) {
        return;
    }

    try {
        yield call(deleteGroupApi, group.id);
        yield put(showSuccessAlert(`Группа "${group.title}" была успешно удалена`));
        yield put(needUpdateGroups());
        yield put(refetchDictionary(GROUPS_DICTIONARY_NAME));
    } catch (error) {

        const reqError = getError(error, getGroupError(group));

        yield put(showErrorAlert(reqError.message));
    }
};

const getGroupError = (group) => (code) => {
    switch (code) {
        case serviceResultCode.NotFound:
            return `Группа с именем "${group.title}" не найдена`;
        case serviceResultCode.GroupIsInUse:
            return `Группу с именем "${group.title}" запрещено менять, т.к. она уже используется`;
        case serviceResultCode.GroupCannotChangeType:
            return `У Группы с именем "${group.title}" запрещено менять тип, т.к. она уже используется`;
        case serviceResultCode.GroupQuotasIsAllocated:
            return `Невозможно удалить группу "${group.title}", так как на эту группу распределены квоты`;
        default:
            return "Произошла непредвиденная ошибка";
    }
};

export const saga = function* () {
    yield all([
        takeLatest(FETCH_REQUEST_ALLOWED, fetchAllowedGroupsSaga),
        takeLatest(FETCH_REQUEST, fetchGroupsSaga),
        takeLatest(DELETE_GROUP, sendDeleteGroup),
    ]);
};

export const groupsSelector = (state) => {
    const { groups } = state;

    if (!groups.loadComplete) {
        return { loadComplete: false };
    }

    return {
        loadComplete: true,
        data: groups.data,
        needUpdate: groups.needUpdate,
        currentUser: state.auth.user,
    }
};
