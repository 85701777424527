import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { setSearchFilter } from '../utils';
import { MANAGEMENT_FILTER_ID } from '../constants';
import { EMPLOYEE_LEVEL_DICT, GEO_LEVEL_DICT } from 'constants.js';
import Field from 'components/Lk/Uikit/Field';
import Select from 'components/Lk/Uikit/Select/CustomSelect';
import SearchGroupCondition from '../SearchGroupCondition';
import ActiveFilters from '../ActiveFilters';
import { queryOperation } from 'components/Search/QueryOperation';
import SuccessButton from '../SuccessButton';
import Input from 'components/Lk/Uikit/Input'; 
import StyledCheckBox from 'components/Lk/Uikit/StyledCheckBox';
import uuid from 'uuid/v4';
import { FIELDS_TYPE } from '../SearchFilters/constants';

const CustomSelect = (props) => {
    const { options, value } = props;
    return (
        <Select {...props} value={options.filter((x) => value && value.indexOf(x.value) >= 0)} />
    );
};

const EMPLOYEE_SECTION = 'employee';
const EMPLOYEE_LEVEL_SECTION = 'employee_level';
const GEO_LEVEL_SECTION = 'geo_level';
const EXPERIENCE_LEVEL = 'experience_level';
const SOCIAL_ACHIVEMENTS = 'social_achivements';
const MANAGEMENT_LEVEL = 'management_level';

const sectionOptions = [
    { value: GEO_LEVEL_SECTION, label: 'Географический уровень' },
    { value: SOCIAL_ACHIVEMENTS, label: 'Деятельность в качестве руководителя' },
    { value: EXPERIENCE_LEVEL, label: 'Уровень подготовленности участника' },
    { value: EMPLOYEE_LEVEL_SECTION, label: 'Уровень управленца' },
];

const ManagementFilter = props => {
    const {
        setFiltersState,
        employeesNumbers,
        managementLevels,
        updateFiltersWithCondition,
        getGroupCondition,
        activeFilter,
        onSuccess,
        isFocused,
    } = props;

    const [visibleSection, setVisibleSection] = useState(null);
    const [employee, setEmployee] = useState(null);
    const [employeeLevel, setEmployeeLevel] = useState(null);
    const [geoLevel, setGeoLevel] = useState(null);
    const [experienceLevel, setExperienceLevel] = useState(null);
    const [socialAchivements, setSocialAchivements] = useState(null);
    const [managementLevel, setManagementLevel] = useState(null);
    const [groupCondition, setGroupCondition] = useState(getGroupCondition(MANAGEMENT_FILTER_ID));
    const [excludeFromSearch, setExcludeFromSearch] = useState(false);

    const handleCriteriaChange = e => {
        setVisibleSection(e ? e.value : null);
    };

    const onSubmit = async (isClose) => {
        await AddFilter()
        clearFields();
        isClose && onSuccess();
    };

    useEffect(() => {
        if (activeFilter == null) {
            return;
        }

        setVisibleSection(activeFilter.section);
        switch (activeFilter.section) {
            case EMPLOYEE_SECTION:
                setEmployee(employeesNumbers.filter((x) => activeFilter.values.find((z) => z === x.id)));
                break;
            case EMPLOYEE_LEVEL_SECTION:
                setEmployeeLevel(EMPLOYEE_LEVEL_DICT.filter((x) => activeFilter.values.find((z) => z === x.value)));
                break;
            case GEO_LEVEL_SECTION:
                setGeoLevel(GEO_LEVEL_DICT.filter((x) => activeFilter.values.find((z) => z === x.value)));
                break;
            case EXPERIENCE_LEVEL:
                setExperienceLevel(props.catalogs.experienceLevels.data.filter((x) => activeFilter.values.find((z) => z === x.value)));
                break;
            case SOCIAL_ACHIVEMENTS:
                setSocialAchivements(activeFilter.value);
                break;
            case MANAGEMENT_LEVEL:
                setManagementLevel(managementLevels.filter((x) => activeFilter.values.find((z) => z === x.value)));
                break;                
            default:
                break;
        }

        setExcludeFromSearch(activeFilter.excludeFromSearch);
    }, [activeFilter, employeesNumbers, managementLevels, props.catalogs.experienceLevels.data]);

    const buttonEnabled =
        (employee) ||
        (managementLevel) ||
        (visibleSection === EMPLOYEE_LEVEL_SECTION && employeeLevel) ||
        (visibleSection === GEO_LEVEL_SECTION && geoLevel) ||
        (visibleSection === EXPERIENCE_LEVEL && experienceLevel) ||
        (visibleSection === SOCIAL_ACHIVEMENTS && socialAchivements);

    const AddFilter = () => {
        const group = uuid();
        let readyFilters = [];
        
        employee && readyFilters.push(
            setSearchFilter(
                'PersonJobs',
                'EmployeesNumberId',
                queryOperation.InArray,
                employee.map((x) => x.id),
                employee?.length !== 0
                    ? `Кол-во человек в подчинении: ${employee.map((x) => ` ${x.label}`)}`
                    : null,
                'AND',
                MANAGEMENT_FILTER_ID,
                EMPLOYEE_SECTION,
                excludeFromSearch,
                null,
                group,
                FIELDS_TYPE.multiSelect,
            )
        );

        employeeLevel && readyFilters.push(
            setSearchFilter(
                'PersonPersonnels',
                'ManagerLevel',
                queryOperation.InArray,
                employeeLevel.map((x) => x.value),
                employeeLevel?.length !== 0
                    ? `Уровень управленца: ${employeeLevel.map((x) => ` ${x.label}`)}`
                    : null,                
                'AND',
                MANAGEMENT_FILTER_ID,
                EMPLOYEE_LEVEL_SECTION,
                excludeFromSearch,
                null,
                group,
                FIELDS_TYPE.multiSelect,                
            )
        );

        geoLevel && readyFilters.push(
            setSearchFilter(
                'PersonPersonnels',
                'GeoLevel',
                queryOperation.InArray,
                geoLevel.map((x) => x.value),
                geoLevel?.length !== 0
                    ? `Географический уровень: ${geoLevel.map((x) => ` ${x.label}`)}`
                    : null,                
                'AND',
                MANAGEMENT_FILTER_ID,
                visibleSection,
                excludeFromSearch,
                null,
                group,
                FIELDS_TYPE.multiSelect,                 
            )
        );

        experienceLevel && readyFilters.push(
            setSearchFilter(
                'PersonPersonnels',
                'ExperienceLevelId',
                queryOperation.InArray,
                experienceLevel.map((x) => x.id),
                experienceLevel?.length !== 0
                    ? `Уровень подготовленности участника: ${experienceLevel.map((x) => ` ${x.label}`)}`
                    : null,                
                'AND',
                MANAGEMENT_FILTER_ID,
                visibleSection,
                excludeFromSearch,
                null,
                group,
                FIELDS_TYPE.multiSelect,                
            )
        );

        socialAchivements && readyFilters.push(
            setSearchFilter(
                'PersonPersonnels',
                'SocialAchivements',
                queryOperation.Contain,
                socialAchivements,
                `Деятельность в качестве руководителя: ${socialAchivements}`,
                'AND',
                MANAGEMENT_FILTER_ID,
                visibleSection,
                excludeFromSearch,
            )
        );

        managementLevel && readyFilters.push(
            setSearchFilter(
                'PersonJobs',
                'ManagementLevelId',
                queryOperation.InArray,
                managementLevel.map((x) => x.value),
                managementLevel?.length !== 0
                ? `Уровень менеджмента: ${managementLevel.map((x) => ` ${x.label}`)}`
                : null,                 
                'AND',
                MANAGEMENT_FILTER_ID,
                MANAGEMENT_LEVEL,
                excludeFromSearch,
                null,
                group,
                FIELDS_TYPE.multiSelect,                     
            )
        );

        return readyFilters.length !== 0 && setFiltersState(readyFilters, MANAGEMENT_FILTER_ID);
    };

    const clearFields = () => {
        setEmployee(null);
        setEmployeeLevel(null);
        setGeoLevel(null);
        setExperienceLevel(null);
        setSocialAchivements(null);
        setManagementLevel(null);
        setVisibleSection(null);
        setExcludeFromSearch(false);
    };

    const handleGroupConditionChange = e => {
        const condition = e.currentTarget.checked ? 'OR' : 'AND';
        updateFiltersWithCondition(MANAGEMENT_FILTER_ID, condition);
        setGroupCondition(condition);
    };

    const defaultFilters = () => (
        <>
            <div
                className={classnames('LKSearchCardVisibleFIlterLine', {
                    'LKSearchCardVisibleFIlterLine--hidden': activeFilter && activeFilter.section !== EMPLOYEE_SECTION,
                })}
            >
                <Field>
                    <CustomSelect
                        placeholder="Количество человек в подчинении"
                        options={employeesNumbers}
                        onChange={setEmployee}
                        isMulti
                        value={(employee || []).map((x) => x.value)}
                        closeMenuOnSelect={false}
                    />
                </Field>
            </div>

            <div
                className={classnames('LKSearchCardVisibleFIlterLine', {
                    'LKSearchCardVisibleFIlterLine--hidden': activeFilter && activeFilter.section !== MANAGEMENT_LEVEL,
                })}
            >
                <Field>
                    <CustomSelect
                        placeholder="Уровень менеджмента"
                        options={managementLevels}
                        onChange={setManagementLevel}
                        isMulti
                        value={(managementLevel || []).map((x) => x.value)}
                        closeMenuOnSelect={false}
                    />
                </Field>
            </div>
                   
        </>
    );

    const sectionValue = sectionOptions.find(x => x.value === visibleSection);
    return (
        <>
            <div className="LKSearchCardVisibleFIlterLine">
                <SearchGroupCondition
                    checked={groupCondition === 'OR'}
                    onClick={e => handleGroupConditionChange(e)}
                />
            </div>
            <div className="LKSearchCardVisibleFIlterLine">
                <ActiveFilters filterId={MANAGEMENT_FILTER_ID} />
            </div>
            {defaultFilters()}
            {((activeFilter && sectionValue) || !activeFilter) && 
                <div className="LKSearchCardVisibleFIlterLine">
                    <Field>
                        <Select
                            placeholder="Выберите дополнительный критерий"
                            options={sectionOptions}
                            value={sectionValue || null}
                            onChange={handleCriteriaChange}
                            isClearable={!activeFilter}
                        />
                    </Field>
                </div>
            }

            <div
                className={classnames('LKSearchCardVisibleFIlterLine', {
                    'LKSearchCardVisibleFIlterLine--hidden': visibleSection !== GEO_LEVEL_SECTION,
                })}
            >
                <Field>
                    <CustomSelect
                        placeholder="Выберите уровень"
                        options={GEO_LEVEL_DICT}
                        onChange={setGeoLevel}
                        isMulti
                        value={(geoLevel || []).map((x) => x.value)}
                        closeMenuOnSelect={false}
                    />
                </Field>
            </div>
            <div
                className={classnames('LKSearchCardVisibleFIlterLine', {
                    'LKSearchCardVisibleFIlterLine--hidden': visibleSection !== EXPERIENCE_LEVEL,
                })}
            >
                <Field>
                    <CustomSelect
                        placeholder="Выберите уровень"
                        options={props.catalogs.experienceLevels.data}
                        onChange={setExperienceLevel}
                        isMulti
                        value={(experienceLevel || []).map((x) => x.value)}
                        closeMenuOnSelect={false}
                    />
                </Field>
            </div>
            <div
                className={classnames('LKSearchCardVisibleFIlterLine', {
                    'LKSearchCardVisibleFIlterLine--hidden': visibleSection !== SOCIAL_ACHIVEMENTS,
                })}
            >
                <Field>
                    <Input value={socialAchivements || ''} onChange={e => setSocialAchivements(e.target.value)}/>
                </Field>
            </div>
            <div
                className={classnames('LKSearchCardVisibleFIlterLine', {
                    'LKSearchCardVisibleFIlterLine--hidden': visibleSection !== EMPLOYEE_LEVEL_SECTION,
                })}
            >
                <Field>
                    <CustomSelect
                        placeholder="Выберите уровень"
                        options={EMPLOYEE_LEVEL_DICT}
                        onChange={setEmployeeLevel}
                        isMulti
                        value={(employeeLevel || []).map((x) => x.value)}
                        closeMenuOnSelect={false}
                    />
                </Field>
            </div>

            <div className={classnames('LKSearchCardVisibleFIlterLine', {
                'LKSearchCardVisibleFIlterLine--hidden': !buttonEnabled,
            })}>
                <Field>
                    <StyledCheckBox
                        onChange={setExcludeFromSearch}
                        checked={excludeFromSearch}
                        title="Исключить из поиска"
                        style={{display: 'inline-block'}}
                    />
                </Field>
            </div>
            
            <SuccessButton
                onSubmit={onSubmit}
                buttonEnabled={buttonEnabled}
                activeFilter={activeFilter}
                visibleSection={visibleSection}
                defaultFilters={defaultFilters}
                isFocused={isFocused}
            />
        </>
    );
};

const mapStateToProps = state => {
    return {
        employeesNumbers: state.catalogs.employeesNumbers.data,
        managementLevels: state.catalogs.managementLevels.data,
        activeFilter: state.filterSearch.activeFilter,
    };
};

export default connect(mapStateToProps)(ManagementFilter);
