import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const MenuSwitcherItem = props => {
    const { itemId, isActive, title, onClick, theme, isTab } = props;

    return (
        <div
            data-itemid={itemId}
            className={classnames('MenuSwitcher__Item', {
                "MenuSwitcher__Item--tab": isTab,
                "MenuSwitcher__Item--tab-inactive": isTab && !isActive,
                [`MenuSwitcher__Item--active MenuSwitcher__Item--active-${theme}`]: isActive,
            })}
            onClick={onClick}
        >
            {title}
        </div>
    );
};

MenuSwitcherItem.defaultProps = {
    isActive: false,
    onClick: () => {},
    theme: "light",
    isTab: false,
};

MenuSwitcherItem.propTypes = {
    itemId: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    theme: PropTypes.oneOf(["light", "dark"]),
    isTab: PropTypes.bool,
};

export default MenuSwitcherItem;
