import React from 'react';
import Button from 'components/uikit/Button';

export const ConstructorItem = props => {
    const { data, onShowModal, count } = props;

    const onClick = () => {
        onShowModal(data);
    };

    return (
        <div className="ConstructorItem">
            <Button className="ConstructorItem__Button" onClick={onClick}>{data.name}</Button>
            <span className="ConstructorItem__Count">{count}</span>
        </div>
    );
};

export default ConstructorItem;
