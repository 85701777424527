import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import debounce from 'debounce-promise';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ControlGroup from 'components/uikit/ControlGroup';
import { AsyncSelect } from 'components/uikit/Select';
import { showErrorAlert } from 'ducks/Alert';
import './NewsTagSearchSelect.scss';

import Field from 'components/uikit/Field';
import Label from 'components/uikit/Label';
import Button from 'components/uikit/Button';

import { fullTextSearchNewsTags } from 'api';

const search = (onError) => async inputValue => {
    const criteria = {
        searchTerm: inputValue,
        paging: {
            pageNum: 1,
            pageSize: 10,
        },
    };

    try {
        const response = await fullTextSearchNewsTags(criteria);
        const tags = response.data.payload;
        const options = tags.map(tag => ({
            value: tag,
            label: tag.title,
        }));
        return options;
    } catch (error) { 
        onError('При поиске тегов произошла ошибка');
        return [];
    }
};

const renderOptionLabel = ({ value: tag }, { context }) => {
    const title = tag && tag.title;

    const isSelected = context === 'value';
    const containerClassName = classnames('NewsTagSearchSelect__OptionContainer', {
        'NewsTagSearchSelect__OptionContainer--selected': isSelected,
    });

    return (
        <div className={containerClassName}>
            <div className="NewsTagSearchSelect__Option">
                <span className="NewsTagSearchSelect__OptionName">{title}</span>
            </div>
        </div>
    );
};

class NewsTagSearchSelect extends Component {
    state = {
        tagEditMode: false,
        tagExists: false,
    };

    tagControlFocused = false;

    onStartSelectTag = () => {
        this.setState({
            tagEditMode: true,
        });
    };

    onCloseSelectTag = () => {
        this.setState({
            tagEditMode: false,
        });
    };

    onSelectTag = tag => {

        const tags =
            !this.props.tags || !Array.isArray(this.props.tags) ? [] : [...this.props.tags];

        const tagExists = !!tags.find(x => x.title === tag.label);

        this.setState({
            tmpSelectedTag: tag.label,
            tagExists,
            tagFromSearch: { ...tag },
        });
    };

    onTagControlInputChange = value => {
        if (this.tagControlFocused) {
            this.setState({
                tmpSelectedTag: value,
            });
        }
    };

    onTagControlFocus = () => {
        this.tagControlFocused = true;

        this.setState({
            tagExists: false,
        });
    };

    onTagControlBlur = () => {
        this.tagControlFocused = false;
        this.setState(state => ({
            tagFromSearch: (this.state.tagFromSearch &&
                this.state.tagFromSearch.label === state.tmpSelectedTag && {
                ...this.state.tagFromSearch,
            }) || {
                label: state.tmpSelectedTag,
                value: {
                    id: undefined,
                    title: state.tmpSelectedTag,
                    groupId: this.props.groupId,
                },
            },
        }));
    };

    onAddTag = () => {
        const { tagFromSearch } = this.state;

        if (!tagFromSearch || !tagFromSearch.label) {
            return;
        }

        const newTag = tagFromSearch.value.title.trim();

        const tags =
            !this.props.tags || !Array.isArray(this.props.tags) ? [] : [...this.props.tags];

        const existingTag = tags.find(x => x.title === newTag);

        if (existingTag) {

            this.setState({
                tagExists: true,
            });

            return;
        }

        tags.push({
            id: tagFromSearch.value.id,
            title: newTag,
            groupId: this.props.groupId,
        });

        this.setState({
            tagEditMode: false,
            tagFromSearch: undefined,
            tmpSelectedTag: undefined,
        });
        this.props.onTagsChanged(tags);
    };

    onRemoveTag = title => {
        const tags = !this.props.tags || !Array.isArray(this.props.tags) ? [] : this.props.tags;

        this.props.onTagsChanged(tags.filter(x => x.title !== title));
    };

    render() {
        const { onError, viewMode, tags } = this.props;
        const { tagFromSearch, tmpSelectedTag, tagExists } = this.state;

        const editButtons = this.state.tagEditMode
            ? [
                <Button size="sm" faIcon="check" onClick={this.onAddTag} />,
                <Button size="sm" faIcon="times" onClick={this.onCloseSelectTag} />,
            ]
            : [];

        return (
            <div className="NewsTagSearchSelect">
                <ControlGroup>
                    {tags && tags[0] && <Label>Теги</Label>}
                    <Field filled={true} required={false} invalid={false}>
                        <div>
                            {tags &&
                                tags.map(x => (
                                    <div className="NewsTagSearchSelect__NewsTag" key={x.title}>
                                        {x.title}
                                        {!viewMode && (
                                            <FontAwesomeIcon
                                                icon="times-circle"
                                                className="NewsTagSearchSelect__NewsTag__DeleteButton"
                                                onClick={() => this.onRemoveTag(x.title)}
                                            />
                                        )}
                                    </div>
                                ))}
                        </div>
                    </Field>
                    {!viewMode && (
                        <div className="NewsTagSearchSelect__AddNewsTagControl">
                            {!this.state.tagEditMode ? (
                                <div
                                    className="NewsTagSearchSelect__AddLink"
                                    onClick={this.onStartSelectTag}
                                >
                                    Добавить тег
                                </div>
                            ) : (
                                    <>
                                        <ControlGroup buttons={editButtons}>

                                            <div className="NewsTagSearchSelect__NewsTagSearch">
                                                <FontAwesomeIcon
                                                    className="NewsTagSearchSelect__SearchIcon"
                                                    icon={faSearch}
                                                />
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    blurInputOnSelect={true}
                                                    loadOptions={debounce(search(onError), 500)}
                                                    formatOptionLabel={renderOptionLabel}
                                                    onChange={this.onSelectTag}
                                                    value={this.tagControlFocused ? {} : tagFromSearch}
                                                    onInputChange={this.onTagControlInputChange}
                                                    onBlur={this.onTagControlBlur}
                                                    onFocus={this.onTagControlFocus}
                                                    inputValue={
                                                        this.tagControlFocused ? tmpSelectedTag : ''
                                                    }
                                                />
                                            </div>
                                        </ControlGroup>
                                        {tagExists && <small className="NewsTagSearchSelect__AddTagHint">* тэг с таким именем уже существует</small>}
                                    </>
                                )}
                        </div>
                    )}
                </ControlGroup>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    onError: bindActionCreators(showErrorAlert, dispatch),
});

export default connect(null, mapDispatchToProps)(NewsTagSearchSelect);
