import React, { useEffect, useState } from 'react';

import ModalPopup from 'components/Lk/Uikit/ModalPopup';
import PersonComments from 'components/Lk/Containers/PersonComments';

const ModalSearchCardCommentsBlock = props => {
    const { personId, isOpen, setIsOpen } = props;

    const [isHide, setIsHide] = useState(false);
    
    useEffect(() => {
        if (!isHide) {
            setIsOpen(false);
        }
        // eslint-disable-next-line
    }, [isHide]);

    const renderCommentsBlock = () => {
        return (
            <ModalPopup
                isModalOpen={isOpen}
                header="Заметки"
                onModalClose={() => setIsOpen(false)}
                actionButtons={[]}
                showCloseIcon
                withFooter={false}
                width="100%"
            >
                <PersonComments
                    personId={personId}
                    onModalShown={setIsHide}
                />
            </ModalPopup>
        );
    };

    return renderCommentsBlock();
};

export default ModalSearchCardCommentsBlock;
