import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Input from 'components/uikit/Input';
import Label from 'components/uikit/Label';

const TwoFactorStage = (props) => {
    const { code, codeTimerValue, signInInProgress, onChange, onResendCode, auth } = props;

    return (
        <>
            <div className="SignIn__TextSection">
                Введите код подтверждения, отправленный на номер:
            </div>
            <div className="SignIn__PhoneNumberMask">{auth.maskedPhoneNumber}</div>
            <div className="kr_item required">
                <Label htmlFor="code">Код подтверждения</Label>
                <Input
                    name="code"
                    id="code"
                    required
                    autoFocus
                    value={code || ''}
                    disabled={auth.signInInProgress}
                    onChange={onChange}
                />
            </div>
            <div className="SingnIn__RequestBlock">
                {codeTimerValue > 0 ? (
                    <div className="SignIn__TextSection">
                        Код можно будет запросить повторно через {codeTimerValue} сек
                    </div>
                ) : (
                    <div className="SingnIn__RequestText">
                        <button
                            className="kr_link_button"
                            disabled={signInInProgress}
                            onClick={onResendCode}
                        >
                            Запросить код
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

const props = (state) => {
    return {
        auth: state.auth,
    };
};

TwoFactorStage.propTypes = {
    code: PropTypes.string,
    codeTimerValue: PropTypes.number,
    signInInProgress: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onResendCode: PropTypes.func.isRequired,
};

export default connect(props)(TwoFactorStage);
