import React from 'react';
import Button from 'components/uikit/Button';
import { localizeTimezone } from 'utils';
import './UserSessionCard.scss';

function UserSessionCard(props) {
    const { id, userId, deviceSessions } = props.userSession;
    const { isSupport } = props;

    const ColoredLine = ({ color, height }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: height,
            }}
        />
    );

    return (
        <div className="UserSessionCard">
            {deviceSessions &&
                deviceSessions.map((item, index) => (
                    <div key={item.id}>
                        {index > 0 ? <ColoredLine color={'grey'} height={2} /> : null}
                        <div className="UserSessionCardLine">
                            <div className="UserSessionCardLine__Label">IP</div>
                            <div className="UserSessionCardLine__Value">{item.ipAddress}</div>
                            <div className="UserSessionCardLine__Action">
                                {!isSupport && props.onDropDevice && (
                                    <Button
                                        onClick={() => props.onDropDevice(userId, item.id)}
                                        disabled={!item.id}
                                        size="xs"
                                    >
                                        {item.id
                                            ? 'Прервать сессию устройства'
                                            : 'Сессия устройства прервана'}
                                    </Button>
                                )}
                            </div>
                        </div>
                        <div className="UserSessionCardLine">
                            <div className="UserSessionCardLine__Label">Время начала сессии</div>
                            <div className="UserSessionCardLine__Value">
                                {localizeTimezone(item.createdOn)}
                            </div>
                        </div>
                        <div className="UserSessionCardLine">
                            <div className="UserSessionCardLine__Label">
                                Время последнего доступа
                            </div>
                            <div className="UserSessionCardLine__Value">
                                {localizeTimezone(item.lastAccessedOn)}
                            </div>
                        </div>
                    </div>
                ))}
            {props.onDropSession && deviceSessions && (
                <div className="UserSessionCardLine">
                    {deviceSessions.length !== 0 ? <ColoredLine color={'grey'} height={2} /> : null}
                    {!isSupport && deviceSessions.length > 1 && (
                        <Button
                            onClick={() => props.onDropSession(userId)}
                            disabled={!id}
                            size="xs"
                        >
                            {id
                                ? 'Прервать все сессии пользователя'
                                : 'Все сессии пользователя прерваны'}
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
}

export default UserSessionCard;
