import React, { useState, useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import moment from 'moment';
registerLocale('ru', ru);

export default React.forwardRef((props, ref) => {
    const { dateFormat = 'dd.MM.yyyy', value, onChange, ...tail } = props;
    const format = [].concat(dateFormat).concat(['ddMMyyyy', 'dd MM yyyy']);
    const [val, setVal] = useState(value);

    useEffect(() => {
        setVal(value);
    }, [value]);
    const onChangeVal = date => {
        let fieldValue =
            moment(date).isValid() && dateFormat === 'dd.MM.yyyy'
                ? moment(date).format('DD.MM.YYYY')
                : date;
        onChange && onChange(date);
        setVal(fieldValue);
    };
    const onChangeRaw = v => {
        setVal(v.target.value);
        props.onChangeRaw && props.onChangeRaw(v);
    };
    return (
        <DatePicker
            ref={ref}
            dateFormat={format}
            value={val}
            onChange={onChangeVal}
            onChangeRaw={onChangeRaw}
            {...tail}
        />
    );
});
