import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { personGrowthResourceRoute } from 'routes';
import PersonGrowthResource from './PersonGrowthResource';
import NotFound from '../../NotFound';
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import { ALLOWED_MODERATE_PERSONGROWTHRESOURCE } from '../../../rightsController';

class PersonGrowthResourceRoutes extends Component {
    render() {
        return (
            <Switch>
                <ProtectedRoute path={personGrowthResourceRoute.url} component={PersonGrowthResource} allowedRoles={ALLOWED_MODERATE_PERSONGROWTHRESOURCE} />
                <Route component={NotFound} />
            </Switch>
        );
    }
}

export default PersonGrowthResourceRoutes;