import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { showSuccessAlert, showErrorAlert } from 'ducks/Alert';
import { getWishlistById, updateWishlist, deleteWishlist } from 'api';
import queryString from 'query-string';
import { lkSearch } from 'routes';
import classnames from 'classnames';
import { ACTIVE_DETAIL_CARD } from 'components/Lk/Basic/constants';
import InfoField from 'components/Lk/Uikit/InfoField';
import WishlistAttributeSelector from 'components/Lk/Common/Selectors/WishlistAttributeSelector';
import IconButton from 'components/Lk/Uikit/Buttons/IconButton';
import ModalPopup from 'components/Lk/Uikit/ModalPopup';
import Loader from 'components/common/Loader';
import CommentsBlock from 'components/Lk/Common/CommentsBlock';
import StickyFooter from 'components/Lk/Common/StickyFooter';
import WithKeyboardHandler from 'components/Lk/Hooks/WithKeyboardHandler';
import { isNullOrWhitespace } from 'utils';
import './WishlistCard.scss';

class WishlistCard extends Component {
    _isMounted = false;

    state = {
        loading: false,
        saving: false,
        name: '',
        visibility: '',
        comment: '',
        comments: [],
        isModalOpen: false,
        actionType: '',
        actionHeader: '',
        actionConfirm: () => {},
        isDefault: false,
        wishlist: null,
    };

    getWishlistById = async (id) => {
        try {
            this.setState({ loading: true });
            const data = await getWishlistById(id);
            const wishlist = data.data;
            const { visibility, comments, isDefault } = wishlist;
            const name = isDefault ? 'Моё избранное' : wishlist.name;
            this._isMounted &&
                this.setState({ name, visibility, comments, comment: '', isDefault, wishlist });
        } catch (e) {
            showErrorAlert('Ошибка получения подборки');
        } finally {
            this._isMounted && this.setState({ loading: false });
        }
    };

    handleNameChange = (value) => this.setState({ name: value });
    handleVisibilityChange = (value) => () => this.setState({ visibility: value });
    handleCommentChange = (value) => this.setState({ comment: value });

    getActionProps = (actionType) => {
        switch (actionType) {
            case 'delete':
                return { actionHeader: 'Удалить подборку?', actionConfirm: this.deleteWishlist };
            case 'save':
                return { actionHeader: 'Сохранить изменения?', actionConfirm: this.saveWishlist };
            default:
                return {};
        }
    };

    handlePopup = (isModalOpen, actionType) => () => {
        const { actionHeader, actionConfirm } = this.getActionProps(actionType);
        this._isMounted && this.setState({ isModalOpen, actionType, actionHeader, actionConfirm });
    };

    deleteWishlist = async () => {
        try {
            this._isMounted && this.setState({ saving: true });
            const {
                match: {
                    params: { id },
                },
            } = this.props;
            await deleteWishlist(id);
            this.goBack([id]);
            showSuccessAlert('Подборка успешно удалена');
        } catch (e) {
            showErrorAlert('Ошибка удаления подборки');
        } finally {
            this._isMounted && this.setState({ saving: false, isModalOpen: false });
        }
    };

    saveWishlist = async () => {
        try {
            this._isMounted && this.setState({ saving: true });
            const {
                match: {
                    params: { id },
                },
            } = this.props;
            const { name, visibility, comment } = this.state;
            const wishList = { id, name: name.trim(), visibility, comment: comment.trim() };
            await updateWishlist(wishList);
            await this.getWishlistById(id);
            this.goBack();
            showSuccessAlert('Подборка успешно сохранена');
        } catch (e) {
            showErrorAlert('Ошибка сохранения подборки');
        } finally {
            this._isMounted && this.setState({ saving: false, isModalOpen: false });
        }
    };

    goBack = (excludeWishlistsFromSelected = []) => {
        const zipSearch = queryString.parse(this.props.location.search).q;
        this.props.push({
            pathname: lkSearch.url,
            search: queryString.stringify({ q: zipSearch }),
            state: { updated: new Date(), excludeWishlistsFromSelected },
        });
    };

    componentDidMount() {
        this._isMounted = true;
        const {
            match: {
                params: { id },
            },
        } = this.props;
        this.getWishlistById(id);
    }

    componentDidUpdate(prevProps) {
        const {
            match: {
                params: { id },
            },
        } = this.props;
        if (prevProps.match.params.id !== id) {
            this.getWishlistById(id);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    isFieldsModified = () => {
        const { wishlist, name, visibility, comment } = this.state;
        return (
            name?.trim() !== wishlist?.name ||
            visibility !== wishlist?.visibility ||
            !isNullOrWhitespace(comment?.trim())
        );
    };

    render() {
        const { activeCard, setActiveCard, isFocused } = this.props;
        setActiveCard(ACTIVE_DETAIL_CARD);
        const isMobileActive = activeCard === ACTIVE_DETAIL_CARD;

        const {
            name,
            visibility,
            comments,
            comment,
            isModalOpen,
            actionHeader,
            actionConfirm,
            loading,
            saving,
            isDefault,
        } = this.state;

        return (
            <div
                className={classnames('DetailsCard', {
                    'DetailsCard--isMobileActive': isMobileActive,
                })}
            >
                {loading && <Loader absolute />}
                <div className="LKWishlistCard" id="WishlistCard">
                    <div
                        className={classnames('LKWishlistCard__Item', {
                            'LKWishlistCard__Item--disabled': isDefault,
                        })}
                    >
                        <InfoField
                            autoFocus
                            isEdit={!isDefault}
                            noIcon
                            noBorder
                            placeholder="Название..."
                            title="Название подборки"
                            value={name}
                            onChange={this.handleNameChange}
                        />
                    </div>
                    <div
                        className={classnames('LKWishlistCard__Item', {
                            'LKWishlistCard__Item--disabled': isDefault,
                        })}
                    >
                        <WishlistAttributeSelector
                            selectItem={this.handleVisibilityChange}
                            selected={visibility}
                            disabled={isDefault}
                        />
                    </div>
                    <div className="LKWishlistCard__Item">
                        <InfoField
                            type="textarea"
                            isEdit
                            noIcon
                            noBorder
                            autoFocus
                            placeholder="Заметка..."
                            title="Добавление заметки к подборке"
                            value={comment}
                            onChange={this.handleCommentChange}
                        />
                        {comments?.length !== 0 && <CommentsBlock comments={comments} />}
                    </div>
                    <div className="LKWishlistCard__Item LKWishlistCard__Item--button">
                        <IconButton
                            onClick={this.handlePopup(true, 'delete')}
                            type="cross"
                            disabled={isDefault}
                        >
                            Удалить
                        </IconButton>
                    </div>
                    <StickyFooter unstick={isFocused}>
                        <div className="LKWishlistCardFooter">
                            <div
                                className="LKWishlistCardFooter__Action LKWishlistCardFooter__Action--red"
                                onClick={() => this.goBack()}
                            >
                                Отменить
                            </div>
                            <div
                                className={classnames('LKWishlistCardFooter__Action', {
                                    'LKWishlistCardFooter__Action--disabled': !this.isFieldsModified(),
                                })}
                                onClick={
                                    this.isFieldsModified()
                                        ? this.handlePopup(true, 'save')
                                        : () => {}
                                }
                            >
                                Сохранить изменения
                            </div>
                        </div>
                    </StickyFooter>
                </div>
                <ModalPopup
                    isModalOpen={isModalOpen}
                    onModalClose={this.handlePopup(false, '')}
                    header={actionHeader}
                    confirm={actionConfirm}
                    processing={saving}
                />
            </div>
        );
    }
}

const actions = { push };
export default connect(null, actions)(WithKeyboardHandler(WishlistCard, 'WishlistCard'));
