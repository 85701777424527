import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { deleteEvaluationTestById, searchEvaluationTestList, getTestProvidersActive } from 'api';
import { testEditRoute, testNewRoute, testsRoute } from 'routes';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import { serviceResultCode, getError } from 'serviceErrors';
import Button from 'components/uikit/Button';
import Page from 'components/common/Page/Page';
import ListTemplate from 'components/uikit/ListTemplate';
import DataGrid from 'components/common/DataGrid';
import ModalDialog from 'components/common/ModalDialog';
import { parse as queryStringParse, stringify } from 'query-string';
import useUpdateEffect from 'components/Lk/Hooks/useUpdateEffect';

import './TestList.scss';

const getUrlFilterParams = (state) => {
    return {
        page: state.paging.pageNum !== null ? Number(state.paging.pageNum) : 1,
        size: state.paging.pageSize !== null ? Number(state.paging.pageSize) : 10,
        providers: !!state.filter.testProviderIds ? state.filter.testProviderIds : [],
    };
};

const initialCriteria = {
    filter: { title: '', testProviderIds: [] },
    sorting: { title: 'asc' },
    paging: { pageNum: 1, pageSize: 10 },
};

const TestList = (props) => {
    const getStateFromUrl = () => {
        if (!props.location.search) {
            setCriteria({...initialCriteria, paging: {...criteria.paging, pageSize: 10, pageNum: 1}});
            setSelectedProviders(initialCriteria.filter.testProviderIds);

            return initialCriteria;
        }
        const { title = '', page = 1, size = 10 } = queryStringParse(props.location.search);
        const queryParams = new URLSearchParams(props.location.search);
        const providers = queryParams.getAll('providers');
        const newCriteria = {
            filter: {
                title,
                testProviderIds: providers,
            },
            sorting: { title: 'asc' },
            paging: {
                ...criteria.paging,
                pageSize: +size,
                pageNum: +page,
            },
        };

        setCriteria(newCriteria);
        setSelectedProviders(newCriteria.filter.testProviderIds);

        return newCriteria;
    };

    const { showErrorAlert, showSuccessAlert, push } = props;
    const [tests, setTests] = useState([]);

    const [testProviders, setTestProviders] = useState([]);
    const [testProvidersLoading, setTestProvidersLoading] = useState([]);
    const [criteria, setCriteria] = useState(initialCriteria);
    const [stringCriteria, setStringCriteria] = useState(JSON.stringify(initialCriteria));

    const [selectedProviders, setSelectedProviders] = useState(
        initialCriteria.filter.testProviderIds,
    );

    const [meta, setPageMeta] = useState({ foundCount: 0, pageCount: 0 });

    const [testsLoading, setTestsLoading] = useState(true);

    const [openModal, setModal] = useState(false);
    const [currentTest, setCurrentTest] = useState(null);
    const [isDeleting, setDeleteState] = useState(false);

    useEffect(() => {
        let mounted = true;

        const fetchTests = async () => {
            try {
                setTestProvidersLoading(true);
                const testProvidersData = await getTestProvidersActive();
                if (
                    mounted &&
                    testProvidersData &&
                    testProvidersData.data &&
                    Array.isArray(testProvidersData.data)
                ) {
                    setTestProviders(
                        testProvidersData.data.map((x) => ({
                            code: x.code,
                            label: x.title,
                            value: x.id,
                        })),
                    );
                }
            } catch (error) {
                showErrorAlert(error.message);
            } finally {
                setTestProvidersLoading(false);
            }
        };
        fetchTests();

        return () => {
            mounted = false;
        };
    }, [showErrorAlert]);

    useEffect(() => {
        let mounted = true;
        const fetchTests = async () => {
            try {
                const criteria = getStateFromUrl();
                setTestsLoading(true);
                const res = await searchEvaluationTestList(criteria);

                if (mounted && res && res.status === 200) {
                    const resData = await res.data.payload.map((x) => {
                        x.name = x.title;
                        return x;
                    });
                    setPageMeta(res.data.meta);
                    setTests(resData);
                }
            } catch (error) {
                showErrorAlert(error.message);
            } finally {
                mounted && setTestsLoading(false);
            }
        };
        fetchTests();

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line
    }, [showErrorAlert, props.location.search]);

    const fetchTests = async () => {
        try {
            const criteria = getStateFromUrl();
            setTestsLoading(true);
            const res = await searchEvaluationTestList(criteria);

            if (res && res.status === 200) {
                const resData = await res.data.payload.map((x) => {
                    x.name = x.title;
                    return x;
                });
                setPageMeta(res.data.meta);
                setTests(resData);
            }
        } catch (error) {
            showErrorAlert(error.message);
        } finally {
            setTestsLoading(false);
        }
    };

    useUpdateEffect(() => {
        const updateUrl = () => {
            const filter = getUrlFilterParams(JSON.parse(stringCriteria));
            const baseFilter = { ...filter };

            push({
                pathname: testsRoute.url,
                search: stringify(baseFilter),
            });
        };

        updateUrl();
    }, [stringCriteria, push]);

    const onPageChange = (pageIndex) => {
        const newCriteria = { ...criteria, paging: { ...criteria.paging, pageNum: pageIndex + 1 } };

        setCriteria(newCriteria);
        setStringCriteria(JSON.stringify(newCriteria));
    };

    const changePageAmount = (pageSize) => {
        const newCriteria = { ...criteria, paging: { pageNum: 1, pageSize } };
        
        setCriteria(newCriteria);
        setStringCriteria(JSON.stringify(newCriteria));
    };

    const onSortedChange = (sorted) => {
        const newCriteria = {
            ...criteria,
            sorting: { ...criteria.sorting, [sorted[0].id]: sorted[0].desc ? 'desc' : 'asc' },
        };

        setCriteria(newCriteria);
        setStringCriteria(JSON.stringify(newCriteria));
    };

    const buildColumns = [
        {
            Header: 'Название оценочной процедуры',
            accessor: 'title',
            resizable: false,
            sortable: true,
            Cell: ({ original }) => {
                return original.title;
            },
        },
        {
            Header: 'Действие',
            maxWidth: 190,
            resizable: false,
            sortable: false,
            Cell: ({ original }) => (
                <div>
                    <Button
                        size="sm"
                        onClick={() => editTest(original.id)}
                        className="TestListActionButton"
                    >
                        Редактировать
                    </Button>
                </div>
            ),
        },
        {
            Header: '',
            maxWidth: 190,
            resizable: false,
            sortable: false,
            Cell: ({ original }) => (
                <div>
                    <Button
                        size="sm"
                        color="danger"
                        onClick={() => onOpenModal(original)}
                        className="TestListActionButton"
                    >
                        Удалить
                    </Button>
                </div>
            ),
        },
    ];

    const renderModal = (currentTest) => {
        return (
            <ModalDialog
                onClick={() => deleteTest(currentTest.id)}
                onCloseModal={onCloseModal}
                modalOpen={openModal}
                processing={isDeleting}
                modalHeader={`Вы действительно хотите удалить тест ${
                    currentTest && currentTest.title
                }?`}
                btnOktext="Да"
                btnOkColor="danger"
                btnCanceltext="Нет"
            />
        );
    };

    const getTestError = () => (code) => {
        switch (code) {
            case serviceResultCode.NotFound:
                return `Тест с таким именем не найден`;
            case serviceResultCode.EvaluationTestIsUsedUnableDelete:
                return `Невозможно удалить тест, так как он используется`;
            default:
                return 'Произошла непредвиденная ошибка';
        }
    };

    const deleteTest = (testId) => {
        const deleteTestEffect = async () => {
            try {
                setDeleteState(true);
                await deleteEvaluationTestById(testId);
                await fetchTests();
                showSuccessAlert('Тест успешно удален');
            } catch (error) {
                const err = getError(error, getTestError());
                showErrorAlert(err.message);
            } finally {
                onCloseModal();
                setDeleteState(false);
            }
        };
        deleteTestEffect();
    };

    const handleFilterClearOnClick = (e) => {
        e.preventDefault();
        const newCriteria = {
            ...criteria,
            filter: { ...criteria.filter, testProviderIds: [] },
            paging: { ...criteria.paging, pageNum: 1 },
        };
        setSelectedProviders([]);
        setCriteria(newCriteria);
        setStringCriteria(JSON.stringify(newCriteria));
    };

    const handleFilterProviderOnChange = (value) => {
        setSelectedProviders(value ? value.map((x) => x.value) : []);
    };

    const handleEnterKeyPressed = (e) => {
        if (e.key === 'Enter') {
            handleFilterSubmitOnClick(e);
        }
    };

    const handleFilterSubmitOnClick = (e) => {
        e.preventDefault();
        const newCriteria = {
            ...criteria,
            filter: {
                ...criteria.filter,
                testProviderIds:
                    (selectedProviders && selectedProviders.length && selectedProviders) || [],
            },
            paging: { ...criteria.paging, pageNum: 1 },
        };

        setCriteria(newCriteria);
        setStringCriteria(JSON.stringify(newCriteria));
    };

    const editTest = (testId) => {
        props.push(testEditRoute.buildUrl({ id: testId }, props.location.search));
    };

    const customSelectStyles = {
        control: (base) => ({
            ...base,
            minHeight: 55,
            backgroundColor: '#f9f9ff',
            borderColor: '#d2d5ea',
        }),
    };

    const onOpenModal = (test) => {
        setModal(true);
        setCurrentTest(test);
    };

    const onCloseModal = () => {
        setModal(false);
    };

    const valueProviders =
        testProviders && testProviders.filter((x) => selectedProviders.indexOf(x.value) !== -1);

    const filterForm = {
        title: 'Фильтр',
        line: [
            {
                type: 'select',
                label: 'Поставщики тестов',
                value: valueProviders,
                inputId: 'selectedType',
                placeholder: 'Выберите поставщика',
                options: testProviders,
                onChange: handleFilterProviderOnChange,
                onKeyDown: handleEnterKeyPressed,
                isMulti: true,
                styles: customSelectStyles,
            },
        ],
        btnOnClick: handleFilterSubmitOnClick,
        btnClearOnClick: handleFilterClearOnClick,
    };

    const headBtn = {
        onClick: () => {
            props.push({
                pathname: testNewRoute.url,
                search: props.location.search,
            });
        },
        value: 'Добавить',
        addLink: true,
    };

    const testsPayloadArray = (tests, selectedProviders) => {
        if (
            selectedProviders &&
            Array.isArray(selectedProviders) &&
            selectedProviders.length === 0
        ) {
            return tests;
        }

        return (
            tests.filter((x) => testProviders.map((x) => x.value).includes(x.testProviderId)) || []
        );
    };

    let testsPayload = testsPayloadArray(tests, selectedProviders);

    const loading = testProvidersLoading || testsLoading;

    return (
        <Page>
            <div className="TestList_Wrapper">
                {!testProvidersLoading && (
                    <ListTemplate
                        title={'Список оценочных процедур'}
                        headBtn={headBtn}
                        form={filterForm}
                    >
                        <DataGrid
                            data={testsPayload}
                            columns={buildColumns}
                            loading={loading}
                            showPagination={true}
                            showPageSizeOptions={false}
                            foundCount={meta.foundCount}
                            pages={meta.pageCount}
                            page={criteria.paging.pageNum}
                            onPageChange={onPageChange}
                            onSortedChange={onSortedChange}
                            manual
                            pageSize={criteria.paging.pageSize}
                            changePageAmount={changePageAmount}
                        />
                    </ListTemplate>
                )}
            </div>
            {renderModal(currentTest)}
        </Page>
    );
};

const actions = { showErrorAlert, showSuccessAlert, push };

export default connect(null, actions)(TestList);
