import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import {
    filters,
    FILTERS_FILTER_MENU_ID,
    SEARCH_TEMPLATE_MENU_ID,
    MAX_TITLE_LENGTH,
    REGIONS_FILTER_ID
} from './constants';
import SearchFilter from './SearchFilter';
import SaveModalTemplates from './SaveModalTemplates';
import { saveSearchTemplate, updateSearchTemplate } from 'api';
import SearchTemplateList from './SearchTemplateList';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { setFilterId, setMenuId, setRestoreMenuId, setExtraFields } from 'ducks/FilterSearch';
import { queryOperation } from 'components/Search/QueryOperation';
import { FIELDS_TYPE } from './SearchFilters/constants';
import { serviceResultCode, getError } from 'serviceErrors';

const SearchCardFilter = props => {
    const {
        menuId,
        competencies,
        mainFilter,
        setMainFilter,
        getResultAmount,
        clearAllFilters,
        loadTemplates,
        showErrorAlert,
        showSuccessAlert,
        activeTemplate,
        setTemplate,
        setFilterId,
        onSearch,
        cleanActiveTemplate,
        setMenuId,
        setRestoreMenuId,
        restoreMenuId,
        getFilter,
        setExtraFields,
        searchTerm,
        setSearchTerm,
        dynamicSearchTerm,
    } = props;

    const [templateTitle, setTemplateTitle] = useState();
    const [isTempModalOpen, setTemplModalState] = useState(false);
    const [templateMode, setTemplateMode] = useState('new');
    const [mainFilterCopy, setMainFilterCopy] = useState();
    const [rawFilter, setRawFilter] = useState();

    useEffect(() => {
        setMainFilterCopy(mainFilter);
        setExtraFields(true);
        // тут надо только при 1ой загрузке сохранить копию фильтров для последующего сброса
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setTemplateTitle(activeTemplate ? activeTemplate.title : '');
        if (activeTemplate) {
            setRawFilter(activeTemplate.filtersRaw + (activeTemplate.searchTerm || ''));
        } else {
            setRawFilter('');
        }
    }, [activeTemplate]);

    const renderFilterCard = menuId => {
        switch (menuId) {
            case SEARCH_TEMPLATE_MENU_ID:
                return (
                    <SearchTemplateList
                        onSearch={onSearch}
                        loadTemplates={loadTemplates}
                        setTemplate={setTemplate}
                        cleanActiveTemplate={cleanActiveTemplate}
                        dynamicSearchTerm={dynamicSearchTerm}
                    />
                );
            case FILTERS_FILTER_MENU_ID:
                return renderFilters();
            default:
                return null;
        }
    };

    const handleExpanderClick = id => () => {
        setFilterId(id);
    };

    const hideFilter = ({ restoreMenu = false, flushFilter = false, previousFilter = mainFilterCopy }) => {
        setFilterId(null);
        restoreMenu && setMenuId(restoreMenuId === 'none' ? null : restoreMenuId);
        flushFilter && setMainFilter(previousFilter);
        setRestoreMenuId('none');
    };

    const openSaveModal = mode => () => {
        setTemplateMode(mode);
        setTemplModalState(true);

        let title = []
            .concat((searchTerm||'').trim() || [])
            .concat(getShortFilters(mainFilter.filter(c => c)).slice(0, 3))
            .join(', ');
        mode === 'new' &&
            setTemplateTitle(
                (title.length > MAX_TITLE_LENGTH
                    ? title.slice(0, MAX_TITLE_LENGTH - 3).concat('...')
                    : title).trim()
            );
    };

    const closeModal = () => {
        setTemplModalState(false);
        setTemplateTitle(activeTemplate ? activeTemplate.title : '');
    };

    const saveTemplate = async () => {
        if (!templateTitle) {
            showErrorAlert('Необходимо задать название результату поиска');
            return;
        }
        if (templateTitle.length > 128) {
            showErrorAlert('Название не должно превышать 128 символов');
            return;
        }

        try {
            const data = {
                ...activeTemplate,
                title: templateTitle,
                WithExtraFields: props.extraFields,
                filters: getFilter(mainFilter),
                filtersRaw: JSON.stringify(getFilter(mainFilter)),
                searchTerm: searchTerm ? searchTerm : null,
            };
            templateMode === 'new'
                ? await saveSearchTemplate(data)
                : await updateSearchTemplate(activeTemplate.id, data);

            templateMode !== 'new' && setTemplate({ ...activeTemplate });
            setTemplModalState(false);
            setRawFilter(data.filtersRaw + searchTerm);
            showSuccessAlert('Запрос успешно cохранен');
        } catch (error) {
            const reqError = getError(error, getSaveTemplateError);
            showErrorAlert(reqError.message);
        }
    };

    const extractValidationError = errorPayload => {
        if (!errorPayload) {
            return '';
        }

        const errors = Object.values(errorPayload);
        let errorMessages = [];
        if (errors) {
            errors.map(x => x.map(i => errorMessages.push(i.message)));
        }

        return errorMessages.length > 0 && errorMessages.join(', ');
    };

    const getSaveTemplateError = (code, payload) => {
        switch (code) {
            case serviceResultCode.ValidationErrors:
                return extractValidationError(payload);
            default:
                return `Произошла непредвиденная ошибка`;
        }
    };

    const renderFilter = (id, name) => {
        return (
            props.filterId &&
            props.filterId === id && (
                <div className="LKSearchCardVisibleFIlter">
                    <div className="LKSearchCardVisibleFIlter__Content">
                        <SearchFilter
                            filterId={id}
                            competencies={competencies}
                            mainFilter={mainFilter}
                            setMainFilter={setMainFilter}
                            name={name}
                            hideFilter={hideFilter}
                            activeFiltersCount={countActiveFilters(
                                mainFilter.filter(x => x && x.filterId === id),
                            )}
                            getResultAmount={getResultAmount}
                            setMainFilterCopy={setMainFilterCopy}
                            searchTerm={searchTerm}
                            setSearchTerm={setSearchTerm}
                        />
                    </div>
                </div>
            )
        );
    };

    const countActiveFilters = items => {
        return items.reduce((p, c) => {
            if (c.label && !c.parent) {
                return p + 1;
            }
            if (c.childs) {
                p += countActiveFilters(c.childs);
                return p;
            }
            return !c.parent ? p + 1 : p;
        }, 0);
    };

    const flushFilters = () => {
        setMainFilterCopy([]);
        setSearchTerm('');
        cleanActiveTemplate();
        clearAllFilters();
    };

    const showExtraFields = () => {
        props.setExtraFields(true);
    };

    const getShortFilters = filters =>
        filters.reduce((p, c) => {
            if (c.label) {
                if (!!p) return p.concat(`${getShortFilterValue(c, true)}`);
                return `${getShortFilterValue(c, true)}`;
            }
            if (c.childs) {
                if(c.filterId === REGIONS_FILTER_ID && c.parent){
                    const region = getShortFilters(c.childs).join(' ');
                    return p.concat(region);
                }
                return p.concat(getShortFilters(c.childs));
            }
            return !c.parent
                ? !!p
                    ? p.concat(`${getShortFilterValue(c, true)}`)
                    : `${getShortFilterValue(c, true)}`
                : p;
        }, []);

    const selectedFilter = (filters, textOnly) => {
        return filters.reduce((p, c) => {
            if (c.label) {
                if (!!p) return p.concat(`${getShortFilterValue(c, textOnly)}`);
                return `${getShortFilterValue(c, textOnly)}`;
            }
            if (c.childs) {
                return  p.concat(selectedFilter(c.childs));
            }
            return !c.parent
                ? !!p
                    ? p.concat(`${getShortFilterValue(c, textOnly)}`)
                    : `${getShortFilterValue(c, textOnly)}`
                : p;
        }, []);
    };

    const getShortFilterValue = (child, textOnly) => {
        if (textOnly) {
            return correctFilterValue(child).replace(/,/g ,'').trim();
        }
        return child.excludeFromSearch || child.indicateExcludedFromSearch
            ? `<span class='LKSearchCardFilterItem__SelectedFilters--excluded'>${correctFilterValue(
                  child,
              )}</span>`
            : `<span>${correctFilterValue(child)}</span>`;
    };

    const correctFilterValue = child => {
        switch (child.type) {
            case FIELDS_TYPE.date:
                return child.operation.replace('=', '') + child.shortLabel;
            case FIELDS_TYPE.number:
                return child.operation.replace('=', '') + child.value;
            case FIELDS_TYPE.year:
                if (child.operation === queryOperation.GreaterOrEqual) {
                    return '>' + child.shortLabel;
                } else {
                    return '<' + child.shortLabel;
                }
            default:
                return child.label.match(':(.*)') && child.label.match(':(.*)').length > 1
                    ? child.label.match(':(.*)')[1]
                    : child.label;
        }
    };

    const renderFilters = () => {
        if (props.filterId) {
            const filter = filters.filter(x => x.id === props.filterId);
            return renderFilter(filter[0].id, filter[0].name);
        }

        return (
            <>
                {((mainFilter && mainFilter.length !== 0) || searchTerm) && (
                    <>
                        <div className="LKSearchCardClearFilterButton" onClick={flushFilters}>
                            Сброс всех фильтров
                        </div>

                        <button
                            className="LKSearchCardSaveFilterButton"
                            onClick={openSaveModal(activeTemplate ? 'edit' : 'new')}
                            disabled={JSON.stringify(mainFilter) + searchTerm === rawFilter}
                        >
                            Сохранить запрос
                        </button>
                    </>
                )}
                {filters.map(x => {
                    const selectedFilters = selectedFilter(
                        mainFilter.filter(c => c && c.filterId === x.id),
                    );

                    return (
                        <div key={x.id} onClick={handleExpanderClick(x.id)}>
                            <div key={x.id} className="LKSearchCardFilterItem">
                                <div className="LKSearchCardFilterItem__Name">{x.name}</div>
                                <div
                                    className={classnames(
                                        'LKSearchCardFilterItem__SelectedFilters',
                                        {
                                            'LKSearchCardFilterItem__SelectedFilters--hidden': !selectedFilters,
                                        },
                                    )}
                                    dangerouslySetInnerHTML={{ __html: selectedFilters }}
                                ></div>
                                <div className="LKSearchCardFilterItem__Expander" />
                                {renderFilter(x.id, x.name)}
                            </div>
                        </div>
                    );
                })}
                {!props.extraFields && (
                    <div className="LKSearchCardFilterButtons__Item" onClick={showExtraFields}>
                        Показать все поля
                    </div>
                )}
                <SaveModalTemplates
                    isOpen={isTempModalOpen}
                    setTitle={setTemplateTitle}
                    onClose={closeModal}
                    onSave={saveTemplate}
                    title="Сохранить запрос"
                    subTitle={'Задайте или отредактируйте название запроса'}
                    templateTitle={templateTitle}
                />
            </>
        );
    };

    return renderFilterCard(menuId);
};

const mapStateToProps = state => {
    return {
        filterId: state.filterSearch.filterId,
        restoreMenuId: state.filterSearch.restoreMenuId,
        extraFields: state.filterSearch.extraFields,
    };
};

const actions = {
    showErrorAlert,
    showSuccessAlert,
    setFilterId,
    setMenuId,
    setRestoreMenuId,
    setExtraFields,
};

export default connect(
    mapStateToProps,
    actions,
)(withRouter(SearchCardFilter));
