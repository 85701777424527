import React from 'react';
import classnames from 'classnames';
import {Link} from 'react-router-dom'
import { menuItems } from './constants';
import './Menu.scss';

const Menu = props => {
    const { isOnTop, menuSelect, onSelectMenu } = props;

    const onSelectMenuChange = menu => e => {
        e.preventDefault();
        e.stopPropagation();
        if (menu !== menuSelect) {
            onSelectMenu(menu)(e);
        }
    };

    return (
        <div className={classnames('LKProfileMenu', { 'LKProfileMenu--top': isOnTop === true })}>
            {menuItems.map(item => (
                <Link
                    className={classnames('LKProfileMenuItem', {
                        'LKProfileMenuItem--selected': menuSelect === item.link,
                    })}
                    key={item.id}
                    onClick={onSelectMenuChange(item.link)}
                    to={location=>({...location, search:`profileMenuId=${item.link}`})}
                >
                    <div
                        className={classnames(
                            'LKProfileMenuItem__Icon',
                            `LKProfileMenuItem__Icon--${item.link}`,
                        )}
                    />
                    <div className="LKProfileMenuItem__Text">{item.text}</div>
                </Link>
            ))}
        </div>
    );
};

export default Menu;
