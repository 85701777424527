import { TeamWidget } from 'components/Widgets';
import { profileTeams } from 'routes';
import { WIDGET_TYPE_TEAM } from 'constants.js';

export default {
    [WIDGET_TYPE_TEAM]: {
        icon: 'users',
        component: TeamWidget,
        link: profileTeams.url,
        title: 'Команда',
    },
};
