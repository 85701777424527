import React from 'react';
import './DataCard.scss';
import DataCardWrapper from './components/DataCardWrapper';
import PropTypes from 'prop-types';

class DataCard extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: typeof props.defaultIsOpen !== 'undefined' ? props.defaultIsOpen : true,
        };
    }

    render() {
        const wrapperProps = {...this.props};
        delete wrapperProps.defaultIsOpen;
        delete wrapperProps.openCallback;

        return (
            <DataCardWrapper {...wrapperProps} isOpen={this.state.isOpen} onToggle={this.onToggle} />
        );
    }

    onToggle = (e) => {
        e.preventDefault();
        // Необходимо для излечения поведения Sticky.
        const scrollY = window.scrollY;
        window.scrollTo({
            top: scrollY + 1,
        });
        window.scrollTo({
            top: scrollY,
        });
        this.props.openCallback();
        this.setState({ isOpen: !this.state.isOpen });
    };

    handleChange = (e) => {
        e.preventDefault();
        this.props.onChange && this.props.onChange();
    };
}

DataCard.defaultProps = {
    openCallback: () => {},
};

DataCard.propTypes = {
    openCallback: PropTypes.func,
};

export default DataCard;
