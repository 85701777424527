import React from 'react';
import { isMobileWidth } from 'utils.js';
import moment from 'moment';
import Icons from 'components/Lk/Uikit/Icons';
import HeadersMenu from './HeadersMenu';
import { withRouter } from 'react-router';
import { showSuccessAlert, showErrorAlert } from 'ducks/Alert';
import { connect } from 'react-redux';
import { isDevice } from 'utils';
import { printInstructionRoute } from 'api';
import SuspenseImages from 'components/Lk/Common/SuspenseImages';

class Instruction extends React.Component {
    state = { html: null, menu: [], loading: false };

    componentDidMount() {
        this.setHeaders();
    }

    setHeaders() {
        const { item, instructionMenu } = this.props;
        const html = document.createElement('div');
        html.innerHTML = item?.summary;

        const headers = html.querySelectorAll('h1, h2');
        this.addAfterHeaderAnchors(headers);

        const anchors = document.getElementsByClassName('anchor');

        this.setState({ html, menu: instructionMenu }, () => {
            this.setUpListeners(anchors);
        });
    }

    copyAnchorLink = (link) => {
        if (!navigator.clipboard) {
            return () => this.props.showErrorAlert('При копировании ссылки произошла ошибка');
        }

        return () =>
            navigator.clipboard.writeText(link).then(
                () => {
                    this.props.showSuccessAlert('Ссылка успешно скопирована');
                },
                () => this.props.showErrorAlert('При копировании ссылки произошла ошибка'),
            );
    };

    getAnchorWithLastWrod = (id, lastWord) => {
        return `<div class="InstructionCommon--last-word">${lastWord}&nbsp<span id=${id} class="anchor" aria-hidden="true"></span></div>`;
    };

    setUpListeners = (anchors) => {
        anchors.forEach((x) => {
            x.addEventListener(
                'click',
                this.copyAnchorLink(window.location.origin + window.location.pathname + '#' + x.id),
            );
        });
    };

    removeListeners = (anchors) => {
        anchors.forEach((x) => {
            x.removeEventListener(
                'click',
                this.copyAnchorLink(window.location.origin + window.location.pathname + '#' + x.id),
            );
        });
    };

    addAfterHeaderAnchors = (elems) => {
        elems.forEach((x, indx) => {
            const id = `anchor${indx}`;
            const firstWords = x.innerHTML.substring(0, x.innerHTML.lastIndexOf(' '));
            const lastWord = x.innerHTML.substring(x.innerHTML.lastIndexOf(' ') + 1);
            x.innerHTML = firstWords + ' ' + this.getAnchorWithLastWrod(id, lastWord);
        });

        return elems;
    };

    scrollToElem = (hash) => {
        if (!hash) return;
        const elem = document.querySelector(this.props.location?.hash);
        isDevice.SM() && elem.classList.add('mobileAnchor');
        elem.scrollIntoView();
    };

    componentDidUpdate(prevProps) {
        if (prevProps.location?.hash !== this.props.location?.hash) {
            this.scrollToElem(this.props.location?.hash);
        }
        if (prevProps.item !== this.props.item) {
            this.setHeaders();
        }
    }

    componentWillUnmount() {
        const anchors = document.getElementsByClassName('anchor');
        this.removeListeners(anchors);
    }

    onScroll = () => {
        this.props.history.replace('', { hash: '' });
    };

    render() {
        const isMobile = isMobileWidth();
        const { item } = this.props;
        const { html, menu } = this.state;
        const published = moment(item.published);

        return (
            <SuspenseImages
                onComplete={() => this.scrollToElem(this.props.location?.hash)}
                startWaiting={!!html}
            >
                <div className="Instruction" id={item.id} onScroll={this.onScroll}>
                    <div className="Instruction__Header">
                        <h2 className="Instruction__Title">{item.title}</h2>
                        {item && item.published && (
                            <div className="Instruction__PublishDate">
                                Дата публикации&nbsp;{published.format('DD.MM.YYYY')}
                            </div>
                        )}
                    </div>

                    {menu && menu.length > 0 && (
                        <div className="Instruction__Links">
                            <HeadersMenu items={menu} />
                        </div>
                    )}
                    <div
                        className="Clear InstructionCommon"
                        dangerouslySetInnerHTML={{
                            __html: html?.innerHTML,
                        }}
                    />
                    {!isMobile && (
                        <div>
                            <div className="PrintBlock">
                                <a
                                    className="PdfIcon"
                                    rel="noopener noreferrer"
                                    href={printInstructionRoute(item.id)}
                                >
                                    <Icons name="pdf" fill="#051945" altFill="#051945" />
                                    <div className="PrintBlock__Text">Версия для печати</div>
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </SuspenseImages>
        );
    }
}

const actions = { showSuccessAlert, showErrorAlert };
export default connect(null, actions)(withRouter(Instruction));
