import React from "react";
import classnames from "classnames";
import "./InputTextArea.scss";

function InputTextArea(props) {
    const { className, value, forwardRef, clearButton, onClearClick, ...tale } = props;
    
    const finalClass = classnames("InputTextArea", className);
    
    const onClick = (e) => {
        e.preventDefault();
        
        onClearClick();
    };
    
    return (
        <div className="InputTextArea__container">
            <textarea
                {...tale}
                ref={forwardRef}
                type="textarea"
                className={finalClass}
                value={value || ''}
            />
            { clearButton && 
                <button className="InputTextArea__clearBtn" onClick={onClick}>
                    X
                </button>
            }
        </div>
    );
}

export default InputTextArea;
