import { AdaptiveProviderContext } from "components/common/AdaptiveProvider";
import Logo from "components/common/Logo";
import { push } from 'connected-react-router';
import { signOut } from 'ducks/Auth';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fullTextSearch as fullTextSearchRoute } from 'routes';
import { canEditPerson, canImportPerson } from '../../rightsController';
import CommonMenu from './CommonMenu';
import UserMenu from "./UserMenu";
import "./NavMenu.scss";

class NavMenu extends Component {
    static contextType = AdaptiveProviderContext;
    
    state = {
        searchTerm: ''
    };

    search = () => {
        const searchTerm = this.state.searchTerm.trim();

        if (!!searchTerm) {
            this.setState({ searchTerm: '' });
            this.props.push({ 
                pathname: fullTextSearchRoute.url, 
                search: `?searchTerm=${searchTerm}` 
            });
        }
    };
    
    handleChangeSearchTerm = newSearchTerm =>
        this.setState({ searchTerm: newSearchTerm });

    render() {
        const { user, mobileMenuIsOpen, onToggleMobileMenu } = this.props;
        const userName = `${user.firstName} ${user.lastName}`;

        return (
            <React.Fragment>
                <div id="header">
                    <div 
                        onClick={onToggleMobileMenu}
                        className={"kr_menu_link" + (mobileMenuIsOpen ? " open" : "")}
                    >
                        <span />
                        <span />
                        <span />
                    </div>
                    <Logo 
                        svgLogo
                        className="kr_left" 
                    />
                    <UserMenu 
                        className="kr_right"
                        userName={userName}
                        onSignOut={this.props.signOut}
                        defaultOpen={false}
                    />
                </div>
                <CommonMenu 
                    mobileMenuIsOpen={mobileMenuIsOpen}
                    onToggleMobileMenu={onToggleMobileMenu}
                    canEditPerson={canEditPerson(this.props.user)}
                    canImportPerson={canImportPerson(this.props.user)}
                />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.auth.user,
    };
}

const actions = { push, signOut };

export default connect(mapStateToProps, actions)(NavMenu);
