import React from 'react';
import { connect } from 'react-redux';
import { yearMask } from '../../../constants';
import ToggleOpen from 'components/hoc/ToggleOpen';
import ValidBlock from 'components/hoc/ValidBlock';
import { showErrorAlert, showWarningAlert } from 'ducks/Alert';
import { EDUCATIONAL_LEVELS, STUDY_FORMS } from 'ducks/Catalog';
import { yearValidator } from 'libs/validators';
import Select from 'components/uikit/Select';
import { DataCard } from "components/common/DataCard";
import DataList from './DataList';
import Field from "components/uikit/Field";
import Input from "components/uikit/Input";
import InputMask from 'components/uikit/InputMask';
import InputText from "components/uikit/InputText";
import Label from "components/uikit/Label";
import Row from "components/uikit/Row";
import Form from "components/common/Form";
import { fieldsToValidateEducation } from 'constants.js';

class Education extends React.Component {

    ValidatableField = (props) => {
        const {
            fieldKey,
            fieldValue,
            validationError,
            ...tail
        } = props;

        const errorKey = `education_${this.props.indexAsKey}`;
        const validators = [this.validator(fieldValue, validationError)];
        const fieldState = this.getFieldState(fieldKey, fieldValue, validators, errorKey);

        return (
            <Field
                {...fieldState}
                {...tail}
            />
        );
    }

    validator = (value, errorMessage) => () => !!value ? null : errorMessage;

    getFieldState = (fieldKey, fieldValue, validators, errorKey) => {
        const required = this.isValidatedField(fieldKey);

        return {
            required,
            filled: this.props.isInputFilled(fieldValue, required),
            invalid: required && this.props.isInputInvalid(fieldKey, validators, errorKey),
        };
    }

    isValidatedField = (fieldId) => {
        return !!fieldsToValidateEducation.find(elem => elem === fieldId);
    }

    render() {
        const {
            educationLevel,
            studyForm,
            faculty,
            university,
            specialty,
            graduationYear,
            extraInfo,
            id
        } = this.props.value;

        const {
            isInputInvalid,
            isInputFilled,
            keySelector,
            onChange
        } = this.props;

        const index = keySelector(this.props.value);

        return (
            <div className='past-residences-wrapper'>
                <this.ValidatableField
                    fieldKey="educationLevel"
                    fieldValue={educationLevel}
                    validationError="Выберите уровень образования"
                    size="50"
                >
                    <Label htmlFor={`educationLevel_${id}`}>Уровень</Label>
                    <Select
                        inputId={`educationLevel_${id}`}
                        value={educationLevel}
                        onChange={newSelectedLevel => onChange(index, { educationLevel: newSelectedLevel })}
                        options={this.props[EDUCATIONAL_LEVELS].data}
                        catalog
                    />
                </this.ValidatableField>
                <Field
                    filled={isInputFilled(university)}
                    size="50">
                    <Label htmlFor={`university_${id}`}>ВУЗ</Label>
                    <Input
                        id={`university_${id}`}
                        value={university || ''}
                        maxLength={250}
                        onChange={e => onChange(index, { university: e.target.value })}
                    />
                </Field>
                <Field
                    filled={isInputFilled(studyForm)}
                    size="50"
                >
                    <Label htmlFor={`studyForm_${id}`}>Форма обучения</Label>
                    <Select
                        inputId={`studyForm_${id}`}
                        value={studyForm}
                        onChange={newSelectedStudyForm => onChange(index, { studyForm: newSelectedStudyForm })}
                        options={this.props[STUDY_FORMS].data}
                        isClearable
                        catalog
                    />
                </Field>
                <Field
                    filled={isInputFilled(faculty)}
                    size="50">
                    <Label htmlFor={`faculty_${id}`}>Факультет</Label>
                    <Input
                        id={`faculty_${id}`}
                        value={faculty || ''}
                        maxLength={250}
                        onChange={e => onChange(index, { faculty: e.target.value })}
                    />
                </Field>
                <Field
                    filled={isInputFilled(specialty)}
                    size="50">
                    <Label htmlFor={`specialty_${id}`}>Специальность</Label>
                    <Input
                        id={`specialty_${id}`}
                        value={specialty || ''}
                        maxLength={250}
                        onChange={e => onChange(index, { specialty: e.target.value })}
                    />
                </Field>
                <Field
                    invalid={isInputInvalid('graduationYear', [yearValidator()])}
                    filled={isInputFilled(graduationYear)}
                    size="50"
                >
                    <Label htmlFor={`graduationYear_${id}`}>Год окончания</Label>
                    <InputMask
                        mask={yearMask}
                        maskChar={null}
                        id={`graduationYear_${id}`}
                        value={graduationYear || ''}
                        onChange={e => onChange(index, { graduationYear: e.target.value })}
                    />
                </Field>
                <Field
                    filled={isInputFilled(extraInfo)}
                    size="100"
                >
                    <Label htmlFor={`educationExtraInfo_${id}`}>Повышение квалификации и другая информация о дополнительном образовании</Label>
                    <InputText
                        id={`educationExtraInfo_${id}`}
                        maxLength={4000}
                        value={extraInfo || ''}
                        onChange={e => onChange(index, { extraInfo: e.target.value })}
                    />
                </Field>
            </div>
        )
    }
}

class EducationBlock extends React.Component {

    educationKeySelector = (education) => education.indexAsKey;

    addEducation = () => {
        const educations = this.props.educations.push({});
        this.props.handleStateChange({ educations });
    }

    removeEducation = (education) => {
        const educations = this.props.educations.delete(this.educationKeySelector(education));
        this.props.handleStateChange({ educations });
    }

    canRemoveEducation = () => true;

    changeEducation = (index, changes) => {
        const educations = this.props.educations;
        const newEducations = educations.update(index, (education) => ({ ...education, ...changes }));

        this.props.handleStateChange({ educations: newEducations });
    }

    renderEducationBlock = ((education) => {
        return (
            <Education
                {...this.props}
                keySelector={this.educationKeySelector}
                value={education}
                onChange={this.changeEducation}
                isInputFilled={this.props.isInputFilled}
                isInputInvalid={this.props.isInputInvalid}
            />
        );
    });

    render() {
        const {
            isEditBlock,
            educations
        } = this.props;

        return (
            <DataCard
                shadow
                title="Образование"
                className="kr_profile-anketa"
                isOpen={this.props.hasValidationErrors}
            >
                <Form autoComplete={false} className="EditPerson__Form">
                    <Row>
                        <DataList
                            onAdd={this.addEducation}
                            onRemove={this.removeEducation}
                            canRemove={this.canRemoveEducation}
                            entries={educations.map((education, index) => ({ ...education, indexAsKey: index }))}
                            keySelector={this.educationKeySelector}
                            renderEntry={this.renderEducationBlock}
                        />
                    </Row>
                    <Row>{isEditBlock && this.props.renderEditBlock()}</Row>
                </Form>
            </DataCard>
        );
    }
}

const actions = { showWarningAlert, showErrorAlert };

export default connect(null, actions)(ToggleOpen(ValidBlock(EducationBlock)));
