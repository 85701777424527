import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { GROUPS_DICTIONARY_NAME } from 'constants.js';
import { push } from 'connected-react-router';
import { fetchGroup, selectGroup } from 'ducks/Group';
import { groupEdit } from 'routes';
import Page from 'components/common/Page';
import queryString from 'query-string';
import './Group.scss';
import MenuSwitcher from 'components/common/MenuSwitcher';
import { mainMenuItems, mainMenuIds, TYPE_SCOPE } from './constants';
import GroupInfo from './GroupInfo';
import GroupExtraInfo from './GroupExtraInfo';
import { isSecurityAdmin, isITAdmin, isSupportLine1, isSupportLine2 } from 'rightsController';

export class Group extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        group: PropTypes.object,
    };

    state = {
        menu: [],
    };

    filterMenu = () => {
        const { currentUser, group } = this.props;
        const isAdmin = isSecurityAdmin(currentUser) || isITAdmin(currentUser);
        const isSupport = isSupportLine1(currentUser) || isSupportLine2(currentUser);
        const filteredMenu = mainMenuItems.filter(
            x =>
                (group.type !== TYPE_SCOPE.scope || x.scope) &&
                ((isAdmin && x.roles.includes('admin')) ||
                    (isSupport && x.roles.includes('support'))),
        );

        this.setState({ menu: filteredMenu });
    };

    componentDidMount() {
        this.props.fetchGroup(this.props.id);
    }

    componentDidUpdate(prevProps) {
        if (this.props.group !== prevProps.group) {
            this.filterMenu();
        }
    }

    renderTab = () => {
        const { group, location, dictionaries, currentUser } = this.props;

        const { section = mainMenuIds.groupInfo } = queryString.parse(location?.search);

        switch (section) {
            default:
            case mainMenuIds.groupInfo:
                return (
                    <GroupInfo
                        group={group}
                        dictionaries={dictionaries}
                        currentUser={currentUser}
                        editGroup={this.editGroup}
                    />
                );
            case mainMenuIds.quotas:
            case mainMenuIds.experts:
            case mainMenuIds.competencies:
                return <GroupExtraInfo group={group} type={section} />;
        }
    };

    changeUrl = section => {
        const { location, push } = this.props;
        push({
            pathname: location.pathname,
            search: queryString.stringify({ section }),
        });
    };

    render() {
        if (this.props.loading || !this.props.group) {
            return null;
        }

        const { menu } = this.state;
        const { location } = this.props;
        const { section } = queryString.parse(location?.search);

        return (
            <Page>
                <div className="AdminUser">
                    <MenuSwitcher
                        menu={menu}
                        selectedMenuId={section || mainMenuIds.groupInfo}
                        onMenuClick={this.changeUrl}
                        tabs
                        activeTab={this.renderTab()}
                    />
                </div>
            </Page>
        );
    }

    editGroup = () => {
        this.props.push(groupEdit.buildUrl({ id: this.props.id }));
    };

    getParentName = () => {
        const { group, dictionaries } = this.props;
        const groupDict = dictionaries[GROUPS_DICTIONARY_NAME];
        const parentGroup = groupDict.find(g => g.id === group.parentId);

        return parentGroup ? parentGroup.name : '';
    };

    getGroupScopeNames = () => {
        const { group, dictionaries } = this.props;
        const groupDict = dictionaries[GROUPS_DICTIONARY_NAME];
        const groupScopes = groupDict.filter(g =>
            group.groupScopes ? group.groupScopes.includes(g.id) : false,
        );

        return groupScopes ? groupScopes.map(x => x.name).join(', ') : '';
    };
}

function mapStateToProps(state) {
    return selectGroup(state);
}

const actions = {
    fetchGroup,
    push,
};

export default connect(
    mapStateToProps,
    actions,
)(Group);
