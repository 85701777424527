import React from 'react';
import './InstructionList.scss';
import { instructionsView } from 'routes';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { isDevice } from 'utils';
import classnames from 'classnames';
import EmailBlock from 'components/Lk/Common/EmailBlock';
import { helpEmail } from 'constants.js';

class InstructionList extends React.Component {
    state = {
        wrapped: true,
    };

    openInstructionsList = (id = null) => () => {
        return id
            ? this.props.push({
                  pathname: instructionsView.buildUrl({ id: null }),
                  search: `?section=${id}`,
              })
            : undefined;
    };

    openInstruction = (id) => () => {
        this.props.push(instructionsView.buildUrl({ id }));
    };

    toggleWrap = () => this.setState({ wrapped: !this.state.wrapped });

    componentDidMount() {
        this.props.blockStyle && this.setState({ wrapped: false });
    }

    render() {
        const { wrapped } = this.state;
        const { title, items, blockStyle = false } = this.props;
        const instructions = items.filter((x) => x.isArticle);
        return (
            <div
                className={classnames('InstructionList', {
                    'InstructionList--wrapped': isDevice.SM(),
                    'InstructionList--overflowed': blockStyle,
                })}
            >
                <div className="InstructionList__TitleWrapper">
                    <span
                        className={
                            blockStyle ? 'InstructionList__Title--block' : 'InstructionList__Title'
                        }
                        onClick={this.openInstructionsList(instructions[0]?.parentId)}
                    >
                        {title}
                    </span>
                </div>
                {!isDevice.SM() ? (
                    blockStyle ? (
                        <div className="InstructionList__Block">
                            {instructions.map((x) => (
                                <div
                                    key={x.id}
                                    className="InstructionList__ListItemBlock"
                                    onClick={this.openInstruction(x.id)}
                                >
                                    {x.title}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <ul className="InstructionList__List">
                            {instructions.map((x) => (
                                <a key={x.id} href={`${instructionsView.buildUrl({ id: x.id })}`}>
                                    <div className="InstructionList__ListItemWrapper">
                                        <li className="InstructionList__ListItem">{x.title}</li>
                                    </div>
                                </a>
                            ))}
                        </ul>
                    )
                ) : (
                    <div>
                        {blockStyle ? (
                            <div className="InstructionList__Block">
                                {instructions.map((x) => (
                                    <div
                                        key={x.id}
                                        className="InstructionList__ListItemBlock"
                                        onClick={this.openInstruction(x.id)}
                                    >
                                        {x.title}
                                    </div>
                                ))}
                                {isDevice.SM() && blockStyle && (
                                    <div className="InstrctionWrapper__Email">
                                        <EmailBlock
                                            preText="Если у Вас остались вопросы, напишите на"
                                            email={helpEmail}
                                        />
                                    </div>
                                )}
                            </div>
                        ) : (
                            !wrapped && (
                                <ul className="InstructionList__List">
                                    {instructions.map((x) => (
                                        <a
                                            key={x.id}
                                            href={`${instructionsView.buildUrl({ id: x.id })}`}
                                        >
                                            <div className="InstructionList__ListItemWrapper">
                                                <li className="InstructionList__ListItem">
                                                    {x.title}
                                                </li>
                                            </div>
                                        </a>
                                    ))}
                                </ul>
                            )
                        )}
                        {!blockStyle && (
                            <div className="InstructionList__ListAction" onClick={this.toggleWrap}>
                                {wrapped ? 'Развернуть список' : 'Свернуть список'}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

const actions = { push };
export default connect(null, actions)(InstructionList);
