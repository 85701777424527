import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { quotasRoute, quotasNewRoute, quotasEditRoute, quotasViewRoute } from 'routes';
import Quotas from 'pages/manager/moderation/quotas';
import EditQuotas from 'pages/manager/moderation/quotas/EditQuotas';
import NotFound from 'pages/common/NotFound';
import ProtectedRoute from 'components/hoc/ProtectedRoute';
import { ALLOWED_MODERATE_QUOTAS } from 'rightsController';

class QuotasRoutes extends Component {

    renderEditQuotas = ({ match }) => {
        const { params } = match;
        return <EditQuotas id={params.id} />;
    };

    renderNewQuotas = () => {
        return <EditQuotas isNew />;
    };

    renderViewQuotas = ({ match }) => {
        const { params } = match;
        return <EditQuotas id={params.id} fixed />;
    };

    renderNotFound = () => {
        return <NotFound />;
    };

    render() {
        return (
            <Switch>
                <ProtectedRoute exact path={quotasRoute.url} component={Quotas} allowedRoles={ALLOWED_MODERATE_QUOTAS} />             
                <ProtectedRoute exact path={quotasNewRoute.url} component={this.renderNewQuotas} allowedRoles={ALLOWED_MODERATE_QUOTAS} />
                <ProtectedRoute exact path={quotasEditRoute.url} component={this.renderEditQuotas} allowedRoles={ALLOWED_MODERATE_QUOTAS} />
                <ProtectedRoute exact path={quotasViewRoute.url} component={this.renderViewQuotas} allowedRoles={ALLOWED_MODERATE_QUOTAS} />
                <Route component={this.renderNotFound} />
            </Switch>
        );
    }
}

export default QuotasRoutes;