import React from 'react';
import { ACTIVE_MAIN_CARD } from 'components/Lk/Basic/constants';
import LeaderTeams from 'components/Lk/Shared/Teams/LeaderTeams';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';

const TeamsPage = props => {
    const { setActiveCard, personId } = props;
    setActiveCard(ACTIVE_MAIN_CARD);
    
    return (
        <>
            <MainCardMenu main/>
            <LeaderTeams {...props} setActiveCard={setActiveCard} activeCard={ACTIVE_MAIN_CARD} personId={personId} />
        </>
    );
};

export default TeamsPage;