import React, { useState, useEffect, useCallback } from 'react';
import Comment from './Comment';
import ModalPopup from 'components/Lk/Uikit/ModalPopup';
import InfoField from 'components/Lk/Uikit/InfoField';
import WishlistModal from './WishlistModal';
import { isDevice } from 'utils';
import WithKeyboardHandler from 'components/Lk/Hooks/WithKeyboardHandler';
import './CommentsBlock.scss';

const ModalCommentsBlock = (props) => {
    const {
        comments = [],
        isOpen = false,
        closeModal,
        onSubmit = () => {},
        actionButtons = [],
        processing = false,
        commentIdKey = 'id',
        initialComment = '',
        singleComment = false,
        type,
        isFocused,
    } = props;
    const [commentsBlock, setCommentsBlock] = useState(null);
    const [newComment, setNewComment] = useState(initialComment);
    const [currentComment, setCurrenComment] = useState(null);

    useEffect(() => {
        const generateComments = () => {
            return (
                <div className="LKModalCommentsBlock">
                    {comments.map((x) => (
                        <Comment
                            comment={x}
                            key={x[commentIdKey]}
                            onWrap={
                                type === 'wishlist' && x.wishlists?.length > 1 && setCurrenComment
                            }
                        />
                    ))}
                </div>
            );
        };
        setCommentsBlock(generateComments());
    }, [isOpen, comments, commentIdKey, type]);

    useEffect(() => {
        setNewComment(initialComment);
    }, [initialComment]);

    const headerInput =
        currentComment !== null ? (
            <Comment comment={currentComment} />
        ) : (
            <div id="HeaderInput">
                <InfoField
                    isEdit
                    title={singleComment ? '' : 'Добавить заметку'}
                    onChange={(value) => setNewComment(value)}
                    value={newComment}
                    type="textarea"
                    placeholder="Ваша заметка..."
                    maxRows={isDevice.SM() ? 5 : 10}
                    style={{ padding: '0 20px 0 0' }}
                    noIcon
                />
            </div>
        );

    const closeModalCommentsBlock = useCallback(() => {
        if (currentComment == null) {
            closeModal();
            setNewComment(initialComment);
        } else {
            setCurrenComment(null);
        }
    }, [initialComment, closeModal, currentComment]);

    const getHeader = () => {
        if (currentComment !== null) {
            return 'Заметка';
        }
        return singleComment ? 'Добавить заметку' : 'Заметки';
    };

    const body = document.querySelector('#root');

    return (
        <ModalPopup
            isModalOpen={isOpen}
            header={getHeader()}
            headerInput={headerInput}
            confirm={() => onSubmit(newComment)}
            onModalClose={closeModalCommentsBlock}
            actionButtons={actionButtons}
            processing={processing}
            showCloseIcon
            withFooter={currentComment === null}
            fitScreenSize={true}
            enabled={newComment.trim()}
            noChildren={comments.length === 0}
            container={body}
            width="100%"
            noScrollPadding
            unstickFooter={isFocused && isDevice.SM()}
        >
            {currentComment === null ? (
                <>
                    {((isDevice.SM() && !isFocused) || !isDevice.SM()) && (
                        <>
                            {commentsBlock}
                            {props.children}
                        </>
                    )}
                </>
            ) : (
                <WishlistModal comment={currentComment} />
            )}
        </ModalPopup>
    );
};

export default WithKeyboardHandler(ModalCommentsBlock, 'HeaderInput');
