import React from 'react';
import ToggleOpen from 'components/hoc/ToggleOpen';
import { DataCard } from "components/common/DataCard";
import EditFamilyStatusBlock from './Edit/FamilyStatusBlock';

const FamilyStatusBlock = props => {
    const { selectedFamilyStatus, selectedChildrenStatus } = props;

    function renderView() {
        return (
            <DataCard 
                shadow
                title="Семейное положение" 
                className="kr_profile-anketa-view"
                id="profile-module6" 
                onChange={ props.canEditPerson ? () => props.setEditBlock('FamilyStatusBlock') : null }
            >
                <div className="kr_like-table kr_like-table_4cols">
                    <div className="kr_tr">
                        {selectedFamilyStatus && (
                            <>
                                <div className="kr_td">Статус</div>
                                <div className="kr_td">{selectedFamilyStatus.name}</div>
                            </>
                        )}
                        {selectedChildrenStatus && (
                            <>
                                <div className="kr_td">Дети</div>
                                <div className="kr_td">{selectedChildrenStatus.name}</div>
                            </>
                        )}
                    </div>
                    {!selectedFamilyStatus && !selectedChildrenStatus && props.renderEmptyBlock()}
                </div>
            </DataCard>
        );
    }

    function renderEdit() {
        return (
            <EditFamilyStatusBlock {...props} setEditBlock={props.setEditBlock} />
        );
    }

    return props.isEditBlock ? renderEdit() : renderView();

}

export default ToggleOpen(FamilyStatusBlock);