import React, { useState, useMemo, useEffect } from 'react';
import './EvaluteScaleInfo.scss';
import ChartTooltip from '../Profile/ProfileEvaluate/Charts/ChartTooltip';
import ScaleTooltip from '../Profile/ProfileEvaluate/Charts/ScaleTooltip';
import { EVALUATION_TYPE } from 'components/Lk/Basic/constants';
import ChevronButton from 'components/Lk/Uikit/Buttons/ChevronButton';

const EvaluteScaleInfo = props => {
    const { test, testId } = props;
    const [isOpen, setIsOpen] = useState(false);

    const evaluteType = EVALUATION_TYPE.find(x => x.id === testId);

    useEffect(() => {
        setIsOpen(false);
    }, [test, testId]);

    const chart = useMemo(() => {
        if (!test || test.visualizationKind !== 'Signals') {
            return <ScaleTooltip type={evaluteType && evaluteType.legend} />;
        }

        return <ChartTooltip criteria={test.criteria} values={test.criteriaOrder} />;
    }, [test, evaluteType]);

    const onToggle = () => {
        setIsOpen(!isOpen);
    };

    if (evaluteType && evaluteType.legend === false) {
        return null;
    }

    return (
        <>
            <div className="EvaluteScaleInfo" onClick={onToggle}>
                <div className="EvaluteScaleInfo__Wrapper">
                    <span className="EvaluteScaleInfo__Text">Описание шкалы оценки</span>
                    <ChevronButton isUp={isOpen} color="blue" />
                </div>
                {isOpen && <div className="EvaluteScaleInfo__Description">{chart}</div>}
            </div>
        </>
    );
};

export default EvaluteScaleInfo;
