import { all, call, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import uuid from 'uuid/v4';
import { push } from 'connected-react-router';
import {
    getPerson,
    putPerson,
    photoSizes as apiPhotoSizes,
    uploadPhoto,
    getPersonFileUrl as getPersonFileUrlApi,
    getTempFileUrl as getTempFileUrlApi,
    uploadPersonFile as uploadPersonFileApi,
    uploadTempFile as uploadTempFileApi,
    deletePersonFile as deletePersonFileApi,
    deleteTempFile as deleteTempFileApi,
    addPersonToDefaultWishlist,
    removePersonFromDefaultWishlist,
    getAttributeAttachmentInfo,
    evaluationTestResultsByPersonId,
    getEvaluationRequestsByPersonId,
    getPersonComments,
    deleteComment,
    setPersonsComments,
} from 'api.js';
import {
    appName,
    defaultDocumentCode,
    defaultForeignDocumentCode,
    GROUPS_DICTIONARY_NAME,
    GEO_LEVEL_DICT,
    EMPLOYEE_LEVEL_DICT,
} from '../constants';
import RequestError from '../RequestError';
import { notFound, person as personRoute, forbidden } from 'routes';
import { serviceResultCode, getError, personValidationCodes } from 'serviceErrors';
import { showErrorAlert, showSuccessAlert } from './Alert';
import {
    CHILDREN_STATUS,
    COUNTRIES,
    EDUCATIONAL_LEVELS,
    EMPLOYEES_NUMBERS,
    FAMILY_STATUS,
    FEDERAL_DISTRICTS,
    fetchCatalog,
    IDENTITY_DOCUMENTS,
    INDUSTRIES,
    LANGUAGES,
    LANGUAGE_LEVELS,
    MANAGEMENT_EXPERIENCES,
    MANAGEMENT_LEVELS,
    REGIONS,
    REGION_LOCALITIES,
    SEX,
    SOCIAL_NETWORKS,
    WORK_AREAS,
    DATA_ORIGINS,
    EXPERIENCE_LEVELS,
    GOVT_SERVICE_KINDS,
    COMPETITION_LEVELS,
    STUDY_FORMS,
} from './Catalog';
import { fetchDictionaries, isDictionariesLoaded, selectDictionaries } from './Dictionary';
import { withPageLoader } from './PageLoader';
import { fetchAccess } from './Access';
import { setWishlistPersonsUpdated } from './Wishlists';
import { isModerator } from 'rightsController';
import { changePersonsData } from './ExternalSearch';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';

//TODO: см комменты ниже
//import { fetchTeams } from "./Teams";

const moduleName = 'person';
export const NEW_PERSON = `${appName}/${moduleName}/NEW_PERSON`;
export const FETCH_REQUEST = `${appName}/${moduleName}/FETCH_REQUEST`;
export const FETCH_START = `${appName}/${moduleName}/FETCH_START`;
export const FETCH_SUCCESS = `${appName}/${moduleName}/FETCH_SUCCESS`;
export const FETCH_FAILED = `${appName}/${moduleName}/FETCH_FAILED`;
export const SAVE_REQUEST = `${appName}/${moduleName}/SAVE_REQUEST`;
export const SAVE_START = `${appName}/${moduleName}/SAVE_START`;
export const SAVE_SUCCESS = `${appName}/${moduleName}/SAVE_SUCCESS`;
export const SAVE_FAILED = `${appName}/${moduleName}/SAVE_FAILED`;
export const SAVE_PHOTO = `${appName}/${moduleName}/SAVE_PHOTO`;
export const SAVE_PHOTO_SUCCESS = `${appName}/${moduleName}/SAVE_PHOTO_SUCCESS`;
export const SAVE_PHOTO_FAILED = `${appName}/${moduleName}/SAVE_PHOTO_FAILED`;
export const SAVE_CARD_REFS = `${appName}/${moduleName}/SAVE_CARD_REFS`;
export const TOGGLE_PERSON_IN_WISHLIST = `${appName}/${moduleName}/TOGGLE_PERSON_IN_WISHLIST`;
export const FETCH_TESTS_RESULTS = `${appName}/${moduleName}/FETCH_TESTS_RESULTS`;
export const CHANGE_DATA = `${appName}/${moduleName}/CHANGE_DATA`;
export const SET_MERGE_DATA = `${appName}/${moduleName}/SET_MERGE_DATA`;
export const SET_IS_MERGE_NEXT = `${appName}/${moduleName}/SET_IS_MERGE_NEXT`;
export const SET_SNILS_IS_OK = `${appName}/${moduleName}/SET_SNILS_IS_OK`;
export const SET_SHOW_SNILS_ERROR = `${appName}/${moduleName}/SET_SHOW_SNILS_ERROR`;
export const CLEAR_PERSON = `${appName}/${moduleName}/CLEAR_PERSON`;

const FETCH_COMMENTS_START = `${appName}/${moduleName}/FETCH_COMMENTS_START`;
const FETCH_COMMENTS_END = `${appName}/${moduleName}/FETCH_COMMENTS_END`;
const SAVE_COMMENTS = `${appName}/${moduleName}/SAVE_COMMENTS`;
const ADD_COMMENT = `${appName}/${moduleName}/ADD_COMMENT`;
const REMOVE_COMMENT_START = `${appName}/${moduleName}/REMOVE_COMMENT_START`;
const REMOVE_COMMENT = `${appName}/${moduleName}/REMOVE_COMMENT`;

export const personalInfoBlockCatalogs = [
    FEDERAL_DISTRICTS,
    REGIONS,
    REGION_LOCALITIES,
    IDENTITY_DOCUMENTS,
    SEX,
    COUNTRIES,
];
export const educationBlockCatalogs = [EDUCATIONAL_LEVELS, STUDY_FORMS];
export const workInfoBlockCatalogs = [
    INDUSTRIES,
    WORK_AREAS,
    MANAGEMENT_LEVELS,
    MANAGEMENT_EXPERIENCES,
    EMPLOYEES_NUMBERS,
];
export const governmentServiceInfoCatalogs = [GOVT_SERVICE_KINDS];
export const languagesBlockCatalogs = [LANGUAGES, LANGUAGE_LEVELS];
export const socialNetworksBlockCatalogs = [SOCIAL_NETWORKS];
export const familyStatusBlockCatalogs = [FAMILY_STATUS, CHILDREN_STATUS];
export const foreignerBlockCatalogs = [COUNTRIES];
export const personnelInfoBlockCatalogs = [DATA_ORIGINS, EXPERIENCE_LEVELS, COMPETITION_LEVELS];

export const allPersonCatalogs = [
    ...personalInfoBlockCatalogs,
    ...educationBlockCatalogs,
    ...workInfoBlockCatalogs,
    ...governmentServiceInfoCatalogs,
    ...languagesBlockCatalogs,
    ...socialNetworksBlockCatalogs,
    ...familyStatusBlockCatalogs,
    ...foreignerBlockCatalogs,
    ...personnelInfoBlockCatalogs,
].filter((x, index, arr) => arr.indexOf(x) === index);

export const allPersonDictionaries = [GROUPS_DICTIONARY_NAME];

const cacheTimeoutInSeconds = 60;

const initialState = {
    loading: false,
    loadComplete: false,
    loadTime: new Date(0),
    saving: false,
    id: null,
    updatingPhoto: false,
    data: {},
    error: '',
    cardRef: {
        cardRefs: {},
        evaluationRefs: {},
    },

    comments: {
        data: [],
        meta: {},
        hasMore: false,
        isLoaded: false,
        loading: false,
        criteria: { pageNum: 1, pageSize: 10 },
    },

    mergeData: null,
    isMergeNext: false,
    snilsIsOk: true,
    showSnilsError: false,
};

export default function reducer(state = initialState, action) {
    const { type, payload, error } = action;

    switch (type) {
        case FETCH_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_START:
            return {
                ...state,
                loading: true,
                id: payload.id,
                loadComplete: false,
            };

        case FETCH_SUCCESS:
        case SAVE_SUCCESS:
            return {
                ...state,
                saving: false,
                loading: false,
                loadComplete: true,
                loadTime: payload.loadTime,
                id: payload.data.id,
                data: payload.data,
            };

        case FETCH_FAILED:
            return {
                ...state,
                saving: false,
                loading: false,
                loadComplete: true,
                loadTime: payload.loadTime,
                id: null,
                error: error.message,
            };

        case SAVE_REQUEST:
            return {
                ...state,
                saving: true,
                error: null,
            };

        case SAVE_START:
            return {
                ...state,
                id: payload.id,
            };

        case SAVE_FAILED:
            return {
                ...state,
                saving: false,
                loadTime: payload.loadTime,
                id: payload.id,
                error: error.message,
            };

        case SAVE_PHOTO: {
            const { id } = action.payload;

            if (state.data.id === id) {
                return {
                    ...state,
                    updatingPhoto: true,
                };
            }

            return state;
        }

        case SAVE_PHOTO_SUCCESS: {
            const { id } = action.payload;

            if (state.data.id === id) {
                return {
                    ...state,
                    updatingPhoto: false,
                    refetchKey: new Date().getTime(),
                };
            }

            return state;
        }

        case SAVE_PHOTO_FAILED: {
            const { id } = action.payload;

            if (state.data.id === id) {
                return {
                    ...state,
                    updatingPhoto: false,
                    refetchKey: new Date().getTime(),
                };
            }

            return state;
        }
        case SAVE_CARD_REFS: {
            const { cardRefs, evaluationRefs } = action.payload;

            state = {
                ...state,
                cardRef: {
                    cardRefs,
                    evaluationRefs,
                },
            };

            return state;
        }

        case CHANGE_DATA: {
            const { key, value } = action.payload;

            state = {
                ...state,
                data: {
                    ...state.data,
                    [key]: value,
                },
            };

            return state;
        }

        case REMOVE_COMMENT_START:
        case SAVE_COMMENTS:
            return { ...state, comments: { ...state.comments, loading: true } };
        case ADD_COMMENT:
            const item = payload.find((x) => x.personId === state.id);
            return {
                ...state,
                comments: {
                    ...state.comments,
                    loading: false,
                    data: item
                        ? [item].concat(state.comments.data.filter((x) => x.id !== item.id))
                        : state.comments.data,
                },
            };

        case FETCH_COMMENTS_START:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    data: payload.isMore ? state.comments.data : [],
                    hasMore: false,
                    criteria: { ...state.comments.criteria, ...payload.criteria },
                    isLoaded: payload.isMore ? true : false,
                    loading: true,
                },
            };
        case FETCH_COMMENTS_END:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    data: (payload.isMore ? state.comments.data : [])?.concat(
                        payload.comments.payload,
                    ),
                    meta: { ...payload.comments.meta },
                    hasMore:
                        payload.comments.meta.pageCount > state.comments.criteria.pageNum
                            ? true
                            : false,
                    isLoaded: true,
                    loading: false,
                },
            };

        case REMOVE_COMMENT:
            return {
                ...state,
                comments: {
                    ...state.comments,
                    loading: false,
                    data: state.comments.data.filter((x) => x.id !== payload),
                },
            };

        case SET_MERGE_DATA:
            const { mergeData } = payload;
            return {
                ...state,
                mergeData,
            };
        case SET_IS_MERGE_NEXT:
            const { isMergeNext } = payload;
            return {
                ...state,
                isMergeNext,
            };
        case SET_SNILS_IS_OK:
            const { snilsIsOk } = payload;
            return {
                ...state,
                snilsIsOk,
            };

        case SET_SHOW_SNILS_ERROR:
            const { showSnilsError } = payload;
            return {
                ...state,
                showSnilsError,
            };

        case CLEAR_PERSON:
            return {
                ...state,
                id: null,
            };

        default:
            return state;
    }
}

export const newPerson = () => {
    return {
        type: NEW_PERSON,
    };
};

export const fetchComments = (personId, criteria, isMore = false) => ({
    type: FETCH_COMMENTS_START,
    payload: { personId, criteria, isMore },
});

export const saveComments = (ids, forceUpdate, comment) => ({
    type: SAVE_COMMENTS,
    payload: { ids, comment, forceUpdate },
});

export const removeComment = (id) => ({
    type: REMOVE_COMMENT_START,
    payload: id,
});

export const remove = (id) => ({
    type: REMOVE_COMMENT,
    payload: id,
});

export const addComment = (payload) => ({
    type: ADD_COMMENT,
    payload,
});

const setComments = (comments, isMore = false) => ({
    type: FETCH_COMMENTS_END,
    payload: { comments, isMore },
});

export const fetchPerson = (
    id,
    photoSizes = [apiPhotoSizes.small],
    showLoader = false,
    redirect = true,
) => {
    return {
        type: FETCH_REQUEST,
        payload: { id, photoSizes },
        showLoader: showLoader,
        redirect,
    };
};

export const fetchStart = (id) => {
    return {
        type: FETCH_START,
        payload: { id },
    };
};

export const fetchSuccess = (data, loadTime) => {
    return {
        type: FETCH_SUCCESS,
        payload: { data, loadTime },
    };
};

export const fetchFailed = (id, error, loadTime) => {
    return {
        type: FETCH_FAILED,
        payload: { id, loadTime },
        error,
    };
};

export const savePerson = (person) => {
    return {
        type: SAVE_REQUEST,
        payload: { person },
    };
};

export const saveStart = (id) => {
    return {
        type: SAVE_START,
        payload: { id },
    };
};

export const saveSuccess = (data, loadTime) => {
    return {
        type: SAVE_SUCCESS,
        payload: { data, loadTime },
    };
};

export const saveFailed = (id, error, loadTime) => {
    return {
        type: SAVE_FAILED,
        payload: { id, loadTime },
        error,
    };
};

export const savePhoto = (id, file) => {
    return {
        type: SAVE_PHOTO,
        payload: { id, file },
    };
};

export const savePhotoSuccess = (id) => {
    return {
        type: SAVE_PHOTO_SUCCESS,
        payload: { id },
    };
};

export const savePhotoFailed = (id) => {
    return {
        type: SAVE_PHOTO_FAILED,
        payload: { id },
    };
};

export const saveCardRefs = (cardRefs, evaluationRefs) => {
    return {
        type: SAVE_CARD_REFS,
        payload: { cardRefs, evaluationRefs },
    };
};

export const togglePersonInWishlist = (personId) => {
    return {
        type: TOGGLE_PERSON_IN_WISHLIST,
        payload: { personId },
    };
};

export const fetchTestsResultsByPersonId = (personId) => {
    return {
        type: FETCH_TESTS_RESULTS,
        payload: { personId },
    };
};

export const changeData = (key, value, clear) => {
    return {
        type: CHANGE_DATA,
        payload: { key, value, clear },
    };
};

export const setMergeData = (mergeData) => {
    return {
        type: SET_MERGE_DATA,
        payload: { mergeData },
    };
};

export const setIsMergeNext = (isMergeNext) => {
    return {
        type: SET_IS_MERGE_NEXT,
        payload: { isMergeNext },
    };
};

export const setSnilsIsOk = (snilsIsOk) => {
    return {
        type: SET_SNILS_IS_OK,
        payload: { snilsIsOk },
    };
};

export const setShowSnilsError = (showSnilsError) => {
    return {
        type: SET_SHOW_SNILS_ERROR,
        payload: { showSnilsError },
    };
};

export const clearPerson = () => {
    return {
        type: CLEAR_PERSON,
    };
};

export const allActions = {
    newPerson,
    fetchPerson,
    fetchStart,
    fetchSuccess,
    fetchFailed,
    savePerson,
    saveStart,
    saveSuccess,
    saveFailed,
    savePhoto,
    saveCardRefs,
    fetchTestsResultsByPersonId,
};

export function* fetchDependencies() {
    yield put(fetchDictionaries(allPersonDictionaries));
    yield all(allPersonCatalogs.map((c) => put(fetchCatalog(c, null, true))));
}

const updateFilesAttributesInfo = async (customAttributes, personId) => {
    let promiseArray = customAttributes.map(async (x) => {
        if (x.attribute.type === 'file') {
            const fileInfo = await getAttributeAttachmentInfo(personId, x.id, x.attachmentId);
            return { ...x, fileInfo: fileInfo.data };
        } else {
            return x;
        }
    });

    try {
        const attributes = await Promise.all(promiseArray);
        return attributes;
    } catch {
        return null;
    }
};

export const newPersonSaga = function* () {
    yield put(setSnilsIsOk(false));
    yield call(fetchDependencies);
};

export const fetchPersonSaga = function* (action) {
    yield call(fetchDependencies);
    const { id, photoSizes = [apiPhotoSizes.small] } = action.payload;
    yield put(fetchStart(id));

    try {
        yield !!action.showLoader && put(showPageLoader());
        const response = yield call(getPerson(photoSizes), id);
        yield put(fetchAccess(id));

        let personData = response.data;
        const attributes = yield call(
            updateFilesAttributesInfo,
            personData.customAttributes,
            personData.id,
        );

        const requestsData = yield getEvaluationRequestsByPersonId(personData.id);
        const testsResults = yield evaluationTestResultsByPersonId(personData.id);

        personData = {
            ...personData,
            customAttributes: attributes,
            requestsData,
            testsResults,
        };

        const data = {
            ...personData,
            files: (personData.files || []).map((f) => ({ ...f, localKey: f.id })),
        };

        yield put(fetchSuccess(data, new Date()));

        const snilsIsOk = personData.isRussianCitizen && !personData.snils ? false : true;
        yield put(setSnilsIsOk(snilsIsOk));
    } catch (error) {
        const reqError = new RequestError(error, 'При загрузке резервиста произошла ошибка');
        yield put(fetchFailed(id, reqError, new Date()));
        if (error.response) {
            if (error.response.status === 404) {
                yield action.redirect
                    ? put(push(notFound.buildUrl()))
                    : showErrorAlert('Резервист не найден');
            }

            if (error.response.status === 403) {
                yield action.redirect
                    ? put(push(forbidden.buildUrl()))
                    : showErrorAlert('Резервист недоступен');
            }
        } else {
            yield put(showErrorAlert(reqError.message));
        }
    } finally {
        yield !!action.showLoader && put(hidePageLoader());
    }
};

export const savePersonSaga = function* (action) {
    const { person } = action.payload;
    person.birthDate = person.birthDate && person.birthDate.toDateString();

    const id = person.id;
    const state = yield select();
    const currentLocation = state.router.location;

    yield put(saveStart(id));

    const isLocationChanged = (state) => currentLocation !== state.router.location;
    const isPersonChanged = (state) => state.person.id !== id;
    try {
        const response = yield call(withPageLoader, () => putPerson(person));
        const savedPerson = response.data;
        const state = yield select();

        if (!isPersonChanged(state)) {
            yield put(saveSuccess(savedPerson, new Date()));
        }

        if (!isLocationChanged(state)) {
            yield put(push(personRoute.buildUrl({ id: savedPerson.id })));
        }
    } catch (error) {
        const reqError = getError(error, getPersonError);

        if (
            error.response?.data?.payload?.person[0]?.code === personValidationCodes.snilsNotValid
        ) {
            yield put(setShowSnilsError(true));
            window.scroll(0, 0);
        }

        if (!isPersonChanged(yield select())) {
            yield put(saveFailed(id, reqError, new Date()));
        }
        yield put(showErrorAlert(reqError.message));
    }
};

const getPersonError = (code, payload) => {
    switch (code) {
        case serviceResultCode.PersonPhoneIsInvalid:
            return 'Проверьте правильность введенного номера телефона';
        case serviceResultCode.PersonEmailAlreadyExists:
            return 'Резервист с таким Email уже существует в системе';
        case serviceResultCode.PersonAlreadyExists:
            return 'Резервист с таким ФИО и датой рождения уже существует в системе';
        case serviceResultCode.PersonBirthDateIsNotRange:
            return 'Возраст резервиста должен быть от 14 до 100 лет';
        case serviceResultCode.SocialNetworkLinkIsNotValid:
            return payload;
        case serviceResultCode.AttributeLinkIsNotValid:
            return payload;
        case serviceResultCode.ValidationErrors:
            if (payload?.person[0]?.message) {
                return payload.person[0].message;
            }
            return 'Проверьте правильность введенных данных';
        default:
            return 'При сохранении резервиста произошла ошибка';
    }
};

const savePhotoSaga = function* (action) {
    const { id, file } = action.payload;

    try {
        yield call(uploadPhoto, id, file);
        yield put(savePhotoSuccess(id));
    } catch (error) {
        if (error.response.status === 422) {
            const fileErrorCodes = error.response.data;
            if (fileErrorCodes && fileErrorCodes.formatUnsupported) {
                yield put(
                    showErrorAlert(
                        'Неподдерживаемый формат изображения. Поддерживаются форматы JPEG и PNG',
                    ),
                );
            } else if (fileErrorCodes && fileErrorCodes.tooLarge) {
                let maxSize = (fileErrorCodes.tooLarge.maxSize / (1024 * 1024)).toFixed(2);
                yield put(showErrorAlert(`Фото превышает лимит в ${maxSize} Мб`));
            } else {
                yield put(showErrorAlert('При загрузке фото произошла ошибка'));
            }
        } else {
            yield put(showErrorAlert('При загрузке фото произошла ошибка'));
        }

        yield put(savePhotoFailed(id));
    }
};

function* togglePersonInWishlistSaga(action) {
    const { personId } = action.payload;
    const { isFavorite } = yield select((state) => state.person.data);

    try {
        if (isFavorite) {
            yield call(removePersonFromDefaultWishlist, personId);
            yield put(changeData('isFavorite', false));
        } else {
            yield call(addPersonToDefaultWishlist, personId);
            yield put(changeData('isFavorite', true));
        }
        yield put(setWishlistPersonsUpdated({ isWishlistPersonsUpdated: true }));
    } catch (error) {
        yield put(
            showErrorAlert(
                `Ошибка при ${
                    isFavorite
                        ? 'удалении резервиста из избранного'
                        : 'добавлении резервиста в избранное'
                }`,
            ),
        );
    }
}

function* fetchTestsResultsByPersonIdSaga(action) {
    const { personId } = action.payload;
    try {
        if (personId) {
            const testsResults = yield call(evaluationTestResultsByPersonId, personId);
            yield put(changeData('testsResults', testsResults));
        }
    } catch (error) {
        yield put(showErrorAlert(`Ошибка при получении результатов тестов резервиста`));
    }
}

function* removeCommentSaga({ payload }) {
    try {
        const response = yield call(deleteComment, payload);

        yield put(remove(payload));
        yield put(changePersonsData([response.data]));
        yield put(showSuccessAlert('Заметка успешно удалена'));
    } catch (error) {
        const reqError = new RequestError(error, 'При удалении комментария произошла ошибка');
        yield put(showErrorAlert(reqError.message));
    }
}

function* fetchCommentsSaga({ payload = {} }) {
    try {
        const { criteria, personId, isMore } = payload;
        if (!personId) {
            return;
        }
        const response = yield call(getPersonComments, personId, criteria);
        yield put(setComments(response.data, isMore));
    } catch (error) {
        if (error && error.response && error.response.status === 404) {
            return;
        }

        const reqError = new RequestError(error, 'При загрузке комментариев произошла ошибка');
        yield put(showErrorAlert(reqError.message));
    }
}

function* saveCommentsSaga({ payload }) {
    try {
        const { ids, comment, forceUpdate } = payload;

        const response = yield call(
            setPersonsComments,
            ids,
            forceUpdate,
            comment.text,
            comment.visibleToAll,
        );
        yield put(addComment(response.data));
        yield put(changePersonsData(ids.map((id) => ({ personId: id, hasComment: true }))));
        yield put(showSuccessAlert('Заметка успешно сохранена'));
    } catch (error) {
        const reqError = new RequestError(error, 'При сохранении комментариев произошла ошибка');
        yield put(showErrorAlert(reqError.message));
    }
}

export function* saga() {
    yield all([
        takeLatest(FETCH_REQUEST, fetchPersonSaga),
        takeLeading(NEW_PERSON, newPersonSaga),
        takeEvery(SAVE_REQUEST, savePersonSaga),
        takeEvery(SAVE_PHOTO, savePhotoSaga),
        takeEvery(TOGGLE_PERSON_IN_WISHLIST, togglePersonInWishlistSaga),
        takeEvery(FETCH_TESTS_RESULTS, fetchTestsResultsByPersonIdSaga),
        takeLatest(REMOVE_COMMENT_START, removeCommentSaga),
        takeLatest(FETCH_COMMENTS_START, fetchCommentsSaga),
        takeLatest(SAVE_COMMENTS, saveCommentsSaga),
    ]);
}

export const getPersonFileUrl = (personId) => (fileId) =>
    personId > 0 ? getPersonFileUrlApi(personId, fileId) : getTempFileUrlApi(fileId);

export const uploadPersonFile = (personId) => (file, onUploadProgress = () => {}) =>
    personId > 0
        ? uploadPersonFileApi(personId, file, onUploadProgress)
        : uploadTempFileApi(file, onUploadProgress);

export const deletePersonFile = (personId) => (fileId) =>
    personId > 0 ? deletePersonFileApi(personId, fileId) : deleteTempFileApi(fileId);

const isPersonActual = (person) => {
    return (
        person.loadComplete &&
        !person.error &&
        (Date.now() - person.loadTime) / 1000 < cacheTimeoutInSeconds
    );
};

const isCatalogsLoaded = (allCatalogs) => {
    for (let catalogName of allPersonCatalogs) {
        const catalog = allCatalogs[catalogName];
        if (catalog === null) {
            continue;
        }

        if (!catalog.loadComplete) {
            return false;
        }
    }
    return true;
};

export function isForeignDocument(document) {
    return document && document.code === defaultForeignDocumentCode;
}

export const isPersonEducationEnabled = (state) => {
    const {
        config,
        auth: { user },
    } = state;

    const features = config && config.data && config.data.features;

    if (!features) {
        return false;
    }

    const { personEducation: isEnabledPersonEducation } = features;
    if (!isEnabledPersonEducation) {
        return false;
    }

    return isEnabledPersonEducation && isModerator(user);
};

export function newPersonSelector(state) {
    setSnilsIsOk(false);
    if (
        !isCatalogsLoaded(state.catalogs) ||
        !isDictionariesLoaded(state.dictionary, allPersonDictionaries)
    ) {
        return { loadComplete: false };
    }

    const dictionaries = selectDictionaries(state.dictionary, allPersonDictionaries);

    return {
        loadComplete: true,
        isActual: true,
        personalInfo: {
            isRussianCitizen: true,
            document: state.catalogs[IDENTITY_DOCUMENTS].data.find(
                (x) => x.code === defaultDocumentCode,
            ),
            pastResidences: [],
        },
        educationInfo: {
            educations: [
                {
                    educationLevel: null,
                    studyForm: null,
                },
            ],
        },
        workInfo: {
            workPlaces: [
                {
                    isCurrentWorkPlace: false,
                    industry: null,
                    workArea: null,
                    managementLevel: null,
                    managementExperience: null,
                    employeesNumber: null,
                },
            ],
        },
        governmentServiceInfo: {
            governmentServices: [],
        },
        evaluationInfo: {
            evaluations: [],
        },
        languagesInfo: {
            knownLanguages: [
                {
                    _id: uuid(),
                    language: null,
                    languageLevel: null,
                    fileAttachmentId: null,
                },
            ],
        },
        socialNetworksInfo: {
            networks: [],
        },
        personnelInfo: {
            awards: [],
        },
        filesInfo: {
            files: [],
        },
        attributesInfo: { attributes: [] },
        filesDirectoryId: uuid(),
        systemInfo: {
            groups: dictionaries.groups,
            groupId: null,
        },
    };
}

export const personFullSelector = (state, id) => {
    const { person, catalogs: allCatalogs } = state;

    const staticCatalogs = { ...state.catalogs, [REGION_LOCALITIES]: null };

    if (
        !person.loadComplete ||
        !isCatalogsLoaded(staticCatalogs) ||
        !isDictionariesLoaded(state.dictionary, allPersonDictionaries)
    ) {
        return { loadComplete: false };
    }

    let {
        educationInfo,
        workPlaces,
        governmentServices,
        languages,
        socialNetworks,
        files,
        filesDirectoryId,
        customAttributes,
        personnelInfo,
        evaluations,
        groupId,
        requestsData,
        testsResults,
        user,
        snils,
        ...personalInfo
    } = person.data;

    // Так как с сервера приходит null, то дефолтные значения не срабатывают
    files = files || [];
    socialNetworks = socialNetworks || [];
    languages = languages || [];
    customAttributes = customAttributes || [];
    personalInfo = personalInfo || {};
    const pastResidences = personalInfo.pastResidences || [];

    // эти блоки должны быть заполнены, но при первом импорте много данных не было. Чтоб не ломалось отображение
    educationInfo = educationInfo || [];
    workPlaces = workPlaces || [];
    personnelInfo = personnelInfo || {};
    evaluations = evaluations || [];
    requestsData = requestsData || {};
    testsResults = testsResults || {};
    user = user || {};

    const getCatalogItem = (catalogName, itemId) => {
        return itemId && allCatalogs[catalogName].indexedData[itemId];
    };

    const getPastResidences = (pastResidences = []) => {
        return pastResidences.map((item) => {
            return {
                ...item,
                selectedDistrict: getCatalogItem(FEDERAL_DISTRICTS, item.federalDistrictId),
                selectedRegion: getCatalogItem(REGIONS, item.regionId),
                selectedLocality: item?.locality,
                regionLocalities: [],
            };
        });
    };

    function getAwards(awards) {
        return awards.map((award) => ({
            ...award,
            selectedCompetitionLevel: getCatalogItem(COMPETITION_LEVELS, award.competitionLevel),
        }));
    }

    const dictionaries = selectDictionaries(state.dictionary, allPersonDictionaries);

    return {
        loadComplete: true,
        updatingPhoto: person.updatingPhoto,
        refetchKey: person.refetchKey,
        isActual: id === person.id && isPersonActual(person),
        saveInProgress: person.saving,
        mergeData: null,
        personalInfo: {
            ...personalInfo,
            snils,
            documentNumber: personalInfo.documentNumber ? personalInfo.documentNumber.trim() : '',
            sex: getCatalogItem(SEX, personalInfo.sex),
            birthDate: personalInfo.birthDate ? new Date(personalInfo.birthDate) : null,
            birthDateDisplayMode: personalInfo.birthDateDisplayMode,
            federalDistrict: getCatalogItem(FEDERAL_DISTRICTS, personalInfo.federalDistrictId),
            region: getCatalogItem(REGIONS, personalInfo.regionId),
            locality: personalInfo.locality,
            localityId: personalInfo.localityId,
            document: getCatalogItem(IDENTITY_DOCUMENTS, personalInfo.documentId),
            familyStatus: allCatalogs[FAMILY_STATUS].sexData[personalInfo.sex || 'All'].find(
                (x) => x.value === personalInfo.familyStatus,
            ),
            childrenStatus: getCatalogItem(CHILDREN_STATUS, personalInfo.childrenStatusId),
            nationality: getCatalogItem(COUNTRIES, personalInfo.nationalityId),
            pastResidences: getPastResidences(pastResidences),
        },
        educationInfo: {
            educations: educationInfo.map((item) => ({
                ...item,
                educationLevel: getCatalogItem(EDUCATIONAL_LEVELS, item.educationLevelId),
                studyForm: getCatalogItem(STUDY_FORMS, item.studyFormId),
            })),
        },
        workInfo: {
            workPlaces: workPlaces.map((item) => ({
                ...item,
                industry: getCatalogItem(INDUSTRIES, item.industryId),
                workArea: getCatalogItem(WORK_AREAS, item.workAreaId),
                managementLevel: getCatalogItem(MANAGEMENT_LEVELS, item.managementLevelId),
                managementExperience: getCatalogItem(
                    MANAGEMENT_EXPERIENCES,
                    item.managementExperienceId,
                ),
                employeesNumber: getCatalogItem(EMPLOYEES_NUMBERS, item.employeesNumberId),
            })),
        },
        governmentServiceInfo: { governmentServices },
        evaluationInfo: { evaluations },
        languagesInfo: {
            knownLanguages: languages.map((item) => ({
                ...item,
                language: getCatalogItem(LANGUAGES, item.languageId),
                languageLevel: getCatalogItem(LANGUAGE_LEVELS, item.languageLevelId),
            })),
        },
        socialNetworksInfo: {
            networks: socialNetworks.map((item) => ({
                ...item,
                network: getCatalogItem(SOCIAL_NETWORKS, item.networkId),
                value: item.value,
            })),
        },
        filesInfo: {
            files,
        },
        attributesInfo: {
            attributes: customAttributes,
        },
        filesDirectoryId,
        personnelInfo: {
            ...personnelInfo,
            awards: getAwards(personalInfo.awards || []),
            dataOrigin: getCatalogItem(DATA_ORIGINS, personnelInfo.dataOriginId),
            experienceLevel: getCatalogItem(EXPERIENCE_LEVELS, personnelInfo.experienceLevelId),
            curatorId: personnelInfo.curatorId,
            curatorName: personnelInfo.curatorName,
            selectedGeoLevel: GEO_LEVEL_DICT.find((x) => x.value === personnelInfo.geoLevel),
            selectedManagerLevel: EMPLOYEE_LEVEL_DICT.find(
                (x) => x.value === personnelInfo.managerLevel,
            ),
        },
        systemInfo: {
            groups: dictionaries.groups,
            groupId,
            user: user,
        },
        requestsData: {
            data: !!requestsData.data ? requestsData.data : {},
        },
        testsResults: {
            data: !!testsResults.data ? testsResults.data : [],
        },
    };
};
