import { faUpload } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import React, { Component } from "react";

import { bracket } from "utils";
import Button from "../Button";
import { showErrorAlert, showWarningAlert } from 'ducks/Alert';
import {connect} from "react-redux";
import { isUploadFilesSizeValid } from 'libs/validators';
import { getFileSize } from 'utils';
import "./FileUploadButton.scss";

class FileUploadButton extends Component {
    inputRef = React.createRef();

    state = {
        fileName: null,
        loading: false
    }
    _mount = true;

    withLoading = bracket(
        () => this._mount && this.setState({ loading: true }),
        () => this._mount && this.setState({ loading: false }),
    );

    componentDidMount = async () => {
        const { fileId, getFileInfo } = this.props;
        if (!fileId) { return; }

        const result = await this.withLoading(() => getFileInfo(fileId));
        if (!result.ok || !result.ok.id) { return; }

        this._mount && this.setState({ fileName: result.ok.name });
    }

    componentWillUnmount(){
        this._mount = false
    }

    handleClick = () => {
        const { current } = this.inputRef;
        if (!current) { return; }

        current.click();
    }

    onInvalidFileTypeUpload = fileTypes => {
        this.props.showWarningAlert(`Загружаемый файл не соответствует типам файлов ${fileTypes.split(',').join(', ')}`);
    };
    
    onInvalidPhotoUpload = message => {
        this.props.showWarningAlert(message);
    };
    
    handleFileChange = async (e) => {
        const { onFileIdChange, onUpload, fileTypes, maxBytesPerFile, setFileUploading = () => {} } = this.props;
        setFileUploading(true);
        const file = e.target.files[0];
        if (!file) { return; }

        if (fileTypes && !file.type.match(fileTypes.split(',').join('|'))) {
            e.target.value = null;
            this.onInvalidFileTypeUpload(fileTypes);
            return;
        }

        if (maxBytesPerFile && !isUploadFilesSizeValid([file], maxBytesPerFile)) {
            this.onInvalidPhotoUpload(
                `Размер файла не может превышать ${getFileSize(maxBytesPerFile)}`,
            );
            return;
        }
        
        const result = await this.withLoading(() => onUpload(file));
        if (!result.ok || !result.ok.id) { return; }
        
        onFileIdChange(result.ok);
        this.setState({ fileName: result.ok.name });
        setFileUploading(false);
    }

    render = () => {
        const { fileName, loading } = this.state;

        const buttonClassName = classNames({
            "FileUploadButton__Button": true,
            "FileUploadButton__Button--shrunk": !fileName || loading,
            "FileUploadButton__Button--hasFileName": fileName && !loading
        });

        return (
            <div className="FileUploadButton" tabIndex="0">
                <Button
                    className={buttonClassName}
                    type="button"
                    size="sm"
                    title={fileName || null}
                    faIcon={faUpload}
                    loading={loading}
                    onClick={this.handleClick}
                >
                    {fileName
                        ? <div className="FileUploadButton__ContentContainter">
                            <span className="FileUploadButton__Content">{fileName}</span>
                        </div>
                        : null}
                </Button>
                <input
                    ref={this.inputRef}
                    className="FileUploadButton__Input"
                    type="file"
                    tabIndex="-1"
                    onChange={this.handleFileChange}
                    accept={this.props.fileTypes}
                />
            </div>
        );
    }
}

const actions = {
    showErrorAlert, showWarningAlert,
};

export default connect(null, actions)(FileUploadButton);
