import React from "react";
import classnames from "classnames";

const ModalPopupHeader = props => {
    const { header, fitScreenSize, headerInput, noScrollPadding = false } = props;
    
    return (
        <div
            className={classnames('LKModalPopup__Header', {
                'LKModalPopup__Header--fitScreenSize': fitScreenSize,
                'LKModalPopup__Header--no-scroll-padding': noScrollPadding,
            })}
        >
            {header}
            {headerInput && <div
                className={classnames('', {
                    'LKModalPopup__HeaderInput--fitScreenSize': fitScreenSize,
                })}
            >
                {headerInput}
            </div>}
        </div>
    );
};

export default ModalPopupHeader;