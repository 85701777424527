import React, { useState, useEffect, useMemo } from 'react';
import ContextMenu from './ContextMenu';
import AcceptPage from '../AcceptPage';
import {
    getReportDownloadLink,
    getReportTemplates,
    getReportById,
    sendPersons,
    getPersonComment,
    checkCommentsExist,
} from 'api';
import Modal from 'react-responsive-modal';
import ModalPopup from 'components/Lk/Uikit/ModalPopup';
import { lkDetailsEditWishLists } from 'routes';
import { setPersonCart } from 'ducks/Wishlists';
import { serviceResultCode, getError } from 'serviceErrors';
import {
    EXPORT_REPORT,
    SEND_TO_EVALUATION,
    MENU_ACTION_ITEMS,
    MENU_ACTION,
    MENU_TYPE,
    SEND_TO_IPR,
    SEND_EMAIL,
    WISHLIST_ADD,
    WISHLIST_REMOVE,
    PERSON_COMMENT,
} from './constants';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import EvaluatePersonWizard from '../ModalPersonSummaryCard/EvaluatePersonWizard';
import './PersonMassActionMenu.scss';
import { saveComments } from 'ducks/PersonComments';
import { EditPersonComment } from '../PersonComments/components';

const generatePersonReports = (reportId, personIds) => {
    return getReportById(reportId, personIds);
};

const PersonMassActionMenu = (props) => {
    const {
        type,
        persons,
        setMenu,
        data,
        showErrorAlert,
        showSuccessAlert,
        setPersonCart,
        push,
        wishlistId,
        skipEditWishList,
        removeFromWishList,
        location,
        saveComments,
    } = props;

    const activePerson = data.filter((x) => persons.indexOf(x.id) >= 0);

    const defaultAcceptPageParams = {
        title: '',
        acceptAction: () => {},
    };

    let modal = { borderRadius: '12px', padding: '24px' };
    let style = { modal: modal };

    const [acceptPage, setAcceptPage] = useState(defaultAcceptPageParams);
    const [isAcceptPage, setIsAcceptPage] = useState(false);
    const [isEvalutionOpen, setEvalutionState] = useState(false);
    const [isCommentOpen, setCommentState] = useState(false);
    const [reports, setReports] = useState([]);
    const [templateMenu] = useState(MENU_ACTION_ITEMS);
    const [initialComment, setInitialComment] = useState('');
    const [visibleComments, setVisibleComments] = useState(false);
    const [needForceMessage, setNeedForceMessage] = useState(false);

    useEffect(() => {
        let mounted = true;
        const load = async () => {
            const templates = await getReportTemplates();
            let { base, system, user } = templates.data;
            user = Object.keys(user).flatMap((x) => user[x]);
            user = user.map((x) => ({ ...x, isUserReport: true }));
            base.map((x, index) => {
                const replacement = user.find((r) => r.isDefault && r.base === x.base);
                if (replacement) {
                    user.splice(user.indexOf(replacement), 1);
                    return base.splice(index, 1, replacement);
                } else {
                    return x;
                }
            });
            mounted &&
                setReports([...base, ...system, ...user].map((x) => ({ ...x, isReport: true })));
        };

        load();

        return () => {
            mounted = false;
        };
    }, []);

    const showAccept = (title, acceptAction, info = '') => {
        setIsAcceptPage(true);
        setAcceptPage({
            title,
            acceptAction,
            info,
        });
    };

    const onPersonSend = (type, id) => async () => {
        try {
            await sendPersons(type, id, persons);
            showSuccessAlert('Запрос успешно отправлен');
        } catch (error) {
            const reqError = getError(error, getSendError());
            showErrorAlert(reqError.message);
        }
    };
    const getSendError = () => (code, payload) => {
        switch (code) {
            case serviceResultCode.PersonAlreadyHavePersonGrowthPlan:
                return `У выбранного резервиста уже есть ИПР`;
            case serviceResultCode.CuratorNotFound:
                return payload ? payload : 'Специалист по развитию не найден';
            default:
                return 'Произошла непредвиденная ошибка';
        }
    };

    const handleDownload = async (selectedReport) => {
        if (persons.length === 0 || !selectedReport) {
            return;
        }

        const response = await generatePersonReports(selectedReport.id, persons);
        if (!response.data.fileId) {
            return;
        }

        const downloadLink = getReportDownloadLink(response.data.fileId);
        window.location = downloadLink;
    };

    const onClick = async (record) => {
        if (record.isReport) {
            showAccept(`Сформировать отчет - ${record.name}?`, () => handleDownload(record));
            return;
        }
        switch (record.id) {
            case EXPORT_REPORT:
                setMenu(MENU_TYPE);
                return;
            case SEND_TO_EVALUATION:
                setEvalutionState(true);
                return;
            case PERSON_COMMENT:
                if (!persons || persons?.length === 0) {
                    return;
                }
                if (persons.length === 1) {
                    await getPersonCommentForPerson(persons[0]);
                } else {
                    await countExistCommentsForPersons(persons);
                }
                setCommentState(true);
                return;
            case SEND_EMAIL:
                window.location = `mailto:${activePerson.map((x) => x.email).join(';')}`;
                return;

            case SEND_TO_IPR:
                showAccept(
                    `Направить задачу по созданию ИПР?`,
                    onPersonSend('PersonGrowth'),
                    'Задача о создании индивидуального плана развития будет направлена резервисту и эксперту по управлению талантами, который закреплён за Вами и вашими командами',
                );
                return;
            case WISHLIST_ADD:
                await setPersonCart({
                    personIds: persons,
                    type: 'add',
                });
                persons?.length !== 0 &&
                    push({ pathname: lkDetailsEditWishLists.url, search: location.search });
                return;
            case WISHLIST_REMOVE:
                setPersonCart({
                    personIds: persons,
                    type: 'delete',
                });
                if (skipEditWishList) {
                    removeFromWishList(wishlistId, persons);
                    return;
                }
                persons?.length !== 0 &&
                    push({ pathname: lkDetailsEditWishLists.url, search: location.search });
                return;
            default:
                return;
        }
    };

    const menuItems = useMemo(() => {
        switch (type) {
            case MENU_TYPE:
                return { title: 'Тип отчета', items: reports };
            case MENU_ACTION:
            default:
                return { items: templateMenu };
        }
    }, [type, reports, templateMenu]);

    const onClose = () => {
        setEvalutionState(false);
        setMenu(null);
    };

    const getPersonCommentForPerson = async (personId) => {
        const comment = await getPersonComment(personId);
        const currentUserComment = comment.data;
        setInitialComment(currentUserComment ? currentUserComment.text : '');
        setNeedForceMessage(false);
        setVisibleComments(currentUserComment ? currentUserComment.visibleToAll : false);
    };

    const countExistCommentsForPersons = async (personId) => {
        const result = await checkCommentsExist(personId);
        const model = result.data;
        setNeedForceMessage(model.countExistCommentsForPersons !== 0);
        setInitialComment('');
        setVisibleComments(false);
    };

    const externalInfo = needForceMessage ? (
        <div className="LkPersonComment__WarningMessage">
            При добавлении к анкете новой заметки <b>Ваша предыдущая заметка будет удалена</b>
        </div>
    ) : (
        ''
    );

    return (
        <>
            <ContextMenu {...menuItems} isOpen={!!type} onClose={onClose} onClick={onClick} />

            <AcceptPage
                show={isAcceptPage}
                title={acceptPage.title}
                info={acceptPage.info}
                acceptAction={acceptPage.acceptAction}
                setIsAcceptPage={setIsAcceptPage}
            />
            <Modal
                open={isEvalutionOpen}
                onClose={onClose}
                center
                styles={style}
                focusTrapped={false}
                showCloseIcon={false}
            >
                {isEvalutionOpen && (
                    <EvaluatePersonWizard
                        setPageType={onClose}
                        setIsAcceptPage={setIsAcceptPage}
                        setAcceptPage={setAcceptPage}
                        onPersonSend={onPersonSend}
                    />
                )}
            </Modal>
            <ModalPopup
                isModalOpen={isCommentOpen}
                header={'Добавить заметку'}
                onModalClose={() => setCommentState(false)}
                actionButtons={[]}
                showCloseIcon
                withFooter={false}
                width="100%"
            >
                <div className="LKPersonComments__EditModal">
                    <EditPersonComment
                        comment={{ text: initialComment, visibleToAll: visibleComments }}
                        onClose={() => setCommentState(false)}
                        onSubmit={saveComments}
                        personIds={persons}
                        withStickyFooter={false}
                        withScroll
                        externalInfo={externalInfo}
                    />
                </div>
            </ModalPopup>
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    loading: state.personComments.loading,
});
const actions = { showErrorAlert, showSuccessAlert, setPersonCart, push, saveComments };

export default connect(mapStateToProps, actions)(PersonMassActionMenu);
