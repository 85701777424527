import React from 'react';
import './EmailBlock.scss';

const EmailBlock = props => {
    const { preText, email } = props
    return (
        <div className="LKEmailBlock">
            {preText}&nbsp;<a href={`mailto:${email}`}>{email}</a>
        </div>
    );
};

export default EmailBlock;