import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Info extends Component {
    updateProps = () => {
        const { isSearchCard } = this.props;
        return Array.isArray(this.props.children)
            ? this.props.children.map(x => ({ ...x, props: { ...x.props, isSearchCard } }))
            : {
                  ...this.props.children,
                  props: { ...this.props.children.props, isSearchCard } ,
              };
    };

    render() {
        return (
            <div className="LKPersonCardInfo">
                {this.updateProps()}
            </div>
        );
    }
}

Info.propTypes = { isSearchCard: PropTypes.bool };

export default Info;
