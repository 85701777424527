import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { lkProgressTeam, lkDetailsEvaluateSelect, lkEvaluateCartProfile, lkDetailsEvaluateCart } from 'routes';
import { sendPersons } from 'api';
import { clearCart as clearProgressCart, setProgressCartPersons } from 'ducks/ProgressCart';
import { showErrorAlert, showSuccessAlert } from 'ducks/Alert';
import { serviceResultCode, getError } from 'serviceErrors';
import { clearBasket, removeItem } from 'ducks/Baskets';
import { EVALUATION_TYPE } from 'components/Lk/Basic/constants';
import classnames from 'classnames';
import MainCardMenu from 'components/Lk/Basic/MainCard/MainCardMenu';
import CardItemBlock from 'components/Lk/Common/CardItemBlock';
import CardItemBlockType from 'components/Lk/Common/CardItemBlockType';
import CardItemPerson from 'components/Lk/Common/CardItemPerson';
import CardItemPersonData from 'components/Lk/Common/CardItemPerson/CardItemPersonData';
import RoundedButton from 'components/Lk/Uikit/Buttons/RoundedButton';
import ExpandedBlock from './ExpandedBlock'
import { getUserShortName, endOf, startOf } from 'utils';
import StickyFooter from 'components/Lk/Common/StickyFooter';
import { changeBasket } from 'ducks/Baskets';
import { ACTIVE_LIST_CARD, ACTIVE_DETAIL_CARD } from 'components/Lk/Basic/constants';
import InfoField from 'components/Lk/Uikit/InfoField';
import Modal from 'react-responsive-modal';
import moment from 'moment';
import './PersonCart.scss';

const PersonCart = props => {
    const { type = '', cartTitle = '', cartTitleTooltip = '', menuItems = [], linkParams = {}, clearProgressCart, setProgressCartPersons,
        setActiveCard, push, progressCart, evaluateCart, showErrorAlert, showSuccessAlert, activeCard, evalutions, 
        profileRoute, removeItem, clearBasket, changeBasket } = props;

    if (!type) { push('/') }

    const [persons, setPersons] = useState([]);
    const [teamGrpoup, setTeamGrpoup] = useState([]);
    const [favoriteGroup, setFavoriteGroup] = useState([]);
    const [searchGroup, setSearchGroup] = useState([]);
    const [evalution, setEvalution] = useState({ id: '', title: '', description: '' });
    const [dueDate, setDueDate] = useState('');

    const [modalInfoOpen, setModalInfoOpen] = useState(false);

    useEffect(() => {
        let mounted = true;
        
        const getPersons = () => {
            switch (type) {
                case 'progress':
                    return progressCart;
                case 'evaluate':
                    return evaluateCart;
                default:
                    return [];
            }
        };

        const personsData = getPersons();
        mounted && setPersons(personsData);
        mounted && setTeamGrpoup(personsData.filter(x => x.type === 'team'));
        mounted && setFavoriteGroup(personsData.filter(x => x.type === 'favorite'));
        mounted && setSearchGroup(personsData.filter(x => x.type === 'search'));
        
        return () => { mounted = false; };
    }, [progressCart, evaluateCart, type]);

    useEffect(() => {
        let mounted = true;
        
        if (type === 'evaluate' && evalutions.selected.id) {
            const result = EVALUATION_TYPE.filter(item => item.id === evalutions.selected.id);
            mounted && setEvalution(result[0]);
        }
        
        if (!!!evalutions.selected.id) {
            mounted && setEvalution(EVALUATION_TYPE[0])
        }

        return () => { mounted = false };
    }, [type, evalutions]);

    setActiveCard(type === 'progress'  || props.match.path === lkEvaluateCartProfile.url ? ACTIVE_LIST_CARD : ACTIVE_DETAIL_CARD);
    const isMobileActive = (activeCard === ACTIVE_LIST_CARD && type === 'progress') || (props.match.path === lkDetailsEvaluateCart.url && type === 'evaluate');


    const deleteFromCart = (person, cartType) => e => {
        e.stopPropagation();
        switch (cartType) {
            case 'progress':
                setProgressCartPersons({ person, type: 'REMOVE' });
                break;
            case 'evaluate':
                removeItem('evaluate', person.id);
                return
            default:
                return false;
        }
    };

    const renderGroup = group => {
        return group.map(x => (
            <CardItemBlock key={x.person.id}>
                <CardItemPerson
                    person={x.person}
                    clickable
                    profileRoute={profileRoute}
                    actionIcon='delete'
                    onActionIconClick={deleteFromCart(x.person, type)}
                >
                    <CardItemPersonData data={getUserShortName(x.person)} position={x.person.currentJobTitle || x.person.currentPosition} />
                </CardItemPerson>
            </CardItemBlock>
        ))
    };

    const getTypeToSend = type => {
        switch (type) {
            case 'progress':
                return 'PersonGrowth';
            case 'evaluate':
                return 'Evaluation';
            default:
                return false;
        }
    };

    const clearCart = type => {
        switch (type) {
            case 'progress':
                clearProgressCart();
                push(lkProgressTeam.url);
                break;
            case 'evaluate':
                changeBasket("preselectionEvaluate", {persons: []});
                clearBasket('evaluate');
                push(lkDetailsEvaluateSelect.url);
                break;
            default:
                return false;
        }
    };

    const onPersonsSend = async () => {
        try {
            const personsIds = persons.map(x => x.person.id);
            await sendPersons(getTypeToSend(type), realCartTitle, personsIds, dueDate);
            await clearCart(type);
            showSuccessAlert('Запрос успешно отправлен');
            push('/');
        } catch(error) {
            const reqError = getError(error, onPersonsSendError());
            showErrorAlert(reqError.message);
        }
    };

    const onPersonsSendError = () => (code, payload) => {
        switch (code) {
            case serviceResultCode.PersonAlreadyHavePersonGrowthPlan:
                return payload;
            case serviceResultCode.PersonAlreadySentToEvaluation:
                return payload;
            case serviceResultCode.CuratorNotFound:
                return payload ? payload : "Специалист по развитию не найден";
            default:
                return 'Произошла непредвиденная ошибка';
        }
    };
    
    const onCancelSend = type => () => {
        switch(type) {
            case 'progress':
                push(lkProgressTeam.url, {canSelect: true});
                break;
            case 'evaluate':
                changeBasket("preselectionEvaluate", props.evaluateBasket);
                changeBasket("evaluate", {persons: []})
                push(lkDetailsEvaluateSelect.url);
                break;
            default:
                return false;
        }
    };

    const realCartTitle = type === 'evaluate' && evalution.title !== undefined ? evalution.title : cartTitle;
    const realCartTitleTooltip = type === 'evaluate' && evalution.description !== undefined ? evalution.description || '': cartTitleTooltip;
    const isCartFull = () => {
        switch (type) {
            case 'progress':
                return progressCart && progressCart.length !== 0;
            case 'evaluate':
                return evaluateCart && evaluateCart.length !== 0;
            default:
                return false;
        }
    };

    const finalClass = () => type === 'progress' || props.match.path === lkEvaluateCartProfile.url ? 'ListCard' : 'DetailsCard';

    const datePickerProps = {
        dateFormat: 'dd.MM.yyyy',
        mobileFormat: 'DD.MM.YYYY',
        minDate: new Date(startOf(new Date(), 'day').format()),
        openToDate: moment().add(7, 'days').toDate(),
        type: 'date',
        dataTest: 'PersonCart_DueDate--datepicker',
        dateConfig: {
            date: {
                format: 'D',
                caption: 'Day',
                step: 1,
            },
            month: {
                format: 'M',
                caption: 'Mon',
                step: 1,
            },
            year: {
                format: 'YYYY',
                caption: 'Year',
                step: 1,
            },
        },
    };

    const isDueDateValid = dueDate => {
        if (!dueDate) {
            return false;
        }
        
        return !isNaN(Date.parse(dueDate)) && moment(dueDate).isAfter(datePickerProps.minDate);
    };

    const onChangeDueDate = e => {
        if (!e) {
            return;
        }
        
        const newDueDate = endOf(e, 'day');
        
        if (newDueDate.isAfter(datePickerProps.minDate, 'sec')) {
            setDueDate(newDueDate.format());
        }
    };

    const handleOnPersonSend = () => {
        setModalInfoOpen(true);
    };
    
    const onModalClose = () => {
        onPersonsSend();
        setModalInfoOpen(false);
    };
    
    const getAssigneesCount = () => {
        return teamGrpoup.length + favoriteGroup.length + searchGroup.length;
    };
    
    return (
        type && <>
            <div className={classnames(
                `${finalClass(props.activeCard)} PersonCartWrapper`, 
                { 
                    [`${finalClass(props.activeCard)}--isMobileActive`]: isMobileActive,
                })
            }>
                <MainCardMenu main menuItems={menuItems} defaultMenuId='' linkParams={linkParams} />
                <div className='PersonCardDetails'>
                    <div className="PersonCardDetails__Line">
                        <CardItemBlock grey>
                            <CardItemBlockType
                                id='cart'
                                title={realCartTitle}
                                tooltip={realCartTitleTooltip}
                                icon='small'
                            />
                        </CardItemBlock>
                    </div>
                    <div className='PersonCart'>
                        <div className='PersonCart__Header'>
                            Вы выбрали
                        </div>
                        {teamGrpoup.length !== 0 && <ExpandedBlock header='Команды' subHeader={teamGrpoup.length} direction="vertical">{renderGroup(teamGrpoup)}</ExpandedBlock>}
                        {favoriteGroup.length !== 0 && <ExpandedBlock header='Резервисты “Избранное”' subHeader={favoriteGroup.length} direction="vertical">{renderGroup(favoriteGroup)}</ExpandedBlock>}
                        {searchGroup.length !== 0 && <ExpandedBlock header='Резервисты ' subHeader={searchGroup.length} direction="vertical">{renderGroup(searchGroup)}</ExpandedBlock>}                    
                    </div>
                </div>
                <StickyFooter>
                    <div className="PersonCart__StickyFooterActions">
                        <div className="PersonCart__DueDatePickerWrapper">
                            <InfoField
                                className={'PersonCart__DueDatePickerInput'}
                                title={type === 'evaluate'
                                    ? 'Дата, до которой необходимо произвести оценку' 
                                    : 'Дата, до которой необходимо сформировать индивидуальный план развития'}
                                titlePadding
                                inputProps={datePickerProps}
                                value={dueDate}
                                onChange={onChangeDueDate}
                                placeholder="Добавить"
                                isEdit
                                noIcon
                                noBorder
                            />
                        </div>
                        <div className='PersonCart__Footer'>
                            <RoundedButton 
                                disabled={!isCartFull() || !isDueDateValid(dueDate)} 
                                onClick={handleOnPersonSend} 
                                modifier="withoutPaddingBottom"
                            >
                                Направить
                            </RoundedButton>
                            <RoundedButton 
                                onClick={onCancelSend(type)} 
                                color="transparent" 
                                modifier="withoutPaddings"
                            >
                                Отменить
                            </RoundedButton>
                        </div>
                    </div>
                </StickyFooter>
            </div>
            <Modal 
                open={modalInfoOpen} 
                onClose={onModalClose}
                center
                closeOnOverlayClick={false}
                closeOnEsc={false}
                closeIconSvgPath={''}
                focusTrapped={false}
                classNames={{
                    modal: 'PersonCart__ModalInfoContainer',
                    closeButton: 'PersonCart__ModalInfoCloseBtn',
                    closeIcon: 'PersonCart__ModalInfoCloseIcon'
                }}
            >
                {type === 'evaluate'
                    ? <span>Задача о&nbsp;направлении {getAssigneesCount() > 1 ? 'резервистов' : 'резервиста'} на&nbsp;оценку направлена эксперту по&nbsp;управлению талантами, который закреплён за&nbsp;Вами и&nbsp;вашими командами.</span>
                    : <span>Задача о&nbsp;создании индивидуального плана развития направлена {getAssigneesCount() > 1 ? 'резервистам' : 'резервисту'} и&nbsp;эксперту по&nbsp;управлению талантами, который закреплён за&nbsp;Вами и&nbsp;вашими командами.</span>
                }
            </Modal>
        </>
    );
};

const mapStateToProps = state => {
    return {
        progressCart: state.progressCart.persons,
        evaluateCart: state.baskets.evaluate.basket.persons,
        evaluateBasket: state.baskets.evaluate.basket,
        evalutions: state.evaluation,
        evaluationList: state.evaluation.list,
    };
};

const actions = { push, showErrorAlert, showSuccessAlert, clearProgressCart, clearBasket, changeBasket, setProgressCartPersons, removeItem};

export default connect(mapStateToProps, actions)(PersonCart);