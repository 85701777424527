import React, { useEffect, useState } from 'react';
import { EVALUATION_FILTER_ID } from '../constants';
import { setSearchFilter } from '../utils';
import Input from 'components/Lk/Uikit/Input';
import Field from 'components/Lk/Uikit/Field';
import ActiveFilters from '../ActiveFilters';
import uuid from 'uuid/v4';
import { connect } from 'react-redux';
import { queryOperation } from 'components/Search/QueryOperation';
import SuccessButton from '../SuccessButton';
import { FIELDS_TYPE } from '../SearchFilters/constants';
import FilterField from './FilterField';
import { getEvaluationTestList, getTestProvidersActive, getEvaluationSummaryScales } from 'api';
import { showErrorAlert } from 'ducks/Alert';
import SearchGroupCondition from "../SearchGroupCondition";

const EvaluationFilter = props => {
    const {
        setFiltersState,
        mainFilter,
        activeFilter,
        onSuccess,
        filterId,
        defaultFilters,
        isFocused,
        showErrorAlert,
        getGroupCondition,
        updateFiltersWithCondition
    } = props;

    const [state, setState] = useState({});
    const [isLoadCat, setLoadCat] = useState(false);
    const [visibleSection, setVisibleSection] = useState(null);
    const [excludeFromSearch, setExcludeFromSearch] = useState(false);
    const [catalogs, setCatalogs] = useState({
        evaluationProviders: [],
        evaluationTests: [],
    });
    const [summaryScales, setSummaryScales] = useState([]);
    const [additionalCriteria] = useState([{ id: 1, name: 'Оценочная процедура', value: 1 }]);

    const [groupCondition, setGroupCondition] = useState(getGroupCondition(EVALUATION_FILTER_ID, false));

    const { 
        providerId, 
        evaluationTestId, 
        criteriaId, 
        valueTo, 
        valueFrom, 
        evaluationSummaryScaleId, 
        evaluationSummaryScaleValueTo, 
        evaluationSummaryScaleValueFrom, 
        additionalCriterionId
    } = state;
    
    const { scaleLowerBound, scaleUpperBound } = criteriaId || {};
    const { 
        scaleLowerBound: evaluationSummaryScaleLowerBound, 
        scaleUpperBound: evaluationSummaryScaleUpperBound,
        testsCriteria: evaluationTestsCriteria
    } = evaluationSummaryScaleId || {};

    const clearFields = () => {
        setState({});
        setExcludeFromSearch(false);
    };

    useEffect(() => {
        const load = async () => {
            try {
                const [testsResult, providersResult, summaryScalesResult] = await Promise.all([
                    getEvaluationTestList(),
                    getTestProvidersActive(),
                    getEvaluationSummaryScales()
                ]);

                const tests = testsResult.data.map(x => ({ ...x, name: x.title, value: x.id }));
                const providers = providersResult.data.map(x => ({ ...x, name: x.title, value: x.id }));
                setSummaryScales(summaryScalesResult.data
                    .filter(x => x.scaleLowerBound !== null && x.scaleUpperBound !== null)
                    .map(x => ({ ...x, name: x.title, value: x.id })));

                setCatalogs(cat => ({
                    ...cat,
                    evaluationTests: tests,
                    evaluationProviders: providers,
                }));
            } catch (e) {
                showErrorAlert('Произошла непредвиденная ошибка')
            }
            setLoadCat(true);
        };
        load();
    }, [showErrorAlert]);

    const onSubmit = async isClose => {
        await AddFilter();
        clearFields();
        setVisibleSection(null);
        isClose && onSuccess();
    };

    const onChange = state => {
        setState(x => ({ ...x, ...state }));
    };

    useEffect(() => {
        if (activeFilter == null || !isLoadCat) {
            return;
        }
        const [activeFilters] = mainFilter.filter(x => x.filterId === EVALUATION_FILTER_ID);
        const { childs: filters = [] } = activeFilters || {};

        const currentFilters = filters.filter(x => x.group === activeFilter.group);

        const [evaluation] = currentFilters;

        const provider = evaluation.childs.find(x => x.field === 'ProviderId');
        const evaluationTest = evaluation.childs.find(x => x.field === 'EvaluationTestId');
        const criteria = evaluation.childs.find(x => x.field === 'EvaluationTestCriterionId');
        const evaluationSummaryScale = evaluation.childs.find(x => x.field === 'EvaluationSummaryScaleId');

        const providerId = catalogs['evaluationProviders'].find(x => x.id === provider?.value);
        const evaluationTestId = catalogs['evaluationTests'].find(
            x => x.id === evaluationTest?.value,
        );

        const evaluationSummaryScaleId = summaryScales.find(x => x.id === evaluationSummaryScale?.value);
        
        const criteriaRecord = (criteria?.value && evaluationTestId?.criteria?.[criteria?.value]) || {};
        const criteriaId = criteria?.value && {
            ...criteriaRecord,
            value: criteria.value,
            name: criteriaRecord?.title,
        };

        const valueFrom = evaluation.childs.find(
            x => x.field === 'DecimalValue' && x.operation === queryOperation.GreaterOrEqual && x.shortLabel === evaluationTestId?.name,
        )?.value;

        const valueTo = evaluation.childs.find(
            x => x.field === 'DecimalValue' && x.operation === queryOperation.LessOrEqual && x.shortLabel === evaluationTestId?.name,
        )?.value;

        const evaluationSummaryScaleValueFrom = evaluation.childs.find(
            x => x.field === 'DecimalValue' && x.operation === queryOperation.GreaterOrEqual && x.shortLabel === evaluationSummaryScaleId?.name,
        )?.value;
        const evaluationSummaryScaleValueTo = evaluation.childs.find(
            x => x.field === 'DecimalValue' && x.operation === queryOperation.LessOrEqual && x.shortLabel === evaluationSummaryScaleId?.name,
        )?.value;

        onChange({ 
            providerId, 
            evaluationTestId, 
            criteriaId, 
            valueFrom, 
            valueTo, 
            evaluationSummaryScaleId,
            evaluationSummaryScaleValueFrom,
            evaluationSummaryScaleValueTo,
            additionalCriterionId: providerId || evaluationTestId ? { id: 1, name: 'Оценочная процедура', value: 1 } : null,
        });
        setExcludeFromSearch(evaluation.excludeFromSearch);

    }, [activeFilter, mainFilter, catalogs, isLoadCat, summaryScales]);

    const checkValues = value => {
        return value >= scaleLowerBound && value <= scaleUpperBound;
    };

    const checkSummaryScaleValues = value => {
        return value >= evaluationSummaryScaleLowerBound && value <= evaluationSummaryScaleUpperBound;
    };
    
    const buttonEnabled =
        (!additionalCriterionId || (!!additionalCriterionId && !!providerId && !!evaluationTestId)) &&
        (valueFrom == null || valueFrom === '' || checkValues(valueFrom)) &&
        (valueTo == null || valueTo === '' || checkValues(valueTo)) &&
        (!evaluationSummaryScaleId ||  evaluationSummaryScaleId?.value) &&
        (evaluationSummaryScaleValueFrom == null || evaluationSummaryScaleValueFrom === '' || checkSummaryScaleValues(evaluationSummaryScaleValueFrom)) &&
        (evaluationSummaryScaleValueTo == null || evaluationSummaryScaleValueTo === '' || checkSummaryScaleValues(evaluationSummaryScaleValueTo));

    const handleGroupConditionChange = e => {
        const condition = e.currentTarget.checked ? 'OR' : 'AND';
        updateFiltersWithCondition(EVALUATION_FILTER_ID, condition);
        setGroupCondition(condition);
    };

    const AddFilter = () => {
        let res = null;
        const groupLang = uuid();
        const parentId = uuid();

        const getLabel = (title, value) =>
            value != null && value !== '' ? `${title}${value}` : null;

        const getRangeLabel = (valueFrom, valueTo) =>
            [getLabel('от ', valueFrom), getLabel('до ', valueTo)].filter(x => x).join(' ');

        let children = [
            providerId
                ? {
                      ...setSearchFilter(
                          'PersonEvaluationCriterionResults',
                          'ProviderId',
                          queryOperation.Equal,
                          providerId.id,
                          `Поставщик: ${providerId.name}`,
                          'AND',
                          filterId,
                          'evaluation',
                          excludeFromSearch,
                          null,
                          groupLang,
                          FIELDS_TYPE.number,
                          providerId.name,
                      ),
                  }
                : undefined,
            evaluationTestId
                ? {
                      ...setSearchFilter(
                          'PersonEvaluationCriterionResults',
                          'EvaluationTestId',
                          queryOperation.Equal,
                          evaluationTestId.id,
                          `Тестирование: ${evaluationTestId.name}`,
                          'AND',
                          filterId,
                          'evaluation',
                          excludeFromSearch,
                          null,
                          groupLang,
                          FIELDS_TYPE.number,
                          evaluationTestId.name,
                      ),
                  }
                : undefined,
            criteriaId
                ? {
                      ...setSearchFilter(
                          'PersonEvaluationCriterionResults',
                          'EvaluationTestCriterionId',
                          queryOperation.Equal,
                          criteriaId.id,
                          `Шкала: ${criteriaId.name}`,
                          'AND',
                          filterId,
                          'evaluation',
                          excludeFromSearch,
                          null,
                          groupLang,
                          FIELDS_TYPE.number,
                          evaluationTestId.name,
                      ),
                  }
                : undefined,
            valueFrom
                ? {
                      ...setSearchFilter(
                          'PersonEvaluationCriterionResults',
                          'DecimalValue',
                          queryOperation.GreaterOrEqual,
                          valueFrom,
                          `Значение от: ${valueFrom}`,
                          'AND',
                          filterId,
                          'evaluation',
                          excludeFromSearch,
                          null,
                          groupLang,
                          FIELDS_TYPE.number,
                          evaluationTestId.name,
                      ),
                  }
                : undefined,
            valueTo
                ? {
                      ...setSearchFilter(
                          'PersonEvaluationCriterionResults',
                          'DecimalValue',
                          queryOperation.LessOrEqual,
                          valueTo,
                          `Значение от: ${valueTo}`,
                          'AND',
                          filterId,
                          'evaluation',
                          excludeFromSearch,
                          null,
                          groupLang,
                          FIELDS_TYPE.number,
                          evaluationTestId.name,
                      ),
                  }
                : undefined,
            evaluationSummaryScaleId
                ? {
                    ...setSearchFilter(
                        'PersonEvaluationCriterionResults',
                        'EvaluationSummaryScaleId',
                        queryOperation.Equal,
                        evaluationSummaryScaleId.id,
                        `Компетенции и способности: ${evaluationSummaryScaleId.name}`,
                        'AND',
                        filterId,
                        'evaluation',
                        excludeFromSearch,
                        null,
                        groupLang,
                        FIELDS_TYPE.number,
                        evaluationSummaryScaleId.name,
                    ),
                }
                : undefined,
            evaluationSummaryScaleValueFrom
                ? {
                    ...setSearchFilter(
                        'PersonEvaluationCriterionResults',
                        'DecimalValue',
                        queryOperation.GreaterOrEqual,
                        evaluationSummaryScaleValueFrom,
                        `Значение от: ${evaluationSummaryScaleValueFrom}`,
                        'AND',
                        filterId,
                        'evaluation',
                        excludeFromSearch,
                        null,
                        groupLang,
                        FIELDS_TYPE.number,
                        evaluationSummaryScaleId.name,
                    ),
                }
                : undefined,
            evaluationSummaryScaleValueTo
                ? {
                    ...setSearchFilter(
                        'PersonEvaluationCriterionResults',
                        'DecimalValue',
                        queryOperation.LessOrEqual,
                        evaluationSummaryScaleValueTo,
                        `Значение до: ${evaluationSummaryScaleValueTo}`,
                        'AND',
                        filterId,
                        'evaluation',
                        excludeFromSearch,
                        null,
                        groupLang,
                        FIELDS_TYPE.number,
                        evaluationSummaryScaleId.name,
                    ),
                }
                : undefined,
        ].filter(x => x);

        if (children.length > 0) {
            children = children.concat({
                ...setSearchFilter(
                    'PersonEvaluationCriterionResults',
                    'ResultStatus',
                    queryOperation.Equal,
                    'Relevant',
                    ``,
                    'AND',
                    filterId,
                    'evaluation',
                    excludeFromSearch,
                    null,
                    groupLang,
                    FIELDS_TYPE.input,
                    '',
                ),
            });
        }

        const label = [
            getLabel('Поставщик: ', providerId?.name),
            getLabel('Тестирование: ', evaluationTestId?.name),
            getLabel('Шкала: ', criteriaId?.name),
            getLabel('Значение: ',getRangeLabel(valueFrom, valueTo)),
            getRangeLabel(evaluationSummaryScaleValueFrom, evaluationSummaryScaleValueTo) 
                ? getLabel(`${evaluationSummaryScaleId?.name} `, getRangeLabel(evaluationSummaryScaleValueFrom, evaluationSummaryScaleValueTo))
                : getLabel('Компетенции и способности: ', evaluationSummaryScaleId?.name)
        ].filter(x => x);

        res = [
            {
                id: parentId,
                joinOperation: groupCondition ? groupCondition : 'AND',
                filterId: filterId,
                childs: children,
                group: groupLang,
                parent: true,
                table: 'PersonsSearch',
                operation: queryOperation.Exists,
                excludeFromSearch,
                label: label.join(', '),
                section: 'evaluation',
            },
        ];

        if (children.length === 0 || !buttonEnabled) {
            return;
        }

        return setFiltersState(res, filterId);
    };

    const testsCatalog = catalogs.evaluationTests
        .filter(option => providerId 
            && option.testProviderId === providerId.id 
            && (!evaluationTestsCriteria || evaluationTestsCriteria.some(testCriteria => testCriteria.evaluationTestId === option.id)))
        .map(item => ({ ...item, code: item.id, name: item.title }));

    const criteriaCatalog =
        evaluationTestId &&
        Object.values(evaluationTestId.criteria)
            .filter(criteria => !evaluationTestsCriteria || evaluationTestsCriteria.some(testCriteria => testCriteria.evaluationTestCriterionId === criteria.id))
            .map(item => ({
            ...item,
            name: item.title,
            value: item.id,
        }));

    return (
        <>
            <div className="LKSearchCardVisibleFIlterLine">
                <SearchGroupCondition
                    checked={groupCondition === 'OR'}
                    onClick={handleGroupConditionChange}
                />
            </div>
            <ActiveFilters filterId={filterId} />
            <div className="LKSearchCardVisibleFIlterLine">
                <FilterField
                    onChange={val =>
                        onChange({
                            evaluationSummaryScaleId: val,
                            evaluationSummaryScaleValueFrom: null,
                            evaluationSummaryScaleValueTo: null,
                            providerId: null,
                            evaluationTestId: null,
                            criteriaId: null,
                            valueFrom: null,
                            valueTo: null,
                            valueText: null,
                        })
                    }
                    type={FIELDS_TYPE.select}
                    value={evaluationSummaryScaleId}
                    items={summaryScales}
                    placeholder={'Компетенции и способности'}
                    maxMenuHeight={window.innerWidth < 577 ? 180 : 350}
                />
                {evaluationSummaryScaleId && (
                    <div className="LKSearchCardVisibleFIlterLine__Container--EvaluationSummaryScale">
                        <div className="LKSearchCardVisibleFIlterLine__GroupHeader">{`Значение (от ${evaluationSummaryScaleLowerBound} до ${evaluationSummaryScaleUpperBound})`}</div>
                        <Field
                            className="LKFilterField"
                            error={`от ${evaluationSummaryScaleLowerBound} до ${evaluationSummaryScaleUpperBound}`}
                            size={50}
                            invalid={evaluationSummaryScaleValueFrom && !checkSummaryScaleValues(+evaluationSummaryScaleValueFrom)}
                        >
                            <Input
                                placeholder="от"
                                type="number"
                                value={evaluationSummaryScaleValueFrom || ''}
                                onChange={e => onChange({ evaluationSummaryScaleValueFrom: e.currentTarget.value })}
                            />
                        </Field>
                        <Field
                            className="LKFilterField"
                            error={`от ${evaluationSummaryScaleLowerBound} до ${evaluationSummaryScaleUpperBound}`}
                            size={50}
                            invalid={evaluationSummaryScaleValueTo && !checkSummaryScaleValues(+evaluationSummaryScaleValueTo)}
                        >
                            <Input
                                placeholder="до"
                                type="number"
                                value={evaluationSummaryScaleValueTo || ''}
                                onChange={e => onChange({ evaluationSummaryScaleValueTo: e.currentTarget.value })}
                            />
                        </Field>
                    </div>
                )}
                <FilterField
                    onChange={val =>
                        onChange({
                            additionalCriterionId: val,
                            providerId: null,
                            evaluationTestId: null,
                            criteriaId: null,
                            valueFrom: null,
                            valueTo: null,
                            valueText: null,
                        })
                    }
                    type={FIELDS_TYPE.select}
                    value={additionalCriterionId}
                    items={additionalCriteria}
                    placeholder={'Выберите дополнительный критерий'}
                />
                {additionalCriterionId && (<>
                    <FilterField
                        onChange={val =>
                            onChange({
                                providerId: val,
                                evaluationTestId: null,
                                criteriaId: null,
                                valueFrom: null,
                                valueTo: null,
                                valueText: null,
                            })
                        }
                        type={FIELDS_TYPE.select}
                        value={providerId}
                        items={catalogs['evaluationProviders']}
                        placeholder={'Поставщик'}
                    />
                    <FilterField
                        onChange={val =>
                            onChange({
                                evaluationTestId: val,
                                criteriaId: null,
                                valueFrom: null,
                                valueTo: null,
                                valueText: null,
                            })
                        }
                        type={FIELDS_TYPE.select}
                        value={evaluationTestId}
                        items={testsCatalog}
                        placeholder={'Тестирование'}
                    />
                    <FilterField
                        onChange={val =>
                            onChange({
                                criteriaId: val,
                                valueFrom: null,
                                valueTo: null,
                                valueText: null,
                            })
                        }
                        type={FIELDS_TYPE.select}
                        value={criteriaId}
                        items={criteriaCatalog}
                        placeholder={'Шкала'}
                        maxMenuHeight={window.innerWidth < 577 ? 180 : 350}
                    />

                    {criteriaId && (
                        <>
                            <div className="LKSearchCardVisibleFIlterLine__GroupHeader">{`Значение (от ${scaleLowerBound} до ${scaleUpperBound})`}</div>
                            <Field
                                className="LKFilterField"
                                error={`от ${scaleLowerBound} до ${scaleUpperBound}`}
                                size={50}
                                invalid={valueFrom && !checkValues(+valueFrom)}
                            >
                                <Input
                                    placeholder="от"
                                    type="number"
                                    value={valueFrom || ''}
                                    onChange={e => onChange({ valueFrom: e.currentTarget.value })}
                                />
                            </Field>
                            <Field
                                className="LKFilterField"
                                error={`от ${scaleLowerBound} до ${scaleUpperBound}`}
                                size={50}
                                invalid={valueTo && !checkValues(+valueTo)}
                            >
                                <Input
                                    placeholder="до"
                                    type="number"
                                    value={valueTo || ''}
                                    onChange={e => onChange({ valueTo: e.currentTarget.value })}
                                />
                            </Field>
                        </>
                    )}
                </>)}
            </div>

            <SuccessButton
                onSubmit={onSubmit}
                buttonEnabled={buttonEnabled}
                activeFilter={activeFilter}
                visibleSection={visibleSection?.value}
                defaultFilters={defaultFilters}
                isFocused={isFocused}
            />
        </>
    );
};

const mapStateToProps = state => {
    return {
        mainFilter: state.filterSearch.mainFilter,
        activeFilter: state.filterSearch.activeFilter,
        extraFields: state.filterSearch.extraFields,
    };
};

const actions = { showErrorAlert };

export default connect(mapStateToProps, actions)(EvaluationFilter);
