import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    defaultDocumentCode,
    defaultForeignDocumentCode,
    federalCities,
    yearMask,
    internationalPhoneRegex,
    BIRTH_DATE_DISPLAY_MODES,
    snilsRegex,
    rusCode,
} from '../../../../constants';
import ValidBlock from 'components/hoc/ValidBlock';
import { showErrorAlert, showWarningAlert, showSuccessAlert } from 'ducks/Alert';
import {
    COUNTRIES,
    FEDERAL_DISTRICTS,
    IDENTITY_DOCUMENTS,
    REGIONS,
    SEX,
    LOCALITIES,
} from 'ducks/Catalog';
import {
    isForeignDocument,
    setMergeData,
    setIsMergeNext,
    setSnilsIsOk,
    setShowSnilsError,
} from 'ducks/Person';
import {
    requireValidator,
    yearValidator,
    regexValidator,
    birthDateValidator,
    validateName,
} from 'libs/validators';
import { DataCardBlock } from 'components/common/DataCard';
import DatePicker from 'components/uikit/DatePicker';
import InputMask from 'components/uikit/InputMask';
import Select from 'components/uikit/Select';
import CheckField from 'components/uikit/CheckField';
import { DataCard } from 'components/common/DataCard';
import Field from 'components/uikit/Field';
import Form from 'components/common/Form';
import Input from 'components/uikit/Input';
import InputText from 'components/uikit/InputText';
import Label from 'components/uikit/Label';
import Toggle from 'components/common/Toggle';
import Row from 'components/uikit/Row';
import PastResidencesTable from './PastResidencesTable';
import { sex as sexEnum } from '../../../../enums';
import { startOf, isNull } from 'utils';
import PhoneInput from 'components/uikit/PhoneInput/PhoneInput';
import SnilsInput from 'components/uikit/SnilsInput/SnilsInput';
import { getCatalog, getPersonIdBySnils } from 'api';
import { debounce } from 'lodash';
import { push } from 'connected-react-router';
import { showPageLoader, hidePageLoader } from 'ducks/PageLoader';

const sexEnumObj = sexEnum;

const newPastResidenceEntry = {
    selectedRegion: null,
    yearFrom: null,
    yearTo: null,
    regionLocalities: [],
};

class PersonalInfoBlock extends Component {
    state = {
        currentRegionLocalities: [],
        mergeId: null,
        canMerge: false,
        snilsLoading: false,
        snilsFilled: false,
    };

    componentDidMount = () => {
        this.setState({ snilsFilled: this.props.snilsIsOk });
        this.props.setShowSnilsError(false);
    };

    componentDidUpdate = async (prevProps) => {
        const {
            snils,
            id,
            setSnilsIsOk,
            setShowSnilsError,
            isRussianCitizen,
            handleStateChange,
            showSuccessAlert,
            snilsIsOk,
        } = this.props;

        if (!snilsIsOk && prevProps.snilsIsOk !== snilsIsOk) {
            this.setState({ snilsFilled: false });
        }

        if (!snils && prevProps.snils && isRussianCitizen) {
            setSnilsIsOk(false);
            this.setState({ mergeId: null });
        }

        if (snils && prevProps.snils !== snils) {
            if (this.state.mergeId) {
                this.setState({ mergeId: null });
            }

            if (this.snilsInputInvalid(snils)) {
                setSnilsIsOk(false);
                setShowSnilsError(false);
            }

            if (!this.snilsInputInvalid(snils)) {
                this.setState({ snilsLoading: true });

                try {
                    this.props.showPageLoader();
                    let res = await getPersonIdBySnils(snils);

                    if (!res.data.isSnilsValid) {
                        handleStateChange({ snils: '' });
                        setSnilsIsOk(false);
                        setShowSnilsError(true);
                        return;
                    }

                    if (res.data.hasCollisions && res.data.ids[0] !== id) {
                        setSnilsIsOk(false);
                        setShowSnilsError(false);
                        this.setState({ mergeId: res.data.ids[0], canMerge: res.data.isCanMerge });
                    }
                    if (!res.data.hasCollisions) {
                        setSnilsIsOk(true);
                        setShowSnilsError(false);
                        showSuccessAlert('Доступно для редактирования');
                    }
                } catch {
                    showErrorAlert('Ошибка проверки СНИЛС');
                } finally {
                    this.setState({ snilsLoading: false });
                    this.props.hidePageLoader();
                }
            }
        }

        if (prevProps.isRussianCitizen !== isRussianCitizen) {
            handleStateChange({ snils: null });
            if (isRussianCitizen) {
                setSnilsIsOk(false);
                setShowSnilsError(false);
            } else {
                setSnilsIsOk(true);
            }
        }

        if (!id && prevProps.id !== id) {
            this.setState({ snilsFilled: false });
        }
    };

    componentWillUnmount = () => {
        if (!this.state.snilsFilled) {
            this.props.setSnilsIsOk(false);
            this.props.handleStateChange({ snils: null });
        }
    };

    disabledBySnils = () => !this.props.snilsIsOk && !!this.props.isRussianCitizen;

    handleMergeClick = () => {
        const { push, id, data, setMergeData, setIsMergeNext } = this.props;

        if (id && data) {
            setMergeData(data);
            setIsMergeNext(true);
        }
        push(`/Person/${this.state.mergeId}`);
    };

    handleRegionChange = (region, clearLocalities = true) => {
        if (!region || region === this.props.selectedRegion) {
            return;
        }
        clearLocalities && this.setState({ currentRegionLocalities: [] });
        this.props.handleStateChange({
            selectedDistrict: region
                ? this.props[FEDERAL_DISTRICTS].data.find((x) => x.id === region.federalDistrictId)
                : null,
            selectedRegion: region,
            ...(clearLocalities && { selectedLocality: null }),
        });
    };

    handlePastResidencesChange = (pastResidences) => {
        this.props.handleStateChange({ pastResidences });
    };

    isPastResidencesInputInvalid = (fieldKey, validators, index, customLookup) => {
        const { isEditBlock } = this.props;
        if (isEditBlock) {
            if (Array.isArray(fieldKey)) {
                return (
                    this.props.isInputInvalid(
                        fieldKey[0],
                        validators,
                        `pastResidences_${index}`,
                        customLookup,
                    ) ||
                    this.props.isInputInvalid(
                        fieldKey[1],
                        validators,
                        `pastResidences_${index}`,
                        customLookup,
                    )
                );
            }
            return this.props.isInputInvalid(
                fieldKey,
                validators,
                `pastResidences_${index}`,
                customLookup,
            );
        } else {
            if (Array.isArray(fieldKey)) {
                return (
                    this.props.isInputInvalid(
                        fieldKey[0],
                        validators,
                        `pastResidences_${index}`,
                        'pastResidences',
                        index,
                    ) ||
                    this.props.isInputInvalid(
                        fieldKey[1],
                        validators,
                        `pastResidences_${index}`,
                        'pastResidences',
                        index,
                    )
                );
            }
            return this.props.isInputInvalid(
                fieldKey,
                validators,
                `pastResidences_${index}`,
                'pastResidences',
                index,
            );
        }
    };

    isBirthDateInvalid = () => {
        return (value) => {
            return (
                !isNull(value) &&
                birthDateValidator('Возраст должен быть от 14 до 100 лет', {
                    minAge: 14,
                    maxAge: 100,
                })(value)
            );
        };
    };

    nationalityValidator = () => {
        return (value) => {
            return value || this.props.isRussianCitizen ? null : 'Выберите гражданство';
        };
    };

    isRegionFederal = (region) => {
        if (region) return federalCities.includes(region.id.toUpperCase());
        return false;
    };

    getDocumentNumberFormat(document) {
        return document && document.numberFormat;
    }

    getDocumentNumberRegex(document) {
        if (document && document.numberRegex) {
            return new RegExp(document.numberRegex);
        }
    }

    onIsRussianCitizenChanged = (isRussian) => {
        const documentCode = isRussian ? defaultDocumentCode : defaultForeignDocumentCode;
        this.props.handleStateChange({
            isRussianCitizen: isRussian,
            selectedDocument: this.props[IDENTITY_DOCUMENTS].data.find(
                (x) => x.code === documentCode,
            ),
        });
    };

    onBirthDateDisplayModeChanged = (check) => {
        const { birthDate } = this.props;

        const mode = check ? BIRTH_DATE_DISPLAY_MODES.Year : BIRTH_DATE_DISPLAY_MODES.FullDate;
        this.props.handleStateChange({
            birthDateDisplayMode: mode,
            birthDate: birthDate && new Date(startOf(birthDate, 'year')),
        });
    };

    getCurrentLocality = (searchTerm) => {
        if (!searchTerm) {
            this.setState({ currentRegionLocalities: [] });
            return;
        }
        try {
            getCatalog(
                LOCALITIES,
                { searchTerm, Region: this.props?.selectedRegion?.id },
                true,
            ).then((res) => {
                this.setState({ currentRegionLocalities: res.data });
            });
        } catch (e) {
            this.props.showErrorAlert('Ошибка загрузки населенных пунктов');
        }
    };

    setCurrentFederalDistrict = (value) => {
        this.setState({ currentRegionLocalities: [] });
        this.props.handleStateChange({ selectedDistrict: value });
    };

    setCurrentLocality = (selectedLocality) => {
        this.props.handleStateChange({ selectedLocality });
        const region = this.props[REGIONS].data.find(
            (x) => x.code === selectedLocality?.regionCode,
        );
        this.handleRegionChange(region, false);
    };

    renderRussianBlock = () => {
        return (
            <>
                <Field
                    required
                    filled={this.props.isInputFilled(this.props.selectedDistrict)}
                    size="33"
                >
                    <Label htmlFor="federalDistrict">Федеральный округ</Label>
                    <Select
                        key="federalDistrict"
                        inputId="federalDistrict"
                        value={this.props.selectedDistrict}
                        onChange={this.setCurrentFederalDistrict}
                        options={this.props[FEDERAL_DISTRICTS].data}
                        isDisabled
                        catalog
                    />
                </Field>
                <Field
                    required
                    filled={this.props.isInputFilled(this.props.selectedRegion)}
                    invalid={this.props.isInputInvalid('selectedRegion', [
                        requireValidator('Выберите регион'),
                    ])}
                    size="33"
                >
                    <Label htmlFor="region">Регион</Label>
                    <Select
                        inputId="region"
                        value={this.props.selectedRegion}
                        onChange={this.handleRegionChange}
                        options={this.props[REGIONS].data}
                        catalog
                        isDisabled={this.disabledBySnils()}
                    />
                </Field>
                <Field
                    // TODO: Удалить если заказчик не захочет вернуть после импорта новых резервистов
                    //required={!this.isRegionFederal(this.props.selectedRegion)}
                    filled={this.props.isInputFilled(this.props.selectedLocality)}
                    //invalid={!this.isRegionFederal(this.props.selectedRegion) ? this.props.isInputInvalid('selectedLocality', [requireValidator('Выберите населённый пункт')]) : false}
                    size="33"
                >
                    <Label htmlFor="locality">Населённый пункт</Label>
                    <Select
                        inputId="locality"
                        value={this.props.selectedLocality}
                        onChange={this.setCurrentLocality}
                        onInputChange={debounce(this.getCurrentLocality, 500)}
                        options={this.state.currentRegionLocalities}
                        isClearable
                        catalog
                        isDisabled={this.disabledBySnils()}
                    />
                </Field>
                <Field
                    invalid={this.props.isInputInvalid('residenceStartYear', [yearValidator()])}
                    size="33"
                    disabled={this.disabledBySnils()}
                >
                    <Label htmlFor="residenceStartYear">Год начала проживания</Label>
                    <InputMask
                        mask={yearMask}
                        maskChar={null}
                        id="residenceStartYear"
                        value={this.props.residenceStartYear || ''}
                        onChange={(e) =>
                            this.props.handleStateChange({ residenceStartYear: e.target.value })
                        }
                    />
                </Field>
            </>
        );
    };

    renderForeignBlock = () => {
        return (
            <>
                <Field
                    required
                    filled={this.props.isInputFilled(this.props.selectedNationality)}
                    invalid={this.props.isInputInvalid('selectedNationality', [
                        this.nationalityValidator(),
                    ])}
                    size="33"
                >
                    <Label htmlFor="selectedNationality">Гражданство</Label>
                    <Select
                        key="selectedNationality"
                        inputId="selectedNationality"
                        value={this.props.selectedNationality}
                        onChange={(item) =>
                            this.props.handleStateChange({ selectedNationality: item })
                        }
                        options={this.props[COUNTRIES].data.filter((x) => x.code !== rusCode)}
                        isClearable
                        catalog
                    />
                </Field>
                <Field size="33">
                    <Label htmlFor="foreignRegion">Регион</Label>
                    <Input
                        id="foreignRegion"
                        value={this.props.foreignRegion || ''}
                        maxLength={200}
                        onChange={(e) =>
                            this.props.handleStateChange({ foreignRegion: e.target.value })
                        }
                    />
                </Field>
                <Field size="33">
                    <Label htmlFor="foreignLocality">Населённый пункт</Label>
                    <Input
                        id="foreignLocality"
                        value={this.props.foreignLocality || ''}
                        maxLength={200}
                        onChange={(e) =>
                            this.props.handleStateChange({ foreignLocality: e.target.value })
                        }
                    />
                </Field>
                <Field
                    invalid={this.props.isInputInvalid('residenceStartYear', [yearValidator()])}
                    size="33"
                >
                    <Label htmlFor="residenceStartYear">Год начала проживания</Label>
                    <InputMask
                        mask={yearMask}
                        maskChar={null}
                        id="residenceStartYear"
                        value={this.props.residenceStartYear || ''}
                        onChange={(e) =>
                            this.props.handleStateChange({ residenceStartYear: e.target.value })
                        }
                    />
                </Field>
            </>
        );
    };

    snilsValidator = (message = 'Некорректный ввод номера СНИЛС', options = {}) => {
        return regexValidator(/\d{3}-?\d{3}-?\d{3}-?\d{2}/, message, options);
    };

    snilsInputInvalid = (input) => {
        return !input.match(snilsRegex);
    };

    phoneInputInvalid = (input) => {
        return !input.match(internationalPhoneRegex);
    };

    renderDocumentNumber = (hasMask) => {
        const { handleStateChange, selectedDocument, documentNumber } = this.props;

        if (hasMask) {
            return (
                <InputMask
                    id="documentNumber"
                    mask={this.getDocumentNumberFormat(selectedDocument)}
                    value={documentNumber || ''}
                    onChange={(e) => handleStateChange({ documentNumber: e.target.value })}
                    disabled={this.disabledBySnils()}
                />
            );
        } else {
            return (
                <Input
                    id="documentNumber"
                    mask={this.getDocumentNumberFormat(selectedDocument)}
                    value={documentNumber || ''}
                    onChange={(e) => handleStateChange({ documentNumber: e.target.value })}
                    maxLength={50}
                    disabled={this.disabledBySnils()}
                />
            );
        }
    };

    renderSnilsError = (text) => {
        return (
            <div className="FieldItem Person__SnilsError">
                <div className="Person__SnilsError__Icon">
                    <div className="Mark_Left"></div>
                    <div className="Mark_Right"></div>
                </div>
                {text}
            </div>
        );
    };

    render() {
        const {
            isInputInvalid,
            isInputFilled,
            handleStateChange,
            snils,
            lastName,
            firstName,
            middleName,
            birthDate,
            selectedSex,
            selectedDocument,
            documentNumber,
            documentName,
            birthPlace,
            isRussianCitizen,
            birthDateDisplayMode,
            email,
            phone,
            pastResidences,
            extraInfo,
        } = this.props;

        const pastResidencesCatalogs = {
            federalDistricts: this.props[FEDERAL_DISTRICTS].data,
            regions: this.props[REGIONS].data,
        };

        return (
            <DataCard
                shadow
                title="Личные данные"
                className="kr_profile-anketa"
                isOpen={this.props.hasValidationErrors}
            >
                <Form autoComplete={false} className="EditPerson__Form">
                    <Row>
                        <CheckField
                            id="isRussianCitizen"
                            title="Гражданин РФ"
                            checked={isRussianCitizen}
                            onChange={this.onIsRussianCitizenChanged}
                            required
                            disabled={!!snils && !this.snilsInputInvalid(snils)}
                            size="33"
                            className="anketa-nationality_wrap"
                            modifier="DataCardContent"
                        />
                        {!isRussianCitizen && (
                            <CheckField
                                id="readyMoveToRussia"
                                title="Готов к переезду в Россию"
                                checked={this.props.readyMoveToRussia}
                                onChange={(readyMoveToRussia) =>
                                    this.props.handleStateChange({ readyMoveToRussia })
                                }
                                required
                                className="anketa-nationality_wrap"
                                modifier="DataCardContent"
                            />
                        )}
                    </Row>
                    {isRussianCitizen && (
                        <Row>
                            <Field
                                required
                                filled={isInputFilled(snils)}
                                invalid={snils && this.snilsInputInvalid(snils)}
                                size="33"
                            >
                                <Label htmlFor="snils">Номер СНИЛС</Label>
                                <SnilsInput
                                    id="snils"
                                    name="snils"
                                    value={snils || ''}
                                    disabled={this.state.snilsFilled || this.state.snilsLoading}
                                    maxLength={14}
                                    onChange={(e) => handleStateChange({ snils: e })}
                                />
                            </Field>
                            {this.state.mergeId && (
                                <>
                                    {this.state.canMerge ? (
                                        <div className="FieldItem Person__MergeLink">
                                            <div className="Person__MergeLink__Icon">
                                                <div className="Mark"></div>
                                            </div>
                                            Найдено совпадение.
                                            <p onClick={this.handleMergeClick}>Сравнить данные</p>
                                        </div>
                                    ) : (
                                        this.renderSnilsError(
                                            'Найдено совпадение. Невозможно объединить анкеты',
                                        )
                                    )}
                                </>
                            )}
                            {this.props.showSnilsError &&
                                this.renderSnilsError('Некорректный ввод номера СНИЛС')}
                        </Row>
                    )}
                    <Row>
                        <Field
                            required
                            filled={isInputFilled(lastName)}
                            invalid={isInputInvalid('lastName', [
                                validateName('Не правильно заполнена фамилия', true),
                            ])}
                            size="33"
                            disabled={this.disabledBySnils()}
                        >
                            <Label htmlFor="lastName">Фамилия</Label>
                            <Input
                                id="lastName"
                                name="lastName"
                                value={lastName || ''}
                                maxLength={100}
                                onChange={(e) => handleStateChange({ lastName: e.target.value })}
                            />
                        </Field>
                        <Field
                            required
                            filled={isInputFilled(firstName)}
                            invalid={isInputInvalid('firstName', [
                                validateName('Не правильно заполнео имя', true),
                            ])}
                            size="33"
                            disabled={this.disabledBySnils()}
                        >
                            <Label htmlFor="firstName">Имя</Label>
                            <Input
                                id="firstName"
                                name="firstName"
                                value={firstName || ''}
                                maxLength={100}
                                onChange={(e) => handleStateChange({ firstName: e.target.value })}
                            />
                        </Field>
                        <Field
                            filled={isInputFilled(middleName)}
                            invalid={isInputInvalid('middleName', [
                                validateName('Не правильно заполнено отчество'),
                            ])}
                            size="33"
                            disabled={this.disabledBySnils()}
                        >
                            <Label htmlFor="middleName">Отчество</Label>
                            <Input
                                id="middleName"
                                name="middleName"
                                value={middleName || ''}
                                maxLength={100}
                                onChange={(e) => handleStateChange({ middleName: e.target.value })}
                            />
                        </Field>
                        <Field
                            // TODO: Удалить если заказчик не захочет вернуть после импорта новых резервистов
                            //required
                            filled={isInputFilled(birthPlace)}
                            //invalid={isInputInvalid('birthPlace', [requireValidator('Введите место рождения')])}
                            size="33"
                            disabled={this.disabledBySnils()}
                        >
                            <Label htmlFor="birthPlace">Место рождения</Label>
                            <Input
                                name="birthPlace"
                                value={birthPlace || ''}
                                maxLength={100}
                                onChange={(e) => handleStateChange({ birthPlace: e.target.value })}
                            />
                        </Field>

                        <Field
                            required
                            filled={isInputFilled(selectedSex)}
                            invalid={isInputInvalid('selectedSex', [
                                requireValidator('Выберите пол'),
                            ])}
                            size="33"
                            disabled={this.disabledBySnils()}
                        >
                            <Label>Пол</Label>
                            <Toggle
                                value={selectedSex}
                                options={this.props[SEX].data
                                    .filter((item) => item.name !== sexEnumObj.Any)
                                    .map((item, index) => ({
                                        htmlId: 'profile-page-personal-info-sex-' + index,
                                        title: item.name,
                                        value: item,
                                    }))}
                                onChange={(newSelectedSex) =>
                                    handleStateChange({ selectedSex: newSelectedSex })
                                }
                                isDisabled={this.disabledBySnils()}
                            />
                        </Field>
                    </Row>
                    <Row>
                        <Field
                            //required
                            //filled={isInputFilled(birthDate)}
                            invalid={isInputInvalid('birthDate', [this.isBirthDateInvalid()])}
                            size="33"
                            disabled={this.disabledBySnils()}
                        >
                            <Label htmlFor="birthDate">Дата рождения</Label>
                            <DatePicker
                                id="birthDate"
                                name="birthDate"
                                selected={birthDate}
                                dateFormat={
                                    birthDateDisplayMode === BIRTH_DATE_DISPLAY_MODES.Year
                                        ? 'yyyy'
                                        : 'dd.MM.yyyy'
                                }
                                showYearPicker={
                                    birthDateDisplayMode === BIRTH_DATE_DISPLAY_MODES.Year
                                }
                                onChange={(value) => handleStateChange({ birthDate: value })}
                                noDataBtn
                            />
                        </Field>
                        <CheckField
                            id="birthDateDisplayMode"
                            title="ДР не определена"
                            checked={birthDateDisplayMode === BIRTH_DATE_DISPLAY_MODES.Year}
                            onChange={this.onBirthDateDisplayModeChanged}
                            required
                            size="33"
                            className="anketa-nationality_wrap"
                            modifier="DataCardContent"
                            disabled={this.disabledBySnils()}
                        />
                    </Row>

                    <Row>
                        <Field
                            required
                            filled={isInputFilled(selectedDocument)}
                            invalid={isInputInvalid('selectedDocument', [
                                requireValidator('Выберите документ'),
                            ])}
                            size="33"
                            disabled={this.disabledBySnils()}
                        >
                            <Label>Документ</Label>
                            <Select
                                inputId="documentType"
                                value={selectedDocument}
                                onChange={(newSelectedDocument) => {
                                    const newDocumentName = isForeignDocument(newSelectedDocument)
                                        ? documentName
                                        : null;

                                    handleStateChange({
                                        selectedDocument: newSelectedDocument,
                                        documentName: newDocumentName,
                                    });
                                }}
                                options={this.props[IDENTITY_DOCUMENTS].data}
                                catalog
                                isDisabled={this.disabledBySnils()}
                            />
                            <small>* Основной документ, удостоверяющий личность.</small>
                        </Field>
                        <Field
                            filled={isInputFilled(documentNumber)}
                            invalid={isInputInvalid('documentNumber', [
                                regexValidator(
                                    this.getDocumentNumberRegex(selectedDocument),
                                    'Номер не соответствует маске',
                                    true,
                                ),
                            ])}
                            size="33"
                            disabled={this.disabledBySnils()}
                        >
                            <Label>Номер документа</Label>
                            {this.renderDocumentNumber(
                                !!this.getDocumentNumberFormat(selectedDocument),
                            )}
                        </Field>
                        {isForeignDocument(selectedDocument) && (
                            <Field
                                required
                                filled={isInputFilled(documentName)}
                                invalid={isInputInvalid('documentName', [
                                    requireValidator('Введите название документа'),
                                ])}
                                size="33"
                                disabled={this.disabledBySnils()}
                            >
                                <Label>Название документа</Label>
                                <Input
                                    id="documentName"
                                    value={documentName || ''}
                                    onChange={(e) =>
                                        handleStateChange({ documentName: e.target.value })
                                    }
                                    maxLength={100}
                                    disabled={this.disabledBySnils()}
                                />
                            </Field>
                        )}
                    </Row>
                    <Row>
                        <Field
                            filled={isInputFilled(email)}
                            // TODO: Удалить если заказчик не захочет вернуть после импорта новых резервистов
                            /*invalid={isInputInvalid('email', [requireValidator('Введите E-mail'), emailValidator()])}*/
                            size="50"
                            disabled={this.disabledBySnils()}
                        >
                            <Label htmlFor="email">E-mail</Label>
                            <Input
                                type="email"
                                id="email"
                                value={email || ''}
                                maxLength={100}
                                onChange={(e) => handleStateChange({ email: e.target.value })}
                                disabled={this.disabledBySnils()}
                            />
                        </Field>
                        <Field
                            filled={isInputFilled(phone)}
                            // TODO: Удалить если заказчик не захочет вернуть после импорта новых резервистов
                            /*invalid={isInputInvalid('phone', [requireValidator('Введите телефон')])}*/
                            invalid={phone && this.phoneInputInvalid(phone)}
                            size="50"
                            disabled={this.disabledBySnils()}
                        >
                            <Label htmlFor="phone">Телефон</Label>
                            <PhoneInput
                                id="phone"
                                value={phone}
                                onChange={(e) => handleStateChange({ phone: e })}
                                disabled={this.disabledBySnils()}
                            />
                            <small>* Формат телефона: +7 (123) 456-78-90</small>
                        </Field>
                    </Row>
                    <DataCardBlock title="Текущее место проживания">
                        {isRussianCitizen ? this.renderRussianBlock() : this.renderForeignBlock()}
                    </DataCardBlock>
                    <DataCardBlock title="Проживание в субъектах РФ">
                        <PastResidencesTable
                            isInputInvalid={this.isPastResidencesInputInvalid}
                            isInputFilled={this.props.isInputFilled}
                            catalogs={pastResidencesCatalogs}
                            entries={pastResidences}
                            newEntry={newPastResidenceEntry}
                            onChangeEntries={this.handlePastResidencesChange}
                            isDisabled={this.disabledBySnils()}
                        />
                    </DataCardBlock>
                    <Row>
                        <Field
                            filled={isInputFilled(extraInfo)}
                            size="100"
                            disabled={this.disabledBySnils()}
                        >
                            <Label htmlFor="extraInfo">Биографическая справка</Label>
                            <InputText
                                value={extraInfo}
                                maxLength={4000}
                                onChange={(e) => handleStateChange({ extraInfo: e.target.value })}
                                disabled={this.disabledBySnils()}
                            />
                        </Field>
                    </Row>
                    <Row>
                        {this.props.isEditBlock &&
                            this.props.renderEditBlock(!this.disabledBySnils())}
                    </Row>
                </Form>
            </DataCard>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.person.data,
        snilsIsOk: state.person.snilsIsOk,
        showSnilsError: state.person.showSnilsError,
    };
};

const actions = {
    showWarningAlert,
    showErrorAlert,
    showSuccessAlert,
    push,
    setMergeData,
    setIsMergeNext,
    setSnilsIsOk,
    setShowSnilsError,
    showPageLoader,
    hidePageLoader,
};

export default connect(mapStateToProps, actions)(ValidBlock(PersonalInfoBlock));
