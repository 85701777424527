import React from 'react';
import IconButton from 'components/Lk/Uikit/Buttons/IconButton';

const WishlistEventMenuItems = props => {
    const { disabledButtons, selectedItems, menuFuncs } = props;
    const bagdeInfo = selectedItems.length !== 0 && {text: selectedItems.length, color: 'green'};
    return (
        <>
            <IconButton
                className="EventMenuItem"
                disabled={disabledButtons}
                onClick={menuFuncs?.onWishlistsShow(selectedItems)}
                type=""
                badge={bagdeInfo}
            >
                <span className="EventMenuItem__Text">Показать</span>
            </IconButton>
            <IconButton
                className="EventMenuItem"
                disabled={disabledButtons}
                onClick={menuFuncs?.onWishlistsEdit}
                type="edit"
            >
                <span className="EventMenuItem__Text">Редактировать</span>
            </IconButton>
        </>
    );
};

export default WishlistEventMenuItems;