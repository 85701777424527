import React from 'react';
import Modal from 'react-responsive-modal';
import ProfileButton from 'components/Lk/Uikit/Buttons/Button/ProfileButton';
import Input from 'components/uikit/Input';

const SaveModalTemplates = props => {
    const { title = '', subTitle = '', onClose, onSave, setTitle, isOpen, templateTitle } = props;
    const onChange = e => setTitle(e.target.value);

    return (
        <Modal
            focusTrapped={false}
            open={isOpen}
            onClose={onClose}
            showCloseIcon={false}
            center
            classNames={{ modal: 'SearchModalTemplate' }}
        >
            <div className="SearchModalTemplate__Wrapper">
                <div className="SearchTemplateFields">
                    <span className="SearchTemplateFields__Label">{title}</span>
                    <span className="SearchTemplateFields__Description">
                        {subTitle}
                    </span>
                    <Input
                        value={templateTitle || ''}
                        maxLength="128"
                        autoFocus={false}
                        placeholder="Введите название"
                        className="SearchTemplateFields__Input"
                        onChange={onChange}
                    />
                </div>
                <div className="SearchModalTemplate__Buttons">
                    <ProfileButton
                        type="error"
                        onClick={onClose}
                        className="SearchModalTemplate__Button"
                    >
                        Отменить
                    </ProfileButton>
                    <ProfileButton onClick={onSave} className="SearchModalTemplate__Button">
                        Сохранить
                    </ProfileButton>
                </div>
            </div>
        </Modal>
    );
};

export default SaveModalTemplates;
