// search mass actions 
export const MENU_ACTION = 'action';
export const MENU_TYPE = 'type';
export const MENU_EVALUATION = 'evaluation';

export const WISHLIST_ADD = 'wishlistAdd';
export const WISHLIST_REMOVE = 'wishlistRemove';
export const WISHLIST_MERGE = 'wishlistMerge';
export const WISHLIST_COMMENT = 'wishlistComment';
export const WISHLIST_CLONE = 'wishlistClone';
export const EXPORT_REPORT = 'exportReport';
export const SEND_TO_EVALUATION = 'sendToEvaluation';
export const SEND_EMAIL = 'sendEmail';
export const SEND_TO_IPR = 'sendToIPR';
export const PERSON_COMMENT = 'addPersonComment';

export const MENU_ACTION_ITEMS = [
    { id: WISHLIST_ADD, name: 'Добавить в подборку', icon: 'plus' },
    { id: WISHLIST_REMOVE, name: 'Удалить из подборки', icon: 'cross' },
    { id: EXPORT_REPORT, name: 'Выгрузить отчёт', icon: 'pdf' },
    { id: SEND_TO_EVALUATION, name: 'Отправить на оценку', icon: 'evaluate' },
    { id: SEND_EMAIL, name: 'Отправить письмо', icon: 'mail' },
    { id: SEND_TO_IPR, name: 'Направить на создание ИПР', icon: 'progress' },
    { id: PERSON_COMMENT, name: 'Заметка', icon: 'comment'},
];


// wishlists mass actions
export const WISHLISTS_ACTION_ITEMS = [
    { id: WISHLIST_REMOVE, name: 'Удалить', icon: 'cross' },
    { id: WISHLIST_MERGE, name: 'Слияние', icon: 'merge'},
    { id: WISHLIST_COMMENT, name: 'Заметка', icon: 'comment'},
    { id: WISHLIST_CLONE, name: 'Клонировать', icon: 'clone'},
];